<template>
  <!--v-show="$vuetify.breakpoint.mdAndUp" hides this element on small and extra small devices -->
  <v-app-bar flat color="var(--v-resWhite-base)" extended class="mt-0 pt-4 secondaryToolbar" v-if="$vuetify.breakpoint.mdAndUp">
    <v-toolbar-title>
      <v-row :style="toolbarProps">
        <v-col class="ml-2" v-if="this.currentArea==='Leases' && this.currentItem != null">
          <span class="title">{{ currentItem.name }}</span><span class="font-weight-thin subheading"> (</span><span class="dot"></span><span class="font-weight-thin subheading">{{ statusText }})</span>
        </v-col>
        <v-col class="ml-2" v-else-if="this.currentArea==='Properties' && this.currentItem != null">
           <span class="title">{{ currentItem.propertyName }}</span>
        </v-col>
        <v-col class="ml-2" v-else>
            <span class="title pb-1">{{ currentRoute }}</span>
        </v-col>
      </v-row>
      <h4 v-if="this.currentArea==='Leases' && this.currentItem != null" class="ml-2 font-weight-light subheading">{{ currentItem.propertyName }}</h4>
      <h4 v-else class="ml-2 font-weight-light subheading">{{ currentDate | moment("dddd | MMMM DD, YYYY") }}</h4>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-toolbar-items>
      <v-menu
        v-if="false"
        v-model="SearchEditMenu" :nudge-bottom="34" max-width="130" content-class="rounded-card">
        <template v-slot:activator="{ on }">
          <v-btn text outlined color="var(--v-resGray-darken3)" min-width="130" class="secondary-nav__button mr-4 pr-1 pl-2" v-on="on">
            <v-icon class="pl-2" small>search</v-icon>
            <span class="pl-1">Search & Edit</span>
            <v-icon right class="pa-1 ma-0">{{ SearchEditIcon }}</v-icon>
          </v-btn>
        </template>
        <secondary-top-nav-menus :secondaryTopNavSelected="SearchEdit"></secondary-top-nav-menus>
      </v-menu>
      <v-menu v-model="ReportsMenu" :nudge-bottom="34" max-width="130" content-class="rounded-card" v-if="$vuetify.breakpoint.mdAndUp">
        <template v-slot:activator="{ on }">
          <v-btn text outlined color="var(--v-resGray-darken3)" class="secondary-nav__button mr-1 pr-2 pl-1" v-on="on">
            <v-icon class="material-icons-outlined pl-2 pr-1">assessment</v-icon>
            <span>Reports</span>
            <v-icon right class="pl-6 ml-1">{{ ReportsIcon }}</v-icon>
          </v-btn>
        </template>
        <secondary-top-nav-menus :secondaryTopNavSelected="Reports"></secondary-top-nav-menus>
      </v-menu>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import SecondaryTopNavMenus from './SecondaryTopNavMenus'
import {mapGetters} from 'vuex'

export default {
  components: {
    SecondaryTopNavMenus
  },
  data () {
    return {
      currentDate: new Date(),
      text: 'some text',
      SearchEditMenu: false,
      SearchEditIcon: 'expand_more',
      SearchEdit: 'SearchEdit',
      ListingMenu: false,
      ListingIcon: 'expand_more',
      Listing: 'Listing',
      Reports: 'Reports',
      ReportsIcon: 'expand_more',
      ReportsMenu: false,
      items: [],
      '--dotColor': 'var(--v-resGreen-lighten1)'
    }
  },
  created () {
    this.initialize()
  },
  watch: {
    SearchEditMenu: function () {
      this.SearchEditIcon = this.FlipIcon(this.SearchEditIcon)
    },
    ListingMenu: function () {
      this.ListingIcon = this.FlipIcon(this.ListingIcon)
    },
    ReportsMenu: function () {
      this.ReportsIcon = this.FlipIcon(this.ReportsIcon)
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'currentUserDisplayName', 'currentSelectedItem', 'currentSection']),
    currentRoute: {
      get () {
        if (this.$route.name === 'Dashboard' && this.currentUser) {
          return 'Welcome, ' + this.currentUserDisplayName
        } else {
          return this.$route.name
        }
      },
      set () {
        return this.$route.name
      }
    },
    currentArea: {
      get () {
        return this.currentSection
      }
    },
    currentItem: {
      get () {
        return this.currentSelectedItem
      }
    },
    toolbarProps () {
      return {
        '--dotColor': this.dotColor
      }
    },
    statusText: {
      get () {
        if (this.currentSelectedItem) {
          if (this.currentSelectedItem.leaseStatus === 'C') {
            return 'Current'
          } else if (this.currentSelectedItem.leaseStatus === 'M') {
            return 'Monthly'
          } else if (this.currentSelectedItem.leaseStatus === 'F') {
            return 'Future'
          } else if (this.currentSelectedItem.leaseStatus === 'P') {
            return 'Expired'
          }
        }
        return 'Unknown'
      }
    },
    dotColor: {
      get () {
        if (this.currentSelectedItem) {
          if (this.currentSelectedItem.leaseStatus === 'C') {
            return this.$vuetify.theme.themes.light.resGreen.lighten1 // var(--v-resGreen-lighten1)
          } else if (this.currentSelectedItem.leaseStatus === 'M') {
            return this.$vuetify.theme.themes.light.resBlue.darken2 // var(--v-resBlue-darken2)
          } else if (this.currentSelectedItem.leaseStatus === 'F') {
            return this.$vuetify.theme.themes.light.resBlue.base // var(--v-resBlue-base)
          } else if (this.currentSelectedItem.leaseStatus === 'P') {
            return this.$vuetify.theme.themes.light.resRed.base // var(--v-resRed-base)
          }
        }
        return 'Unknown'
      }
    }
  },
  methods: {
    initialize () {
      this.currentRoute = this.$route.name
    },
    FlipIcon (Icon) {
      if (Icon === 'expand_more') {
        Icon = 'expand_less'
      } else {
        Icon = 'expand_more'
      }
      return Icon
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-toolbar__items {
    height: 34px;
  }
  .rounded-card {
    border-radius: 6px;
  }
  .dot {
    height: 10px;
    width: 10px;
    background-color: var(--dotColor);
    border-radius: 50%;
    margin-left: 2px;
    margin-right: 2px;
    display: inline-block;
  }
  .secondaryToolbar{
    height:90px !important;
  }
  .secondary-nav__button {
    font-size: 10px;
    border-radius: 10px;
    width: 130px;
    &:focus{
      border-color: var(--v-resBlue-base);
      &:before{
        background-color: var(--v-resWhite-base) !important;
      }
    }
  }
  .subheading {
    font-size: 16px;
  }
</style>
