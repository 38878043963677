<template>
  <div>
    <notification-tabs>
      <router-view></router-view>
    </notification-tabs>
  </div>
</template>

<script>
import NotificationTabs from './NotificationTabs'

export default {
  data () {
    return {}
  },
  components: {
    NotificationTabs
  }
}
</script>
