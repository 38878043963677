<template>
  <v-card>
    <v-hover v-for="(item, index) in selectedList()" :key="index">
    <template slot-scope="{ hover }">
      <v-card
        v-if="item.title"
        tile
        flat
        height="23"
        :ripple="{ center: true }"
        :class="`${hover? 'resGray lighten-3 secondaryTopNav__menu--hover': ''}`"
        @click="clickMenu(item)">
        <span class="secondaryTopNav__menu--items pl-2">{{ item.title }}</span>
      </v-card>
      <v-divider class="secondaryTopNav__menu--divider ml-1" :key="index" v-else></v-divider>
    </template>
    </v-hover>
  </v-card>
</template>

<script>
export default {
  props: ['secondaryTopNavSelected'],
  data () {
    return {
      selectItem: this.secondaryTopNavSelected,
      items: []
    }
  },
  created () {
    this.initialize()
  },
  methods: {
    initialize () {
      this.items = [
        { header: 'SearchEdit',
          listItems: [
            { title: 'Commercial Leases' },
            { divider: true },
            { title: 'Work Orders' },
            { divider: true },
            { title: 'Contracts and POs' },
            { divider: true },
            { title: 'Loan Draws' }
          ]
        },
        { header: 'Reports',
          listItems: [
            { title: 'Commercial', component: 'CommercialReports' },
            { divider: true },
            { title: 'Work Orders', goToResmanPath: 'WorkOrders' },
            { divider: true },
            { title: 'Accounting', goToResmanPath: 'Reports' },
            { divider: true, hide: true },
            { title: 'Loan Draws', hide: true },
            { divider: true, hide: true },
            { title: 'Projects', hide: true }
          ]
        }
      ]
    },
    selectedList: function () {
      if (this.selectItem) {
        return this.items.filter(item => {
          return item.header === this.selectItem
        })[0].listItems.filter(x => {
          return !x.hide
        })
      }
    },
    clickMenu (e) {
      if (e.component) {
        this.$eventHub.$emit('showReports', e.component)
      }

      if (e.goToResmanPath) {
        this.$eventHub.$emit('goToResman', `/#/${e.goToResmanPath}`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.secondaryTopNav__menu {
  &:active {
    color: var(--v-resWhite-base);
  }
  &--Hover{
    cursor: pointer;
  }
  &--divider {
    width: 94%;
  }
  &--items{
    font-size: 10.2px;
  }
}
</style>
