<template>
  <v-container fluid class="resGray lighten-6">
    <v-app-bar flat height="36">
      <v-spacer></v-spacer>
      <search
        class="mb-0-plus"
        :placeholder="'Search Properties'"
        :class="isMobile ? 'ml-6' : 'mr-3'"
        :width="isMobile ? '97%' : ''"
        @searchStringUpdated="updateSearchString">
      </search>
      <round-button
        color='var(--v-resGray-darken3)'
        height='30px'
        :dark='true'
        :depressed='true'
        buttonText='+ New Property'
        backgroundColor='var(--v-resGreen-lighten1)'
        borderColor='var(--v-resGray-lighten1)'
        buttonClass='mr-2'
        @click="AddProperty">
      </round-button>
    </v-app-bar>
    <v-row>
      <v-col cols="12" class="mt-3">
        <data-table
          :headers="headers"
          :tableData="getData"
          :noData="'No information to display.'"
          :searchString="searchString"
          @ClickProperty="ClickProperty"
          @ClickSpace="ClickSpace"
          class="ml-6 mr-6">
        </data-table>
      </v-col>
    </v-row>
    <!-- Dialogs -->
    <add-property
      v-if="addPropertyDialog"
      :showDialog="addPropertyDialog"
      @cancel="Cancel"
      @complete="Complete">
    </add-property>
  </v-container>
</template>

<script>
import DataTable from '../BaseComponents/Table'
import Search from '../BaseComponents/Search'
import RoundButton from '../BaseComponents/RoundButton'
import AddProperty from './PropertyDialogs/AddProperty'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    DataTable,
    Search,
    RoundButton,
    AddProperty
  },
  data () {
    return {
      searchString: null,
      addPropertyDialog: false
    }
  },
  methods: {
    ...mapActions(['changeLoading', 'changeSelectedItem', 'fetchData', 'changeTab']),
    updateSearchString (val) {
      this.searchString = val
    },
    ClickProperty (val) {
      this.$router.push({ name: 'PropertiesOverview' })
      this.changeSelectedItem(val)
    },
    ClickSpace (val) {
      this.changeSelectedItem(val)
      this.changeTab({ header: 'Spaces', index: 1, route: 'PropertiesSpaces' })
      this.$router.push({ name: 'PropertiesSpaces' })
    },
    AddProperty () {
      this.addPropertyDialog = true
    },
    Cancel () {
      this.addPropertyDialog = false
    },
    Complete () {
      this.fetchData({ newPage: 'Properties' }).catch(error => { console.log(error.response) })
      this.addPropertyDialog = false
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentItem', 'currentPlaceholder']),
    isMobile () {
      return this.$vuetify.breakpoint.mobile
    },
    headers () {
      return this.currentHeaders('Properties')
    },
    getData () {
      return this.currentData('Properties')
    }
  },
  created () {
    this.changeLoading(true)
    this.fetchData({ newPage: 'Properties' }).catch(error => { console.log(error.response) })
    this.changeSelectedItem()
    this.changeTab()
  }
}
</script>
