<template>
  <v-hover>
    <v-card
      height="40"
      width="180"
      flat
      slot-scope="{ hover }"
      :dark="isSelected"
      :color="isSelected ? 'var(--v-resBlue-darken1)': 'white'"
      class="font-weight-medium permissions-card"
      :class="[hover ? 'elevation-3' : '']">
      <v-layout align-center justify-center row fill-height>
        {{ cardText.toUpperCase() }}
      </v-layout>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  props: ['isSelected', 'cardText'],
  data () {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
.permissions-card {
  border: solid 1px var(--v-resGray-darken2) !important;
  border-radius: 6px;
  margin-top: 10px;
  padding-top: 16px;
  &:hover {
    cursor: pointer;
  }
}
</style>
