<template>
  <div>
    <properties-tabs>
      <router-view>
      </router-view>
    </properties-tabs>
  </div>
</template>

<script>
import PropertiesTabs from './PropertiesTabs'

export default {
  data () {
    return {}
  },
  components: {
    PropertiesTabs
  }
}
</script>
