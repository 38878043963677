<template>
  <overview-card
    :noEdit="true"
    :title="title">
    <v-row class="ml-2 pb-3">
      <v-col cols="12">
        <span class="noData" v-if="!notes">No notes to display</span>
        <span v-else>{{ notes }}</span>
      </v-col>
    </v-row>
  </overview-card>
</template>

<script>
import { mapGetters } from 'vuex'
import OverviewCard from '../BaseComponents/OverviewCard'

export default {
  components: {
    OverviewCard
  },
  computed: {
    ...mapGetters(['currentSelectedItem']),
    title () {
      return 'Notes'
    },
    notes () {
      if (this.currentSelectedItem.notes) {
        return this.currentSelectedItem.notes
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
.noData {
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  color: var(--v-resGray-darken5);
}
</style>
