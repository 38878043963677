<template>
  <div>
    <v-flex mt-6 mb-4>
    <span class="title">Open Charges</span>
    </v-flex>
    <distribution-tab
    ref='distribution'
    :currentCheck="currentCheck"
    @updateDistributed='updateDistributed'>
    </distribution-tab>
    <v-layout row>
      <round-button
        height='30px'
        width='80px'
        :buttonText="'Back'"
        :buttonClass="'mt-4'"
        :borderColor="'var(--v-resBlue-darken1)'"
        background-color="var(--v-resBlue-darken1)"
        :depressed="true"
        :flat="true"
        @click='previousStep'>
      </round-button>
      <v-spacer></v-spacer>
      <!-- <round-button
        height='30px'
        width='80px'
        :buttonText="'Skip'"
        :buttonClass="'mt-4'"
        :borderColor="'var(--v-resRed-base)'"
        background-color="var(--v-resRed-base)"
        :depressed="true"
        :flat="true"
        @click='nextStep'>
      </round-button> -->
      <round-button
        height='30px'
        buttonClass='mr-0 mt-4'
        width='80px'
        :depressed='true'
        :flat='true'
        :backgroundColor="'var(--v-resBlue-darken1)'"
        :buttonText="'Next'"
        @click="nextStep">
      </round-button>
    </v-layout>
  </div>
</template>

<script>
import DistributionTab from './CurrentChargesTab'
import RoundButton from '../../../BaseComponents/RoundButton'
import { mapActions } from 'vuex'

export default {
  props: ['currentCheck'],
  components: {
    RoundButton,
    DistributionTab
  },
  data () {
    return {
    }
  },
  methods: {
    ...mapActions(['changeRefundOpenCharges']),
    nextStep () {
      let openCharges = this.$refs.distribution.getCurrentCharges()
      openCharges.unshift({ chargeCode: 'REFUND', chargeCodeName: 'Refund', baseOpenAmount: 0, selectBasePaymentAmount: 0, basePaymentAmount: 0, baseAmount: 0, selectBaseAmount: 0, balance: 0, readonly: false, applyAmount: { color: 'var(--v-resBlue-darken1)', displayText: 'Apply Amount' } })
      this.changeRefundOpenCharges(openCharges)
      this.$emit('currentChargesComplete')
    },
    previousStep () {
      this.$emit('backToNewCharges')
    },
    updateDistributed (val) {
      this.$emit('updateDistributedTotal', val)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
