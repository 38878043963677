<template>
  <v-container fluid class="resGray lighten-6">
    <v-app-bar flat>
      <date-picker
        class="ml-2 mr-3"
        :label="'As of date'"
        :defaultNull="true"
        :initialDate="selectedDate"
        @dateSelected="updateSelectedDate">
      </date-picker>
      <drop-down
        label="Property Name"
        class="mb-5 mr-3"
        :selectedValue="{ text: propertyName }"
        :items="'Properties'"
        :multiple='false'
        width="250px"
        @itemSelected="selectProperty">
      </drop-down>
      <drop-down
        ref='leaseDropdown'
        label="Lease Name"
        :selectedValue="{ text: leaseName }"
        :customList="leases"
        class="mb-5 mr-3"
        checkBoxText="Include Expired"
        :showCheckBox='true'
        @checkBoxChanged='includeExpired'
        :multiple='false'
        width="200px"
        @itemSelected="selectLease">
      </drop-down>
      <text-input :value="searchString"
        label='Charge Code'
        :width="'125px'"
        class="mr-3"
        :optionalLabel="true"
        :optionalLabelText="'(Optional)'"
        :placeholder="'Enter charge code'"
        @textInputUpdated="updateSearchString">
      </text-input>
      <round-button
        class="mt-4-plus"
        color='var(--v-resGray-darken3)'
        height='30px'
        width='92px'
        :depressed='true'
        :disabled='!canSearch'
        buttonText='Search'
        :backgroundColor="'var(--v-resBlue-darken1)'"
        borderColor='var(--v-resGray-lighten1)'
        @click="getChecks">
      </round-button>
    </v-app-bar>
    <warning-bar
      class="mx-6 mb-2"
      message="Charge codes with associated payments will NOT appear on this list, as they cannot be deleted."
      @closeWarning="closeWarning"
      :showWarning="warningBar">
    </warning-bar>
    <v-row>
      <v-col cols="12">
        <data-table
          class="mx-6"
          :headers="headers"
          :tableData="getData"
          :searchString="searchString"
          @itemDeleteRequested="openDeleteDialog"
          :noData="'Please use search criteria above for results.'">
        </data-table>
      </v-col>
    </v-row>
    <!-- Dialogs -->
    <delete-dialog
      :showDialog="deleteConfirm"
      v-if="deleteConfirm"
      :title="'Are you sure?'"
      confirmButton="Yes, delete"
      confirmButtonWidth="108px"
      @confirmDelete="confirmDialog"
      message="Please confirm that you would like to delete this charge."
      @cancelDelete="cancelDialog">
    </delete-dialog>
  </v-container>
</template>

<script>
import DropDown from '../../BaseComponents/DropDown'
import RoundButton from '../../BaseComponents/RoundButton'
import DatePicker from '../../BaseComponents/DatePicker'
import TextInput from '../../BaseComponents/TextInput'
import DataTable from '../../BaseComponents/Table'
import DeleteDialog from '../../BaseComponents/DeleteDialog'
import WarningBar from '../../BaseComponents/WarningBar'
import { mapActions, mapGetters } from 'vuex'
import * as validation from '../../../utility/validation'

export default {
  components: {
    DropDown,
    DatePicker,
    RoundButton,
    DataTable,
    DeleteDialog,
    WarningBar,
    TextInput
  },
  data () {
    return {
      property: null,
      propertyName: undefined,
      selectedDate: null,
      chargeCode: null,
      chargeCodeName: undefined,
      lease: null,
      leaseName: undefined,
      deleteConfirm: false,
      currentChargeToDelete: {},
      searchString: '',
      warningBar: true
    }
  },
  methods: {
    ...mapActions(['fetchData', 'deleteData', 'initializeStoreItem', 'fetchDropdown']),
    updateSelectedDate (val) {
      this.selectedDate = val
    },
    selectProperty (val) {
      if (val !== null && val !== undefined) {
        this.propertyName = val.text
        this.property = val.value
        this.$refs.leaseDropdown.resetCheckBox()
        this.fetchDropdown({ newDropdown: 'Leases', params: { PropertyId: this.property, LeaseStatus: 0 } }).catch(error => { console.log(error.response) })
      } else {
        this.propertyName = null
        this.property = null
      }
    },
    includeExpired (val) {
      let type = 0
      if (val) {
        type = null
      }
      this.fetchDropdown({ newDropdown: 'Leases', params: { LeaseStatus: type, PropertyId: this.property } }).catch(error => { console.log(error.response) })
    },
    selectLease (val) {
      if (val !== null && val !== undefined) {
        this.leaseName = val.text
        this.lease = val.value
      } else {
        this.leaseName = null
        this.lease = null
      }
    },
    closeWarning () {
      this.warningBar = false
    },
    clearInfo () {
      this.property = undefined
      this.propertyName = undefined
      this.selectedDate = undefined
      this.deleteConfirm = false
      this.currentChargeToDelete = {}
      this.initializeStoreItem('LeaseDeletableCharges')
    },
    getChecks () {
      if (this.canSearch) {
        this.fetchData({newPage: 'LeaseDeletableCharges', params: { Property: this.property, Lease: this.lease, StartDate: this.selectedDate, IncludeChargeHistory: true }}).catch(error => { console.log(error.response) })
      }
    },
    updateSearchString (val) {
      this.searchString = val
    },
    openDeleteDialog (val) {
      this.currentChargeToDelete = val
      this.deleteConfirm = true
    },
    cancelDialog () {
      this.deleteConfirm = false
    },
    confirmDialog () {
      this.deleteData({ currentPage: 'LeaseDeletableCharges', removeItem: this.currentChargeToDelete }).then(() => {
        this.fetchData({newPage: 'LeaseDeletableCharges', params: { Property: this.property, Lease: this.lease, StartDate: this.selectedDate, IncludeChargeHistory: true }})
        this.deleteConfirm = false
      }).catch(error => { console.log(error.response) })
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentDropdown']),
    getData () {
      return this.currentData('LeaseDeletableCharges')
    },
    headers () {
      return this.currentHeaders('LeaseDeletableCharges')
    },
    leases () {
      return this.currentDropdown('Leases')
    },
    canSearch () {
      if (validation.hasValue(this.property) && validation.hasValue(this.lease) && validation.hasValue(this.selectedDate)) {
        return true
      }
      return false
    }
  }
}
</script>
