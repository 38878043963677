<template>
  <base-dialog
    iconColor="var(--v-resWhite-base)"
    titleClass="alert-header"
    :showTitleIcon="true"
    :smallTitleIcon="true"
    :saveButtonColor="saveButtonColor"
    :title="title"
    :saveButtonText="saveButtonText"
    :saveButtonWidth="100"
    :cancelButtonColor="'var(--v-resBlue-darken1)'"
    :cancelButtonTextColor="'blue--text'"
    :setWidth="425"
    :maxWidth="425"
    :showDialog="showDialog"
    @cancel="cancel"
    @save="save">
    <v-container>
      <v-layout row>
        <span>You are about to delete all the charges.  Do you want to continue?</span>
      </v-layout>
    </v-container>
  </base-dialog>
</template>

<script>
import BaseDialog from '../../../BaseComponents/BaseDialog'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    BaseDialog,
  },
  props: ['showDialog'],
  data () {
    return {
      confirm: false
    }
  },
  methods: {
    ...mapActions(['fetchData']),
    cancel () {
      this.$emit('cancelDialog')
    },
    save () {
      this.$emit('save')
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders']),
    title () {
      return 'Delete Charges'
    },
    saveButtonText () {
      return 'Yes, Delete'
    },
    saveButtonColor () {
      return 'var(--v-resRed-base)'
    },
    tableData () {
      return this.currentData('Reconciliations Expense Account Details')
    },
    tableHeaders () {
      return this.currentHeaders('Reconciliations Expense Account Details')
    },
    selectedRows () {
      return this.tableData.filter(x => {
        return x.isUsed
      })
    }
  }
}
</script>
