<template>
  <v-tabs
    centered
    slider-color="var(--v-resBlue-base)"
    v-model="currentTab.index">

    <v-tab v-for="tab in displayableTabs"
      :ripple="false"
      :key="tab.header"
      :name="tab.header"
      :disabled="currentLoading==true"
      @change="changeTabs(tab)"
      class="mr-6">
      {{ tab.header }}
    </v-tab>

    <v-menu
      v-model="menuActive"
      nudge-bottom="52"
      v-if="moreTabs">
      <template v-slot:activator="{ on }">
        <div @click="toggleMenu" v-on="on">
        <v-tab
          v-if="moreTabsExist"
          class="more-menu pt-3"
          :disabled="currentLoading==true || !moreTabsSelected"
          :ripple="false">
            {{ moreTabsLabel }}
            <v-icon v-if="!menuActive">arrow_drop_down</v-icon>
            <v-icon v-if="menuActive">arrow_drop_up</v-icon>
        </v-tab>
        </div>
      </template>
      <v-list>
        <v-list-item
          v-for="tab in moreTabs"
          :key="tab.header">
          <a class="menu-item" @click.stop="changeMenuTab(tab)">{{ tab.header }}</a>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-tab-item
      v-for="tab in tabs"
      :key="tab.header">
      <slot v-if="tabs.indexOf(tab) == currentTab.index"></slot>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: ['tabs'],
  data () {
    return {
      activeTab: null,
      menuActive: false
    }
  },
  methods: {
    ...mapActions(['changeTab']),
    changeTabs (tab) {
      this.$emit('tabChanged', tab)
      var tabIndex = this.tabs.indexOf(tab)
      tab.index = tabIndex
      this.changeTab(tab)
    },
    changeMenuTab (tab) {
      tab.originalIndex = this.tabs.indexOf(tab)
      tab.index = 5
      this.menuActive = false
      this.$emit('tabChanged', tab)
      this.changeTab(tab)
    },
    toggleMenu (e) {
      e.preventDefault()
      this.menuActive = !this.menuActive
    }
  },
  created () {
    if (!this.currentTab) {
      var tab = this.tabs[0]
      tab.index = 0
      this.changeTab(tab)
    }
  },
  computed: {
    ...mapGetters(['currentLoading', 'currentTab']),
    displayableTabs () {
      return this.tabs.filter(tab => {
        return this.tabs.indexOf(tab) < 5
      })
    },
    moreTabs () {
      return this.tabs.filter(tab => {
        return this.tabs.indexOf(tab) >= 5
      })
    },
    moreTabsExist () {
      return this.moreTabs.length > 0
    },
    moreTabsLabel () {
      if (!this.moreTabsSelected) {
        return 'more'
      } else {
        return this.currentTab.header
      }
    },
    moreTabsSelected () {
      return this.tabs.indexOf(this.currentTab) >= 5
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-tabs {
    ::v-deep .v-tab {
      font-size: 12px;
      min-width: 120px;
      &:hover {
        border-bottom: 2px solid var(--v-resBlue-base);
        background-color: transparent !important;
      }
      &:before {
        background-color: transparent !important;
      }
    }
    .v-tab--active.more-menu {
      border-bottom: none!important;
    }
    .v-tab--disabled.more-menu {
      opacity: 1;
      pointer-events: inherit;
      text-transform: lowercase;
      border-bottom: none!important;
    }
  }
  a.menu-item {
    color: var(--v-resGray-darken4);
    font-size: 12px;
  }
</style>
