<template>
  <div>
    <receipts-tabs>
      <router-view></router-view>
    </receipts-tabs>
  </div>
</template>

<script>
import ReceiptsTabs from './ReceiptsTabs'

export default {
  data () {
    return {}
  },
  components: {
    ReceiptsTabs
  }
}
</script>
