<template>
  <v-container fluid v-resize="onResize">
    <v-row>
      <v-col cols="12" class="pb-0">
        <div class="mx-6">
          <span class="resGray-darken5--text title">Overview</span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="isMobile ? 12 : 4">
        <div :class="{'ml-6 mr-1': !isMobile, 'ml-6 mr-6 mb-2': isMobile }">
          <lease-sq-ft-expiration-chart
            ref="expirationChart"
            :asOfDate="asOfDate"
            @selectChart="setCurrentChart('expiration')">
          </lease-sq-ft-expiration-chart>
        </div>
      </v-col>
      <v-col :cols="isMobile ? 12 : 4">
        <div :class="{'ml-6 mr-6': !isMobile, 'ml-6 mr-6 mb-2': isMobile }">
          <occupancy-chart
            ref="occupancyChart"
            :asOfDate="asOfDate"
            @selectChart="setCurrentChart('occupancy')">
          </occupancy-chart>
        </div>
      </v-col>
      <v-col :cols="isMobile ? 12 : 4">
        <div :class="{'ml-1 mr-6': !isMobile, 'ml-6 mr-6 mb-2': isMobile }">
          <tenant-receivables-chart
            ref="receivablesChart"
            :asOfDate="asOfDate"
            @selectChart="setCurrentChart('receivables')">
          </tenant-receivables-chart>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pb-0">
        <div class="mx-6">
          <span class="resGray-darken5--text title">{{ getTableViewDescription }}</span>
        </div>
      </v-col>
    </v-row>
    <v-app-bar flat height="36" class="mt-3">
      <toggle-button-group
        class="ml-5"
        :buttons="toggleButtons"
        @toggled="changeFilter">
      </toggle-button-group>
      <v-spacer></v-spacer>
      <search
        class="mb-0-plus"
        :placeholder="getSearchPlaceholderText"
        :class="isMobile ? 'ml-6' : 'mr-2'"
        :width="isMobile ? '97%' : ''"
        @searchStringUpdated="updateSearchString">
      </search>
      <span class="mr-2">As of:</span>
      <date-picker
        class="mr-2"
        :hideDetails="true"
        :initialDate="asOfDate"
        @dateSelected="setAsOfDate">
      </date-picker>
    </v-app-bar>
    <v-row>
      <v-col cols="12" class="mt-3">
        <data-table
          :headers="getDetailHeaders"
          :tableData="getDetailData"
          @ClickLease="openLeaseHistory"
          :noData="'No information to display.'"
          :searchString="searchString"
          :nullDisplayValue="'-'"
          class="ml-6 mr-6">
        </data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DataTable from '../BaseComponents/Table'
import DatePicker from '../BaseComponents/DatePicker'
import Search from '../BaseComponents/Search'
import ToggleButtonGroup from '../BaseComponents/ToggleButtonGroup'
import LeaseSqFtExpirationChart from './Dashboard/LeaseSqFtExpirationChart'
import OccupancyChart from './Dashboard/OccupancyChart'
import TenantReceivablesChart from './Dashboard/TenantReceivablesChart'
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    DataTable,
    DatePicker,
    Search,
    ToggleButtonGroup,
    LeaseSqFtExpirationChart,
    OccupancyChart,
    TenantReceivablesChart
  },
  data () {
    return {
      asOfDate: moment(new Date()).format('YYYY-MM-DD'),
      currentBreakpoint: this.$vuetify.breakpoint.name,
      currentChart: 'expiration',
      currentTableView: 'property',
      searchString: null,
      toggleButtons: [
        { text: 'primary', textOverride: 'Properties' },
        { text: 'secondary', textOverride: 'Leases' }
      ]
    }
  },
  methods: {
    ...mapActions(['changeLoading', 'fetchData', 'changeSection', 'changeSelectedItem', 'changeTab', 'changeCurrentToggle']),
    setCurrentChart (val) {
      this.currentChart = val
      switch (this.currentChart) {
        case 'expiration':
          this.$refs.occupancyChart.setSelected(false)
          this.$refs.receivablesChart.setSelected(false)
          this.toggleButtons[0].textOverride = 'Properties'
          this.toggleButtons[1].textOverride = 'Leases'
          break
        case 'occupancy':
          this.$refs.expirationChart.setSelected(false)
          this.$refs.receivablesChart.setSelected(false)
          this.toggleButtons[0].textOverride = 'Properties'
          this.toggleButtons[1].textOverride = 'Spaces'
          break
        case 'receivables':
          this.$refs.expirationChart.setSelected(false)
          this.$refs.occupancyChart.setSelected(false)
          this.toggleButtons[0].textOverride = 'Properties'
          this.toggleButtons[1].textOverride = 'Leases'
          break
      }

      this.refreshTableView()
    },
    setCurrentTableView (val) {
      this.currentTableView = val
      this.refreshTableView()
    },
    changeFilter (val) {
      if (val === 0) {
        this.setCurrentTableView('property')
      } else {
        this.setCurrentTableView('lease-space')
      }
    },
    updateSearchString (val) {
      this.searchString = val
    },
    setAsOfDate (e) {
      this.asOfDate = moment(e).format('YYYY-MM-DD')
    },
    refreshTableView () {
      switch (this.currentChart) {
        case 'expiration':
          this.refreshSqFtExpirationTableView()
          break
        case 'occupancy':
          this.refreshOccupancyTableView()
          break
        case 'receivables':
          this.refreshReceivablesTableView()
          break
      }
    },
    refreshSqFtExpirationTableView () {
      if (this.currentTableView === 'property') {
        this.fetchData({ newPage: 'DashboardSqFtExpirationPropertyDetails', params: { AsOfDate: this.asOfDate } }).catch(error => { console.log(error.response) })
      } else {
        this.fetchData({ newPage: 'DashboardSqFtExpirationLeaseDetails', params: { AsOfDate: this.asOfDate } }).catch(error => { console.log(error.response) })
      }
    },
    refreshOccupancyTableView () {
      if (this.currentTableView === 'property') {
        this.fetchData({ newPage: 'DashboardOccupancyPropertyDetails', params: { AsOfDate: this.asOfDate } }).catch(error => { console.log(error.response) })
      } else {
        this.fetchData({ newPage: 'DashboardOccupancyLeaseDetails', params: { AsOfDate: this.asOfDate } }).catch(error => { console.log(error.response) })
      }
    },
    refreshReceivablesTableView () {
      if (this.currentTableView === 'property') {
        this.fetchData({ newPage: 'DashboardReceivablesPropertyDetails', params: { AsOfDate: this.asOfDate } }).catch(error => { console.log(error.response) })
      } else {
        this.fetchData({ newPage: 'DashboardReceivablesLeaseDetails', params: { AsOfDate: this.asOfDate } }).catch(error => { console.log(error.response) })
      }
    },
    openLeaseHistory (lease) {
      this.fetchData({ newPage: 'SingleLease', params: { LeaseId: lease.leaseId } }).then(() => {
        this.changeSelectedItem(this.currentData('SingleLease'))
        this.changeTab({ index: 3 })
        this.changeCurrentToggle(1)
        this.$router.push({ name: 'LeasesHistory' })
      }).catch(error => { console.log(error.response) })
    },
    changeState () {
      this.changeSection('Dashboard')
      this.changeSelectedItem()
    },
    onResize () {
      if (this.currentBreakpoint === this.$vuetify.breakpoint.name) {
        return
      }

      this.currentBreakpoint = this.$vuetify.breakpoint.name
      this.$refs.expirationChart.refreshChartData()
      this.$refs.occupancyChart.refreshChartData()
      this.$refs.receivablesChart.refreshChartData()
      this.refreshTableView()
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentItem', 'currentPlaceholder']),
    isMobile () {
      return this.$vuetify.breakpoint.mobile
    },
    getDetailHeaders () {
      switch (this.currentChart) {
        case 'expiration':
          return this.currentTableView === 'property'
            ? this.currentHeaders('DashboardSqFtExpirationPropertyDetails')
            : this.currentHeaders('DashboardSqFtExpirationLeaseDetails')
        case 'occupancy':
          return this.currentTableView === 'property'
            ? this.currentHeaders('DashboardOccupancyPropertyDetails')
            : this.currentHeaders('DashboardOccupancyLeaseDetails')
        case 'receivables':
          return this.currentTableView === 'property'
            ? this.currentHeaders('DashboardReceivablesPropertyDetails')
            : this.currentHeaders('DashboardReceivablesLeaseDetails')
      }
      return null
    },
    getDetailData () {
      switch (this.currentChart) {
        case 'expiration':
          return this.currentTableView === 'property'
            ? this.currentData('DashboardSqFtExpirationPropertyDetails')
            : this.currentData('DashboardSqFtExpirationLeaseDetails')
        case 'occupancy':
          return this.currentTableView === 'property'
            ? this.currentData('DashboardOccupancyPropertyDetails')
            : this.currentData('DashboardOccupancyLeaseDetails')
        case 'receivables':
          return this.currentTableView === 'property'
            ? this.currentData('DashboardReceivablesPropertyDetails')
            : this.currentData('DashboardReceivablesLeaseDetails')
      }
      return null
    },
    getSearchPlaceholderText () {
      return this.currentTableView === 'property'
        ? 'Search Properties'
        : 'Search Leases'
    },
    getTableViewDescription () {
      switch (this.currentChart) {
        case 'expiration':
          return 'Lease SqFt Expiration Details'
        case 'occupancy':
          return 'Occupancy Details'
        case 'receivables':
          return 'Tenant Receivables Details'
      }
      return null
    }
  },
  watch: {
    asOfDate: function () {
      this.refreshTableView()
    }
  },
  created () {
    this.changeLoading(true)
    setTimeout(() => {
      this.refreshTableView()
    }, 1000)
  },
  mounted () {
    this.changeState()
    this.changeTab()
  }
}
</script>

<style lang="scss" scoped>
  .as-of-date-wrapper {
    margin-bottom: 1px;
  }
</style>
