<template>
  <base-dialog
    iconColor="var(--v-resWhite-base)"
    titleClass="dark-header"
    :saveButtonColor="saveButtonColor"
    :saveButtonWidth="130"
    :title="title"
    :saveButtonText="saveButtonText"
    :cancelButtonColor="'var(--v-resRed-base)'"
    :cancelButtonBorderColor="'var(--v-resRed-base)'"
    :setWidth="700"
    :maxWidth="700"
    :showDialog="showDialog"
    @cancel="cancel"
    @save="send">
    <v-card-text>
      <v-card flat class="container-card mt-6" v-if="cycleInfo">
        <v-layout row>
          <v-layout column>
            <span class="caption resGray-darken1--text font-weight-medium">Cycle Year</span>
            <span class="caption resGray-darken5--text font-weight-medium">{{ cycleInfo.cycleNumber }}</span>
          </v-layout>
          <v-layout column>
            <span class="caption resGray-darken1--text font-weight-medium">Cycle #</span>
            <span class="caption resGray-darken5--text font-weight-medium">{{ cycleInfo.cyclePeriod }}</span>
          </v-layout>
          <v-layout column>
            <span class="caption resGray-darken1--text font-weight-medium">Cycle start date</span>
            <span class="caption resGray-darken5--text font-weight-medium">{{ formatDate(cycleInfo.cycleStartDate) }}</span>
          </v-layout>
          <v-layout column>
            <span class="caption resGray-darken1--text font-weight-medium">Estimate charge code</span>
            <span class="caption resGray-darken5--text font-weight-medium">{{ cycleInfo.estimateChargeCode }}</span>
          </v-layout>
          <v-layout column>
            <span class="caption resGray-darken1--text font-weight-medium">Estimate frequency</span>
            <span class="caption resGray-darken5--text font-weight-medium">{{ cycleInfo.estimateChargeFrequencyName }}</span>
          </v-layout>
          <v-layout column>
            <span class="caption resGray-darken1--text font-weight-medium">Estimate locked</span>
            <span class="caption resGray-darken5--text font-weight-medium">{{ cycleInfo.estimateIsLocked ? 'YES' : 'NO' }}</span>
          </v-layout>
        </v-layout>
      </v-card>
      <v-layout row pt-4>
        <v-flex xs4>
          <v-radio-group
            class="options mt-0 pt-0"
            v-model="letterType"
            label="Letter Type"
            color="var(--v-resBlue-darken1)">
            <div class="option-values">
              <v-radio
                label="Standard"
                value="standard"
                color="var(--v-resBlue-darken1)">
              </v-radio>
              <v-radio
                label="Custom"
                value="custom"
                color="var(--v-resBlue-darken1)">
              </v-radio>
            </div>
          </v-radio-group>
        </v-flex>
        <drop-down
          label="Form"
          class="mt-0 pr-6">
        </drop-down>
        <date-picker
          label="Reconciliation charge date">
        </date-picker>
      </v-layout>
      <data-table
        v-if="tableData"
        :tableKey="'journalItem'"
        :selectableRows="true"
        :selectedRows="selectedRows"
        :tableData="tableData"
        :headers="tableHeaders">
      </data-table>
    </v-card-text>
  </base-dialog>
</template>

<script>
import DropDown from '../../../BaseComponents/DropDown'
import DatePicker from '../../../BaseComponents/DatePicker'
import DataTable from '../../../BaseComponents/Table'
import BaseDialog from '../../../BaseComponents/BaseDialog'
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    BaseDialog,
    DataTable,
    DropDown,
    DatePicker
  },
  props: ['showDialog', 'cycleInfo'],
  data () {
    return {
      confirm: false,
      letterType: 'standard'
    }
  },
  methods: {
    ...mapActions(['fetchData']),
    cancel () {
      this.$emit('cancelDialog')
    },
    send () {
      this.$emit('save')
    },
    formatDate (val) {
      if (val) {
        return moment(val).format('MM/DD/YYYY')
      } else {
        return '-'
      }
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders']),
    title () {
      return 'Reconciliation Letters'
    },
    saveButtonText () {
      return 'Email Selected'
    },
    saveButtonColor () {
      return 'var(--v-resGreen-base)'
    },
    tableData () {
      return []
    },
    tableHeaders () {
      return []
    },
    selectedRows () {
      return this.tableData.filter(x => {
        return x
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .container-card {
    border: 1px solid var(--v-resGray-lighten1);
  }

  ::v-deep.options {
    display: inline-block;
    label {
      font-size: 12px;
      color: var(--v-resGray-darken5);
    }
  }

  ::v-deep.option-values {
    .v-radio {
      display: inline;
    }
    label {
      display: inline-block !important;
      vertical-align: middle;
      height: 24px !important;
    }
    .v-input--selection-controls__input {
      margin-right: 5px;
    }
  }
</style>
