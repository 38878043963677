<template>
  <base-dialog
    :showDialog="showDialog"
    :title="'Delete Property Group'"
    :maxWidth="750"
    :iconColor="'white'"
    :showTitleIcon="true"
    :smallTitleIcon="true"
    :titleClass="'alert-header'"
    :saveButtonText="'Yes, delete'"
    :saveButtonWidth="100"
    :saveButtonColor="'var(--v-resRed-base)'"
    :cancelButtonColor="'var(--v-resBlue-darken1)'"
    :cancelButtonTextColor="'resBlue-darken1--text'"
    @cancel="cancel"
    @save="save">
    <v-card-text>
    <v-layout row mb-4>
      <v-flex xs12>
        <span class="caption">
          This property group will be deleted and the following properties will be removed from the group if you proceed with this action.
        </span>
      </v-flex>
    </v-layout>
    <v-layout row mb-6>
      <v-flex xs12>
        <span class="caption">
          Do you wish to continue?
        </span>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs12>
        <data-table
          :headers="tableHeaders"
          :tableData="propertiesInGroup"
          :tableKey="'property'">
        </data-table>
      </v-flex>
    </v-layout>
    </v-card-text>
  </base-dialog>
</template>

<script>
import BaseDialog from '../../../BaseComponents/BaseDialog'
import DataTable from '../../../BaseComponents/Table'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BaseDialog,
    DataTable
  },
  props: ['showDialog', 'propertyGroup'],
  data () {
    return {
      propertiesInGroup: [],
      selectedProperty: null,
      selectedPropertiesInGroup: []
    }
  },
  methods: {
    ...mapActions(['fetchData', 'changeData', 'deleteData']),
    async getPropertyGroupProperties () {
      this.fetchData({
        newPage: 'PropertyGroupsDetails',
        params: {
          propertyGroup: this.propertyGroup.propertyGroup
        }
      }).catch(error => { console.log(error.response) })

      this.selectedProperty = null
    },
    cancel () {
      this.$emit('cancel')
    },
    save () {
      this.$emit('delete', this.propertyGroup)
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders']),
    tableHeaders () {
      return this.currentHeaders('PropertyGroupsDetails')
    },
    tableData () {
      return this.currentData('PropertyGroupsDetails')
    },
    existingProperties () {
      return this.tableData.map(p => {
        return p.property.toLowerCase()
      })
    },
    propertiesAreSelected () {
      return this.selectedPropertiesInGroup.length > 0
    },
    buttonText () {
      return this.propertiesAreSelected ? 'Remove Properties from Group' : 'Add Property to Group'
    },
    buttonTextColor () {
      return this.propertiesAreSelected ? 'resRed-base--text' : 'blue--text'
    }
  },
  watch: {
    tableData: function (val) {
      if (val) {
        this.propertiesInGroup = this.tableData.map(d => {
          d.selected = false
          return d
        })
      }
    }
  },
  created () {
    if (this.propertyGroup) {
      this.getPropertyGroupProperties()
    }
  }
}
</script>
