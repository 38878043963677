<template>
  <v-card :height="cardHeight" :flat='flat' class="overview__card" :style="overviewProps">
    <v-layout column fill-height>
      <v-layout row :class="`${removeLeftPad ? 'mt-4': 'ml-4 mt-4'}`">
        <h3 class="mb-0">{{ title }}</h3>
        <a class="title-link" @click="openTitleLink">{{ titleLink }}</a>
        <v-spacer></v-spacer>
        <a v-if="!noEdit" @click="openEdit" class="card-edit mr-6">Edit</a>
      </v-layout>
      <slot></slot>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  props: ['height', 'title', 'titleLink', 'flat', 'width', 'removeLeftPad', 'noEdit'],
  methods: {
    openTitleLink () {
      this.$emit('openTitleLink')
    },
    openEdit () {
      this.$emit('openEdit')
    }
  },
  computed: {
    cardHeight () {
      return this.height
    },
    overviewProps () {
      return {
        '--width': this.width
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  h3 {
    font-weight: 400;
  }
  .overview__card {
    width: var(--width);
  }

  a.title-link {
    text-decoration: underline;
    font-weight: 300;
    margin-left: 5px;
    padding-top: 3px;
  }

  a.card-edit {
    padding-top: 5px;
    text-transform: uppercase;
    font-size: 12px;
  }
</style>
