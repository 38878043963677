<template>
  <div>
    <setup-tabs :tabs="tabs">
      <setup-table></setup-table>
    </setup-tabs>
  </div>
</template>

<script>
import SetupTabs from './SetupTabs'
import SetupTable from './SetupTable'

export default {
  data () {
    return {
      tabs: []
    }
  },
  components: {
    SetupTabs,
    SetupTable
  },
  created () {
    this.initialize()
  },
  methods: {
    initialize () {
      this.tabs = [
        { header: 'Property Types' },
        { header: 'Property Group Types' },
        // { header: 'Owners' },
        { header: 'Area Types' },
        // { header: 'Locations' },
        { header: 'Space Types' },
        // { header: 'Amenities Types' },
        // { header: 'Amenities' },
        { header: 'Area Measures Types', HideAddNew: true },
        { header: 'Insurance Policy Types' }
      ]
    }
  }
}
</script>
