<template>
  <base-dialog
    iconColor="var(--v-resWhite-base)"
    titleClass="dark-header"
    :title="title"
    :saveButtonOnly="true"
    :saveButtonColor="buttonColor"
    :saveButtonText="buttonText"
    :setWidth="1100"
    :maxWidth="1100"
    :showDialog="showDialog"
    @cancel="close"
    @save="close">
    <v-card flat class="container-card mx-4 mt-6">
      <v-row no-gutters>
        <v-col cols="6" class="pl-6 py-2">
          <div class="caption resGray-darken1--text font-weight-medium">Pool</div>
          <div class="caption resGray-darken5--text font-weight-medium">{{ this.leaseInfo.poolName }}</div>
        </v-col>
        <v-col cols="6" class="pl-6 py-2">
          <div class="caption resGray-darken1--text font-weight-medium">Lease</div>
          <div>
            <a title="Go to lease expense recovery settings" @click="goToLeaseExpenseRecovery">{{ this.leaseInfo.leaseName }}</a>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-row no-gutters>
      <v-col cols="12">
        <data-table
          v-if="tableData"
          class="mx-4"
          :selectableRows="false"
          :tableData="tableData"
          :headers="tableHeaders">
        </data-table>
      </v-col>
    </v-row>
  </base-dialog>
</template>

<script>
import DataTable from '../../../BaseComponents/Table'
import BaseDialog from '../../../BaseComponents/BaseDialog'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    BaseDialog,
    DataTable
  },
  props: ['showDialog', 'leaseInfo'],
  data () {
    return {
    }
  },
  methods: {
    ...mapActions(['fetchData', 'changeSelectedItem', 'changeTab']),
    close () {
      this.$emit('close')
    },
    getLeaseExpenseAccountSettings () {
      this.fetchData({
        newPage: 'Lease Expense Accounts',
        params: {
          property: this.leaseInfo.property,
          chargeCode: this.leaseInfo.chargeCode,
          lease: this.leaseInfo.lease,
          propertySpace: this.leaseInfo.propertySpace,
          cycleNumber: this.leaseInfo.cycleNumber
        }
      }).catch(error => { console.log(error.response) })
    },
    goToLeaseExpenseRecovery () {
      this.fetchData({ newPage: 'SingleLease', params: { leaseId: this.leaseInfo.lease } }).then(() => {
        this.close()
        this.changeSelectedItem(this.currentData('SingleLease'))
        this.changeTab({ header: 'ExpenseRecovery', index: 7, route: 'LeasesExpenseRecovery' })
        this.$router.push({ name: 'LeasesExpenseRecovery' })
      }).catch(error => { console.log(error.response) })
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders']),
    title () {
      return 'Lease Expense Account Settings'
    },
    buttonText () {
      return 'Close'
    },
    buttonColor () {
      return 'var(--v-resBlue-darken1)'
    },
    tableData () {
      return this.currentData('Lease Expense Accounts')
    },
    tableHeaders () {
      return this.currentHeaders('Lease Expense Accounts')
    }
  },
  created () {
    this.getLeaseExpenseAccountSettings()
  }
}
</script>

<style lang="scss" scoped>
  .container-card {
    border: 1px solid var(--v-resGray-lighten1);
    border-radius: 0;
  }
  ::v-deep th.column {
    padding: 0px 16px !important;
  }
</style>
