/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'png': {
    width: 16,
    height: 16,
    viewBox: '0 0 56 56',
    data: '<path pid="0" d="M36.985 0H7.963C7.155 0 6.5.655 6.5 1.926V55c0 .345.655 1 1.463 1h40.074c.808 0 1.463-.655 1.463-1V12.978c0-.696-.093-.92-.257-1.085L37.607.257A.884.884 0 0 0 36.985 0z" fill="#e9e9e0"/><path pid="1" d="M48.037 56H7.963A1.463 1.463 0 0 1 6.5 54.537V39h43v15.537c0 .808-.655 1.463-1.463 1.463z" fill="#659c35"/><path pid="2" fill="#d9d7ca" d="M37.5.151V12h11.849z"/><g fill="#fff"><path pid="3" d="M17.385 53h-1.641V42.924h2.898c.428 0 .852.068 1.271.205.419.137.795.342 1.128.615.333.273.602.604.807.991s.308.822.308 1.306c0 .511-.087.973-.26 1.388a2.9 2.9 0 0 1-.725 1.046c-.31.282-.684.501-1.121.656s-.921.232-1.449.232h-1.217V53zm0-8.832v3.992h1.504c.2 0 .398-.034.595-.103.196-.068.376-.18.54-.335.164-.155.296-.371.396-.649.1-.278.15-.622.15-1.032 0-.164-.023-.354-.068-.567a1.637 1.637 0 0 0-.28-.615 1.657 1.657 0 0 0-.595-.492c-.255-.132-.593-.198-1.012-.198h-1.23zM31.316 42.924V53h-1.668l-3.951-6.945V53h-1.668V42.924h1.668l3.951 6.945v-6.945h1.668zM41.16 47.805v3.896c-.21.265-.444.48-.704.649s-.533.308-.82.417-.584.187-.889.233a6.096 6.096 0 0 1-.909.068c-.602 0-1.155-.109-1.661-.328s-.948-.542-1.326-.971c-.378-.429-.675-.966-.889-1.613-.214-.647-.321-1.395-.321-2.242s.107-1.593.321-2.235c.214-.643.51-1.178.889-1.606a3.78 3.78 0 0 1 1.333-.978 4.069 4.069 0 0 1 1.654-.335c.547 0 1.057.091 1.531.273.474.183.897.456 1.271.82l-1.135 1.012a1.951 1.951 0 0 0-.752-.574 2.252 2.252 0 0 0-.875-.178c-.337 0-.659.063-.964.191a2.065 2.065 0 0 0-.82.649c-.242.306-.431.699-.567 1.183s-.21 1.075-.219 1.777c.009.684.08 1.276.212 1.777.132.501.314.911.547 1.23s.497.556.793.711c.296.155.608.232.937.232.1 0 .234-.007.403-.021a4.91 4.91 0 0 0 .506-.068 3.29 3.29 0 0 0 .485-.13.695.695 0 0 0 .342-.232v-2.488h-1.709v-1.121h3.336z"/></g><circle pid="4" cx="18.931" cy="14.431" r="4.569" fill="#f3d55b"/><path pid="5" fill="#88c057" d="M6.5 39h43V28l-10-9.5L29 30l-5.483-5.483z"/>'
  }
})
