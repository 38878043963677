<template>
  <v-container fluid class="resGray lighten-6">
    <month-picker
      class="ml-4 mb-4"
      :errorMessages="errorMessages"
      @dateSelected="selectChargeDate">
    </month-picker>
    <div class="ml-6 mb-4" v-if="chargeErrors">
      <span class="charge-errors">
        <v-icon small>warning</v-icon>
        {{ chargeErrors }}
      </span>
    </div>
    <div class="ml-6">
      <span class="subheading font-weight-bold">
        Selected Property for {{ chargeDate.format('MMM YYYY') }}
      </span>
    </div>
    <div class="ml-6">
      <span>
        Charge date: {{ chargeDate.format('MM/DD/YYYY') }}
      </span>
    </div>
    <v-app-bar flat class="charges-filter">
      <drop-down
        class="ml-2"
        :items="'Groups'"
        :label="'Filter by property group'"
        @itemSelected="selectPropertyGroup">
      </drop-down>
    </v-app-bar>

    <v-row>
      <v-col cols="12" class="mt-3">
        <data-table
          class="mx-6"
          :selectableRows="true"
          :headers="tableHeaders"
          :tableData="dataFormatted"
          :noData="'Please select the properties to calculate charges.'"
          :tableKey="'tableKey'"
          :showEditButtons="true"
          :allowNullColumn="true"
          :customLoadingMessage="'Please wait while charges are posting...'"
          @selectionChanged="updateSelectedProperties"
          @showLeases="showLeasesDialog"
          @undoCharges="undoCharges"
          @previewCharge="openChargesPreview">
        </data-table>
      </v-col>
    </v-row>

    <v-app-bar flat height="36" class="mr-2">
      <v-spacer></v-spacer>
      <span class="subheading">
        ({{ selectedProperties.length }}) Properties selected
      </span>
    </v-app-bar>

    <v-app-bar flat height="36" class="mt-2">
      <v-spacer></v-spacer>
      <round-button
        height='30px'
        buttonText='Post Charges'
        buttonClass='mr-2'
        :depressed="true"
        :buttonTextColor="'var(--v-resWhite-base)'"
        :backgroundColor="'var(--v-resGreen-base)'"
        :disabled="!somethingSelected"
        @click="postCharges">
      </round-button>
    </v-app-bar>
    <!-- Dialogs -->
    <lease-charges-dialog
      v-if="showLeases && selectedProperty"
      :showDialog="showLeases"
      :propertyName="selectedProperty"
      @closeDialog="hideLeases">
    </lease-charges-dialog>
    <confirm-dialog
      :showDialog="showConfirm"
      :tableData="selectedProperties"
      :headers="tableHeaders"
      :chargeDate="chargeDate"
      @closeDialog="hideConfirm"
      @saveDialog="saveConfirm"
      @saveDialogComplete="saveComplete">
    </confirm-dialog>
    <undo-charges-dialog
      :showDialog="showUndo"
      :propertyName="selectedProperty"
      :property="selectedPropertyId"
      :chargeDate="chargeDate"
      @closeUndoDialog="hideUndo"
      @saveUndoDialog="confirmUndo"
      @undoDialogComplete="saveComplete">
    </undo-charges-dialog>
  </v-container>
</template>

<script>
import moment from 'moment'
import DataTable from '../../BaseComponents/Table'
import DropDown from '../../BaseComponents/DropDown'
import RoundButton from '../../BaseComponents/RoundButton'
import MonthPicker from './MonthPicker'
import LeaseChargesDialog from './Dialogs/LeaseCharges'
import ConfirmDialog from './Dialogs/Confirm'
import UndoChargesDialog from './Dialogs/UndoCharges'
import {mapGetters, mapActions} from 'vuex'

export default {
  components: {
    DataTable,
    DropDown,
    RoundButton,
    MonthPicker,
    LeaseChargesDialog,
    ConfirmDialog,
    UndoChargesDialog
  },
  data () {
    return {
      selectedProperty: null,
      selectedPropertyId: null,
      selectedPropertyGroup: null,
      selectedProperties: [],
      chargeDate: this.startOfMonth(),
      showTableData: true,
      properties: 'Properties',
      showLeases: false,
      showConfirm: false,
      showUndo: false,
      errorMessages: []
    }
  },
  methods: {
    ...mapActions(['fetchData', 'changeData', 'deleteData', 'changeLoading', 'initializeStoreItem']),
    selectPropertyGroup (e) {
      if (e && e.value && e.value.trim().toLowerCase() !== 'all') this.selectedPropertyGroup = e.value
      else this.selectedPropertyGroup = null
      this.getAllCharges()
    },
    setChargeDate (e) {
      this.chargeDate = moment(e)
    },
    undoCharges (e) {
      this.selectedProperty = e.propertyName
      this.selectedPropertyId = e.property
      this.getLeases(e.property, e.isCalculated).then(() => {
        this.showUndo = true
      }).catch(error => { console.log(error.response) })
    },
    updateSelectedProperties (e) {
      this.selectedProperties = e
    },
    startOfMonth () {
      let monthStart = moment().add(1, 'months').startOf('Month')
      return monthStart
    },
    selectChargeDate (e) {
      this.initializeStoreItem('Monthly Recurring Charges')
      this.selectedProperties = []
      this.$eventHub.$emit('deselectAll')
      this.chargeDate = e
      this.getAllCharges().then(() => {
        this.updateErrors()
      }).catch(error => { console.log(error.response) })
    },
    showLeasesDialog (e) {
      this.selectedProperty = e.propertyName
      this.getLeases(e.property, e.isCalculated).then(() => {
        this.showLeases = true
      })
    },
    getLeases (propertyId, isCalculated) {
      return this.fetchData({
        newPage: 'Leases With Charges',
        params: {
          PropertyId: propertyId,
          ChargeDate: this.chargeDate.format('MM/DD/YYYY'),
          IsCalculated: isCalculated
        }
      })
    },
    getAllCharges () {
      return this.fetchData({newPage: 'Monthly Recurring Charges', params: { PropertyGroup: this.selectedPropertyGroup, ChargeDate: this.chargeDate.format('MM/DD/YYYY') }})
        .catch(error => { console.log(error.response) })
    },
    hideLeases () {
      this.showLeases = false
      this.selectedProperty = null
    },
    hideConfirm () {
      this.showConfirm = false
    },
    hideUndo () {
      this.showUndo = false
      this.selectedProperty = null
    },
    saveConfirm () {
      this.showTableData = false
      this.changeLoading(true)
      this.showConfirm = false
      this.selectedProperties = []
      this.$eventHub.$emit('deselectAll')
    },
    saveComplete () {
      this.changeLoading(false)
      this.getAllCharges().then(() => {
        this.updateErrors()
        this.showTableData = true
      }).catch(error => { console.log(error.response) })
      this.selectedProperties = []
      this.$eventHub.$emit('deselectAll')
    },
    confirmUndo () {
      this.showTableData = false
      this.changeLoading(true)
      this.showUndo = false
      this.selectedProperties = []
      this.$eventHub.$emit('deselectAll')
    },
    postCharges () {
      if (this.somethingSelected) this.showConfirm = true
    },
    updateErrors () {
      let errorMessage = this.errorMessages.find(e => {
        return e.date === this.chargeDate.format('MM/DD/YYYY')
      })

      if (errorMessage) {
        if (this.propertiesWithUncalculatedCharges.length > 0) {
          errorMessage.message = `(${this.propertiesWithUncalculatedCharges.length}) properties have uncalculated charges`
        } else {
          let i = this.errorMessages.indexOf(errorMessage)
          this.errorMessages.splice(i, 1)
        }
      } else {
        if (this.propertiesWithUncalculatedCharges.length > 0) {
          let error = { date: this.chargeDate.format('MM/DD/YYYY'), message: `(${this.propertiesWithUncalculatedCharges.length}) properties have uncalculated charges` }
          this.errorMessages.push(error)
        }
      }
    },
    openChargesPreview (e) {
      let url = this.reportUrl + `&property=${e.property.trim()}&as_of_date=${this.chargeDate.format('L').trim()}`
      window.open(url, '_blank')
    },
    getToken () {
      this.fetchData({ newPage: 'ReportConnectionToken', params: { } }).catch(error => { console.log(error.response) })
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentSelectedItem', 'currentUser']),
    calculateEnabled () {
      return this.selectedProperty != null &&
      this.selectedProperty.length > 0 &&
      this.chargeDate != null &&
      !this.undoEnabled
    },
    chargeErrors () {
      let error = this.errorMessages.find(e => {
        return e.date === this.chargeDate.format('MM/DD/YYYY')
      })

      if (error) {
        return error.message
      }
      
      return ''
    },
    propertiesWithUncalculatedCharges () {
      return this.tableData.filter(t => {
        return t.postedCharge === null || t.postedCharge === undefined || t.postedCharge === '' || t.postedCharge === 0
      })
    },
    tableData () {
      return this.currentData('Monthly Recurring Charges')
    },
    tableHeaders () {
      return this.currentHeaders('Monthly Recurring Charges')
    },
    undoEnabled () {
      return this.selectedProperties && this.selectedProperties.length > 0
    },
    rentRollReportEnabled () {
      return this.showTableData
    },
    dataFormatted () {
      return this.showTableData ? this.tableData : []
    },
    somethingSelected () {
      return this.selectedProperties.length > 0
    },
    clientQueryString () {
      return `&report_connection_token=${this.connectionToken}`
    },
    reportUrl () {
      const baseURL = process.env.VUE_APP_REPORT_VIEWER_URL
      return `${baseURL}/ViewReport.aspx?report_path=/RMCOMM/PM/Recurring_Charges_Preview_Report${this.clientQueryString}`
    },
    connectionToken () {
      return this.currentData('ReportConnectionToken')
    }
  },
  created () {
    this.getAllCharges()
    this.getToken()
  }
}
</script>

<style lang="scss" scoped>
.charge-errors {
  color: var(--v-resRed-base) !important;
  .theme--light.v-icon {
    color: var(--v-resRed-base) !important;
  }
}
</style>
