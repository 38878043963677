<template>
  <v-container fluid class="resGray lighten-6">
    <v-app-bar flat>
      <date-picker
        class="ml-2 mr-3"
        :label="'As of date'"
        :defaultNull="true"
        :initialDate="selectedDate"
        @dateSelected="updateSelectedDate">
      </date-picker>
      <drop-down
        label="Property Name"
        class="mb-5"
        :selectedValue="{ text: propertyName }"
        :items="'Properties'"
        :multiple='false'
        width="250px"
        @itemSelected="selectProperty">
      </drop-down>
    </v-app-bar>
    <v-row>
      <v-col cols="12">
        <data-table
          :headers="headers"
          :tableData="getData"
          @undoLateFee="openDeleteDialog"
          :noData="'Please use search criteria above to start calculating fees.'"
          class="mx-6">
        </data-table>
      </v-col>
    </v-row>
    <v-app-bar flat height="36">
      <v-spacer></v-spacer>
      <round-button
        height='30px'
        width='111px'
        buttonText='Process Fees'
        buttonClass='mr-2'
        :depressed='true'
        :buttonTextColor="'var(--v-resWhite-base)'"
        :backgroundColor="'var(--v-resGreen-base)'"
        :disabled="!canProcess"
        @click="processLateFees">
      </round-button>
    </v-app-bar>
    <!-- Dialogs -->
    <delete-dialog
      :showDialog="deleteConfirm"
      v-if="deleteConfirm"
      :title="'Are you sure?'"
      confirmButton="Yes, undo fee"
      confirmButtonWidth="108px"
      @confirmDelete="confirmDialog"
      message="Are you sure you want to remove this late fee charge from this tenant’s ledger ?  Select Yes to confirm the late fee removal."
      @cancelDelete="cancelDialog">
    </delete-dialog>
  </v-container>
</template>

<script>
import DropDown from '../../BaseComponents/DropDown'
import RoundButton from '../../BaseComponents/RoundButton'
import DatePicker from '../../BaseComponents/DatePicker'
import DataTable from '../../BaseComponents/Table'
import DeleteDialog from '../../BaseComponents/DeleteDialog'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    DropDown,
    DatePicker,
    RoundButton,
    DataTable,
    DeleteDialog
  },
  data () {
    return {
      property: null,
      propertyName: undefined,
      selectedDate: null,
      deleteConfirm: false,
      currentUndoLateFee: {}
    }
  },
  methods: {
    ...mapActions(['fetchData', 'changeData', 'deleteData', 'initializeStoreItem']),
    updateSelectedDate (val) {
      this.selectedDate = val
      this.getLateFees()
    },
    selectProperty (val) {
      this.propertyName = val.text
      this.property = val.value
      this.getLateFees()
    },
    openDeleteDialog (val) {
      this.deleteConfirm = true
      this.currentUndoLateFee = val
    },
    processLateFees () {
      if (this.canProcess) {
        let updateData = []
        let processItems = this.getData.filter(x => x.hasLateFee === true)
        processItems.forEach(item => {
          let data = {
            property: this.property,
            lease: item.lease,
            chargeDate: item.chargeDate,
            asOfDate: this.selectedDate
          }
          updateData.push(data)
        })
        this.changeData({ currentPage: 'LateFees', newData: { LateFees: updateData } }).then(() => {
          this.fetchData({ newPage: 'LateFees', params: { Property: this.property, AsOfDate: this.selectedDate, ChargeDate: this.selectedDate } })
        }).catch(error => { console.log(error.response) })
      }
    },
    getLateFees () {
      if (this.canProcess) {
        this.fetchData({ newPage: 'LateFees', params: { Property: this.property, AsOfDate: this.selectedDate, ChargeDate: this.selectedDate } }).catch(error => { console.log(error.response) })
      }
    },
    cancelDialog () {
      this.deleteConfirm = false
    },
    confirmDialog () {
      this.deleteData({ currentPage: 'LateFees', removeItem: this.currentUndoLateFee }).then(() => {
        this.fetchData({ newPage: 'LateFees', params: { Property: this.property, AsOfDate: this.selectedDate, ChargeDate: this.selectedDate } }).then(() => {
          this.deleteConfirm = false
          this.currentUndoLateFee = {}
        })
      }).catch(error => { console.log(error.response) })
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders']),
    getData () {
      let items = JSON.parse(JSON.stringify(this.currentData('LateFees')))
      items.forEach(item => {
        if (!item.hasLateFee) {
          item.readonly = false
        }
      })
      return items
    },
    headers () {
      return this.currentHeaders('LateFees')
    },
    canProcess () {
      if (this.selectedDate !== null && this.selectedDate !== undefined && this.property !== null && this.property !== undefined) {
        return true
      }
      return false
    }
  },
  beforeDestroy () {
    this.initializeStoreItem('LateFees')
  }
}
</script>
