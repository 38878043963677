<template>
  <div>
    <v-card height="270">
      <v-row class="ml-1 mt-0">
        <v-col cols="5">
          <v-card class="mt-1">
            <v-carousel
              height="218"
              :cycle="false"
              class="carousel"
              hide-delimiter-background
              :show-arrows="hideActions === false"
              :hide-delimiters="hideActions === true">
              <v-carousel-item
                v-for="(item,i) in contentItems"
                :key="i"
                :src="item.src">
              </v-carousel-item>
            </v-carousel>
          </v-card>
        </v-col>
        <v-col cols="7" class="pt-0">
          <overview-card
            :title="'Property details'"
            :flat='true'
            @openEdit="openPropertyDetailsDialog">
            <v-row class="ml-1">
              <v-col>
                <h4>Category</h4>
                <span>{{ currentInfo.propertyTypeName }}</span>
              </v-col>
              <v-col>
                <h4>Template</h4>
                <span>{{ currentInfo.propertyTemplateName || '' }}</span>
              </v-col>
              <v-col>
                <h4>Closed</h4>
                <span>{{ currentInfo.isClosed ? 'Yes' : 'No' }}</span>
              </v-col>
            </v-row>
            <v-row class="ml-1">
              <v-col>
                <h4>Addresses ({{ addressCount }})
                  <a @click="openAddressesDialog">View all</a>
                </h4>
                <span>{{ computedAddress }}</span>
                <span>{{ computedAddress2 }}</span>
              </v-col>
            </v-row>
            <v-row class="ml-1">
              <v-col>
                <h4>Project</h4>
                <span>{{ currentInfo.project || '' }}</span>
              </v-col>
            </v-row>
          </overview-card>
        </v-col>
      </v-row>
    </v-card>
    <!-- Dialogs -->
    <addresses-dialog
      @cancel="cancel"
      @save="save"
      v-if="addressesDialog"
      :showDialog="addressesDialog">
    </addresses-dialog>
    <property-details
      @cancel="cancelPropertyDetails"
      :editedProperty="editPropertyInfo"
      @save="savePropertyDetails"
      v-if="propertyDetailsDialog"
      :showDialog="propertyDetailsDialog">
    </property-details>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import OverviewCard from '../BaseComponents/OverviewCard'
import AddressesDialog from '../Properties/Dialogs/DetailsCardDialog'
import PropertyDetails from '../Properties/Dialogs/PropertyDetails'

export default {
  components: {
    OverviewCard,
    AddressesDialog,
    PropertyDetails
  },
  data () {
    return {
      addressesDialog: false,
      propertyDetailsDialog: false,
      editPropertyInfo: {},
      images: []
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentSelectedItem']),
    propertyDetails () {
      return this.currentData('LeaseDetails').primaryInfo
    },
    addressCount () {
      return this.currentData('Addresses').length
    },
    currentInfo () {
      return this.currentSelectedItem
    },
    computedAddress () {
      if (this.propertyDetails !== undefined && this.propertyDetails !== null) {
        return `${this.propertyDetails[0].addressLine1 || ''}
        ${this.propertyDetails[0].addressLine2 || ''}`
      }

      return ''
    },
    computedAddress2 () {
      if (this.propertyDetails !== undefined && this.propertyDetails !== null) {
        return `${(this.propertyDetails[0].city || '').trim()}${this.propertyDetails[0].city ? ',' : ''}
        ${this.propertyDetails[0].stateProvinceCode || ''}
        ${this.propertyDetails[0].postalCode || ''}`
      }
      return ''
    },
    contentItems () {
      if (this.images.length > 0) {
        return this.images
      }

      let images = []
      images.push({src: 'https://upload.wikimedia.org/wikipedia/commons/1/15/No_image_available_600_x_450.svg'})
      return images
    },
    hideActions () {
      if (this.images.length > 1) {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    ...mapActions(['changeLoading', 'fetchData', 'changeData']),
    openAddressesDialog () {
      this.addressesDialog = true
    },
    openPropertyDetailsDialog () {
      this.editPropertyInfo = JSON.parse(JSON.stringify(this.currentSelectedItem))
      this.propertyDetailsDialog = true
    },
    cancel () {
      this.addressesDialog = false
    },
    cancelPropertyDetails () {
      this.propertyDetailsDialog = false
    },
    savePropertyDetails () {
      this.propertyDetailsDialog = false
    },
    save () {
      this.addressesDialog = false
      this.fetchData({newPage: 'Addresses', params: { OrganizationId: this.currentSelectedItem.organizationId, ApplicationRole: this.currentSelectedItem.applicationRole }}).catch(error => { console.log(error.response) })
      this.fetchData({newPage: 'LeaseDetails', params: { organizationId: this.currentSelectedItem.organizationId }}).catch(error => { console.log(error.response) })
    }
  },
  created () {
    this.fetchData({newPage: 'Documents', params: { ApplicationRole: 'PROP', PrimaryKey: this.currentSelectedItem.property }}).then(() => {
      this.currentData('Documents').forEach((item) => {
        if (item.fileType.toLowerCase() === 'jpg' || item.fileType.toLowerCase() === 'jpeg') {
          this.fetchData({newPage: 'DocumentsContent', params: { DocumentId: item.documentId }}).then(() => {
            if (this.currentData('DocumentsContent')) {
              this.images.push({src: 'data:image/jpeg;base64,' + this.currentData('DocumentsContent').content})
            }
          })
        }
      })
    }).catch(error => { console.log(error.response) })
    this.fetchData({newPage: 'Addresses', params: { OrganizationId: this.currentSelectedItem.organizationId, ApplicationRole: this.currentSelectedItem.applicationRole }}).catch(error => { console.log(error.response) })
  },
  filters: {
    date: function (value) {
      if (value) {
        return moment(String(value)).format('MM/DD/YYYY')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
h3 {
  font-weight: 500;
}

.carousel {
  width:300px;
  ::v-deep .v-carousel__controls {
    background: transparent;
  }
}

h4 {
  text-transform: uppercase;
  color: var(--v-resGray-darken1);
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 5px;

  a {
    padding-left: 5px;
    text-transform: none;
  }
}
</style>
