<template>
  <div>
    <setup-tabs :tabs="tabs">
      <setup-table></setup-table>
    </setup-tabs>
  </div>
</template>

<script>
import SetupTabs from './SetupTabs'
import SetupTable from './SetupTable'

export default {
  data () {
    return {
      tabs: []
    }
  },
  components: {
    SetupTabs,
    SetupTable
  },
  created () {
    this.initialize()
  },
  methods: {
    initialize () {
      this.tabs = [
        { header: 'Property Templates' },
        { header: 'Charge Code Templates', dialog: 'charge-code-template-dialog', dropdown: 'Property Templates', HideAddNew: true },
        { header: 'Area Measures Templates', dropdown: 'Property Templates', HideAddNew: true }
        // { header: 'Lease Clauses Templates' },
        // { header: 'Evaluation Templates' },
        // { header: 'Amenities Templates' }
      ]
    }
  }
}
</script>
