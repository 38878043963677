<template>
  <base-dialog
    :showDialog="showDialog"
    :title="mode + ' Space'"
    :maxWidth="550"
    @cancel="cancelDialog"
    @save="confirmSpaceDialog">
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="12">
        <text-input
          :value="editedItem.space"
          class=""
          label='Space Name'
          width="200px"
          rules="max:10"
          :disabled="mode === 'Edit'"
          @textInputUpdated="updateSpace">
        </text-input>
        <drop-down
          :selectedValue="{ text: editedItem.spaceTypeName }"
          :items="'Space Types'"
          :label="'Space Type'"
          :multiple='false'
          class="ml-6"
          width="200px"
          @itemSelected="selectSpaceType">
        </drop-down>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <text-input
            :value="editedItem.spaceName"
            class=""
            label='Space Description'
            width="200px"
            @textInputUpdated="updateSpaceName">
          </text-input>
          <date-picker
            :label="'Disposition Date'"
            class="ml-6"
            :defaultNull='true'
            :initialDate="editedItem.offlineUntilDate"
            @dateSelected="setDispositionDate">
          </date-picker>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
        <drop-down
          :selectedValue="{ text: editedItem.buildingName }"
          :items="'Property Buildings'"
          :label="'Building'"
          :multiple='false'
          class=""
          width="200px"
          :defaultFirst='true'
          :getParams="{ PropertyId: currentSelectedItem.property }"
          @itemSelected="selectBuilding">
        </drop-down>
        <drop-down
          :selectedValue="{ text: editedItem.buildingAreaName }"
          :items="'Property Areas'"
          :label="'Area'"
          :multiple='false'
          width="200px"
          :defaultFirst='true'
          class="ml-6"
          :getParams="{ PropertyId: currentSelectedItem.property }"
          @itemSelected="selectArea">
        </drop-down>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-5">
        <v-col cols="12">
          <text-input
            :value="editedItem.marketRent"
            class=""
            label='Market Rate'
            width="120px"
            prefix="$"
            textFormat="currency"
            rules="decimal:2"
            @textInputUpdated="updateMarkRent">
          </text-input>
          <text-input
            :value="editedItem.marketRentPerSpaceArea"
            class="ml-6"
            label='Market Rate per SqFt'
            width="120px"
            :prefix="'$'"
            textFormat="currency"
            rules="decimal:2"
            @textInputUpdated="updateMarkRentSpaceArea">
          </text-input>
        </v-col>
      </v-row>
      <v-row no-gutters class="checkbox__container">
        <v-col cols="12">
          <v-checkbox
            height="20"
            v-model="editedItem.includeIfZeroArea"
            class="shrink ma-0 pa-0"
            color="var(--v-resBlue-darken1)"
            label="Display rate if SqFt is zero">
          </v-checkbox>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <text-area
            height="70"
            :value="editedItem.notes"
            label='Notes'
            @textAreaUpdated="updateNotes">
          </text-area>
        </v-col>
      </v-row>
    </v-card-text>
  </base-dialog>
</template>

<script>
import DropDown from '../../BaseComponents/DropDown'
import TextInput from '../../BaseComponents/TextInput'
import DatePicker from '../../BaseComponents/DatePicker'
import BaseDialog from '../../BaseComponents/BaseDialog'
import TextArea from '../../BaseComponents/TextArea'
import { mapGetters } from 'vuex'

export default {
  props: ['showDialog', 'editedItem', 'mode'],
  components: {
    DropDown,
    TextInput,
    BaseDialog,
    DatePicker,
    TextArea
  },
  data () {
    return {
    }
  },
  methods: {
    cancelDialog () {
      this.$emit('cancel', true)
    },
    updateReference (val) {
      this.editedItem.reference = val
    },
    updateDisplayOrder (val) {
      this.editedItem.displayOrder = val
    },
    selectArea (val) {
      if (val !== null) {
        this.editedItem.buildingAreaName = val.text
        this.editedItem.buildingArea = val.value
      } else {
        this.editedItem.buildingAreaName = null
        this.editedItem.buildingArea = null
      }
    },
    selectBuilding (val) {
      if (val !== null) {
        this.editedItem.buildingName = val.text
        this.editedItem.building = val.value
      } else {
        this.editedItem.buildingName = null
        this.editedItem.building = null
      }
    },
    selectSpaceType (val) {
      if (val !== null) {
        this.editedItem.SpaceTypeName = val.text
        this.editedItem.SpaceType = val.value
      } else {
        this.editedItem.SpaceTypeName = null
        this.editedItem.SpaceType = null
      }
    },
    setDispositionDate (val) {
      this.editedItem.offlineUntilDate = val
    },
    updateSpaceName (val) {
      this.editedItem.spaceName = val
    },
    updateSpace (val) {
      this.editedItem.space = val
    },
    updateMarkRent (val) {
      this.editedItem.marketRent = val
    },
    updateMarkRentSpaceArea (val) {
      this.editedItem.marketRentPerSpaceArea = val
    },
    updateNotes (val) {
      this.editedItem.notes = val
    },
    confirmSpaceDialog () {
      this.$validator.validateAll()
      if (!this.errors.items.length > 0) {
        this.$emit('save', this.editedItem)
      }
    }
  },
  computed: {
    ...mapGetters(['currentSelectedItem'])
  }
}
</script>

<style lang="scss" scoped>
  .checkbox__container {
    ::v-deep label {
      color: var(--v-resBlack-base);
      font-size: 14px;
    }
  }
</style>
