<template>
  <setup-tabs :tabs="tabs">
    <v-container fluid class="resGray lighten-6">
      <v-app-bar flat>
        <drop-down
          class="ml-2"
          :label="'Select Custom Field Type'"
          width="200px"
          :customList="customDropdown"
          :multiple='false'
          @itemSelected="selectedDropdown">
        </drop-down>
        <v-spacer></v-spacer>
        <!-- <round-button
          color='var(--v-resGray-darken3)'
          width='40px'
          height='30px'
          :outline='true'
          :icon='true'
          :depressed='true'
          iconText='get_app'
          backgroundColor='white'
          borderColor='var(--v-resGray-lighten1)'
          buttonClass='mr-2 mt-9'>
        </round-button> -->
        <round-button
          color='var(--v-resGray-darken3)'
          height='30px'
          width='100px'
          :dark='!this.addingNew && !this.editing'
          :depressed='true'
          :disabled='this.addingNew || this.editing'
          buttonText='+ New'
          backgroundColor='var(--v-resGreen-lighten1)'
          borderColor='var(--v-resGray-lighten1)'
          buttonClass='mr-2 mt-9'
          @click="newItem">
        </round-button>
      </v-app-bar>
      <v-row>
        <v-col cols="12" class="mt-3">
          <data-table
            :selectableRows="false"
            :headers="headers"
            :tableData="getData"
            :noData="'No information to display.'"
            :showActions="true"
            @dataChanged="save"
            @canceled="cancel"
            @itemDeleteRequested="deleteItem"
            @unlockEditing='unlockEdit'
            :resetPage="resetPage"
            class="mx-6">
          </data-table>
        </v-col>
      </v-row>
      <!-- Dialogs -->
      <delete-dialog
        :showDialog="isDialog"
        @confirmDelete="confirmDialog"
        @cancelDelete="cancelDialog">
      </delete-dialog>
    </v-container>
  </setup-tabs>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SetupTabs from './SetupTabs'
import DataTable from '../BaseComponents/Table'
import RoundButton from '../BaseComponents/RoundButton'
import DropDown from '../BaseComponents/DropDown'
import DeleteDialog from '../BaseComponents/DeleteDialog'

export default {
  data: () => ({
    pagination: {
      sortBy: null
    },
    previousItem: {},
    tabs: [{ header: 'Custom Fields' }],
    resetPage: false,
    addingNew: false,
    editing: false,
    readonly: false,
    isDialog: false,
    itemHolder: {},
    currentDialog: '',
    showDialog: false,
    dialogItem: {},
    dialogMode: 'Edit',
    customDropdown: [
      { text: 'Leases', value: 'LEAS' },
      { text: 'Properties', value: 'PROP' },
      { text: 'Spaces', value: 'SPAC' }
    ]
  }),
  components: {
    DataTable,
    RoundButton,
    DropDown,
    SetupTabs,
    DeleteDialog
  },
  computed: {
    pages () {
      if (this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      ) return 0
      return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
    },
    ...mapGetters(['currentData', 'currentHeaders', 'currentItem', 'currentPlaceholder', 'currentTab', 'currentLoading']),
    getData: {
      get () {
        return this.currentData('Custom Fields')
      }
    },
    headers: {
      get () {
        return this.currentHeaders('Custom Fields')
      }
    }
  },
  watch: {
    getData () {
      this.$nextTick(() => {
        this.pagination.totalItems = this.getData.length
      })
    }
  },
  methods: {
    ...mapActions(['changeData', 'deleteData', 'fetchData', 'addNew', 'cancelNew']),
    unlockEdit (item) {
      if (!this.currentLoading) {
        // this handles the client side of locking and unlocking editing
        if (this.currentTab && this.currentTab.dialog) {
          this.dialogMode = 'Edit'
          this.dialogItem = JSON.parse(JSON.stringify(item))
          this.currentDialog = this.currentTab.dialog
          this.showDialog = true
        } else {
          if (!this.editing && !this.addingNew) {
            this.editedIndex = this.getData.indexOf(item)
            this.previousValue = Object.assign({}, item)
            item.readonly = false
            this.editing = true
            Object.assign(this.getData[this.editedIndex], item)
          }
        }
      }
    },
    selectedDropdown (val) {
      if (val !== undefined && val !== null) {
        this.dropdownValue = val.value
        this.fetchData({ newPage: 'Custom Fields', params: { Value: val.value } })
      }
    },
    deleteItem (item) {
      if (!this.editing) {
        this.itemHolder = item
        this.isDialog = true
      }
    },
    save (item) {
      // check to see if the item was new, if it was we need to remove the new property
      if (item.new === true) {
        item.readonly = true
        this.addingNew = false
        delete item.new
      }
      this.changeData({ currentPage: 'Custom Fields', newData: item }).then(() => {
        this.fetchData({ newPage: 'Custom Fields', params: { Value: this.dropdownValue } }).then(() => {
          this.editing = false
        })
      }).catch(error => { console.log(error.response) })
    },
    cancel (item) {
      // if we can cancel a new item we want to remove it from the datatable, otherwise we just want to set the value to its previous state before any editing
      if (item.new === true) {
        this.cancelNew({ currentPage: 'Custom Fields' })
        this.addingNew = false
      } else {
        this.editedIndex = this.getData.indexOf(item)
        Object.assign(this.getData[this.editedIndex], this.previousValue)
        this.previousValue = {}
      }
      this.editing = false
      this.isSorted = true
    },
    cancelDialog () {
      this.isDialog = false
    },
    confirmDialog () {
      this.isDialog = false
      this.deleteData({ currentPage: 'Custom Fields', removeItem: this.itemHolder })
      this.snackbar = true
      this.snackbarMsg = 'deleted'
      this.itemHolder = {}
    },
    newItem () {
      // this handle the new item sorting has to be turned off and the item added to the top of the list
      if (!this.addingNew) {
        this.pagination.sortBy = null
        this.addingNew = true
        this.resetPage = !this.resetPage
        var newCustomField = {
          applicationRole: this.dropdownValue,
          fieldTypeDropdown: {
            text: '',
            value: ''
          },
          actionColumn: { add: false, close: false, delete: true, edit: true },
          readonly: false,
          new: true
        }
        this.addNew({ currentPage: 'Custom Fields', index: 0, newItem: newCustomField })
      }
    }
  }
}
</script>
