<template>
  <v-card flat>
    <v-app-bar flat height="40" color='var(--v-resWhite-base)'>
      <div class="caption mt-3 resGray-darken5--text">New Attachment</div>
      <v-spacer></v-spacer>
      <v-btn @click="cancelUpload" icon class="mt-3">
        <v-icon>close</v-icon>
      </v-btn>
    </v-app-bar>

    <div class="d-flex">
      <!-- Waiting for file -->
      <v-card
        width="315"
        flat
        height="260"
        :class="fileHover ? 'drag-drop__card--hover' : 'drag-drop__card--no-file'"
        class="ma-4"
        @drop.prevent="dropFiles"
        @dragstart.prevent
        @drag.prevent
        @dragenter.prevent="hoverChange"
        @dragleave.prevent="hoverChange"
        @dragend.prevent
        @dragover.prevent>
        <input type="file" ref="fileUploadButton" v-show="false" @change="onFileSelection" multiple>
        <v-layout align-center justify-center column fill-height>
          <span class="caption resGray-darken1--text">Drag &amp; drop files here or</span>
          <round-button
            color='var(--v-resGray-darken3)'
            height='30px'
            width='100px'
            :buttonText="'Browse'"
            backgroundColor='var(--v-resBlue-darken1)'
            borderColor='var(--v-resGray-lighten1)'
            buttonClass='mt-1'
            :dark='true'
            :depressed='true'
            @click="$refs.fileUploadButton.click()">
          </round-button>
          <span class="caption resGray-darken1--text">max file size: 25mb</span>
        </v-layout>
      </v-card>
      <!-- File uploading/uploaded -->
      <v-card flat width="220" height="260" class="ma-4 y-scrollable">
        <v-layout v-for="(file, index) of files" :key="index">
          <v-icon class="mb-1 mr-1" x-large>far fa-file</v-icon>
          <v-layout justify-center column>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" class="ml-1 mr-1 file-name">{{ file.fileName }}</span>
              </template>
              <span>{{ file.fileName }}</span>
            </v-tooltip>
            <div class="ml-1 mr-1">
              <span class="mini-text resRed-base--text" v-if="!file.isValidSize">File size too large, max file size: 25mb.</span>
              <span class="mini-text resRed-base--text" v-if="!file.isValidFile">
                File type not supported, supported types include: jpg, jpeg, gif, bmp, png, pdf, xls, xlsx, doc, docx, txt.
              </span>
              <span class="mini-text resRed-base--text" v-if="file.isFileError">
                Error while uploading the file, please try again.
              </span>
            </div>
            <v-card class="mx-1 mt-1" width="150" flat>
              <v-layout>
                <v-progress-linear
                  height="5"
                  width="80"
                  :value="file.progressValue">
                </v-progress-linear>
                <round-button
                  color='var(--v-resGray-lighten1)'
                  class="drag-drop__cancel-icon pl-2 ml-1"
                  height='20px'
                  minHeight='20px'
                  width='20px'
                  minWidth='20px'
                  :icon='true'
                  :flat='true'
                  iconText='delete'
                  :depressed='true'
                  @click="removeFile(index)">
                </round-button>
              </v-layout>
            </v-card>
          </v-layout>
        </v-layout>
      </v-card>
    </div>

    <v-app-bar flat height="60" color='var(--v-resWhite-base)'>
      <v-spacer></v-spacer>
      <round-button
        color='var(--v-resRed-base)'
        height='30px'
        width='80px'
        :depressed='true'
        :outline='true'
        buttonText='Cancel'
        buttonTextColor='var(--v-resRed-base)'
        borderColor='var(--v-resRed-base)'
        buttonClass='mr-3'
        @click="cancelUpload">
      </round-button>
      <round-button
        color='var(--v-resGray-darken3)'
        height='30px'
        width='80px'
        :depressed='true'
        buttonText='Save'
        :disabled="!allowSave"
        :backgroundColor="'var(--v-resGreen-lighten1)'"
        @click="saveUpload">
      </round-button>
    </v-app-bar>
  </v-card>
</template>

<script>
import RoundButton from './RoundButton'

const validFiles = Object.freeze(['jpg', 'gif', 'jpeg', 'bmp', 'png', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'csv', 'txt'])

export default {
  components: {
    RoundButton
  },
  data () {
    return {
      files: [],
      fileHover: false,
      allowSave: false
    }
  },
  methods: {
    cancelUpload () {
      this.resetWorkFlow()
      this.$emit('cancelUpload')
    },
    removeFile (index) {
      this.files.splice(index, 1)
      this.allowSave = this.saveAllowed()
    },
    saveUpload () {
      this.$eventHub.$emit('validate')
      if (this.allowSave) {
        var documents = this.files.map((f) => {
          return {
            fileName: f.fileName,
            title: f.title,
            fileContent: f.fileContent
          }
        })
        this.$emit('complete', documents)
        this.resetWorkFlow()
      }
    },
    resetWorkFlow () {
      this.files = []
    },
    onFileSelection (e) {
      this.prepareFiles(e.target.files)
    },
    dropFiles (e) {
      this.fileHover = false
      this.prepareFiles(e.dataTransfer.files)
    },
    prepareFiles (files) {
      for (const file of files) {
        let item = {}
        item.isValidSize = this.checkFileSize(file.size)
        item.isValidFile = this.checkFileExtension(file.name)
        item.isFileError = false
        item.fileName = file.name
        item.title = file.name
        this.files.push(item)
        this.processFile(item, file)
      }
    },
    async processFile (item, file) {
      item.progressValue = 0

      const reader = new FileReader()

      reader.onload = () => {
        let arrayBuffer = reader.result
        var bytes = new Uint8Array(arrayBuffer)
        item.fileContent = btoa(new Uint8Array(bytes).reduce((data, byte) => {
          return data + String.fromCharCode(byte)
        }, ''))
        this.allowSave = this.saveAllowed()
      }

      reader.onerror = () => {
        item.isFileError = true
      }

      reader.onprogress = (data) => {
        if (data.lengthComputable) {
          item.progressValue = parseInt(((data.loaded / data.total) * 100), 10)
          this.$forceUpdate()
        } else {
          item.progressValue = 100
        }
      }
      reader.readAsArrayBuffer(file)
    },
    checkFileExtension (fileName) {
      let extension = (fileName.slice((fileName.lastIndexOf('.') - 1 >>> 0) + 2)).toLowerCase()
      return validFiles.includes(extension)
    },
    checkFileSize (fileSize) {
      return fileSize < 25000000 && fileSize > 0
    },
    hoverChange () {
      this.fileHover = !this.fileHover
    },
    saveAllowed () {
      if (this.files.length > 0) {
        // check each file
        for (const file of this.files) {
          if (file.progressValue === 100 && file.isValidFile && file.isValidSize && !file.isFileError) {
            continue
          }
          return false // not all files are loaded or valid
        }
        return true // all files are loaded and valid
      }
      return false // no files
    }
  }
}
</script>

<style lang="scss" scoped>
  .drag-drop__card {
    &--no-file {
      border-radius: 6px;
      border: dashed 1px var(--v-resGray-darken2);
    }
    &--hover {
      border-radius: 6px;
      border: solid 1px var(--v-resBlue-darken1);
      background-color: var(--v-resBlue-lighten4);
    }
  }
  .drag-drop__cancel-icon {
    ::v-deep .v-icon {
      font-size:20px;
      margin-bottom: 14px;
    }
  }
  .mini-text {
    font-size: 10px;
  }
  .file-name {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
</style>
