<template>
    <v-container fluid grid-list-md text-left>
      <span class="resGray-darken5--text title ml-6">Please choose from one of the following Transaction options.</span>
      <v-layout wrap>
        <v-flex shrink v-for="card in cards" :key="card.title" class="ml-6">
          <card :data="card" class="mt-4 mr-4 mb-1"></card>
        </v-flex>
      </v-layout>
    </v-container>
</template>

<script>
import Card from '../BaseComponents/Card'
import {mapActions} from 'vuex'

export default {
  data () {
    return {
      cards: [
        { title: 'Charges & Adjustments', description: 'Process all charges and any adjustments needed.', icon: 'attach_money', items: [ 'Monthly Recurring Charges', 'Adjusting Charges & Credits', 'Delete Charges', 'Late Fees' ], route: 'TransactionDetail' },
        { title: 'Receipts & Refunds', description: 'Process any refunds and cash related items.', icon: 'receipt', items: [ 'Cash Receipts', 'Apply Credits', 'Apply Prepayments', 'Void Cash Receipt & NSF' ], /*, 'Refunds' ], */ route: 'ReceiptsDetail' }
        // { title: 'Statements & Emails', description: 'Email invoice statements and send any notifications needed.', icon: 'email', items: [ 'Invoice & Statements', 'Email Notifications' ], route: '' }
      ]
    }
  },
  components: {
    Card
  },
  mounted () {
    this.changeState()
  },
  methods: {
    ...mapActions(['changeSection', 'changeTab', 'changeSelectedItem']),
    changeState () {
      this.changeSection('Transactions')
      this.changeTab()
      this.changeSelectedItem()
    }
  }
}
</script>
