<template>
  <v-container fluid class="resGray lighten-6">
    <v-app-bar flat height="36">
      <v-spacer></v-spacer>
      <round-button
        color='var(--v-resGray-darken3)'
        height='30px'
        width='112px'
        :dark='true'
        :depressed='true'
        :disabled="editing"
        buttonText='Edit Mapping'
        :backgroundColor="'var(--v-resGreen-lighten1)'"
        borderColor='var(--v-resGray-lighten1)'
        buttonClass='mr-2'
        @click="unlockTable">
      </round-button>
    </v-app-bar>
    <v-row>
      <v-col cols="12" class="mt-3">
        <data-table
          class="mx-6"
          :headers="headers"
          :tableData="editing ? editedItems : getData"
          :customDropdown="accounts"
          :noData="'No information to display.'">
        </data-table>
      </v-col>
    </v-row>
    <v-app-bar flat height="36" class="mt-2">
      <v-spacer></v-spacer>
      <round-button
        v-if="editing"
        height='30px'
        width='80px'
        buttonClass="mr-2"
        :buttonText="'Cancel'"
        buttonTextColor='var(--v-resRed-base)'
        :borderColor="'var(--v-resRed-base)'"
        :depressed="true"
        :flat="true"
        :outline="true"
        @click="cancel">
      </round-button>
      <round-button
        v-if="editing"
        height='30px'
        buttonClass='resWhite-base--text mr-2'
        width='80px'
        :depressed='true'
        :flat='true'
        :buttonTextColor="'var(--v-resWhite-base)'"
        :backgroundColor="'var(--v-resBlue-darken1)'"
        :buttonText="'Save'"
        @click="save">
      </round-button>
    </v-app-bar>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DataTable from '../BaseComponents/Table'
import RoundButton from '../BaseComponents/RoundButton'

export default {
  components: {
    DataTable,
    RoundButton
  },
  data () {
    return {
      editing: false,
      editedItems: []
    }
  },
  methods: {
    ...mapActions(['fetchData', 'changeData', 'fetchDropdown', 'changeData']),
    unlockTable () {
      this.editing = true
      this.editedItems = JSON.parse(JSON.stringify(this.currentData('ChargeCodeMaps')))
      this.editedItems.forEach(function (item) {
        item.readonly = false
      })
    },
    cancel () {
      this.editing = false
      var items = this.currentData('ChargeCodeMaps')
      items.forEach(function (item) {
        item.readonly = true
      })
    },
    save () {
      this.changeData({ currentPage: 'ChargeCodeMaps', newData: { Property: this.currentSelectedItem.company, Charges: this.editedItems } }).then(() => {
        this.fetchData({ newPage: 'ChargeCodeMaps', params: { Property: this.currentSelectedItem.property, PropertyTemplate: this.currentSelectedItem.propertyTemplateId } })
      }).catch(error => { console.log(error.response) })
      this.editing = false
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentSelectedItem', 'currentDropdown']),
    getData: {
      get () {
        return this.currentData('ChargeCodeMaps')
      }
    },
    headers: {
      get () {
        return this.currentHeaders('ChargeCodeMaps')
      }
    },
    accounts: {
      get () {
        return this.currentDropdown('Company Accounts')
      }
    }
  },
  created () {
    this.fetchData({ newPage: 'ChargeCodeMaps', params: { Property: this.currentSelectedItem.property, PropertyTemplate: this.currentSelectedItem.propertyTemplateId } }).catch(error => { console.log(error.response) })
    this.fetchDropdown({ newDropdown: 'Company Accounts', params: { Property: this.currentSelectedItem.company } }).catch(error => { console.log(error.response) })
  }
}
</script>

<style lang="scss" scoped>
::v-deep .checkbox__container {
  height: 30px;
}
.space__table {
  ::v-deep .v-datatable {
  span {
    font-size: 12px;
    }
  }
}
</style>
