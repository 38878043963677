const state = {
  steps: [
    'Pool', 'Accounts', 'Spaces'
  ],
  step: 'Pool',
  pool: {},
  spaces: [],
  accounts: [],
  poolCompleted: false,
  accountsCompleted: false,
  spacesCompleted: false,
  selectedPool: {}
}

const getters = {
  currentPool: (state) => state.pool,
  currentPoolSpaces: (state) => state.spaces,
  currentGLAccounts: (state) => state.accounts,
  currentPoolStep: (state) => state.step,
  currentPoolCompleted: (state) => state.poolCompleted,
  currentGLAccountsCompleted: (state) => state.accountsCompleted,
  currentPoolSpacesCompleted: (state) => state.spacesCompleted,
  currentSelectedPool: (state) => state.selectedPool
}

const actions = {
  changePool ({ commit }, newPool) {
    commit('setPool', newPool)
  },
  changePoolSpaces ({ commit }, newPoolSpaces) {
    commit('setPoolSpaces', newPoolSpaces)
  },
  changeGLAccounts ({ commit }, newAccounts) {
    commit('setGLAccounts', newAccounts)
  },
  changePoolCompleted ({ commit }, poolCompleted) {
    commit('setPoolCompleted', poolCompleted)
  },
  changeGLAccountsCompleted ({ commit }, accountsCompleted) {
    commit('setGLAccountsCompleted', accountsCompleted)
  },
  changePoolSpacesCompleted ({ commit }, spacesCompleted) {
    commit('setPoolSpacesCompleted', spacesCompleted)
  },
  changePoolNextStep ({ commit }) {
    commit('setPoolNextStep')
  },
  changePoolPreviousStep ({ commit }) {
    commit('setPoolPreviousStep')
  },
  clearPoolWorkflow ({ commit }) {
    commit('setClearedPoolWorkflow')
  },
  changeSelectedPool ({ commit }, newSelectedPool) {
    commit('setSelectedPool', newSelectedPool)
  }
}

const mutations = {
  setPoolNextStep: (state) => {
    var currentIndex = state.steps.indexOf(state.step)
    if (currentIndex < 2) {
      state.step = state.steps[currentIndex + 1]
    }
  },
  setPoolPreviousStep: (state) => {
    var currentIndex = state.steps.indexOf(state.step)
    if (currentIndex > 0) {
      state.step = state.steps[currentIndex - 1]
    }
  },
  setClearedPoolWorkflow: (state) => {
    state.steps = [
      'Pool', 'Accounts', 'Spaces'
    ]
    state.step = 'Pool'
    state.pool = {}
    state.spaces = []
    state.accounts = []
    state.poolCompleted = false
    state.accountsCompleted = false
    state.spacesCompleted = false
  },
  setPool: (state, newPool) => (state.pool = newPool),
  setPoolSpaces: (state, newSpaces) => (state.spaces = newSpaces),
  setGLAccounts: (state, newAccounts) => (state.accounts = newAccounts),
  setPoolCompleted: (state, poolCompleted) => (state.poolCompleted = poolCompleted),
  setGLAccountsCompleted: (state, glAccountsCompleted) => (state.accountsCompleted = glAccountsCompleted),
  setPoolSpacesCompleted: (state, poolSpacesCompleted) => (state.spacesCompleted = poolSpacesCompleted),
  setSelectedPool: (state, newSelectedPool) => (state.selectedPool = newSelectedPool)
}

export default {
  state,
  getters,
  actions,
  mutations
}
