<template>
  <v-container fluid class="resGray lighten-6">
    <v-app-bar flat>
      <date-picker
        class="ml-2 mr-3"
        :label="'As of date'"
        :defaultNull="true"
        :initialDate="selectedDate"
        @dateSelected="updateSelectedDate">
      </date-picker>
      <drop-down
        label="Property Name"
        class="mb-5"
        :selectedValue="{ text: propertyName }"
        :items="'Properties'"
        :multiple='false'
        width="250px"
        @itemSelected="selectProperty">
      </drop-down>
      <v-spacer></v-spacer>
      <search
        @searchStringUpdated="updateSearchString"
        placeholder='Enter check # or lease name'
        :width="'250px'"
        class="mt-4 mr-2">
      </search>
    </v-app-bar>
    <v-row>
      <v-col cols="12">
        <data-table
          :headers="headers"
          :tableData="getData"
          @VoidCheck="openDeleteDialog"
          @ShowCheckDetails="openDepositDetails"
          :searchString="searchString"
          :noData="'Please use search criteria above for results.'"
          class="mx-6">
        </data-table>
      </v-col>
    </v-row>
    <!-- Dialogs -->
    <delete-dialog
      :showDialog="deleteConfirm"
      v-if="deleteConfirm"
      :title="'Are you sure?'"
      confirmButton="Yes, void check"
      confirmButtonWidth="108px"
      @confirmDelete="confirmDialog"
      message="Please confirm that you would like to void this check. Doing so will reverse all charges this check is applied to."
      @cancelDelete="cancelDialog">
      <v-flex>
        <v-layout>
          <div class="fees-reminder">
            <span>If this is an NSF check, be sure to post NSF fees accordingly in the Adjusting Charges module.</span>
          </div>
        </v-layout>
        <v-layout ml-6>
          <v-flex>
            <div class="caption resGray-darken1--text mb-1">Check date</div>
            <div class="caption">{{ currentCheckToVoid.payerCheckDate }}</div>
          </v-flex>
          <v-flex>
            <div class="caption resGray-darken1--text mb-1">Check number</div>
            <div class="caption">{{ currentCheckToVoid.payerCheckNumber }}</div>
          </v-flex>
          <v-flex mr-6>
            <div class="caption resGray-darken1--text mb-1 text-right">Check total</div>
            <div class="caption text-right">{{ currentCheckToVoid.baseAmount }}</div>
          </v-flex>
        </v-layout>
        <v-layout mt-6>
          <date-picker
            label="Void Date"
            :minDate="minVoidDate"
            :initialDate="voidDate"
            @dateSelected="setVoidDate">
          </date-picker>
          <v-checkbox
            height="18"
            :ripple="false"
            v-model="nonSufficientFunds"
            class="checkbox-label shrink ml-6"
            color="var(--v-resBlue-darken1)"
            label="This is an NSF check">
          </v-checkbox>
        </v-layout>
      </v-flex>
    </delete-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DropDown from '../../../BaseComponents/DropDown'
import DatePicker from '../../../BaseComponents/DatePicker'
import Search from '../../../BaseComponents/Search'
import DataTable from '../../../BaseComponents/Table'
import DeleteDialog from '../../../BaseComponents/DeleteDialog'
import moment from 'moment'
import * as currency from '../../../../utility/currency'
export default {
  props: ['previousProperty', 'previousDate'],
  components: {
    Search,
    DropDown,
    DatePicker,
    DataTable,
    DeleteDialog
  },
  data () {
    return {
      property: this.previousProperty.value,
      propertyName: this.previousProperty.text,
      selectedDate: this.previousDate,
      deleteConfirm: false,
      currentCheckToVoid: {},
      searchString: '',
      nonSufficientFunds: false,
      voidDate: moment().format('L')
    }
  },
  methods: {
    ...mapActions(['fetchData', 'changeData', 'initializeStoreItem']),
    updateSelectedDate (val) {
      this.selectedDate = val
      this.getChecks()
    },
    selectProperty (val) {
      this.propertyName = val.text
      this.property = val.value
      this.getChecks()
    },
    setVoidDate (val) {
      this.voidDate = val
    },
    getChecks () {
      if ((this.selectedDate !== null && this.selectedDate !== undefined) && ((this.property !== null && this.property !== undefined))) {
        this.fetchData({newPage: 'BankDepositDetailsVoid', params: { Property: this.property, AsOfDate: this.selectedDate }}).catch(error => { console.log(error.response) })
      }
    },
    updateSearchString (val) {
      this.searchString = val
    },
    openDepositDetails (val) {
      let property = {
        value: this.property,
        text: this.propertyName
      }
      this.$emit('setProperty', property)
      this.$emit('setDate', this.selectedDate)
      this.$emit('openCheckDetails', val)
    },
    openDeleteDialog (val) {
      val.payerCheckDate = moment(val.payerCheckDate).format('L')
      val.baseAmount = currency.convertToCurrency(val.baseAmount)
      this.currentCheckToVoid = val
      this.deleteConfirm = true
    },
    cancelDialog () {
      this.deleteConfirm = false
    },
    confirmDialog () {
      this.changeData({ currentPage: 'BankDepositDetailsVoid',
        newData: {
          CompanyId: this.currentCheckToVoid.companyId,
          BankAccountCode: this.currentCheckToVoid.bankAccountCode,
          DepositSlipId: this.currentCheckToVoid.depositSlipId,
          BankDepositId: this.currentCheckToVoid.depositId,
          Payer: this.currentCheckToVoid.payer,
          PayerType: this.currentCheckToVoid.payerType,
          PayerCheckNumber: this.currentCheckToVoid.payerCheckNumber,
          PayerCheckDate: this.currentCheckToVoid.payerCheckDate,
          VoidDate: this.voidDate,
          NonSufficientFunds: this.nonSufficientFunds
        } }).then(() => {
        this.fetchData({newPage: 'BankDepositDetailsVoid', params: { Property: this.property, AsOfDate: this.selectedDate }})
        this.deleteConfirm = false
      }).catch(error => { console.log(error.response) })
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders']),
    canSearch () {
      if ((this.selectedDate !== null && this.selectedDate !== undefined) && ((this.property !== null && this.property !== undefined))) {
        return true
      }
      return false
    },
    getData () {
      return this.currentData('BankDepositDetailsVoid')
    },
    headers () {
      return this.currentHeaders('BankDepositDetailsVoid')
    },
    minVoidDate () {
      if (this.currentCheckToVoid !== null) {
        var checkDate = new Date(this.currentCheckToVoid.payerCheckDate)
        var depositDate = new Date(this.currentCheckToVoid.depositDate)
        if (checkDate > depositDate) {
          return checkDate.toISOString().substr(0, 10)
        } else {
          return depositDate.toISOString().substr(0, 10)
        }
      }
      return null
    }
  },
  created () {
    this.fetchData({ newPage: 'BankDepositDetailsVoid' }).catch(error => { console.log(error.response) })
  }
}
</script>

<style lang="scss" scoped>
  .fees-reminder {
    margin: 8px 0px 8px 0px;
    font-weight: bold;
  }

  .checkbox-label {
    height: 30px;
    ::v-deep label {
      color: var(--v-resBlack-base);
      font-size: 14px;
    }
  }
</style>
