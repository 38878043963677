<template>
  <v-card-text class="pa-1">
    <v-row no-gutters>
      <v-col cols="12">
        <drop-down
          :selectedValue="{ text: newPool.propertyName }"
          :items="'Properties'"
          :label="'Property'"
          :multiple='false'
          width="290px"
          rules="required"
          @itemSelected="selectProperty">
        </drop-down>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-5">
      <v-col cols="12">
        <drop-down
          :selectedValue="{ text: newPool.chargeCodeName }"
          :customList="reconChargeCode"
          :label="'Pool'"
          width="164px"
          rules="required"
          :genericLabel="true"
          :multiple='false'
          class="mr-3"
          @itemSelected="selectChargeCode">
        </drop-down>
        <drop-down
          :selectedValue="{ text: newPool.poolFrequencyName }"
          :items="'Recovery Pool Frequencies'"
          :label="'Reconciliation Frequency'"
          width="164px"
          rules="required"
          :genericLabel="true"
          :multiple='false'
          @itemSelected="selectPoolFrequency">
        </drop-down>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-5">
      <v-col cols="12">
        <text-input
          :value="newPool.poolName"
          label='Pool Name'
          width="290px"
          rules="required"
          @textInputUpdated="updatePoolName">
        </text-input>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-5">
      <v-col cols="12">
        <drop-down
          :selectedValue="{ text: newPool.estimateChargeCodeName }"
          :items="'Estimate Charge Codes'"
          :label="'Estimate charge code'"
          width="164px"
          rules="required"
          :genericLabel="true"
          :multiple='false'
          @itemSelected="selectEstimateChargeCode">
        </drop-down>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-5">
      <v-col cols="12">
        <drop-down
          :selectedValue="{ text: newPool.areaMeasureTypeName }"
          :customList="expenseMeasureTypes"
          :label="'Area measure'"
          width="164px"
          rules="required"
          :genericLabel="true"
          :multiple='false'
          :getParams="{ Property: newPool.property }"
          @itemSelected="selectAreaMeasureType">
        </drop-down>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import DropDown from '../../../BaseComponents/DropDown'
import TextInput from '../../../BaseComponents/TextInput'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    DropDown,
    TextInput
  },
  data () {
    return {
      newPool: {
        poolName: ''
      }
    }
  },
  methods: {
    ...mapActions(['changePoolCompleted', 'changePool', 'fetchData', 'fetchDropdown']),
    updatePoolName (val) {
      if (val.length > 0) {
        this.newPool.poolName = val
      } else {
        this.newPool.poolName = null
      }
      this.checkFormCompleted()
    },
    selectProperty (val) {
      if (val !== null && val !== undefined) {
        this.newPool.propertyName = val.text
        this.newPool.property = val.value
        this.fetchDropdown({ newDropdown: 'Expense Measure Types', params: { Property: this.newPool.property } }).catch(error => { console.log(error.response) })
      } else {
        this.newPool.propertyName = null
        this.newPool.property = null
      }
      this.checkFormCompleted()
    },
    selectPoolFrequency (val) {
      if (val !== null && val !== undefined) {
        this.newPool.poolFrequencyName = val.text
        this.newPool.poolFrequency = val.value
      } else {
        this.newPool.poolFrequencyName = null
        this.newPool.poolFrequency = null
      }
      this.checkFormCompleted()
    },
    selectChargeCode (val) {
      if (val !== null && val !== undefined) {
        this.newPool.chargeCodeName = val.text
        this.newPool.chargeCode = val.value
        this.newPool.poolName = this.currentData('Charge Codes').find(x => x.chargeCode === val.value).chargeTypeName
      } else {
        this.newPool.chargeCodeName = null
        this.newPool.chargeCode = null
      }
      this.checkFormCompleted()
    },
    selectEstimateChargeCode (val) {
      if (val !== null && val !== undefined) {
        this.newPool.estimateChargeCodeName = val.text
        this.newPool.estimateChargeCode = val.value
      } else {
        this.newPool.estimateChargeCodeName = null
        this.newPool.estimateChargeCode = null
      }
      this.checkFormCompleted()
    },
    selectAreaMeasureType (val) {
      if (val !== null && val !== undefined) {
        this.newPool.areaMeasureTypeName = val.text
        this.newPool.areaMeasureType = val.value
      } else {
        this.newPool.areaMeasureTypeName = null
        this.newPool.areaMeasureType = null
      }
      this.checkFormCompleted()
    },
    checkFormCompleted () {
      if (this.newPool.areaMeasureType !== null && this.newPool.areaMeasureType !== undefined &&
      this.newPool.chargeCode !== null && this.newPool.chargeCode !== undefined &&
      this.newPool.poolFrequency !== null && this.newPool.poolFrequency !== undefined &&
      this.newPool.property !== null && this.newPool.property !== undefined &&
      this.newPool.poolName !== null && this.newPool.poolName !== undefined) {
        this.changePoolCompleted(true)
        this.changePool(this.newPool)
        return true
      }
      this.changePoolCompleted(false)
      return false
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentDropdown']),
    reconChargeCode () {
      let list = this.currentData('Charge Codes')
      let dropdown = []
      list.forEach(item => {
        if (item.chargeType > 20 && item.chargeType < 30) {
          let newItem = {
            text: item.chargeCode,
            value: item.chargeCode
          }
          dropdown.push(newItem)
        }
      })
      return dropdown
    },
    expenseMeasureTypes () {
      return this.currentDropdown('Expense Measure Types')
    }
  },
  created () {
    this.fetchData({ newPage: 'Charge Codes' }).catch(error => { console.log(error.response) })
  }
}
</script>
