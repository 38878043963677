<template>
  <div>
    <transaction-tabs>
      <router-view></router-view>
    </transaction-tabs>
  </div>
</template>

<script>
import TransactionTabs from './TransactionTabs'

export default {
  data () {
    return {}
  },
  components: {
    TransactionTabs
  }
}
</script>
