<template>
  <div>
    <v-card width="1000" height="90" class="tabs-card">
      <v-layout>
        <v-flex ml-4 mt-2>
          <div class="caption resGray-darken1--text mb-2">Lease name</div>
          <div class="caption resGray-darken5--text font-weight-medium">{{ currentCheck.leaseName }}</div>
        </v-flex>
        <v-flex ml-4 mt-2>
          <div class="caption resGray-darken1--text mb-2">Check date</div>
          <div class="caption resGray-darken5--text font-weight-medium">{{ currentCheck.payerCheckDate | dateFilter(currentCheck.payerCheckDate) }}</div>
        </v-flex>
        <v-flex ml-4 mr-12 mt-2>
          <div class="caption resGray-darken1--text mb-2">Check number</div>
          <div class="caption resGray-darken5--text font-weight-medium">{{ currentCheck.payerCheckNumber }}</div>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex ml-12 mt-2 class="text-right">
          <div class="caption resGray-darken1--text mb-2">Check total</div>
          <div class="subheading resGray-darken5--text font-weight-medium">{{ checkTotal }}</div>
        </v-flex>
        <v-flex mt-2 class="text-right">
          <div class="caption resGray-darken1--text mb-2">Applied total</div>
          <div class="subheading resGray-darken5--text font-weight-medium">{{ appliedCheckTotal }}</div>
          <v-layout class="checkbox__container" justify-end>
            <v-checkbox
              height="20"
              :ripple="false"
              v-model="autoApply"
              :disabled="currentCheck.transactionStatus !== 20"
              class="shrink ma-0 pa-0"
              color="var(--v-resBlue-darken1)"
              @change="applyAutoApply">
            </v-checkbox>
            <span class="caption">Auto apply</span>
          </v-layout>
        </v-flex>
        <v-flex mr-6 mt-2 class="text-right">
          <div class="caption resGray-darken1--text mb-2">Unapplied total</div>
          <div class="subheading font-weight-medium" :class="reconcileCheckColor">{{ unappliedCheckTotal }}</div>
          <v-layout class="checkbox__container" justify-end>
            <v-checkbox
              height="20"
              :disabled="!showPrepay || currentCheck.transactionStatus !== 20"
              :ripple="false"
              v-model="prepayBalance"
              @change="applyPrepay"
              class="shrink ma-0 pa-0"
              color="var(--v-resBlue-darken1)">
            </v-checkbox>
            <span class="caption">Prepay balance</span>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card>
    <v-card flat width="1000">
      <data-table
        class="distribute-table"
        :headers="headers"
        :tableData="getData"
        @applyAmount="applyAmount"
        :noData="'No information to display.'">
      </data-table>
    </v-card>
  </div>
</template>

<script>
import DataTable from '../../../BaseComponents/Table'
import { mapGetters, mapActions } from 'vuex'
import * as currency from '../../../../utility/currency'
import moment from 'moment'

export default {
  props: ['depositDetails', 'active'],
  components: {
    DataTable
  },
  data () {
    return {
      editedItem: {},
      autoApply: false,
      prepayBalance: false,
      currentCheck: this.depositDetails.bankDepositDetails[this.active]
    }
  },
  methods: {
    ...mapActions(['fetchDropdown', 'changeData', 'fetchData', 'addNew', 'cancelNew', 'initializeStoreItem']),
    applyPrepay (val) {
      if (val) {
        let newPrepay = {}
        newPrepay.selectBasePaymentAmount = currency.parseCurrency(this.unappliedCheckTotal)
        newPrepay.baseAmount = currency.parseCurrency(this.unappliedCheckTotal)
        newPrepay.baseOpenAmount = currency.parseCurrency(this.unappliedCheckTotal)
        newPrepay.chargeCode = 'PREPAID'
        newPrepay.chargeCodeName = 'Prepaid'
        newPrepay.leaseCharge = 'P-' + moment().format('YYYY-MM-DD')
        newPrepay.chargeItem = 0
        newPrepay.lease = this.currentCheck.payer
        newPrepay.readonly = true
        newPrepay.applyAmount = { color: 'var(--v-resBlue-darken1)', displayText: 'Apply Amount' }
        this.addNew({ currentPage: 'LeaseOpenCharges', index: 0, newItem: newPrepay })
      } else {
        this.cancelNew({ currentPage: 'LeaseOpenCharges', index: 0 })
      }
    },
    applyAmount (item) {
      var unapplied = currency.parseCurrency(this.unappliedCheckTotal)
      if (item.baseOpenAmount <= unapplied) {
        item.selectBasePaymentAmount = item.baseOpenAmount
      } else if (unapplied > 0) {
        item.selectBasePaymentAmount = unapplied
      }
    },
    applyAutoApply () {
      let autoApplyAmount = currency.parseCurrency(this.checkTotal)
      if (this.autoApply) {
        for (let i = 0; i < this.getData.length; i++) {
          if (this.getData[i].baseOpenAmount > autoApplyAmount && autoApplyAmount > 0) {
            this.getData[i].selectBasePaymentAmount = autoApplyAmount
            autoApplyAmount = 0
          } else if (autoApplyAmount > 0) {
            this.getData[i].selectBasePaymentAmount = this.getData[i].baseOpenAmount
            autoApplyAmount = autoApplyAmount - this.getData[i].selectBasePaymentAmount
          } else {
            this.getData[i].selectBasePaymentAmount = 0 // reset
          }
          this.getData[i].readonly = true
        }
      } else {
        for (let i = 0; i < this.getData.length; i++) {
          if (this.currentCheck.transactionStatus === 20) {
            this.getData[i].selectBasePaymentAmount = 0
            this.getData[i].readonly = false
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters(['currentDropdown', 'currentData', 'currentHeaders']),
    totalCheckReconciled () {
      let imbalanced = this.getData.filter(x => {
        if (Math.abs(x.selectBasePaymentAmount) > Math.abs(x.baseAmount + x.baseTaxAmount)) {
          return true
        } else if (x.baseAmount > 0 && x.selectBasePaymentAmount < 0) {
          return true
        } else if (x.baseAmount < 0 && x.selectBasePaymentAmount > 0) {
          return true
        }
        return false
      })
      if (this.appliedCheckTotal !== this.checkTotal || imbalanced.length > 0) {
        this.$emit('reconciled', false)
        return false
      }
      this.$emit('reconciled', true)
      return true
    },
    appliedCheckTotal () {
      if (this.getData.length > 0) {
        let sum = Number(0)
        this.getData.forEach(function (charge) {
          sum += currency.parseCurrency(charge.selectBasePaymentAmount) // remove excess decimal values
        })
        if (this.currentCheck.transactionStatus === 20) {
          this.$emit('appliedTotalChange', sum)
        }
        return currency.convertToCurrency(sum)
      }
      return currency.convertToCurrency(0)
    },
    unappliedCheckTotal () {
      let remaining = currency.parseCurrency(this.checkTotal) - currency.parseCurrency(this.appliedCheckTotal)
      return currency.convertToCurrency(remaining)
    },
    checkTotal () {
      if (this.currentCheck.baseAmount !== undefined) {
        return currency.convertToCurrency(this.currentCheck.baseAmount)
      }
      return currency.convertToCurrency(0)
    },
    reconcileCheckColor () {
      if (!this.totalCheckReconciled) {
        return 'resRed-base--text'
      } else {
        return 'resGreen-base--text'
      }
    },
    showPrepay () {
      return parseFloat(this.unappliedCheckTotal.toString().replace(/,/g, '')) >= 0 && this.depositDetails.hasPrepaid
    },
    headers: {
      get () {
        return this.currentHeaders('LeaseOpenCharges')
      }
    },
    getData: {
      get () {
        let items = this.currentData('LeaseOpenCharges')
        let check = this.depositDetails.bankDepositDetails[this.active]
        items.forEach(function (item) {
          if (check.transactionStatus !== 20) {
            item.readonly = true
          } else {
            item.readonly = false
          }
          if (item.chargeCode === 'PREPAID') {
            item.readonly = true
          }
        })
        return items
      }
    },
    completeDistribution () {
      return null
    }
  },
  created () {
    this.initializeStoreItem('LeaseOpenCharges')
    this.fetchData({ newPage: 'LeaseOpenCharges', params: { Lease: this.currentCheck.payer, CompanyId: this.depositDetails.companyId, BankAccountCode: this.depositDetails.bankAccountCode, BankDepositId: this.currentCheck.depositId } })
      .catch(error => { console.log(error.response) })
  },
  filters: {
    dateFilter (val) {
      return moment(val).format('L')
    }
  }
}
</script>

<style lang="scss" scoped>
  .checkbox__container {
    height: 30px;
    ::v-deep span {
      padding-top: 6px;
    }
  }

  .distribute-table {
    ::v-deep thead th {
      padding: 0px 10px !important;
    }
    ::v-deep tbody td {
      padding: 0px 10px !important;
    }
  }

  .tabs-card {
    border-top: 2px var(--v-resBlue-base) solid;
    border-right: 1px var(--v-resGray-lighten1) solid;
    border-left: 1px var(--v-resGray-lighten1) solid;
    border-bottom: 1px var(--v-resGray-lighten1) solid;
    border-radius: 0;
  }

  .contact-title--primary {
    font-style: italic;
  }
</style>
