<template>
  <v-container class="resGray lighten-6">
    <v-layout align-center justify-space-between column fill-height>
      <v-card flat width="900" height="240">
        <v-flex>
          <v-layout>
            <v-flex>
              <v-flex mt-4>
                <span class="title ml-6">Adjustment Details</span>
              </v-flex>
              <v-flex mt-6>
                <v-layout>
                  <date-picker
                    :label="'Start Date'"
                    class="ml-6"
                    :disabled="completed"
                    :initialDate="editedItem.postingDate"
                    @dateSelected="setChargeDate">
                  </date-picker>
                  <text-input
                    :value="editedItem.journalReference"
                    label='Description'
                    width="190px"
                    :disabled="completed"
                    class="ml-6"
                    @textInputUpdated="updateDescription">
                  </text-input>
                  <text-input
                    :value="editedItem.baseAmount"
                    label='Amount'
                    width="125px"
                    :prefix="'$'"
                    :disabled="completed"
                    textFormat="currency"
                    rules="decimal:2"
                    class="ml-6"
                    :placeholder="'00.00'"
                    @textInputUpdated="updateAmount">
                  </text-input>
                </v-layout>
              </v-flex>
              <v-flex mt-6>
                <v-layout>
                  <drop-down
                    :selectedValue="{ text: editedItem.propertyName }"
                    :items="'Properties'"
                    :label="'Property'"
                    :multiple='false'
                    width="200px"
                    :disabled="completed"
                    class="ml-6"
                    @itemSelected="selectProperty">
                  </drop-down>
                  <div class="lease-drop-down ml-6" :style="dropdownProps">
                    <label class="lease-drop-down__label d-block mb-0">Lease</label>
                    <v-combobox
                      v-model="editedItem.lease"
                      class="lease-drop-down__combobox ma-0 pa-0"
                      single-line
                      outlined
                      height="30"
                      solo
                      flat
                      dense
                      :disabled="!propertySelected || completed"
                      :items="leases"
                      :filter="filter"
                      append-icon="search"
                      label="Select Lease"
                      :menu-props="{ contentClass: 'lease-drop-down__menu' }"
                      @change="selectLease">
                      <template v-if="true" v-slot:prepend-item>
                        <v-list-item class='lease-drop-down__header'>
                          <v-list-item-content class="">
                            <v-layout class="checkbox__container">
                              <v-checkbox
                                height="20"
                                :ripple="false"
                                v-model="checkBoxValue"
                                @change='includeExpired'
                                class="shrink ma-0 pa-0"
                                color="var(--v-resBlue-darken1)">
                              </v-checkbox>
                              <span class="checkbox-label">Include Expired</span>
                            </v-layout>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="{ item }">
                        <span class="pr-2" v-html="textShortener(item.name)">
                        </span>
                      </template>
                      <template v-slot:item="{ item }">
                        <span class="dot" :style="leaseStatusDot(item)"></span>
                        <span>{{ item.name }}</span>
                      </template>
                    </v-combobox>
                  </div>
                  <drop-down
                    :selectedValue="{ text: editedItem.propertySpaceName }"
                    :customList="leaseSpaces"
                    :label="'Space'"
                    :disabled="!leaseSelected  || completed"
                    :multiple='false'
                    width="200px"
                    class="ml-6"
                    @itemSelected="selectSpace">
                  </drop-down>
                </v-layout>
              </v-flex>
            </v-flex>
            <v-flex>
              <v-layout align-end justify-space-between column fill-height>
                <span class="caption mr-6 mt-4 resGray-darken1--text">Charge total</span>
                <span class="title mb-2 mr-6" :class="showDistribution ? '' : 'resGray-darken1--text'">{{ chargeTotal }}</span>
                <span class="caption resGray-darken1--text mr-6 mt-4">Distribution total</span>
                <span class="title mb-2 mr-6" :class="showDistribution ? '' : 'resGray-darken1--text'">{{ distributedTotal }}</span>
                <span class="caption resGray-darken1--text mr-6 mt-4">Remaining Total</span>
                <span class="title mr-6" :class="reconcileColor">{{ remainingTotal }}</span>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-card>
      <div v-if="showDistribution">
        <v-flex mt-4 mb-2>
          <span class="title ml-6">Distribute Amount</span>
        </v-flex>
        <v-data-table
          :hide-default-header="true"
          hide-default-footer
          class="distribution"
          :headers="headers"
          :items="totalDistributedCharges">
          <template v-slot:header="{ props }">
            <thead>
              <tr>
                <th v-for="header in props.headers" :key="header.value"
                  role="columnheader"
                  scope="col"
                  :style="`width: ${header.width}px`"
                  :class="['column', `text-${header.align}`]">
                  <span class="header__text">
                    {{ header.title }}
                  </span>
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="props">
            <tr>
              <td>
                <drop-down
                  :selectedValue="{ text: props.item.chargeCodeName }"
                  :customList="chargeCodes"
                  :multiple='false'
                  width="125px"
                  v-if="!completed"
                  :hideDetails='true'
                  @itemSelected="selectChargeCode(props.item, $event)">
                </drop-down>
                <span v-if="completed">{{ props.item.chargeCodeName }}</span>
              </td>
              <td class="pt-0-plus">
                <text-input
                  :value="props.item.reference"
                  width="190px"
                  v-if="!completed"
                  :hideDetails='true'
                  @textInputUpdated="updateItemReference(props.item, $event)">
                </text-input>
                <span v-if="completed">{{ props.item.reference }}</span>
              </td>
              <!-- <td>
                <drop-down
                  :selectedValue="{ text: props.item.paymentCompany }"
                  :customList="leaseSpaces"
                  :multiple='false'
                  width="125px"
                  v-if="!completed"
                  :disabled="true"
                  :hideDetails='true'
                  @itemSelected="selectSpace">
                </drop-down>
                <span v-if="completed">{{ props.item.paymentCompany }}</span>
              </td> -->
              <td>
                <drop-down
                  :selectedValue="{ text: props.item.paymentBankAccountName }"
                  :customList="bankAccounts"
                  :multiple='false'
                  width="200px"
                  v-if="!completed"
                  :disabled="false"
                  :hideDetails='true'
                  @itemSelected="selectBankAccount(props.item, $event)">
                </drop-down>
                <span v-if="completed">{{ props.item.paymentBankAccountName }}</span>
              </td>
              <td class="text-center pt-0-plus">
                <text-input
                  :value="props.item.baseAmount"
                  width="100px"
                  :prefix="'$'"
                  v-if="!completed"
                  textFormat="currency"
                  rules="decimal:2"
                  :hideDetails='true'
                  @textInputUpdated="updateItemBaseAmount(props.item, $event)">
                </text-input>
                <span v-if="completed">$ &emsp; &emsp; {{ props.item.baseAmount | currencyFilter(props.item.baseAmount) }}</span>
              </td>
              <td v-if="!completed">
                <v-layout row>
                  <round-button
                    v-if="props.item.Id !== 0"
                    color='var(--v-resRed-base)'
                    height='24px'
                    width='24px'
                    :icon='true'
                    :flat='true'
                    iconText='close'
                    :depressed='true'
                    buttonClass='pa-0 ma-1'
                    @click="removeDistribution(props.item)">
                  </round-button>
                  <v-spacer></v-spacer>
                  <round-button
                    color='var(--v-resBlue-darken1)'
                    height='24px'
                    width='24px'
                    :icon='true'
                    :flat='true'
                    iconText='add_circle'
                    :depressed='true'
                    buttonClass='ma-1 pa-0'
                    @click="addNewDistribution">
                  </round-button>
                </v-layout>
              </td>
            </tr>
          </template>
          <template v-slot:[`body.append`]>
            <tr>
              <td :colspan="headers.length" class="distribution-footer">
                <v-layout>
                  <v-spacer></v-spacer>
                  <label class="">Rows  1 - {{ totalDistributedCharges.length }} ({{ totalDistributedCharges.length }} Total)</label>
                </v-layout>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-layout>
          <v-spacer></v-spacer>
          <round-button
            height='30px'
            width='80px'
            v-if="!completed"
            :buttonText="'Cancel'"
            :buttonTextColor="'var(--v-resRed-base)'"
            :buttonClass="'mt-4 mr-3'"
            :borderColor="'var(--v-resRed-base)'"
            :depressed="true"
            :flat="true"
            :outline="true"
            @click="resetWorkFlow">
          </round-button>
          <round-button
            height='30px'
            buttonClass='resWhite-base--text mt-4'
            width='80px'
            :depressed='true'
            :flat='true'
            :disabled="!totalReconciled"
            v-if="!completed"
            :buttonTextColor="'var(--v-resWhite-base)'"
            :backgroundColor="'var(--v-resGreen-lighten1)'"
            :buttonText="'Save'"
            @click="save">
          </round-button>
          <round-button
            height='30px'
            buttonClass='mt-4'
            width='140px'
            :depressed='true'
            :flat='true'
            v-if="completed"
            :buttonTextColor="'var(--v-resWhite-base)'"
            :backgroundColor="'var(--v-resGreen-lighten1)'"
            :buttonText="'New Adjustment'"
            @click="resetWorkFlow">
          </round-button>
        </v-layout>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import RoundButton from '../../BaseComponents/RoundButton'
import DropDown from '../../BaseComponents/DropDown'
import TextInput from '../../BaseComponents/TextInput'
import DatePicker from '../../BaseComponents/DatePicker'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import * as currency from '../../../utility/currency'

export default {
  components: {
    RoundButton,
    DropDown,
    TextInput,
    DatePicker
  },
  data () {
    return {
      propertySelected: false,
      leaseSelected: false,
      spaceSelected: false,
      completed: false,
      width: '190px',
      totalDistributedCharges: [],
      editedItem: {
        baseAmount: 0.00
      },
      selectedLease: {},
      checkBoxValue: false
    }
  },
  methods: {
    ...mapActions(['fetchDropdown', 'changeData', 'fetchData']),
    setChargeDate (val) {
      this.editedItem.postingDate = val
    },
    updateDescription (val) {
      this.editedItem.journalReference = val
    },
    updateAmount (val) {
      this.editedItem.baseAmount = currency.parseCurrency(val)
    },
    resetWorkFlow () {
      this.propertySelected = false
      this.leaseSelected = false
      this.spaceSelected = false
      this.editedItem = {
        baseAmount: 0.00
      }
      this.completed = false
      this.totalDistributedCharges = []
    },
    resetWorkFlowProperty () {
      this.propertySelected = false
      this.leaseSelected = false
      this.spaceSelected = false
      this.editedItem.property = null
      this.editedItem.propertyName = null
      this.editedItem.propertySpace = null
      this.editedItem.propertySpaceName = null
      this.editedItem.lease = null
      this.totalDistributedCharges = []
    },
    resetWorkFlowLease () {
      this.leaseSelected = false
      this.spaceSelected = false
      this.editedItem.propertySpace = null
      this.editedItem.propertySpaceName = null
      this.editedItem.lease = null
      this.totalDistributedCharges = []
    },
    resetWorkFlowSpace () {
      this.spaceSelected = false
      this.editedItem.propertySpace = null
      this.editedItem.propertySpaceName = null
      this.totalDistributedCharges = []
    },
    selectProperty (val) {
      this.resetWorkFlowProperty()
      if (val !== null) {
        this.editedItem.property = val.value
        this.editedItem.propertyName = val.text
        this.propertySelected = true
        this.checkBoxValue = false
        this.getLeases()
      } else {
        this.editedItem.property = null
        this.editedItem.propertyName = null
        this.propertySelected = false
      }
    },
    selectSpace (val) {
      this.resetWorkFlowSpace()
      if (val !== null) {
        this.editedItem.propertySpace = val.value
        this.editedItem.propertySpaceName = val.text
        this.spaceSelected = true
        this.fetchData({ newPage: 'AdjustCharges', params: { Lease: this.editedItem.lease.lease, Property: this.editedItem.property, PropertySpace: this.editedItem.propertySpace } }).then(() => {
          this.addNewDistribution()
          this.getBankAccounts()
        }).catch(error => { console.log(error.response) })
        this.getChargeCodes()
      } else {
        this.editedItem.propertySpace = null
        this.editedItem.propertySpaceName = null
        this.spaceSelected = false
      }
    },
    selectLease (val) {
      this.resetWorkFlowLease()
      if (val !== null) {
        this.editedItem.lease = val
        this.leaseSelected = true
        this.getLeaseSpaces()
      } else {
        this.editedItem.lease = null
        this.leaseSelected = false
      }
    },
    updateItemBaseAmount (item, val) {
      item.baseAmount = currency.parseCurrency(val)
    },
    updateItemReference (item, val) {
      item.reference = val
    },
    selectChargeCode (item, val) {
      item.chargeCode = val.value
      item.chargeCodeName = val.text
    },
    selectBankAccount (item, val) {
      item.paymentBankAccount = val.value
      item.paymentBankAccountName = val.text
    },
    getLeases () {
      this.fetchData({ newPage: 'Leases', params: { LeaseType: 0, Property: this.editedItem.property, CutOffDate: moment().subtract(6, 'months').format('L') } }).catch(error => { console.log(error.response) })
    },
    getLeaseSpaces () {
      this.fetchDropdown({ newDropdown: 'Lease Spaces', params: { Lease: this.editedItem.lease.lease } }).catch(error => { console.log(error.response) })
    },
    getChargeCodes () {
      this.fetchDropdown({ newDropdown: 'Filtered Charge Codes By Lease', params: { Property: this.editedItem.property, Lease: this.editedItem.lease.lease, PropertySpace: this.editedItem.propertySpace } }).catch(error => { console.log(error.response) })
    },
    getBankAccounts () {
      this.fetchDropdown({ newDropdown: 'Bank Account', params: { Company: this.editedItem.property } }).catch(error => { console.log(error.response) })
    },
    save () {
      if (this.totalDistributedCharges.length > 0) {
        let sendData = JSON.parse(JSON.stringify(this.editedItem))
        sendData.lease = this.editedItem.lease.lease
        sendData.chargeItems = this.totalDistributedCharges
        this.changeData({ currentPage: 'AdjustCharges', newData: sendData }).then(() => {
          this.completed = true
        })
      }
    },
    addNewDistribution () {
      var newCharge = JSON.parse(JSON.stringify(this.leaseItem))
      newCharge.account = null
      newCharge.accountList = null
      newCharge.chargeCode = undefined
      newCharge.chargeCodeName = undefined
      newCharge.reference = null
      newCharge.baseAmount = null
      newCharge.leaseItem = 1
      newCharge.detailItem = 1
      newCharge.lineItem = 0
      newCharge.quantity = 0
      newCharge.Action = ''
      newCharge.Id = this.totalDistributedCharges.length
      this.totalDistributedCharges.unshift(newCharge)
    },
    removeDistribution (val) {
      var index = this.totalDistributedCharges.indexOf(val)
      this.totalDistributedCharges.splice(index, 1)
    },
    dotColor (val) {
      if (val.leaseStatus) {
        if (val.leaseStatus === 'C') {
          return this.$vuetify.theme.themes.light.resGreen.lighten1 // var(--v-resGreen-lighten1)
        } else if (val.leaseStatus === 'M') {
          return this.$vuetify.theme.themes.light.resBlue.darken2 // var(--v-resBlue-darken2)
        } else if (val.leaseStatus === 'F') {
          return this.$vuetify.theme.themes.light.resBlue.base // var(--v-resBlue-base)
        } else if (val.leaseStatus === 'P') {
          return this.$vuetify.theme.themes.light.resRed.base // var(--v-resRed-base)
        } else {
          return 'Unknown'
        }
      }
    },
    leaseStatusDot (val) {
      return {
        '--dotColor': this.dotColor(val)
      }
    },
    filter (item, queryText) {
      const hasValue = val => val != null ? val : ''

      const text = hasValue(item.name)
      const query = hasValue(queryText)

      return text.toString()
        .toLowerCase()
        .indexOf(query.toString().toLowerCase()) > -1
    },
    textShortener (value) {
      if (this.width !== undefined && this.width !== null) {
        let maxChars = Math.floor(this.width.split('p')[0]) / (8 * (Math.pow(this.width.split('p')[0], -0.5) + Math.pow(this.width.split('p')[0], -0.5) + 0.8))
        if (value && value.length > maxChars) {
          return `${value.substr(0, maxChars - 3)}...`
        } else {
          return value
        }
      } else {
        return value
      }
    },
    includeExpired (val) {
      let type = 0
      if (val) {
        type = null
      }
      this.fetchData({ newPage: 'Leases', params: { LeaseType: type, Property: this.editedItem.property } }).catch(error => { console.log(error.response) })
    }
  },
  computed: {
    ...mapGetters(['currentDropdown', 'currentData']),
    totalReconciled () {
      if (this.chargeTotal !== this.distributedTotal) {
        return false
      }
      return true
    },
    distributedTotal () {
      if (this.totalDistributedCharges.length > 0) {
        let sum = Number(0)
        this.totalDistributedCharges.forEach(function (charge) {
          if (charge.baseAmount !== null && charge.baseAmount !== '') {
            sum += currency.parseCurrency(charge.baseAmount)
          }
        })
        return currency.convertToCurrency(sum)
      }
      return currency.convertToCurrency(0)
    },
    remainingTotal () {
      if (this.totalDistributedCharges.length > 0) {
        let remaining = currency.parseCurrency(this.distributedTotal) - currency.parseCurrency(this.chargeTotal)
        return currency.convertToCurrency(remaining)
      }
      return currency.convertToCurrency(0)
    },
    chargeTotal () {
      if (this.editedItem.baseAmount) {
        return currency.convertToCurrency(this.editedItem.baseAmount)
      }
      return currency.convertToCurrency(0)
    },
    reconcileColor () {
      if (!this.showDistribution || this.completed) {
        return 'resGray-darken1--text'
      } else if (!this.totalReconciled) {
        return 'resRed-base--text'
      } else {
        return 'resGreen-base--text'
      }
    },
    showDistribution: {
      get () {
        if (this.propertySelected && this.leaseSelected && this.spaceSelected) {
          return true
        } else {
          return false
        }
      }
    },
    leaseSpaces () {
      return this.currentDropdown('Lease Spaces')
    },
    leases () {
      return this.currentData('Leases')
    },
    chargeCodes () {
      return this.currentDropdown('Filtered Charge Codes By Lease')
    },
    banks () {
      return this.currentDropdown('Banks')
    },
    bankAccounts () {
      return this.currentDropdown('Bank Account')
    },
    leaseItem () {
      return this.currentData('AdjustCharges')
    },
    headers () {
      if (!this.completed) {
        return [
          { title: 'Charge Code', value: 'chargeCode', align: 'left' },
          { title: 'Description', value: 'reference', align: 'left' },
          // { title: 'Receiving Entity', value: 'paymentCompany', align: 'left' },
          { title: 'Payment Bank Account', value: 'paymentBankAccount', align: 'left' },
          { title: 'Amount', value: 'baseAmount', align: 'center' },
          { title: 'Action', value: 'Action', align: 'center' }
        ]
      } else {
        return [
          { title: 'Charge Code', value: 'chargeCode', align: 'left' },
          { title: 'Description', value: 'reference', align: 'left' },
          // { title: 'Receiving Entity', value: 'paymentCompany', align: 'left' },
          { title: 'Payment Bank Account', value: 'paymentBankAccount', align: 'left' },
          { title: 'Amount', value: 'baseAmount', align: 'center' }
        ]
      }
    },
    dropdownProps () {
      return {
        '--width': this.width
      }
    }
  },
  filters: {
    currencyFilter (value) {
      return currency.convertToCurrency(value)
    }
  }
}
</script>

<style lang="scss" scoped>
.distribution {
  width: 900px;
  thead {
    tr {
      height: 35px;
      background-color: var(--v-resGray-lighten3);
      font-size: 12px;
      th {
        padding: 0 14px;
        color: var(--v-resGray-darken5);
      }
    }
  }
  tbody {
    tr {
      height: 35px !important;
      td {
        height: 35px !important;
      }
    }
  }

  ::v-deep .v-text-field.v-text-field--solo .v-input__control {
    min-height: 36px;
  }
}

.dot {
  height: 10px;
  width: 10px;
  background-color: var(--dotColor);
  border-radius: 50%;
  margin-left: 2px;
  margin-right: 2px;
  display: inline-block;
}

.lease-drop-down  {
  display: inline-block;
  min-width: 50px;
  max-width: 340px;
  width: var(--width);
  min-height: 31px;
  max-height: 31px;
  height: 31px;
  ::v-deep .v-text-field--outlined>.v-input__control>.v-input__slot {
    background-color: var(--v-resWhite-base) !important;
    min-height: 31px;
    max-height: 31px;
    height: 31px;
  }
  ::v-deep .v-input__control {
    width: var(--width);
    min-height: 31px;
    max-height: 31px;
    height: 31px;
  }
  ::v-deep .v-input__slot {
    padding-right:0px !important;
    margin-bottom: 0px;
    min-height: 31px;
    max-height: 31px;
    height: 31px;
    span {
      font-size:12px;
      white-space: nowrap;
    }
    ::v-deep .v-select__selections {
      width: calc(var(--width) - 42px);
      min-height: 31px;
      max-height: 31px;
      height: 31px;
    }
  }
  ::v-deep .v-input__append-inner {
    border-radius: 0px 4px 4px 0px;
    padding: 4px 2px 2px 2px;
    margin: 0px;
    .v-icon {
      color: var(--v-resGray-darken3);
      transform: rotate(0);
    }
  }

  ::v-deep .v-text-field__details {
    margin-bottom: 0px;
  }

  ::v-deep .v-text-field--box .v-input__slot,
  ::v-deep .v-text-field--outline .v-input__slot {
    min-height: auto!important;
    display: flex!important;
    align-items: center!important;
    border-radius: 6px!important;
    border: solid 1px var(--v-resGray-darken2) !important;
    text-align: left!important;
    min-height: 31px;
    max-height: 31px;
    height: 31px;
  }
  ::v-deep .v-icon {
    font-size: 20px;
  }
  &__menu {
    .v-select-list {
      ::v-deep .v-list__tile {
        min-height: 31px;
        max-height: 31px;
        height: 31px !important;
      }
      ::v-deep .v-icon {
        font-size: 15px;
      }
    }
    .v-card {
      border-radius: 6px !important;
    }
    .v-select-list {
      ::v-deep .v-list {
        padding-bottom: 0px;
      }
      ::v-deep .v-list__tile {
        font-size: 13px;
      }
      ::v-deep .v-list__tile__action, .v-list__tile__avatar {
        min-width: 32px;
      }
    }
  }
  &__combobox {
    ::v-deep input {
      font-size: 12px;
    }
    ::v-deep .v-label {
      font-size: 12px;
    }
    ::v-deep .v-select__slot {
      display: -webkit-box;
    }
  }
  &__label {
    font-size: 12px;
  }
  &__header {
    border-bottom: 1px solid var(--v-resGray-darken1);
    &.v-list-item {
      height: 30px;
      min-height: 30px !important;
    }
  }
}

::v-deep .checkbox__container {
  height: 31px;
  ::v-deep .v-icon {
    font-size: 18px;
  }
  ::v-deep .v-input__slot {
    width: 20px;
  }
}

span.checkbox-label {
  padding-top: 8px;
}

.v-overflow-btn .v-input__append-inner {
  padding-left: 10px;
  height: 30px!important;
  width: 30px!important;
  margin-top: 0px!important;
}

.distribution-footer {
  height: 40px !important;
  ::v-deep label {
    font-size: 12px;
  }
}
</style>
