<template>
  <imports-template
    ref='template'
    :canImport='canImport'
    :canExport='canExport'
    currentPage='GetImportChargeCodeMappingsData'
    @downloadTemplate='downloadTemplate'
    @clearImportData='clearImportData'
    @saveUpload='saveUpload'
    @addNew='addNewItem'
    @submit='submit'>
    <template v-slot:header>
      <drop-down
        :selectedValue="{ text: selectedPropertyTemplateName }"
        :items="'Property Templates'"
        :label="'Property Template'"
        :multiple='false'
        width="200px"
        hideDetails
        class="ml-2"
        :class="warningBar ? 'mb-4' : ''"
        rules="required"
        @itemSelected="selectPropertyTemplate">
      </drop-down>
    </template>
    <template v-slot:body>
      <warning-bar
        class="mx-6 mb-2"
        message="This will import charge codes for all properties attached to this template."
        @closeWarning="closeWarning"
        :showWarning="warningBar">
      </warning-bar>
      <data-table
        :headers="headers"
        :tableData="getData"
        @itemDeleteRequested="deleteItem"
        @unlockEditing="unlockEdit"
        @dataChanged="save"
        @canceled="cancel"
        class="mx-6"
        :noData="'Please select an import template to upload.'">
      </data-table>
      <v-layout row mt-1 v-if='showWarning'>
        <v-icon small color="var(--v-resRed-base)">warning</v-icon>
        <span class='resRed-base--text'>
            {{ warningMessage }}
        </span>
      </v-layout>
    </template>
  </imports-template>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as fileDownload from '../../../../utility/fileDownloader'
import * as validation from '../../../../utility/validation'
import DataTable from '../../../BaseComponents/Table'
import DropDown from '../../../BaseComponents/DropDown'
import ImportsTemplate from './ImportsTemplate'
import WarningBar from '../../../BaseComponents/WarningBar'
export default {
  components: {
    DataTable,
    DropDown,
    ImportsTemplate,
    WarningBar
  },
  data () {
    return {
      files: {},
      selectedPropertyTemplate: undefined,
      selectedPropertyTemplateName: undefined,
      importDialog: false,
      confirmDialog: false,
      message: '',
      warningBar: true
    }
  },
  methods: {
    ...mapActions(['fetchData', 'changeData', 'initializeStoreItem', 'fetchDropdown', 'addNew']),
    selectPropertyTemplate (val) {
      if (val !== null) {
        this.selectedPropertyTemplate = val.value
        this.selectedPropertyTemplateName = val.text
      } else {
        this.selectedPropertyTemplate = null
        this.selectedPropertyTemplateName = null
      }
    },
    closeWarning () {
      this.warningBar = false
    },
    deleteItem (item) {
      this.$refs.template.deleteItem(item, this.getData)
    },
    unlockEdit (item) {
      this.$refs.template.unlockEdit(item, this.getData)
    },
    cancel (item) {
      this.$refs.template.cancelEdit(item, this.getData)
    },
    save (item) {
      this.$refs.template.save(item)
    },
    addNewItem () {
      this.addNew({
        currentPage: 'GetImportChargeCodeMappingsData',
        newItem: {
          chargeCode: { text: null, value: null },
          account: { text: null, value: null },
          actionColumn: { delete: true, edit: true },
          readonly: false,
          new: true,
          errorToolTip: { hasIcon: false, tooltip: null, value: '' }
        }
      })
    },
    downloadTemplate () {
      this.fetchData({ newPage: 'ImportChargeCodeMappings', params: { PropertyTemplateId: this.selectedPropertyTemplate } }).then(() => {
        let file = this.currentData('ImportChargeCodeMappings')
        let bufferArray = fileDownload.convertArrayBuffer(file.fileContent)
        let fileName = file.fileName.split('.')[0]
        fileName += '-' + this.selectedPropertyTemplateName + '.xlsx'
        fileDownload.createDownloadFile(bufferArray, fileName)
      }).catch(error => { console.log(error.response) })
    },
    saveUpload (item) {
      this.fetchDropdown({ newDropdown: 'Charge Codes' }).catch(error => { console.log(error.response) })
      this.fetchDropdown({ newDropdown: 'Accounts' }).then(() => {
        this.changeData({ currentPage: 'GetImportChargeCodeMappingsData', newData: item, updateItem: {} }).then(() => {
          this.$refs.template.cancel()
        })
      }).catch(error => { console.log(error.response) })
    },
    submit () {
      this.changeData({ currentPage: 'ImportChargeCodeMappings', newData: { PropertyTemplateId: this.selectedPropertyTemplate, ChargeCodeMappings: this.getData } }).then(() => {
        this.$refs.template.cancelImport()
      }).catch(error => { console.log(error.response) })
    },
    clearImportData () {
      this.initializeStoreItem('GetImportChargeCodeMappingsData')
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentDropdown']),
    headers () {
      let items = JSON.parse(JSON.stringify(this.currentHeaders('GetImportChargeCodeMappingsData')))
      let validation = items.splice(items.findIndex(x => x.value === 'errorToolTip'), 1)
      return validation.concat(items)
    },
    rawData () {
      return this.currentData('GetImportChargeCodeMappingsData')
    },
    getData () {
      let items = this.rawData
      let chargeCodes = this.currentDropdown('Charge Codes')
      let chargeCodesLength = chargeCodes.length
      let accounts = this.currentDropdown('Accounts')
      let accountsLength = accounts.length
      items.forEach(item => {
        if (chargeCodesLength > 0 && item.chargeCode && validation.hasValue(item.chargeCode.text)) {
          item.chargeCode = chargeCodes.find(x => {
            if (validation.hasValue(x)) {
              return x.text.toLowerCase() === item.chargeCode.text.toLowerCase()
            }
          })
        }
        if (accountsLength > 0 && item.account && validation.hasValue(item.account.text)) {
          item.account = accounts.find(x => {
            if (validation.hasValue(x)) {
              return x.text.toLowerCase() === item.account.text.toLowerCase()
            }
          })
        }
        let errors = validation.validateValue(item, [
          { value: 'chargeCode', required: true, unique: true },
          { value: 'account', required: true }
        ], items)
        validation.handleErrorToolTip(item, errors)
      })
      return items
    },
    canExport () {
      if (validation.hasValue(this.selectedPropertyTemplate)) {
        return true
      }
      return false
    },
    canImport () {
      if (this.getData.length > 0 && !this.showWarning) {
        return true
      }
      return false
    },
    warningMessage () {
      return this.getData.filter(x => x.errorToolTip.hasIcon === true).length + ' row(s) have errors. Please fix these to continue.'
    },
    showWarning () {
      return this.getData.filter(x => x.errorToolTip.hasIcon === true).length > 0
    }
  },
  beforeDestroy () {
    this.clearImportData()
  }
}
</script>
