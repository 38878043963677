<template>
  <v-container>
    <v-dialog
      v-model="showDialog"
      v-if="showDialog"
      persistent
      width="600">
      <v-app-bar dark height="40" color="var(--v-resBlue-darken1)">
        <span class="caption">Edit Pool</span>
        <v-spacer></v-spacer>
        <v-btn @click="cancelDialog" icon>
          <v-icon>close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card height="570" style="border-radius: 0;">
        <v-card-text class="px-0 pt-0 pb-0">
          <v-stepper alt-labels class="elevation-0" :value="currentPoolStep">
            <v-stepper-header class="elevation-0">
              <v-stepper-step class="pb-0" :complete="currentPoolCompleted" step="Pool">
                Property Pool
              </v-stepper-step>
              <v-divider :class="currentPoolCompleted ? 'complete__divider' : ''"></v-divider>
              <v-stepper-step class="pb-0" :complete="currentGLAccountsCompleted" step="Accounts">
                GL Accounts
              </v-stepper-step>
              <v-divider :class="currentGLAccountsCompleted ? 'complete__divider' : ''"></v-divider>
              <v-stepper-step class="pb-0" :complete="currentPoolSpacesCompleted" step="Spaces">
                Spaces
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content class="pt-0" step="Pool">
                <property-pool :poolInfo="poolInfo"></property-pool>
              </v-stepper-content>
              <v-stepper-content class="pt-0" step="Accounts">
                <gl-accounts></gl-accounts>
              </v-stepper-content>
              <v-stepper-content class="pt-0" step="Spaces">
                <spaces></spaces>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
        <v-card-actions class="px-4 ml-2 pt-0 pb-4">
          <round-button
            color='var(--v-resRed-base)'
            height='30px'
            width='110px'
            :disabled="currentLoading"
            :depressed='true'
            :outline='true'
            buttonText='Delete Pool'
            :buttonTextColor="'var(--v-resRed-base)'"
            :borderColor="'var(--v-resRed-base)'"
            buttonClass='mr-4'
            @click="deletePool">
          </round-button>
          <v-spacer></v-spacer>
          <round-button
            :color='leftButton.color'
            height='30px'
            width='80px'
            :disabled="currentLoading"
            :depressed='true'
            :outline='true'
            v-if="currentPoolStep !== 'Pool'"
            :buttonText='leftButton.text'
            :buttonTextColor="leftButton.color"
            :borderColor="leftButton.color"
            buttonClass='mr-4'
            @click="leftButton.text === 'Cancel' ? cancelDialog() : previousStep()">
          </round-button>
          <round-button
            height='30px'
            :width="currentPoolStep === 'Spaces' ? '115px' : '80px'"
            :disabled="currentLoading || buttonDisabled"
            :depressed='true'
            :outline='true'
            :buttonText='rightButton.text'
            :background-color="rightButton.color"
            @click="nextStep">
          </round-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialogs -->
    <delete-dialog
      :showDialog="deleteDialog"
      @confirmDelete="confirmDelete"
      @cancelDelete="cancelDelete"
      :confirmButtonWidth="'120px'"
      :confirmButton="'Yes, Delete'"
      :message="'Please confirm that would like to delete this pool.'">
    </delete-dialog>
  </v-container>
</template>

<script>
import RoundButton from '../../../BaseComponents/RoundButton'
import PropertyPool from './PropertyPool'
import Spaces from './Spaces'
import GlAccounts from './GLAccounts'
import DeleteDialog from '../../../BaseComponents/DeleteDialog'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    RoundButton,
    PropertyPool,
    Spaces,
    GlAccounts,
    DeleteDialog
  },
  props: ['showDialog', 'poolInfo'],
  data () {
    return {
      deleteDialog: false
    }
  },
  methods: {
    ...mapActions(['changePoolNextStep', 'changePoolPreviousStep', 'clearPoolWorkflow', 'changeData', 'completePoolWorkFlow', 'fetchData', 'changePool', 'deleteData']),
    cancelDialog () {
      this.clearPoolWorkflow()
      this.$emit('cancel')
    },
    completeDialog () {
      this.$emit('complete', this.currentPool)
      this.clearPoolWorkflow()
    },
    nextStep () {
      if (!this.buttonDisabled) {
        if (this.currentPoolStep === 'Pool') {
          this.saveNewPool()
        } else if (this.currentPoolStep === 'Spaces') {
          this.saveSpaces()
        } else {
          this.changePoolNextStep()
        }
      }
    },
    previousStep () {
      this.changePoolPreviousStep()
    },
    saveNewPool () {
      this.changeData({ currentPage: 'Recovery Pools', newData: this.currentPool }).then(() => {
        this.fetchData({ newPage: 'Recovery Pools' }).then(() => {
          let newPool = this.currentPool
          newPool.rowVersion = this.currentData('Recovery Pools').find((x) => {
            if (x.chargeCode === this.currentPool.chargeCode.trim() && x.property === this.currentPool.property.trim()) {
              return true
            }
          }).rowVersion
          this.changePool(newPool)
          this.changePoolNextStep()
        })
      }).catch(error => { console.log(error.response) })
    },
    saveSpaces () {
      this.changeData({currentPage: 'Expense Spaces', newData: { Property: this.currentPool.property, ChargeCode: this.currentPool.chargeCode, SpacesInUse: this.currentPoolSpaces }}).then(() => {
        this.completeDialog()
      }).catch(error => { console.log(error.response) })
    },
    deletePool () {
      this.deleteDialog = true
    },
    confirmDelete () {
      this.deleteData({ currentPage: 'Recovery Pools', removeItem: { Property: this.currentPool.property, ChargeCode: this.currentPool.chargeCode } }).then(() => {
        this.deleteDialog = false
        this.$emit('deletePool')
        this.cancelDialog()
      }).catch(error => { console.log(error.response) })
    },
    cancelDelete () {
      this.deleteDialog = false
    }
  },
  computed: {
    ...mapGetters(['currentLoading', 'currentPoolCompleted', 'currentGLAccountsCompleted', 'currentPoolSpacesCompleted', 'currentPoolStep', 'currentPool', 'currentPoolSpaces', 'currentData']),
    rightButton () {
      if (this.currentPoolStep === 'Pool' || this.currentPoolStep === 'Accounts') {
        return { text: 'Next', color: 'var(--v-resBlue-darken1)' }
      } else {
        return { text: 'Save & Close', color: 'var(--v-resGreen-base)' }
      }
    },
    leftButton () {
      if (this.currentPoolStep === 'Pool') {
        return { text: 'Cancel', color: 'var(--v-resRed-base)' }
      } else {
        return { text: 'Back', color: 'var(--v-resBlue-darken1)' }
      }
    },
    buttonDisabled () {
      if (this.currentPoolStep === 'Pool' && this.currentPoolCompleted) {
        return false
      } else if (this.currentPoolStep === 'Accounts' && this.currentGLAccountsCompleted) {
        return false
      } else if (this.currentPoolStep === 'Spaces' && this.currentPoolSpacesCompleted) {
        return false
      } else {
        return true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-stepper__header {
    width:450px;
    margin: auto;
    hr {
      max-height:5px;
      background-color: var(--v-resGray-lighten4);
      border: 1px var(--v-resGray-lighten2) solid;
      height: 5px;
      margin: 30px -120px 0 !important;
      box-shadow: inset 0 0 0 0 var(--v-resGray-lighten4);
      -webkit-transition: all ease .75s;
      transition: all ease .75s;
    }
    .complete__divider {
      box-shadow: inset 125px 0 0 0 var(--v-resGreen-lighten1);
      -webkit-transition: all ease .75s;
      transition: all ease .75s;
    }
    ::v-deep .v-stepper__step {
      min-width: 0px;
      margin-bottom: 4px;
      flex-basis: 250px;
      &__step {
        min-width: 0px;
        height: 18px;
        width: 18px;
      }
      .v-stepper__step__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) {
        background-color: var(--v-resGray-lighten4);
        color: transparent;
        border: 1px var(--v-resGray-lighten2) solid;
        -webkit-transition: background-color ease .5s;
        transition: background-color ease .5s;
      }
      &--complete {
        .v-stepper__step__step {
          background-color: var(--v-resGreen-lighten1) !important;
          border-color: var(--v-resGreen-lighten1) !important;
          -webkit-transition: background-color ease .5s;
          transition: background-color ease .5s;
          .v-icon {
            font-size: 14px;
          }
        }
      }
      &--active {
        .v-stepper__step__step{
          background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 45%, rgba(131,186,28,1) 50%, rgba(131,186,28,1) 100%);
          background-color: var(--v-resGreen-lighten1) !important;
          border-color: var(--v-resGreen-lighten1) !important;
          -webkit-transition: background-color ease .5s;
          transition: background-color ease .5s;
        }
      }
    }
  }
  .dot {
    height: 10px;
    width: 10px;
    background-color: var(--v-resGreen-lighten1);
    border-radius: 50%;
    margin-left: 2px;
    margin-right: 2px;
    display: inline-block;
  }
</style>
