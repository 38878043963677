<template>
  <v-container fluid>
    <v-layout align-center justify-center column fill-height>
      <span class='title resRed-base--text'>ACCESS DENIED</span>
      <span>You do not have access to this page.</span>
    </v-layout>
  </v-container>
</template>

<script>
export default {
}
</script>
