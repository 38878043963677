<template>
  <base-dialog
    iconColor="var(--v-resWhite-base)"
    titleClass="dark-header"
    saveButtonColor="var(--v-resBlue-darken1)"
    :title="title"
    :saveButtonText="runButtonText"
    :setWidth="350"
    :saveButtonWidth="130"
    :showDialog="showDialog"
    :saveDisabled="!enableRun"
    @cancel="close"
    @save="run">
    <v-card-text>
      <date-picker
        label="Reconciliation Charge Date"
        :defaultNull="true"
        @dateSelected="setChargeDate">
      </date-picker>
    </v-card-text>
  </base-dialog>
</template>

<script>
import DatePicker from '../../../BaseComponents/DatePicker'
import BaseDialog from '../../../BaseComponents/BaseDialog'
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    DatePicker,
    BaseDialog
  },
  props: ['showDialog', 'cycleInfo'],
  data () {
    return {
      chargeDate: null,
      reportName: 'Property_Expense_Reconcile_Charges'
    }
  },
  methods: {
    ...mapActions(['fetchData']),
    close () {
      this.$emit('cancelDialog')
    },
    run () {
      window.open(this.reportUrl, '_blank')
      this.close()
    },
    setChargeDate (e) {
      this.chargeDate = e
    },
    getToken () {
      this.fetchData({ newPage: 'ReportConnectionToken', params: { } }).catch(error => { console.log(error.response) })
    },
    formatDate (val) {
      if (val) {
        return moment(val).format('YYYY-MM-DD')
      } else {
        return ''
      }
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'currentData']),
    title () {
      return 'Reconciliation Reports'
    },
    runButtonText () {
      return 'Run Report'
    },
    enableRun () {
      return !!this.chargeDate
    },
    connectionToken () {
      return this.currentData('ReportConnectionToken')
    },
    connectionQueryParams () {
      return `&report_connection_token=${this.connectionToken}`
    },
    reportQueryParams () {
      return this.cycleInfo ? `&property=${this.cycleInfo.property}&charge_code=${this.cycleInfo.chargeCode}&cycle_nbr=${this.cycleInfo.cycleNumber}&reconcile_charge_date=${this.formatDate(this.chargeDate)}` : ''
    },
    reportUrl () {
      const baseURL = process.env.VUE_APP_REPORT_VIEWER_URL
      return `${baseURL}/ViewReport.aspx?report_path=/RMCOMM/PM/${this.reportName}${this.reportQueryParams}${this.connectionQueryParams}`
    }
  },
  created () {
    this.getToken()
  }
}
</script>
