<template>
  <v-container fluid class="resGray lighten-6">
    <v-app-bar flat height="36">
      <v-spacer></v-spacer>
      <round-button
        color='var(--v-resGray-darken3)'
        height='30px'
        width='143px'
        :depressed='true'
        :buttonText='buttonTitle'
        backgroundColor='var(--v-resBlue-darken1)'
        borderColor='var(--v-resGray-lighten1)'
        buttonClass='mr-3'
        @click="openAttachments">
      </round-button>
      <round-button
        color='var(--v-resGray-darken3)'
        height='30px'
        width='135px'
        :depressed='true'
        buttonText='+ Add Insurance'
        backgroundColor='var(--v-resGreen-lighten1)'
        borderColor='var(--v-resGray-lighten1)'
        buttonClass='mr-2'
        @click="addInsurance">
      </round-button>
    </v-app-bar>
    <v-row>
      <v-col cols="12" class="mt-3">
        <data-table
          class="mx-6"
          :headers="headers"
          :tableData="getData"
          :showActions="true"
          @unlockEditing="unlockEdit"
          @itemDeleteRequested="deleteInsurance">
        </data-table>
      </v-col>
    </v-row>
    <!-- Dialogs -->
    <base-dialog
      :showDialog="isDialog"
      :title="dialogTitle"
      @cancel="cancelDialog"
      @save="confirmDialog"
      v-if="isDialog">
      <v-card-text class="pl-4">
        <v-row no-gutters>
          <v-col cols="12">
            <drop-down
              :selectedValue="{ text: editedItem.insuranceType }"
              :items="'Lease Insurance Types'"
              :label="'Insurance Types'"
              :multiple='false'
              :disabled="this.editedItem.rowVersion"
              width="220px"
              rules="required"
              @itemSelected="selectInsuranceType">
            </drop-down>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-5">
          <v-col cols="12">
            <text-input :value="editedItem.insuranceCompany"
              label='Insurance Company'
              rules="required"
              @textInputUpdated="updateInsuranceCompany">
            </text-input>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-5">
          <v-col cols="12">
            <date-picker
              :label="'Start Date'"
              :defaultNull='true'
              rules="required"
              :initialDate="editedItem.startDate"
              @dateSelected="updateStartDate">
            </date-picker>
            <date-picker
              class="ml-6"
              :label="'Expire Date'"
              :defaultNull="true"
              rules="required"
              :initialDate="editedItem.expireDate"
              @dateSelected="updateExpireDate">
            </date-picker>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-5">
          <v-col cols="12">
            <text-input
              :value="editedItem.description"
              label='Description'
              rules="required"
              @textInputUpdated="updateDescription">
            </text-input>
          </v-col>
        </v-row>
      </v-card-text>
    </base-dialog>
    <attachments
      :showDialog="attachmentsDialog"
      v-if="attachmentsDialog"
      @cancel="closeAttachments">
    </attachments>
  </v-container>
</template>

<script>
import DataTable from '../BaseComponents/Table'
import RoundButton from '../BaseComponents/RoundButton'
import DropDown from '../BaseComponents/DropDown'
import DatePicker from '../BaseComponents/DatePicker'
import TextInput from '../BaseComponents/TextInput'
import BaseDialog from '../BaseComponents/BaseDialog'
import Attachments from './Dialogs/Attachments'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    DataTable,
    RoundButton,
    DropDown,
    DatePicker,
    TextInput,
    BaseDialog,
    Attachments
  },
  data () {
    return {
      attachmentsDialog: false,
      isDialog: false,
      editedIndex: -1,
      editedItem: {
        startDate: null,
        expireDate: null
      }
    }
  },
  methods: {
    ...mapActions(['changeLoading', 'changeData', 'fetchData', 'deleteData']),
    unlockEdit (item) {
      this.editedIndex = this.getData.indexOf(item)
      this.editedItem = JSON.parse(JSON.stringify((this.getData[this.editedIndex], item)))
      this.isDialog = true
    },
    cancelDialog () {
      this.isDialog = false
    },
    confirmDialog () {
      this.$eventHub.$emit('validate')
      setTimeout(() => {
        if (!this.errors.items.length > 0) {
          this.editedItem.leaseId = this.currentSelectedItem.lease
          this.changeData({ currentPage: 'LeaseInsurance', newData: this.editedItem }).then(() => {
            this.fetchData({newPage: 'LeaseInsurance', params: { LeaseId: this.currentSelectedItem.lease }}).then(() => {
              this.isDialog = false
            })
          }).catch(error => { console.log(error.response) })
        }
      }, 100)
    },
    selectInsuranceType (val) {
      if (val !== null) {
        this.editedItem.insuranceType = val.text
        this.editedItem.complianceId = val.value
      } else {
        this.editedItem.insuranceType = null
        this.editedItem.complianceId = null
      }
    },
    updateInsuranceCompany (val) {
      this.editedItem.insuranceCompany = val
    },
    updateDescription (val) {
      this.editedItem.description = val
    },
    updateStartDate (val) {
      this.editedItem.startDate = val
    },
    updateExpireDate (val) {
      this.editedItem.expireDate = val
    },
    addInsurance () {
      this.editedItem = {
        startDate: null,
        expireDate: null
      }
      this.isDialog = true
    },
    deleteInsurance (item) {
      this.deleteData({ currentPage: 'LeaseInsurance', removeItem: item }).catch(error => { console.log(error.response) })
    },
    openAttachments () {
      this.attachmentsDialog = true
    },
    closeAttachments () {
      this.attachmentsDialog = false
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentSelectedItem']),
    getData () {
      return this.currentData('LeaseInsurance')
    },
    headers () {
      return this.currentHeaders('LeaseInsurance') || []
    },
    dialogTitle () {
      return this.editedItem.rowVersion ? 'Edit Insurance' : 'Add Insurance'
    },
    buttonTitle () {
      return 'Attachments (' + (this.currentData('Documents').length || '0') + ')'
    }
  },
  created () {
    this.fetchData({newPage: 'LeaseInsurance', params: { LeaseId: this.currentSelectedItem.lease }}).catch(error => { console.log(error.response) })
    this.fetchData({newPage: 'Documents', params: { ApplicationRole: 'LEAS', PrimaryKey: this.currentSelectedItem.lease }}).catch(error => { console.log(error.response) })
  }
}
</script>
