/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'xls': {
    width: 16,
    height: 16,
    viewBox: '0 0 56 56',
    data: '<path pid="0" d="M36.985 0H7.963C7.155 0 6.5.655 6.5 1.926V55c0 .345.655 1 1.463 1h40.074c.808 0 1.463-.655 1.463-1V12.978c0-.696-.093-.92-.257-1.085L37.607.257A.884.884 0 0 0 36.985 0z" fill="#e9e9e0"/><path pid="1" fill="#d9d7ca" d="M37.5.151V12h11.849z"/><path pid="2" d="M48.037 56H7.963A1.463 1.463 0 0 1 6.5 54.537V39h43v15.537c0 .808-.655 1.463-1.463 1.463z" fill="#91cda0"/><g fill="#fff"><path pid="3" d="M20.379 48.105L22.936 53h-1.9l-1.6-3.801h-.137L17.576 53h-1.9l2.557-4.895-2.721-5.182h1.873l1.777 4.102h.137l1.928-4.102H23.1l-2.721 5.182zM27.037 42.924v8.832h4.635V53h-6.303V42.924h1.668zM39.041 50.238c0 .364-.075.718-.226 1.06s-.362.642-.635.902-.611.467-1.012.622a3.771 3.771 0 0 1-1.367.232c-.219 0-.444-.012-.677-.034s-.467-.062-.704-.116a3.686 3.686 0 0 1-.677-.226 2.17 2.17 0 0 1-.554-.349l.287-1.176c.127.073.289.144.485.212s.398.132.608.191c.209.06.419.107.629.144.209.036.405.055.588.055.556 0 .982-.13 1.278-.39.296-.26.444-.645.444-1.155 0-.31-.105-.574-.314-.793-.21-.219-.472-.417-.786-.595s-.654-.355-1.019-.533a6.173 6.173 0 0 1-1.025-.629 3.281 3.281 0 0 1-.793-.854c-.21-.328-.314-.738-.314-1.23 0-.446.082-.843.246-1.189s.385-.641.663-.882.602-.426.971-.554.759-.191 1.169-.191c.419 0 .843.039 1.271.116.428.077.774.203 1.039.376a13.31 13.31 0 0 1-.191.39l-.205.396c-.064.123-.119.226-.164.308a1.263 1.263 0 0 1-.082.137c-.055-.027-.116-.063-.185-.109s-.167-.091-.294-.137a2.097 2.097 0 0 0-.506-.096 4.781 4.781 0 0 0-.807.014c-.183.019-.355.07-.52.157s-.31.193-.438.321a1.464 1.464 0 0 0-.301.431 1.1 1.1 0 0 0-.109.458c0 .364.104.658.314.882.209.224.469.419.779.588.31.169.647.333 1.012.492.364.159.704.354 1.019.581s.576.513.786.854c.212.342.317.781.317 1.319z"/></g><path pid="4" d="M23.5 16v-4h-12v22h33V16h-21zm-10-2h8v2h-8v-2zm0 4h8v2h-8v-2zm0 4h8v2h-8v-2zm0 4h8v2h-8v-2zm8 6h-8v-2h8v2zm21 0h-19v-2h19v2zm0-4h-19v-2h19v2zm0-4h-19v-2h19v2zm-19-4v-2h19v2h-19z" fill="#c8bdb8"/>'
  }
})
