<template>
  <v-container fluid class="resGray lighten-6">
    <v-row>
      <v-col cols="12" class="mt-3">
        <data-table
          class="mx-6"
          :headers="headers"
          :tableData="getData"
          :showActions="true"
          :noData="'No expense pools or cycles have been setup for this lease.'"
          @unlockEditing="openLeaseExpenseAccountSettings"
          @goToPropertyExpenseCycles="goToPropertyExpenseCycles">
        </data-table>
      </v-col>
    </v-row>
    <!-- Dialogs -->
    <lease-expense-account-settings-dialog
      v-if="showLeaseExpenseAccountSettingsDialog && selectedPoolInfo"
      :showDialog="showLeaseExpenseAccountSettingsDialog"
      :leaseInfo="selectedPoolInfo"
      @cancel="closeLeaseExpenseAccountSettings"
      @save="closeLeaseExpenseAccountSettings">
    </lease-expense-account-settings-dialog>
  </v-container>
</template>

<script>
import DataTable from '../BaseComponents/Table'
import LeaseExpenseAccountSettingsDialog from './Dialogs/LeaseExpenseAccountSettingsDialog'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    DataTable,
    LeaseExpenseAccountSettingsDialog
  },
  data () {
    return {
      selectedPoolInfo: null,
      showLeaseExpenseAccountSettingsDialog: false
    }
  },
  methods: {
    ...mapActions(['changeLoading', 'fetchData', 'changeTab', 'changeSelectedItem', 'initializeStoreItem']),
    openLeaseExpenseAccountSettings (e) {
      this.selectedPoolInfo = {
        poolName: e.poolName,
        chargeCode: e.chargeCode,
        property: e.property,
        lease: e.lease,
        leaseName: e.leaseName,
        propertySpace: e.propertySpace,
        cycleNumber: e.currentCycleNumber
      }
      this.showLeaseExpenseAccountSettingsDialog = true
    },
    closeLeaseExpenseAccountSettings () {
      this.showLeaseExpenseAccountSettingsDialog = false
    },
    goToPropertyExpenseCycles () {
      this.changeSelectedItem()
      this.changeTab({ header: 'PoolsCycles', index: 0, route: 'Pools Cycles' })
      this.$router.push({ name: 'Pools Cycles' })
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentSelectedItem']),
    getData () {
      return this.currentData('LeaseExpensePools')
    },
    headers () {
      return this.currentHeaders('LeaseExpensePools')
    }
  },
  created () {
    this.initializeStoreItem('LeaseExpensePools')
    this.fetchData({newPage: 'LeaseExpensePools', params: { Property: this.currentSelectedItem.property, Lease: this.currentSelectedItem.lease }}).catch(error => { console.log(error.response) })
  }
}
</script>
