const state = {
  currentUser: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
}

const getters = {
  currentUser: (state) => state.currentUser ? state.currentUser : '',
  currentUserName: (state) => state.currentUser ? state.currentUser.profile.name : '',
  currentUserDisplayName: (state) => state.currentUser ? state.currentUser.profile.display_name : '',
  currentUserEmail: (state) => state.currentUser ? state.currentUser.profile.email : '',
  currentUserIsAdmin: (state) => state.currentUser ? state.currentUser.profile.is_admin.toLowerCase() === 'true' : false,
  currentUserIsSupport: (state) => state.currentUser ? state.currentUser.profile.role === 'internal-management-user' : false
}

const actions = {
  changeUser ({ commit }, user) {
    commit('setUser', user)
  }
}

const mutations = {
  setUser: (state, user) => {
    state.currentUser = user
    const parsed = JSON.stringify(user)
    localStorage.setItem('user', parsed)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
