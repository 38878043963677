<template>
  <base-dialog
    :title="titleMessage"
    :showDialog="showDialog"
    :smallTitleIcon="true"
    :saveButtonText="saveButton"
    :saveButtonColor="saveButtonColor"
    :saveButtonWidth="confirmButtonWidth"
    :cancelButtonTextColor="'resBlue-darken1--text'"
    :cancelButtonColor="'var(--v-resBlue-darken1)'"
    :cancelButtonText="'Cancel'"
    :cancelButtonWidth="'100'"
    :iconColor="'white'"
    :showTitleIcon="true"
    :titleClass="'alert-header'"
    @cancel="cancelDelete"
    @save="confirmDelete">
    <v-card-text>
      <span>{{ dialogMessage }}</span>
      <slot></slot>
    </v-card-text>
  </base-dialog>
</template>

<script>
import BaseDialog from './BaseDialog'

export default {
  components: {
    BaseDialog
  },
  props: ['showDialog', 'message', 'title', 'confirmButton', 'confirmButtonWidth'],
  data () {
    return {
      saveButtonColor: 'var(--v-resRed-base)'
    }
  },
  methods: {
    cancelDelete () {
      this.$emit('cancelDelete', true)
    },
    confirmDelete () {
      this.$emit('confirmDelete', true)
    }
  },
  computed: {
    dialogMessage () {
      if (this.message !== undefined && this.message !== null) {
        return this.message
      } else {
        return 'Please confirm that you would like to delete this row. Completing this action will permanently delete this item. You can always add it again by clicking on "New".'
      }
    },
    titleMessage () {
      if (this.title !== undefined && this.title !== null) {
        return this.title
      } else {
        return 'Are you sure you want to delete?'
      }
    },
    saveButton () {
      if (this.confirmButton !== undefined && this.confirmButton !== null) {
        return this.confirmButton
      } else {
        return 'Confirm'
      }
    }
  }
}
</script>
