<template>
  <v-card-text class="pa-1">
    <h3>Select the new property to setup.</h3>
    <v-row no-gutters class="pt-2">
      <v-col>
        <drop-down
          :selectedValue="{ text: property.propertyName }"
          :items="'Properties'"
          :label="'Property'"
          :multiple='false'
          width="200px"
          rules="required"
          :getParams="{ IncludeIncomplete: true, IncompleteOnly: true }"
          @itemSelected="selectProperty">
        </drop-down>
        <v-tooltip top nudge-right="20" max-width=400>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" class="material-icons-outlined resBlue-darken1--text">info</v-icon>
          </template>
          <span>New properties are commercial properties that have been entered in the ResMan Core application, but have not been completely setup in ResMan Commercial (i.e. the properties still need to configure spaces, charge code mappings, etc...)</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <div class="pt-8">
      <span>Enter the property information below to start.</span>
    </div>
    <v-row no-gutters class="mt-1">
      <v-col>
        <drop-down
          :selectedValue="{ text: property.propertyTypeName }"
          :items="'Property Types'"
          :label="'Property Type'"
          width="150px"
          rules="required"
          :genericLabel="true"
          :multiple='false'
          class="mb-6"
          @itemSelected="selectPropertyType">
        </drop-down>
        <drop-down
          :selectedValue="{ text: property.propertyTemplateName }"
          :items="'Property Templates'"
          :label="'Template'"
          width="150px"
          rules="required"
          :genericLabel="true"
          :multiple='false'
          class="mb-6 ml-6"
          @itemSelected="selectPropertyTemplate">
        </drop-down>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-4">
      <v-col>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <text-input
              v-on="on"
              :value="property.buildingName"
              label='Building Name'
              width="240px"
              rules="required|max:50"
              @textInputUpdated="updateBuildingName">
            </text-input>
          </template>
          <span>Recommended character amount is less than 20.</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-4">
      <v-col>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <text-input
              v-on="on"
              :value="property.areaName"
              class="mb-2"
              label='Area Name'
              rules="required|max:50"
              width="240px"
              @textInputUpdated="updateAreaName">
            </text-input>
            </template>
          <span>Recommended character amount is less than 20.</span>
        </v-tooltip>
        <drop-down
          :selectedValue="{ text: property.areaTypeName }"
          :items="'Area Types'"
          :label="'Area Type'"
          :multiple='false'
          rules="required"
          width="150px"
          class="mb-6 ml-4"
          @itemSelected="selectAreaType">
        </drop-down>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import DropDown from '../../BaseComponents/DropDown'
import TextInput from '../../BaseComponents/TextInput'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    DropDown,
    TextInput
  },
  data () {
    return {
      property: {
        buildingName: 'Building',
        areaName: 'Area'
      },
      show: false
    }
  },
  computed: {
    ...mapGetters(['currentPropertyReview'])
  },
  methods: {
    ...mapActions(['changeProperty', 'changePropertyReview']),
    selectProperty (val) {
      this.property.propertyName = val.text
      this.property.property = val.value
      this.changeProperty(this.property)
      if (this.currentPropertyReview) {
        this.changePropertyReview(false)
      }
    },
    selectPropertyType (val) {
      this.property.propertyTypeName = val.text
      this.property.propertyType = val.value
      this.changeProperty(this.property)
      if (this.currentPropertyReview) {
        this.changePropertyReview(false)
      }
    },
    selectPropertyTemplate (val) {
      this.property.propertyTemplateName = val.text
      this.property.propertyTemplate = val.value
      this.changeProperty(this.property)
      if (this.currentPropertyReview) {
        this.changePropertyReview(false)
      }
    },
    updateBuildingName (val) {
      this.property.buildingName = val
      this.changeProperty(this.property)
    },
    updateAreaName (val) {
      this.property.areaName = val
      this.changeProperty(this.property)
    },
    selectAreaType (val) {
      this.property.areaTypeName = val.text
      this.property.areaType = val.value
      this.changeProperty(this.property)
      if (this.currentPropertyReview) {
        this.changePropertyReview(false)
      }
    }
  }
}
</script>
