<template>
  <recovery-tabs>
    <router-view></router-view>
  </recovery-tabs>
</template>

<script>
import {mapActions} from 'vuex'
import RecoveryTabs from '../Recoveries/RecoveryTabs'

export default {
  components: {
    RecoveryTabs
  },
  data () {
    return {}
  },
  mounted () {
    this.changeState()
  },
  methods: {
    ...mapActions(['changeSection', 'changeTab', 'changeSelectedItem']),
    changeState () {
      this.changeSection('Recoveries')
      this.changeSelectedItem()
    }
  }
}
</script>
