<template>
  <div class="text-area">
    <label class="text-area__label d-block mb-1">{{ label }}</label>
    <v-textarea
      flat
      solo
      full-width
      auto-grow
      :height="height"
      :value="value"
      :placeholder="placeholder"
      @input="updateTextArea">
    </v-textarea>
  </div>
</template>

<script>
export default {
  props: ['placeholder', 'label', 'value', 'height'],
  data () {
    return {
    }
  },
  methods: {
    updateTextArea (val) {
      this.$emit('textAreaUpdated', val)
    }
  }
}
</script>

<style lang="scss" scoped>
  .text-area {
    ::v-deep .v-input {
      font-size: 12px;
      margin-top: 0px;

      &__icon {
        height: 20px;
      }
      &--is-focused {
        .v-input__slot {
          border: solid 1px var(--v-resBlue-darken1) !important;
        }
      }
    }
    ::v-deep .v-input__slot {
      border: 1px solid gray !important;
    }
  }
  .text-area__label {
    font-size: 12px;
  }
</style>
