<template>
  <v-container fluid class="resGray lighten-6">
    <v-app-bar flat height="36">
      <v-spacer></v-spacer>
      <!-- <round-button
        color='var(--v-resGray-darken3)'
        width='40px'
        height='30px'
        :outline='true'
        :icon='true'
        :depressed='true'
        iconText='delete_outline'
        backgroundColor='white'
        borderColor='var(--v-resGray-lighten1)'
        buttonClass='ml-4 mt-0'>
      </round-button> -->
      <!-- <round-button
        color='var(--v-resBlue-darken1)'
        height='30px'
        width='125px'
        :outline='true'
        :depressed='true'
        buttonText='Print Overview'
        buttonTextColor='var(--v-resBlue-darken1)'
        backgroundColor='white'
        borderColor='var(--v-resBlue-darken1)'
        buttonClass='mt-0'>
      </round-button> -->
      <round-button
        color='var(--v-resGray-darken3)'
        height='30px'
        width='110px'
        dark
        :depressed='true'
        buttonText='Parameters'
        backgroundColor='var(--v-resBlue-darken1)'
        borderColor='var(--v-resGray-lighten1)'
        buttonClass='mr-3'
        @click="parameters">
      </round-button>
      <round-button
        color='var(--v-resGray-darken3)'
        height='30px'
        width='143px'
        dark
        :depressed='true'
        :buttonText='buttonTitle'
        backgroundColor='var(--v-resBlue-darken1)'
        borderColor='var(--v-resGray-lighten1)'
        buttonClass='mr-2'
        @click="openAttachments">
      </round-button>
    </v-app-bar>

    <v-row class="mx-3 mt-1">
      <v-col cols="6">
        <property-details></property-details>
      </v-col>
      <v-col cols="6">
        <property-contacts></property-contacts>
      </v-col>
    </v-row>

    <v-app-bar flat height="36" class="mt-4">
      <toggle-button-group
        class="ml-5"
        :buttons="toggleButtons"
        @toggled="changeChart">
      </toggle-button-group>
    </v-app-bar>

    <v-row class="mx-3">
      <v-col cols="12" class="mt-2">
        <stacking-plan v-if='showStackingPlan'></stacking-plan>
        <space-availability v-if='showSpaceAvailability'></space-availability>
      </v-col>
    </v-row>

    <v-row row class="mx-3 mt-1">
      <v-col cols="12">
        <notes-card></notes-card>
      </v-col>
    </v-row>
    <!-- Dialogs -->
    <attachments
      :showDialog="attachmentsDialog"
      v-if="attachmentsDialog"
      @cancel="closeAttachments">
    </attachments>
    <parameters
      v-if='parametersDialog'
      :showDialog="parametersDialog"
      @saveComplete='closeParameters'
      @cancel='closeParameters'>
    </parameters>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import RoundButton from '../BaseComponents/RoundButton'
import PropertyDetails from './DetailsCard'
import PropertyContacts from './ContactsCard'
import NotesCard from './NotesCard'
import Attachments from './Dialogs/Attachments'
import StackingPlan from './StackingPlan'
import Parameters from './Dialogs/Parameters'
import ToggleButtonGroup from '../BaseComponents/ToggleButtonGroup'
import SpaceAvailability from './SpaceAvailability'

export default {
  components: {
    RoundButton,
    NotesCard,
    PropertyDetails,
    PropertyContacts,
    Attachments,
    StackingPlan,
    Parameters,
    ToggleButtonGroup,
    SpaceAvailability
  },
  data () {
    return {
      dialogOpen: false,
      attachmentsDialog: false,
      parametersDialog: false,
      toggleButtons: [
        { text: 'Stacking Plan' },
        { text: 'Space Availability' }
      ],
      showStackingPlan: true,
      showSpaceAvailability: false
    }
  },
  created () {
    this.fetchData({newPage: 'LeaseDetails', params: { organizationId: this.currentSelectedItem.organizationId }}).catch(error => { console.log(error.response) })
  },
  methods: {
    ...mapActions(['changeLoading', 'fetchData']),
    openDialog () {
      this.dialogOpen = true
    },
    closeDialog () {
      this.dialogOpen = false
    },
    openAttachments () {
      this.attachmentsDialog = true
    },
    closeAttachments () {
      this.attachmentsDialog = false
    },
    parameters () {
      this.parametersDialog = true
    },
    closeParameters () {
      this.parametersDialog = false
    },
    changeChart () {
      this.showStackingPlan = !this.showStackingPlan
      this.showSpaceAvailability = !this.showSpaceAvailability
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentSelectedItem']),
    buttonTitle () {
      return 'Attachments (' + (this.currentData('Documents').length || '0') + ')'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
