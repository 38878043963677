/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'down': {
    width: 10,
    height: 5,
    viewBox: '0 0 10 5',
    data: '<path pid="0" d="M-.125 0l5 5 5-5h-10z"/>'
  }
})
