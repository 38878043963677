<template>
  <v-hover>
    <v-card
      slot-scope="{ hover }"
      height="121"
      width="153"
      class="pools-card"
      :dark="isSelected"
      :class="[isSelected ? 'pools-card__selected': '', newCard ? 'pools-card__new-card' : '', newCard && hover ? 'pools-card__new-card--hover elevation-3' : '', !newCard && hover ? 'elevation-3' : '',]"
      flat>
      <v-row @click="cardClicked">
        <v-col cols="12">
          <v-card-text class="text-center">
            <v-icon v-if="newCard" :color="hover ? 'var(--v-resBlue-darken1)' : 'var(--v-resGray-darken4)'" large>add</v-icon>
            <div>
              <span
                :class="[newCard ? hover ? 'resBlue-darken1--text' : 'resGray-darken4--text' : isSelected ? 'resWhite-base--text' : 'resBlue-darken1--text']"
                class="subheading text-center font-weight-medium">
                  {{ cardText }}
              </span>
            </div>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  props: ['content', 'isSelected', 'newCard'],
  data () {
    return {
    }
  },
  methods: {
    cardClicked () {
      this.$emit('cardClicked')
    }
  },
  computed: {
    cardText () {
      if (this.newCard) {
        return 'NEW PROPERTY POOL'
      } else {
        return this.content
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .pools-card {
    border-radius: 6px;
    border: solid 1px var(--v-resGray-lighten1);
    &__selected {
      border: solid 4px var(--v-resBlue-lighten2);
      background-color: var(--v-resBlue-darken1);
    }
    &__new-card {
      border: dashed 2px var(--v-resGray-lighten1);
      &--hover {
        border: dashed 2px var(--v-resBlue-darken1);
      }
    }
  }
</style>
