<template>
<div>
  <v-hover>
    <v-card height="176" flat width="280" class="cycles-card" slot-scope="{ hover }" :class="hover ? 'elevation-3 cycles-card--hover' : ''">
      <v-container grid-list-xs class="pa-2">
        <v-layout>
          <v-flex v-if="image" :class="image ? 'xs6' : ''">
            <v-img
              height="119"
              width="135"
              class="ma-0"
              :src="image">
            </v-img>
          </v-flex>
          <v-flex :class="image ? 'xs6' : 'xs6'">
            <v-flex pt-2>
              <div class="caption resGray-darken1--text font-weight-medium">Property</div>
              <div class="caption resGray-darken5--text font-weight-medium">{{ content.propertyName }}</div>
            </v-flex>
            <v-flex>
              <div class="caption resGray-darken1--text font-weight-medium">Pool</div>
              <div class="caption resGray-darken5--text font-weight-medium">{{ content.poolName }}</div>
            </v-flex>
            <v-flex>
              <v-layout>
                <v-flex xs6>
                  <div class="caption resGray-darken1--text font-weight-medium">Current</div>
                  <div class="caption resGray-darken5--text font-weight-medium">{{ content.current || '-' }}</div>
                </v-flex>
                <v-flex xs6>
                  <div class="caption resGray-darken1--text font-weight-medium">Cycles</div>
                  <div class="caption font-weight-medium" :class="content.cycles === 0 ? 'resRed-base--text' : 'resGray-darken5--text'">{{ content.cycles }}</div>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </v-hover>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: ['content'],
  data () {
    return {
      image: null
    }
  },
  methods: {
    ...mapActions(['fetchData'])
  },
  computed: {
    ...mapGetters(['currentData'])
  },
  created () {
    this.fetchData({newPage: 'Documents', params: { ApplicationRole: 'PROP', PrimaryKey: this.content.property }}).then(() => {
      this.currentData('Documents').forEach((item) => {
        if (item.fileType.toLowerCase() === 'jpg' || item.fileType.toLowerCase() === 'jpeg') {
          this.fetchData({newPage: 'DocumentsContent', params: { DocumentId: item.documentId }}).then(() => {
            if (this.currentData('DocumentsContent')) {
              this.image = 'data:image/jpeg;base64,' + this.currentData('DocumentsContent').content
            }
          })
        }
      })
    }).catch(error => { console.log(error.response) })
  }
}
</script>

<style lang="scss" scoped>
  .cycles-card {
    border-radius: 6px;
    border: solid 1px var(--v-resGray-lighten1);
    &--hover {
      border: solid 1px var(--v-resBlue-darken1);
    }
  }
</style>
