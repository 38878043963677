<template>
  <div class="stacking-block text-center" :style="blockStyle" @click="sendToLeaseOverview">
    <v-hover>
      <v-card
        hover
        class="mr-1 block-card"
        slot-scope="{ hover }"
        height="80px"
        :color="`var(--v-${color})`">
        <v-card-text class="resWhite-base--text pt-1" :class="`${textColor}--text`">
          <h4>{{ space }}</h4>
          <span v-if="showSqft && status !== 'Occupied'">{{ formattedArea }}</span>
          <span v-else-if="showSqft && status === 'Occupied'" class='font-italic'>Occupied</span>
          <span v-else>{{ leaseName || '-'}}</span>
        </v-card-text>
        <v-expand-transition v-if='transition'>
          <div
            v-if="hover"
            class="v-card--reveal resWhite-base--text transition-fast-in-fast-out">
            <v-layout
              align-end
              row
              fill-height>
              <v-flex text-left mx-6 pl-1>
                <span>
                  {{ formattedArea }}
                </span>
              </v-flex>
              <v-flex text-right mx-6 pr-1>
                <span>
                  {{ leaseExpiring }}
                </span>
              </v-flex>
            </v-layout>
          </div>
        </v-expand-transition>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: ['space', 'leaseName', 'leaseId', 'width', 'color', 'area', 'expireDate', 'showSqft', 'transition', 'textColor', 'status'],
  methods: {
    ...mapActions(['fetchData', 'changeSelectedItem', 'changeTab']),
    sendToLeaseOverview () {
      if (this.leaseId) {
        this.fetchData({newPage: 'SingleLease', params: { LeaseId: this.leaseId }}).then(() => {
          this.changeSelectedItem(this.currentData('SingleLease'))
          this.changeTab({ header: 'Overview', index: 0, route: 'LeasesOverview' })
          this.$router.push({ name: 'LeasesOverview' })
        }).catch(error => { console.log(error.response) })
      }
    }
  },
  computed: {
    ...mapGetters(['currentData']),
    blockStyle () {
      return {
        '--width': this.width + '%'
      }
    },
    roundedWidth () {
      return Math.round(this.width)
    },
    formattedArea () {
      return this.area ? `${this.area.toLocaleString(undefined, { 'minimumFractionDigits': 0, 'maximumFractionDigits': 2 })} SqFt` : ''
    },
    leaseExpiring () {
      return this.expireDate ? `Expiring ${moment(this.expireDate).format('MM/DD/YYYY')}` : ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .stacking-block {
    display: inline-block;
    height: 100%;
    width: var(--width);
    font-size: 12px;
  }

  .block-card {
    height: 100%;
  }

  .v-card--reveal {
    align-items: end;
    bottom: 0;
    justify-content: end;
    background-color: var(--v-resBlack-base);
    opacity: .4;
    position: absolute;
    height: 80px;
    width: 100%;
  }
</style>
