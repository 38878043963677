const state = {
  isErrorDialog: false,
  errorDialogMsg: '',
  errorDialogStatus: '',
  errorDialogStatusText: ''
}

const getters = {
  currentErrorDialog: (state) => state.isErrorDialog,
  currentErrorDialogMsg: (state) => state.errorDialogMsg,
  currentErrorDialogStatus: (state) => state.errorDialogStatus,
  currentErrorDialogStatusText: (state) => state.errorDialogStatusText
}

const actions = {
  changeErrorDialog ({ commit }, newErrorDialog) {
    commit('setErrorDialog', newErrorDialog)
  }
}

const mutations = {
  setErrorDialog: (state, newErrorDialog) => {
    state.isErrorDialog = newErrorDialog.isErrorDialog
    state.errorDialogMsg = newErrorDialog.errorDialogMsg
    state.errorDialogStatus = newErrorDialog.errorDialogStatus
    state.errorDialogStatusText = newErrorDialog.errorDialogStatusText
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
