<template>
  <div>
    <v-flex mt-6 mb-4>
      <span class="title">Tenant Refund</span>
    </v-flex>
    <v-card width="1000" height="90" class="tabs-card">
      <v-layout row>
          <v-layout ml-4 mt-2 column>
            <label class="caption resGray-darken1--text mb-2">Lease name</label>
            <span class="caption resGray-darken5--text font-weight-medium">{{ currentCheck.leaseName }}</span>
          </v-layout>
          <v-layout ml-4 mt-2 column>
            <label class="caption resGray-darken1--text mb-2">Check date</label>
            <span class="caption resGray-darken5--text font-weight-medium">{{ currentCheck.payerCheckDate | dateFilter(currentCheck.payerCheckDate) }}</span>
          </v-layout>
          <v-spacer></v-spacer>
          <v-layout ml-12 mt-2 column class="text-right">
            <label class="caption resGray-darken1--text mb-2">Refund total</label>
            <span class="subheading resGray-darken5--text font-weight-medium">{{ checkTotal }}</span>
          </v-layout>
          <v-layout mt-2 column class="text-right">
            <label class="caption resGray-darken1--text mb-2">Applied total</label>
            <span class="subheading resGray-darken5--text font-weight-medium">{{ appliedCheckTotal }}</span>
          </v-layout>
          <v-layout mr-6 mt-2 column class="text-right">
            <label class="caption resGray-darken1--text mb-2">Unapplied total</label>
            <span class="subheading font-weight-medium" :class="reconcileCheckColor">{{ unappliedCheckTotal }}</span>
          </v-layout>
      </v-layout>
    </v-card>
    <v-card flat width="1000">
      <data-table
        :headers="headers"
        :tableData="getData"
        @applyAmount="applyAmount"
        :noData="'No information to display.'">
      </data-table>
    </v-card>
    <v-layout row>
      <round-button
        height='30px'
        width='80px'
        :buttonText="'Back'"
        :buttonClass="'mt-4'"
        :borderColor="'var(--v-resBlue-darken1)'"
        background-color="var(--v-resBlue-darken1)"
        :depressed="true"
        :flat="true"
        @click='previousStep'>
      </round-button>
      <v-spacer></v-spacer>
      <round-button
        height='30px'
        buttonClass='mr-0 mt-4'
        width='100px'
        :depressed='true'
        :flat='true'
        :disabled='canSave'
        :backgroundColor="'var(--v-resGreen-base)'"
        :buttonText="'Complete'"
        @click="showDialog">
      </round-button>
    </v-layout>
    <base-dialog
      :showDialog="isDialog"
      title="Confirm Refund to Clearing Account"
      @cancel="cancelDialog"
      @save="confirmDialog"
      :saveButtonText="'Confirm Amount'"
      :saveButtonWidth="'120'"
      :iconColor="'white'"
      :titleClass="'dark-header'"
      v-if="isDialog">
      <v-card-text>
        <p>Please confirm you would like to refund ${{ clearingAccount }} to the clearing account.
        </p>
      </v-card-text>
    </base-dialog>
  </div>
</template>

<script>
import DataTable from '../../../BaseComponents/Table'
import RoundButton from '../../../BaseComponents/RoundButton'
import BaseDialog from '../../../BaseComponents/BaseDialog'
import * as currency from '../../../../utility/currency'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {
  props: ['currentCheck'],
  components: {
    DataTable,
    RoundButton,
    BaseDialog
  },
  data () {
    return {
      isDialog: false
    }
  },
  methods: {
    ...mapActions(['changeData', 'changeRefundOpenCharges']),
    nextStep () {
      this.$emit('currentChargesComplete')
    },
    previousStep () {
      let items = this.currentRefundOpenCharges
      items.shift()
      this.changeRefundOpenCharges(items)
      this.$emit('backToCurrentCharges')
    },
    confirmDialog () {
      this.changeData({ currentPage: 'Refunds',
        newData: {
          Refund: this.currentCheck,
          ChargeItems: this.getData
        }
      }).then(() => {
        this.isDialog = false
        this.$emit('refundComplete')
      }).catch(error => { console.log(error.response) })
    },
    showDialog () {
      this.isDialog = true
    },
    cancelDialog () {
      this.isDialog = false
    },
    applyAmount () {
    }
  },
  computed: {
    ...mapGetters(['currentHeaders', 'currentRefundOpenCharges']),
    headers () {
      let items = JSON.parse(JSON.stringify(this.currentHeaders('LeaseOpenCharges')))
      items.splice(items.findIndex(x => x.value === 'applyAmount'), 1)
      items.find(x => x.value === 'dueDate').isEditable = true
      return items
    },
    getData () {
      let items = this.currentRefundOpenCharges
      items.forEach(item => {
        if (item.chargeCode !== 'REFUND') {
          item.readonly = true
        }
      })
      return items
    },
    totalCheckReconciled () {
      if (this.appliedCheckTotal !== this.checkTotal) {
        return false
      }
      return true
    },
    appliedCheckTotal () {
      let sum = Number(0)
      this.getData.forEach(function (charge) {
        sum += Number(charge.selectBasePaymentAmount)
        sum = currency.parseCurrency(sum) // remove excess decimal values
      })
      this.$emit('updateDistributedTotal', sum)
      return currency.convertToCurrency(sum)
    },
    unappliedCheckTotal () {
      let remaining = currency.parseCurrency(this.checkTotal) - currency.parseCurrency(this.appliedCheckTotal)
      let total = currency.convertToCurrency(remaining)
      return total
    },
    checkTotal () {
      if (this.currentCheck.baseAmount !== undefined) {
        return currency.convertToCurrency(this.currentCheck.baseAmount)
      }
      return currency.convertToCurrency(0)
    },
    reconcileCheckColor () {
      if (!this.totalCheckReconciled) {
        return 'resRed-base--text'
      } else {
        return 'resGreen-base--text'
      }
    },
    clearingAccount () {
      if (this.getData.find(x => x.chargeCode === 'REFUND').selectBasePaymentAmount !== undefined) {
        return currency.convertToCurrency(this.getData.find(x => x.chargeCode === 'REFUND').selectBasePaymentAmount)
      }
      return currency.convertToCurrency(0)
    },
    canSave () {
      return currency.parseCurrency(this.unappliedCheckTotal) !== Number(0)
    }
  },
  filters: {
    dateFilter (val) {
      return moment(val).format('L')
    }
  }
}
</script>

<style lang="scss" scoped>
.distribute-table {
  ::v-deep .v-datatable {
    thead th {
      padding: 0px 19px;
    }
    tbody td {
      padding: 0px 19px;
    }
  }
}

.tabs-card {
  border-top: 2px var(--v-resBlue-base) solid;
  border-right: 1px var(--v-resGray-lighten1) solid;
  border-left: 1px var(--v-resGray-lighten1) solid;
  border-bottom: 1px var(--v-resGray-lighten1) solid;
}

.contact-title--primary {
  font-style: italic;
}
</style>
