<template>
  <div class="text-input mt-0" :style="textFieldProps">
    <label v-if="label !== undefined" class="text-field__label d-block mb-0">{{ label }} <label v-if="optionalLabel" class="resGray-darken4--text"> {{ optionalLabelText }}</label></label>
    <v-text-field
      flat
      solo
      :height="30"
      v-validate:validateField="rules"
      data-vv-name="validateField"
      data-vv-validate-on="blur"
      :class="textFormat"
      :disabled="disabled"
      :prefix="prefix"
      :suffix="suffix"
      :mask="mask"
      :hide-details="hideDetails"
      :value="textValue"
      :placeholder="placeholder"
      :error-messages="errors.collect('validateField')"
      v-on="inputListeners"
      :counter="textCounter"
      :maxlength="maxLength"
      @input="formatTextInline"
      @focus="focusChange"
      @change="finalFormat">
    </v-text-field>
  </div>
</template>

<script>
import * as currency from '../../utility/currency'
// Pass rules into this and the text field will provide the validation such as "required"
// for more than one rule use a pipe "required|alpha"
// rules are in main.js
// some require parameters like "max:10" will do a max 10 character validation
export default {
  props: [
    'placeholder',
    'label',
    'value',
    'width',
    'prefix',
    'suffix',
    'required',
    'rules',
    'disabled',
    'textFormat',
    'mask',
    'hideDetails',
    'optionalLabel',
    'optionalLabelText',
    'textCounter',
    'maxLength'
  ],
  data () {
    return {
      textValue: this.initialFormat(this.value),
      inactive: false
    }
  },
  methods: {
    initialFormat (val) {
      if (val) {
        return this.formatText(val)
      }
    },
    focusChange () {
      this.$emit('focus')
    },
    formatTextInline (val) {
      if (val !== undefined) {
        this.textValue = this.formatText(val)
        this.$emit('textInputUpdated', val)
      }
    },
    formatText (val) {
      if (val) {
        if (this.textFormat === 'currency') {
          // handle currency text format
          let currencyVal = val.toString().replace(/,/g, '')
          if (!isNaN(currencyVal)) {
            // check if val is a number and if true, parse to float and then format to a local string format
            return parseFloat(currencyVal).toLocaleString()
          }
        } else if (this.textFormat === 'numeric') {
          let numericVal = val.toString().replace(/,/g, '')
          if (!isNaN(numericVal)) {
            return parseFloat(numericVal).toLocaleString()
          }
        } else if (this.textFormat === 'capitalize') {
          return val.toUpperCase()
        }
        // return raw value
        return val
      }
    },
    finalFormat (val) {
      if (val) {
        if (this.textFormat === 'currency') {
          this.textValue = currency.convertToCurrency(val)
        } else {
          this.textValue = val
        }
      }
    },
    validate () {
      if (!this.inactive) {
        this.$validator.validateAll()
      }
    },
    resetValidation () {
      this.$validator.reset()
    }
  },
  mounted () {
    this.$eventHub.$on('validate', this.validate)
  },
  beforeDestroy () {
    this.$eventHub.$off('validate')
  },
  computed: {
    textFieldProps () {
      return {
        '--width': this.width,
        '--border-color': this.disabled ? 'var(--v-resGray-lighten1)' : 'var(--v-resGray-darken1)'
      }
    },
    validateField () {
      if (this.textFormat === 'currency' && this.textValue) {
        if (!this.value) {
          return this.textValue.toString().replace(/,/g, '')
        } else {
          return this.value.toString().replace(/,/g, '')
        }
      } else {
        return this.textValue
      }
    },
    inputListeners: function () {
      var vm = this
      return Object.assign({},
        this.$listeners,
        {
          on: function (event) {
            vm.$emit('on', event.target.value)
          }
        }
      )
    }
  },
  activated: function () {
    this.inactive = false
  },
  deactivated: function () {
    this.inactive = true
    this.resetValidation()
  },
  watch: {
    value: function (val) {
      if (val !== undefined) {
        this.textValue = this.initialFormat(this.value)
      } else {
        this.textValue = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .text-input {
    margin-top: 2px;
    display: inline-block;
    .currency, .numeric {
      ::v-deep input {
        text-align: right;
      }
    }

    ::v-deep .v-input {
      font-size: 12px;
      width: var(--width);
      margin-top: 0px;
      &__icon {
        height: 20px;
      }

      &--is-focused {
        .v-input__slot {
          border: solid 1px var(--v-resBlue-darken1) !important;
        }
      }
    }

    ::v-deep .v-messages__message {
      .v-icon {
        font-size: 16px;
      }
      font-size:10px;
    }

    ::v-deep .v-counter.theme--light {
      margin-top: 5px;
    }

    .text-field__label {
      font-size: 12px;
    }

    ::v-deep .v-input__slot {
      min-height: unset;
      height: 30px;
      border: 1px solid var(--border-color) !important;
      width: var(--width);
      background-color: var(--v-resWhite-base) !important;
      border-radius: 6px;
      margin-bottom: 0px;
    }

    ::v-deep .v-text-field__details {
      margin-bottom: 0px;
      padding-left: 0;
    }

    ::v-deep .v-input input {
      margin-top: 0px !important;
    }

    ::v-deep .v-text-field {
      ::v-deep .v-input__slot {
        min-height: 0px !important;
        ::v-deep .v-text-field__prefix {
          margin-top: 0 !important;
          color: var(--v-resGray-darken1);
        }
        .v-text-field__suffix {
          color: var(--v-resGray-darken1);
        }
      }
    }

    ::v-deep .v-text-field--outline .v-text-field__prefix {
      margin-top: 0 !important;
      color: var(--v-resGray-darken1);
    }

    ::v-deep .v-text-field--outline .v-text-field__suffix {
      margin-top: 0 !important;
      color: var(--v-resGray-darken1);
    }
  }
</style>
