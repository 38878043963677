<template>
    <v-snackbar
      class="toast"
      v-model="snackbar"
      top
      :color="snackbarSuccess"
      :timeout="timeout">
      <v-layout>
        <v-icon dark class="pr-2">error</v-icon>
        <span class="message">{{ snackbarMsg }}</span>
        <v-spacer></v-spacer>
        <v-btn
          color="var(--v-resWhite-base)"
          text
          @click="closeSnackbar"
          class="">
          <v-icon>close</v-icon>
        </v-btn>
      </v-layout>
    </v-snackbar>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  data: () => ({
    timeout: 5000
  }),
  computed: {
    ...mapGetters(['currentSnackbar', 'currentSnackbarMsg', 'currentSnackbarSuccess']),
    snackbar: {
      get () {
        return this.currentSnackbar
      }
    },
    snackbarMsg: {
      get () {
        return this.currentSnackbarMsg
      }
    },
    snackbarSuccess: {
      get () {
        if (this.currentSnackbarSuccess) {
          return 'var(--v-resGreen-base)'
        } else {
          return 'var(--v-resRed-base)'
        }
      }
    }
  },
  watch: {
    snackbar (value) {
      if (value === false) {
        this.closeSnackbar()
      } else {
        setTimeout(() => {
          this.closeSnackbar()
        }, this.timeout)
      }
    }
  },
  methods: {
    ...mapActions(['changeSnackbar']),
    closeSnackbar () {
      this.changeSnackbar({isSnackbar: false, snackbarMsg: '', snackbarSuccess: true})
    }
  }
}
</script>

<style lang="scss" scoped>
  .toast {
    ::v-deep .v-snack__wrapper {
      max-width: 100% !important;
      width:100% !important;
      margin: 0 !important;
      padding: 0 !important;
    }
    ::v-deep .message {
      padding-top: 9px
    }
  }
</style>
