<template>
  <div>
    <v-card
      :class="hasDiscrepancy && !measure.addEdit ? 'discrepancy' : ''"
      flat
      max-width="160"
      height="90">
      <div v-if="measure.addEdit==true" class="text-center">
        <v-icon large class="mt-4 mb-2">settings</v-icon>
        <br>
        <span class="caption text-uppercase">Add / Edit</span>
      </div>
      <div v-else class="px-2 py-1">
        <span class="subheading text-uppercase font-weight-regular">{{ measure.title }}</span>
        <div class="sq-ft-container">
          <div class="text-right">
            <span class="headline">{{ measureValue(measure.measureValue) }}</span>
            <br>
            <span class="caption text-uppercase text-right">sqft</span>
          </div>
        </div>
      </div>
    </v-card>
    <span v-if="hasDiscrepancy && !measure.addEdit" class="discrepancy-text">{{ discrepancyMessage }}</span>
  </div>
</template>

<script>
export default {
  props: ['measure', 'addEdit', 'discrepancy'],
  data () {
    return {
    }
  },
  methods: {
    measureValue (value) {
      if (value) return parseFloat(value).toLocaleString()
      return 'N/A'
    }
  },
  computed: {
    hasDiscrepancy () {
      return this.discrepancy !== 0
    },
    discrepancyMessage () {
      return `(${this.discrepancy} SQFT) discrepancy'`
    }
  }
}
</script>

<style lang="scss" scoped>
.subheading, .caption {
  color: var(--v-resGray-darken1);
}

.sq-ft-container {
  margin-right: auto;
  margin-left: auto;
}

.discrepancy {
  border-bottom: 3px solid var(--v-resRed-base)
;
}

.discrepancy-text {
  color: var(--v-resRed-base);
  font-size: 11px;
}
</style>
