<template>
<div>
  <overview-card
    :height="'270'"
    :title="'Lease details'"
    @openEdit="openLeaseDetailDialog">
    <v-row class="ml-1">
      <v-col>
        <h4>Category</h4>
        <span>{{ currentInfo.leaseCategoryName }}</span>
      </v-col>
      <v-col>
        <h4>DBA</h4>
        <span>{{ currentInfo.dbaName || '' }}</span>
      </v-col>
      <v-col>
        <h4>NAICS</h4>
        <span>{{ currentInfo.primaryNaics || '' }}</span>
      </v-col>
    </v-row>
    <v-row class="ml-1">
      <v-col>
        <h4>Addresses ({{ addressCount }})
          <a @click="openAddressesDialog">View all</a>
        </h4>
        <span>{{ computedAddress }}</span>
        <span>{{ computedAddress2 }}</span>
      </v-col>
    </v-row>
    <v-row class="ml-1">
      <v-col>
        <h4>Lease Date</h4>
        <span>{{ currentInfo.leaseDate | date }}</span>
      </v-col>
      <v-col>
        <h4>Start Date</h4>
        <span>{{ currentInfo.startDate | date }}</span>
      </v-col>
      <v-col>
        <h4>End Date</h4>
        <span>{{ currentInfo.endDate | date }}</span>
      </v-col>
      <v-col>
        <h4>Approval Date</h4>
        <span>{{ currentInfo.approvedDate | date }}</span>
      </v-col>
    </v-row>
  </overview-card>
  <!-- Dialogs -->
  <addresses-dialog
    @cancel="cancel"
    @save="save"
    v-if="addressesDialog"
    :showDialog="addressesDialog">
  </addresses-dialog>
  <lease-details
    @cancel="cancelLeaseDetails"
    :editedLease="editLeaseInfo"
    @save="saveLeaseDetails"
    v-if="leaseDetailsDialog"
    :showDialog="leaseDetailsDialog">
  </lease-details>
</div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import OverviewCard from '../BaseComponents/OverviewCard'
import AddressesDialog from '../Leases/Dialogs/DetailsCardDialog'
import LeaseDetails from '../Leases/Dialogs/LeaseDetails'

export default {
  components: {
    OverviewCard,
    AddressesDialog,
    LeaseDetails
  },
  data () {
    return {
      addressesDialog: false,
      leaseDetailsDialog: false,
      editLeaseInfo: {}
    }
  },
  methods: {
    ...mapActions(['fetchData']),
    openAddressesDialog () {
      this.addressesDialog = true
    },
    cancel () {
      this.addressesDialog = false
    },
    save () {
      this.addressesDialog = false
      this.fetchData({newPage: 'Addresses', params: { OrganizationId: this.currentSelectedItem.organizationId, ApplicationRole: this.currentSelectedItem.applicationRole }}).catch(error => { console.log(error.response) })
      this.fetchData({newPage: 'LeaseDetails', params: { organizationId: this.currentSelectedItem.organizationId }}).catch(error => { console.log(error.response) })
    },
    openLeaseDetailDialog () {
      this.editLeaseInfo = JSON.parse(JSON.stringify(this.currentSelectedItem))
      this.leaseDetailsDialog = true
    },
    cancelLeaseDetails () {
      this.leaseDetailsDialog = false
    },
    saveLeaseDetails () {
      this.leaseDetailsDialog = false
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentSelectedItem']),
    leaseDetails () {
      return this.currentData('LeaseDetails').primaryInfo
    },
    currentInfo () {
      return this.currentSelectedItem
    },
    addressCount () {
      return this.currentData('Addresses').length
    },
    addressInfo () {
      return this.currentData('Addresses')
    },
    computedAddress () {
      if (this.addressInfo.length > 0) {
        return `${this.addressInfo[0].line1 || ''}
        ${this.addressInfo[0].line2 || ''}`
      }
      return ''
    },
    computedAddress2 () {
      if (this.addressInfo.length > 0) {
        return `${this.addressInfo[0].city || ''}${this.addressInfo[0].city ? ',' : ''}
        ${this.addressInfo[0].state || ''}
        ${this.addressInfo[0].postalCode || ''}`
      }
      return ''
    }
  },
  filters: {
    date: function (value) {
      if (value) {
        return moment(String(value)).format('L')
      }
    }
  },
  created () {
    this.currentData('LeaseDetails')
    this.fetchData({newPage: 'Addresses', params: { OrganizationId: this.currentSelectedItem.organizationId, ApplicationRole: this.currentSelectedItem.applicationRole }}).catch(error => { console.log(error.response) })
  }
}
</script>

<style lang="scss" scoped>
h3 {
  font-weight: 500;
}

h4 {
  text-transform: uppercase;
  color: var(--v-resGray-darken1);
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 5px;

  a {
    padding-left: 5px;
    text-transform: none;
  }

}
</style>
