<template>
  <v-card-text class="pa-1">
    <pool-dialog-header>
    </pool-dialog-header>
    <v-card max-height="325" flat class="mt-6">
      <v-flex style="overflow: auto">
        <v-card max-height="320" flat>
          <data-table
            class="space__table"
            :headers="headers"
            :selectableRows="true"
            :tableData="getData"
            :tableKey="'propertySpace'"
            :selectedRows="selectedSpaces"
            @selectionChanged="changeSelected"
            :noData="'No information to display.'">
          </data-table>
        </v-card>
      </v-flex>
    </v-card>
  </v-card-text>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DataTable from '../../../BaseComponents/Table'
import PoolDialogHeader from './PoolDialogHeader'
export default {
  components: {
    DataTable,
    PoolDialogHeader
  },
  data () {
    return {
      selectedSpaces: []
    }
  },
  methods: {
    ...mapActions(['changePoolSpacesCompleted', 'changePoolSpaces', 'fetchData', 'changeData']),
    changeSelected (val) {
      this.selectedSpaces = val
      if (this.selectedSpaces.length > 0) {
        this.changePoolSpacesCompleted(true)
      } else {
        this.changePoolSpacesCompleted(false)
      }
      this.changePoolSpaces(val)
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentPoolStep', 'currentPool']),
    getData () {
      return this.currentData('Expense Spaces')
    },
    headers () {
      let headers = this.currentHeaders('Expense Spaces')
      let shortHeaders = []
      headers.forEach(item => {
        if (item.displayName === 'Space' || item.displayName === 'Description' || item.displayName === 'SqFt') {
          shortHeaders.push(item)
        }
      })
      return shortHeaders
    }
  },
  created () {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'setPoolNextStep') {
        if (this.currentPoolStep === 'Spaces') {
          this.fetchData({newPage: 'Expense Spaces', params: { Property: this.currentPool.property, ChargeCode: this.currentPool.chargeCode }}).catch(error => { console.log(error.response) })
        }
      }
    })
  },
  beforeDestroy () {
    this.unsubscribe()
  }
}
</script>

<style lang="scss" scoped>
  .space__table {
    ::v-deep span {
      font-size: 12px;
    }
    ::v-deep thead tr {
      height: 35px !important;
    }
    ::v-deep thead th {
      padding: 0 !important;
      height: 35px !important;
      &:first-child {
        padding-left: 10px !important;
      }
    }
    ::v-deep tbody tr {
      height: 35px !important;
    }
    ::v-deep tbody td {
      padding: 0 !important;
      height: 35px !important;
      &:first-child {
        padding-left: 10px !important;
      }
    }
  }
</style>
