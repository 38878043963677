<template>
  <base-dialog
    :showDialog="showDialog"
    :title="dialogTitle"
    @cancel="cancel"
    @save="save">
    <v-card-text>
      <v-row no-gutters class="checkbox__container">
        <v-col cols="12">
          <v-checkbox
            height="20"
            :ripple='false'
            v-model="editedItem.isUsed"
            class="shrink ma-0 pa-0"
            color="var(--v-resBlue-darken1)"
            label="Selected">
          </v-checkbox>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <drop-down label="Charge Frequency"
            :selectedValue="{ text: editedItem.chargeFrequencyName }"
            :items="'Charge Frequency'"
            :multiple='false'
            width="200px"
            @itemSelected="selectChargeFrequency">
          </drop-down>
          <text-input :value="editedItem.displayColumn"
            width='50px'
            label='Rent Roll Column'
            rules="required|numeric"
            class="ml-6"
            @textInputUpdated="updateRentRollColumn">
          </text-input>
        </v-col>
      </v-row>
      <div class="mt-5">
        <span><b>Fees</b></span>
      </div>
      <v-row no-gutters class="checkbox__container">
        <v-col cols="12">
          <v-checkbox
            height="20"
            v-model="editedItem.lateFeeApplies"
            class="shrink ma-0 pa-0"
            :ripple='false'
            color="var(--v-resBlue-darken1)"
            label="Late Fee">
          </v-checkbox>
        </v-col>
      </v-row>
      <v-row no-gutters class="checkbox__container">
        <v-col cols="12">
          <v-checkbox
            height="20"
            v-model="editedItem.managementFeeApplies"
            :ripple='false'
            class="shrink ma-0 pa-0"
            color="var(--v-resBlue-darken1)"
            label="Management Fee">
          </v-checkbox>
        </v-col>
      </v-row>
      <v-row no-gutters class="checkbox__container">
        <v-col cols="12">
            <v-checkbox
            height="20"
            v-model="editedItem.salesTaxApplies"
            :ripple='false'
            class="shrink ma-0 pa-0"
            color="var(--v-resBlue-darken1)"
            label="Sales Tax">
          </v-checkbox>
        </v-col>
      </v-row>
      <div class="mt-5">
        <v-tooltip top nudge-right="110" content-class="tooltip">
          <template v-slot:activator="{ on }">
            <span class="pl-0 mt-4 font-weight-bold">Statement Settings <v-icon v-on="on" color="var(--v-resGray-darken5)" small>info</v-icon></span>
          </template>
          <span>These settings are for the fields that <br/>appear on the lease statements.
          </span>
        </v-tooltip>
      </div>
      <v-row no-gutters class="mt-1">
        <v-col cols="12">
          <text-input :value="editedItem.reference"
            label='Reference Name'
            width="200px"
            rules="required"
            @textInputUpdated="updateReference">
          </text-input>
          <text-input :value="editedItem.displayOrder"
            width='50px'
            label='Statement Order'
            rules="numeric"
            class="ml-6"
            @textInputUpdated="updateDisplayOrder">
          </text-input>
        </v-col>
      </v-row>
    </v-card-text>
  </base-dialog>
</template>

<script>
import BaseDialog from '../../BaseComponents/BaseDialog'
import TextInput from '../../BaseComponents/TextInput'
import DropDown from '../../BaseComponents/DropDown'

export default {
  components: {
    BaseDialog,
    TextInput,
    DropDown
  },
  props: ['showDialog', 'editedItem', 'mode'],
  data () {
    return {
      editedChargeCode: {}
    }
  },
  methods: {
    cancel () {
      this.$emit('cancel', true)
    },
    save () {
      this.$eventHub.$emit('validate')
      setTimeout(() => {
        if (!this.errors.items.length > 0) {
          this.$emit('save', true)
        }
      }, 100)
    },
    selectChargeFrequency (val) {
      if (val !== null) {
        this.editedItem.chargeFrequencyName = val.text
        this.editedItem.chargeFrequency = val.value
      } else {
        this.editedItem.chargeFrequencyName = null
        this.editedItem.chargeFrequency = null
      }
    },
    updateRentRollColumn (val) {
      this.editedItem.displayColumn = val
    },
    updateReference (val) {
      this.editedItem.reference = val
    },
    updateDisplayOrder (val) {
      this.editedItem.displayOrder = val
    }
  },
  computed: {
    dialogTitle () {
      return `${this.mode} Charge Template`
    }
  }
}
</script>

<style lang="scss" scoped>
  .tooltip {
    background-color: var(--v-resGray-darken5);
  }
  .checkbox__container {
    height: 30px;
    ::v-deep label {
      color: var(--v-resBlack-base);
      font-size: 14px;
    }
  }
</style>
