const state = {
  currentSection: localStorage.getItem('currentSection') ? JSON.parse(localStorage.getItem('currentSection')) : {},
  currentTab: localStorage.getItem('currentTab') ? JSON.parse(localStorage.getItem('currentTab')) : {}
}

const getters = {
  currentSection: (state) => state.currentSection,
  currentTab: (state) => state.currentTab
}

const actions = {
  changeSection ({ commit }, newSection) {
    commit('setSection', newSection)
  },
  changeTab ({ commit }, newTab) {
    commit('setTab', newTab)
  }
}

const mutations = {
  setSection: (state, newSection) => {
    state.currentSection = newSection
    if (newSection) {
      const parsed = JSON.stringify(newSection)
      localStorage.setItem('currentSection', parsed)
    } else {
      localStorage.removeItem('currentSection')
    }
  },
  setTab: (state, newTab) => {
    state.currentTab = newTab
    if (newTab) {
      const parsed = JSON.stringify(newTab)
      localStorage.setItem('currentTab', parsed)
    } else {
      localStorage.removeItem('currentTab')
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
