<template>
  <base-dialog
    :showDialog="showDialog"
    :title="'Edit Charge'"
    :maxWidth="550"
    @cancel="cancelDialog"
    @save="confirmRecurringDialog">
    <v-row no-gutters>
      <v-col>
        <div class="ml-6">Charge Code</div>
        <div class="ml-6"><b>{{ editedItem.chargeCode }}</b></div>
      </v-col>
      <v-col>
        <div class="ml-6">Space</div>
        <div class="ml-6"><b>{{ editedItem.leaseSpace }}</b></div>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-5">
      <v-col>
        <drop-down
          :selectedValue="{ text: editedItem.chargeFrequencyName}"
          :items="'Charge Frequency'"
          :label="'Frequency'"
          :multiple='false'
          width="180px"
          class="ml-6"
          @itemSelected="selectChargeFrequency">
        </drop-down>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-5">
      <v-col cols="12">
        <date-picker
          :label="'Start Date'"
          class="mx-6"
          :initialDate="editedItem.startDate"
          @dateSelected="setStartDate">
        </date-picker>
        <date-picker
          :label="'End Date'"
          class="mr-6"
          :initialDate="editedItem.endDate"
          @dateSelected="setEndDate">
        </date-picker>
        <text-input
          :value="editedItem.baseAmount"
          label='Amount'
          width="100px"
          :prefix="'$'"
          textFormat="currency"
          rules="decimal:2"
          @textInputUpdated="updateChargeAmount">
        </text-input>
      </v-col>
    </v-row>
    <div class="ml-6 mt-5">
      <span><b>Fees</b></span>
    </div>
    <v-row no-gutters class="checkbox__container">
      <v-col cols="12">
        <v-checkbox
          height="20"
          v-model="editedItem.lateFeeApplies"
          class="shrink ml-6 my-0 pa-0"
          color="var(--v-resBlue-darken1)"
          label="Late Fee">
        </v-checkbox>
      </v-col>
    </v-row>
    <v-row no-gutters class="checkbox__container">
      <v-col cols="12">
        <v-checkbox
          height="20"
          v-model="editedItem.managementFeeApplies"
          class="shrink ml-6 my-0 pa-0"
          color="var(--v-resBlue-darken1)"
          label="Management Fee">
        </v-checkbox>
      </v-col>
    </v-row>
    <v-row no-gutters class="checkbox__container">
      <v-col cols="12">
        <v-checkbox
          height="20"
          v-model="editedItem.salesTaxApplies"
          class="shrink ml-6 my-0 pa-0"
          color="var(--v-resBlue-darken1)"
          label="Sales Tax">
        </v-checkbox>
      </v-col>
    </v-row>
  </base-dialog>
</template>

<script>
import * as currency from '../../../utility/currency'
import DropDown from '../../BaseComponents/DropDown'
import TextInput from '../../BaseComponents/TextInput'
import DatePicker from '../../BaseComponents/DatePicker'
import BaseDialog from '../../BaseComponents/BaseDialog'

export default {
  props: ['showDialog', 'editedItem'],
  components: {
    DropDown,
    TextInput,
    BaseDialog,
    DatePicker
  },
  data () {
    return {
    }
  },
  methods: {
    cancelDialog () {
      this.$emit('cancel', true)
    },
    updateReference (val) {
      this.editedItem.reference = val
    },
    updateDisplayOrder (val) {
      this.editedItem.displayOrder = val
    },
    selectChargeFrequency (val) {
      if (val !== null) {
        this.editedItem.chargeFrequencyName = val.text
        this.editedItem.chargeFrequency = val.value
      } else {
        this.editedItem.chargeFrequencyName = null
        this.editedItem.chargeFrequency = null
      }
    },
    setEndDate (val) {
      this.editedItem.endDate = val
    },
    setStartDate (val) {
      this.editedItem.startDate = val
    },
    updateChargeAmount (val) {
      this.editedItem.baseAmount = currency.parseCurrency(val)
    },
    confirmRecurringDialog () {
      this.$validator.validateAll()
      if (!this.errors.items.any) {
        this.$emit('editRecurringCharge', this.editedItem)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .checkbox__container {
    height: 30px;
    ::v-deep label {
      color: var(--v-resBlack-base);
      font-size: 14px;
    }
  }
</style>
