<template>
  <v-container fluid class="resGray lighten-6">
    <v-app-bar flat>
      <drop-down
        :selectedValue="{ text: selectedUserName }"
        :customList="userList"
        :label="'Select User'"
        :genericLabel="true"
        :multiple='false'
        :hideDetails="true"
        class="mr-3"
        width="220px"
        rules="required"
        @itemSelected="selectUser">
      </drop-down>
      <drop-down
        :selectedValue="{ text: selectedRoleName }"
        v-if="canSave"
        :items="'UserRoles'"
        :label="'Select Role'"
        :genericLabel="true"
        :multiple='false'
        :hideDetails="true"
        width="220px"
        rules="required"
        class="mr-3"
        @itemSelected="selectRole">
      </drop-down>
      <v-icon v-if="isModified" class="pt-9 mr-1" small color="var(--v-resOrange-base)">
        warning
      </v-icon>
      <span v-if="isModified" class="caption pt-9">
        This user role has been modified for {{ selectedUserName }}.
      </span>
    </v-app-bar>
    <v-layout>
      <v-item-group
        v-model="window"
        class="shrink mx-4 permission-categories"
        mandatory
        tag="v-flex">
        <v-item v-for="(name, index) in cards" :key="index">
          <div slot-scope="{ active, toggle }">
            <permission-cards
              class="permissions-card"
              :cardText="name"
              :isSelected="active"
              @click.native="toggle(); selectCard(name)">
            </permission-cards>
          </div>
        </v-item>
      </v-item-group>
      <v-flex>
        <v-window
          v-model="window"
          vertical
          class="mr-4 pt-3">
          <v-window-item v-for="(name, index) in cards" :key="index">
            <v-card flat max-height="500">
              <v-flex style="overflow: auto">
                <v-card max-height="500" flat>
                  <data-table
                    v-if="name === selectedCard && name !== 'Property Groups'"
                    class="permissions-table"
                    :headers="headers"
                    :tableData="getData"
                    :noData="'No information to display.'">
                  </data-table>
                  <data-table
                    v-if="name === 'Property Groups'"
                    :headers="userPropertyGroupHeaders"
                    :tableData="userPropertyGroupData"
                    :tableKey="'propertyGroup'"
                    :selectableRows="true"
                    :selectedRows="selectedPropertyGroups"
                    :showSelectAll="true"
                    @selectionChanged="selectUserPropertyGroups">
                  </data-table>
                </v-card>
              </v-flex>
            </v-card>
          </v-window-item>
        </v-window>
      </v-flex>
    </v-layout>
    <v-app-bar flat height="36" class="mt-3">
      <v-spacer></v-spacer>
      <round-button
        color='var(--v-resBlue-darken1)'
        height='30px'
        width='180px'
        :depressed='true'
        :outline='true'
        buttonText='Restore Default Role'
        buttonTextColor='var(--v-resBlue-darken1)'
        :disabled="!canSave || !selectedRoleName"
        :backgroundColor="'var(--v-resWhite-base)'"
        :borderColor="'var(--v-resBlue-darken1)'"
        buttonClass='mr-3'
        @click="openRestoreDialog">
      </round-button>
      <round-button
        color='var(--v-resGray-darken3)'
        height='30px'
        width='135px'
        :depressed='true'
        buttonText='Save Changes'
        :disabled="!canSave"
        :backgroundColor="'var(--v-resGreen-lighten1)'"
        borderColor='var(--v-resGray-lighten1)'
        @click="submit">
      </round-button>
    </v-app-bar>
    <!-- Dialogs -->
    <base-dialog
      :showDialog="showRestoreDialog"
      :title="'Restore Default User Role'"
      :iconColor="'white'"
      :titleClass="'dark-header'"
      :maxWidth="350"
      saveButtonText="Confirm Restore"
      saveButtonWidth="140px"
      :showTitleIcon="true"
      :smallTitleIcon="true"
      @cancel="cancelRestoreDialog"
      @save="confirmRestoreDialog">
      <v-card-text>
        <p class="caption">This will restore all the default permissions assigned to the {{ selectedRoleName }} user role to the selected user.</p>
        <p class="caption"> Are you sure you want to restore default permissions?</p>
      </v-card-text>
    </base-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DataTable from '../../BaseComponents/Table'
import RoundButton from '../../BaseComponents/RoundButton'
import BaseDialog from '../../BaseComponents/BaseDialog'
import DropDown from '../../BaseComponents/DropDown'
import PermissionCards from './PermissionCards'

export default {
  components: {
    DropDown,
    DataTable,
    RoundButton,
    BaseDialog,
    PermissionCards
  },
  data () {
    return {
      selectedUser: undefined,
      selectedUserName: undefined,
      selectedRole: undefined,
      selectedRoleName: undefined,
      roleChanged: false,
      showDialog: false,
      selectedUserPropertyGroups: [],
      selectedCard: 'General',
      cards: ['General', 'Leases', 'Properties', 'Transactions', 'Recoveries', 'Setup', 'Property Groups'],
      window: 0,
      showRestoreDialog: false
    }
  },
  methods: {
    ...mapActions(['fetchData', 'changeData', 'initializeStoreItem']),
    selectUser (val) {
      this.initializeStoreItem('UserPropertyGroups')
      if (val !== null && val !== undefined) {
        this.selectedUser = val.value
        this.selectedUserName = val.text
        this.initializeStoreItem('UserPermissions')
        this.fetchData({ newPage: 'UserPermissions', params: { UserId: this.selectedUser } }).catch(error => { console.log(error.response) })
        this.fetchData({ newPage: 'UserPropertyGroups', params: { UserId: this.selectedUser } })
          .then(() => {
            this.selectedUserPropertyGroups = this.currentData('UserPropertyGroups')
          }).catch(error => { console.log(error.response) })
        this.selectedRole = this.currentData('UsersInfo').find(x => x.userId === val.value).role
        this.selectedRoleName = this.currentData('UsersInfo').find(x => x.userId === val.value).roleName
        this.fetchData({ newPage: 'UserRoles', params: { RoleId: this.selectedRole } })
          .then(() => {
            this.roleChanged = false
          }).catch(error => { console.log(error.response) })
      } else {
        this.selectedUser = undefined
        this.selectedUserName = undefined
        this.selectedRole = undefined
        this.selectedRoleName = undefined
        this.roleChanged = false
      }
    },
    selectCard (val) {
      this.selectedCard = val
    },
    selectRole (val) {
      if (val !== null && val !== undefined) {
        this.selectedRole = val.value
        this.selectedRoleName = val.text
        this.roleChanged = true
        this.initializeStoreItem('UserRoles')
        this.fetchData({ newPage: 'UserRoles', params: { RoleId: this.selectedRole } }).catch(error => { console.log(error.response) })
      } else {
        this.selectedRole = undefined
        this.selectedRoleName = undefined
        this.roleChanged = false
      }
    },
    submit () {
      if (this.canSave) {
        this.changeData({ currentPage: 'UserPermissions', newData: { UserId: this.selectedUser, IsModified: this.isModified, UserRole: this.selectedRole, UserPermissions: this.allData } }).then(() => {
          this.updateUserPropertyGroups()
          this.roleChanged = false
          this.initializeStoreItem('UserPermissions')
          this.fetchData({ newPage: 'UserPermissions', params: { UserId: this.selectedUser } })
        }).catch(error => { console.log(error.response) })
      }
    },
    selectUserPropertyGroups (e) {
      this.selectedUserPropertyGroups = e.map(x => {
        x.selected = true
        return x
      })
    },
    updateUserPropertyGroups () {
      if (this.selectedUserPropertyGroups) {
        if (this.selectedUserPropertyGroups.length === this.userPropertyGroupData.length) {
          this.selectedUserPropertyGroups = this.currentData('UserPropertyGroups').filter(p => {
            return p.propertyGroup === 'ALL'
          }).map(x => {
            x.selected = true
            return x
          })
        }
        this.changeData({ currentPage: 'UserPropertyGroups', newData: { userId: this.selectedUser, userPropertyGroups: this.selectedUserPropertyGroups } })
          .then(() => {
            this.fetchData({ newPage: 'UserPropertyGroups', params: { UserId: this.selectedUser } })
          }).catch(error => { console.log(error.response) })
      }
    },
    openRestoreDialog () {
      if (this.canSave) {
        this.showRestoreDialog = true
      }
    },
    cancelRestoreDialog () {
      this.showRestoreDialog = false
    },
    confirmRestoreDialog () {
      if (this.canSave) {
        let rolePermissions = this.currentData('UserRoles')
        this.changeData({ currentPage: 'UserPermissions', newData: { UserId: this.selectedUser, IsModified: false, UserRole: this.selectedRole, UserPermissions: rolePermissions } }).then(() => {
          this.roleChanged = false
          this.initializeStoreItem('UserPermissions')
          this.fetchData({ newPage: 'UserPermissions', params: { UserId: this.selectedUser } }).then(() => {
            this.showRestoreDialog = false
          })
        }).catch(error => { console.log(error.response) })
      }
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentUser']),
    canSave () {
      if (this.selectedUser !== null && this.selectedUser !== undefined) {
        return true
      }
      return false
    },
    userPropertyGroupHeaders () {
      return this.currentHeaders('UserPropertyGroups')
    },
    userPropertyGroupData () {
      return this.currentData('UserPropertyGroups').filter(p => {
        return p.propertyGroup !== 'ALL'
      })
    },
    allSelected () {
      return this.currentData('UserPropertyGroups').find(u => {
        return u.propertyGroup === 'ALL'
      })
    },
    selectedPropertyGroups () {
      let allSelected = this.currentData('UserPropertyGroups').find(u => {
        return u.propertyGroup === 'ALL' && u.selected
      })

      if (allSelected) {
        return this.userPropertyGroupData
      }

      return this.userPropertyGroupData.filter(u => {
        return u.selected
      })
    },
    allData () {
      if (this.roleChanged) {
        return this.currentData('UserRoles')
      }
      return this.currentData('UserPermissions')
    },
    getData () {
      return this.allData.filter(x => x.category === this.selectedCard)
    },
    headers () {
      return this.currentHeaders('UserPermissions')
    },
    userList () {
      let items = []
      this.currentData('UsersInfo').forEach(item => {
        items.push({ text: item.name, value: item.userId })
      })
      return items
    },
    isModified () {
      if (!this.selectedUser) {
        return false // do not display modified flag if no user is selected
      }
      if (!this.selectedRole) {
        return false // do not display modified flag if no role is selected
      }
      let role = this.currentData('UserRoles').map(x => {
        return { routeId: x.routeId, readWrite: x.readWrite }
      })
      let currentData = this.allData.map(x => {
        return { routeId: x.routeId, readWrite: x.readWrite }
      })
      let objectsEqual = JSON.stringify(role) === JSON.stringify(currentData)
      return !objectsEqual
    }
  },
  created () {
    this.fetchData({ newPage: 'UsersInfo' }).catch(error => { console.log(error.response) })
  },
  beforeDestroy () {
    this.initializeStoreItem('UserPermissions')
  }
}
</script>

<style lang="scss" scoped>
.permission-categories {
  padding-top: 2px;
}
</style>
