<template>
  <v-container fluid class="resGray lighten-6">
    <v-app-bar flat height="36">
      <toggle-button-group
        class="ml-5"
        :buttons="toggleButtons"
        @toggled="changeTable">
      </toggle-button-group>
      <v-spacer></v-spacer>
      <search
        class="mb-0-plus"
        :placeholder="'Search Charges'"
        :class="isMobile ? 'ml-6' : 'mr-3'"
        :width="isMobile ? '97%' : ''"
        @searchStringUpdated="updateSearchString">
      </search>
      <round-button
        v-if="currentToggle == 0"
        color='var(--v-resGray-darken3)'
        height='30px'
        :dark='true'
        :depressed='true'
        buttonText='+ Add Charge'
        backgroundColor='var(--v-resGreen-lighten1)'
        borderColor='var(--v-resGray-lighten1)'
        buttonClass='mr-2'
        @click="AddRecurringCharges">
      </round-button>
    </v-app-bar>
    <v-row>
      <v-col cols="12" class="mt-3">
        <data-table
          :headers="headers"
          :tableData="getData"
          :showActions="true"
          class="mx-6"
          :editOnly='false'
          :noData="'No information to display.'"
          :searchString="searchString"
          @unlockEditing="unlockEdit"
          @itemDeleteRequested="openDeleteDialog"
          @showParameters="showParameters">
        </data-table>
      </v-col>
    </v-row>
    <!-- Dialogs -->
    <edit-charges-recurring
      :showDialog="EditRecurringCharges"
      :editedItem = this.editedItem
      @editRecurringCharge="confirmRecurringDialog"
      @cancel="cancelDialog">
    </edit-charges-recurring>
    <edit-charges-non-recurring
      :showDialog="EditNonRecurringCharges"
      :editedItem = this.editedItem
      @cancel="cancelDialog"
      @editNonRecurringCharge="confirmNonRecurringDialog">
    </edit-charges-non-recurring>
    <add-charges
      :lease="currentSelectedItem.lease"
      :showDialog="AddRecurringChargesDialog"
      @cancel="cancelDialog"
      @saveNewCharge="saveNewCharge">
    </add-charges>
    <parameters-dialog
      v-if="showParametersDialog"
      :showDialog="showParametersDialog"
      :leaseItem='editedItem'
      @cancel="hideParameters"
      @saveComplete="hideParameters">
    </parameters-dialog>
    <delete-dialog
      :showDialog="deleteDialog"
      :message='deleteMessage'
      @confirmDelete="confirmDeleteDialog"
      @cancelDelete="cancelDeleteDialog">
    </delete-dialog>
  </v-container>
</template>

<script>
import DataTable from '../BaseComponents/Table'
import ToggleButtonGroup from '../BaseComponents/ToggleButtonGroup'
import RoundButton from '../BaseComponents/RoundButton'
import Search from '../BaseComponents/Search'
import AddCharges from './Dialogs/AddCharges'
import EditChargesRecurring from './Dialogs/EditChargesRecurring'
import EditChargesNonRecurring from './Dialogs/EditChargesNonRecurring'
import DeleteDialog from '../BaseComponents/DeleteDialog'
import ParametersDialog from './Dialogs/Parameters'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    DataTable,
    ToggleButtonGroup,
    RoundButton,
    Search,
    AddCharges,
    EditChargesNonRecurring,
    EditChargesRecurring,
    ParametersDialog,
    DeleteDialog
  },
  data () {
    return {
      currentToggle: 0,
      searchString: null,
      editedItem: {},
      EditRecurringCharges: false,
      EditNonRecurringCharges: false,
      AddRecurringChargesDialog: false,
      showParametersDialog: false,
      deleteDialog: false,
      toggleButtons: [
        { text: 'Recurring' },
        { text: 'Non-recurring' }
      ],
      deleteItem: {}
    }
  },
  created () {
    this.fetchData({ newPage: 'LeasesRecurringCharges', params: { Lease: this.currentSelectedItem.lease, Property: this.currentSelectedItem.property } })
  },
  methods: {
    ...mapActions(['changeLoading', 'fetchData', 'changeData', 'deleteData']),
    updateSearchString (val) {
      this.searchString = val
    },
    changeTable (value) {
      this.currentToggle = value
      this.editedItem = {}
      if (value === 0) {
        this.fetchData({ newPage: 'LeasesRecurringCharges', params: { Lease: this.currentSelectedItem.lease, Property: this.currentSelectedItem.property } }).catch(error => { console.log(error.response) })
      } else {
        this.fetchData({ newPage: 'LeasesNonRecurringCharges', params: { Lease: this.currentSelectedItem.lease, Property: this.currentSelectedItem.property } }).catch(error => { console.log(error.response) })
      }
    },
    unlockEdit (item) {
      this.editedIndex = this.getData.indexOf(item)
      this.editedItem = JSON.parse(JSON.stringify((this.getData[this.editedIndex], item)))
      if (this.currentToggle === 0) {
        this.EditRecurringCharges = true
      } else {
        this.EditNonRecurringCharges = true
      }
    },
    cancelDialog () {
      if (this.currentToggle === 0) {
        this.EditRecurringCharges = false
        this.AddRecurringChargesDialog = false
      } else {
        this.EditNonRecurringCharges = false
      }
    },
    AddRecurringCharges () {
      this.AddRecurringChargesDialog = true
    },
    confirmDeleteDialog () {
      if (this.currentToggle === 0) {
        this.deleteData({ currentPage: 'LeasesRecurringCharges', removeItem: this.deleteItem }).then(() => {
          this.deleteDialog = false
        }).catch(error => { console.log(error.response) })
      } else {
        this.deleteData({ currentPage: 'LeasesNonRecurringCharges', removeItem: this.deleteItem }).then(() => {
          this.deleteDialog = false
        }).catch(error => { console.log(error.response) })
      }
      this.deleteItem = {}
    },
    cancelDeleteDialog () {
      this.deleteDialog = false
    },
    openDeleteDialog (val) {
      this.deleteItem = val
      this.deleteDialog = true
    },
    saveNewCharge (val) {
      this.changeData({ currentPage: 'LeasesRecurringCharges', newData: val }).then(() => {
        this.fetchData({ newPage: 'LeasesRecurringCharges', params: { Lease: this.currentSelectedItem.lease, Property: this.currentSelectedItem.property } }).then(() => {
          this.AddRecurringChargesDialog = false
        })
      }).catch(error => { console.log(error.response) })
    },
    confirmNonRecurringDialog () {
      this.changeData({ currentPage: 'LeasesNonRecurringCharges', newData: this.editedItem }).then(() => {
        this.fetchData({ newPage: 'LeasesNonRecurringCharges', params: { Lease: this.currentSelectedItem.lease, Property: this.currentSelectedItem.property } }).then(() => {
          this.EditNonRecurringCharges = false
        })
      }).catch(error => { console.log(error.response) })
    },
    confirmRecurringDialog () {
      this.changeData({ currentPage: 'LeasesRecurringCharges', newData: this.editedItem }).then(() => {
        this.fetchData({ newPage: 'LeasesRecurringCharges', params: { Lease: this.currentSelectedItem.lease, Property: this.currentSelectedItem.property } }).then(() => {
          this.EditRecurringCharges = false
        })
      }).catch(error => { console.log(error.response) })
    },
    showParameters (e) {
      this.editedItem = e
      this.showParametersDialog = true
    },
    hideParameters () {
      this.showParametersDialog = false
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentSelectedItem']),
    isMobile () {
      return this.$vuetify.breakpoint.mobile
    },
    headers () {
      if (this.currentToggle === 0) {
        return this.currentHeaders('LeasesRecurringCharges')
      } else {
        return this.currentHeaders('LeasesNonRecurringCharges')
      }
    },
    getData () {
      if (this.currentToggle === 0) {
        return this.currentData('LeasesRecurringCharges')
      } else {
        return this.currentData('LeasesNonRecurringCharges')
      }
    },
    deleteMessage () {
      if (this.currentToggle === 0) {
        return 'Please confirm that you would like to delete this charge. Completing this action will permanently delete this item. You can add it back through the Add Charge button. Charges with transaction cannot be deleted.'
      } else {
        return 'Please confirm that you would like to delete this charge code from the lease. Completing this action will permanently delete this item. You can add it back through the Property Charge Code tab in Properties. Charges with transaction cannot be deleted.'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tooltip {
  background-color: var(--v-resGray-darken5);
}
::v-deep .checkbox__container {
  height: 30px;
}
</style>
