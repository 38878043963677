// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'vuetify/dist/vuetify.min.css'
import Vue from 'vue'
import App from './App'
import router from './router'
import vuetify from './plugins/vuetify'
import Axios from 'axios'
import Mgr from './services/auth/'
import store from './store'
import VueSVGIcon from 'vue-svgicon'
import VeeValidate from 'vee-validate'
import EventHub from './services/eventHub'

Vue.use(VueSVGIcon)
Vue.prototype.$http = Axios

const dictionary = {
  custom: {
    validateField: {
      required: () => 'This field is required.',
      max: '<i aria-hidden="true" class="v-icon input-error material-icons">warning</i> Exceeds character limit.',
      min: '<i aria-hidden="true" class="v-icon input-error material-icons">warning</i> Sorry, not enough characters.',
      alpha_spaces: '<i aria-hidden="true" class="v-icon input-error material-icons">warning</i> This field may only contain alphabetic characters and spaces.',
      decimal: '<i aria-hidden="true" class="v-icon input-error material-icons">warning</i> This field cannot exceed 2 decimal places.',
      digit: '<i aria-hidden="true" class="v-icon input-error material-icons">warning</i> This field has exceeded the max digits',
      alpha: '<i aria-hidden="true" class="v-icon input-error material-icons">warning</i> This field can only contain alphabetic characters.',
      numeric: '<i aria-hidden="true" class="v-icon input-error material-icons">warning</i> Numeric Only.',
      email: '<i aria-hidden="true" class="v-icon input-error material-icons">warning</i> Must contain valid email address'
    },
    currentItem: {
      required: () => 'This field is required.',
      max: '<i aria-hidden="true" class="v-icon input-error material-icons">warning</i> Exceeds character limit.',
      min: '<i aria-hidden="true" class="v-icon input-error material-icons">warning</i> Sorry, not enough characters.',
      alpha_spaces: '<i aria-hidden="true" class="v-icon input-error material-icons">warning</i> This field may only contain alphabetic characters and spaces.',
      decimal: '<i aria-hidden="true" class="v-icon input-error material-icons">warning</i> This field cannot exceed 2 decimal places.',
      digit: '<i aria-hidden="true" class="v-icon input-error material-icons">warning</i>This field has exceeded the max digits',
      alpha: '<i aria-hidden="true" class="v-icon input-error material-icons">warning</i> This field can only contain alphabetic characters.',
      numeric: '<i aria-hidden="true" class="v-icon input-error material-icons">warning</i> Numeric Only.',
      email: '<i aria-hidden="true" class="v-icon input-error material-icons">warning</i> Must contain valid email address'
    }
  }
}

VeeValidate.Validator.localize('en', dictionary)
Vue.use(VeeValidate, dictionary)

Vue.use(require('vue-moment'))

Vue.prototype.$eventHub = EventHub

Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

let mgr = new Mgr()

router.afterEach(() => { // (to, from) can be passed to function if needed
  mgr.getUser()
    .then(user => {
      let timeout = Math.floor(Date.now() / 1000) + 900
      if (timeout > user.expires_at) { // checks if token is due to expire in 15 mins or less, and will sliently renew on a change of route
        mgr.renewToken()
          .then(user => {
            store.dispatch('changeUser', user)
          })
      }
    })
    .catch(err => {
      console.log(err)
    })
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  if (requiresAuth) {
    let adminCheck = store.getters.currentUserIsAdmin
    if (adminCheck) {
      next()
    } else {
      let permission = store.getters.checkPermissions(to.name)
      if (permission === null || permission === undefined) {
        next({ path: '/accessdenied', replace: true })
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

Vue.directive('focus', {
  inserted: function (el) {
    var focusItem = el.getElementsByTagName('INPUT')[0]
    if (focusItem.getAttribute('readonly') !== 'readonly') {
      focusItem.focus()
    }
  }
})
