<template>
  <div>
    <v-card flat width="1000" height="90" class="tabs-card">
      <v-layout>
        <v-flex ml-4 mt-2 mr-12>
          <div class="caption resGray-darken1--text mb-2">Lease name</div>
          <div class="caption resGray-darken5--text font-weight-medium">{{ currentAppliedCredit.leaseName }}</div>
        </v-flex>
        <v-flex ml-12 mt-2>
          <div class="caption resGray-darken1--text mb-2">Application date</div>
          <div class="caption resGray-darken5--text font-weight-medium">{{ currentAppliedCredit.payerCheckDate | dateFilter(currentAppliedCredit.payerCheckDate) }}</div>
        </v-flex>
        <v-flex ml-4 mt-2>
          <div class="caption resGray-darken1--text mb-2">Reference number</div>
          <div class="caption resGray-darken5--text font-weight-medium">{{ currentAppliedCredit.payerCheckNumber }}</div>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex mt-2 mr-6 class="text-right">
          <div class="caption resGray-darken1--text mb-2">Applied total</div>
          <div class="subheading font-weight-medium resGray-darken5--text">
            {{ appliedTotal }}
          </div>
        </v-flex>
      </v-layout>
    </v-card>
    <v-card flat width="1000">
      <data-table
        :headers="headers"
        :tableData="getData"
        :noData="'No information to display.'">
      </data-table>
    </v-card>
  </div>
</template>

<script>
import DataTable from '../../../BaseComponents/Table'
import { mapGetters, mapActions } from 'vuex'
import * as currency from '../../../../utility/currency'
import moment from 'moment'

export default {
  props: ['appliedCreditDetails', 'depositId'],
  components: {
    DataTable
  },
  data () {
    return {
    }
  },
  methods: {
    ...mapActions(['fetchDropdown', 'changeData', 'fetchData', 'addNew', 'cancelNew'])
  },
  computed: {
    ...mapGetters(['currentDropdown', 'currentData', 'currentHeaders']),
    appliedTotal () {
      if (this.getData.length > 0) {
        let sum = Number(0)
        this.getData.forEach(function (charge) {
          if (charge.paymentAmount < 0) {
            sum += Math.abs(charge.paymentAmount) // only sum credits
          }
        })
        return currency.convertToCurrency(sum)
      }
      return currency.convertToCurrency(0)
    },
    headers: {
      get () {
        return this.currentHeaders('AppliedCreditsDistributionDetails')
      }
    },
    getData: {
      get () {
        return this.currentData('AppliedCreditsDistributionDetails')
      }
    },
    currentAppliedCredit () {
      return this.appliedCreditDetails.bankDepositDetails.find(x => x.depositId === this.depositId)
    }
  },
  created () {
    this.fetchData({ newPage: 'AppliedCreditsDistributionDetails', params: { Lease: this.currentAppliedCredit.payer, BankDepositId: this.currentAppliedCredit.depositId } }).catch(error => { console.log(error.response) })
  },
  filters: {
    dateFilter (val) {
      return moment(val).format('L')
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs-card {
  border-radius: 0;
  border-top: 2px var(--v-resBlue-base) solid;
  border-right: 1px var(--v-resGray-lighten1) solid;
  border-left: 1px var(--v-resGray-lighten1) solid;
  border-bottom: 1px var(--v-resGray-lighten1) solid;
}
</style>
