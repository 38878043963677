<template>
  <base-dialog
    iconColor="var(--v-resWhite-base)"
    titleClass="dark-header"
    :saveButtonColor="saveButtonColor"
    :title="title"
    :saveButtonText="saveButtonText"
    :setWidth="350"
    :saveButtonWidth="150"
    :showDialog="showDialog"
    @cancel="cancel"
    @save="save">
    <v-card-text>
      <date-picker
        v-if="!confirm"
        label="Reconciliation charge date"
        @dateSelected="setAsOfDate">
      </date-picker>
      <span v-if="confirm">
        Are you sure you want to create these charges?
      </span>
    </v-card-text>
  </base-dialog>
</template>

<script>
import DatePicker from '../../../BaseComponents/DatePicker'
import BaseDialog from '../../../BaseComponents/BaseDialog'

export default {
  components: {
    DatePicker,
    BaseDialog
  },
  props: ['showDialog'],
  data () {
    return {
      asOfDate: null,
      confirm: false
    }
  },
  methods: {
    cancel () {
      this.$emit('cancelDialog')
    },
    save () {
      if (!this.confirm) {
        this.confirm = true
      } else {
        this.$emit('confirmReconciliationCharges', this.asOfDate)
      }
    },
    setAsOfDate (e) {
      this.asOfDate = e
    }
  },
  computed: {
    title () {
      return this.confirm ? 'Confirm Reconciliation Charges' : 'Create Reconciliation Charges'
    },
    saveButtonText () {
      return this.confirm ? 'Confirm Charges' : 'Create Charges'
    },
    saveButtonColor () {
      return this.confirm ? 'var(--v-resGreen-base)' : 'var(--v-resBlue-darken1)'
    }
  }
}
</script>
