<template>
  <v-container fluid class="resGray lighten-6">
    <v-app-bar flat height="36">
      <v-switch
        v-if="numberOfSpaces > 1"
        v-model="editPrimarySpace"
        color="var(--v-resGreen-base)"
        class="toggle-switch"
        label="Edit primary space">
      </v-switch>
      <v-spacer></v-spacer>
      <round-button
        color='var(--v-resGray-darken3)'
        height='30px'
        width='105px'
        :dark='true'
        :depressed='true'
        buttonText='+ Add Space'
        backgroundColor='var(--v-resGreen-lighten1)'
        borderColor='var(--v-resGray-lighten1)'
        buttonClass='mr-2'
        @click="addSpace()">
      </round-button>
    </v-app-bar>
    <v-row>
      <v-col cols="12" class="mt-3">
        <data-table
          class="mx-6"
          :headers="headers"
          :tableData="getData"
          :showActions="true"
          :searchString="searchString"
          :selectOneRow="numberOfSpaces > 1"
          :selectRowActive="editPrimarySpace"
          :initialSelectedRow="primarySpaceRow"
          :selectedRowIcon="'stars'"
          :selectedRowValue="'isPrimary'"
          @canceled="cancel"
          @dataChanged="save"
          @unlockEditing="unlockEdit"
          @itemDeleteRequested="openDeleteDialog"
          @selectUniqueRow="makePrimarySpace">
        </data-table>
      </v-col>
    </v-row>
    <!-- Dialogs -->
    <lease-spaces-dialog
      @cancel="cancelLeaseSpacesDialog"
      :showDialog="leaseSpacesDialog"
      v-if="leaseSpacesDialog"
      @save="saveLeaseSpacesDialog">
    </lease-spaces-dialog>
    <delete-dialog
      :showDialog="deleteDialog"
      :message="'Please confirm that you would like to delete this space from the lease. Completing this action will permanently remove this space from the lease. You can add it back using the Add Space button. Spaces tied to transactions cannot be deleted.'"
      @confirmDelete="confirmDeleteDialog"
      @cancelDelete="cancelDeleteDialog">
    </delete-dialog>
  </v-container>
</template>

<script>
import DataTable from '../BaseComponents/Table'
import RoundButton from '../BaseComponents/RoundButton'
import LeaseSpacesDialog from './Dialogs/LeaseSpacesDialog'
import DeleteDialog from '../BaseComponents/DeleteDialog'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    DataTable,
    RoundButton,
    LeaseSpacesDialog,
    DeleteDialog
  },
  data () {
    return {
      searchString: null,
      editing: false,
      previousVale: {},
      deleteItem: null,
      leaseSpacesDialog: false,
      deleteDialog: false,
      editPrimarySpace: false
    }
  },
  methods: {
    ...mapActions(['changeLoading', 'fetchData', 'changeData', 'deleteData', 'initializeStoreItem']),
    addSpace () {
      this.leaseSpacesDialog = true
    },
    unlockEdit (item) {
      if (!this.editing) {
        this.editedIndex = this.getData.indexOf(item)
        this.previousValue = Object.assign({}, item)
        item.readonly = false
        this.editing = true
        Object.assign(this.getData[this.editedIndex], item)
      }
    },
    cancelLeaseSpacesDialog () {
      this.leaseSpacesDialog = false
    },
    async getLeaseSpaces () {
      this.fetchData({newPage: 'LeaseSpaces', params: { LeaseId: this.currentSelectedItem.lease, PropertyId: this.currentSelectedItem.property }}).then(() => {
        this.editing = false
      }).catch(error => { console.log(error.response) })
    },
    saveLeaseSpacesDialog () {
      this.fetchData({ newPage: 'LeaseSpaces', params: { LeaseId: this.currentSelectedItem.lease } }).then(() => {
        this.leaseSpacesDialog = false
      }).catch(error => { console.log(error.response) })
    },
    save (item) {
      item.readonly = true
      this.changeData({ currentPage: 'LeaseSpaces', newData: item }).then(() => {
        this.fetchData({ newPage: 'LeaseSpaces', params: { LeaseId: this.currentSelectedItem.lease } }).then(() => {
          this.editing = false
        })
      }).catch(error => { console.log(error.response) })
    },
    cancel (item) {
      this.editedIndex = this.getData.indexOf(item)
      Object.assign(this.getData[this.editedIndex], this.previousValue)
      this.previousValue = {}
      this.editing = false
      this.isSorted = true
    },
    openDeleteDialog (val) {
      this.deleteItem = val
      this.deleteDialog = true
    },
    confirmDeleteDialog () {
      this.deleteData({ currentPage: 'LeaseSpaces', removeItem: this.deleteItem }).then(() => {
        this.deleteDialog = false
      }).catch(error => { console.log(error.response) })
      this.deleteItem = null
    },
    cancelDeleteDialog () {
      this.deleteDialog = false
    },
    makePrimarySpace (e) {
      if (!e.isPrimary) {
        this.changeData({ currentPage: 'LeaseSpacesPrimary', newData: e }).then(() => {
          this.initializeStoreItem('LeaseSpaces')
          this.getLeaseSpaces()
        }).catch(error => { console.log(error.response) })
      }
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentSelectedItem']),
    getData () {
      return this.currentData('LeaseSpaces')
    },
    headers () {
      return this.currentHeaders('LeaseSpaces').map(h => {
        if (this.getData.length <= 1) {
          h.hoverIcon = null
        }
        return h
      })
    },
    numberOfSpaces () {
      if (this.getData) {
        return this.getData.length
      }
      return 0
    },
    primarySpaceRow () {
      return this.getData.filter(d => {
        return d.isPrimary
      })[0]
    }
  },
  watch: {
    numberOfSpaces: function (val) {
      if (val > 1) {
        this.warningBar = true
      }
    }
  },
  created () {
    this.getLeaseSpaces()
  }
}
</script>

<style lang="scss" scoped>
.toggle-switch {
  ::v-deep label {
    font-size: 12px;
    font-weight: 800;
    color: var(--v-resGray-darken5);
  }
}
</style>
