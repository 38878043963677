<template>
  <div class="resGray lighten-6">
    <estimates v-if="estimatesActive"
      @openCyclePage='openCyclePage'>
    </estimates>
    <add-estimates v-if="cyclesActive"
      :poolInfo="poolInfo"
      @openPoolsPage="openPoolsPage">
    </add-estimates>
  </div>
</template>

<script>
import Estimates from './Estimates'
import AddEstimates from './AddEstimates'

export default {
  components: {
    Estimates,
    AddEstimates
  },
  data () {
    return {
      cyclesActive: false,
      estimatesActive: true,
      poolInfo: null
    }
  },
  methods: {
    openCyclePage (val) {
      this.estimatesActive = false
      this.cyclesActive = true
      this.poolInfo = val
    },
    openPoolsPage () {
      this.poolInfo = {}
      this.estimatesActive = true
      this.cyclesActive = false
    }
  }
}
</script>
