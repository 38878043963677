<template>
  <v-container fluid class="resGray lighten-6">
    <v-row>
      <v-col cols="12" class="pb-0">
        <div class="mx-6">
          <span class="resGray-darken5--text title">Pools</span>
        </div>
      </v-col>
    </v-row>
    <v-layout>
      <v-container fluid grid-list-md ml-6 v-if="!isMobile">
        <v-layout wrap>
          <v-flex shrink v-for="pool in poolNameList" :key="pool" class="mr-4">
            <pools-card
              :content="pool"
              :newCard="false"
              :isSelected="pool == selectedPool ? true : false"
              @click.native="selectPool(pool)">
            </pools-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-layout>
    <v-row>
      <v-col cols="12" class="pb-0">
        <div class="mx-6">
          <span class="resGray-darken5--text title">Property Reconciliation Cycles</span>
        </div>
      </v-col>
    </v-row>
    <v-layout>
      <v-container fluid grid-list-md ml-6>
        <v-layout wrap>
          <v-flex shrink v-for="cycle in cycles" :key="cycle.property" class="mr-4">
            <cycles-card
              :content="cycle"
              @click.native="openCycle(cycle)">
            </cycles-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-layout>
  </v-container>
</template>

<script>
import PoolsCard from '../PoolsCycles/PoolsCard'
import CyclesCard from '../PoolsCycles/CyclesCard'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    PoolsCard,
    CyclesCard
  },
  data () {
    return {
      selectedPool: {}
    }
  },
  methods: {
    ...mapActions(['fetchData']),
    selectPool (val) {
      this.selectedPool = val
    },
    openCycle (val) {
      this.$emit('openCyclePage', val)
    }
  },
  computed: {
    ...mapGetters(['currentData']),
    isMobile () {
      return this.$vuetify.breakpoint.mobile
    },
    poolNameList () {
      let poolNames = this.currentData('Recovery Pools').map(x => x.chargeCodeName)
      return [...new Set(poolNames)]
    },
    cycles () {
      return this.currentData('Recovery Pools')
        .filter(x => x.chargeCodeName === this.selectedPool)
        .map(x => {
          x.poolName = x.chargeCode
          x.current = x.currentCycleYear
          x.cycles = x.cycleCount
          return x
        })
    }
  },
  created () {
    this.fetchData({ newPage: 'Recovery Pools' })
      .then(() => {
        if (this.poolNameList && this.poolNameList.length > 0) this.selectedPool = this.poolNameList[0]
      }).catch(error => { console.log(error.response) })
  }
}
</script>
