<template>
  <v-container class="resGray lighten-6">
    <distribute-details>
    </distribute-details>
  </v-container>
</template>

<script>
import DistributeDetails from './PrepaidComponents/DistributeDetails'

export default {
  components: {
    DistributeDetails
  },
  data () {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
