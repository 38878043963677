<template>
  <v-card-text class="pa-1">
    <h3>Select available spaces for  {{ this.currentLease.name }}.</h3>
    <div class="pt-4">
      <span class="font-weight-medium">Selected Spaces: {{ this.Spaces.selectedSpaces.length }}</span>
      <span class="font-weight-regular float-right">Lease term: {{ currentLease.startDate }} to {{ currentLease.endDate }}</span>
    </div>
    <v-card max-height="240" flat>
      <div style="overflow: auto">
        <v-card max-height="235" flat>
          <data-table
            class="space__table"
            :headers="headers"
            :selectableRows="true"
            :tableData="getData"
            :tableKey="'propertySpace'"
            :showActions="false"
            :editOnly='false'
            :noFooter="true"
            :noPagination="true"
            :baseRowPerPage="50"
            @selectionChanged="changeSelected"
            :noData="'No information to display.'">
          </data-table>
        </v-card>
      </div>
    </v-card>
    <v-row no-gutters class="mt-3">
      <v-col>
        <date-picker
          :initialDate="Spaces.sameAsLeaseDate ? Spaces.planOccupyDate : null"
          :defaultNull="!Spaces.sameAsLeaseDate"
          :label="'Choose Actual Move-In Date. (Optional)'"
          @dateSelected="updatePlanOccupyDate">
        </date-picker>
      </v-col>
    </v-row>
    <v-row no-gutters class="checkbox__container">
      <v-col>
        <v-checkbox
          height="20"
          :ripple="false"
          v-model="Spaces.sameAsLeaseDate"
          class="shrink ma-0 pa-0"
          color="var(--v-resBlue-darken1)"
          label="Move tenant in">
        </v-checkbox>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DatePicker from '../../BaseComponents/DatePicker'
import DataTable from '../../BaseComponents/Table'
import moment from 'moment'

export default {
  components: {
    DatePicker,
    DataTable
  },
  props: ['showDialog'],
  data () {
    return {
      Spaces: {
        sameAsLeaseDate: null,
        selectedSpaces: [],
        startDate: null
      }
    }
  },
  methods: {
    ...mapActions(['clearCharge', 'changeSpaces', 'fetchData', 'changeSpaceIndex', 'changeData', 'initializeStoreItem', 'changeReview']),
    updatePlanOccupyDate (val) {
      this.Spaces.planOccupyDate = val
      this.changeSpaces(this.Spaces)
      this.clearCharge()
      this.changeReview(false)
    },
    changeSelected (val) {
      this.Spaces.selectedSpaces = val
      this.changeSpaces(this.Spaces)
      this.clearCharge()
      this.changeReview(false)
    },
    calculateDate () {
      this.Spaces.sameAsLeaseDate = this.currentLease ? moment(this.currentLease.startDate).isSameOrBefore(new Date()) : null
      this.Spaces.planOccupyDate = this.currentLease.startDate
    },
    saveSpaces () {
      if (this.currentSpaces.selectedSpaces && this.currentSpaces.selectedSpaces.length > 0) {
        this.currentSpaces.lease = this.currentLease.lease
        this.currentSpaces.leaseRowVersion = this.currentLease.rowVersion
        this.currentSpaces.planOccupyDate = this.Spaces.planOccupyDate
        this.currentSpaces.planVacateDate = this.currentLease.endDate
        this.currentSpaces.leaseRevision = this.currentLease.leaseRevision
        this.changeData({ currentPage: 'LeaseAvailableSpaces', newData: this.currentSpaces }).then(() => {
          this.fetchData({ newPage: 'LeaseAvailableSpaces', params: { Property: this.currentLease.property, Lease: this.currentLease.lease, StartDate: this.currentLease.startDate, EndDate: this.currentLease.endDate } }).then(() => {
            this.changeSpaceIndex()
          })
        }).catch(error => { console.log(error.response) })
      }
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentLease', 'currentStep', 'currentSpaces', 'currentReview']),
    headers: {
      get () {
        return this.currentHeaders('LeaseAvailableSpaces')
      }
    },
    getData: {
      get () {
        return this.currentData('LeaseAvailableSpaces')
      }
    }
  },
  watch: {
    getData: function (val) {
      var newSelected = []
      val.forEach(function (item) {
        if (item.propertySpaceStatus === 'C1') {
          newSelected.push(item)
        }
      })
      if (newSelected.length > 0) {
        this.Spaces.selectedSpaces = newSelected
      }
    }
  },
  created () {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'setNextStep') {
        if (this.currentStep === 'Spaces') {
          this.calculateDate()
          this.fetchData({ newPage: 'LeaseAvailableSpaces', params: { Property: this.currentLease.property, Lease: this.currentLease.lease, StartDate: this.currentLease.startDate, EndDate: this.currentLease.endDate } })
        } else if (this.currentStep === 'Charges') {
          this.saveSpaces()
        }
      }
    })
  },
  beforeDestroy () {
    this.unsubscribe()
  }
}
</script>

<style lang="scss" scoped>
  .checkbox__container {
    ::v-deep label {
      color: var(--v-resBlack-base);
      font-size: 14px;
    }
  }
  .space__table {
    ::v-deep span {
      font-size: 12px;
    }
    ::v-deep thead tr {
      height: 30px !important;
    }
    ::v-deep thead th {
      padding: 0 !important;
      height: 30px !important;
    }
    ::v-deep tbody tr {
      height: 30px !important;
    }
    ::v-deep tbody td {
      padding: 0 !important;
      height: 30px !important;
    }
  }
</style>
