<template>
  <base-dialog
    :showDialog="showDialog"
    :title="'Charge Code History'"
    :titleClass="'dark-header'"
    :hideClose="true"
    :maxWidth="600"
    :saveButtonOnly="true"
    :saveButtonText="'Close'"
    :saveButtonColor="'var(--v-resBlue-darken1)'"
    @save="closeDialog">
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="3">
          <div>Charge Code</div>
          <div><b>{{ chargeCode.code }}</b></div>
        </v-col>
        <v-col cols="4">
          <div>Charge Description</div>
          <div><b>{{ chargeCode.description }}</b></div>
        </v-col>
        <v-col cols="3">
          <div>Date</div>
          <div><b>{{ chargeCode.date }}</b></div>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-3">
        <v-col cols="12">
          <data-table
            transition="fade-transition"
            v-if="chargeCodeHistory"
            :headers="headers"
            :tableData="chargeCodeHistory"
            :allowNullColumn="true"
            :noData="'No information to display.'">
          </data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </base-dialog>
</template>

<script>
import BaseDialog from '../../BaseComponents/BaseDialog'
import DataTable from '../../BaseComponents/Table'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    BaseDialog,
    DataTable
  },
  props: ['showDialog', 'chargeCode'],
  methods: {
    ...mapActions(['fetchData']),
    closeDialog () {
      this.$emit('closeDialog')
    },
    getChargeCodeHistory () {
      this.fetchData({
        newPage: 'LeaseChargesChargeCodeHistory',
        params: {
          property: this.currentSelectedItem.property,
          lease: this.currentSelectedItem.lease,
          includePaidInvoices: true,
          leaseCharge: this.chargeCode.leaseCharge,
          chargeCode: this.chargeCode.code
        }
      }).catch(error => { console.log(error.response) })
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentSelectedItem']),
    chargeCodeHistory () {
      return this.currentData('LeaseChargesChargeCodeHistory')
    },
    headers () {
      return this.currentHeaders('LeaseChargesChargeCodeHistory')
    }
  },
  created () {
    this.getChargeCodeHistory()
  }
}
</script>
