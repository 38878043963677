<template>
  <v-container fluid class="resGray lighten-6">
    <v-app-bar flat height="36">
      <toggle-button-group
        class="ml-5"
        :buttons="toggleButtons"
        @toggled="changeTable">
      </toggle-button-group>
      <v-spacer></v-spacer>
      <search
        class='pb-0-plus mr-3'
        :placeholder="searchPlaceholder"
        @searchStringUpdated="updateSearchString">
      </search>
      <!-- <round-button
        color='var(--v-resGray-darken3)'
        width='40px'
        height='30px'
        :outline='true'
        :icon='true'
        :depressed='true'
        iconText='get_app'
        backgroundColor='white'
        borderColor='var(--v-resGray-lighten1)'
        buttonClass='ml-4 mt-0'>
      </round-button> -->
      <round-button
        color='var(--v-resGray-darken3)'
        height='30px'
        :dark='true'
        :depressed='true'
        :buttonText="newButtonLabel"
        backgroundColor='var(--v-resGreen-lighten1)'
        borderColor='var(--v-resGray-lighten1)'
        buttonClass='mr-2'
        @click="newBuildingItem()">
      </round-button>
    </v-app-bar>
    <v-row>
      <v-col cols="12" class="mt-3">
        <data-table
          class="mx-6"
          :headers="headers"
          :tableData="getData"
          :showActions="true"
          :noData="'No information to display.'"
          :searchString="searchString"
          @ClickAttachments="openAttachments"
          @unlockEditing="unlockEditing"
          @itemDeleteRequested="deleteItem">
        </data-table>
      </v-col>
    </v-row>
    <!-- Dialogs -->
    <buildings-dialog
      :editedBuilding="editedBuilding"
      :showDialog="editBuildingDialog"
      :editMode="mode"
      @cancel="cancelEditBuilding"
      @save="saveBuilding">
    </buildings-dialog>
    <areas-dialog
      :editedArea="editedArea"
      :showDialog="editAreaDialog"
      :editMode="mode"
      @cancel="cancelEditArea"
      @save="saveArea">
    </areas-dialog>
    <delete-dialog
      :showDialog="deleteDialog"
      @confirmDelete="confirmDeleteDialog"
      @cancelDelete="cancelDeleteDialog">
    </delete-dialog>
    <attachments
      v-if="attachmentsDialogOpen"
      :showDialog="attachmentsDialogOpen"
      @cancel="closeAttachments"
      :primaryKey="attachmentAreaId"
      applicationRole="AREA">
    </attachments>
  </v-container>
</template>

<script>
import Attachments from './Dialogs/Attachments'
import DataTable from '../BaseComponents/Table'
import Search from '../BaseComponents/Search'
import ToggleButtonGroup from '../BaseComponents/ToggleButtonGroup'
import RoundButton from '../BaseComponents/RoundButton'
import { mapGetters, mapActions } from 'vuex'
import BuildingsDialog from './Dialogs/Buildings'
import AreasDialog from './Dialogs/Areas'
import DeleteDialog from '../BaseComponents/DeleteDialog'

export default {
  components: {
    Attachments,
    DataTable,
    Search,
    ToggleButtonGroup,
    RoundButton,
    BuildingsDialog,
    DeleteDialog,
    AreasDialog
  },
  data () {
    return {
      currentToggle: 0,
      searchString: null,
      toggleButtons: [
        { text: 'Buildings' },
        { text: 'Areas' }
      ],
      mode: 'Edit',
      attachmentsDialogOpen: false,
      attachmentAreaId: 0,
      editBuildingDialog: false,
      editedBuilding: {},
      editAreaDialog: false,
      editedArea: {},
      deleteDialog: false,
      itemToDelete: null
    }
  },
  methods: {
    ...mapActions(['changeLoading', 'fetchData', 'changeData', 'deleteData', 'changeTab']),
    changeTable (value) {
      this.currentToggle = value
      this.editedBuilding = {}
      if (value === 0) {
        this.fetchData({ newPage: 'PropertiesBuildings', params: { PropertyId: this.currentSelectedItem.property } }).catch(error => { console.log(error.response) })
      } else {
        this.fetchData({ newPage: 'PropertyAreas', params: { PropertyId: this.currentSelectedItem.property } }).catch(error => { console.log(error.response) })
      }
    },
    updateSearchString (val) {
      this.searchString = val
    },
    unlockEditing (val) {
      this.mode = 'Edit'
      if (this.currentToggle === 0) {
        this.editedBuilding = JSON.parse(JSON.stringify(val))
        this.editBuildingDialog = true
      } else {
        this.editedArea = JSON.parse(JSON.stringify(val))
        this.editAreaDialog = true
      }
    },
    newBuildingItem () {
      this.mode = 'New'
      if (this.currentToggle === 0) {
        this.editedBuilding = {
          property: this.currentSelectedItem.property
        }
        this.editBuildingDialog = true
      } else {
        this.editedArea = {}
        this.editAreaDialog = true
      }
    },
    deleteItem (item) {
      this.itemToDelete = item
      this.deleteDialog = true
    },
    saveBuilding (item) {
      this.changeData({ currentPage: 'PropertiesBuildings', newData: item }).then(() => {
        this.fetchData({ newPage: 'PropertiesBuildings', params: { PropertyId: this.currentSelectedItem.property } }).then(() => {
          this.editBuildingDialog = false
        })
      }).catch(error => { console.log(error.response) })
    },
    cancelEditBuilding () {
      this.editBuildingDialog = false
    },
    saveArea () {
      this.changeData({ currentPage: 'PropertyAreas', newData: this.editedArea }).then(() => {
        this.fetchData({ newPage: 'PropertyAreas', params: { PropertyId: this.currentSelectedItem.property } }).then(() => {
          this.editAreaDialog = false
        })
      }).catch(error => { console.log(error.response) })
    },
    cancelEditArea () {
      this.editAreaDialog = false
    },
    cancelDeleteDialog () {
      this.deleteDialog = false
      this.itemToDelete = null
    },
    confirmDeleteDialog () {
      this.deleteDialog = false
      if (this.currentToggle === 0) {
        this.deleteData({ currentPage: 'PropertiesBuildings', removeItem: this.itemToDelete }).catch(error => { console.log(error.response) })
      } else {
        this.deleteData({ currentPage: 'PropertyAreas', removeItem: this.itemToDelete }).catch(error => { console.log(error.response) })
      }
      this.snackbar = true
      this.snackbarMsg = 'deleted'
    },
    openAttachments (val) {
      this.attachmentAreaId = val.space
      this.attachmentsDialogOpen = true
    },
    closeAttachments () {
      this.attachmentsDialogOpen = false
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentSelectedItem']),
    headers: {
      get () {
        if (this.currentToggle === 0) {
          return this.currentHeaders('PropertiesBuildings')
        } else {
          return this.currentHeaders('PropertyAreas')
        }
      }
    },
    getData: {
      get () {
        if (this.currentToggle === 0) {
          return this.currentData('PropertiesBuildings')
        } else {
          return this.currentData('PropertyAreas')
        }
      }
    },
    newButtonLabel () {
      return `+ New ${this.currentToggle === 0 ? 'Building' : 'Area'}`
    },
    searchPlaceholder () {
      return `Search ${this.currentToggle === 0 ? 'Building' : 'Area'}s`
    }
  },
  created () {
    this.fetchData({ newPage: 'PropertiesBuildings', params: { PropertyId: this.currentSelectedItem.property } }).catch(error => { console.log(error.response) })
  }
}
</script>
