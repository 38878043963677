<template>
  <v-container fluid class="resGray lighten-6">
    <v-app-bar flat>
      <date-picker
        class="ml-2 mr-3"
        :label="'As of date'"
        :defaultNull="true"
        :initialDate="asOfDate"
        @dateSelected="setAsOfDate">
      </date-picker>
      <drop-down
        label="Property Name"
        class="mb-5"
        :selectedValue="{ text: propertyName }"
        :items="'Properties'"
        :multiple='false'
        width="250px"
        @itemSelected="setProperty">
      </drop-down>
      <v-spacer></v-spacer>
      <search
        @searchStringUpdated="updateSearchString"
        placeholder='Enter lease name'
        :width="'250px'"
        class="mt-4 mr-2">
      </search>
    </v-app-bar>
    <v-row>
      <v-col cols="12">
        <data-table
          :headers="headers"
          :tableData="getData"
          @UnapplyCredit="openUnapplyDialog"
          @ShowAppliedCreditDetails="openAppliedCreditDetails"
          :searchString="searchString"
          :noData="'Please use search criteria above for results.'"
          class="mx-6">
        </data-table>
      </v-col>
    </v-row>
    <!-- Dialogs -->
    <delete-dialog
      :showDialog="unapplyCreditConfirm"
      v-if="unapplyCreditConfirm"
      :title="'Are you sure?'"
      confirmButton="Yes, unapply credit"
      confirmButtonWidth="108px"
      @confirmDelete="confirmDialog"
      message="Please confirm that you would like to unapply this credit. Doing so will re-open all charges this credit is applied to."
      @cancelDelete="cancelDialog">
      <v-flex>
        <v-layout mt-2 ml-6>
          <v-flex>
            <div class="caption resGray-darken1--text mb-1">Application date</div>
            <div class="caption">{{ currentCreditToUnapply.applicationDate }}</div>
          </v-flex>
          <v-flex>
            <div class="caption resGray-darken1--text mb-1">Reference number</div>
            <div class="caption">{{ currentCreditToUnapply.referenceNumber }}</div>
          </v-flex>
        </v-layout>
      </v-flex>
    </delete-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DropDown from '../../../BaseComponents/DropDown'
import DatePicker from '../../../BaseComponents/DatePicker'
import Search from '../../../BaseComponents/Search'
import DataTable from '../../../BaseComponents/Table'
import DeleteDialog from '../../../BaseComponents/DeleteDialog'
import moment from 'moment'
export default {
  props: ['selectedProperty', 'selectedDate'],
  components: {
    Search,
    DropDown,
    DatePicker,
    DataTable,
    DeleteDialog
  },
  data () {
    return {
      property: this.selectedProperty.value,
      propertyName: this.selectedProperty.text,
      asOfDate: this.selectedDate,
      unapplyCreditConfirm: false,
      currentCreditToUnapply: {},
      searchString: ''
    }
  },
  methods: {
    ...mapActions(['fetchData', 'changeData', 'initializeStoreItem']),
    setAsOfDate (val) {
      this.asOfDate = val
      this.getAppliedCredits()
    },
    setProperty (val) {
      this.propertyName = val.text
      this.property = val.value
      this.getAppliedCredits()
    },
    getAppliedCredits () {
      if ((this.asOfDate !== null && this.asOfDate !== undefined) && ((this.property !== null && this.property !== undefined))) {
        this.fetchData({newPage: 'UnapplyCredits', params: { Property: this.property, AsOfDate: this.asOfDate }}).catch(error => { console.log(error.response) })
      }
    },
    updateSearchString (val) {
      this.searchString = val
    },
    openAppliedCreditDetails (val) {
      let property = {
        value: this.property,
        text: this.propertyName
      }
      this.$emit('setProperty', property)
      this.$emit('setDate', this.asOfDate)
      this.$emit('openAppliedCreditDetails', val)
    },
    openUnapplyDialog (val) {
      val.applicationDate = moment(val.applicationDate).format('L')
      this.currentCreditToUnapply = val
      this.unapplyCreditConfirm = true
    },
    cancelDialog () {
      this.unapplyCreditConfirm = false
    },
    confirmDialog () {
      this.changeData({ currentPage: 'UnapplyCredits',
        newData: {
          companyId: this.currentCreditToUnapply.companyId,
          bankAccountCode: this.currentCreditToUnapply.bankAccountCode,
          depositSlipId: this.currentCreditToUnapply.depositSlipId,
          bankDepositId: this.currentCreditToUnapply.depositId,
          leaseId: this.currentCreditToUnapply.payer,
          referenceNumber: this.currentCreditToUnapply.referenceNumber,
          applicationDate: this.currentCreditToUnapply.applicationDate,
          payerType: this.currentCreditToUnapply.payerType
        }
      }).then(() => {
        this.fetchData({newPage: 'UnapplyCredits', params: { Property: this.property, AsOfDate: this.asOfDate }})
        this.unapplyCreditConfirm = false
      }).catch(error => { console.log(error.response) })
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders']),
    canSearch () {
      if ((this.asOfDate !== null && this.asOfDate !== undefined) && ((this.property !== null && this.property !== undefined))) {
        return true
      }
      return false
    },
    getData () {
      return this.currentData('UnapplyCredits')
    },
    headers () {
      return this.currentHeaders('UnapplyCredits')
    }
  },
  created () {
    this.fetchData({ newPage: 'UnapplyCredits' }).catch(error => { console.log(error.response) })
  }
}
</script>
