<template>
  <div class="resGray lighten-6">
    <unapply-credits-table
      v-if="!viewAppliedCreditDetails"
      @setDate="setDate"
      :selectedProperty="selectedProperty"
      :selectedDate="selectedDate"
      @setProperty="setProperty"
      @openAppliedCreditDetails="getInfo">
    </unapply-credits-table>
    <applied-credit-summary
      v-if="viewAppliedCreditDetails"
      @returnToGrid="refreshGrid"
      :currentAppliedCredit="currentAppliedCredit"
      :appliedCreditDetails="appliedCreditDetails">
    </applied-credit-summary>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UnapplyCreditsTable from './UnapplyCreditsComponents/UnapplyCreditsTable'
import AppliedCreditSummary from './UnapplyCreditsComponents/AppliedCreditSummary'
import moment from 'moment'

export default {
  components: {
    UnapplyCreditsTable,
    AppliedCreditSummary
  },
  data () {
    return {
      appliedCreditDetails: {},
      currentAppliedCredit: 0,
      viewAppliedCreditDetails: false,
      selectedProperty: {},
      selectedDate: moment().startOf('month')
    }
  },
  methods: {
    ...mapActions(['initializeStoreItem', 'fetchData']),
    getInfo (val) {
      this.fetchData({ newPage: 'BankDepositSlipDraftsFullInfo', params: { CompanyId: val.companyId, BankAccountCode: val.bankAccountCode, BankDepositSlipId: val.depositSlipId } }).then(() => {
        this.appliedCreditDetails = this.currentData('BankDepositSlipDraftsFullInfo')
        if (this.viewAppliedCreditDetails === true) {
          this.fetchData({ newPage: 'AppliedCreditsDistributionDetails', params: { Lease: val.payer, BankDepositId: val.depositId } }).then(() => {
            this.viewAppliedCreditDetails = true
            this.currentAppliedCredit = val.depositId
          })
        } else {
          this.viewAppliedCreditDetails = true
          this.currentAppliedCredit = val.depositId
        }
      }).catch(error => { console.log(error.response) })
    },
    setDate (val) {
      this.selectedDate = val
    },
    setProperty (val) {
      if (val.value !== null) {
        this.selectedProperty = val
      }
    },
    refreshGrid () {
      this.fetchData({newPage: 'UnapplyCredits', params: { Property: this.selectedProperty.value, AsOfDate: this.selectedDate }}).then(() => {
        this.viewAppliedCreditDetails = false
      }).catch(error => { console.log(error.response) })
    }
  },
  computed: {
    ...mapGetters(['currentData'])
  }
}
</script>

<style lang="scss" scoped>
</style>
