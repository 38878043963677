<template>
  <v-card height="90" flat class="mx-4">
    <v-layout pt-2 pr-4>
      <v-spacer></v-spacer>
      <a class="font-weight-medium" @click="openEditPoolDialog">Edit Pool</a>
    </v-layout>
    <v-layout mt-2 mx-4>
      <v-flex shrink mr-4>
        <div class="resGray-darken1--text">Cycle year</div>
        <div class="resGray-darken5--text font-weight-medium">{{ cycleInfo.cycleYear || '-' }}</div>
      </v-flex>
      <v-flex shrink mr-4>
        <div class="resGray-darken1--text">Pool cycle #</div>
        <div class="resGray-darken5--text font-weight-medium">{{ cycleInfo.cyclePeriod || '-' }}</div>
      </v-flex>
      <v-flex shrink mr-4>
        <div class="resGray-darken1--text">Cycle start date</div>
        <div class="resGray-darken5--text font-weight-medium">{{ cycleInfo.cycleStartDate | dateFilter(cycleInfo.cycleStartDate) }}</div>
      </v-flex>
      <v-flex shrink mr-4>
        <div class="resGray-darken1--text">Cycle end date</div>
        <div class="resGray-darken5--text font-weight-medium">{{ cycleInfo.cycleEndDate | dateFilter(cycleInfo.cycleEndDate) }}</div>
      </v-flex>
      <v-flex shrink mr-4>
        <div class="resGray-darken1--text">Estimate charge code</div>
        <div class="resGray-darken5--text font-weight-medium">{{ cycleInfo.estimateChargeCode || '-' }}</div>
      </v-flex>
      <v-flex grow>
        <div class="resGray-darken1--text">Area measure</div>
        <div class="resGray-darken5--text font-weight-medium">{{ cycleInfo.areaMeasureTypeName || '-' }}</div>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex shrink mr-4>
        <div class="resGray-darken1--text">Property SqFt</div>
        <div class="resGray-darken5--text font-weight-medium text-right">{{ cycleInfo.propertyArea ? cycleInfo.propertyArea.toLocaleString() : '-' }}</div>
      </v-flex>
      <v-flex shrink>
        <div class="resGray-darken1--text">Total Spaces SqFt</div>
        <div class="resGray-darken5--text font-weight-medium text-right">{{ cycleInfo.totalSpaceSqFt ? cycleInfo.totalSpaceSqFt.toLocaleString() : '-' }}</div>
      </v-flex>
    </v-layout>
    <!-- Dialogs -->
    <edit-pool-dialog
      :showDialog="editPoolDialog"
      v-if="editPoolDialog"
      :poolInfo="editPoolInfo"
      @cancel="cancel"
      @complete="complete"
      @deletePool="deletePool">
    </edit-pool-dialog>
  </v-card>
</template>

<script>
import moment from 'moment'
import EditPoolDialog from './EditPoolDialog/EditPoolDialog'
import { mapGetters } from 'vuex'
export default {
  props: ['cycleInfo'],
  components: {
    EditPoolDialog
  },
  data () {
    return {
      editPoolDialog: false
    }
  },
  methods: {
    openEditPoolDialog () {
      this.editPoolDialog = true
    },
    cancel () {
      this.editPoolDialog = false
    },
    complete (val) {
      this.editPoolDialog = false
      this.$emit('poolEdited', val)
    },
    deletePool () {
      this.$emit('deletePool')
    }
  },
  filters: {
    dateFilter (val) {
      if (val) {
        return moment(val).format('L')
      }
      return '-'
    }
  },
  computed: {
    ...mapGetters(['currentSelectedPool', 'currentData']),
    editPoolInfo () {
      return this.currentData('Recovery Pools').find(x => {
        return x.chargeCode === this.currentSelectedPool.chargeCode && x.property === this.currentSelectedPool.property
      })
    }
  }
}
</script>
