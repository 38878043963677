<template>
  <div>
    <ledger-history-tabs>
      <router-view>
      </router-view>
    </ledger-history-tabs>
  </div>
</template>

<script>
import LedgerHistoryTabs from './LedgerHistoryTabs'

export default {
  data () {
    return {}
  },
  components: {
    LedgerHistoryTabs
  }
}
</script>
