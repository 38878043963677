<template>
  <div class="mx-4">
    <v-tabs
      v-model="active"
      class="reconciliation__tabs"
      hide-slider>
      <v-tab
        v-for="n in totalTabs"
        :href="`#tab-${n}`"
        :key="n"
        :disabled="checkIfTabActive(n)">
        <span class="caption text-uppercase">{{ n }}</span>
      </v-tab>
      <v-spacer></v-spacer>
      <v-tabs-items v-model="active">
        <v-tab-item
          v-for="n in totalTabs"
          :key="n"
          :value="`tab-${n}`">
          <v-card flat class="tabs-card">
            <data-table
              v-if="tableData"
              :tableData="tableData"
              :headers="tableHeaders"
              :showTotals="true"
              @launchDetails="launchDetails">
            </data-table>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
    <v-layout mt-4>
      <round-button
        v-if="activeTabIndex===1 && !cycle.reconcileIsLocked"
        buttonClass=""
        flat
        outline
        buttonText="Previous"
        height="30px"
        borderColor="var(--v-resBlue-darken1)"
        backgroundColor="var(--v-resWhite-base)"
        buttonTextColor='var(--v-resBlue-darken1)'
        color="var(--v-resBlue-darken1)"
        @click="previousTab">
      </round-button>
      <v-spacer></v-spacer>
      <!-- <round-button
        v-if="activeTabIndex===1 && !cycle.reconcileIsLocked"
        buttonClass=""
        borderColor="var(--v-resRed-base)"
        backgroundColor="var(--v-resWhite-base)"
        color="var(--v-resRed-base)"
        flat
        outline
        buttonText="Delete Charges"
        height="30px"
        @click="showDeleteChargesDialog">
      </round-button> -->
      <round-button
        v-if="activeTabIndex===1 && !cycle.reconcileIsLocked"
        buttonClass="ml-3"
        flat
        outline
        buttonText="Run Reports"
        height="30px"
        backgroundColor="var(--v-resBlue-darken1)"
        borderColor='var(--v-resBlue-darken1)'
        @click="showReportsDialog">
      </round-button>
      <round-button
        v-if="activeTabIndex===1 && cycle.reconcileIsLocked"
        buttonClass="ml-3"
        flat
        outline
        buttonText="Letters"
        height="30px"
        backgroundColor="var(--v-resBlue-darken1)"
        borderColor='var(--v-resBlue-darken1)'
        @click="showLettersDialog">
      </round-button>
      <round-button
        v-if="!cycle.reconcileIsLocked"
        buttonClass="ml-3"
        flat
        outline
        height="30px"
        :buttonText="buttonText"
        :borderColor="activeTabIndex === 0 ? 'var(--v-resBlue-darken1)' : 'var(--v-resGreen-base)'"
        :backgroundColor="activeTabIndex === 0 ? 'var(--v-resBlue-darken1)' : 'var(--v-resGreen-base)'"
        :disabled="!reconciliationsComplete"
        @click="reviewCharges">
      </round-button>
      <round-button
        v-if="cycle.reconcileIsLocked || cycle.reconcileChargeDate !== null"
        buttonClass="ml-3"
        flat
        outline
        :icon="true"
        :iconText="lockReconciliationIcon"
        borderColor='var(--v-resGray-lighten1)'
        :buttonText="lockReconciliationButtonText"
        :capitalize="true"
        height="30px"
        :backgroundColor="'var(--v-resGreen-base)'"
        @click="showLockReconciliation">
      </round-button>
      <!-- Dialogs -->
      <lock-reconciliation-dialog
        :isCurrentlyLocked="cycle.reconcileIsLocked"
        :showDialog="showLockReconciliationDialog"
        @cancelLockReconciliation="hideLockReconciliationDialog"
        @confirmLockReconciliation="confirmLockReconciliation">
      </lock-reconciliation-dialog>
    </v-layout>
  </div>
</template>

<script>
import RoundButton from '../../BaseComponents/RoundButton'
import DataTable from '../../BaseComponents/Table'
import LockReconciliationDialog from './Dialogs/LockReconciliation'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    DataTable,
    RoundButton,
    LockReconciliationDialog
  },
  props: ['cycle', 'selectedTab'],
  data () {
    return {
      active: null,
      showLockReconciliationDialog: false,
      totalTabs: ['GL Actuals', 'Lease Actuals']
    }
  },
  methods: {
    ...mapActions(['fetchData', 'changeData']),
    async getGlAccounts (refreshData) {
      this.fetchData({
        newPage: 'Reconciliations Expense Accounts',
        params: {
          property: this.cycle.property,
          chargeCode: this.cycle.chargeCode,
          cycleNumber: this.cycle.cycleNumber,
          refreshData: refreshData
        }
      }).catch(error => { console.log(error.response) })
    },
    async getLeaseReconciliations () {
      this.fetchData({
        newPage: 'Reconciliations Lease Expenses',
        params: {
          property: this.cycle.property,
          chargeCode: this.cycle.chargeCode,
          cycleNumber: this.cycle.cycleNumber
        }
      }).catch(error => { console.log(error.response) })
    },
    reviewCharges () {
      if (this.activeTabIndex === 0) {
        this.showLeaseActuals()
        return
      }
      if (this.activeTabIndex === 1) {
        this.showChargesDialog()
      }
    },
    showLeaseActuals () {
      this.active = `tab-${this.totalTabs[1]}`
    },
    showChargesDialog () {
      this.$emit('showChargesDialog')
    },
    showReportsDialog () {
      this.$emit('showReportsDialog')
    },
    showLettersDialog () {
      this.$emit('showLettersDialog')
    },
    checkIfTabActive (e) {
      if (e === this.totalTabs[1] && !this.reconciliationsComplete) {
        return true
      }
    },
    previousTab () {
      this.active = `tab-${this.totalTabs[this.activeTabIndex - 1]}`
    },
    launchDetails (e) {
      this.$emit('launchDetails', e)
    },
    showDeleteChargesDialog () {
      this.$emit('showDeleteChargesDialog')
    },
    showLockReconciliation () {
      this.showLockReconciliationDialog = true
    },
    hideLockReconciliationDialog () {
      this.showLockReconciliationDialog = false
    },
    confirmLockReconciliation (confirm) {
      this.showLockReconciliationDialog = false
      if (confirm === true) {
        this.changeData({
          currentPage: 'Lock Reconciliations',
          newData: {
            property: this.cycle.property,
            chargeCode: this.cycle.chargeCode,
            cycleNumber: this.cycle.cycleNumber,
            lockReconciliation: this.cycle.reconcileIsLocked === false // flip lock
          }
        }).then(() => {
          this.active = `tab-${this.totalTabs[0]}`
          this.$emit('refreshCycle')
        }).catch(error => { console.log(error.response) })
      }
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders']),
    activeTabIndex () {
      if (this.active) {
        return this.totalTabs.indexOf(this.active.split('-')[1])
      } else {
        return 0
      }
    },
    buttonText () {
      return this.activeTabIndex === 0 ? 'Next' : 'Create Charges'
    },
    tableData () {
      if (this.active) {
        let data = 'Reconciliations Expense Accounts'
        let i = this.totalTabs.indexOf(this.active.split('-')[1])
        switch (i) {
          case 0:
            data = 'Reconciliations Expense Accounts'
            break
          case 1:
            data = 'Reconciliations Lease Expenses'
            break
        }
        return this.currentData(data)
      }
      return null
    },
    tableHeaders () {
      if (this.active) {
        let data = 'Reconciliations Expense Accounts'
        let i = this.totalTabs.indexOf(this.active.split('-')[1])
        switch (i) {
          case 0:
            data = 'Reconciliations Expense Accounts'
            break
          case 1:
            data = 'Reconciliations Lease Expenses'
            break
        }
        return this.currentHeaders(data)
      }
      return null
    },
    reconciliationsComplete () {
      if (this.cycle.reconcileIsLocked) {
        return true
      }
      if (this.tableData && this.activeTabIndex === 0) {
        return !this.tableData.some(x => {
          return x.needsReview === true
        })
      } else {
        return true
      }
    },
    lockReconciliationIcon () {
      return this.cycle.reconcileIsLocked ? 'lock_open' : 'lock'
    },
    lockReconciliationButtonText () {
      return this.cycle.reconcileIsLocked ? 'Unlock Reconciliation Cycle' : 'Lock Reconciliation Cycle'
    }
  },
  watch: {
    cycle: function () {
      this.getGlAccounts(false)
      this.getLeaseReconciliations()
    },
    reconciliationsComplete: function (val) {
      if (val && val === true) {
        this.$emit('reconciliationsComplete', true)
      } else {
        this.$emit('reconciliationsComplete', false)
      }
    },
    selectedTab: function (val) {
      if (val) {
        this.active = `tab-${this.totalTabs[val]}`
      }
    },
    active: function (val) {
      if (val) {
        let i = this.totalTabs.indexOf(val.split('-')[1])
        switch (i) {
          case 0:
            this.getGlAccounts(true)
            break
          case 1:
            this.getLeaseReconciliations()
            break
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-tabs {
    &.reconciliation__tabs {
      ::v-deep .v-tabs-bar {
        height: 40px;
        background-color: var(--v-resGray-lighten6)
      }
      ::v-deep .v-tab {
        justify-content: left;
        padding: 6px 6px;
        background-color: var(--v-resGray-lighten3);
        border-color: var(--v-resGray-lighten1);
        border-style: solid;
        border-width: 1px 1px 0px 1px;
        color: var(--v-resGray-darken4);
        .v-icon {
          color: var(--v-resGray-darken3);
        }
      }
      ::v-deep .v-tab--active {
        background-color: var(--v-resBlue-base);
        border-color: var(--v-resBlue-base);
        border-style: solid;
        border-width: 1px 1px 0px 1px;
        color: var(--v-resWhite-base) !important;
        .v-icon {
          color: var(--v-resWhite-base);
        }
        &.v-tab--disabled {
          opacity: 100%;
        }
      }
    }
  }

  .tabs-card {
    border-radius: 0;
    border-top: 2px var(--v-resBlue-base) solid;
    border-right: 1px var(--v-resGray-lighten1) solid;
    border-left: 1px var(--v-resGray-lighten1) solid;
    border-bottom: 1px var(--v-resGray-lighten1) solid;
  }
</style>
