<template>
  <base-dialog
    :title="title"
    :showTitleIcon="true"
    :showDialog="errorDialog"
    :saveButtonText="saveButtonText"
    :saveButtonColor="saveButtonColor"
    :saveButtonOnly="true"
    :titleClass="'dark-header'"
    @save="Continue"
    @cancel="Continue">
    <v-card-text class="pl-6">
        <v-subheader>
          <v-layout column>
            <span>{{ errorDialogStatus }}</span>
            <span class="caption">{{ errorDialogStatusText }}</span>
          </v-layout>
        </v-subheader>
        <span class="caption ml-4">{{ errorDialogMsg }}</span>
    </v-card-text>
  </base-dialog>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import BaseDialog from './BaseDialog'

export default {
  components: {
    BaseDialog
  },
  props: ['showDialog'],
  data () {
    return {
      title: 'Whoops! Error has occurred!',
      saveButtonText: 'Continue',
      saveButtonColor: 'var(--v-resGreen-base)'
    }
  },
  computed: {
    ...mapGetters(['currentErrorDialog', 'currentErrorDialogMsg', 'currentErrorDialogStatus', 'currentErrorDialogStatusText']),
    errorDialog: {
      get () {
        return this.currentErrorDialog
      }
    },
    errorDialogMsg: {
      get () {
        return this.currentErrorDialogMsg
      }
    },
    errorDialogStatus: {
      get () {
        return this.currentErrorDialogStatus
      }
    },
    errorDialogStatusText: {
      get () {
        return this.currentErrorDialogStatusText
      }
    }
  },
  methods: {
    ...mapActions(['changeErrorDialog']),
    Continue () {
      this.changeErrorDialog({ isErrorDialog: false, errorDialogMsg: '' })
    }
  }
}
</script>
