<template>
  <v-navigation-drawer app v-model="rightDrawer" temporary right light>
          <v-list dense>
            <template v-for="(item, index) in notifications">
              <v-list-item v-if="item.header" :key="item.header" class="my-2">
                <v-list-item-content>
                  <strong>{{ item.header }}</strong>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon @click.stop="rightDrawer = !rightDrawer"><v-icon>close</v-icon></v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider class="notifications-divider" :key="`A-${index}`" v-else-if="item.divider"></v-divider>
              <v-list-item @click="item" :key="index" v-else>
                  <v-list-item-content>
                    <span class="pl-2">{{ item.type }}</span>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn class="notifications-countIcon resWhite-base--text" depressed small color="orange"><span class="notification-countIcon__content">{{ item.count }}</span></v-btn>
                  </v-list-item-action>
              </v-list-item>
            </template>
        </v-list>
        </v-navigation-drawer>
</template>

<script>
export default {
  data () {
    return {
      notifications: [],
      rightDrawer: false
    }
  },
  created () {
    this.$eventHub.$on('open-drawer', this.openDrawer)
    this.initialize()
  },
  methods: {
    initialize () {
      this.notifications = [
        { header: 'Notifications' },
        { type: 'Leasing', count: '1', read: false },
        { divider: true, inset: true },
        { type: 'Accounting', count: '1', read: false },
        { divider: true, inset: true },
        { type: 'Vendors', count: '1', read: false },
        { divider: true, inset: true },
        { type: 'Payments', count: '1', read: true },
        { divider: true, inset: true },
        { type: 'Banks', count: '1', read: false },
        { divider: true, inset: true },
        { type: 'Invoices', count: '1', read: false },
        { divider: true, inset: true },
        { type: 'Properties', count: '1', read: false },
        { divider: true, inset: true },
        { type: 'Units', count: '1', read: false },
        { divider: true, inset: true },
        { type: 'Work Orders', count: '1', read: false },
        { divider: true, inset: true },
        { type: 'Vacancies', count: '1', read: false },
        { divider: true, inset: true },
        { type: 'Units', count: '1', read: true },
        { divider: true, inset: true },
        { type: 'Leasing', count: '1', read: true }]
    },
    openDrawer: function () {
      // this.rightDrawer = value
    }
  }
}
</script>

<style>
#app .side-bar__collapse-button {
  position: absolute;
  bottom: 0;
  width: 40px;
  height: 40px;
  background-color: var(--v-resBlue-base);
  border-radius: 6px;
  cursor: pointer;
}

#app .side-bar__menu-item {
  cursor: pointer;
}
</style>
