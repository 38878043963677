<template>
  <base-dialog
    iconColor="var(--v-resWhite-base)"
    titleClass="dark-header"
    :title="title"
    :saveButtonOnly="true"
    :saveButtonColor="buttonColor"
    :saveButtonText="buttonText"
    :setWidth="800"
    :maxWidth="800"
    :showDialog="showDialog"
    @cancel="close"
    @save="close">
    <v-card-text>
      <v-card flat height="60" class="container-card">
        <v-layout class="pa-2">
          <v-flex>
            <div class="caption resGray-darken1--text font-weight-medium">GL Account</div>
            <div class="caption resGray-darken5--text font-weight-medium">{{ this.accountInfo.account }} - {{ this.accountInfo.accountName }}</div>
          </v-flex>
          <v-flex>
            <div class="caption resGray-darken1--text font-weight-medium">GL Actuals</div>
            <div class="caption resGray-darken5--text font-weight-medium">{{ this.accountInfo.actuals.toLocaleString() }}</div>
          </v-flex>
          <v-flex>
            <div class="caption resGray-darken1--text font-weight-medium">Budget</div>
            <div class="caption resGray-darken5--text font-weight-medium">{{ this.accountInfo.budget.toLocaleString() }}</div>
          </v-flex>
          <v-flex>
            <div class="caption resGray-darken1--text font-weight-medium">Difference</div>
            <div class="caption resGray-darken5--text font-weight-medium">{{ this.accountInfo.difference.toLocaleString() }}</div>
          </v-flex>
        </v-layout>
      </v-card>
      <!-- <v-layout my-4>
        <v-flex>
          <text-input
            width="250px"
            :placeholder="'Search accounts'">
          </text-input>
        </v-flex>
      </v-layout> -->
      <data-table
        v-if="tableData"
        class="mt-2"
        :tableKey="'coreJournalEntryId'"
        :selectableRows="false"
        :tableData="tableData"
        :headers="tableHeaders"
        @ClickCoreReference="ClickCoreReference">
      </data-table>
    </v-card-text>
  </base-dialog>
</template>

<script>
import DataTable from '../../../BaseComponents/Table'
import BaseDialog from '../../../BaseComponents/BaseDialog'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    BaseDialog,
    DataTable
  },
  props: ['showDialog', 'accountInfo'],
  data () {
    return {
    }
  },
  methods: {
    ...mapActions(['fetchData', 'changeData']),
    ClickCoreReference (val) {
      var path = ''
      switch (val.coreObjectType) {
        case 'Invoice':
          path = '/Reports/InvoicePrintWithDocuments?invoiceIds=' + val.coreTransactionObjectId
          break
        default:
          path = '/#/Boardroom' // just go to home page if object does not have specific link
          break
      }
      this.$eventHub.$emit('goToResman', `${path}`)
    },
    close () {
      this.$emit('save')
    },
    getAccountDetails () {
      this.fetchData({
        newPage: 'Estimates Expense Account Details',
        params: {
          property: this.accountInfo.property,
          chargeCode: this.accountInfo.chargeCode,
          cycleNumber: this.accountInfo.cycleNumber,
          account: this.accountInfo.account,
          accountList: this.accountInfo.accountList
        }
      }).catch(error => { console.log(error.response) })
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders']),
    title () {
      return 'Expense Recovery Account Detail'
    },
    buttonText () {
      return 'Close'
    },
    buttonColor () {
      return 'var(--v-resBlue-darken1)'
    },
    tableData () {
      return this.currentData('Estimates Expense Account Details')
    },
    tableHeaders () {
      return this.currentHeaders('Estimates Expense Account Details')
    }
  },
  created () {
    this.getAccountDetails()
  }
}
</script>

<style lang="scss" scoped>
  .container-card {
    border: 1px solid var(--v-resGray-lighten1);
  }
</style>
