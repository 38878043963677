<template>
  <v-radio-group
    v-model="returnValue"
    @change="changeSelected"
    class="permissions"
    hide-details
    row>
    <v-radio
      color="var(--v-resBlue-darken1)"
      :ripple="false"
      label="No Access"
      :value="null">
    </v-radio>
    <v-radio
      color="var(--v-resBlue-darken1)"
      :ripple="false"
      label="View Only"
      :value="false">
    </v-radio>
    <v-radio
      color="var(--v-resBlue-darken1)"
      :ripple="false"
      label="Full Access"
      :value="true">
    </v-radio>
  </v-radio-group>
</template>

<script>
export default {
  props: ['selectedValue'],
  data () {
    return {
      returnValue: this.selectedValue
    }
  },
  methods: {
    changeSelected (val) {
      this.$emit('valueChosen', val)
    }
  }
}
</script>

<style lang="scss" scoped>
  .permissions {
    ::v-deep label {
      font-size: 12px;
      color: var(--v-resGray-darken5);
    }
    ::v-deep i {
      font-size: 15px;
      color: var(--v-resGray-darken5);
    }
  }
</style>
