<template>
  <v-list dense>
    <template @click="navItems" class="secondary-side-bar__menu-item" v-for="(item, index) in items">
      <v-list-item v-if="item.heading" :key="item.heading" class="my-2" :ripple="{ center: true }">
        <v-list-item-content>
          <v-list-item-title>
            <span><strong>{{ item.heading }}</strong></span>
          </v-list-item-title>
          <v-list-item-subtitle v-if="item.subtitle">
            <span class="menu-header">{{ item.subtitle }}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="$eventHub.$emit(item.action)" :key="item.title" v-else :ripple="{ center: true }">
        <v-list-item-content>
          <span class="pl-2">{{ item.title }}</span>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="notifications-divider" :key="index" v-if="!item.heading && index + 1 < items.length"></v-divider>
    </template>
  </v-list>
</template>

<script>
export default {
  props: ['navItems'],
  data () {
    return {
      items: this.navItems
    }
  },
  methods: {
    logout () {
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-list.v-sheet {
    padding: 0 !important;
  }
  .v-list-item {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .v-list-item__content {
    font-size: 13px;
  }
  .notifications-divider {
    width:90%;
    margin-left: 15px;
  }
</style>
