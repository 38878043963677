<template>
  <v-navigation-drawer app v-model="sideBar" dark width="246">
    <v-list class="pt-0">
      <v-hover v-for="item in items" :key="item.title">
        <v-list-item
          class="side-bar__menu-item py-1"
          slot-scope="{ hover }"
          :to="{ name: item.route }"
          active-class="side-bar__menu--active"
          @click="`${!item.secondaryMenu? closeLeftDrawer() : openLeftDrawer(item.title)}`"
        >
          <v-list-item-action class="side-bar__menu-avatar">
          <v-tooltip right v-if="mini">
            <template v-slot:activator="{ on }">
              <v-icon :class="item.class + `${hover? '': 'resGray-darken1--text'}`" v-on="on" :size="item.size">{{ item.icon }}</v-icon>
            </template>
            <span>{{ item.title }}</span>
          </v-tooltip>
          <v-icon :class="`${hover? '': ' resGray-darken1--text'}`" v-else :size="item.size">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content :class="`${hover? '': 'resGray-darken1--text'}`">
              <span class="side-bar__menu-text pl-1">{{ item.title.toUpperCase() }}</span>
          </v-list-item-content>
        </v-list-item>
      </v-hover>
      <v-list-item class="mobile-logout">
        <v-list-item-avatar size="20" class="size-bar__menu-avatar">
          <v-icon>exit_to_app</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <span>Logout</span>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data () {
    return {
      items: [],
      mini: false,
      drawer: true,
      right: null,
      sideBar: false
    }
  },
  created () {
    this.$eventHub.$on('open-sideBar', this.openSideBar)
    this.initialize()
  },
  methods: {
    initialize () {
      this.items = [
        {
          title: 'Dashboard',
          icon: 'dashboard',
          route: 'Dashboard',
          size: '20',
          class: 'mr-1 '
        },
        {
          title: 'Leases',
          icon: 'description',
          route: 'Leases',
          size: '20',
          class: 'mr-1 '
        },
        {
          title: 'Properties',
          icon: 'domain',
          route: 'Properties',
          size: '20',
          class: 'mr-1 '
        },
        {
          title: 'Transactions',
          icon: 'attach_money',
          route: 'Transactions',
          size: '24',
          class: ''
        },
        {
          title: 'Recoveries',
          icon: 'cached',
          route: 'Recoveries',
          size: '20',
          class: 'mr-1 '
        },
        {
          title: 'Setup',
          icon: 'build',
          route: 'Setup',
          size: '17',
          class: 'mr-2 '
        }
      ]
    },
    openSideBar: function (value) {
      this.sideBar = value
    },
    openLeftDrawer: function (item) {
      this.closeLeftDrawer()
      this.$eventHub.$emit('open-LeftDrawer', { header: item, switch: true })
    },
    closeLeftDrawer: function () {
      this.sideBar = false
    }
  }
}
</script>

<style>
#app .mobile-logout{
  position: absolute;
  bottom: 32px;
}
</style>
