<template>
  <base-dialog
    :showDialog="showDialog"
    :title="'Edit Charge'"
    :maxWidth="400"
    @cancel="cancelDialog"
    @save="confirmNonRecurringDialog">
    <v-row no-gutters>
      <v-col>
        <div class="ml-6">Charge Code</div>
        <div class="ml-6"><b>{{ editedItem.chargeCode }}</b></div>
      </v-col>
      <v-col>
        <div class="ml-6">Space</div>
        <div class="ml-6"><b>{{ editedItem.leaseSpace }}</b></div>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-5">
      <v-col>
        <drop-down
          :selectedValue="{ text: editedItem.chargeFrequencyName }"
          :items="'Charge Frequency'"
          :label="'Frequency'"
          :multiple='false'
          width="180px"
          class="ml-6"
          @itemSelected="selectChargeFrequency">
        </drop-down>
      </v-col>
    </v-row>
    <div class="ml-6 mt-5">
      <span><b>Fees</b></span>
    </div>
    <v-row no-gutters class="checkbox__container">
      <v-col cols="12">
        <v-checkbox
          height="20"
          v-model="editedItem.lateFeeApplies"
          class="shrink ml-6 my-0 pa-0"
          color="var(--v-resBlue-darken1)"
          label="Late Fee">
        </v-checkbox>
      </v-col>
    </v-row>
    <v-row no-gutters class="checkbox__container">
      <v-col cols="12">
        <v-checkbox
          height="20"
          v-model="editedItem.managementFeeApplies"
          class="shrink ml-6 my-0 pa-0"
          color="var(--v-resBlue-darken1)"
          label="Management Fee">
        </v-checkbox>
      </v-col>
    </v-row>
    <v-row no-gutters class="checkbox__container">
      <v-col cols="12">
        <v-checkbox
          height="20"
          v-model="editedItem.salesTaxApplies"
          class="shrink ml-6 my-0 pa-0"
          color="var(--v-resBlue-darken1)"
          label="Sales Tax">
        </v-checkbox>
      </v-col>
    </v-row>
    <div class="ml-6 mt-5">
      <v-tooltip top nudge-right="110" content-class="tooltip">
        <template v-slot:activator="{ on }">
          <span class="pl-0 mt-4 font-weight-bold">Statement Settings <v-icon v-on="on" color="var(--v-resGray-darken5)" small>info</v-icon></span>
        </template>
        <span>These settings are for the fields that <br/>appear on the lease statements.
        </span>
      </v-tooltip>
    </div>
    <v-row no-gutters class="mt-1">
      <v-col cols="12">
        <text-input :value="editedItem.reference"
          label='Reference Name'
          width="200px"
          class='mx-6'
          @textInputUpdated="updateReference">
        </text-input>
        <text-input :value="editedItem.displayOrder"
          width='50px'
          label='Display Order'
          rules="numeric"
          @textInputUpdated="updateDisplayOrder">
        </text-input>
      </v-col>
    </v-row>
  </base-dialog>
</template>

<script>
import DropDown from '../../BaseComponents/DropDown'
import TextInput from '../../BaseComponents/TextInput'
import BaseDialog from '../../BaseComponents/BaseDialog'

export default {
  props: ['showDialog', 'editedItem'],
  components: {
    DropDown,
    TextInput,
    BaseDialog
  },
  data () {
    return {
    }
  },
  methods: {
    cancelDialog () {
      this.$emit('cancel', true)
    },
    updateReference (val) {
      this.editedItem.reference = val
    },
    updateDisplayOrder (val) {
      this.editedItem.displayOrder = val
    },
    selectChargeFrequency (val) {
      if (val !== null) {
        this.editedItem.chargeFrequencyName = val.text
        this.editedItem.chargeFrequency = val.value
      } else {
        this.editedItem.chargeFrequencyName = null
        this.editedItem.chargeFrequency = null
      }
    },
    setEndDate (val) {
      this.editedItem.endDate = val
    },
    setStartDate (val) {
      this.editedItem.startDate = val
    },
    updateChargeAmount (val) {
      this.editedItem.baseAmount = val
    },
    confirmNonRecurringDialog () {
      this.$validator.validateAll()
      if (!this.errors.items.any) {
        this.$emit('editNonRecurringCharge', this.editedItem)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .tooltip {
    background-color: var(--v-resGray-darken5);
  }
  .checkbox__container {
    height: 30px;
    ::v-deep label {
      color: var(--v-resBlack-base);
      font-size: 14px;
    }
  }
</style>
