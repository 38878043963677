<template>
  <div class="search-input" :style="searchProps">
    <v-text-field
      v-model="searchString"
      :append-icon="searchIcon"
      flat
      solo
      clearable
      :placeholder="placeholder"
      :class="activeClass"
      hide-details
      @focus="setActiveClass"
      @blur="removeActiveClass"
      @input="updateSearchString">
    </v-text-field>
  </div>
</template>

<script>
export default {
  props: ['placeholder', 'width'],
  data () {
    return {
      searchString: null,
      activeClass: null
    }
  },
  methods: {
    updateSearchString (val) {
      this.$emit('searchStringUpdated', val)
    },
    setActiveClass () {
      this.activeClass = 'active'
    },
    removeActiveClass () {
      this.activeClass = null
    }
  },
  computed: {
    searchProps () {
      return {
        '--width': this.width
      }
    },
    searchIcon () {
      return !this.searchString ? 'search' : ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .search-input {
    display: inline-block;
    width: var(--width);
    ::v-deep .v-input__append-inner {
      margin-top: 0;
    }
    ::v-deep .v-icon {
      color: var(--v-resGray-darken4) !important;
      width: 20px;
      height: 20px;
      font-size: 21px;
      margin-top: 2px;
      font-weight: 500 !important;
    }
    ::v-deep .v-input {
      font-size: 12px;
      width: var(--width);
      color: var(--v-resGray-darken1);
      margin-top: 0px;
      &__icon {
        height: 20px;
      }
    }
    ::v-deep .v-icon--link {
      color: var(--v-resBlue-base)!important;
      border-radius: 7px;
      font-size: 12px;
      width: 15px;
      padding-left: 1px;
      height: 15px;
      font-weight: 600;
      margin:0px;
    }
    ::v-deep .v-text-field {
      .v-input__control {
        margin-top: 2px;
        .v-input__slot {
          min-height: 30px;
          height: 30px;
          width: var(--width);
          border-radius: 6px;
          border: solid 1px var(--v-resGray-darken2) !important;
          background-color: var(--v-resWhite-base) !important;
          padding: 2px 6px 2px 10px;
        }
      }
    }
    ::v-deep .active {
      .v-input__control {
        .v-input__slot {
          border: solid 2px var(--v-resBlue-base) !important;
        }
      }
    }
    ::v-deep input {
      margin-top: 0px;
    }
  }
</style>
