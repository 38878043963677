<template>
  <base-dialog
    :showDialog="showDialog"
    :title="dialogTitle"
    :titleClass="'dark-header'"
    :showTitleIcon="true"
    :iconColor="'white'"
    :maxWidth="500"
    :saveButtonText="'Yes, Override'"
    :saveButtonColor="'var(--v-resBlue-darken1)'"
    :saveButtonWidth="120"
    :cancelButtonText="'No'"
    :cancelButtonColor="'var(--v-resBlue-darken1)'"
    :cancelButtonTextColor="'override-blue'"
    @cancel="cancel"
    @save="save">
    <v-card-text class="pl-6 py-0">
      <v-row no-gutters class="mt-4">
        <v-col>
          <span>{{ message }}</span>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-4">
        <v-col>
          <span>Do you want to override?</span>
        </v-col>
      </v-row>
    </v-card-text>
  </base-dialog>
</template>

<script>
import BaseDialog from '../../BaseComponents/BaseDialog'

export default {
  components: {
    BaseDialog
  },
  props: ['showDialog', 'effectiveDate'],
  data () {
    return {
      dialogTitle: 'Override Existing Measures'
    }
  },
  methods: {
    save () {
      this.$emit('confirmOverride', true)
    },
    cancel () {
      this.$emit('cancelOverride', true)
    }
  },
  computed: {
    message () {
      return `There are already measures taken for ${this.asOfDate}. If you proceed, you will override the existing measures.`
    }
  }
}
</script>
