<template>
  <v-container fluid class="resGray lighten-6">
    <v-app-bar flat height="36">
      <toggle-button-group
          class="ml-2"
          group-width="500px"
          :buttons="toggleButtons"
          @toggled="setTypeFilter">
      </toggle-button-group>
      <v-spacer></v-spacer>
      <search
        placeholder='Search Users'
        class="mr-2"
        @searchStringUpdated="updateSearchString">
      </search>
    </v-app-bar>
    <v-row>
      <v-col cols="12" class="mt-3">
        <data-table
          class="mx-6"
          :headers="tableHeaders"
          :tableData="tableData"
          :noData="'No information to display.'"
          :searchString="searchString"
          @unlockEditing="openEditUserDialog">
        </data-table>
      </v-col>
    </v-row>
    <!-- Dialogs -->
    <edit-user-dialog
      :showDialog="showEditUserDialog"
      :user="selectedUser"
      @saveCompleted="userSaved"
      @cancel="closeEditUserDialog">
    </edit-user-dialog>
  </v-container>
</template>

<script>
import ToggleButtonGroup from '../../BaseComponents/ToggleButtonGroup2'
import Search from '../../BaseComponents/Search'
import DataTable from '../../BaseComponents/Table'
import EditUserDialog from './Dialogs/EditUserDialog'
import { mapGetters, mapActions } from 'vuex'

const NO_ACCESS = 0
const VIEW_ACCESS = 1
const FULL_ACCESS = 2

export default {
  components: {
    ToggleButtonGroup,
    Search,
    DataTable,
    EditUserDialog
  },
  data () {
    return {
      searchString: null,
      selectedUser: {},
      showEditUserDialog: false,
      typeFilter: 0,
      toggleButtons: [
        { text: 'All', index: 0 },
        { text: 'No Access', index: 1 },
        { text: 'View Access', index: 2 },
        { text: 'Full Access', index: 3 }
      ]
    }
  },
  methods: {
    ...mapActions(['fetchData', 'deleteData']),
    updateSearchString (val) {
      this.searchString = val
    },
    getUsers () {
      this.fetchData({ newPage: 'UserIntegration' }).catch(error => { console.log(error.response) })
    },
    openEditUserDialog (user) {
      this.selectedUser = user
      this.showEditUserDialog = true
    },
    userSaved () {
      this.getUsers()
      this.closeEditUserDialog()
    },
    closeEditUserDialog () {
      this.showEditUserDialog = false
      this.selectedUser = {}
    },
    setTypeFilter (e) {
      this.typeFilter = e
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders']),
    tableHeaders () {
      return this.currentHeaders('UserIntegration')
    },
    tableData () {
      let t = this.typeFilter
      return this.currentData('UserIntegration')
        .filter(u => {
          switch (t) {
            case 0:
              return true
            case 1:
              return u.userAccessType === NO_ACCESS
            case 2:
              return u.userAccessType === VIEW_ACCESS
            case 3:
              return u.userAccessType === FULL_ACCESS
            default:
              return false
          }
        })
    }
  },
  created () {
    this.getUsers()
  }
}
</script>
