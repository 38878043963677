<template>
  <base-dialog
    :showDialog="showDialog"
    :title="dialogTitle"
    :maxWidth="500"
    @cancel="cancelEditBuilding"
    @save="saveBuilding">
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="12">
          <text-input
            label="Building Name"
            :value="editedBuilding.buildingName"
            @textInputUpdated="updateBuilding">
          </text-input>
          <text-input
            label="Construction Description"
            width="200px"
            class="pl-6"
            :value="editedBuilding.constructionDescription"
            @textInputUpdated="updateConstructionDesciption">
          </text-input>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-5">
        <v-col cols="12">
          <date-picker
            :label="'Acquisition Date'"
            :defaultNull="true"
            :initialDate="editedBuilding.acquisitionDate"
            @dateSelected="updateAquisistionDate">
          </date-picker>
          <text-input :value="editedBuilding.acquisitionAmt"
            class="ml-6"
            label='Acquisition Amount'
            width="125px"
            :prefix="'$'"
            textFormat="currency"
            rules="decimal:2"
            :placeholder="'00.00'"
            @textInputUpdated="updateAcquisitionAmt">
          </text-input>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-5">
        <v-col cols="12">
          <date-picker
            :label="'Disposition Date'"
            :defaultNull="true"
            :initialDate="editedBuilding.dispositionDate"
            @dateSelected="updateDispositionDate">
          </date-picker>
          <text-input :value="editedBuilding.dispositionAmt"
            class="ml-6"
            label='Disposition Amount'
            width="125px"
            textFormat="currency"
            rules="decimal:2"
            :prefix="'$'"
            :placeholder="'00.00'"
            @textInputUpdated="updateDispositionAmt">
          </text-input>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-5">
        <v-col cols="12">
          <text-area label="Notes"
            :value="editedBuilding.notes"
            :height="75"
            @textAreaUpdated="updateNotes">
          </text-area>
        </v-col>
      </v-row>
    </v-card-text>
  </base-dialog>
</template>

<script>
import TextInput from '../../BaseComponents/TextInput'
import DatePicker from '../../BaseComponents/DatePicker'
import TextArea from '../../BaseComponents/TextArea'
import BaseDialog from '../../BaseComponents/BaseDialog'

export default {
  components: {
    TextInput,
    TextArea,
    DatePicker,
    BaseDialog
  },
  props: ['editedBuilding', 'showDialog', 'editMode'],
  methods: {
    updateBuilding (val) {
      this.editedBuilding.buildingName = val
    },
    updateConstructionDesciption (val) {
      this.editedBuilding.constructionDescription = val
    },
    updateAquisistionDate (val) {
      this.editedBuilding.acquisitionDate = val
    },
    updateDispositionDate (val) {
      this.editedBuilding.dispositionDate = val
    },
    updateAcquisitionAmt (val) {
      this.editedBuilding.acquisitionAmt = val
    },
    updateDispositionAmt (val) {
      this.editedBuilding.dispositionAmt = val
    },
    updateNotes (val) {
      this.editedBuilding.notes = val
    },
    saveBuilding () {
      this.$emit('save', this.editedBuilding)
    },
    cancelEditBuilding () {
      this.$emit('cancel', true)
    }
  },
  computed: {
    dialogTitle () {
      return `${this.editMode} Building`
    }
  }
}
</script>
