<template>
  <base-dialog
    :showDialog="showDialog"
    :title="dialogTitle"
    :maxWidth="700"
    :saveButtonOnly="true"
    :saveButtonText="'Okay'"
    @cancel="close"
    @save="close">
    <v-card-text>
      <v-row no-gutters>
        <v-col>
          <data-table
            :allowNullColumn="true"
            :headers="headers"
            :tableData="tableData"
            :noFooter="true">
          </data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </base-dialog>
</template>

<script>
import BaseDialog from '../../../BaseComponents/BaseDialog'
import DataTable from '../../../BaseComponents/Table'
import { mapGetters } from 'vuex'

export default {
  components: {
    BaseDialog,
    DataTable
  },
  props: ['showDialog', 'propertyName'],
  data () {
    return {
      tableData: [],
      dialogTitle: `Leases for ${this.propertyName}`
    }
  },
  methods: {
    close () {
      this.tableData = []
      this.$emit('closeDialog', true)
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders']),
    headers () {
      return this.currentHeaders('Leases With Charges')
    },
    getData () {
      return this.currentData('Leases With Charges')
    }
  },
  mounted () {
    this.tableData = this.getData
  }
}
</script>
