<template>
  <div class="date-picker">
    <label v-if="label !== undefined" class="date-picker__label d-block mb-0 pb-0">{{ label }}</label>
    <v-menu
      v-model="menu1"
      :close-on-content-click="false"
      transition="scale-transition"
      nudge-bottom="32"
      min-width="210px"
      max-width="210px">
      <template v-slot:activator="{ on }">
        <v-text-field
          height="30"
          flat
          solo
          v-model="currentItem"
          append-icon="calendar_today"
          placeholder="mm/dd/yyyy"
          v-validate:currentItem="rules"
          data-vv-name="currentItem"
          @click:append="showMenu"
          @change="parseDate"
          :hide-details="hideDetails"
          v-on="on"
          :disabled='disabled'
          :error-messages="errors.collect('currentItem')"
          @blur="typedDate">
        </v-text-field>
      </template>
      <v-date-picker
        class="date-picker__calendar"
        v-model="date"
        no-title
        width="210"
        event-color="var(--v-resBlue-darken1)"
        :min="minDate"
        @input="menu1 = false">
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: ['label', 'initialDate', 'defaultNull', 'disabled', 'hideDetails', 'rules', 'minDate'],
  data () {
    return {
      menu1: false,
      date: null,
      currentItem: null
    }
  },
  methods: {
    parseDate (date) {
      if (date) {
        return moment(date).format('YYYY-MM-DD')
      }
      this.$emit('dateSelected', null)
    },
    typedDate () {
      if (this.currentItem !== null && this.currentItem !== undefined) {
        this.$emit('dateSelected', this.currentItem)
      }
    },
    formatDate (date) {
      if (date) {
        return moment(date).format('L')
      }
    },
    selectDate () {
      this.$emit('dateSelected', this.date)
    },
    initialize (val) {
      if (val) {
        this.date = this.parseDate(val)
      } else if (this.defaultNull) {
        this.date = null
        this.currentItem = null
      } else {
        this.date = moment().format('YYYY-MM-DD')
        this.$emit('dateSelected', this.date)
      }
    },
    showMenu () {
      this.menu1 = !this.menu1
    },
    validate () {
      if (!this.inactive) {
        this.$validator.validateAll()
      }
    }
  },
  watch: {
    date: function (val) {
      if (val) {
        this.currentItem = this.formatDate(this.date)
        this.selectDate()
      }
    },
    initialDate: function (val) {
      this.initialize(val)
    }
  },
  created () {
    this.initialize(this.initialDate)
    this.$eventHub.$on('validate', this.validate)
  },
  beforeDestroy () {
    this.$eventHub.$off('validate')
  },
  activated: function () {
    this.inactive = false
  },
  deactivated: function () {
    this.inactive = true
    this.$validator.reset()
  }
}
</script>

<style lang="scss" scoped>
  .date-picker {
    display: inline-block;
    ::v-deep .v-text-field {
      .v-input__control {
        padding-top: 0px;
        min-height: 30px;
        .v-input__slot {
          background-color: var(--v-resWhite-base) !important;
          min-height: auto!important;
          align-items: center!important;
          border-radius: 6px!important;
          border: solid 1px var(--v-resGray-darken2) !important;
          width: 120px;
          cursor: pointer!important;
          padding-right: 7px;
          input {
            margin-top: 0;
            font-size: 12px;
            cursor: pointer!important;
          }
        }
      }
    }

    ::v-deep .v-input__slot {
      border: 1px solid gray !important;
      border-radius: 6px;
      margin-bottom: 0px;
    }

    ::v-deep .v-text-field__details {
      margin-bottom: 0px;
    }

    ::v-deep &-table {
      height: auto!important;
    }
    ::v-deep .v-icon {
      font-size: 16px!important;
    }

    &__calendar {
      font-size: 10px!important;
      ::v-deep .v-date-picker-years {
        font-size: 12px;
        .active {
          font-size: 12px;
        }
      }
      ::v-deep .v-date-picker-header {
        background-color: var(--v-resBlue-base);
        height: 35px;
        padding-left: 0px;
        padding-right: 0px;
        button {
          color: var(--v-resWhite-base);
            &:hover {
              background-color: transparent;
              &:before {
                background-color: transparent;
              }
            }
            &:before{
              background-color: transparent;
            }
        }
        .accent--text {
          button {
            font-size: 12px;
            color: var(--v-resWhite-base) !important;
          }
        }
      }
      ::v-deep .v-date-picker-table {
        height: auto!important;
        padding-left: 0!important;
        padding-right: 0!important;
        .v-btn {
          font-size: 10.7px!important;
        }
        th {
          font-size: 10.7px!important;
        }
        &--date .v-btn {
          width: 20px;
          height: 20px;
        }
      }
    }
    &__label {
      font-size: 12px;
    }
  }

  ::v-deep .v-date-picker-table {
    ::v-deep .accent {
      background-color: var(--v-resBlue-darken1) !important;
      &--text {
        color: var(--v-resBlue-darken1) !important;
      }
    }
  }
</style>
