<template>
  <v-container fluid grid-list-md text-left>
    <span class="resGray-darken5--text title ml-6">Please choose from one of the following setup options.</span>
    <v-layout wrap>
      <v-flex shrink v-for="card in cardsInfo" :key="card.title" class="ml-6">
        <card :data="card" class="mt-4 mr-4 mb-1"></card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Card from '../BaseComponents/Card'
import {mapActions, mapGetters} from 'vuex'

export default {
  data () {
    return {
    }
  },
  components: {
    Card
  },
  mounted () {
    this.changeState()
  },
  methods: {
    ...mapActions(['changeSection', 'changeTab', 'changeSelectedItem']),
    changeState () {
      this.changeSection('Setup')
      this.changeTab()
      this.changeSelectedItem()
    }
  },
  computed: {
    ...mapGetters(['currentUserIsAdmin', 'currentUserIsSupport']),
    cardsInfo () {
      let list = [
        { title: 'Lease Items', description: 'Manage and setup all lease related items here. You can add, edit or remove:', icon: 'description', items: [ 'Charge Codes', 'Lease Types', 'Amendment Types', 'Option Types' ], route: 'Lease Items' },
        { title: 'Property Items', description: 'Manage and setup all property related items here. You can add, edit or remove:', icon: 'domain', items: [ 'Property Types', 'Area Types', 'Group Types', 'Space Types', 'Area Measures Types', 'Insurance Policy Types' ], route: 'Property Items' }, //, 'Amenities'
        { title: 'Templates', description: 'Create a new template or edit an existing one for the following options:', icon: 'layers', items: [ 'Property', 'Charge Code', 'Area Measures' ], route: 'Templates' }, //, 'Lease Clauses', 'Amenities'
        { title: 'Custom Fields', description: 'Create or edit custom fields for the following:', icon: 'line_weight', items: [ 'Property', 'Space', 'Lease' ], route: 'Custom Fields' }, //, 'Building'
        { title: 'Notifications', description: 'Control your notification decriptions and which alerts you want to receive.', icon: 'notification_important', items: [ 'Alerts' ], route: 'Notifications' }, // 'Notification Numbers',
        { title: 'User Administration', description: 'Manage user access to the application.', icon: 'lock', items: [ 'User Page Access', 'Data Access', 'Role Management' ], route: 'Security' },
        { title: 'Reports', description: 'Manage system-wide reports', icon: 'insert_chart', items: [ 'Manage Reports' ], route: 'Reports' },
        { title: 'Imports', description: 'Process all imports needed here.', icon: 'get_app', items: [ 'Charge Codes Import', 'Properties Import', 'Leases Import', 'Beginning Balances Import', 'Ledger History Import' ], route: 'Imports' }
      ]
      if (this.currentUserIsAdmin || this.currentUserIsSupport) {
        let index = list.findIndex(x => x.title === 'User Administration')
        list[index].items.push('Activate Users (Onboarding)')
      }

      if (this.currentUserIsAdmin) {
        return list
      } else {
        let index = list.findIndex(x => x.title === 'Reports')
        list.splice(index, 1)
        return list
      }
    }
  }
}
</script>
