<template>
  <v-container fluid class="resGray lighten-6">
    <v-app-bar flat>
      <drop-down
        class="mb-0-plus ml-2"
        :default-first="true"
        :getParams="{propertyId: currentSelectedItem.property}"
        :selectedValue="{ text: selectedBuilding.text }"
        items="Property Buildings"
        label="Buildings"
        :multiple="false"
        width="220px"
        rules="required"
        @itemSelected="selectBuilding">
      </drop-down>
      <v-spacer></v-spacer>
      <round-button
        color='var(--v-resGray-darken3)'
        height='30px'
        width='143px'
        :depressed='true'
        :buttonText='buttonTitle'
        backgroundColor='var(--v-resBlue-darken1)'
        borderColor='var(--v-resGray-lighten1)'
        buttonClass='mr-3 mt-9'
        @click="openAttachments">
      </round-button>
      <round-button
        color='var(--v-resGray-darken3)'
        height='30px'
        width='135px'
        :depressed='true'
        buttonText='+ Add Insurance'
        backgroundColor='var(--v-resGreen-lighten1)'
        borderColor='var(--v-resGray-lighten1)'
        buttonClass='mr-2 mt-9'
        @click="addInsurance()">
      </round-button>
    </v-app-bar>
    <v-row>
      <v-col cols="12" class="mt-3">
        <data-table
          class="mx-6"
          :headers="headers"
          :tableData="getData"
          :showActions="true"
          noData="Select building to continue"
          @unlockEditing="unlockEdit"
          @itemDeleteRequested="openDeleteDialog">
        </data-table>
      </v-col>
    </v-row>
    <!-- Dialogs -->
    <base-dialog
      v-if="isDialog"
      :showDialog="isDialog"
      title="Add Insurance"
      @cancel="cancelDialog"
      @save="confirmDialog">
      <v-card-text class="pl-4">
        <v-row no-gutters>
          <v-col cols="12">
            <drop-down
              :selectedValue="{ text: editedItem.policyTypeName, value: editedItem.policyType }"
              items="Insurance Policy Types"
              label="Insurance Types"
              :multiple='false'
              width="220px"
              rules="required"
              @itemSelected="selectInsuranceType">
            </drop-down>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-5">
          <v-col cols="12">
            <text-input
              class="mr-6"
              :value="editedItem.insuranceCompany"
              label="Insurance Company"
              rules="required"
              @textInputUpdated="updateInsuranceCompany">
            </text-input>
            <text-input
              :value="editedItem.insuranceAgency"
              label="Insurance Agency"
              rules="required"
              @textInputUpdated="updateInsuranceAgency">
            </text-input>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-5">
          <v-col cols="12">
            <text-input
              class="mr-6"
              :value="editedItem.reference"
              label="Policy Number"
              rules="required"
              @textInputUpdated="updateReference">
            </text-input>
            <text-input
              :value="editedItem.policyLimit"
              label="Policy Limit"
              prefix="$"
              textFormat="currency"
              rules="required"
              @textInputUpdated="updatePolicyLimit">
            </text-input>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-5">
          <v-col cols="12">
            <date-picker
              class="mr-6"
              label="Start Date"
              :defaultNull='true'
              rules="required"
              :initialDate="editedItem.startDate"
              @dateSelected="updateStartDate">
            </date-picker>
            <date-picker
              label="Expire Date"
              :defaultNull="true"
              rules="required"
              :initialDate="editedItem.endDate"
              @dateSelected="updateEndDate">
            </date-picker>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-5">
          <v-col cols="12">
            <text-input
              class="mr-6"
              :value="editedItem.cancelDays"
              width="120px"
              label="Cancel Days"
              textFormat="numeric"
              @textInputUpdated="updateCancelDays">
            </text-input>
            <date-picker
              label="Cancel Date"
              :defaultNull="true"
              :initialDate="editedItem.cancelDate"
              @dateSelected="updateCancelDate">
            </date-picker>
          </v-col>
        </v-row>
      </v-card-text>
    </base-dialog>
    <attachments
      v-if="attachmentsDialog"
      :showDialog="attachmentsDialog"
      @cancel="closeAttachments">
    </attachments>
    <delete-dialog
      :showDialog="deleteDialog"
      @confirmDelete="deleteInsurance"
      @cancelDelete="closeDeleteDialog">
    </delete-dialog>
  </v-container>
</template>

<script>
import DataTable from '../BaseComponents/Table'
import RoundButton from '../BaseComponents/RoundButton'
import DropDown from '../BaseComponents/DropDown'
import DatePicker from '../BaseComponents/DatePicker'
import TextInput from '../BaseComponents/TextInput'
import BaseDialog from '../BaseComponents/BaseDialog'
import DeleteDialog from '../BaseComponents/DeleteDialog'
import Attachments from './Dialogs/Attachments'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    Attachments,
    BaseDialog,
    DataTable,
    DatePicker,
    DeleteDialog,
    DropDown,
    RoundButton,
    TextInput
  },
  data () {
    return {
      selectedBuilding: {},
      attachmentsDialog: false,
      isDialog: false,
      editedIndex: -1,
      editedItem: {
        startDate: null,
        endDate: null
      },
      deleteDialog: false,
      deletingItem: {}
    }
  },
  methods: {
    ...mapActions(['changeLoading', 'changeData', 'fetchData', 'deleteData']),
    unlockEdit (item) {
      this.editedIndex = this.getData.indexOf(item)
      this.editedItem = JSON.parse(JSON.stringify((this.getData[this.editedIndex], item)))
      this.isDialog = true
    },
    openDeleteDialog (item) {
      this.deletingItem = item
      this.deleteDialog = true
    },
    closeDeleteDialog () {
      this.deleteDialog = false
      this.deletingItem = {}
    },
    cancelDialog () {
      this.isDialog = false
    },
    confirmDialog () {
      this.$eventHub.$emit('validate')
      setTimeout(() => {
        if (!this.errors.items.length > 0) {
          this.changeData({ currentPage: 'BuildingInsurance', newData: this.editedItem }).then(() => {
            this.fetchData({ newPage: 'BuildingInsurance', params: { BuildingId: this.editedItem.buildingId } }).then(() => {
              this.isDialog = false
            })
          }).catch(error => { console.log(error.response) })
        }
      }, 100)
    },
    selectBuilding (val) {
      this.selectedBuilding = val
      this.editedItem.buildingId = this.selectedBuilding.value.trim()
      this.fetchData({ newPage: 'BuildingInsurance', params: { BuildingId: this.editedItem.buildingId } }).catch(error => { console.log(error.response) })
    },
    selectInsuranceType (val) {
      if (val !== null) {
        this.editedItem.policyTypeName = val.text
        this.editedItem.policyType = val.value
      } else {
        this.editedItem.policyTypeName = null
        this.editedItem.policyType = null
      }
    },
    updateInsuranceCompany (val) {
      this.editedItem.insuranceCompany = val
    },
    updateInsuranceAgency (val) {
      this.editedItem.insuranceAgency = val
    },
    updateReference (val) {
      this.editedItem.reference = val
    },
    updatePolicyLimit (val) {
      this.editedItem.policyLimit = val
    },
    updateStartDate (val) {
      this.editedItem.startDate = val
    },
    updateEndDate (val) {
      this.editedItem.endDate = val
    },
    updateCancelDays (val) {
      this.editedItem.cancelDays = val
    },
    updateCancelDate (val) {
      this.editedItem.cancelDate = val
    },
    addInsurance () {
      this.editedItem = {
        startDate: null,
        endDate: null,
        buildingId: this.selectedBuilding.value.trim()
      }
      this.isDialog = true
    },
    deleteInsurance () {
      this.deleteData({ currentPage: 'BuildingInsurance', removeItem: this.deletingItem }).then(() => { this.closeDeleteDialog() }).catch(error => { console.log(error.response) })
    },
    openAttachments () {
      this.attachmentsDialog = true
    },
    closeAttachments () {
      this.attachmentsDialog = false
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentSelectedItem']),
    getData () {
      return this.currentData('BuildingInsurance')
    },
    headers () {
      return this.currentHeaders('BuildingInsurance') || []
    },
    buttonTitle () {
      return 'Attachments (' + (this.currentData('Documents').length || '0') + ')'
    }
  },
  created () {
    this.fetchData({ newPage: 'Documents', params: { ApplicationRole: 'PROP', PrimaryKey: this.currentSelectedItem.property } }).catch(error => { console.log(error.response) })
  }
}
</script>
