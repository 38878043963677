<template>
  <v-container fluid class="resGray lighten-6">
    <span class="resGray-darken5--text title ml-6">Property Measures</span>
    <v-app-bar flat class="mb-0 pb-0">
      <drop-down
        class="ml-2"
        :disabled="editMeasures || showAddEditDialog"
        :type="'sqFtMeasures'"
        :items="dropDownItems"
        :getParams="{ PropertyId: this.currentSelectedItem.property, MeasureType: measureType }"
        :selectedValue="today"
        width="132px"
        label="Measures as of:"
        @itemSelected="setAsOfDate">
      </drop-down>
    </v-app-bar>
    <v-layout row mx-6 mt-1>
      <v-flex xs3 v-for="measure in propertyMeasuresList" :key="measure.title">
        <property-measure-card
          :discrepancy="calculateDiscrepancy(measure)"
          :measure="measure"
          :class="['my-3', measure.addEdit && !editMeasures ? 'add-edit-card' : '']"
          @click.native="showAddEditPropertyMeasures(measure.addEdit)">
        </property-measure-card>
      </v-flex>
    </v-layout>
    <v-row class="mx-6 mt-1 mb-1" v-for="warning in discrepancyWarnings" :key="warning">
      <v-icon small color="var(--v-resRed-base)">warning</v-icon>
      <span class="discrepancy-warning">
        {{ warning }}
      </span>
    </v-row>
    <v-app-bar flat height="36" class="mt-3">
      <toggle-button-group
        class="ml-5"
        :buttons="toggleButtons"
        @toggled="toggleMeasures">
      </toggle-button-group>
    </v-app-bar>
    <v-row>
      <v-col cols="12" class="mt-3">
        <data-table
          class="mx-6"
          :headers="headers"
          :tableData="tableData"
          :showActions="false"
          :showTotals="true"
          :units="'SQFT'"
          :dynamicPlaceholder="true"
          @showHistory="showHistory"
          @textInputUpdated="enableTableSave">
        </data-table>
      </v-col>
    </v-row>
    <v-app-bar flat class="mt-2">
      <v-spacer></v-spacer>
      <date-picker
        v-if="editMeasures"
        class="pb-4-plus mr-3"
        label="Effective Date"
        :initialDate="asOfDate"
        @dateSelected="setEffectiveDate">
      </date-picker>
      <round-button
        v-if="editMeasures"
        height='30px'
        width='80px'
        buttonText='Cancel'
        buttonClass="mr-3"
        :borderColor="'var(--v-resRed-base)'"
        :depressed="true"
        :flat="true"
        :outline="true"
        buttonTextColor='var(--v-resRed-base)'
        @click="cancelAddEditSpaceMeasures">
      </round-button>
      <round-button
        :disabled="!saveEnabled"
        v-if="editMeasures"
        height='30px'
        width='80px'
        buttonClass="mr-2"
        :depressed='true'
        :flat='true'
        :backgroundColor="'var(--v-resGreen-lighten1)'"
        :buttonText="'Save'"
        @click="checkEffectiveMeasureDate">
      </round-button>
      <round-button
        v-if="!editMeasures"
        height='30px'
        :dark='true'
        :depressed='true'
        buttonText='Edit Measures'
        backgroundColor='var(--v-resBlue-darken1)'
        buttonClass='mr-2'
        @click="editTable">
      </round-button>
    </v-app-bar>
    <!-- Dialogs -->
    <space-measures-history-dialog
      v-if="showDialog"
      :showDialog="showDialog"
      :spaceOrArea="selectedSpaceOrArea"
      :measureType="measureType"
      @cancel="cancelHistoryDialog">
    </space-measures-history-dialog>
    <add-edit-property-measures-dialog
      v-if="showAddEditDialog && propertyMeasuresWithValues"
      :showDialog="showAddEditDialog"
      :measures="propertyMeasuresWithValues"
      :asOfDate="asOfDate"
      :allowSave="false"
      @checkEffectiveDate="checkEffectiveDate"
      @cancel="cancelAddEditDialog">
    </add-edit-property-measures-dialog>
    <override-existing-measure-dialog
      :showDialog="showOverrideDialog"
      :effectiveDate="effectiveDate"
      @confirmOverride="confirmOverride"
      @cancelOverride="cancelOverride">
    </override-existing-measure-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import DataTable from '../BaseComponents/Table'
import DatePicker from '../BaseComponents/DatePicker'
import DropDown from '../BaseComponents/DropDown'
import RoundButton from '../BaseComponents/RoundButton'
import PropertyMeasureCard from './PropertyMeasureCard'
import ToggleButtonGroup from '../BaseComponents/ToggleButtonGroup'
import SpaceMeasuresHistoryDialog from './Dialogs/SpaceMeasuresHistory'
import AddEditPropertyMeasuresDialog from './Dialogs/AddEditPropertyMeasures'
import OverrideExistingMeasureDialog from './Dialogs/OverrideExistingMeasures'

export default {
  components: {
    DataTable,
    DatePicker,
    RoundButton,
    DropDown,
    PropertyMeasureCard,
    ToggleButtonGroup,
    SpaceMeasuresHistoryDialog,
    AddEditPropertyMeasuresDialog,
    OverrideExistingMeasureDialog
  },
  data () {
    return {
      asOfDate: moment().format('YYYY-MM-DD'),
      showDialog: false,
      showAddEditDialog: false,
      selectedSpaceOrArea: null,
      currentToggle: 0,
      toggleButtons: [
        { text: 'Spaces' },
        { text: 'Areas' }
      ],
      totals: {},
      editMeasures: false,
      tableData: [],
      saveEnabled: false,
      editData: null,
      showOverrideDialog: false,
      effectiveDate: moment().format('YYYY-MM-DD'),
      editedMeasures: null
    }
  },
  methods: {
    ...mapActions(['changeLoading', 'fetchData', 'changeData', 'initializeStoreDropdown', 'changeTab']),
    refreshMeasures () {
      if (this.currentToggle === 0) {
        this.getSpaceMeasures()
      } else {
        this.getAreaMeasures()
      }
      this.getPropertyMeasuresHistory()
      this.cancelAddEditDialog()
      this.refreshDropdown()
    },
    refreshDropdown () {
      this.$eventHub.$emit('refreshDropdown', true)
    },
    setAsOfDate (e) {
      if (e) {
        if (e.value) {
          this.asOfDate = moment(e.value, 'MM/DD/YYYY').format('YYYY-MM-DD')
        } else {
          this.asOfDate = moment(e).format('YYYY-MM-DD')
        }
        this.refreshMeasures()
      }
    },
    setEffectiveDate (e) {
      if (e) {
        this.effectiveDate = moment(e, 'YYYY-MM-DD').format('YYYY-MM-DD')
      }
    },
    getPropertyMeasuresHistory () {
      this.fetchData({newPage: 'PropertyMeasuresHistory', params: { PropertyId: this.currentSelectedItem.property, AsOfDate: this.asOfDate }}).catch(error => { console.log(error.response) })
    },
    getSpaceMeasures () {
      this.fetchData({newPage: 'PropertyMeasures', params: { PropertyId: this.currentSelectedItem.property, AsOfDate: this.asOfDate, MeasureType: 'Space' }})
        .catch(error => { console.log(error.response) })
    },
    getAreaMeasures () {
      this.fetchData({newPage: 'PropertyMeasures', params: { PropertyId: this.currentSelectedItem.property, AsOfDate: this.asOfDate, MeasureType: 'Area' }})
        .catch(error => { console.log(error.response) })
    },
    addSpaceMeasure () {
    },
    showHistory (e) {
      if (this.currentToggle === 0) {
        this.selectedSpaceOrArea = e.space
      } else {
        this.selectedSpaceOrArea = e.area
      }

      this.showDialog = true
    },
    showAddEditPropertyMeasures (e) {
      if (e && !this.editMeasures) {
        this.showAddEditDialog = true
      }
    },
    cancelHistoryDialog () {
      this.showDialog = false
    },
    cancelAddEditDialog () {
      this.showAddEditDialog = false
    },
    toggleMeasures (value) {
      this.currentToggle = value
      setTimeout(() => {
        this.refreshMeasures()
      }, 250)
    },
    calculateDiscrepancy (measure) {
      if (this.variances) {
        let discrepancy = this.variances.find(v => {
          return v.measureType === measure.measureType
        })

        if (discrepancy && discrepancy.measureValue) {
          return discrepancy.measureValue
        } else {
          return 0
        }
      }
    },
    editTable () {
      this.saveEnabled = false
      let measureType = 'Space'
      if (this.currentToggle === 0) {
        measureType = 'Space'
      } else {
        measureType = 'Area'
      }
      this.fetchData({
        newPage: 'PropertySpaceMeasuresHistory',
        params: {
          PropertyId: this.currentSelectedItem.property,
          AsOfDate: this.asOfDate,
          MeasureType: measureType
        }}).then(() => {
        this.editMeasures = !this.editMeasures
        this.setDataForEdit()
      }).catch(error => { console.log(error.response) })
    },
    setDataForEdit () {
      this.tableData = this.measuresData.map(m => {
        let d = JSON.parse(JSON.stringify(m))
        let keys = Object.keys(d).filter(k => k.includes('['))
        let historyItem = this.spaceMeasureHistory.find(h => {
          if (this.currentToggle === 0) {
            return h.space.trim() === d.space.trim() &&
            moment(h.date).format('MM/DD/YYYY') === moment(this.asOfDate).format('MM/DD/YYYY')
          } else {
            return h.area.trim() === d.area.trim() &&
            moment(h.date).format('MM/DD/YYYY') === moment(this.asOfDate).format('MM/DD/YYYY')
          }
        })
        d.placeholder = {}
        for (var i = 0; i < keys.length; i++) {
          let currentKey = keys[i]
          d.placeholder[currentKey] = (d[keys[i]] ? d[keys[i]].toString() : '-')

          d[keys[i]] = null
          if (historyItem) {
            let measure = historyItem.measures.find(m => {
              return m.measureType === parseInt(keys[i].split('[')[1].charAt(0))
            })
            if (measure && keys[i].includes(measure.measureType.toString())) {
              d[keys[i]] = measure.measureValue
            }
          }
        }

        d.readonly = !this.editMeasures
        delete d.dynamicColumns
        return d
      })
    },
    cancelAddEditSpaceMeasures () {
      this.editMeasures = false
      this.saveEnabled = false
      this.tableData = this.measuresData
    },
    checkEffectiveMeasureDate () {
      if (this.effectiveDate) {
        let checkDate = moment(this.effectiveDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
        if (this.spaceMeasureHistoryDates.includes(checkDate)) {
          this.showOverrideDialog = true
        } else {
          this.saveSpaceMeasures()
          this.showOverrideDialog = false
        }
      }
    },
    saveSpaceMeasures () {
      let measures = []

      for (var j = 0; j < this.tableData.length; j++) {
        let row = this.tableData[j]
        let keys = Object.keys(row).filter(k => {
          return k.includes('[')
        })

        for (var i = 0; i < keys.length; i++) {
          let key = keys[i]
          let measureType = key.split('[')[1].charAt(0)

          if (row[key]) {
            let measure = {
              propertyId: this.currentSelectedItem.property,
              space: row.space,
              area: row.area,
              building: row.building,
              measureType: measureType,
              measureValue: row[key]
            }
            measures.push(measure)
          }
        }
      }

      this.changeData({ currentPage: 'PropertyMeasures',
        newData: {
          measureType: this.measureType,
          propertyId: this.currentSelectedItem.property,
          asOfDate: this.effectiveDate,
          measures: measures
        }
      }).then(() => {
        this.editMeasures = false
        this.refreshMeasures()
      }).catch(error => { console.log(error.response) })
    },
    checkEffectiveDate (e) {
      if (e && e.effectiveDate) {
        this.effectiveDate = e.effectiveDate
        this.editedMeasures = e.editedMeasures
        let effectiveDate = moment(e.effectiveDate).format('MM/DD/YYYY')
        if (this.propertyMeasureHistoryDates.includes(effectiveDate)) {
          this.showAddEditDialog = false
          this.showOverrideDialog = true
        } else {
          this.savePropertyMeasures(e)
          this.showAddEditDialog = false
        }
      }
    },
    cancelOverride () {
      if (!this.editMeasures) {
        this.showAddEditDialog = true
      }
      this.showOverrideDialog = false
    },
    confirmOverride () {
      if (this.editMeasures) {
        this.saveSpaceMeasures()
      } else {
        this.savePropertyMeasures({
          effectiveDate: this.effectiveDate,
          editedMeasures: this.editedMeasures
        })
        this.showAddEditDialog = false
      }
      this.showOverrideDialog = false
    },
    savePropertyMeasures (e) {
      this.changeData({ currentPage: 'PropertyMeasures',
        newData: {
          asOfDate: e.effectiveDate,
          PropertyId: this.currentSelectedItem.property,
          measureType: 'Property',
          measures: e.editedMeasures.filter(m => {
            return m.measureValue
          })
        }
      }).then(() => {
        this.refreshMeasures()
      }).catch(error => { console.log(error.response) })
    },
    enableTableSave () {
      this.saveEnabled = true
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentSelectedItem', 'currentDropdown']),
    currentDropdownItems () {
      return this.currentDropdown(this.dropDownItems)
    },
    propertyMeasureHistoryDates () {
      return this.currentDropdownItems.filter(d => {
        return d.value.includes('*')
      }).map(d => {
        return moment(d.value.replace('*', '')).format('MM/DD/YYYY')
      })
    },
    baseDataObject () {
      return this.currentData('PropertyMeasures')
    },
    variances () {
      return this.baseDataObject.variance
    },
    propertyMeasures () {
      if (this.baseDataObject.propertyMeasures) {
        return this.baseDataObject.propertyMeasures
      } else {
        return []
      }
    },
    propertyMeasuresList () {
      return this.propertyMeasures.concat({ addEdit: true })
    },
    measuresData () {
      return this.baseDataObject.measureTable
    },
    propertyMeasuresHistory () {
      return this.currentData('PropertyMeasuresHistory')
    },
    propertyMeasuresWithValues () {
      return this.propertyMeasures.map(m => {
        let measureWithValue = {
          title: m.title,
          measureValue: null,
          measureType: m.measureType,
          propertyId: this.currentSelectedItem.property
        }
        if (this.propertyMeasuresHistory) {
          let measureHistoryItem = this.propertyMeasuresHistory.find(h => {
            return h.measureType === m.measureType
          })

          if (measureHistoryItem) {
            measureWithValue.measureValue = measureHistoryItem.measureValue
          }
        }
        return measureWithValue
      })
    },
    spaceMeasureHistory () {
      return this.currentData('PropertySpaceMeasuresHistory')
    },
    spaceMeasureHistoryDates () {
      return [...new Set(this.spaceMeasureHistory.map(h => {
        return moment(h.date).format('MM/DD/YYYY')
      }))]
    },
    dropDownItems () {
      return 'Property Measure Dates'
    },
    measureType () {
      if (this.currentToggle === 0) {
        return 'Space'
      } else {
        return 'Area'
      }
    },
    headers () {
      return this.currentHeaders('PropertyMeasures')
        .filter(h => {
          if (this.currentToggle === 0) {
            return h.value !== 'area'
          } else {
            return h.value !== 'space'
          }
        })
        .map(h => {
          if (h.value.includes('[')) h.isEditable = true
          return h
        })
    },
    today () {
      return {
        text: moment().format('MM/DD/YYYY'),
        value: moment().format('MM/DD/YYYY')
      }
    },
    measureDiscrepancies () {
      return this.propertyMeasures.filter(m => {
        return this.calculateDiscrepancy(m) !== 0
      })
    },
    discrepancyWarnings () {
      let warnings = []
      if (this.measureDiscrepancies.length > 0) {
        warnings.push(`${this.measureType} measures are not reconciled`)
      }

      return warnings
    }
  },
  watch: {
    measuresData: function (val) {
      if (!this.editMeasures) {
        this.tableData = val
      }
    }
  },
  created () {
    this.refreshMeasures()
    this.changeTab({ header: 'SqFtMeasures', index: 2, route: 'SqFtMeasures' })
  }
}
</script>

<style lang="scss" scoped>
.discrepancy-warning {
  color: var(--v-resRed-base);
  font-size: 10px;
}

.add-edit-card {
  cursor: pointer;
}

</style>
