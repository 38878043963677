<template>
  <v-card-text class="pa-1">
    <v-row no-gutters>
      <v-col cols="12">
        <drop-down
          :selectedValue="{ text: poolInfo.propertyName }"
          :items="'Properties'"
          :label="'Property'"
          :multiple='false'
          width="290px"
          class="mb-2"
          :disabled="true"
          rules="required">
        </drop-down>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-5">
      <v-col cols="12">
        <drop-down
          :selectedValue="{ text: poolInfo.chargeCode }"
          :customList="reconChargeCode"
          :label="'Pool'"
          width="164px"
          rules="required"
          :genericLabel="true"
          :multiple='false'
          :disabled="true"
          class="mb-2">
        </drop-down>
        <drop-down
          :selectedValue="{ text: poolInfo.poolFrequencyName }"
          :items="'Recovery Pool Frequencies'"
          :label="'Reconciliation Frequency'"
          width="164px"
          rules="required"
          :genericLabel="true"
          :disabled="true"
          :multiple='false'
          class="mb-2 ml-4">
        </drop-down>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-5">
      <v-col cols="12">
        <text-input
          :value="poolInfo.poolName"
          class="mb-2"
          label='Pool Name'
          width="290px"
          rules="required"
          @textInputUpdated="updatePoolName">
        </text-input>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-5">
      <v-col cols="12">
        <drop-down
          :selectedValue="{ text: poolInfo.estimateChargeCode }"
          :items="'Estimate Charge Codes'"
          :label="'Estimate charge code'"
          width="164px"
          rules="required"
          :disabled="true"
          :genericLabel="true"
          :multiple='false'
          class="mb-6">
        </drop-down>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-5">
      <v-col cols="12">
        <drop-down
          :selectedValue="{ text: poolInfo.areaMeasureTypeName }"
          :customList="expenseMeasureTypes"
          :label="'Area measure'"
          width="164px"
          rules="required"
          :disabled="true"
          :genericLabel="true"
          :multiple='false'
          class="mb-6"
          :getParams="{ Property: poolInfo.property }">
        </drop-down>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import DropDown from '../../../BaseComponents/DropDown'
import TextInput from '../../../BaseComponents/TextInput'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: ['poolInfo'],
  components: {
    DropDown,
    TextInput
  },
  data () {
    return {
    }
  },
  methods: {
    ...mapActions(['changePoolCompleted', 'changePool', 'fetchData', 'fetchDropdown']),
    updatePoolName (val) {
      if (val.length > 0) {
        this.poolInfo.poolName = val
      } else {
        this.poolInfo.poolName = null
      }
      this.checkFormCompleted()
    },
    checkFormCompleted () {
      if (this.poolInfo.property &&
        this.poolInfo.chargeCode &&
        this.poolInfo.poolFrequencyName &&
        this.poolInfo.poolName &&
        this.poolInfo.estimateChargeCode &&
        this.poolInfo.areaMeasureTypeName) {
        this.changePoolCompleted(true)
        this.changePool(this.poolInfo)
        return true
      }

      this.changePoolCompleted(false)
      return false
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentDropdown']),
    reconChargeCode () {
      let list = this.currentData('Charge Codes')
      let dropdown = []
      list.forEach(item => {
        if (item.chargeType > 20 && item.chargeType < 30) {
          let newItem = {
            text: item.chargeCode,
            value: item.chargeCode
          }
          dropdown.push(newItem)
        }
      })
      return dropdown
    },
    expenseMeasureTypes () {
      return this.currentDropdown('Expense Measure Types')
    }
  },
  created () {
    this.changePoolCompleted(true)
    this.changePool(this.poolInfo)
  }
}
</script>
