<template>
  <imports-template
    ref='template'
    :canImport='canImport'
    :canExport='canExport'
    currentPage='GetImportPropertySpacesData'
    @downloadTemplate='downloadTemplate'
    @clearImportData='clearImportData'
    @saveUpload='saveUpload'
    @addNew='addNewItem'
    @submit='submit'>
    <template v-slot:header>
      <drop-down
        :selectedValue="{ text: selectedPropertyName }"
        :items="'Properties'"
        :label="'Property'"
        :multiple='false'
        width="200px"
        hideDetails
        rules="required"
        class="ml-2"
        :getParams="{ IncludeIncomplete: false, IncompleteOnly: false }"
        @itemSelected="selectProperty">
      </drop-down>
    </template>
    <template v-slot:body>
      <data-table
        :headers="headers"
        :tableData="getData"
        @itemDeleteRequested="deleteItem"
        @unlockEditing="unlockEdit"
        @dataChanged="save"
        @canceled="cancel"
        class="mx-6"
        :noData="'Please select an import template to upload.'">
      </data-table>
      <v-layout row mt-1 v-if='showWarning'>
        <v-icon small color="var(--v-resRed-base)">warning</v-icon>
        <span class='resRed-base--text'>
            {{ warningMessage }}
        </span>
      </v-layout>
    </template>
  </imports-template>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as fileDownload from '../../../../utility/fileDownloader'
import * as validation from '../../../../utility/validation'
import DataTable from '../../../BaseComponents/Table'
import DropDown from '../../../BaseComponents/DropDown'
import ImportsTemplate from './ImportsTemplate'
export default {
  components: {
    DataTable,
    DropDown,
    ImportsTemplate
  },
  data () {
    return {
      files: {},
      selectedProperty: undefined,
      selectedPropertyName: undefined,
      importDialog: false,
      confirmDialog: false
    }
  },
  methods: {
    ...mapActions(['fetchData', 'changeData', 'initializeStoreItem', 'fetchDropdown', 'addNew']),
    selectProperty (val) {
      if (val !== null) {
        this.selectedProperty = val.value
        this.selectedPropertyName = val.text
      } else {
        this.selectedProperty = null
        this.selectedPropertyName = null
      }
    },
    deleteItem (item) {
      this.$refs.template.deleteItem(item, this.getData)
    },
    unlockEdit (item) {
      this.$refs.template.unlockEdit(item, this.getData)
    },
    cancel (item) {
      this.$refs.template.cancelEdit(item, this.getData)
    },
    save (item) {
      this.$refs.template.save(item)
    },
    addNewItem () {
      this.addNew({
        currentPage: 'GetImportPropertySpacesData',
        newItem: {
          space: null,
          description: null,
          type: { text: null, value: null },
          building: { text: null, value: null },
          area: { text: null, value: null },
          marketRent: null,
          marketRentPerSpaceArea: null,
          includeIfZeroArea: null,
          dispositionDate: null,
          notes: null,
          actionColumn: { delete: true, edit: true },
          readonly: false,
          new: true,
          errorToolTip: { hasIcon: false, tooltip: null, value: '' }
        }
      })
    },
    downloadTemplate () {
      this.fetchData({ newPage: 'ImportPropertySpaces', params: { Property: this.selectedProperty } }).then(() => {
        let file = this.currentData('ImportPropertySpaces')
        let bufferArray = fileDownload.convertArrayBuffer(file.fileContent)
        let fileName = file.fileName.split('.')[0]
        fileName += '-' + this.selectedPropertyName + '.xlsx'
        fileDownload.createDownloadFile(bufferArray, fileName)
      }).catch(error => { console.log(error.response) })
    },
    saveUpload (item) {
      this.fetchDropdown({ newDropdown: 'Space Types' })
      this.fetchDropdown({ newDropdown: 'Property Buildings', params: { PropertyId: this.selectedProperty } }).catch(error => { console.log(error.response) })
      this.fetchDropdown({ newDropdown: 'Property Areas', params: { PropertyId: this.selectedProperty } }).then(() => {
        this.changeData({ currentPage: 'GetImportPropertySpacesData', newData: item, updateItem: {} }).then(() => {
          this.$refs.template.cancel()
        })
      }).catch(error => { console.log(error.response) })
    },
    submit () {
      this.changeData({ currentPage: 'ImportPropertySpaces', newData: { Property: this.selectedProperty, PropertySpaces: this.getData } }).then(() => {
        this.$refs.template.cancelImport()
      }).catch(error => { console.log(error.response) })
    },
    clearImportData () {
      this.initializeStoreItem('GetImportPropertySpacesData')
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentDropdown']),
    headers () {
      let items = JSON.parse(JSON.stringify(this.currentHeaders('GetImportPropertySpacesData')))
      let validation = items.splice(items.findIndex(x => x.value === 'errorToolTip'), 1)
      return validation.concat(items)
    },
    rawData () {
      return this.currentData('GetImportPropertySpacesData')
    },
    getData () {
      let items = this.rawData
      let spaceTypes = this.currentDropdown('Space Types')
      let spaceTypesLength = spaceTypes.length
      let buildings = this.currentDropdown('Property Buildings')
      let buildingsLength = buildings.length
      let areas = this.currentDropdown('Property Areas')
      let areasLength = areas.length
      items.forEach(item => {
        if (spaceTypesLength > 0 && validation.hasValue(item.type) && validation.hasValue(item.type.text)) {
          item.type = spaceTypes.find(x => {
            if (validation.hasValue(x.text)) {
              return x.text.toLowerCase() === item.type.text.toLowerCase()
            }
          })
        }
        if (buildingsLength > 0 && validation.hasValue(item.building) && validation.hasValue(item.building.text)) {
          item.building = buildings.find(x => {
            if (validation.hasValue(x.text)) {
              return x.text.toLowerCase() === item.building.text.toLowerCase()
            }
          })
        }
        if (areasLength > 0 && validation.hasValue(item.area) && validation.hasValue(item.area.text)) {
          item.area = areas.find(x => {
            if (validation.hasValue(x.text)) {
              return x.text.toLowerCase() === item.area.text.toLowerCase()
            }
          })
        }
        let errors = validation.validateValue(item, [
          { value: 'space', required: true, maxLength: 10 },
          { value: 'description', required: true, maxLength: 50 },
          { value: 'type', required: true },
          { value: 'building', required: true },
          { value: 'area', required: true },
          { value: 'marketRent', positive: true },
          { value: 'marketRentPerSpaceArea', positive: true }
        ], items)
        validation.handleErrorToolTip(item, errors)
      })
      return items
    },
    canExport () {
      if (this.selectedProperty !== null && this.selectedProperty !== undefined) {
        return true
      }
      return false
    },
    canImport () {
      if (this.getData.length > 0 && !this.showWarning) {
        return true
      }
      return false
    },
    warningMessage () {
      return this.getData.filter(x => x.errorToolTip.hasIcon === true).length + ' row(s) have errors. Please fix these to continue.'
    },
    showWarning () {
      return this.getData.filter(x => x.errorToolTip.hasIcon === true).length > 0
    }
  },
  beforeDestroy () {
    this.clearImportData()
  }
}
</script>
