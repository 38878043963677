// Here we can set our current evironment
// "devNoAuth" mean we are in the dev environment and do not want to sign in or use authentication
// "devAuth" means we are in dev environment and do not want to sign in but we want to test/use authentications
// "prod" means we want to test signing in and authentication
const state = {
  currentEnvironment: process.env.NODE_ENV,
  rowsPerPage: 5
}

const getters = {
  currentEnvironment: (state) => state.currentEnvironment,
  currentRowsPerPage: (state) => state.rowsPerPage
}

const actions = {
  changeEnvironment ({ commit }, newEnvironment) {
    commit('setEnvironment', newEnvironment)
  },
  updateRowsPerPage ({ commit }, rowsPerPage) {
    commit('setRowsPerPage', rowsPerPage)
  }
}

const mutations = {
  setEnvironment (state, newEnvironment) {
    if (newEnvironment === 'development') {
      state.currentEnvironment = newEnvironment
    } else {
      state.currentEnvironment.environment = newEnvironment
    }
  },
  setRowsPerPage (state, rowsPerPage) {
    state.rowsPerPage = rowsPerPage
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
