<template>
  <v-container fluid fill-height>
    <v-layout column>
      <v-flex>
        <v-layout align-center justify-center column fill-height>
          <div class="text-center">
            <v-progress-circular
              :size="50"
              :indeterminate="true"
              color="var(--v-resBlue-darken1)">
            </v-progress-circular>
          </div>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  props: ['loadingMessage'],
  data () {
    return {

    }
  }
}
</script>
