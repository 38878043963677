<template>
  <base-dialog
    :showDialog="showDialog"
    :title="'New Property Group'"
    :maxWidth="750"
    :saveDisabled="!enableSave"
    @cancel="cancel"
    @save="save">
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="12">
          <text-input
            v-model="propertyGroup"
            label="Property Group">
          </text-input>
          <text-input
            v-model="propertyGroupName"
            label="Property Group Name"
            class="ml-4">
          </text-input>
          <drop-down
            :items="'Property Group Types'"
            :label="'Type'"
            :genericLabel="true"
            :multiple="false"
            class="ml-4"
            width="180px"
            @itemSelected="selectType">
          </drop-down>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-3">
        <v-col cols="12">
          <data-table
            :headers="tableHeaders"
            :tableData="properties"
            :tableKey="'property'"
            :selectableRows="true"
            :showSelectAll="true"
            @selectionChanged="changeSelectedProperties">
          </data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </base-dialog>
</template>

<script>
import TextInput from '../../../BaseComponents/TextInput'
import DropDown from '../../..//BaseComponents/DropDown'
import BaseDialog from '../../../BaseComponents/BaseDialog'
import DataTable from '../../../BaseComponents/Table'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    BaseDialog,
    TextInput,
    DropDown,
    DataTable
  },
  props: ['showDialog'],
  data () {
    return {
      propertyGroup: null,
      propertyGroupName: null,
      propertyGroupType: null,
      selectedProperties: []
    }
  },
  methods: {
    ...mapActions(['fetchData', 'changeData']),
    async getAllProperties () {
      this.fetchData({newPage: 'Properties'}).catch(error => { console.log(error.response) })
    },
    cancel () {
      this.$emit('cancel')
    },
    save () {
      if (this.enableSave) {
        this.selectedProperties.forEach(item => {
          item.PropertyGroup = this.propertyGroup
          delete item.rowVersion
        })
        this.changeData({
          currentPage: 'PropertyGroups',
          newData: {
            propertyGroup: this.propertyGroup,
            propertyGroupName: this.propertyGroupName,
            propertyGroupType: this.propertyGroupType.value,
            properties: this.selectedProperties
          }
        }).then(() => {
          this.$emit('saveComplete')
        }).catch(error => { console.log(error.response) })
      }
    },
    changeSelectedProperties (e) {
      this.selectedProperties = e
    },
    selectType (e) {
      this.propertyGroupType = e
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders']),
    properties () {
      return this.currentData('Properties')
    },
    tableHeaders () {
      return this.currentHeaders('Properties')
    },
    enableSave () {
      return !!this.propertyGroup &&
        !!this.propertyGroupName &&
        !!this.propertyGroupType &&
        this.selectedProperties.length > 0
    }
  },
  created () {
    this.getAllProperties()
  }
}
</script>
