<template>
<div>
  <v-app-bar class="pl-0" flat color="var(--v-resWhite-base)" height="40">
    <v-btn :ripple="false" text :to="{ name: 'Setup' }" color="var(--v-resWhite-base)" class="back-button">
      <v-btn small color="var(--v-resBlue-darken1)" icon><v-icon size="18" dark>arrow_back</v-icon></v-btn>
      <span class="text-none ml-0 back-button-text">Back to Setup</span>
    </v-btn>
  </v-app-bar>
  <v-container fluid grid-list-md text-left>
    <span class="resGray-darken5--text title ml-6">Please choose from one of the following import options.</span>
    <v-layout wrap>
      <v-flex shrink v-for="card in cardsInfo" :key="card.title" class="ml-6">
        <card :data="card" class="mt-4 mr-4 mb-1"></Card>
      </v-flex>
    </v-layout>
  </v-container>
</div>
</template>

<script>
import Card from '../../BaseComponents/Card'
import { mapActions } from 'vuex'

export default {
  data () {
    return {
    }
  },
  components: {
    Card
  },
  methods: {
    ...mapActions(['changeTab', 'changeSelectedItem']),
    changeState () {
      this.changeTab()
      this.changeSelectedItem()
    }
  },
  computed: {
    cardsInfo () {
      return [
        { title: 'Global Charge Codes', description: 'Import global charge code related information.', icon: 'compare_arrows', items: [ 'Charge Codes', 'Charge Code Templates' ], route: 'Imports Charge Codes' },
        { title: 'Property Infomation', description: 'Import property related information.', icon: 'domain', items: [ 'Properties', 'Charge Code Mapping', 'Property Spaces', 'Square Foot Measures' ], route: 'Imports Properties' },
        { title: 'Lease Information', description: 'Import lease related information.', icon: 'description', items: [ 'Leases', 'Lease Spaces', 'Lease Charges' ], route: 'Imports Leases' },
        { title: 'Beginning Balances', description: 'Import beginning balance related information.', icon: 'attach_money', items: [ 'Delinquents', 'Prepaids', 'Security Deposits' ], route: 'Imports Delinquents' },
        { title: 'Ledger History', description: 'Import ledger history related information.', icon: 'history', items: [ 'Tenant Ledger History' ], route: 'Imports Tenant Ledger History' }
      ]
    }
  },
  mounted () {
    this.changeState()
  }
}
</script>

<style lang="scss" scoped>
.back-button{
  cursor: pointer;
  padding-left: 0px;
  &:hover {
    background-color: transparent;
    &:before {
      background-color: transparent;
    }
  }
  &:before{
    background-color: transparent;
  }
  &-text {
    color: var(--v-resBlue-darken1);
  }
}
</style>
