<template>
  <base-dialog
    :showDialog="showDialog"
    :title="'Edit User'"
    :maxWidth="400"
    :saveDisabled="!enableSave"
    @cancel="cancel"
    @save="save">
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="12">
          <text-input
            v-model="user.username"
            width="320px"
            label="Username"
            :disabled="true">
          </text-input>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-2">
        <v-col cols="12">
          <text-input
            v-model="user.displayName"
            width="320px"
            label="Name"
            :disabled="true">
          </text-input>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-2">
        <v-col cols="12">
          <text-input
            v-model="user.email"
            width="320px"
            label="Email"
            :disabled="true">
          </text-input>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-2">
        <v-col cols="12">
          <drop-down
            :selectedValue="selectedItem"
            :items="'UserAccessTypes'"
            :label="'Access Type'"
            :genericLabel="true"
            :multiple="false"
            width="180px"
            @itemSelected="selectUserAccessType">
          </drop-down>
        </v-col>
      </v-row>
    </v-card-text>
  </base-dialog>
</template>

<script>
import TextInput from '../../../BaseComponents/TextInput'
import DropDown from '../../..//BaseComponents/DropDown'
import BaseDialog from '../../../BaseComponents/BaseDialog'
import { mapActions } from 'vuex'

export default {
  components: {
    BaseDialog,
    TextInput,
    DropDown
  },
  props: ['showDialog', 'user'],
  data () {
    return {
      selectedItem: { text: 'None', value: '0' }
    }
  },
  methods: {
    ...mapActions(['changeData']),
    cancel () {
      this.$emit('cancel')
    },
    save () {
      if (this.enableSave) {
        this.changeData({
          currentPage: 'UserIntegration',
          newData: {
            coreUserId: this.user.coreUserId,
            username: this.user.username,
            email: this.user.email,
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            userAccessType: this.user.userAccessType
          }
        }).then(() => {
          this.$emit('saveCompleted')
        }).catch(error => {
          console.log(error.response)
        })
      }
    },
    selectUserAccessType (e) {
      if (e) {
        this.user.userAccessType = e.value
      }
    }
  },
  computed: {
    enableSave () {
      return true
    }
  },
  watch: {
    user: function () {
      if (this.user) {
        this.selectedItem.value = this.user.userAccessType
        this.selectedItem.text = this.user.userAccessTypeName
      }
    }
  }
}
</script>
