<template>
  <v-container fluid class="resGray lighten-6">
    <v-app-bar flat class="mb-0 pb-0">
      <date-picker
        class="ml-2 mr-3 mb-4-plus"
        :label="startDateLabel"
        :initialDate="currentSelectedItem.startDate"
        @dateSelected="selectStartDate">
      </date-picker>
      <date-picker
        class="mr-3 mb-4-plus"
        :label="'End date'"
        :initialDate="currentSelectedItem.endDate"
        @dateSelected="selectEndDate">
      </date-picker>
      <toggle-button-group
        group-width="360px"
        :buttons="toggleButtons"
        :currentSelected="currentToggle"
        @toggled="setTypeFilter">
      </toggle-button-group>
      <v-spacer></v-spacer>
      <span class="v-subheader theme--light">Amount Due</span>
      <span class="title">{{baseTotalDueAmount}}</span>
      <v-spacer></v-spacer>
      <search
        class="mb-0-plus"
        width="260px"
        :placeholder="'Search by charge code or check #'"
        @searchStringUpdated="updateSearchString">
      </search>
      <round-button
        height='30px'
        class="ml-4 mr-2"
        :background-color="'white'"
        :width="'118px'"
        buttonTextColor='var(--v-resBlue-darken1)'
        :borderColor="'var(--v-resBlue-darken1)'"
        :depressed="true"
        :flat="true"
        :outline="true"
        :button-text="'Print History'"
        @click='openHistoryReport'>
      </round-button>
    </v-app-bar>
    <v-row>
      <v-col cols="12">
        <data-table
          class="mx-6"
          :headers="headers"
          :tableData="charges"
          :showActions="true"
          :editOnly='false'
          :allowNullColumn="true"
          :noData="'No information to display.'"
          :searchString="searchString"
          @showChargeCodeHistory="showChargeCodeHistory"
          @showPaymentHistory="showPaymentHistory">
        </data-table>
      </v-col>
    </v-row>
    <!-- Dialogs -->
    <charge-code-history-dialog
      v-if="showChargeCodeHistoryDialog && selectedChargeCode"
      :showDialog="showChargeCodeHistoryDialog"
      :chargeCode="selectedChargeCode"
      @closeDialog="hideChargeCodeHistoryDialog">
    </charge-code-history-dialog>
    <payment-detail-history-dialog
      v-if="showPaymentDetailHistoryDialog && selectedPayment"
      :showDialog="showPaymentDetailHistoryDialog"
      :payment="selectedPayment"
      @closeDialog="hidePaymentDetailHistoryDialog">
    </payment-detail-history-dialog>
  </v-container>
</template>

<script>
import DataTable from '../BaseComponents/Table'
import RoundButton from '../BaseComponents/RoundButton'
import DatePicker from '../BaseComponents/DatePicker'
import Search from '../BaseComponents/Search'
import ToggleButtonGroup from '../BaseComponents/ToggleButtonGroup2'
import ChargeCodeHistoryDialog from './Dialogs/ChargeCodeHistory'
import PaymentDetailHistoryDialog from './Dialogs/PaymentDetailsHistory'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    RoundButton,
    DataTable,
    DatePicker,
    Search,
    ToggleButtonGroup,
    ChargeCodeHistoryDialog,
    PaymentDetailHistoryDialog
  },
  data () {
    return {
      firstStartDate: null,
      startDate: null,
      endDate: null,
      typeFilter: 0,
      searchString: null,
      showChargeCodeHistoryDialog: false,
      selectedChargeCode: null,
      showPaymentDetailHistoryDialog: false,
      selectedPayment: null,
      toggleButtons: [
        { text: 'All', index: 0 },
        { text: 'Open Charges', index: 1 },
        { text: 'Payments', index: 2 }
      ]
    }
  },
  methods: {
    ...mapActions(['fetchData', 'initializeStoreItem']),
    getHistory () {
      this.fetchData({newPage: 'LeaseChargeActivity',
        params: {
          property: this.currentSelectedItem.property,
          lease: this.currentSelectedItem.lease,
          startDate: this.startDate,
          endDate: this.endDate,
          includePaidInvoices: true
        }
      }).catch(error => { console.log(error.response) })
    },
    async getEarliestCharge () {
      this.fetchData({
        newPage: 'LeaseChargeActivityEarliestCharge',
        params: {
          property: this.currentSelectedItem.property,
          lease: this.currentSelectedItem.lease
        }
      }).then(() => {
        let earliestCharge = this.currentData('LeaseChargeActivityEarliestCharge')
        if (earliestCharge) {
          this.firstStartDate = moment(earliestCharge.chargeDate).format('YYYY-MM-DD')
          this.startDate = earliestCharge.chargeDate
          if (this.startDate !== this.endDate) this.getHistory()
        }
      }).catch(error => { console.log(error.response) })
    },
    updateSearchString (e) {
      this.searchString = e
    },
    setTypeFilter (e) {
      this.typeFilter = e
    },
    selectStartDate (e) {
      this.startDate = e
    },
    selectEndDate (e) {
      this.endDate = e
    },
    showChargeCodeHistory (e) {
      this.showChargeCodeHistoryDialog = true
      this.selectedChargeCode = {
        code: e.chargeCode,
        description: e.chargeCodeName,
        date: moment(e.chargeDate).format('MM/DD/YYYY'),
        leaseCharge: e.leaseCharge
      }
    },
    showPaymentHistory (e) {
      this.showPaymentDetailHistoryDialog = true
      this.selectedPayment = {
        checkNumber: e.payerCheckNumber,
        date: moment(e.payerCheckDate).format('MM/DD/YYYY'),
        amount: e.paymentAmount,
        bankDepositId: e.bankDepositId
      }
    },
    hideChargeCodeHistoryDialog () {
      this.showChargeCodeHistoryDialog = false
      this.selectedChargeCode = null
      this.initializeStoreItem('LeaseChargesChargeCodeHistory')
    },
    hidePaymentDetailHistoryDialog () {
      this.showPaymentDetailHistoryDialog = false
      this.selectedPayment = null
      this.initializeStoreItem('LeaseChargeActivityPaymentDetails')
    },
    openHistoryReport () {
      window.open(this.reportUrl, '_blank')
    },
    getToken () {
      this.fetchData({ newPage: 'ReportConnectionToken', params: { } }).catch(error => { console.log(error.response) })
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentSelectedItem', 'currentUser', 'currentToggle']),
    startDateLabel () {
      return this.startDate === this.firstStartDate ? 'First charge date' : 'Start Date'
    },
    charges () {
      return this.currentData('LeaseChargeActivity')
        .filter(a => {
          return this.typeFilter === 0 ? true
            : this.typeFilter === 1 ? a.chargeCodeName !== 'Payment' && a.openAmount !== 0
              : this.typeFilter === 2 ? a.chargeCodeName === 'Payment'
                : false
        })
        .filter(a => {
          return moment(a.chargeDate) >= moment(this.startDate) && moment(a.chargeDate) <= moment(this.endDate)
        })
    },
    headers () {
      return this.currentHeaders('LeaseChargeActivity')
    },
    clientQueryString () {
      return `&report_connection_token=${this.connectionToken}&lease=${this.currentSelectedItem.lease}&property=${this.currentSelectedItem.property}`
    },
    reportUrl () {
      const baseURL = process.env.VUE_APP_REPORT_VIEWER_URL
      return `${baseURL}/ViewReport.aspx?report_path=/RMCOMM/PM/Lease_Charge_Payment_History${this.clientQueryString}`
    },
    connectionToken () {
      return this.currentData('ReportConnectionToken')
    },
    baseTotalDueAmount () {
      return (((this.currentData('LeaseChargeActivity') || [{}])[0] || {}).baseTotalDueAmount || 0).toLocaleString('en-US', { minimumFractionDigits: 2 })
    }
  },
  watch: {
    endDate: function (val) {
      if (moment(val) > moment()) {
        this.getEarliestCharge()
      }
    }
  },
  created () {
    this.getToken()
    this.initializeStoreItem('LeaseChargeActivity')
    this.getEarliestCharge()
  }
}
</script>
