<template>
  <div>
    <security-tabs>
      <router-view></router-view>
    </security-tabs>
  </div>
</template>

<script>
import SecurityTabs from './SecurityTabs'

export default {
  data () {
    return {}
  },
  components: {
    SecurityTabs
  }
}
</script>
