<template>
  <base-dialog
    :showDialog="showDialog"
    :title="'Address information'"
    :maxWidth="680"
    @cancel="cancelAddressEdit"
    @save="saveAddressEdit">
    <v-card-text>
      <h3 class="mb-4">Enter address information for {{ this.currentSelectedItem.propertyName }}.</h3>
      <v-tabs
        v-model="active"
        class="address-contacts"
        prev-icon="arrow_left"
        next-icon="arrow_right"
        show-arrows
        hide-slider>
        <v-tab
          v-for="n in totalTabs"
          :href="`#tab-${n}`"
          @click.stop="setActiveTab(n)"
          :key="n">
          <v-icon>location_on</v-icon>
          <span class="caption text-capitalize ml-1">
            <div>{{ tabTitle(n) }}</div>
          </span>
        </v-tab>
        <a class="v-tabs__item add-new" @click.stop="additionalContact">
          <v-icon>add</v-icon>
        </a>
        <v-tabs-items v-model="active">
          <v-tab-item
            v-for="n in renderedTabs"
            :key="n"
            :value="`tab-${n}`">
            <v-card max-height="300" flat class="tabs-card">
              <v-row no-gutters class="mt-3">
                <v-col>
                  <drop-down
                    :selectedValue="{ text: editedItem.addressTypeName }"
                    :items="'Address Type'"
                    :label="'Type'"
                    width="150px"
                    rules="required"
                    :multiple='false'
                    class="ml-6"
                    :customList="customAddressTypes"
                    @itemSelected="selectAddressType">
                  </drop-down>
                  <v-btn v-if="totalTabs.length > 1" class="float-right button__no-hover" text @click="removeTab" :ripple="false">
                    <v-icon>delete_forever</v-icon>
                    <span>Delete</span>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-5">
                <v-col cols="12">
                  <text-input
                    :value="editedItem.line1"
                    class="mx-6"
                    label='Address 1'
                    width="250px"
                    rules="required"
                    @textInputUpdated="updateAddress1">
                  </text-input>
                  <drop-down
                    :selectedValue="{ text: editedItem.stateName }"
                    :items="'State'"
                    :label="'State'"
                    width="200px"
                    :multiple='false'
                    rules="required"
                    @itemSelected="selectState">
                  </drop-down>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-5">
                <v-col cols="12">
                  <text-input
                    :value="editedItem.line2"
                    class="mx-6"
                    label='Address 2'
                    width="250px"
                    @textInputUpdated="updateAddress2">
                  </text-input>
                  <drop-down
                    :selectedValue="{ text: editedItem.countryName }"
                    :items="'Country'"
                    :label="'Country'"
                    :multiple='false'
                    width="145px"
                    :defaultFirst="true"
                    rules="required"
                    @itemSelected="selectCounty">
                  </drop-down>
                </v-col>
              </v-row>
              <v-row no-gutters class="my-5">
                <v-col cols="12">
                  <text-input
                    :value="editedItem.city"
                    class="mx-6"
                    label='City'
                    width="145px"
                    rules="required"
                    @textInputUpdated="updateCity">
                  </text-input>
                  <text-input
                    :value="editedItem.postalCode"
                    label='Zip code'
                    rules="required"
                    width="80px"
                    @textInputUpdated="updateZipCode">
                  </text-input>
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card-text>
  </base-dialog>
</template>

<script>
import DropDown from '../../BaseComponents/DropDown'
import TextInput from '../../BaseComponents/TextInput'
import BaseDialog from '../../BaseComponents/BaseDialog'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: ['showDialog'],
  components: {
    DropDown,
    TextInput,
    BaseDialog
  },
  data () {
    return {
      active: 'tab-0',
      tabCount: 0,
      totalTabs: [0],
      editedItem: { addressId: 0 },
      allAddresses: [],
      deleteAddresses: []
    }
  },
  methods: {
    ...mapActions(['fetchDropdown', 'fetchData', 'changeData']),
    additionalContact () {
      this.editedItem.organizationId = this.currentSelectedItem.organizationId
      var index = this.allAddresses.find(x => x.addressId === this.editedItem.addressId) || null
      if (index === null) {
        this.allAddresses.push(this.editedItem)
      }
      this.editedItem = {}
      this.tabCount++
      this.editedItem.organizationId = this.currentSelectedItem.organizationId
      this.editedItem.addressId = this.tabCount
      this.allAddresses.push(this.editedItem)
      this.totalTabs.push(this.tabCount)
      this.active = 'tab-' + this.totalTabs.indexOf(this.tabCount)
    },
    cancelAddressEdit () {
      this.$emit('cancel')
    },
    saveAddressEdit () {
      this.$eventHub.$emit('validate')
      setTimeout(() => {
        if (!this.errors.items.length > 0) {
          this.editedItem.organizationId = this.currentSelectedItem.organizationId
          let index = this.allAddresses.findIndex(x => x.addressId === this.editedItem.addressId)
          if (!~index) {
            this.allAddresses.push(this.editedItem)
          }
          this.changeData({ currentPage: 'Addresses', newData: { allAddresses: this.allAddresses, deleteAddresses: this.deleteAddresses } }).then(() => {
            this.$emit('save')
          }).catch(error => { console.log(error.response) })
        }
      }, 100)
    },
    tabTitle (tabIndex) {
      let address = this.allAddresses[tabIndex]
      if (address && address.addressTypeName) {
        return address.addressTypeName.split(' ')[0]
      } else {
        return 'New Address'
      }
    },
    removeTab () {
      var index = this.totalTabs.indexOf(parseInt(this.active.split('-').pop()))
      if (this.allAddresses[index] !== undefined && (this.allAddresses[index].rowVersion !== null || this.allAddresses[index].rowVersion !== undefined)) {
        this.deleteAddresses.push(this.allAddresses[index])
      }
      // this.customAddressTypes.push({ text: this.allAddresses[index].addressTypeName, value: this.allAddresses[index].addressType })
      this.allAddresses.splice(index, 1)
      this.totalTabs.splice(index, 1)
      this.tabCount = this.totalTabs[this.totalTabs.length - 1]
      this.active = 'tab-' + this.totalTabs[0]
      this.editedItem = this.allAddresses[0]
    },
    setActiveTab (val) {
      var index = this.allAddresses.find(x => x.addressId === this.editedItem.addressId) || null
      if (index === null) {
        this.allAddresses.push(this.editedItem)
      }
      this.editedItem = this.allAddresses[this.totalTabs.indexOf(val)]
      this.active = 'tab-' + this.totalTabs.indexOf(val)
    },
    updateZipCode (val) {
      this.editedItem.postalCode = val
    },
    selectAddressType (val) {
      this.editedItem.addressTypeName = val.text
      this.editedItem.addressType = val.value
    },
    selectCounty (val) {
      this.editedItem.countryName = val.text
      this.editedItem.country = val.value
    },
    updateAddress1 (val) {
      this.editedItem.line1 = val
    },
    updateAddress2 (val) {
      this.editedItem.line2 = val
    },
    updateCity (val) {
      this.editedItem.city = val
    },
    selectState (val) {
      this.editedItem.stateName = val.text
      this.editedItem.state = val.value
    },
  },
  computed: {
    ...mapGetters(['currentDropdown', 'currentSelectedItem', 'currentData']),
    customAddressTypes: {
      get () {
        var selectedAddressTypes = JSON.parse(JSON.stringify(this.addressTypes))
        var alreadySelectedAddressTypes = []
        this.allAddresses.forEach(function (item) {
          alreadySelectedAddressTypes.push(item.addressType)
        })
        alreadySelectedAddressTypes.forEach(function (item) {
          if (item !== undefined && item !== null) {
            var removeItem = selectedAddressTypes.find(x => parseInt(x.value) === parseInt(item))
            var removeItemIndex = selectedAddressTypes.indexOf(removeItem)
            if (~removeItemIndex) {
              selectedAddressTypes.splice(removeItemIndex, 1)
            }
          }
        })
        return selectedAddressTypes
      }
    },
    addressTypes () {
      return this.currentDropdown('Address Type')
    },
    renderedTabs () {
      return this.totalTabs.filter(t => {
        return `tab-${t}` === this.active
      })
    },
    getData () {
      return this.currentData('Addresses')
    }
  },
  created () {
    this.fetchDropdown({ newDropdown: 'Address Type' }).catch(error => { console.log(error.response) })
    this.fetchData({newPage: 'Addresses', params: { OrganizationId: this.currentSelectedItem.organizationId, ApplicationRole: this.currentSelectedItem.applicationRole }}).then(() => {
      this.allAddresses = this.currentData('Addresses')
      if (this.allAddresses.length > 0) {
        this.editedItem = this.allAddresses[0]
        this.totalTabs = []
        this.tabCount = this.allAddresses.length - 1
        for (var i = 0; i < this.allAddresses.length; i++) {
          this.totalTabs.push(i)
        }
      }
    }).catch(error => { console.log(error.response) })
  }
}
</script>

<style lang="scss" scoped>
  .v-tabs {
    &.address-contacts {
      ::v-deep a.add-new {
        padding-top: 10px;
        padding-left: 12px;
        width: 50px;
        background-color: var(--v-resGray-lighten3);
        border-color: var(--v-resGray-lighten1);
        border-style: solid;
        border-width: 1px 1px 0px 1px;
        color: var(--v-resGray-darken4);
        .v-icon {
          color: var(--v-resGray-darken3);
        }
      }
      ::v-deep .v-slide-group__prev {
        background-color: var(--v-resGray-lighten3);
        border-color: var(--v-resGray-lighten1);
        border-style: solid;
        border-width: 1px 1px 0px 1px;
      }
      ::v-deep .v-slide-group__next {
        background-color: var(--v-resGray-lighten3);
        border-color: var(--v-resGray-lighten1);
        border-style: solid;
        border-width: 1px 1px 0px 1px;
      }
      ::v-deep .v-tab {
        background-color: var(--v-resGray-lighten3);
        border-color: var(--v-resGray-lighten1);
        border-style: solid;
        border-width: 1px 1px 0px 1px;
        color: var(--v-resGray-darken4);
        .v-icon {
          color: var(--v-resGray-darken3);
        }
      }
      ::v-deep .v-tab--active {
        background-color: var(--v-resBlue-base);
        border-color: var(--v-resBlue-base);
        border-style: solid;
        border-width: 1px 1px 0px 1px;
        color: var(--v-resWhite-base) !important;
        .v-icon {
          color: var(--v-resWhite-base);
        }
      }
    }
  }

  .tabs-card {
    border-radius: 0px;
    border-top: 2px var(--v-resBlue-base) solid;
    border-right: 1px var(--v-resGray-lighten1) solid;
    border-left: 1px var(--v-resGray-lighten1) solid;
    border-bottom: 1px var(--v-resGray-lighten1) solid;
  }
</style>
