<template>
  <base-dialog
    :showDialog="showDialog"
    :title="dialogTitle"
    :maxWidth="500"
    @cancel="cancel"
    @save="save">
    <v-card-text>
      <div>
        <span>Measures as of:</span>
      </div>
      <v-row no-gutters>
        <v-col cols="4" v-for="measure in editedMeasures" :key="measure.title">
          <text-input
            class="mt-1 mr-4"
            :textFormat="'numeric'"
            :label="measure.title"
            :placeholder="'-'"
            :value="measure.measureValue"
            :suffix="'SQFT'"
            @textInputUpdated="updateMeasure(measure, $event)">
          </text-input>
        </v-col>
      </v-row>
      <div class="pt-2">
        <span>{{ helperMessage }}</span>
      </div>
      <v-row no-gutters class="mt-3">
        <v-col cols="12">
          <date-picker
            :label="'Effective Date'"
            :initialDate="asOfDate"
            @dateSelected="setEffectiveDate">
          </date-picker>
        </v-col>
      </v-row>
    </v-card-text>
  </base-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseDialog from '../../BaseComponents/BaseDialog'
import TextInput from '../../BaseComponents/TextInput'
import DatePicker from '../../BaseComponents/DatePicker'

export default {
  components: {
    BaseDialog,
    TextInput,
    DatePicker
  },
  props: ['showDialog', 'measures', 'asOfDate', 'allowSave'],
  data () {
    return {
      dialogTitle: 'Edit Property Measures',
      editedMeasures: [],
      effectiveDate: this.asOfDate
    }
  },
  methods: {
    ...mapActions(['changeData']),
    save () {
      this.checkEffectiveDate()
    },
    cancel () {
      this.$emit('cancel', true)
    },
    checkEffectiveDate () {
      this.$emit('checkEffectiveDate', {
        effectiveDate: this.effectiveDate,
        editedMeasures: this.editedMeasures
      })
    },
    updateMeasure (measure, e) {
      let existingMeasure = this.editedMeasures.find(m => {
        return m.measureType === measure.measureType
      })

      if (existingMeasure) {
        existingMeasure.measureValue = e
        existingMeasure.propertyId = this.currentSelectedItem.property
      } else {
        this.editedMeasures.push({
          measureType: measure.measureType,
          measureValue: e,
          title: measure.title,
          propertyId: this.currentSelectedItem.property
        })
      }
    },
    setEffectiveDate (e) {
      this.effectiveDate = e
    }
  },
  computed: {
    ...mapGetters(['currentSelectedItem']),
    helperMessage () {
      return 'Please choose the date that your edits will go into effect.'
    }
  },
  mounted () {
    if (this.measures) {
      this.editedMeasures = this.measures
    }
  }
}
</script>
