<template>
  <v-container fluid class="resGray lighten-6">
    <v-app-bar flat height="36">
      <v-spacer></v-spacer>
      <round-button
        color='var(--v-resGray-darken3)'
        width='40px'
        height='30px'
        :outline='true'
        :icon='true'
        :depressed='true'
        iconText='delete_outline'
        backgroundColor='white'
        borderColor='var(--v-resGray-lighten1)'
        buttonClass='mr-3'
        @click="openDeleteDialog">
      </round-button>
      <round-button
        height='30px'
        :outline='true'
        :depressed='true'
        buttonText='Print Overview'
        backgroundColor='white'
        buttonTextColor='var(--v-resBlue-darken1)'
        borderColor='var(--v-resBlue-darken1)'
        buttonClass='mr-3'
        @click='openLeaseProfile'>
      </round-button>
      <round-button
        height='30px'
        width='143px'
        dark
        :depressed='true'
        :buttonText='buttonTitle'
        backgroundColor='var(--v-resBlue-darken1)'
        borderColor='var(--v-resGray-lighten1)'
        buttonClass='mr-6'
        @click="openAttachments">
      </round-button>
    </v-app-bar>

    <v-row class="mx-6 mt-1">
      <v-col cols="6">
        <lease-details></lease-details>
      </v-col>
      <v-col cols="6">
        <lease-contacts></lease-contacts>
      </v-col>
    </v-row>

    <v-row row class="mx-6 mt-1">
      <v-col cols="12">
        <notes-card></notes-card>
      </v-col>
    </v-row>

    <!-- <v-layout column class="mx-6">

      <v-layout row>
        <h2 class="mt-6">Amendments</h2>
        <v-spacer></v-spacer>
        <round-button
          color='var(--v-resGreen-base)'
          height='30px'
          dark
          :depressed='true'
          buttonText='+ Add Amendment'
          backgroundColor='var(--v-resGreen-base)'
          class="mt-6 mr-0">
        </round-button>
      </v-layout>

      <data-table
        :headers="leaseAmendmentsHeaders"
        :tableData="leaseAmendments"
        :noData="'No Amendments to display'"
        noFooter=true>
      </data-table>

      <v-layout row>
        <h2 class="mt-6">Options</h2>
        <v-spacer></v-spacer>
        <round-button
          color='var(--v-resGreen-base)'
          height='30px'
          dark
          :depressed='true'
          buttonText='+ Add Option'
          backgroundColor='var(--v-resGreen-base)'
          class="mt-6 mr-0">
        </round-button>
      </v-layout>

      <data-table
        :headers="leaseOptionsHeader"
        :tableData="leaseOptions"
        :noData="'No Options to display'"
        noFooter=true>
      </data-table>

    </v-layout> -->
    <!-- Dialogs -->
    <attachments
      :showDialog="attachmentsDialog"
      v-if="attachmentsDialog"
      @cancel="closeAttachments">
    </attachments>
    <delete-dialog
      :showDialog="deleteDialog"
      @confirmDelete="confirmDelete"
      @cancelDelete="cancelDialog">
    </delete-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import RoundButton from '../BaseComponents/RoundButton'
import LeaseDetails from './DetailsCard'
import LeaseContacts from './ContactsCard'
import NotesCard from './NotesCard'
import Attachments from './Dialogs/Attachments'
import DeleteDialog from '../BaseComponents/DeleteDialog'

export default {
  components: {
    RoundButton,
    LeaseDetails,
    LeaseContacts,
    NotesCard,
    Attachments,
    DeleteDialog
  },
  data () {
    return {
      leaseContacts: [
        {
          contactName: 'Carol Smith',
          contactType: 'Primary',
          phoneNumber: '972-999-6854',
          statementPreference: 'Email and mailing'
        },
        {
          contactName: 'John Smith',
          contactType: 'Primary',
          phoneNumber: '972-999-6854',
          statementPreference: 'Email and mailing'
        }
      ],
      leaseOptionsCount: 0,
      leaseAmendmentsCount: 0,
      attachmentsDialog: false,
      deleteDialog: false
    }
  },
  methods: {
    ...mapActions(['changeLoading', 'fetchData', 'deleteData']),
    openAttachments () {
      this.attachmentsDialog = true
    },
    closeAttachments () {
      this.attachmentsDialog = false
    },
    openLeaseProfile () {
      window.open(this.reportUrl, '_blank')
    },
    getToken () {
      this.fetchData({ newPage: 'ReportConnectionToken', params: { } }).catch(error => { console.log(error.response) })
    },
    openDeleteDialog () {
      this.deleteDialog = true
    },
    cancelDialog () {
      this.deleteDialog = false
    },
    confirmDelete () {
      this.deleteData({ currentPage: 'Leases', removeItem: this.currentSelectedItem }).then(() => {
        this.$router.push({ name: 'Leases' })
      }).catch(error => { console.log(error.response) })
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentSelectedItem', 'currentUser']),
    leaseOptionsHeader () {
      return this.currentHeaders('LeaseOptions')
    },
    leaseOptions () {
      return this.currentData('LeaseOptions')
    },
    leaseAmendments () {
      return this.currentData('LeaseAmendments')
    },
    leaseAmendmentsHeaders () {
      return this.currentHeaders('LeaseAmendments')
    },
    buttonTitle () {
      return 'Attachments (' + (this.currentData('Documents').length || '0') + ')'
    },
    clientQueryString () {
      return `&report_connection_token=${this.connectionToken}&lease=${this.currentSelectedItem.lease}`
    },
    reportUrl () {
      const baseURL = process.env.VUE_APP_REPORT_VIEWER_URL
      return `${baseURL}/ViewReport.aspx?report_path=/RMCOMM/PM/Lease_Profile${this.clientQueryString}`
    },
    connectionToken () {
      return this.currentData('ReportConnectionToken')
    }
  },
  created () {
    this.changeLoading(true)
    this.getToken()
    this.fetchData({newPage: 'LeaseOptions', params: { Lease: this.currentSelectedItem.lease }}).catch(error => { console.log(error.response) })
    this.fetchData({newPage: 'LeaseAmendments', params: { Lease: this.currentSelectedItem.lease }}).catch(error => { console.log(error.response) })
    this.fetchData({newPage: 'LeaseDetails', params: { organizationId: this.currentSelectedItem.organizationId }}).catch(error => { console.log(error.response) })
    this.fetchData({newPage: 'Documents', params: { ApplicationRole: 'LEAS', PrimaryKey: this.currentSelectedItem.lease }}).catch(error => { console.log(error.response) })
  }
}
</script>

<style lang="scss" scoped>
h3, h2 {
  font-weight: 400;
}
</style>
