<template>
  <base-dialog
    v-if="editedParameters"
    :setWidth="350"
    :showDialog="showDialog"
    :title="dialogTitle"
    @cancel="cancel"
    @save="save">

    <div v-if="leaseItem.chargeType===61">
      <v-card-text>
        <text-input
          v-model="editedParameters.flatPercent"
          prefix="%"
          placeholder="0"
          label="Tax Percentage"
          textFormat="numeric"
          width="125px">
        </text-input>
      </v-card-text>
    </div>

    <div v-if="leaseItem.chargeType===8">
      <v-card-text>
        <v-layout column>
          <text-input
            v-model="editedParameters.baseBaseAmount"
            prefix="$"
            placeholder="0.00"
            label="Amount"
            textFormat="currency"
            width="125px">
          </text-input>
          <text-input
            v-model="editedParameters.flatPercent"
            prefix="%"
            placeholder="0"
            label="Percentage"
            textFormat="numeric"
            width="125px"
            class="mt-2">
          </text-input>
        </v-layout>
      </v-card-text>
    </div>

    <div v-if="leaseItem.chargeType===7">
      <v-card-text>
        <text-input
          v-model="editedParameters.flatDays"
          placeholder="0"
          label="Grace Period Days"
          textFormat="numeric"
          width="125px"
          class="mr-4">
        </text-input>
        <text-input
          v-model="editedParameters.baseMinAmount"
          prefix="$"
          placeholder="0.00"
          label="Minimum Balance"
          textFormat="currency"
          width="125px">
        </text-input>
        <text-input
          v-model="editedParameters.baseBaseAmount"
          prefix="$"
          placeholder="0.00"
          label="Amount"
          textFormat="currency"
          width="125px"
          class="mr-4 mt-2">
        </text-input>
        <text-input
          v-model="editedParameters.flatPercent"
          prefix="%"
          placeholder="0"
          label="Percentage"
          textFormat="numeric"
          width="125px"
          class="mt-2">
        </text-input>
      </v-card-text>
    </div>

  </base-dialog>
</template>

<script>
import BaseDialog from '../../BaseComponents/BaseDialog'
import TextInput from '../../BaseComponents/TextInput'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BaseDialog,
    TextInput,
  },
  props: ['showDialog', 'leaseItem'],
  data () {
    return {
      editedParameters: null
    }
  },
  methods: {
    ...mapActions(['fetchData', 'changeData']),
    getParameters () {
      this.fetchData({
        newPage: 'Lease Parameters',
        params: {
          lease: this.leaseItem.lease,
          leaseItem: this.leaseItem.leaseItem
        }
      }).catch(error => { console.log(error.response) })
    },
    cancel () {
      this.$emit('cancel', true)
    },
    save () {
      this.changeData({
        currentPage: 'Lease Parameters',
        newData: {
          lease: this.leaseItem.lease,
          leaseItem: this.leaseItem.leaseItem,
          parameters: this.editedParameters
        }
      }).then(() => {
        this.$emit('saveComplete')
      }).catch(error => { console.log(error.response) })
    }
  },
  computed: {
    ...mapGetters(['currentData']),
    dialogTitle () {
      return `${this.leaseItem.chargeTypeName} Parameters`
    },
    currentParameters () {
      return this.currentData('Lease Parameters')
    },
  },
  watch: {
    currentParameters: function (val) {
      this.editedParameters = val
    }
  },
  created () {
    this.getParameters()
  }
}
</script>
