<template>
  <div>
    <overview-card
      :height="'270'"
      :title="contactsTitle"
      :noEdit="true"
      @openTitleLink="openContacts"
      :titleLink="'View all'">
      <v-row class="ml-2">
        <v-col cols="6">
          <h4>Contact Name</h4>
          <span>{{ propertyContacts[0].firstName + " " + propertyContacts[0].lastName }}</span>
        </v-col>
        <v-col cols="6">
          <h4>Contact Type</h4>
          <span>{{ propertyContacts[0].contactTypeName }}</span>
        </v-col>
      </v-row>
      <v-row class="ml-2">
        <v-col cols="6">
          <h4>Phone Number</h4>
          <span v-if="propertyContacts[0].phones && propertyContacts[0].phones.length > 0">{{ propertyContacts[0].phones[0].phoneNumber | phoneNumberFilter(propertyContacts[0].phones[0].phoneNumber) }}</span>
          <span v-else></span>
        </v-col>
        <v-col cols="6">
          <h4>Email Address</h4>
          <span>{{ propertyContacts[0].emailAddress }}</span>
        </v-col>
      </v-row>
      <v-row class="ml-2">
        <v-col cols="12">
          <h4>Statement Preference</h4>
          <span>{{ statementPreference }}</span>
        </v-col>
      </v-row>
    </overview-card>
    <!-- Dialogs -->
    <contacts-dialog
      @cancel="cancel"
      @save="save"
      v-if="contactsDialog"
      :showDialog="contactsDialog">
    </contacts-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import OverviewCard from '../BaseComponents/OverviewCard'
import ContactsDialog from '../Common/Dialogs/ContactsCardDialog'

export default {
  components: {
    OverviewCard,
    ContactsDialog
  },
  data () {
    return {
      Contacts: false,
      contactsDialog: false
    }
  },
  methods: {
    ...mapActions(['fetchData']),
    setContacts (val) {
      this.Contacts = val
    },
    openContacts () {
      this.contactsDialog = true
    },
    save () {
      this.contactsDialog = false
      this.fetchData({newPage: 'Contacts', params: { OrganizationId: this.currentSelectedItem.organizationId, ApplicationRole: this.currentSelectedItem.applicationRole }}).catch(error => { console.log(error.response) })
    },
    cancel () {
      this.contactsDialog = false
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentSelectedItem']),
    contactsTitle () {
      return 'Contacts (' + (this.contactsLength || '0') + ')'
    },
    contactsLength () {
      return this.currentData('Contacts').length
    },
    propertyContacts () {
      if (this.currentData('Contacts') && this.currentData('Contacts').length > 0) {
        this.setContacts(true)
        return this.currentData('Contacts')
      } else {
        this.setContacts(false)
        return [{
          firstName: '',
          lastName: '',
          contactTypeName: '',
          phones: {
            phoneNumber: ''
          },
          emailAddress: ''
        }]
      }
    },
    statementPreference () {
      if (this.currentSelectedItem) {
        return this.currentSelectedItem.statementPreferenceName
      }
      return null
    }
  },
  created () {
    this.fetchData({newPage: 'Contacts', params: { OrganizationId: this.currentSelectedItem.organizationId, ApplicationRole: this.currentSelectedItem.applicationRole }}).catch(error => { console.log(error.response) })
  },
  filters: {
    phoneNumberFilter (val) {
      if (val) {
        let cleaned = val.replace(/\D/g, '')
        if (cleaned.length === 10) {
          return cleaned.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
        }
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
h4 {
  text-transform: uppercase;
  color: var(--v-resGray-darken1);
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 5px;

  a {
    padding-left: 5px;
    text-transform: none;
  }
}
</style>
