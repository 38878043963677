<template>
  <v-container fluid class="resGray lighten-6">
    <v-row>
      <v-col cols="12" class="pb-0">
        <div class="mx-6">
          <span class="resGray-darken5--text title">Pools</span>
        </div>
      </v-col>
    </v-row>
    <v-layout>
      <v-container fluid grid-list-md ml-6 v-if="!isMobile">
        <v-layout wrap>
          <v-flex shrink v-for="pool in poolNameList" :key="pool" class="mr-4">
            <pools-card
              :content="pool"
              :newCard="false"
              :isSelected="pool == selectedPool ? true : false"
              @cardClicked="selectPool(pool)">
            </pools-card>
          </v-flex>
          <v-flex>
            <pools-card
              :newCard="true"
              @cardClicked="openNewPoolDialog">
            </pools-card>
          </v-flex>
        </v-layout>
      </v-container>
      <v-row v-if="isMobile" mt-2 mb-4>
        <drop-down
          :selectedValue="{ text: selectedPool }"
          :customList="poolNameList"
          :label="'Select Pool'"
          :multiple='false'
          rules="required"
          :genericLabel="true"
          width="164px"
          @itemSelected="selectPoolDropdown">
        </drop-down>
        <round-button
          color='var(--v-resBlue-darken1)'
          height='30px'
          width='150px'
          dark
          :depressed='true'
          :outline="true"
          buttonText='+ New Property Pool'
          borderColor='var(--v-resBlue-darken1)'
          backgroundColor="var(--v-resWhite-base)"
          buttonClass='mt-4'
          @click="openNewPoolDialog">
        </round-button>
      </v-row>
    </v-layout>
    <v-row>
      <v-col cols="12" class="pb-0">
        <div class="mx-6">
          <span class="resGray-darken5--text title">Property Cycles</span>
        </div>
      </v-col>
    </v-row>
    <v-layout>
      <v-container fluid grid-list-md ml-6>
        <v-layout wrap>
          <v-flex shrink v-for="cycle in cycles" :key="cycle.property" class="mr-4">
            <cycles-card
              :content="cycle"
              @click.native="openCycle(cycle)">
            </cycles-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-layout>
    <!-- Dialogs -->
    <new-pool-dialog
      :showDialog="newPoolDialog"
      @cancel="cancelNewPoolDialog"
      @complete="completeNewPoolDialog">
    </new-pool-dialog>
  </v-container>
</template>

<script>
import PoolsCard from './PoolsCard'
import CyclesCard from './CyclesCard'
import NewPoolDialog from './NewPoolDialog/NewPoolDialog'
import DropDown from '../../BaseComponents/DropDown'
import RoundButton from '../../BaseComponents/RoundButton'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    PoolsCard,
    CyclesCard,
    NewPoolDialog,
    DropDown,
    RoundButton
  },
  data () {
    return {
      selectedPool: null,
      newPoolDialog: false
    }
  },
  methods: {
    ...mapActions(['fetchData']),
    selectPool (val) {
      this.selectedPool = val
    },
    selectPoolDropdown (val) {
      this.selectedPool = val.value
    },
    cancelNewPoolDialog () {
      this.newPoolDialog = false
    },
    completeNewPoolDialog (val) {
      this.$emit('openCyclePage', val)
      this.newPoolDialog = false
    },
    openNewPoolDialog () {
      this.newPoolDialog = true
    },
    openCycle (val) {
      this.$emit('openCyclePage', val)
    }
  },
  computed: {
    ...mapGetters(['currentData']),
    isMobile () {
      return this.$vuetify.breakpoint.mobile
    },
    poolNameList () {
      let list = this.currentData('Recovery Pools')
      let poolNames = list.map(x => x.chargeCodeName)
      return [...new Set(poolNames)]
    },
    cycles () {
      let list = this.currentData('Recovery Pools')
      let selectedList = list.filter(x => x.chargeCodeName === this.selectedPool)
      let cycles = []
      selectedList.forEach(item => {
        cycles.push({
          propertyName: item.propertyName,
          chargeCode: item.chargeCode,
          chargeCodeName: item.chargeCodeName,
          property: item.property,
          poolName: item.poolName,
          current: item.currentCycleYear,
          cycles: item.cycleCount
        })
      })
      return cycles
    }
  },
  created () {
    this.fetchData({ newPage: 'Recovery Pools' }).then(() => {
      if (this.currentData('Recovery Pools').length > 0) {
        this.selectedPool = this.currentData('Recovery Pools')[0].chargeCodeName
      }
    }).catch(error => { console.log(error.response) })
  }
}
</script>
