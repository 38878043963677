<template>
  <v-container fluid>
    <v-toolbar class="pl-0" flat color="var(--v-resWhite-base)" height="40">
      <v-btn :ripple="false" flat :to="{ name: currentSection }" color="var(--v-resWhite-base)" class="back-button">
        <v-btn small color="var(--v-resBlue-darken1)" icon><v-icon size="18" dark>arrow_back</v-icon></v-btn>
        <span class="text-none ml-0 back-button-text">Back to {{ currentSection }}</span>
      </v-btn>
    </v-toolbar>
     <v-layout row>
      <v-flex>
        <base-tabs
          :tabs="tabs"
          @tabChanged="setActiveTab">
        </base-tabs>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex>
        <table class="v-datatable v-table theme--light">
          <thead class="thead-dark column sortable text-left">
            <tr>
              <th></th>
              <th scope="col">Name</th>
              <th scope="col">Description</th>
              <th scope="col">Category</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <draggable v-model="sortableReportList" tag="tbody" @end="updateReportList">
            <tr v-for="report in sortableReportList" :key="report.report_Id" class="draggable">
              <td>
                <v-icon>drag_handle</v-icon>
              </td>
              <td>{{ report.report_Name }}</td>
              <td>{{ report.report_Description }}</td>
              <td>{{ report.category }}</td>
              <td>
                <v-icon
                  color="var(--v-resGray-lighten1)"
                  class="mr-2"
                  @click="editReport(report)">
                  edit
                </v-icon>
                <v-icon
                  color="var(--v-resGray-lighten1)"
                  class="ml-2"
                  @click="showDeleteReport(report)">
                  delete
                </v-icon>
              </td>
            </tr>
          </draggable>
        </table>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <round-button
        height='40px'
        dark
        :depressed='true'
        buttonText='New Report'
        backgroundColor='var(--v-resBlue-darken1)'
        borderColor='var(--v-resGray-lighten1)'
        buttonClass='mt-4'
        @click="newReport">
      </round-button>
    </v-layout>
    <new-report-dialog
      v-if="showNewDialog"
      :showDialog="showNewDialog"
      :category="categoryFilter"
      :report="selectedReport"
      @cancel="hideNewReportDialog"
      @saveComplete="saveNewReportComplete">
    </new-report-dialog>
    <delete-report-dialog
      v-if="showDeleteDialog"
      :showDialog="showDeleteDialog"
      :report="selectedReport"
      @cancel="hideDeleteReportDialog"
      @deleteComplete="deleteReportComplete">
    </delete-report-dialog>
  </v-container>
</template>

<script>
import RoundButton from '../../components/BaseComponents/RoundButton'
import BaseTabs from '../../components/BaseComponents/BaseTabs'
import NewReportDialog from './Dialogs/NewReport'
import DeleteReportDialog from './Dialogs/DeleteReport'
import draggable from 'vuedraggable'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    RoundButton,
    NewReportDialog,
    DeleteReportDialog,
    BaseTabs,
    draggable
  },
  data () {
    return {
      sortableReportList: [],
      tabs: [
        { header: 'OPERATIONAL' },
        { header: 'FORMS' },
        { header: 'CUSTOM' }
      ],
      selectedReport: null,
      categoryFilter: 'Operational',
      showNewDialog: false,
      showDeleteDialog: false
    }
  },
  methods: {
    ...mapActions(['fetchData', 'changeData']),
    getReports () {
      this.fetchData({ newPage: 'SetupReports' }).then(() => {
        this.sortableReportList = this.reportList
      }).catch(error => { console.log(error.response) })
    },
    updateReportList () {
      this.changeData({
        currentPage: 'SetupReports',
        newData: {
          reportList: this.orderedReportList
        }
      }).then(() => {
        this.getReports()
      }).catch(error => { console.log(error.response) })
    },
    setActiveTab (e) {
      this.categoryFilter = e.header
      this.getReports()
    },
    newReport () {
      this.showNewDialog = true
    },
    hideNewReportDialog () {
      this.showNewDialog = false
      this.selectedReport = null
    },
    saveNewReportComplete () {
      this.selectedReport = null
      this.hideNewReportDialog()
      this.getReports()
    },
    editReport (e) {
      this.selectedReport = e
      this.showNewDialog = true
    },
    hideDeleteReportDialog () {
      this.selectedReport = null
      this.showDeleteDialog = false
    },
    deleteReportComplete () {
      this.selectedReport = null
      this.hideDeleteReportDialog()
      this.getReports()
    },
    showDeleteReport (e) {
      this.selectedReport = e
      this.showDeleteDialog = true
    }
  },
  computed: {
    ...mapGetters(['currentSection', 'currentData', 'currentHeaders']),
    reportList () {
      return this.currentData('SetupReports').filter(r => r.category.toUpperCase() === this.categoryFilter.toUpperCase())
    },
    orderedReportList () {
      return this.sortableReportList.map(x => {
        x.sort_Order = this.sortableReportList.indexOf(x)
        return x
      })
    },
    tableHeaders () {
      return this.currentHeaders('SetupReports')
    },
    tableKey () {
      return 'report_Id'
    }
  },
  created () {
    this.getReports()
  }
}
</script>

<style lang="scss" scoped>
.back-button {
  cursor: pointer;
  padding-left: 0px;
}

.back-button-text {
  color: var(--v-resBlue-base);
}

.draggable {
  cursor: move;
}

.sortable-chosen {
  background-color: var(--v-resGray-darken4) !important;
  opacity: 0.7;
}
</style>
