<template>
  <base-dialog
    :showDialog="showDialog"
    :title="'Property Group Properties'"
    :maxWidth="750"
    :saveButtonText="'Done'"
    :saveButtonColor="'var(--v-resBlue-darken1)'"
    :cancelButtonText="'Remove Properties'"
    :cancelButtonWidth="'145px'"
    :hideClose="true"
    @cancel="removeProperties"
    @save="cancel">
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="12">
          <drop-down
            :customList="filteredProperties"
            :label="'Property'"
            :multiple="true"
            :multipleDetail="'Properties'"
            :selectedValue="selectedProperties"
            width="190px"
            class="mr-6"
            :hideDetails="true"
            @itemSelected="selectProperties">
          </drop-down>
          <round-button
            height="30px"
            :width="'125px'"
            :buttonText="'Add Properties'"
            :buttonClass="'ma-0'"
            :depressed="true"
            :flat="true"
            :dark="true"
            :backgroundColor="'var(--v-resGreen-base)'"
            @click="addProperties">
          </round-button>
        </v-col>
      </v-row>
      <v-row row>
        <v-col cols="12">
          <data-table
            :headers="tableHeaders"
            :tableData="propertiesInGroup"
            :tableKey="'property'"
            :selectableRows="true"
            :showSelectAll="true"
            @selectionChanged="changeSelectedPropertiesInGroup">
          </data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </base-dialog>
</template>

<script>
import DropDown from '../../..//BaseComponents/DropDown'
import RoundButton from '../../../BaseComponents/RoundButton'
import BaseDialog from '../../../BaseComponents/BaseDialog'
import DataTable from '../../../BaseComponents/Table'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    DropDown,
    RoundButton,
    BaseDialog,
    DataTable
  },
  props: ['showDialog', 'propertyGroup'],
  data () {
    return {
      propertiesInGroup: [],
      selectedProperties: [],
      selectedPropertiesInGroup: []
    }
  },
  methods: {
    ...mapActions(['fetchData', 'changeData', 'deleteData', 'fetchDropdown']),
    async getPropertyGroupProperties () {
      this.fetchData({
        newPage: 'PropertyGroupsDetails',
        params: {
          propertyGroup: this.propertyGroup.propertyGroup
        }
      }).catch(error => { console.log(error.response) })

      this.selectedProperties = []
    },
    cancel () {
      this.$emit('cancel')
    },
    selectProperties (e) {
      this.selectedProperties = e
      this.selectedPropertiesInGroup = []
      this.$eventHub.$emit('deselectAll')
    },
    addProperties () {
      if (!this.propertiesAreSelected) {
        let values = this.selectedProperties.map(x => {
          return {
            property: x.value,
            propertyGroup: this.propertyGroup.propertyGroup
          }
        })
        this.changeData({
          currentPage: 'PropertyGroupsDetails',
          newData: {
            Properties: values
          }
        }).then(() => {
          this.getPropertyGroupProperties()
        }).catch(error => { console.log(error.response) })
      }
    },
    removeProperties () {
      if (this.selectedPropertiesInGroup.length > 0) {
        this.deleteData({
          currentPage: 'PropertyGroupsDetails',
          removeItem: {
            propertyGroup: this.propertyGroup.propertyGroup,
            properties: this.selectedPropertiesInGroup
          }
        }).then(() => {
          this.getPropertyGroupProperties()
        }).catch(error => { console.log(error.response) })
      } else {
        this.cancel()
      }
    },
    changeSelectedPropertiesInGroup (e) {
      this.selectedProperties = []
      this.selectedPropertiesInGroup = e
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentDropdown']),
    tableHeaders () {
      return this.currentHeaders('PropertyGroupsDetails')
    },
    tableData () {
      return this.currentData('PropertyGroupsDetails')
    },
    existingProperties () {
      return this.tableData.map(p => {
        return p.property.trim()
      })
    },
    propertiesAreSelected () {
      return this.selectedPropertiesInGroup.length > 0
    },
    buttonText () {
      return this.propertiesAreSelected ? 'Remove Properties from Group' : 'Add Property to Group'
    },
    buttonTextColor () {
      return this.propertiesAreSelected ? 'resRed-base--text' : 'blue--text'
    },
    filteredProperties () {
      let items = this.currentDropdown('Properties')
      if (items !== null && items !== undefined) {
        return items.filter(i => {
          let index = this.existingProperties.indexOf(i.value)
          if (!~index) {
            return i
          }
        })
      }
      return []
    }
  },
  watch: {
    tableData: function (val) {
      if (val) {
        this.propertiesInGroup = this.tableData.map(d => {
          d.selected = false
          return d
        })
      }
    }
  },
  created () {
    if (this.propertyGroup) {
      this.getPropertyGroupProperties()
    }
    this.fetchDropdown({ newDropdown: 'Properties' }).catch(error => { console.log(error.response) })
  }
}
</script>
