<template>
<div class="resGray lighten-6">
  <pools-cycles v-if="poolsActive"
    @openCyclePage='openCyclePage'>
  </pools-cycles>
  <add-cycles v-if="cyclesActive"
    :poolInfo="poolInfo"
    @refreshCyclePage='openCyclePage'
    @openPoolsPage="openPoolsPage">
  </add-cycles>
</div>
</template>

<script>
import PoolsCycles from './PoolsCycles'
import AddCycles from './AddCycles'
import { mapActions } from 'vuex'
export default {
  components: {
    PoolsCycles,
    AddCycles
  },
  data () {
    return {
      cyclesActive: false,
      poolsActive: true,
      poolInfo: {}
    }
  },
  methods: {
    ...mapActions(['changeSelectedPool']),
    openCyclePage (val) {
      this.poolsActive = false
      this.cyclesActive = true
      this.changeSelectedPool(val)
      this.poolInfo = val
    },
    openPoolsPage () {
      this.poolInfo = {}
      this.poolsActive = true
      this.cyclesActive = false
    }
  }
}
</script>
