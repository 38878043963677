export function validateValue (v, r, l) {
  if (typeof v === 'object' && v !== null) {
    let e = []
    for (var k of Object.keys(v)) { // check each property if it has a value
      if (r.find(x => x.value === k)) {
        if (typeof v[k] === 'object' && v[k] !== null) {
          for (var o of Object.keys(v[k])) { // if a property is an object check the values of that property
            e.push(validateObject(v[k][o], k, r.find(x => x.value === k), l, o))
          }
        } else {
          e.push(validateObject(v[k], k, r.find(x => x.value === k), l))
        }
      } else {
        continue
      }
    }
    return [...new Set([].concat(...e))]
  }
  return []
}

export function validateObject (v, k, l, f, o) {
  let n = k.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase()
  let e = []
  if (l.unique) {
    let list = f.map(x => {
      if (o) {
        return x[k][o]
      }
      return x[k]
    })
    if (list.filter(x => x !== null).length === list.length) {
      if (!isUnique(v, list)) {
        e.push(n.charAt(0).toUpperCase() + n.slice(1) + ' is required to be unique.')
      }
    }
  }
  if (l.maxLength) {
    if (!isBelowCharacterLimit(v, l.maxLength)) {
      e.push(n.charAt(0).toUpperCase() + n.slice(1) + ' exceeds character limit.')
    }
  }
  if (l.required) {
    if (!hasValue(v)) {
      e.push(n.charAt(0).toUpperCase() + n.slice(1) + ' is missing information.')
    }
  }
  if (l.positive) {
    if (!isPositive(v)) {
      e.push(n.charAt(0).toUpperCase() + n.slice(1) + ' negative values are not allowed.')
    }
  }
  return e
}

export function handleErrorToolTip (i, e) {
  if (e.length > 0) {
    i.errorToolTip.hasIcon = true
    i.errorToolTip.tooltip = e.join(' ')
  } else {
    i.errorToolTip.hasIcon = false
    i.errorToolTip.tooltip = null
  }
}

export function isUnique (v, l) {
  if (Array.isArray(l)) {
    return l.filter(x => x.toLowerCase() === v.toLowerCase()).length < 2
  }
  return false
}

export function hasValue (v) {
  return v !== null && v !== undefined && v !== ''
}

export function isPositive (v) {
  return v >= 0
}

export function isBelowCharacterLimit (v, l) {
  if (hasValue(v)) {
    return v.length <= l
  }
  return true
}
