<template>
  <div>
    <v-app-bar class="pl-0" flat color="var(--v-resWhite-base)" height="40">
      <v-btn :ripple="false" text :to="{ name: currentSection }" color="var(--v-resBlue-darken1)" class="back-button">
        <v-btn small color="var(--v-resBlue-darken1)" icon><v-icon size="18" dark>arrow_back</v-icon></v-btn>
        <span class="text-none ml-0 back-button-text">Back to {{ currentSection }}</span>
      </v-btn>
    </v-app-bar>
    <base-tabs
      :tabs="tabs"
      @tabChanged="switchTab">
      <slot></slot>
    </base-tabs>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseTabs from '../BaseComponents/BaseTabs'

export default {
  components: {
    BaseTabs
  },
  props: ['tabs'],
  data () {
    return {}
  },
  computed: {
    ...mapGetters(['currentSection', 'currentTab'])
  },
  methods: {
    ...mapActions(['fetchData', 'changeLoading', 'changeTab']),
    initialize () {
      this.changeLoading(true)
      if (!this.currentTab) {
        this.switchTab(this.tabs[0])
      } else {
        if (this.currentTab.originalIndex) {
          this.switchTab(this.tabs[this.currentTab.originalIndex])
        } else {
          this.switchTab(this.tabs[this.currentTab.index])
        }
      }
    },
    switchTab (e) {
      this.fetchData({ newPage: this.tabs[this.tabs.indexOf(e)].header })
      var tab = this.tabs[this.tabs.indexOf(e)]
      if (!tab.index) {
        tab.index = this.tabs.indexOf(e)
      }
      if (tab.index > 5) {
        tab.index = 5
      }
      this.changeTab(tab)
    }
  },
  created () {
    this.initialize()
  }
}
</script>
<style lang="scss" scoped>
.back-button{
  &:hover {
    background-color: transparent;
    &:before {
      background-color: transparent;
    }
  }
  &:before{
    background-color: transparent;
  }
}
</style>
