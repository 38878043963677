<template>
  <v-container>
    <div>
      <v-btn :ripple="false" text @click="openPoolsPage" color="var(--v-resWhite-base)" class="ml-2 pl-0">
        <v-btn small color="var(--v-resBlue-darken1)" icon><v-icon size="18" dark>arrow_back</v-icon></v-btn>
        <span class="text-none ml-0 back-button-text resBlue-darken1--text">{{ poolInfo.propertyName }}</span>
      </v-btn>
    </div>
    <v-app-bar flat>
      <drop-down
        :selectedValue="{ text: selectedCycleName }"
        :items="'Recovery Cycles'"
        :label="'Pool Cycle'"
        width="240px"
        class="mr-3"
        :genericLabel="true"
        :multiple='false'
        :getParams="{ PropertyId: poolInfo.property, ChargeCode: poolInfo.chargeCode }"
        @itemSelected="selectCycle">
      </drop-down>
      <round-button
        color='var(--v-resGreen-base)'
        height='30px'
        width='120px'
        dark
        :depressed='true'
        buttonText='+ New Cycle'
        borderColor='var(--v-resGreen-base)'
        backgroundColor="var(--v-resGreen-base)"
        buttonClass='mt-9-plus'
        @click="newCycle">
      </round-button>
    </v-app-bar>
    <div class="ml-4 mt-6 mb-2">
      <span class="title">{{ poolInfo.poolName }} <span class="resGray-darken4--text">{{ currentPoolData.poolFrequencyName ? `(${currentPoolData.poolFrequencyName})` : ''}}</span></span>
    </div>
    <pool-cycle-info-card
      :cycleInfo="currentCycle"
      @deletePool="poolDeleted"
      @poolEdited="poolEdited">
    </pool-cycle-info-card>
    <div class="ml-4 mt-6 mb-2">
      <span class="title">Cycle Leases</span>
    </div>
    <data-table
      class="mx-4"
      :headers="headers"
      :tableData="getData"
      :noData="'Select a cycle to display or create a new cycle.'"
      @openLeaseExpenseAccountSettings="openLeaseExpenseAccountSettings">
    </data-table>
    <v-app-bar flat height="36" class="mt-3">
      <v-spacer></v-spacer>
      <round-button
        height='30px'
        width='120px'
        :buttonText="'Delete Cycle'"
        :buttonClass="'mr-3'"
        :borderColor="'var(--v-resRed-base)'"
        buttonTextColor="var(--v-resRed-base)"
        :backgroundColor="'white'"
        :depressed="true"
        :disabled="selectedCycle === undefined || selectedCycle === null"
        :flat="true"
        :outline="true"
        @click="deleteCycle">
      </round-button>
      <round-button
        height='30px'
        width='100px'
        :buttonText="'Edit Cycle'"
        :buttonClass="''"
        :borderColor="'var(--v-resBlue-darken1)'"
        buttonTextColor="var(--v-resBlue-darken1)"
        backgroundColor="var(--v-resWhite-base)"
        :depressed="true"
        :flat="true"
        :outline="true"
        @click="editCycle">
      </round-button>
    </v-app-bar>
    <!-- Dialogs -->
    <lease-expense-account-settings-dialog
      v-if="showLeaseExpenseAccountSettingsDialog && selectedLeaseInfo"
      :showDialog="showLeaseExpenseAccountSettingsDialog"
      :leaseInfo="selectedLeaseInfo"
      @cancel="closeLeaseExpenseAccountSettings"
      @close="closeLeaseExpenseAccountSettings">
    </lease-expense-account-settings-dialog>
    <delete-dialog
      :showDialog="deleteDialog"
      @confirmDelete="confirmDelete"
      @cancelDelete="cancelDelete"
      :confirmButtonWidth="'100px'"
      :confirmButton="'Yes, Delete'"
      :message="'Please confirm that would like to delete this cycle.'">
    </delete-dialog>
    <base-dialog
      :showDialog="showDialog"
      v-if="showDialog"
      :title="dialogTitle"
      titleClass="dark-header"
      :maxWidth="380"
      :iconColor="'white'"
      @cancel="cancelDialog"
      @save="continueDialog">
      <v-row no-gutters class="ma-3">
        <v-col cols="12">
          <text-input
            :value="editedCycle.propertyName"
            label='Property'
            width="292px"
            :disabled="true">
          </text-input>
        </v-col>
      </v-row>
      <v-row no-gutters class="ma-3">
        <v-col cols="12">
          <text-input :value="editedCycle.poolName"
            :disabled="true"
            label='Pool'>
          </text-input>
        </v-col>
      </v-row>
      <v-row no-gutters class="ma-3">
        <v-col cols="12">
          <date-picker
            :label="'Start Date'"
            :defaultNull="true"
            :initialDate="editedCycle.startDate"
            @dateSelected="updateStartDate">
          </date-picker>
        </v-col>
      </v-row>
      <v-row no-gutters class="ma-3">
        <v-col cols="12">
          <text-input :value="editedCycle.cycleDescription"
            label='Cycle Description'
            width="292px"
            @textInputUpdated="updateDescription">
          </text-input>
        </v-col>
      </v-row>
    </base-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DropDown from '../../BaseComponents/DropDown'
import RoundButton from '../../BaseComponents/RoundButton'
import BaseDialog from '../../BaseComponents/BaseDialog'
import PoolCycleInfoCard from './PoolCycleInfoCard'
import DataTable from '../../BaseComponents/Table'
import TextInput from '../../BaseComponents/TextInput'
import DatePicker from '../../BaseComponents/DatePicker'
import DeleteDialog from '../../BaseComponents/DeleteDialog'
import LeaseExpenseAccountSettingsDialog from './Dialogs/LeaseExpenseAccountSettingsDialog'
import moment from 'moment'

export default {
  props: ['poolInfo'],
  components: {
    DropDown,
    RoundButton,
    PoolCycleInfoCard,
    DataTable,
    BaseDialog,
    DatePicker,
    TextInput,
    DeleteDialog,
    LeaseExpenseAccountSettingsDialog
  },
  data () {
    return {
      selectedCycle: undefined,
      selectedCycleName: undefined,
      showDialog: false,
      editedCycle: {},
      dialogTitle: '',
      deleteDialog: false,
      selectedLeaseInfo: null,
      showLeaseExpenseAccountSettingsDialog: false
    }
  },
  methods: {
    ...mapActions(['fetchData', 'changeData', 'fetchDropdown', 'deleteData', 'initializeStoreItem']),
    selectCycle (val) {
      if (val !== null && val !== undefined) {
        this.selectedCycle = val.value
        this.selectedCycleName = val.text
        this.fetchData({ newPage: 'Recovery Cycles', params: { ChargeCode: this.poolInfo.chargeCode, Property: this.poolInfo.property, CycleNumber: this.selectedCycle } }).catch(error => { console.log(error.response) })
        this.fetchData({ newPage: 'Expense Pool Leases', params: { ChargeCode: this.poolInfo.chargeCode, Property: this.poolInfo.property, CycleNumber: this.selectedCycle } }).catch(error => { console.log(error.response) })
      } else {
        this.selectedCycle = null
        this.selectedCycleName = null
      }
    },
    newCycle () {
      this.dialogTitle = 'New Cycle'
      this.editedCycle = {
        propertyName: this.poolInfo.propertyName,
        poolName: this.poolInfo.poolName,
        startDate: moment().startOf('year'),
        cycleDescription: ''
      }
      this.showDialog = true
    },
    poolDeleted () {
      this.openPoolsPage()
    },
    poolEdited (val) {
      this.$emit('refreshCyclePage', {
        propertyName: val.propertyName,
        chargeCode: val.chargeCode,
        chargeCodeName: val.chargeCodeName,
        property: val.property,
        poolName: val.poolName,
        current: val.currentCycleYear,
        cycles: val.cycleCount
      })
    },
    openPoolsPage () {
      this.initializeStoreItem('Expense Pool Leases')
      this.$emit('openPoolsPage')
    },
    cancelDialog () {
      this.showDialog = false
      this.editedCycle = {}
    },
    continueDialog () {
      if (this.dialogTitle === 'Edit Cycle') {
        this.changeData({ currentPage: 'Recovery Cycles', newData: { Property: this.poolInfo.property, ChargeCode: this.poolInfo.chargeCode, CycleName: this.editedCycle.cycleDescription, CycleStartDate: this.editedCycle.startDate, RowVersion: this.currentPoolData.rowVersion } }).then(() => {
          this.refreshDropdown()
          this.fetchData({ newPage: 'Recovery Cycles', params: { ChargeCode: this.poolInfo.chargeCode, Property: this.poolInfo.property, CycleNumber: this.selectedCycle } }).then(() => {
            this.selectedCycleName = this.currentData('Recovery Cycles').cycleName
          })
          this.editedCycle = {}
          this.showDialog = false
        }).catch(error => { console.log(error.response) })
      } else {
        this.changeData({ currentPage: 'Recovery Cycles', newData: { Property: this.poolInfo.property, ChargeCode: this.poolInfo.chargeCode, CycleName: this.editedCycle.cycleDescription, CycleStartDate: this.editedCycle.startDate } }).then(() => {
          this.refreshDropdown()
          this.editedCycle = {}
          this.showDialog = false
        }).catch(error => { console.log(error.response) })
      }
    },
    refreshDropdown () {
      this.fetchDropdown({ newDropdown: 'Recovery Cycles', params: { PropertyId: this.poolInfo.property, ChargeCode: this.poolInfo.chargeCode } }).catch(error => { console.log(error.response) })
    },
    updateDescription (val) {
      this.editedCycle.cycleDescription = val
    },
    updateStartDate (val) {
      this.editedCycle.startDate = val
    },
    editCycle () {
      this.dialogTitle = 'Edit Cycle'
      this.editedCycle = {
        propertyName: this.poolInfo.propertyName,
        poolName: this.poolInfo.poolName,
        chargeCode: this.poolInfo.chargeCode,
        startDate: moment(this.currentPoolData.cycleStartDate),
        cycleDescription: this.currentPoolData.cycleName
      }
      this.showDialog = true
    },
    confirmDelete () {
      this.deleteData({ currentPage: 'Recovery Cycles', removeItem: { Property: this.poolInfo.property, ChargeCode: this.poolInfo.chargeCode, CycleNumber: this.currentPoolData.cycleNumber, RowVersion: this.currentPoolData.rowVersion } }).then(() => {
        this.deleteDialog = false
        this.refreshDropdown()
        this.selectedCycle = undefined
        this.selectedCycleName = undefined
      }).catch(error => { console.log(error.response) })
    },
    cancelDelete () {
      this.deleteDialog = false
    },
    deleteCycle () {
      if (this.selectedCycle === undefined || this.selectedCycle === null) {
        return
      }
      this.deleteDialog = true
    },
    openLeaseExpenseAccountSettings (e) {
      this.selectedLeaseInfo = {
        poolName: e.poolName,
        chargeCode: e.chargeCode,
        property: e.property,
        lease: e.lease,
        leaseName: e.leaseName,
        propertySpace: e.propertySpace,
        cycleNumber: e.cycleNumber
      }
      this.showLeaseExpenseAccountSettingsDialog = true
    },
    closeLeaseExpenseAccountSettings () {
      this.showLeaseExpenseAccountSettingsDialog = false
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders']),
    currentCycle () {
      if (this.currentData('Expense Pool Leases').length > 0) {
        let cycleInfo = this.currentData('Expense Pool Leases')[0]
        let total = Number(0)
        this.currentData('Expense Pool Leases').forEach(item => {
          if (item.propertySpaceArea !== null) {
            total += parseFloat(item.propertySpaceArea)
          }
        })
        cycleInfo.totalSpaceSqFt = total
        return cycleInfo
      }
      return {}
    },
    getData () {
      return this.currentData('Expense Pool Leases')
    },
    headers () {
      return this.currentHeaders('Expense Pool Leases')
    },
    currentPoolData () {
      return this.currentData('Recovery Cycles')
    }
  },
  beforeDestroy () {
    this.initializeStoreItem('Expense Pool Leases')
    this.initializeStoreItem('Recovery Cycles')
  }
}
</script>

<style lang="scss" scoped>
  .back-button {
    &__icon {
      width:20px;
      height:20px;
    }
  }
</style>
