const state = {
  isLoading: false
}

const getters = {
  currentLoading: (state) => state.isLoading
}

const actions = {
  changeLoading ({ commit }, newLoading) {
    commit('setLoading', newLoading)
  }
}

const mutations = {
  setLoading: (state, newLoading) => (state.isLoading = newLoading)
}

export default {
  state,
  getters,
  actions,
  mutations
}
