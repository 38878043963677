<template>
  <v-container class="resGray lighten-6">
    <v-layout align-center justify-space-between column fill-height>
    <v-card flat width="1000" height="130">
      <v-row>
        <v-col cols="12" class="mt-2">
          <div class="mx-6">
            <span class="title">Deposit Details</span>
          </div>
        </v-col>
      </v-row>
      <v-app-bar flat class="mt-2 resWhite">
        <date-picker
          class="ml-2 mr-3"
          :label="'Deposit Date'"
          :initialDate="editedItem.depositDate"
          @dateSelected="setDepositDate">
        </date-picker>
        <drop-down
          :selectedValue="{ text: editedItem.propertyName }"
          :items="'Properties'"
          :label="'Property'"
          class="mb-5 mr-3"
          :multiple='false'
          width="200px"
          @itemSelected="selectProperty">
        </drop-down>
        <drop-down
          :selectedValue="{ text: editedItem.bankAccountName }"
          :customList="bankAccounts"
          :label="'Cash Account'"
          class="mb-5 mr-3"
          :disabled="!propertySelected"
          :multiple='false'
          :getParams="{ Company: editedItem.property }"
          width="200px"
          @itemSelected="selectBankAccount">
        </drop-down>
        <text-input
          :value="editedItem.reference"
          label='Description'
          width="250px"
          class="mr-3"
          :placeholder="'Enter description'"
          @textInputUpdated="updateDescription">
        </text-input>
      </v-app-bar>
    </v-card>
    <div>
      <v-row>
        <v-col cols="12" class="mt-2">
          <div class="mx-6">
            <span class="title">Deposit check(s)</span>
          </div>
        </v-col>
      </v-row>
      <v-data-table
        :hide-default-header="true"
        hide-default-footer
        class="distribution mt-2"
        :headers="headers"
        :items="totalDespositChecks">
        <template v-slot:header="{ props }">
          <thead>
            <tr>
              <th v-for="header in props.headers" :key="header.value"
                role="columnheader"
                scope="col"
                :style="`width: ${header.width}px`"
                :class="['column', `text-${header.align}`]">
                <span class="header__text">
                  {{ header.title }}
                </span>
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:item="props">
          <tr>
            <td>
              <drop-down
                ref='leaseDropdown'
                :selectedValue="{ text: props.item.leaseName }"
                :customList="leases"
                :disabled="!propertySelected"
                :multiple='false'
                :hideDetails="true"
                checkBoxText="Include Expired"
                :showCheckBox='true'
                @checkBoxChanged='includeExpired'
                width="200px"
                @itemSelected="selectItemLease(props.item, $event)">
              </drop-down>
            </td>
            <td>
              <text-input
                :value="props.item.checkNumber"
                width="80px"
                rules="max:20"
                :hideDetails='true'
                @textInputUpdated="updateItemCheckNumber(props.item, $event)">
              </text-input>
            </td>
            <td>
              <date-picker
                :initialDate="props.item.checkDate"
                :hideDetails="true"
                @dateSelected="selectItemCheckDate(props.item, $event)">
              </date-picker>
            </td>
            <td>
              <text-input
                :value="props.item.checkAmount"
                width="100px"
                :prefix="'$'"
                textFormat="currency"
                rules="decimal:2"
                :hideDetails='true'
                @textInputUpdated="updateItemCheckAmount(props.item, $event)">
              </text-input>
            </td>
            <td>
              <v-icon
                v-if="props.item.Id !== 0"
                class="pr-3"
                small
                color='var(--v-resRed-base)'
                @click="removeCheck(props.item)">
                close
              </v-icon>
              <v-icon
                class="pr-3"
                small
                color='var(--v-resBlue-darken1)'
                @click="addNewCheck">
                add_circle
              </v-icon>
            </td>
          </tr>
        </template>
        <template v-slot:[`body.append`]>
          <tr>
            <td :colspan="headers.length">
              <v-layout>
                <v-spacer></v-spacer>
                <v-flex class="text-right font-weight-bold" xs1>
                  <label>Total:</label>
                </v-flex>
                <v-flex class="text-right font-weight-bold" xs1>
                  <label> {{ totalAmount }}</label>
                </v-flex>
                <span class="spacer__total"></span>
              </v-layout>
              <v-layout class="distribution-footer">
                <v-spacer></v-spacer>
                <label class="">Rows  1 - {{ totalDespositChecks.length }} ({{ totalDespositChecks.length }} Total)</label>
              </v-layout>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-layout>
        <v-spacer></v-spacer>
        <round-button
          height='30px'
          width='108px'
          :buttonText="'Clear Fields'"
          :buttonClass="'mt-4'"
          :borderColor="'var(--v-resBlue-darken1)'"
          buttonTextColor='var(--v-resBlue-darken1)'
          :backgroundColor="'var(--v-resWhite-base)'"
          :depressed="true"
          :flat="true"
          :outline="true"
          @click="resetWorkFlow">
        </round-button>
        <round-button
          height='30px'
          buttonClass='mt-4 ml-4 mr-0'
          width='80px'
          :depressed='true'
          :flat='true'
          :disabled='positiveChecks'
          :buttonTextColor="'var(--v-resWhite-base)'"
          :backgroundColor="'var(--v-resBlue-darken1)'"
          :buttonText="'Start'"
          @click="save">
        </round-button>
      </v-layout>
    </div>
    <div>
      <v-row>
        <v-col cols="12" class="mt-2">
          <div class="mx-6">
            <span class="title">Saved drafts</span>
          </div>
        </v-col>
      </v-row>
      <deposit-drafts class="mt-2"
        @EditDeposit="editExisting">
      </deposit-drafts>
    </div>
    </v-layout>
  </v-container>
</template>

<script>
import RoundButton from '../../../BaseComponents/RoundButton'
import DropDown from '../../../BaseComponents/DropDown'
import TextInput from '../../../BaseComponents/TextInput'
import DatePicker from '../../../BaseComponents/DatePicker'
import DepositDrafts from './DepositDrafts'
import * as currency from '../../../../utility/currency'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    RoundButton,
    DropDown,
    TextInput,
    DatePicker,
    DepositDrafts
  },
  data () {
    return {
      width: '190px',
      propertySelected: false,
      totalDespositChecks: [
        {
          lease: undefined,
          checkNumber: '',
          checkDate: undefined,
          checkAmount: '',
          action: '',
          Id: 0
        }
      ],
      editedItem: {
        reference: ''
      }
    }
  },
  methods: {
    ...mapActions(['fetchDropdown', 'changeData', 'fetchData', 'changeData']),
    resetWorkFlow () {
      this.propertySelected = false
      this.totalDespositChecks = [
        {
          lease: undefined,
          leaseName: undefined,
          checkNumber: undefined,
          checkDate: undefined,
          checkAmount: '',
          action: '',
          Id: 0
        }
      ]
      this.editedItem = {
        reference: ''
      }
    },
    setDepositDate (val) {
      this.editedItem.depositDate = val
    },
    setDefaultReference () {
      this.editedItem.reference = 'Deposit ' + this.editedItem.propertyName + ' ' + this.editedItem.bankAccount.trim()
    },
    updateDescription (val) {
      this.editedItem.reference = val
    },
    selectBankAccount (val) {
      if (val !== null) {
        this.editedItem.bankAccount = val.value
        this.editedItem.bankAccountName = val.text
        this.setDefaultReference()
      } else {
        this.editedItem.bankAccount = null
        this.editedItem.bankAccountName = null
      }
    },
    selectProperty (val) {
      if (val !== null) {
        this.editedItem.property = val.value
        this.editedItem.propertyName = val.text
        this.propertySelected = true
        this.$refs.leaseDropdown.resetCheckBox()
        this.getLeases()
        this.getBankAccounts()
      } else {
        this.editedItem.property = null
        this.editedItem.propertyName = null
      }
    },
    updateDepositAmount (val) {
      this.editedItem.depositAmount = val
    },
    updateItemCheckAmount (item, val) {
      item.checkAmount = currency.parseCurrency(val)
    },
    updateItemCheckNumber (item, val) {
      item.checkNumber = val
    },
    selectItemCheckDate (item, val) {
      item.checkDate = val
    },
    selectItemLease (item, val) {
      item.lease = val.value
      item.leaseName = val.text
    },
    save () {
      this.totalDespositChecks.reverse()
      this.changeData({ currentPage: 'BankDepositSlips', newData: { Property: this.editedItem.property, BankAccountCode: this.editedItem.bankAccount, DepositDate: this.editedItem.depositDate, Reference: this.editedItem.reference, DepositItems: this.totalDespositChecks }, updateItem: this.editedItem })
        .then(() => {
          let depositDetails = this.currentData('BankDepositSlips')[0]
          depositDetails.propertyName = this.editedItem.propertyName
          depositDetails.bankAccountName = this.editedItem.bankAccountName
          this.$emit('DepositSlipComplete', depositDetails)
        }).catch(error => { console.log(error.response) })
    },
    editExisting (val) {
      this.$emit('DepositSlipComplete', val)
    },
    addNewCheck () {
      let newCheck = {
        lease: undefined,
        leaseName: undefined,
        checkNumber: '',
        checkDate: undefined,
        checkAmount: '',
        action: ''
      }
      newCheck.Id = this.totalDespositChecks.length
      this.totalDespositChecks.unshift(newCheck)
    },
    removeCheck (val) {
      var index = this.totalDespositChecks.indexOf(val)
      this.totalDespositChecks.splice(index, 1)
    },
    filter (item, queryText) {
      const hasValue = val => val != null ? val : ''

      const text = hasValue(item.name)
      const query = hasValue(queryText)

      return text.toString()
        .toLowerCase()
        .indexOf(query.toString().toLowerCase()) > -1
    },
    getLeases () {
      this.fetchDropdown({ newDropdown: 'Leases', params: { LeaseStatus: 0, PropertyId: this.editedItem.property } }).catch(error => { console.log(error.response) })
    },
    getBankAccounts () {
      this.fetchDropdown({ newDropdown: 'Bank Account', params: { Company: this.editedItem.property } }).catch(error => { console.log(error.response) })
    },
    includeExpired (val) {
      let type = 0
      if (val) {
        type = null
      }
      this.fetchDropdown({ newDropdown: 'Leases', params: { LeaseStatus: type, PropertyId: this.editedItem.property } }).catch(error => { console.log(error.response) })
    }
  },
  computed: {
    ...mapGetters(['currentDropdown', 'currentData']),
    headers () {
      return [
        { title: 'Lease', value: 'leaseName', align: 'left', width: '400' },
        { title: 'Check number', value: 'checkNumber', align: 'left', width: '80' },
        { title: 'Check date', value: 'checkDate', align: 'left', width: '110' },
        { title: 'Check amount', value: 'checkAmount', align: 'left', width: '100' },
        { title: 'Action', value: 'action', align: 'center' }
      ]
    },
    positiveChecks () {
      let result = false
      this.totalDespositChecks.forEach(item => {
        if (parseFloat(item.checkAmount.toString().replace(/,/g, '')) < 0) {
          result = true
        }
      })
      return result
    },
    dropdownProps () {
      return {
        '--width': this.width
      }
    },
    leases () {
      return this.currentDropdown('Leases')
    },
    bankAccounts () {
      return this.currentDropdown('Bank Account')
    },
    totalAmount () {
      let sum = Number(0)
      this.totalDespositChecks.forEach(function (item) {
        let tempAmount = currency.parseCurrency(item.checkAmount)
        if (!isNaN(tempAmount)) {
          sum += tempAmount
        }
      })
      return currency.convertToCurrency(sum)
    }
  }
}
</script>

<style lang="scss" scoped>
.spacer__total {
  width: 116px;
}

.distribution {
  width: 1000px;
  thead {
    tr {
      height: 35px;
      background-color: var(--v-resGray-lighten3);
      font-size: 12px;
      th {
        padding: 0 14px;
        color: var(--v-resGray-darken5);
      }
    }
  }
  tbody {
    tr {
      height: 35px;
      td {
        padding: 0 14px;
      }
    }
  }
}

.dot {
  height: 10px;
  width: 10px;
  background-color: var(--dotColor);
  border-radius: 50%;
  margin-left: 2px;
  margin-right: 2px;
  display: inline-block;
}

.v-overflow-btn .v-input__append-inner {
  padding-left: 10px;
  height: 30px!important;
  width: 30px!important;
  margin-top: 0px!important;
}

::v-deep table.v-table tfoot {
  height: 78px;
  tr {
    height: 78px;
    td {
      height: 78px;
      label {
        font-size: 12px;
      }
    }
  }
}
.distribution-footer {
  ::v-deep label {
    font-size: 12px;
  }
}
</style>
