<template>
  <v-hover>
    <v-card class="rounded-card" height="230" width="280" slot-scope="{ hover }" hover>
      <v-card height="180" flat>
        <v-card-title primary-title height="180" class="justify-center pt-6 pl-6">
          <v-row align-center justify-left ma-0 pa-0>
            <v-icon>
              {{ data.icon }}
            </v-icon>
            <span class="font-weight-medium rounded-card__title ml-1">{{ data.title }}</span>
          </v-row>
          <v-row ma-0 pa-0>
            <v-col class="rounded-card__description">
              <span class="caption">{{ data.description }}</span>
            </v-col>
          </v-row>
          <v-row ma-0 pa-0>
            <ul>
              <li v-for="item in data.items" v-bind:key="item" class="text-sm-left">
                <span class="font-weight-light ml-2 caption">{{ item }}</span>
              </li>
            </ul>
          </v-row>
        </v-card-title>
      </v-card>
      <v-card flat>
        <v-card-actions class="justify-center">
          <round-button
            height='30px'
            width='100px'
            :buttonText="cardAction"
            :backgroundColor="hover ? 'var(--v-resBlue-darken1)': 'var(--v-resWhite-base)'"
            :borderColor="hover ? '': 'var(--v-resBlue-base)' "
            :buttonTextColor="hover ? '' : 'var(--v-resBlue-darken1)'"
            :buttonClass="'mt-0' "
            :dark='true'
            :outline="hover ? false: true"
            :depressed='true'
            @click="$router.push({ name: data.route })">
          </round-button>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-hover>
</template>

<script>
import RoundButton from './RoundButton'

export default {
  props: ['data'],
  data () {
    return {
      cardAction: 'Start'
    }
  },
  components: {
    RoundButton
  }
}
</script>

<style lang="scss" scoped>
  .rounded-card {
    border-radius: 6px;
    cursor: pointer;
    &__title {
      font-size: 18.2px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
    }
    li {
      height: 15px;
    }
    ul {
      font-size: 7px;
    }
    &__description {
      line-height: 0.8;
    }
    .caption {
      word-break: normal !important;
    }
  }
</style>
