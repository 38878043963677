<template>
  <overview-card
    :title="'Space Availability'"
    :noEdit="true"
    class="pb-4 pr-2">
    <v-row>
      <v-col offset="1">
        <drop-down
          :selectedValue="{ text: selectedPropertyName }"
          :items="'Properties'"
          :label="'Property'"
          :multiple='false'
          width="200px"
          hideDetails
          rules="required"
          :getParams="{ IncludeIncomplete: false, IncompleteOnly: false }"
          @itemSelected="selectProperty">
        </drop-down>
      </v-col>
    </v-row>
    <v-row class="mt-6">
      <v-col offset="1">
        <drop-down
          class="mr-3"
          :selectedValue="{ text: leaseTermName }"
          :customList="leaseTermList"
          :label="'Lease Term'"
          width="130px"
          hideDetails
          :genericLabel="true"
          @itemSelected="selectLeaseTerm">
        </drop-down>
        <date-picker
          class="mr-3"
          :label="'Start Date'"
          hideDetails
          :initialDate="startDate"
          @dateSelected="setStartDate">
        </date-picker>
        <date-picker
          class="mr-3"
          :label="'Expire Date'"
          hideDetails
          :initialDate="endDate"
          @dateSelected="setExpireDate">
        </date-picker>
        <text-input
          :value="sqft"
          label='Sqft greater than'
          width="108px"
          hide-details
          :mask="'##############'"
          @textInputUpdated="setSqft">
        </text-input>
      </v-col>
      <v-col align="right">
        <div v-for="legend in legendData" :key="legend.value" class="legend-container">
          <div class="ml-4 mr-1 legend-item" :style="`background-color: var(--v-${legend.color})`"></div>
          <span class="caption">
            {{ legend.value }}
          </span>
        </div>
       </v-col>
    </v-row>
    <v-row class="mt-0 pt-0" v-for="row in chartData" :key="row.area">
        <v-col cols="1" class="text-right mt-3">
            <span>{{ row.area }}</span>
        </v-col>
        <v-col cols="11">
          <stacking-block-card v-for="item in row.blocks"
            :key="item.space"
            :area="item.width"
            :width="100*item.width/row.totalWidth"
            :color="`${item.statusCode.color}`"
            :expireDate="item.leaseExpiration"
            :leaseName="item.leaseName"
            :leaseId="item.leaseId"
            :space="item.space"
            :showSqft='true'
            :textColor='item.statusCode.textColor'
            :status='item.statusCode.value'>
          </stacking-block-card>
        </v-col>
      </v-row>
  </overview-card>
</template>

<script>
import DropDown from '../BaseComponents/DropDown'
import DatePicker from '../BaseComponents/DatePicker'
import StackingBlockCard from '../BaseComponents/StackingBlockCard'
import OverviewCard from '../BaseComponents/OverviewCard'
import TextInput from '../BaseComponents/TextInput'
import moment from 'moment'
import * as validation from '../../utility/validation'
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {
    DropDown,
    DatePicker,
    StackingBlockCard,
    OverviewCard,
    TextInput
  },
  data () {
    return {
      selectedPropertyName: undefined,
      selectedProperty: undefined,
      startDate: moment().format('L'),
      endDate: moment().format('L'),
      leaseTerm: undefined,
      leaseTermName: undefined,
      sqft: 0
    }
  },
  methods: {
    ...mapActions(['fetchData']),
    setExpireDate (val) {
      this.endDate = val
      this.getChartData()
    },
    setStartDate (val) {
      this.startDate = val
      if (this.leaseTerm) {
        this.setExpireDateLeaseTerm()
      }
      this.getChartData()
    },
    selectLeaseTerm (val) {
      this.leaseTerm = val.value
      this.leaseTermName = val.text
      if (this.startDate) {
        this.setExpireDateLeaseTerm()
      }
      this.getChartData()
    },
    selectProperty (val) {
      if (val !== null) {
        this.selectedProperty = val.value
        this.selectedPropertyName = val.text
        this.getChartData()
      } else {
        this.selectedProperty = null
        this.selectedPropertyName = null
      }
    },
    setSqft (val) {
      if (validation.hasValue(val)) {
        this.sqft = val
      } else {
        this.sqft = 0
      }
      if (!isNaN(this.sqft)) {
        this.getChartData()
      }
    },
    getChartData () {
      this.fetchData({
        newPage: 'SpaceAvailability',
        params: {
          property: this.selectedProperty,
          startDate: this.startDate,
          endDate: this.endDate,
          sqft: this.sqft
        }
      }).catch(error => { console.log(error.response) })
    },
    setExpireDateLeaseTerm () {
      this.endDate = moment(this.startDate).add(this.leaseTerm, 'y').subtract(1, 'd').format('L')
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentSelectedItem']),
    chartData () {
      return this.currentData('SpaceAvailability')
    },
    legendData () {
      return this.chartData.map(x => {
        return x.blocks
      }).flat().map(x => {
        return x.statusCode
      }).reduce((unique, item) => {
        let findInArray = unique.find(x => {
          return x.value === item.value
        })

        if (!findInArray) {
          unique.push(item)
        }

        return unique
      }, [])
    },
    leaseTermList () {
      const list = []
      for (var i = 1; i < 21; i++) {
        list.push({ text: i + ' year(s)', value: i.toString() })
      }
      return list
    }
  },
  created () {
    this.selectedProperty = this.currentSelectedItem.property
    this.selectedPropertyName = this.currentSelectedItem.propertyName
    this.getChartData()
  }
}
</script>

<style lang="scss" scoped>
.legend-container {
  display: inline-flex;
  vertical-align: bottom;
  padding-top: 23px;
  padding-right: 12px;
}
.legend-item {
  display: inline-block;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  margin-top: 3px !important;
}
.caption {
  margin-top: 2px;
}
.subheading {
  vertical-align: top;
}
</style>
