<template>
  <v-container fluid class="resGray lighten-6">
    <v-app-bar flat :height="headerHeight">
      <slot name="header">
      </slot>
      <v-spacer></v-spacer>
      <round-button
        v-if="canImport"
        color='var(--v-resGray-darken3)'
        height='30px'
        width='100px'
        :class="hasHeaderSlot ? 'mt-9' : ''"
        :depressed='true'
        :disabled='this.addingNew || this.editing'
        buttonText='+ New'
        backgroundColor='var(--v-resGreen-lighten1)'
        borderColor='var(--v-resGray-lighten1)'
        buttonClass='mr-2'
        @click="newItem">
      </round-button>
      <round-button
        color='var(--v-resBlue-darken1)'
        height='30px'
        width='190px'
        :class="hasHeaderSlot ? 'mt-9' : ''"
        :depressed='true'
        :outline='true'
        :icon='true'
        :iconText="'get_app'"
        :disabled='!canExport'
        buttonText='Download Template'
        buttonTextColor='var(--v-resBlue-darken1)'
        :backgroundColor="'white'"
        :borderColor="'var(--v-resBlue-darken1)'"
        buttonClass='mr-3'
        @click="downloadTemplate">
      </round-button>
      <round-button
        color='var(--v-resGray-darken3)'
        height='30px'
        width='100px'
        :class="hasHeaderSlot ? 'mt-9' : ''"
        :depressed='true'
        :disabled='!canExport'
        buttonText='Source File'
        :backgroundColor="'var(--v-resBlue-darken1)'"
        borderColor='var(--v-resGray-lighten1)'
        buttonClass='mr-2'
        @click="openImportDialog">
      </round-button>
    </v-app-bar>
    <v-row>
      <v-col cols="12" class="mt-3">
        <slot name="body">
        </slot>
      </v-col>
    </v-row>
    <v-app-bar flat height="36" class="mt-2">
      <v-spacer></v-spacer>
      <round-button
        color='var(--v-resRed-base)'
        height='30px'
        width='80px'
        v-if="canImport"
        :depressed='true'
        :outline='true'
        buttonTextColor='var(--v-resRed-base)'
        buttonText='Cancel'
        :backgroundColor="'var(--v-resWhite-base)'"
        :borderColor="'var(--v-resRed-base)'"
        buttonClass='mr-3'
        @click="clearImportData">
      </round-button>
      <round-button
        color='var(--v-resBlue-darken1)'
        height='30px'
        width='130px'
        :depressed='true'
        :disabled='!canImport'
        buttonText='Submit Import'
        :backgroundColor="'var(--v-resGreen-base)'"
        :borderColor="'var(--v-resBlue-darken1)'"
        buttonClass='mr-2'
        @click="openConfirmDialog">
      </round-button>
    </v-app-bar>
    <v-dialog
      v-model="importDialog"
      width="375">
      <drag-and-drop
        @cancelUpload="cancel"
        @complete="saveUpload">
      </drag-and-drop>
    </v-dialog>
    <!-- Dialogs -->
    <base-dialog
      :showDialog="confirmDialog"
      :title="'Import Confirm'"
      :iconColor="'white'"
      :titleClass="'dark-header'"
      :maxWidth="350"
      saveButtonText="Confirm Import"
      saveButtonWidth="130px"
      @cancel="cancelImport"
      @save="submit">
      <v-card-text>
        <p class="caption">Please confirm that you have verified all data is correct before submitting the import.
        </p>
      </v-card-text>
    </base-dialog>
    <delete-dialog
      :showDialog="deleteDialog"
      @confirmDelete="confirmDeleteDialog"
      @cancelDelete="cancelDeleteDialog">
    </delete-dialog>
  </v-container>
</template>

<script>
import RoundButton from '../../../BaseComponents/RoundButton'
import BaseDialog from '../../../BaseComponents/BaseDialog'
import DragAndDrop from '../../../BaseComponents/SingleDragAndDrop'
import DeleteDialog from '../../../BaseComponents/DeleteDialog'
import { mapGetters, mapActions } from 'vuex'
export default {
  props: ['canImport', 'canExport', 'currentPage'],
  components: {
    RoundButton,
    BaseDialog,
    DragAndDrop,
    DeleteDialog
  },
  data () {
    return {
      files: {},
      deleteDialog: false,
      importDialog: false,
      confirmDialog: false,
      editing: false,
      addingNew: false,
      previousItem: {},
      editedIndex: null,
      itemHolder: {},
      deleteIndex: null
    }
  },
  methods: {
    ...mapActions(['cancelNew']),
    unlockEdit (item, data) {
      if (!this.currentLoading) {
        // this handles the client side of locking and unlocking editing
        if (!this.editing && !this.addingNew) {
          this.editedIndex = data.indexOf(item)
          this.previousItem = Object.assign({}, item)
          item.readonly = false
          this.editing = true
          Object.assign(data[this.editedIndex], item)
        }
      }
    },
    cancelEdit (item, data) {
      // if we can cancel a new item we want to remove it from the datatable, otherwise we just want to set the value to its previous state before any editing
      if (item.new === true) {
        this.cancelNew({ currentPage: this.currentPage })
        this.addingNew = false
      } else {
        this.editedIndex = data.indexOf(item)
        Object.assign(data[this.editedIndex], this.previousItem)
        this.previousItem = {}
      }
      this.editing = false
      item.readonly = true
    },
    save (item) {
      // check to see if the item was new, if it was we need to remove the new property
      delete item.new
      item.readonly = true
      this.addingNew = false
      this.editing = false
      this.previousItem = {}
    },
    deleteItem (item, data) {
      if (!this.editing) {
        this.deleteIndex = data.indexOf(item)
        this.itemHolder = item
        this.deleteDialog = true
      }
    },
    newItem () {
      if (!this.addingNew) {
        this.$emit('addNew')
        this.addingNew = true
      }
    },
    downloadTemplate () {
      if (this.canExport) {
        this.$emit('downloadTemplate')
      }
    },
    saveUpload (item) {
      this.$emit('saveUpload', item)
    },
    openImportDialog () {
      if (this.canExport) {
        this.importDialog = true
      }
    },
    cancel () {
      this.importDialog = false
    },
    openConfirmDialog () {
      this.confirmDialog = true
    },
    submit () {
      if (this.canImport) {
        this.$emit('submit')
      }
    },
    cancelDeleteDialog () {
      this.deleteDialog = false
    },
    confirmDeleteDialog () {
      this.deleteDialog = false
      this.cancelNew({ currentPage: this.currentPage, index: this.deleteIndex })
    },
    cancelImport () {
      this.confirmDialog = false
    },
    clearImportData () {
      this.addingNew = false
      this.editing = false
      this.$emit('clearImportData')
    }
  },
  computed: {
    ...mapGetters(['currentLoading']),
    headerHeight () {
      return this.hasHeaderSlot ? null : '36'
    },
    hasHeaderSlot () {
      return !!this.$slots['header']
    }
  }
}
</script>
