<template>
  <v-container fluid class="resGray lighten-6">
    <v-row>
      <v-col cols="12" class="mt-3">
        <data-table
          class="mx-6"
          :headers="headers"
          :tableData="getData"
          :showActions="true"
          @unlockEditing='unlockEdit'
          @dataChanged="save"
          @canceled="cancel"
          :editOnly='true'>
        </data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DataTable from '../BaseComponents/Table'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    DataTable
  },
  data () {
    return {
      editing: false,
      previousValue: {}
    }
  },
  methods: {
    ...mapActions(['changeLoading', 'fetchData', 'changeData']),
    unlockEdit (item) {
      if (!this.currentLoading) {
        // this handles the client side of locking and unlocking editing
        if (!this.editing) {
          this.editedIndex = this.getData.indexOf(item)
          this.previousValue = Object.assign({}, item)
          item.readonly = false
          this.editing = true
          Object.assign(this.getData[this.editedIndex], item)
        }
      }
    },
    cancel (item) {
      // if we can cancel a new item we want to remove it from the datatable, otherwise we just want to set the value to its previous state before any editing
      this.editedIndex = this.getData.indexOf(item)
      Object.assign(this.getData[this.editedIndex], this.previousValue)
      this.previousValue = {}
      this.editing = false
      this.isSorted = true
    },
    save (item) {
      // check to see if the item was new, if it was we need to remove the new property
      item.readonly = true
      this.changeData({ currentPage: 'LeaseCustomFields', newData: item }).then(() => {
        this.fetchData({newPage: 'LeaseCustomFields', params: { LeaseId: this.currentSelectedItem.lease }}).then(() => {
          this.editing = false
        })
      }).catch(error => { console.log(error.response) })
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentSelectedItem', 'currentLoading']),
    getData () {
      return this.currentData('LeaseCustomFields')
    },
    headers () {
      return this.currentHeaders('LeaseCustomFields') || []
    }
  },
  created () {
    this.fetchData({newPage: 'LeaseCustomFields', params: { LeaseId: this.currentSelectedItem.lease }}).catch(error => { console.log(error.response) })
  }
}
</script>
