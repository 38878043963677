const state = {
  selectedItem: localStorage.getItem('selectedItem') ? JSON.parse(localStorage.getItem('selectedItem')) : {}
}

const getters = {
  currentSelectedItem: (state) => state.selectedItem
}

const actions = {
  changeSelectedItem ({ commit }, item) {
    commit('setSelectedItem', item)
  }
}

const mutations = {
  setSelectedItem: (state, item) => {
    state.selectedItem = item
    if (item) {
      const parsed = JSON.stringify(item)
      localStorage.setItem('selectedItem', parsed)
    } else {
      localStorage.removeItem('selectedItem')
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
