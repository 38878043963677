<template>
  <v-container fluid class="resGray lighten-6">
    <v-app-bar flat>
      <date-picker
        class="ml-2 mr-3"
        :label="'As of date'"
        :defaultNull="true"
        :initialDate="asOfDate"
        @dateSelected="updateSelectedDate">
      </date-picker>
      <drop-down
        label="Property Name"
        class="mb-5"
        :selectedValue="{ text: propertyName }"
        :items="'Properties'"
        :multiple='false'
        width="250px"
        @itemSelected="selectProperty">
      </drop-down>
      <v-spacer></v-spacer>
      <search
        @searchStringUpdated="updateSearchString"
        placeholder='Enter check # or lease name'
        :width="'250px'"
        class="mt-4 mr-2">
      </search>
    </v-app-bar>
    <v-row>
      <v-col cols="12">
        <data-table
          :headers="headers"
          :tableData="getData"
          @UnapplyPrepayment="openUnapplyDialog"
          @ShowCheckDetails="openDepositDetails"
          :searchString="searchString"
          :noData="'Please use search criteria above for results.'"
          class="mx-6">
        </data-table>
      </v-col>
    </v-row>
    <!-- Dialogs -->
    <delete-dialog
      :showDialog="unapplyConfirm"
      v-if="unapplyConfirm"
      :title="'Are you sure?'"
      confirmButton="Yes, unapply prepayments"
      confirmButtonWidth="108px"
      @confirmDelete="confirmDialog"
      message="Please confirm that you would like to unapply payments to associated charges. Doing so will re-open all associated charges."
      @cancelDelete="cancelDialog">
      <v-flex>
        <v-layout mt-2 ml-6>
          <v-flex>
            <div class="caption resGray-darken1--text mb-1">Check date</div>
            <div class="caption">{{ currentPrepaidItemsToUnapply.payerCheckDate }}</div>
          </v-flex>
          <v-flex>
            <div class="caption resGray-darken1--text mb-1">Check number</div>
            <div class="caption">{{ currentPrepaidItemsToUnapply.payerCheckNumber }}</div>
          </v-flex>
        </v-layout>
      </v-flex>
    </delete-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DropDown from '../../../BaseComponents/DropDown'
import DatePicker from '../../../BaseComponents/DatePicker'
import Search from '../../../BaseComponents/Search'
import DataTable from '../../../BaseComponents/Table'
import DeleteDialog from '../../../BaseComponents/DeleteDialog'
import moment from 'moment'
export default {
  props: ['selectedProperty', 'selectedDate'],
  components: {
    Search,
    DropDown,
    DatePicker,
    DataTable,
    DeleteDialog
  },
  data () {
    return {
      property: this.selectedProperty.value,
      propertyName: this.selectedProperty.text,
      asOfDate: this.selectedDate,
      unapplyConfirm: false,
      currentPrepaidItemsToUnapply: {},
      searchString: ''
    }
  },
  methods: {
    ...mapActions(['fetchData', 'changeData', 'initializeStoreItem']),
    updateSelectedDate (val) {
      this.asOfDate = val
      this.getChecks()
    },
    selectProperty (val) {
      this.propertyName = val.text
      this.property = val.value
      this.getChecks()
    },
    getChecks () {
      if ((this.asOfDate !== null && this.asOfDate !== undefined) && ((this.property !== null && this.property !== undefined))) {
        this.fetchData({newPage: 'UnapplyPrepayments', params: { Property: this.property, AsOfDate: this.asOfDate }}).catch(error => { console.log(error.response) })
      }
    },
    updateSearchString (val) {
      this.searchString = val
    },
    openDepositDetails (val) {
      let property = {
        value: this.property,
        text: this.propertyName
      }
      this.$emit('setProperty', property)
      this.$emit('setDate', this.asOfDate)
      this.$emit('openCheckDetails', val)
    },
    openUnapplyDialog (val) {
      val.payerCheckDate = moment(val.payerCheckDate).format('L')
      this.currentPrepaidItemsToUnapply = val
      this.unapplyConfirm = true
    },
    cancelDialog () {
      this.unapplyConfirm = false
    },
    confirmDialog () {
      this.changeData({ currentPage: 'UnapplyPrepayments',
        newData: {
          CompanyId: this.currentPrepaidItemsToUnapply.companyId,
          BankAccountCode: this.currentPrepaidItemsToUnapply.bankAccountCode,
          DepositSlipId: this.currentPrepaidItemsToUnapply.depositSlipId,
          BankDepositId: this.currentPrepaidItemsToUnapply.depositId,
          JournalItemId: this.currentPrepaidItemsToUnapply.journalItemId
        } }).then(() => {
        this.fetchData({newPage: 'UnapplyPrepayments', params: { Property: this.property, AsOfDate: this.asOfDate }})
        this.unapplyConfirm = false
      }).catch(error => { console.log(error.response) })
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders']),
    canSearch () {
      if ((this.asOfDate !== null && this.asOfDate !== undefined) && ((this.property !== null && this.property !== undefined))) {
        return true
      }
      return false
    },
    getData () {
      return this.currentData('UnapplyPrepayments')
    },
    headers () {
      return this.currentHeaders('UnapplyPrepayments')
    }
  },
  created () {
    this.fetchData({ newPage: 'UnapplyPrepayments' }).catch(error => { console.log(error.response) })
  }
}
</script>
