const state = {
  steps: [
    'Lease', 'Spaces', 'Charges', 'Contacts', 'Addresses', 'Review'
  ],
  step: 'Lease',
  review: false,
  spaceIndex: 0,
  propertyChanged: false,
  previousCharge: false,
  nextCharge: false,
  Lease: {
    startDate: null,
    endDate: null
  },
  Spaces: {
    selectedSpaces: []
  },
  Charges: [],
  Contacts: {},
  Addresses: {}
}

const getters = {
  currentLease: (state) => state.Lease,
  currentSpaces: (state) => state.Spaces,
  currentCharges: (state) => state.Charges,
  currentContacts: (state) => state.Contacts,
  currentAddresses: (state) => state.Addresses,
  currentStep: (state) => state.step,
  currentReview: (state) => state.review,
  currentPropertyChanged: (state) => state.propertyChanged,
  currentPreviousCharge: (state) => state.previousCharge,
  currentNextCharge: (state) => state.nextCharge,
  currentSpaceIndex: (state) => state.spaceIndex
}

const actions = {
  changeLease ({ commit }, newLease) {
    commit('setLease', newLease)
  },
  changeSpaces ({ commit }, newSpaces) {
    commit('setSpaces', newSpaces)
  },
  addCharges ({ commit }, newCharges) {
    commit('addCharges', newCharges)
  },
  clearCharge ({ commit }) {
    commit('clearCharges')
  },
  changeContacts ({ commit }, newContacts) {
    commit('setContacts', newContacts)
  },
  changeAddresses ({ commit }, newAddresses) {
    commit('setAddresses', newAddresses)
  },
  changeNextStep ({ commit }) {
    commit('setNextStep')
  },
  changePreviousStep ({ commit }) {
    commit('setPreviousStep')
  },
  changeStep ({ commit }, newStep) {
    commit('setStep', newStep)
  },
  changeNextSpace ({ commit }) {
    commit('setNextSpace')
  },
  changePreviousSpace ({ commit }) {
    commit('setPreviousSpace')
  },
  changeSpaceIndex ({ commit }) {
    commit('setSpaceIndex')
  },
  clearLeaseWorkflow ({ commit }) {
    commit('setClearedLeaseWorkflow')
  },
  changePropertyChanged ({ commit }, change) {
    commit('setPropertyChanged', change)
  },
  changeReview ({ commit }, newReview) {
    commit('setReview', newReview)
  }
}

const mutations = {
  setLease: (state, newLease) => (state.Lease = newLease),
  setSpaces: (state, newSpaces) => (state.Spaces = newSpaces),
  addCharges: (state, newCharges) => {
    var index = state.Charges.findIndex(x => x.space === newCharges.space)
    if (~index) {
      state.Charges.splice(index, 1)
    }
    newCharges.charges.forEach(function (item) {
      item.baseAmount = parseInt(item.baseAmount)
      item.readonly = true
    })
    state.Charges.push(newCharges)
  },
  clearCharges: (state) => {
    state.Charges = []
  },
  setContacts: (state, newContacts) => (state.Contacts = newContacts),
  setAddresses: (state, newAddresses) => (state.Addresses = newAddresses),
  setNextStep: (state) => {
    var currentIndex = state.steps.indexOf(state.step)
    if (currentIndex < 6) {
      state.step = state.steps[currentIndex + 1]
    }
    if (state.step === 'Charges') {
      if (state.Spaces.selectedSpaces.length > 1 && state.spaceIndex !== state.Spaces.selectedSpaces.length - 1) {
        state.nextCharge = true
      } else {
        state.nextCharge = false
      }
    } else {
      state.nextCharge = false
      state.previousCharge = false
    }
  },
  setPreviousStep: (state) => {
    var currentIndex = state.steps.indexOf(state.step)
    if (currentIndex > 0) {
      state.step = state.steps[currentIndex - 1]
    }
    if (state.step === 'Charges') {
      if (state.Spaces.selectedSpaces.length > 1 && state.spaceIndex !== state.Spaces.selectedSpaces.length - 1) {
        state.nextCharge = true
      } else {
        state.nextCharge = false
      }
    } else {
      state.nextCharge = false
      state.previousCharge = false
    }
  },
  setStep: (state, newStep) => {
    state.step = newStep
    if (state.step === 'Charges') {
      if (state.Spaces.selectedSpaces.length > 1 && state.spaceIndex !== state.Spaces.selectedSpaces.length - 1) {
        state.nextCharge = true
      } else {
        state.nextCharge = false
      }
    } else {
      state.nextCharge = false
      state.previousCharge = false
    }
  },
  setNextSpace: (state) => {
    if (state.spaceIndex < state.Spaces.selectedSpaces.length) {
      state.spaceIndex++
      if (state.spaceIndex !== 0) {
        state.previousCharge = true
      } else {
        state.previousCharge = false
      }
    }
    if (state.Spaces.selectedSpaces.length > 1 && state.spaceIndex !== state.Spaces.selectedSpaces.length - 1) {
      state.nextCharge = true
    } else {
      state.nextCharge = false
    }
  },
  setPreviousSpace: (state) => {
    if (state.spaceIndex > 0) {
      state.spaceIndex--
      if (state.spaceIndex === 0) {
        state.previousCharge = false
      } else {
        state.previousCharge = true
      }
    }
    if (state.Spaces.selectedSpaces.length > 1 && state.spaceIndex !== state.Spaces.selectedSpaces.length - 1) {
      state.nextCharge = true
    } else {
      state.nextCharge = false
    }
  },
  setPropertyChanged: (state, change) => (state.propertyChanged = change),
  setSpaceIndex: (state) => {
    state.spaceIndex = 0
  },
  setClearedLeaseWorkflow: (state) => {
    state.steps = [
      'Lease', 'Spaces', 'Charges', 'Contacts', 'Addresses', 'Review'
    ]
    state.step = 'Lease'
    state.review = false
    state.spaceIndex = 0
    state.propertyChanged = false
    state.previousCharge = false
    state.Lease = {}
    state.Spaces = {
      selectedSpaces: []
    }
    state.Charges = []
    state.Contacts = {}
    state.Addresses = {}
  },
  setReview: (state, newReview) => (state.review = newReview)
}

export default {
  state,
  getters,
  actions,
  mutations
}
