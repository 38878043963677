<template>
  <v-container fluid class="resGray lighten-6">
    <v-app-bar flat>
      <drop-down
        class="ml-2"
        :label="'Select Charge Code'"
        :selectedValue="{ text: chargeCodeName }"
        :customList="chargeCodes"
        :multiple='false'
        width="180px"
        @itemSelected="selectChargeCode">
      </drop-down>
    </v-app-bar>
    <v-row>
      <v-col cols="12" class="mt-3">
        <data-table
          class="mx-6"
          :headers="headers"
          :tableData="getData"
          :noData="'Select Charge Code to begin.'">
        </data-table>
      </v-col>
    </v-row>
    <v-app-bar flat height="36">
      <v-spacer></v-spacer>
      <round-button
        color='var(--v-resGray-darken3)'
        height='30px'
        width='136px'
        :dark='true'
        :depressed='true'
        buttonText='Add New Charge'
        :backgroundColor="'var(--v-resBlue-darken1)'"
        borderColor='var(--v-resGray-lighten1)'
        buttonClass='mr-2'
        @click="save">
      </round-button>
    </v-app-bar>
    <!-- Dialogs -->
    <base-dialog
      :showDialog="allLeaseCount"
      v-if="allLeaseCount"
      :title="'Charge Codes cannot be edited'"
      :maxWidth="500"
      saveButtonText="Close"
      saveButtonColor="var(--v-resBlue-darken1)"
      :saveButtonOnly="true"
      @cancel="saveAllLeaseCount"
      @save="saveAllLeaseCount">
      <p class="ma-1">All charge codes have already been added. If you need to remove one that can be done in the lease section.</p>
    </base-dialog>
    <base-dialog
      :showDialog="chargeCodeNotMapped"
      v-if="chargeCodeNotMapped"
      :title="'Charge Code not mapped'"
      :maxWidth="500"
      saveButtonText="Close"
      saveButtonColor="var(--v-resBlue-darken1)"
      :saveButtonOnly="true"
      @cancel="saveChargeCodeNotMapped"
      @save="saveChargeCodeNotMapped">
      <p class="ma-1">This charge code has not been mapped. Click <a @click="moveChargeCodeMapping">here</a> to go to the charge code mapping page.</p>
    </base-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DataTable from '../BaseComponents/Table'
import RoundButton from '../BaseComponents/RoundButton'
import DropDown from '../BaseComponents/DropDown'
import BaseDialog from '../BaseComponents/BaseDialog'

export default {
  components: {
    DataTable,
    RoundButton,
    DropDown,
    BaseDialog
  },
  data () {
    return {
      chargeCode: undefined,
      chargeCodeName: undefined,
      tableLock: true,
      allLeaseCount: false,
      chargeCodeNotMapped: false
    }
  },
  methods: {
    ...mapActions(['fetchData', 'changeData', 'fetchDropdown', 'changeData', 'initializeStoreItem', 'changeTab']),
    selectChargeCode (val) {
      this.chargeCodeName = val.text
      this.chargeCode = val.value
      this.initializeStoreItem('PropertyChargeCode')
      if (this.chargeCode !== null || this.chargeCode !== undefined) {
        if (this.checkMapped(this.chargeCode)) {
          this.getLeaseChargeItems()
        } else {
          this.chargeCodeNotMapped = true
        }
      }
    },
    getLeaseChargeItems () {
      this.fetchData({newPage: 'PropertyChargeCode', params: { Property: this.currentSelectedItem.property, ChargeCode: this.chargeCode }}).catch(error => { console.log(error.response) })
    },
    saveAllLeaseCount () {
      this.allLeaseCount = false
    },
    saveChargeCodeNotMapped () {
      this.chargeCodeNotMapped = false
    },
    checkMapped (chargeCode) {
      let selectedChargeCode = this.currentData('Charge Codes Property').find(x => x.chargeCode === chargeCode)
      if (selectedChargeCode !== null || selectedChargeCode !== undefined) {
        if (selectedChargeCode.isMapped) {
          return true
        }
      }
      return false
    },
    moveChargeCodeMapping () {
      this.chargeCodeNotMapped = false
      this.changeTab({ header: 'ChargeCodeMapping', index: 4, route: 'PropertiesChargeCodeMapping' })
      this.$router.push({ name: 'PropertiesChargeCodeMapping' })
    },
    save () {
      let canEditCheck = this.currentData('PropertyChargeCode').filter(x => x.leaseDetailCount > 0)
      if (canEditCheck.length === this.currentData('PropertyChargeCode').length) {
        this.allLeaseCount = true
      }
      if (!this.allLeaseCount) {
        let item = {}
        item.property = this.currentSelectedItem.property
        item.chargeCode = this.chargeCode
        item.ChargeCodeItems = this.getData
        this.changeData({ currentPage: 'PropertyChargeCode', newData: item }).then(() => {
          this.fetchData({newPage: 'PropertyChargeCode', params: { Property: this.currentSelectedItem.property, ChargeCode: this.chargeCode }})
        }).catch(error => {
          console.log(error.response)
        })
      }
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentSelectedItem', 'currentDropdown']),
    getData: {
      get () {
        let items = JSON.parse(JSON.stringify(this.currentData('PropertyChargeCode')))
        items.forEach(item => {
          if (item.isUsed) {
            item.readonly = true
          } else {
            item.readonly = false
          }
        })
        return items
      }
    },
    headers: {
      get () {
        return this.currentHeaders('PropertyChargeCode')
      }
    },
    chargeCodes: {
      get () {
        let chargeCodesOnly = this.currentData('Charge Codes Property').map(x => {
          let dropdown = {
            text: x.name,
            value: x.chargeCode
          }
          return dropdown
        })
        return chargeCodesOnly
      }
    }
  },
  created () {
    this.fetchData({newPage: 'Charge Codes Property', params: { Property: this.currentSelectedItem.property }}).catch(error => { console.log(error.response) })
    this.initializeStoreItem('PropertyChargeCode')
  }
}
</script>

<style lang="scss" scoped>
::v-deep .checkbox__container {
  height: 30px;
}
.space__table {
  ::v-deep .v-datatable {
  span {
    font-size: 12px;
    }
  }
}
</style>
