<template>
  <div>
    <base-tabs
      :tabs="tabs"
      @tabChanged="switchTab">
      <slot></slot>
    </base-tabs>
  </div>
</template>

<script>
import BaseTabs from '../BaseComponents/BaseTabs'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    BaseTabs
  },
  data () {
    return {
      tabs: [
        { header: 'Pools & Cycles', route: 'Pools Cycles' },
        { header: 'Estimates', route: 'Estimates' },
        { header: 'Reconciliations', route: 'Reconciliations' }
      ],
      selectedTab: null
    }
  },
  methods: {
    ...mapActions(['changeTab']),
    initialize () {
      if (!this.currentTab) {
        this.switchTab(this.tabs[0])
      } else {
        if (this.currentTab.originalIndex) {
          this.switchTab(this.tabs[this.currentTab.originalIndex])
        } else {
          this.switchTab(this.tabs[this.currentTab.index])
        }
      }
    },
    switchTab (e) {
      if (e && e.route) {
        this.$router.push({ name: e.route })
      }
      var tab = this.tabs[this.tabs.indexOf(e)]
      if (!tab.index) {
        tab.index = this.tabs.indexOf(e)
      }
      if (tab.index > 2) {
        tab.index = 2
      }
      this.changeTab(tab)
    }
  },
  computed: {
    ...mapGetters(['currentSection', 'currentTab'])
  },
  created () {
    this.initialize()
  }
}
</script>

<style lang="scss" scoped>
  .back-button {
    cursor: pointer;
    padding-left: 0px;
  }

  .back-button-text {
    color: var(--v-resBlue-base);
  }
</style>
