<template>
  <div>
    <property-tabs>
      <router-view></router-view>
    </property-tabs>
  </div>
</template>

<script>
import PropertyTabs from './PropertyTabs'

export default {
  data () {
    return {}
  },
  components: {
    PropertyTabs
  }
}
</script>
