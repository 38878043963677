<template>
  <div :class="receivablesChartWrapperClass" @click="setSelected(true)">
    <div class="chart-title">Tenant Receivables</div>
    <div v-if="currentLoading && needToRefresh" class="loading-container">
      <v-progress-circular
        color="var(--v-resBlue-darken1)"
        size="64"
        indeterminate
        class="loading">
      </v-progress-circular>
    </div>
    <div v-if="(!currentLoading || !needToRefresh) && receivablesChartData != null" class="chart-container">
      <g-chart
        type="PieChart"
        :data="receivablesChartData"
        :options="receivablesChartOptions">
      </g-chart>
    </div>
    <div v-if="!currentLoading && !needToRefresh && receivablesChartData == null" class="no-data-container">
      <div>
        <v-icon color="var(--v-resGray-lighten1)" size="80">error_outline</v-icon>
      </div>
      <div>No results found</div>
      <div class="message-detail">The date you are currently viewing has no tenant receivables data</div>
    </div>
  </div>
</template>

<script>
import { GChart } from 'vue-google-charts'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    GChart
  },
  props: ['asOfDate'],
  data () {
    return {
      needToRefresh: true,
      receivablesChartWrapperClass: 'chart-wrapper',
      receivablesChartData: null,
      receivablesChartOptions: null
    }
  },
  methods: {
    ...mapActions(['changeLoading', 'fetchData']),
    setSelected (selected) {
      this.receivablesChartWrapperClass = 'chart-wrapper'
      if (selected) {
        this.receivablesChartWrapperClass += ' selected'
        this.$emit('selectChart')
      }
    },
    refreshChartData () {
      this.fetchData({ newPage: 'DashboardReceivablesSummary', params: { AsOfDate: this.asOfDate } }).then(() => {
        // Tenant Receivables Data
        var receivablesSummary = this.getReceivablesSummaryData
        var maxOpenAmount = Math.max(receivablesSummary.openChargeAmountCurrent, receivablesSummary.openChargeAmountOver30Days, receivablesSummary.openChargeAmountOver60Days, receivablesSummary.openChargeAmountOver90Days)
        // Handle no data
        if (maxOpenAmount > 0) {
          this.receivablesChartData = [
            ['', ''],
            ['Current', receivablesSummary.openChargeAmountCurrent],
            ['Over 30 days', receivablesSummary.openChargeAmountOver30Days],
            ['Over 60 days', receivablesSummary.openChargeAmountOver60Days],
            ['Over 90 days', receivablesSummary.openChargeAmountOver90Days]
          ]
          this.receivablesChartOptions = {
            titlePosition: 'none',
            backgroundColor: 'transparent',
            fontSize: 12,
            legend: {
              position: 'right',
              alignment: 'center'
            },
            chartArea: {
              top: '8%',
              width: '100%',
              height: '70%'
            },
            colors: [
              this.$vuetify.theme.themes.light.resOrange.base,
              this.$vuetify.theme.themes.light.resOrange.lighten1,
              this.$vuetify.theme.themes.light.resBlue.lighten3,
              this.$vuetify.theme.themes.light.resGreen.base
            ],
            pieSliceBorderColor: 'transparent',
            tooltip: {
              text: 'value'
            },
            pieHole: 0.3
          }
        } else {
          this.receivablesChartData = null
          this.receivablesChartOptions = null
        }
        this.needToRefresh = false
      }).catch(error => { console.log(error.response) })
    }
  },
  computed: {
    ...mapGetters(['currentLoading', 'currentData']),
    getReceivablesSummaryData: {
      get () {
        return this.currentData('DashboardReceivablesSummary')
      }
    }
  },
  watch: {
    asOfDate: function () {
      this.needToRefresh = true
      this.refreshChartData()
    }
  },
  created () {
    this.changeLoading(true)
    setTimeout(() => {
      this.refreshChartData()
    }, 1000)
  }
}
</script>

<style lang="scss" scoped>
  .chart-wrapper {
    height: 240px;
    background-color: var(--v-resWhite-base);
    border: solid 1px var(--v-resGray-lighten1);
    .chart-title {
      padding: 10px 0px 0px 20px;
      font-size: 17px;
      color: var(--v-resGray-darken4);
    }
  }

  .chart-wrapper:hover {
    box-shadow: 2px 2px 6px 0 var(--v-resGray-darken5);
  }

  .chart-wrapper.selected {
    border: solid 2px var(--v-resBlue-base) !important;
    .chart-title {
      font-weight: 500;
      color: var(--v-resGray-darken5);
    }
  }

  .chart-wrapper.selected:hover {
    box-shadow: none;
  }

  .chart-container {
    width: 90%;
    display: block;
    margin: 0 auto;
  }

  .loading-container {
    margin-bottom: 10%;
  }

  .loading {
    display: block;
    margin: 10% auto;
  }

  .no-data-container {
    margin-top: 4%;
    font-size: 12;
    color: var(--v-resGray-darken4);
    text-align: center;
    .message-detail {
      font-weight: 300;
      padding: 0 20px;
    }
  }

  // These remove odd behaviors with the pie charts
  ::v-deep svg > g > g:last-child {
    pointer-events: none
  }

  ::v-deep path[stroke-width="6.5"] {
    stroke: transparent;
  }

  ::v-deep ellipse[stroke-width="6.5"] {
    stroke: transparent;
  }
</style>
