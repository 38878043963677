import Vue from 'vue'
import Vuex from 'vuex'
import sections from './Modules/sections'
import users from './Modules/users'
import permissions from './Modules/permissions'
import environment from './Modules/environment'
import dataAccessLayer from './Modules/dataAccessLayer'
import toast from './Modules/toast'
import loading from './Modules/loading'
import selectedItem from './Modules/selectedItem'
import selectedToggle from './Modules/selectedToggle'
import errorDialog from './Modules/errorDialog'
import leaseWorkflow from './Modules/leaseWorkflow'
import propertyWorkflow from './Modules/propertyWorkflow'
import poolsWorkflow from './Modules/poolsWorkflow'
import refunds from './Modules/refunds'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    sections,
    users,
    permissions,
    environment,
    dataAccessLayer,
    toast,
    loading,
    selectedItem,
    selectedToggle,
    errorDialog,
    leaseWorkflow,
    propertyWorkflow,
    poolsWorkflow,
    refunds
  }
})
