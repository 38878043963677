<template>
  <v-dialog :key="showDialog" v-model="showDialog" persistent :max-width="maxWidth || 450" :width="setWidth">
    <v-card>
      <v-card-title :class="['pl-6 py-0 font-weight-medium dialog-title-bar', titleClass || 'default-header']">
        <v-icon
          v-if="showTitleIcon"
          :large="!smallTitleIcon"
          :color="iconColor || 'var(--v-resRed-base)'">{{ iconCode }}
        </v-icon>
        <span class="base-dialog__title">
          {{ title }}
        </span>
        <v-spacer></v-spacer>
        <v-icon
          v-if="!hideClose"
          @click="cancel"
          :color="iconColor">
          close
        </v-icon>
      </v-card-title>
      <div class='px-2'>
        <slot></slot>
      </div>
      <v-card-actions class="pa-6">
        <round-button
          v-if="extraLeftButton"
          height='30px'
          :buttonText="extraLeftButtonText"
          :buttonClass="'ml-4'"
          :borderColor="cancelButtonColor || 'var(--v-resRed-base)'"
          :buttonTextColor="cancelButtonTextColor || 'var(--v-resRed-base)'"
          :depressed="true"
          :flat="true"
          :outline="true">
        </round-button>
        <v-spacer></v-spacer>
        <round-button
          v-if="!hideActions && !saveButtonOnly"
          height='30px'
          :width='`${cancelButtonWidth || 80}px`'
          :buttonText="cancelButtonText || 'Cancel'"
          :buttonTextColor="(cancelButtonTextColor || 'var(--v-resRed-base)')"
          :borderColor="cancelButtonColor || 'var(--v-resRed-base)'"
          :depressed="true"
          :flat="true"
          :outline="true"
          @click="cancel">
        </round-button>
        <round-button
          v-if="!hideActions && !cancelButtonOnly"
          height='30px'
          buttonClass='ml-4'
          :disabled="saveDisabled"
          :width='`${saveButtonWidth || 80}px`'
          :depressed='true'
          :flat='true'
          :backgroundColor="saveDisabled ? 'var(--v-resGray-lighten1)' : saveButtonColor || 'var(--v-resGreen-base)'"
          :buttonText="saveButtonText || 'Save'"
          @click="save">
        </round-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RoundButton from './RoundButton'

export default {
  components: {
    RoundButton
  },
  props: [
    'showDialog',
    'title',
    'titleClass',
    'saveButtonText',
    'saveButtonColor',
    'saveButtonWidth',
    'cancelButtonText',
    'cancelButtonColor',
    'cancelButtonTextColor',
    'cancelButtonWidth',
    'maxWidth',
    'hideActions',
    'saveButtonOnly',
    'cancelButtonOnly',
    'showTitleIcon',
    'iconTitle',
    'iconColor',
    'hideClose',
    'extraLeftButton',
    'extraLeftButtonText',
    'saveDisabled',
    'setWidth',
    'smallTitleIcon',
    'ignorePadding'
  ],
  data () {
    return {}
  },
  methods: {
    save () {
      if (!this.saveDisabled) this.$emit('save', true)
    },
    cancel () {
      this.$emit('cancel', true)
    }
  },
  computed: {
    iconCode () {
      if (this.iconTitle) return this.iconTitle
      return 'warning'
    }
  }
}
</script>

<style lang="scss" scoped>
  .dialog-title-bar {
    height: 50px;
  }
  .default-header {
    color: var(--v-resGray-darken4) !important;
  }
  .dark-header {
    background-color: var(--v-resBlue-darken1) !important;
    color: var(--v-resWhite-base) !important;
  }
  .alert-header {
    background-color: var(--v-resRed-base) !important;
    color: var(--v-resWhite-base) !important;
  }
  .base-dialog__title {
    font-size: 16px !important;
    padding-left: 6px;
  }
</style>
