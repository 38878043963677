<template>
  <v-container>
    <v-toolbar color="var(--v-resGray-lighten6)" flat height="36" class="pa-0 mt-1 mb-4">
    <drop-down
      label="Property Name"
      :selectedValue="{ text: propertyName }"
      :items="'Properties'"
      :multiple='false'
      width="200px"
      class="mr-4"
      @itemSelected="selectProperty">
     </drop-down>
     <drop-down
      label="Lease Name"
      ref='leaseDropdown'
      :selectedValue="{ text: leaseName }"
      :customList="leases"
      :genericLabel='true'
      :multiple='false'
      checkBoxText="Include Expired"
      :showCheckBox='true'
      @checkBoxChanged='includeExpired'
      width="200px"
      class="mr-4"
      @itemSelected="selectLease">
     </drop-down>
    <drop-down
      label="Charge Code"
      :selectedValue="{ text: chargeCodeName }"
      :items="'Charge Codes'"
      :multiple='false'
      width="200px"
      @itemSelected="selectChargeCode">
     </drop-down>
    </v-toolbar>
    <data-table
      :headers="headers"
      :tableData="getData"
      @refundPayment='selectPayment'
      :noData="'Please use search criteria above to start calculating fees.'"
      class="ml-6 mr-6 mt-6">
    </data-table>
    <base-dialog
    :showDialog="isDialog"
    title="Confirm Refund to Clearing Account"
    @cancel="cancelDialog"
    @save="confirmDialog"
    :saveDisabled="saveDisabled"
    v-if="isDialog">
      <v-card-text>
        <p>How much of the $ {{ payment.baseAmount }} payment would you like to refund?</p>
        <text-input
          v-model="refundAmount"
          prefix="$"
          placeholder="0"
          label="Refund Amount"
          textFormat="numeric"
          width="125px">
        </text-input>
      </v-card-text>
    </base-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DropDown from '../../../BaseComponents/DropDown'
import DataTable from '../../../BaseComponents/Table'
import BaseDialog from '../../../BaseComponents/BaseDialog'
import TextInput from '../../../BaseComponents/TextInput'
import * as validation from '../../../../utility/validation'
import * as currency from '../../../../utility/currency'

export default {
  components: {
    DropDown,
    DataTable,
    BaseDialog,
    TextInput
  },
  data () {
    return {
      lease: undefined,
      leaseName: undefined,
      property: undefined,
      propertyName: undefined,
      chargeCode: 'DEPOSIT',
      chargeCodeName: 'Security Deposit',
      tableData: [],
      isDialog: false,
      refundAmount: null,
      payment: {}
    }
  },
  methods: {
    ...mapActions(['fetchData', 'changeData', 'deleteData', 'initializeStoreItem', 'fetchDropdown']),
    selectProperty (val) {
      if (validation.hasValue(val)) {
        this.property = val.value
        this.propertyName = val.text
        this.$refs.leaseDropdown.resetCheckBox()
        this.getLeases()
      } else {
        this.property = null
        this.propertyName = null
      }
      this.lease = undefined
      this.leaseName = undefined
    },
    selectLease (val) {
      if (validation.hasValue(val)) {
        this.lease = val.value
        this.leaseName = val.text
      } else {
        this.lease = null
        this.leaseName = null
      }
    },
    selectChargeCode (val) {
      if (validation.hasValue(val)) {
        this.chargeCode = val.value
        this.chargeCodeName = val.text
      } else {
        this.chargeCode = null
        this.chargeCodeName = null
      }
    },
    getLeases () {
      this.fetchDropdown({ newDropdown: 'Leases', params: { LeaseStatus: 0, PropertyId: this.property } }).catch(error => { console.log(error.response) })
    },
    getPayments () {
      this.$emit('currentFilters', { Property: this.property, Lease: this.lease, ChargeCode: this.chargeCode })
      this.fetchData({ newPage: 'Refunds', params: { Property: this.property, Lease: this.lease, ChargeCode: this.chargeCode } }).catch(error => { console.log(error.response) })
    },
    confirmDialog () {
      this.payment.baseAmount = this.refundAmount
      this.payment.tranAmount = this.refundAmount
      this.$emit('selectPayment', this.payment)
    },
    selectPayment (val) {
      this.payment = val
      this.refundAmount = val.baseAmount
      this.isDialog = true
    },
    cancelDialog () {
      this.isDialog = false
    },
    includeExpired (val) {
      let type = 0
      if (val) {
        type = null
      }
      this.fetchDropdown({ newDropdown: 'Leases', params: { LeaseStatus: type, PropertyId: this.property } }).catch(error => { console.log(error.response) })
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentDropdown']),
    getData () {
      let items = this.currentData('Refunds')
      if (validation.hasValue(this.property)) {
        items = items.filter(x => x.property === this.property)
      }
      if (validation.hasValue(this.lease)) {
        items = items.filter(x => x.lease === this.lease)
      }
      if (validation.hasValue(this.chargeCode)) {
        items = items.filter(x => x.chargeCode === this.chargeCode)
      }
      return items
    },
    headers () {
      return this.currentHeaders('Refunds')
    },
    leases () {
      return this.currentDropdown('Leases')
    },
    saveDisabled () {
      return currency.parseCurrency(this.payment.baseAmount) < currency.parseCurrency(this.refundAmount) || currency.parseCurrency(this.refundAmount) < 0
    }
  },
  beforeDestroy () {
    this.initializeStoreItem('Refunds')
  },
  created () {
    this.fetchData({ newPage: 'Refunds' }).catch(error => { console.log(error.response) })
  }
}
</script>

<style lang="scss" scoped>
</style>
