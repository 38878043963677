<template>
  <v-card-text>
    <div>
      <span>Report: <b>{{ selectedReport }}</b></span>
    </div>
    <div class="resBlue-darken1--text back-link mb-6">
      <span @click="goBack">
        <v-icon class="back-link-arrow">keyboard_arrow_left</v-icon>
        Choose a different report
      </span>
    </div>
    <v-row no-gutters>
      <v-col cols="12">
        <drop-down
          class="mr-6"
          :label="propertyModeLabel"
          :items="propertyModeDropdown"
          :width="'190px'"
          @itemSelected="selectProperty">
        </drop-down>
        <drop-down
          :label="'Lease'"
          :items="'Leases'"
          :width="'190px'"
          :getParams="leaseParams"
          @itemSelected="selectLease">
        </drop-down>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-2">
      <v-col cols="12">
        <v-switch
          class="mt-0 pt-0 toggle"
          v-model="propertyMode"
          color="var(--v-resGreen-darken1)"
          :label="propertyModeString">
        </v-switch>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col xs5>
        <drop-down
          class="mr-6"
          :label="chargeCodeLabel"
          :items="chargeCodeDropdown"
          :width="'190px'"
          :getParams="recoveryParams">
        </drop-down>
        <drop-down
          :label="chargeCodeTypeLabel"
          :items="chargeCodeTypeDropdown"
          :width="'190px'"
          :getParams="recoveryParams">
        </drop-down>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-2">
      <v-col cols="12">
        <v-switch
          class="mt-0 pt-0 toggle"
          v-model="recoveriesMode"
          color="var(--v-resGreen-darken1)"
          :label="'Use Recoveries'">
        </v-switch>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <date-picker
          class="mr-6"
          :label="dateModeLabel"
          :defaultNull="true"
          :initialDate="startDate"
          @dateSelected="selectAsOfDate">
        </date-picker>
        <date-picker
          v-if="dateRangeMode"
          :label="'End date'"
          :defaultNull="true"
          @dateSelected="selectEndDate">
        </date-picker>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-2">
      <v-col cols="12">
        <v-switch
          class="mt-0 pt-0 toggle"
          v-model="dateRangeMode"
          color="var(--v-resGreen-darken1)"
          :label="'Use Date Range'">
        </v-switch>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import DropDown from '../BaseComponents/DropDown'
import DatePicker from '../BaseComponents/DatePicker'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  props: ['selectedReport'],
  components: {
    DropDown,
    DatePicker
  },
  data () {
    return {
      propertyMode: false,
      recoveriesMode: false,
      dateRangeMode: false,
      selectedProperty: null,
      selectedLease: null,
      selectedChargeCode: null,
      selectedChargeCodeType: null,
      selectedAsOfDate: null,
      selectedStartDate: null,
      selectedEndDate: null
    }
  },
  methods: {
    goBack () {
      this.$emit('goBack')
    },
    selectProperty (e) {
      if (e && e.value) {
        if (e.value.toUpperCase().includes('ALL')) {
          this.selectedProperty = null
        } else {
          this.selectedProperty = e.value
        }
      }

      setTimeout(() => {
        this.refreshDropdowns()
      }, 500)
    },
    selectLease (e) {
      if (e && e.value) {
        this.selectedLease = e.value
      }
    },
    selectChargeCode (e) {
      if (e && e.value) {
        this.selectedChargeCode = e.value
      }
    },
    selectChargeCodeType (e) {
      if (e && e.value) {
        this.selectedChargeCodeType = e.value
      }
    },
    selectAsOfDate (e) {
      if (this.dateRangeMode) {
        this.selectedStartDate = e
      } else {
        this.selectedAsOfDate = e
      }
    },
    selectEndDate (e) {
      this.selectedEndDate = e
    },
    refreshDropdowns () {
      this.$eventHub.$emit('refreshDropdown')
    }
  },
  computed: {
    ...mapGetters(['currentSelectedItem']),
    propertyModeString () {
      return `Search by Property Group`
    },
    propertyModeLabel () {
      return this.propertyMode ? 'Property Group' : 'Property'
    },
    propertyModeDropdown () {
      return this.propertyMode ? 'Groups' : 'Properties'
    },
    chargeCodeLabel () {
      return this.recoveriesMode ? 'Recovery pool' : 'Charge code'
    },
    chargeCodeTypeLabel () {
      return this.recoveriesMode ? 'Recovery cycle' : 'Charge type'
    },
    chargeCodeDropdown () {
      return this.recoveriesMode ? 'Recovery Pools' : 'Charge Codes'
    },
    chargeCodeTypeDropdown () {
      return this.recoveriesMode ? 'Recovery Cycles' : 'Charge Code Types'
    },
    dateModeLabel () {
      return this.dateRangeMode ? 'Start date' : 'As of date'
    },
    leaseParams () {
      if (this.propertyMode) return { PropertyGroup: this.selectedProperty, LeaseStatus: 0 }
      return { PropertyId: this.selectedProperty, LeaseStatus: 0 }
    },
    recoveryParams () {
      if (this.recoveriesMode) return { PropertyId: this.selectedProperty }
      return null
    },
    startDate () {
      if (this.dateRangeMode) {
        if (!this.selectedStartDate) return null
        return this.selectedStartDate
      } else if (!this.selectedStartDate) return moment().format('YYYY-MM-DD')
      return this.selectedStartDate
    },
    computedQueryString () {
      let queryString = '&'
      if (this.selectedProperty) queryString = queryString + `property=${this.selectedProperty.trim()}&`
      if (this.selectedLease) queryString = queryString + `lease=${this.selectedLease.trim()}&`
      if (this.selectedChargeCode) queryString = queryString + `lease=${this.selectedChargeCode.trim()}&`
      if (this.dateRangeMode) {
        if (this.selectedStartDate) queryString = queryString + `start_date=${this.selectedStartDate.trim()}&`
        if (this.selectedEndDate) queryString = queryString + `end_date=${this.selectedEndDate.trim()}&as_of_date=${this.selectedEndDate.trim()}&`
      } else {
        if (this.selectedAsOfDate) queryString = queryString + `end_date=${this.selectedAsOfDate.trim()}&as_of_date=${this.selectedAsOfDate.trim()}&`
      }
      return queryString.substring(0, queryString.length - 1)
    }
  },
  watch: {
    computedQueryString: function (val) {
      this.$emit('queryStringUpdated', val)
    },
    dateRangeMode: function () {
      // reset date values
      this.selectedStartDate = null
      this.selectedEndDate = null
      this.selectedAsOfDate = null
    }
  }
}
</script>

<style lang="scss" scoped>
.back-link {
  cursor: pointer;
  &-arrow {
    font-size: 18px;
    color: var(--v-resBlue-darken1);
  }
}

.toggle {
  ::v-deep label {
    font-size: 12px;
    color: var(--v-resGray-darken5);
  }
}
</style>
