import Vue from 'vue'
import eventHub from '../services/eventHub'
import Router from 'vue-router'
import Properties from '../components/LandingPages/Properties'
import SetupContent from '../components/ContentPages/SetupContent'
import LeasesContent from '../components/ContentPages/LeasesContent'
import PropertiesContent from '../components/ContentPages/PropertiesContent'
import Setup from '../components/LandingPages/Setup'
import Transactions from '../components/LandingPages/Transactions'
import accessdenied from '../components/AccessDenied'
import Leases from '../components/LandingPages/Leases'
import Recoveries from '../components/LandingPages/Recoveries'
import Dashboard from '../components/LandingPages/Dashboard'
import CommercialContent from '../components/ContentPages/CommercialContent'
import LeaseItems from '../components/SetupPages/LeaseItems'
import PropertyItems from '../components/SetupPages/PropertyItems'
import PropertyTemplates from '../components/SetupPages/PropertyTemplates'
import PropertiesSpaces from '../components/Properties/Spaces'
import PropertiesBuildings from '../components/Properties/Buildings'
import PropertiesInsurance from '../components/Properties/Insurance'
import CustomFields from '../components/SetupPages/CustomFields'
import LeasesDetails from '../components/Leases/Details'
import LeasesOverview from '../components/Leases/Overview'
import LeasesSpaces from '../components/Leases/Spaces'
import LeasesComments from '../components/Leases/Comments'
import LeasesHistory from '../components/Leases/History'
import LeasesInsurance from '../components/Leases/Insurance'
import LeasesCustomFields from '../components/Leases/CustomFields'
import LeasesCharges from '../components/Leases/Charges'
import LeasesExpenseRecovery from '../components/Leases/ExpenseRecovery'
import PropertiesDetails from '../components/Properties/Details'
import PropertiesOverview from '../components/Properties/Overview'
import PropertiesChargeCode from '../components/Properties/ChargeCode'
import PropertiesCustomFields from '../components/Properties/CustomFields'
import MonthlyRecurringCharges from '../components/Transactions/ChargesAdjustments/MonthlyRecurringCharges'
import TransactionsContent from '../components/ContentPages/TransactionsContent'
import TransactionDetail from '../components/Transactions/ChargesAdjustments/Detail'
import SqFtMeasures from '../components/Properties/SqFtMeasures'
import AdjustingChargesCredits from '../components/Transactions/ChargesAdjustments/AdjustingChargesCredits'
import LateFees from '../components/Transactions/ChargesAdjustments/LateFees'
import DeleteCharges from '../components/Transactions/ChargesAdjustments/DeleteCharges'
import ReceiptsDetail from '../components/Transactions/ReceiptsRefunds/Detail'
import CashReceipts from '../components/Transactions/ReceiptsRefunds/CashReceipts'
import ApplyCredits from '../components/Transactions/ReceiptsRefunds/ApplyCredits'
import ApplyPrepayments from '../components/Transactions/ReceiptsRefunds/ApplyPrepayments'
import VoidNsf from '../components/Transactions/ReceiptsRefunds/VoidNsf'
import Refunds from '../components/Transactions/ReceiptsRefunds/Refunds'
import DeleteCashReceipts from '../components/Transactions/ReceiptsRefunds/DeleteCashReceipts'
import UnapplyCredits from '../components/Transactions/ReceiptsRefunds/UnapplyCredits'
import UnapplyPrepayments from '../components/Transactions/ReceiptsRefunds/UnapplyPrepayments'
import PropertiesChargeCodeMapping from '../components/Properties/ChargeCodeMapping'
import PoolsCycles from '../components/Recoveries/PoolsCycles/PoolCycleFlow'
import Estimates from '../components/Recoveries/Estimates/EstimatesFlow'
import Reconciliations from '../components/Recoveries/Reconciliations/ReconciliationsFlow'
import Security from '../components/SetupPages/Security/Security'
import UserPermissions from '../components/SetupPages/Security/UserPermissions'
import UserRoles from '../components/SetupPages/Security/UserRoles'
import PropertyGroups from '../components/SetupPages/Security/PropertyGroups'
import ActivateUsers from '../components/SetupPages/Security/ActivateUsers'
import Reports from '../components/SetupPages/Reports'
import Notifications from '../components/SetupPages/Notifications/Notifications'
import NotificationOptions from '../components/SetupPages/Notifications/NotificationOptions'

import Imports from '../components/SetupPages/Imports/Imports'
import ImportsChargeCodesContent from '../components/SetupPages/Imports/ImportsContent/ChargeCodes'
import ImportsPropertiesContent from '../components/SetupPages/Imports/ImportsContent/Properties'
import ImportsLeasesContent from '../components/SetupPages/Imports/ImportsContent/Leases'
import ImportsBalancesContent from '../components/SetupPages/Imports/ImportsContent/Balances'
import ImportsLedgerHistoryContent from '../components/SetupPages/Imports/ImportsContent/LedgerHistory'

import ImportsPropertySpaces from '../components/SetupPages/Imports/ImportPages/ImportsPropertySpaces'
import ImportsChargeCodes from '../components/SetupPages/Imports/ImportPages/ImportsChargeCodes'
import ImportsChargeCodeTemplates from '../components/SetupPages/Imports/ImportPages/ImportsChargeCodeTemplates'
import ImportsChargeCodeMappings from '../components/SetupPages/Imports/ImportPages/ImportsChargeCodeMappings'
import ImportsProperties from '../components/SetupPages/Imports/ImportPages/ImportsProperties'
import ImportsLeases from '../components/SetupPages/Imports/ImportPages/ImportsLeases'
import ImportsLeaseSpaces from '../components/SetupPages/Imports/ImportPages/ImportsLeaseSpaces'
import ImportsLeaseCharges from '../components/SetupPages/Imports/ImportPages/ImportsLeaseCharges'
import ImportsDelinquents from '../components/SetupPages/Imports/ImportPages/ImportsDelinquents'
import ImportsPrepaids from '../components/SetupPages/Imports/ImportPages/ImportsPrepaids'
import ImportsSecurityDeposits from '../components/SetupPages/Imports/ImportPages/ImportsSecurityDeposits'
import ImportsSqFtMeasures from '../components/SetupPages/Imports/ImportPages/ImportsSqFtMeasures'
import ImportsTenantLedgerHistory from '../components/SetupPages/Imports/ImportPages/ImportsTenantHistory'

Vue.use(Router)

export default new Router({
  base: '/commercial-app/',
  routes: [
    {
      path: '/',
      component: CommercialContent,
      meta: {
        requiresAuth: true
      },
      redirect: {
        name: 'Dashboard'
      },
      children: [
        {
          path: 'Dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'Leases',
          name: '',
          component: LeasesContent,
          children: [
            {
              path: '',
              name: 'Leases',
              component: Leases
            },
            {
              path: 'Details',
              name: 'LeasesDetails',
              component: LeasesDetails,
              children: [
                {
                  path: 'Overview',
                  name: 'LeasesOverview',
                  component: LeasesOverview
                },
                {
                  path: 'Spaces',
                  name: 'LeasesSpaces',
                  component: LeasesSpaces
                },
                {
                  path: 'Comments',
                  name: 'LeasesComments',
                  component: LeasesComments
                },
                {
                  path: 'History',
                  name: 'LeasesHistory',
                  component: LeasesHistory
                },
                {
                  path: 'Insurance',
                  name: 'LeasesInsurance',
                  component: LeasesInsurance
                },
                {
                  path: 'CustomFields',
                  name: 'LeasesCustomFields',
                  component: LeasesCustomFields
                },
                {
                  path: 'Charges',
                  name: 'LeasesCharges',
                  component: LeasesCharges
                },
                {
                  path: 'ExpenseRecovery',
                  name: 'LeasesExpenseRecovery',
                  component: LeasesExpenseRecovery
                }
              ]
            }
          ]
        },
        {
          path: 'Properties',
          name: '',
          component: PropertiesContent,
          children: [
            {
              path: '',
              name: 'Properties',
              component: Properties
            },
            {
              path: 'Details',
              name: 'PropertiesDetails',
              component: PropertiesDetails,
              children: [
                {
                  path: 'Overview',
                  name: 'PropertiesOverview',
                  component: PropertiesOverview
                },
                {
                  path: 'Spaces',
                  name: 'PropertiesSpaces',
                  component: PropertiesSpaces
                },
                {
                  path: 'SqFtMeasures',
                  name: 'PropertiesSqFtMeasures',
                  component: SqFtMeasures
                },
                {
                  path: 'Buildings',
                  name: 'PropertiesBuildings',
                  component: PropertiesBuildings
                },
                {
                  path: 'Insurance',
                  name: 'PropertiesInsurance',
                  component: PropertiesInsurance
                },
                {
                  path: 'CustomFields',
                  name: 'PropertiesCustomFields',
                  component: PropertiesCustomFields
                },
                {
                  path: 'ChargeCodeMapping',
                  name: 'PropertiesChargeCodeMapping',
                  component: PropertiesChargeCodeMapping
                },
                {
                  path: 'ChargeCode',
                  name: 'PropertiesChargeCode',
                  component: PropertiesChargeCode
                }
              ]
            }
          ]
        },
        {
          path: 'Transactions',
          name: '',
          component: TransactionsContent,
          children: [
            {
              path: '',
              name: 'Transactions',
              component: Transactions
            },
            {
              path: 'Detail',
              name: 'TransactionDetail',
              component: TransactionDetail,
              redirect: {
                name: 'Monthly Recurring Charges'
              },
              children: [
                {
                  path: 'MonthlyRecurringCharges',
                  name: 'Monthly Recurring Charges',
                  component: MonthlyRecurringCharges
                },
                {
                  path: 'AdjustingChargesCredits',
                  name: 'Adjusting Charges Credits',
                  component: AdjustingChargesCredits
                },
                {
                  path: 'DeleteCharges',
                  name: 'Delete Charges',
                  component: DeleteCharges
                },
                {
                  path: 'LateFees',
                  name: 'Late Fees',
                  component: LateFees
                }
              ]
            },
            {
              path: 'Detail',
              name: 'ReceiptsDetail',
              component: ReceiptsDetail,
              redirect: {
                name: 'Cash Receipts'
              },
              children: [
                {
                  path: 'CashReceipts',
                  name: 'Cash Receipts',
                  component: CashReceipts
                },
                {
                  path: 'ApplyCredits',
                  name: 'Apply Credits',
                  component: ApplyCredits
                },
                {
                  path: 'ApplyPrepayments',
                  name: 'Apply Prepayments',
                  component: ApplyPrepayments
                },
                {
                  path: 'VoidNsf',
                  name: 'Void Nsf',
                  component: VoidNsf
                },
                {
                  path: 'Refunds',
                  name: 'Refunds',
                  component: Refunds
                },
                {
                  path: 'DeleteCashReceipts',
                  name: 'Delete Cash Receipts',
                  component: DeleteCashReceipts
                },
                {
                  path: 'UnapplyCredits',
                  name: 'Unapply Credits',
                  component: UnapplyCredits
                },
                {
                  path: 'UnapplyPrepayments',
                  name: 'Unapply Prepayments',
                  component: UnapplyPrepayments
                }
              ]
            }
          ]
        },
        {
          path: 'Recoveries',
          name: 'Recoveries',
          component: Recoveries,
          children: [
            {
              path: 'PoolsCycles',
              name: 'Pools Cycles',
              component: PoolsCycles
            },
            {
              path: 'Estimates',
              name: 'Estimates',
              component: Estimates
            },
            {
              path: 'Reconciliations',
              name: 'Reconciliations',
              component: Reconciliations
            }
          ]
        },
        {
          path: 'Setup',
          name: 'SetupContent',
          component: SetupContent,
          children: [
            {
              path: 'Landing',
              name: 'Setup',
              component: Setup
            },
            {
              path: 'LeaseItems',
              name: 'Lease Items',
              component: LeaseItems
            },
            {
              path: 'PropertyItems',
              name: 'Property Items',
              component: PropertyItems
            },
            {
              path: 'PropertyTemplates',
              name: 'Templates',
              component: PropertyTemplates
            },
            {
              path: 'CustomFields',
              name: 'Custom Fields',
              component: CustomFields
            },
            {
              path: 'Notifications',
              name: 'Notifications',
              component: Notifications,
              children: [
                {
                  path: 'Options',
                  name: 'Notification Options',
                  component: NotificationOptions
                }
              ]
            },
            {
              path: 'Reports',
              name: 'Reports',
              component: Reports
            },
            {
              path: 'Security',
              name: 'Security',
              component: Security,
              children: [
                {
                  path: 'UserPermissions',
                  name: 'User Permissions',
                  component: UserPermissions
                },
                {
                  path: 'UserRoles',
                  name: 'User Roles',
                  component: UserRoles
                },
                {
                  path: 'PropertyGroups',
                  name: 'Property Groups',
                  component: PropertyGroups
                },
                {
                  path: 'ActivateUsers',
                  name: 'Activate Users',
                  component: ActivateUsers
                }
              ]
            },
            {
              path: 'Imports',
              name: 'Imports',
              component: Imports
            },
            {
              path: 'Imports',
              name: 'Imports Charge Codes Content',
              component: ImportsChargeCodesContent,
              children: [
                {
                  path: 'ImportsChargeCodes',
                  name: 'Imports Charge Codes',
                  component: ImportsChargeCodes
                },
                {
                  path: 'ImportsChargeCodeTemplates',
                  name: 'Imports Charge Code Templates',
                  component: ImportsChargeCodeTemplates
                }
              ]
            },
            {
              path: 'Imports',
              name: 'Imports Properties Content',
              component: ImportsPropertiesContent,
              children: [
                {
                  path: 'ImportsPropertySpaces',
                  name: 'Imports Property Spaces',
                  component: ImportsPropertySpaces
                },
                {
                  path: 'ImportsProperties',
                  name: 'Imports Properties',
                  component: ImportsProperties
                },
                {
                  path: 'ImportsSqFtMeasures',
                  name: 'Imports SqFt Measures',
                  component: ImportsSqFtMeasures
                },
                {
                  path: 'ImportsChargeCodeMappings',
                  name: 'Imports Charge Code Mappings',
                  component: ImportsChargeCodeMappings
                }
              ]
            },
            {
              path: 'Imports',
              name: 'Imports Leases Content',
              component: ImportsLeasesContent,
              children: [
                {
                  path: 'ImportsLeases',
                  name: 'Imports Leases',
                  component: ImportsLeases
                },
                {
                  path: 'ImportsLeaseSpaces',
                  name: 'Imports Lease Spaces',
                  component: ImportsLeaseSpaces
                },
                {
                  path: 'ImportsLeaseCharges',
                  name: 'Imports Lease Charges',
                  component: ImportsLeaseCharges
                }
              ]
            },
            {
              path: 'Imports',
              name: 'Imports Balances Content',
              component: ImportsBalancesContent,
              children: [
                {
                  path: 'ImportsDelinquents',
                  name: 'Imports Delinquents',
                  component: ImportsDelinquents
                },
                {
                  path: 'ImportsPrepaids',
                  name: 'Imports Prepaids',
                  component: ImportsPrepaids
                },
                {
                  path: 'ImportsSecurityDeposits',
                  name: 'Imports Security Deposits',
                  component: ImportsSecurityDeposits
                }
              ]
            },
            {
              path: 'Imports',
              name: 'Imports Ledger History Content',
              component: ImportsLedgerHistoryContent,
              children: [
                {
                  path: 'ImportsTenantLedgerHistory',
                  name: 'Imports Tenant Ledger History',
                  component: ImportsTenantLedgerHistory
                }
              ]
            }
          ]
        },
        {
          path: 'Accounting',
          name: 'Accounting',
          beforeEnter () {
            eventHub.$emit('goToResman')
          }
        }
      ]
    },
    {
      path: '/Accessdenied',
      name: 'accessdenied',
      component: accessdenied
    },
    {
      path: '*',
      redirect: { name: 'Dashboard' }
    }
  ]
})
