<template>
  <base-dialog
    :showDialog="showDialog"
    :title="'Session Timeout'"
    :showTitleIcon="true"
    :iconTitle="'error'"
    :iconColor="'var(--v-resWhite-base)'"
    :titleClass="'dark-header'"
    :maxWidth="380"
    :hideClose="true"
    :saveButtonWidth="150"
    :saveButtonText="'Stay Logged In'"
    :saveButtonColor="'var(--v-resBlue-darken1)'"
    :cancelButtonText="showExpired ? 'Log In' : 'Log Off'"
    :cancelButtonColor="'var(--v-resBlue-darken1)'"
    :cancelButtonTextColor="'resBlue-darken2--text'"
    :saveDisabled="showExpired"
    :cancelButtonOnly="showExpired"
    @cancel="logOff"
    @save="continueSession">
    <v-card-text class="pl-4">
      <span>{{ dialogText }}</span>
    </v-card-text>
  </base-dialog>
</template>

<script>
import BaseDialog from './BaseComponents/BaseDialog'
import Auth from '../services/auth'

export default {
  components: {
    BaseDialog
  },
  props: ['showDialog', 'showExpired'],
  data () {
    return {
      countdown: 60,
      mgr: new Auth()
    }
  },
  created () {
    this.countdown = this.mgr.getAccessTokenExpiringNotificationTime()
    this.startTimer()
  },
  methods: {
    startTimer () {
      let timer = setInterval(() => {
        this.countdown -= 1
        if (this.countdown === 0) clearInterval(timer)
      }, 1000)
    },
    continueSession () {
      this.$emit('continueSession')
    },
    logOff () {
      this.$emit('logOff')
    }
  },
  computed: {
    dialogText () {
      if (this.showExpired) return 'Your session has expired!'
      return `Due to inactivity, your session is about to expire. Please choose to stay logged in or your will be logged out automatically in (${this.countdown}) seconds.`
    }
  }
}
</script>
