<template>
  <imports-template
    ref='template'
    :canImport='canImport'
    :canExport='true'
    currentPage='GetImportChargeCodesData'
    @downloadTemplate='downloadTemplate'
    @clearImportData='clearImportData'
    @saveUpload='saveUpload'
    @addNew='addNewItem'
    @submit='submit'>
    <template v-slot:body>
      <data-table
        :headers="headers"
        :tableData="getData"
        @itemDeleteRequested="deleteItem"
        @unlockEditing="unlockEdit"
        @dataChanged="save"
        @canceled="cancel"
        class="mx-6"
        :noData="'Please select an import template to upload.'">
      </data-table>
      <v-layout row mt-1 v-if='showWarning'>
        <v-icon small color="var(--v-resRed-base)">warning</v-icon>
        <span class='resRed-base--text'>
            {{ warningMessage }}
        </span>
      </v-layout>
    </template>
  </imports-template>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as fileDownload from '../../../../utility/fileDownloader'
import * as validation from '../../../../utility/validation'
import DataTable from '../../../BaseComponents/Table'
import ImportsTemplate from './ImportsTemplate'
export default {
  components: {
    DataTable,
    ImportsTemplate
  },
  data () {
    return {}
  },
  methods: {
    ...mapActions(['fetchData', 'changeData', 'initializeStoreItem', 'fetchDropdown', 'addNew']),
    deleteItem (item) {
      this.$refs.template.deleteItem(item, this.getData)
    },
    unlockEdit (item) {
      this.$refs.template.unlockEdit(item, this.getData)
    },
    cancel (item) {
      this.$refs.template.cancelEdit(item, this.getData)
    },
    save (item) {
      this.$refs.template.save(item)
    },
    addNewItem () {
      this.addNew({
        currentPage: 'GetImportChargeCodesData',
        newItem: {
          chargeCode: null,
          chargeCodeName: null,
          chargeType: { text: null, value: null },
          displayColumn: null,
          notes: null,
          shortName: null,
          actionColumn: { delete: true, edit: true },
          readonly: false,
          new: true,
          errorToolTip: { hasIcon: false, tooltip: null, value: '' }
        }
      })
    },
    downloadTemplate () {
      this.fetchData({ newPage: 'ImportChargeCodes' }).then(() => {
        let file = this.currentData('ImportChargeCodes')
        let bufferArray = fileDownload.convertArrayBuffer(file.fileContent)
        fileDownload.createDownloadFile(bufferArray, file.fileName)
      }).catch(error => { console.log(error.response) })
    },
    saveUpload (item) {
      this.fetchDropdown({ newDropdown: 'Charge Code Types' }).then(() => {
        this.changeData({ currentPage: 'GetImportChargeCodesData', newData: item, updateItem: {} }).then(() => {
          this.$refs.template.cancel()
        })
      }).catch(error => { console.log(error.response) })
    },
    submit () {
      this.changeData({ currentPage: 'ImportChargeCodes', newData: { ChargeCodes: this.getData } }).then(() => {
        this.$refs.template.cancelImport()
      }).catch(error => { console.log(error.response) })
    },
    clearImportData () {
      this.initializeStoreItem('GetImportChargeCodesData')
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentDropdown', 'currentLoading']),
    headers () {
      let items = JSON.parse(JSON.stringify(this.currentHeaders('GetImportChargeCodesData')))
      let validation = items.splice(items.findIndex(x => x.value === 'errorToolTip'), 1)
      return validation.concat(items)
    },
    rawData () {
      return this.currentData('GetImportChargeCodesData')
    },
    getData () {
      let items = this.rawData
      let chargeCodeTypes = this.currentDropdown('Charge Code Types')
      let chargeCodeTypesLength = chargeCodeTypes.length
      items.forEach(item => {
        if (chargeCodeTypesLength > 0 && validation.hasValue(item.chargeType) && validation.hasValue(item.chargeType.text)) {
          item.chargeType = chargeCodeTypes.find(x => {
            if (validation.hasValue(x.text)) {
              return x.text.toLowerCase() === item.chargeType.text.toLowerCase()
            }
          })
        }
        let errors = validation.validateValue(item, [
          { value: 'chargeCode', required: true, maxLength: 10, unique: true },
          { value: 'chargeType', required: true },
          { value: 'chargeCodeName', required: true, maxLength: 50 },
          { value: 'shortName', maxLength: 50 },
          { value: 'displayColumn', positive: true }
        ], items)
        validation.handleErrorToolTip(item, errors)
      })
      return items
    },
    canImport () {
      if (this.getData.length > 0 && !this.showWarning) {
        return true
      }
      return false
    },
    warningMessage () {
      return this.getData.filter(x => x.errorToolTip.hasIcon === true).length + ' row(s) have errors. Please fix these to continue.'
    },
    showWarning () {
      return this.getData.filter(x => x.errorToolTip.hasIcon === true).length > 0
    }
  },
  beforeDestroy () {
    this.clearImportData()
  }
}
</script>
