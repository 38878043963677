<template>
  <div>
    <v-card width="1000" height="90" class="tabs-card">
      <v-layout>
        <v-flex ml-4 mt-2>
          <div class="caption resGray-darken1--text mb-2">Lease name</div>
          <div class="caption resGray-darken5--text font-weight-medium">{{ currentLease.leaseName }}</div>
        </v-flex>
        <!-- <v-layout ml-4 mt-2 column>
          <label class="caption resGray-darken1--text mb-2">Application date</label>
          <span class="caption resGray-darken5--text font-weight-medium">{{ applicationDate | dateFilter(applicationDate) }}</span>
        </v-layout> -->
        <v-flex ml-4 mt-2 column>
          <div class="caption resGray-darken1--text mb-2">Charge code</div>
          <div class="caption resGray-darken5--text font-weight-medium">{{ currentLease.chargeCodeName }}</div>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex ml-12 mt-2 column class="text-right">
          <div class="caption resGray-darken1--text mb-2">Credits total</div>
          <div class="subheading resGray-darken5--text font-weight-medium">{{ creditTotal }}</div>
        </v-flex>
        <v-flex mt-2 column class="text-right">
          <div class="caption resGray-darken1--text mb-2">Applied total</div>
          <div class="subheading font-weight-medium" :class="reconcileCreditColor">{{ appliedCreditTotal }}</div>
        </v-flex>
        <v-spacer></v-spacer>
      </v-layout>
    </v-card>
    <v-card flat width="1000">
      <data-table
        class="distribute-table"
        :headers="headers"
        :tableData="getCharges"
        @applyAmount="applyAmount"
        :noData="'No information to display.'">
      </data-table>
    </v-card>
  </div>
</template>

<script>
import DataTable from '../../../BaseComponents/Table'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import * as currency from '../../../../utility/currency'

export default {
  props: ['creditDetails', 'applicationDate', 'bankAccount'],
  components: {
    DataTable
  },
  data () {
    return {
    }
  },
  methods: {
    ...mapActions(['fetchDropdown', 'changeData', 'fetchData', 'addNew', 'cancelNew', 'initializeStoreItem']),
    applyAmount (item) {
      var unapplied = currency.parseCurrency(this.unappliedCreditTotal)
      if (item.baseOpenAmount <= unapplied) {
        item.selectBasePaymentAmount = item.baseOpenAmount
      } else if (unapplied > 0) {
        item.selectBasePaymentAmount = unapplied
      }
    },
    save () {
      if (this.totalCreditReconciled) {
        // set credit amount
        var details = this.getData
        for (var i = 0; i < details.length; i++) {
          if (details[i].baseAmount < 0 && details[i].leaseCharge === this.creditDetails.leaseCharge && details[i].chargeItem === this.creditDetails.chargeItem) {
            details[i].selectBasePaymentAmount = -(currency.parseCurrency(this.appliedCreditTotal))
          } else if (!details[i].selectBasePaymentAmount) {
            details[i].selectBasePaymentAmount = 0
          }
        }
        this.changeData(
          {
            currentPage: 'ApplyCredits',
            newData:
            {
              CompanyId: this.currentLease.property,
              BankAccountCode: this.bankAccount,
              Lease: this.currentLease.lease,
              ApplicationDate: this.applicationDate,
              PaymentDetails: details
            }
          }).then(() => {
          this.fetchData({ newPage: 'ApplyCredits', params: { Property: this.currentLease.property } })
          let currentPaid = this.getData.map(item => {
            if (currency.parseCurrency(item.selectBasePaymentAmount) < 0) {
              return currency.parseCurrency(item.selectBasePaymentAmount)
            } else {
              return 0
            }
          }).reduce((a, b) => a + b, 0)
          if (currentPaid !== currency.parseCurrency(this.unappliedCreditTotal)) {
            this.fetchData({ newPage: 'LeaseOpenCharges', params: { Lease: this.currentLease.lease, CompanyId: this.currentLease.property, BankAccountCode: this.bankAccount, BankDepositId: -1 } })
          }
        }).catch(error => { console.log(error.response) })
      }
    }
  },
  computed: {
    ...mapGetters(['currentDropdown', 'currentData', 'currentHeaders']),
    totalCreditReconciled () {
      let imbalanced = this.getData.filter(x => {
        if (Math.abs(x.selectBasePaymentAmount) > Math.abs(x.baseAmount)) {
          return true
        } else if (x.baseAmount > 0 && x.selectBasePaymentAmount < 0) {
          return true
        } else if (x.baseAmount < 0 && x.selectBasePaymentAmount > 0) {
          return true
        }
        return false
      })
      if (imbalanced.length === 0 && currency.parseCurrency(this.unappliedCreditTotal) >= 0) {
        this.$emit('reconciled', true)
        return true
      }
      this.$emit('reconciled', false)
      return false
    },
    appliedCreditTotal () {
      if (this.getData.length > 0) {
        let sum = Number(0)
        this.getData.forEach(function (charge) {
          sum += Number(charge.selectBasePaymentAmount)
          sum = currency.parseCurrency(sum) // remove excess decimal values
        })
        return currency.convertToCurrency(sum)
      }
      return currency.convertToCurrency(0)
    },
    unappliedCreditTotal () {
      let remaining = currency.parseCurrency(this.creditTotal) - currency.parseCurrency(this.appliedCreditTotal)
      let total = currency.convertToCurrency(remaining)
      return total
    },
    creditTotal () {
      if (this.currentLease.baseOpenAmount !== undefined) {
        return currency.convertToCurrency(Math.abs(this.currentLease.baseOpenAmount)) // treat as a positive amount
      }
      return currency.convertToCurrency(0)
    },
    reconcileCreditColor () {
      if (!this.totalCreditReconciled) {
        return 'resRed-base--text'
      } else {
        return 'resGreen-base--text'
      }
    },
    currentLease () {
      return this.creditDetails
    },
    headers: {
      get () {
        return this.currentHeaders('LeaseOpenCharges')
      }
    },
    getData () {
      let items = this.currentData('LeaseOpenCharges')
      items.forEach((item) => {
        item.readonly = false
      })
      return items
    },
    getCharges () {
      return (this.getData || []).filter((item) => { return item.baseAmount >= 0 })
    },
    completeDistribution () {
      return null
    }
  },
  created () {
    this.initializeStoreItem('LeaseOpenCharges')
    this.fetchData({ newPage: 'LeaseOpenCharges', params: { Lease: this.currentLease.lease, CompanyId: this.currentLease.property, BankAccountCode: this.bankAccount, BankDepositId: -1 } })
      .catch(error => { console.log(error.response) })
  },
  filters: {
    dateFilter (val) {
      return moment(val).format('L')
    }
  }
}
</script>

<style lang="scss" scoped>
  .distribute-table {
    ::v-deep thead th {
      padding: 0px 10px !important;
    }
    ::v-deep tbody td {
      padding: 0px 10px !important;
    }
  }

  .tabs-card {
    border-top: 2px var(--v-resBlue-base) solid;
    border-right: 1px var(--v-resGray-lighten1) solid;
    border-left: 1px var(--v-resGray-lighten1) solid;
    border-bottom: 1px var(--v-resGray-lighten1) solid;
    border-radius: 0;
  }

  .contact-title--primary {
    font-style: italic;
  }
</style>
