const state = {
  currentToggle: localStorage.getItem('currentToggle') ? JSON.parse(localStorage.getItem('currentToggle')) : 0
}

const getters = {
  currentToggle: (state) => state.currentToggle
}

const actions = {
  changeCurrentToggle ({ commit }, toggle) {
    commit('setCurrentToggle', toggle)
  }
}

const mutations = {
  setCurrentToggle: (state, toggle) => {
    state.currentToggle = toggle
    if (toggle) {
      const parsed = JSON.stringify(toggle)
      localStorage.setItem('currentToggle', parsed)
    } else {
      localStorage.removeItem('currentToggle')
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
