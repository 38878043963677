/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  '0011': {
    width: 87,
    height: 18,
    viewBox: '0 0 87 18',
    data: '<g fill="none" fill-rule="evenodd"><rect pid="0" width="17" height="17" x=".5" y=".5" fill="#E8E8E8" stroke="#D1D1D1" rx="2"/><text fill="#A6A6A6" font-family="Roboto-Medium, Roboto" font-size="10" font-weight="400"><tspan x="2.706" y="12">Q1</tspan></text><g transform="translate(23)"><rect pid="1" width="17" height="17" x=".5" y=".5" fill="#E8E8E8" stroke="#D1D1D1" rx="2"/><text fill="#A6A6A6" font-family="Roboto-Medium, Roboto" font-size="10" font-weight="400"><tspan x="2.706" y="12">Q2</tspan></text></g><g transform="translate(69)"><rect pid="2" width="17" height="17" x=".5" y=".5" fill="#83BA1C" stroke="#D1D1D1" rx="2"/><text fill="#FFF" font-family="Roboto-Medium, Roboto" font-size="10" font-weight="400"><tspan x="2.706" y="12">Q4</tspan></text></g><g><g transform="translate(46)"><rect pid="3" width="17" height="17" x=".5" y=".5" fill="#83BA1C" stroke="#D1D1D1" rx="2"/><text fill="#FFF" font-family="Roboto-Medium, Roboto" font-size="10" font-weight="400"><tspan x="2.706" y="12">Q3</tspan></text></g></g></g>'
  }
})
