<template>
  <router-view></router-view>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  data () {
    return {}
  },
  mounted () {
    this.changeState()
  },
  methods: {
    ...mapActions(['changeSection', 'changeSelectedItem']),
    changeState () {
      this.changeSection('Leases')
    }
  }
}
</script>
