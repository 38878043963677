<template>
  <div>
    <v-layout align-center justify-space-between column fill-height>
      <div>
        <div>
          <v-btn :ripple="false" text @click="returnToGrid" color="var(--v-resWhite-base)" class="ml-0 pl-0">
            <v-btn small color="var(--v-resBlue-darken1)" icon><v-icon size="18" dark>arrow_back</v-icon></v-btn>
            <span class="text-none ml-0 back-button-text resBlue-darken1--text">Back to Results</span>
          </v-btn>
        </div>
        <v-card width="1000" height="212" class="mt-4">
          <v-flex>
            <v-layout>
              <v-flex>
                <v-flex mt-4>
                  <span class="title ml-6">Original Deposit Details</span>
                </v-flex>
                <v-flex mt-4>
                  <v-layout>
                    <v-flex ml-6>
                      <div class="caption resGray-darken1--text mb-1">Deposit date</div>
                      <div class="caption resGray-darken5--text font-weight-medium">{{ depositDetails.depositDate | dateFilter(depositDetails.depositDate) }}</div>
                    </v-flex>
                    <v-flex>
                      <div class="caption resGray-darken1--text mb-1">Property</div>
                      <div class="caption resGray-darken5--text font-weight-medium">{{ depositDetails.bankDepositDetails[0].propertyName }}</div>
                    </v-flex>
                    <v-flex>
                      <div class="caption resGray-darken1--text mb-1">Cash Account</div>
                      <div class="caption resGray-darken5--text font-weight-medium">{{ depositDetails.bankAccountCode }} - {{ depositDetails.bankAccountName }}</div>
                    </v-flex>
                  </v-layout>
                  <v-layout ml-6 mt-6>
                    <div class="caption resGray-darken1--text mb-1">Description</div>
                    <div class="caption resGray-darken5--text font-weight-medium">{{ depositDetails.reference }}</div>
                  </v-layout>
                </v-flex>
              </v-flex>
              <v-flex>
                <v-layout align-end column fill-height>
                  <span class="caption mr-6 mt-4 mb-2 resGray-darken1--text">Deposit slip total</span>
                  <span class="title mb-2 mr-6">{{ depositTotal }}</span>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-card>
      </div>
      <div>
        <v-flex mt-6 mb-4>
          <span class="title">Applied Prepayment Details</span>
        </v-flex>
        <applied-prepayment-details
          :depositId="currentCheck"
          :journalItemId="currentJournalItem"
          :depositDetails="depositDetails">
        </applied-prepayment-details>
        <v-layout>
          <v-spacer></v-spacer>
          <round-button
            v-if="canUnapply"
            height='30px'
            buttonClass='mt-4 ml-4 mr-0'
            width='190px'
            :depressed='true'
            :flat='true'
            :buttonTextColor="'var(--v-resWhite-base)'"
            :backgroundColor="'var(--v-resRed-base)'"
            :buttonText="'Unapply Prepayments'"
            @click="openUnapplyDialog">
          </round-button>
        </v-layout>
      </div>
    </v-layout>
    <!-- Dialogs -->
    <delete-dialog
      :showDialog="unapplyConfirm"
      v-if="unapplyConfirm"
      :title="'Are you sure?'"
      confirmButton="Yes, unapply prepayments"
      confirmButtonWidth="108px"
      @confirmDelete="confirmDialog"
      message="Please confirm that you would like to unapply payments to associated charges. Doing so will re-open all associated charges."
      @cancelDelete="cancelDialog">
      <v-flex>
        <v-layout mt-2 ml-6>
          <v-flex>
            <div class="caption resGray-darken1--text mb-1">Check date</div>
            <div class="caption">{{ currentPrepaidItemsToUnapply.payerCheckDate }}</div>
          </v-flex>
          <v-flex>
            <div class="caption resGray-darken1--text mb-1">Check number</div>
            <div class="caption">{{ currentPrepaidItemsToUnapply.payerCheckNumber }}</div>
          </v-flex>
        </v-layout>
      </v-flex>
    </delete-dialog>
  </div>
</template>

<script>
import AppliedPrepaymentDetails from './AppliedPrepaymentDetails'
import RoundButton from '../../../BaseComponents/RoundButton'
import DeleteDialog from '../../../BaseComponents/DeleteDialog'
import { mapGetters, mapActions } from 'vuex'
import * as currency from '../../../../utility/currency'
import moment from 'moment'

export default {
  props: ['depositDetails', 'currentCheck', 'currentJournalItem'],
  components: {
    RoundButton,
    DeleteDialog,
    AppliedPrepaymentDetails
  },
  data () {
    return {
      editedItem: {},
      appliedTotal: Number(0),
      unapplyConfirm: false,
      currentPrepaidItemsToUnapply: this.depositDetails.bankDepositDetails.find(x => x.depositId === this.currentCheck)
    }
  },
  methods: {
    ...mapActions(['fetchDropdown', 'changeData', 'fetchData', 'initializeStoreItem']),
    openUnapplyDialog () {
      this.currentPrepaidItemsToUnapply.payerCheckDate = moment(this.currentPrepaidItemsToUnapply.payerCheckDate).format('L')
      this.unapplyConfirm = true
    },
    cancelDialog () {
      this.unapplyConfirm = false
    },
    confirmDialog () {
      this.changeData({ currentPage: 'UnapplyPrepayments',
        newData: {
          CompanyId: this.currentPrepaidItemsToUnapply.companyId,
          BankAccountCode: this.currentPrepaidItemsToUnapply.bankAccountCode,
          DepositSlipId: this.currentPrepaidItemsToUnapply.depositSlipId,
          BankDepositId: this.currentPrepaidItemsToUnapply.depositId,
          JournalItemId: this.currentJournalItem
        } }).then(() => {
        this.unapplyConfirm = false
        this.returnToGrid()
      }).catch(error => { console.log(error.response) })
    },
    returnToGrid () {
      this.$emit('returnToGrid')
    }
  },
  computed: {
    ...mapGetters(['currentDropdown', 'currentData', 'currentHeaders']),
    canUnapply () {
      return this.depositDetails.bankDepositDetails.find(x => x.depositId === this.currentCheck).transactionStatus !== 25
    },
    depositTotal () {
      if (this.depositDetails.bankDepositDetails.length > 0) {
        let sum = Number(0)
        this.depositDetails.bankDepositDetails.forEach(function (charge) {
          sum += currency.parseCurrency(charge.baseAmount)
        })
        return currency.convertToCurrency(sum)
      }
      return currency.convertToCurrency(0)
    }
  },
  filters: {
    dateFilter (val) {
      return moment(val).format('L')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
