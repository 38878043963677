/* eslint-disable */
require('./0000')
require('./0001')
require('./0010')
require('./0011')
require('./0100')
require('./0110')
require('./0111')
require('./1000')
require('./1100')
require('./1110')
require('./1111')
require('./csv')
require('./doc')
require('./down')
require('./jpg')
require('./pdf')
require('./png')
require('./ppt')
require('./psd')
require('./settings')
require('./txt')
require('./up')
require('./xls')
