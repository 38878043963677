<template>
  <v-navigation-drawer app clipped dark width="150" mini-variant-width="56" :permanent="$vuetify.breakpoint.mdAndUp" :mini-variant="mini">
    <v-list class="pt-0">
      <v-hover v-for="item in items" :key="item.title">
        <v-list-item
          class="side-bar__menu-item py-1"
          slot-scope="{ hover }"
          :to="{ name: item.route }"
          active-class="side-bar__menu--active">
          <v-list-item-action class="side-bar__menu-avatar">
          <v-tooltip right v-if="mini">
            <template v-slot:activator="{ on }">
              <v-icon :class="item.class + `${hover? '': 'resGray-darken1--text'}`" v-on="on" :size="item.size">{{ item.icon }}</v-icon>
            </template>
            <span>{{ item.title }}</span>
          </v-tooltip>
          <v-icon :class="`${hover? '': ' resGray-darken1--text'}`" v-else :size="item.size">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content :class="`${hover? '': 'resGray-darken1--text'}`">
              <span class="side-bar__menu-text pl-1">{{ item.title.toUpperCase() }}</span>
          </v-list-item-content>
        </v-list-item>
      </v-hover>
    </v-list>
    <div :class="`${mini? 'side-bar__collapse-button--closed mx-2 my-4 pa-2': 'side-bar__collapse-button--open ma-4 pa-2'}`" @click.stop="mini = !mini">
      <v-icon class="side-bar__collapse-icon" v-if="mini">chevron_right</v-icon>
      <v-icon class="side-bar__collapse-icon" v-else>chevron_left</v-icon>
    </div>
  </v-navigation-drawer>
</template>

<script>
export default {
  components: {
  },
  data () {
    return {
      items: [],
      mini: false,
      drawer: true
    }
  },
  created () {
    this.initialize()
  },
  methods: {
    initialize () {
      this.items = [
        {
          title: 'Dashboard',
          icon: 'dashboard',
          route: 'Dashboard',
          size: '20',
          class: 'mr-1 '
        },
        {
          title: 'Leases',
          icon: 'description',
          route: 'Leases',
          size: '20',
          class: 'mr-1 '
        },
        {
          title: 'Properties',
          icon: 'domain',
          route: 'Properties',
          size: '20',
          class: 'mr-1 '
        },
        {
          title: 'Transactions',
          icon: 'attach_money',
          route: 'Transactions',
          size: '24',
          class: ''
        },
        {
          title: 'Recoveries',
          icon: 'cached',
          route: 'Recoveries',
          size: '20',
          class: 'mr-1 '
        },
        {
          title: 'Setup',
          icon: 'build',
          route: 'Setup',
          size: '17',
          class: 'mr-2 '
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .side-bar__collapse-button--open {
    position: absolute;
    bottom: 0;
    width: 40px;
    height: 40px;
    background-color: var(--v-resBlue-base);
    border-radius: 6px;
    cursor: pointer;
  }
  .side-bar__menu-text {
    font-size:12px;
  }
  .side-bar__collapse-button--closed {
    position: absolute;
    bottom: 0;
    width: 40px;
    height: 40px;
    border-radius: 6px;
    cursor: pointer;
  }
  .side-bar__collapse-icon {
    height: 18px;
    width: 10.8px;
    padding: 12px
  }
  .side-bar__menu-avatar {
    min-width:24px;
  }
  .side-bar__menu-item:hover {
    background-color: var(--v-resBlue-base);
  }
  .v-navigation-drawer {
    ::v-deep .side-bar__menu--active {
      box-shadow: inset 4px 0px 0px 0px var(--v-resWhite-base);
      .v-icon{
        color: var(--v-resWhite-base) !important;
      }
      span {
        color: var(--v-resWhite-base) !important;
      }
    }
  }
  .v-list-item__action {
    margin-right: 0px !important;
  }
</style>
