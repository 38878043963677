/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings': {
    width: 20,
    height: 20,
    viewBox: '0 -12 512 512',
    data: '<path pid="0" d="M156 184c-26.074 0-48.305 16.723-56.559 40H0v40h99.441c8.254 23.277 30.485 40 56.559 40s48.305-16.723 56.559-40H512v-40H212.559c-8.254-23.277-30.485-40-56.559-40zm0 80c-11.027 0-20-8.973-20-20s8.973-20 20-20 20 8.973 20 20-8.973 20-20 20zM424.559 40C416.305 16.723 394.074 0 368 0s-48.305 16.723-56.559 40H0v40h311.441c8.254 23.277 30.485 40 56.559 40s48.305-16.723 56.559-40H512V40zM368 80c-11.027 0-20-8.973-20-20s8.973-20 20-20 20 8.973 20 20-8.973 20-20 20zm-38 288c-26.074 0-48.305 16.723-56.559 40H0v40h273.441c8.254 23.277 30.485 40 56.559 40s48.305-16.723 56.559-40H512v-40H386.559c-8.254-23.277-30.485-40-56.559-40zm0 80c-11.027 0-20-8.973-20-20s8.973-20 20-20 20 8.973 20 20-8.973 20-20 20zm0 0"/>'
  }
})
