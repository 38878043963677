<template>
  <base-dialog
    iconColor="var(--v-resWhite-base)"
    titleClass="dark-header"
    :title="title"
    :setWidth="1100"
    :maxWidth="1100"
    :showDialog="showDialog"
    @cancel="cancel"
    @save="save">
    <v-card flat class="container-card mx-4 mt-6">
      <v-row no-gutters>
        <v-col cols="6" class="pl-6 py-2">
          <div class="caption resGray-darken1--text font-weight-medium">Pool</div>
          <div class="caption resGray-darken5--text font-weight-medium">{{ this.leaseInfo.poolName }}</div>
        </v-col>
        <v-col cols="6" class="pl-6 py-2">
          <div class="caption resGray-darken1--text font-weight-medium">Lease</div>
          <div class="caption resGray-darken5--text font-weight-medium">{{ this.leaseInfo.leaseName }}</div>
        </v-col>
      </v-row>
    </v-card>
    <v-row no-gutters>
      <v-col cols="12">
        <data-table
          v-if="tableData"
          class="mx-4"
          :selectableRows="false"
          :tableData="tableData"
          :headers="tableHeaders"
          @textInputUpdated="leasePercentOverrideOrBaseYearUpdated"
          @isExcludedClicked="isExcludedClicked"
          @isOwnerExpenseClicked="isOwnerExpenseClicked">
        </data-table>
      </v-col>
    </v-row>
  </base-dialog>
</template>

<script>
import BaseDialog from '../../BaseComponents/BaseDialog'
import DataTable from '../../BaseComponents/Table'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    BaseDialog,
    DataTable
  },
  props: ['showDialog', 'leaseInfo'],
  data () {
    return {
    }
  },
  methods: {
    ...mapActions(['fetchData', 'changeData', 'fetchDropdown', 'initializeStoreItem']),
    cancel () {
      this.$emit('cancel')
    },
    save () {
      this.$validator.validateAll()
      if (this.errors.items.length > 0) {
        return
      }

      var settings = this.tableData
      for (var i = 0; i < settings.length; i++) {
        if (settings[i].isExcluded === true || settings[i].isOwnerExpense === true) {
          settings[i].leasePercentOverride = null
          settings[i].baseSetBaseYearAmount = 0
        }
      }

      var data = {
        property: this.leaseInfo.property,
        chargeCode: this.leaseInfo.chargeCode,
        lease: this.leaseInfo.lease,
        propertySpace: this.leaseInfo.propertySpace,
        currentCycleNumber: this.leaseInfo.cycleNumber,
        expenseAccountSettings: settings
      }
      this.changeData({ currentPage: 'Lease Expense Accounts', newData: data }).then(() => {
        this.$emit('save')
      }).catch(error => { console.log(error.response) })
    },
    getLeaseExpenseAccountSettings () {
      this.fetchData({
        newPage: 'Lease Expense Accounts',
        params: {
          property: this.leaseInfo.property,
          chargeCode: this.leaseInfo.chargeCode,
          lease: this.leaseInfo.lease,
          propertySpace: this.leaseInfo.propertySpace,
          cycleNumber: this.leaseInfo.cycleNumber
        }
      }).catch(error => { console.log(error.response) })
    },
    leasePercentOverrideOrBaseYearUpdated (e, item) {
      if (e > 0) {
        item.isOwnerExpense = false
        item.isExcluded = false
      }
    },
    isExcludedClicked (e) {
      if (e.isExcluded === true) {
        e.leasePercentOverride = null
        e.baseSetBaseYearAmount = 0
        e.isOwnerExpense = false
      }
    },
    isOwnerExpenseClicked (e) {
      if (e.isOwnerExpense === true) {
        e.leasePercentOverride = null
        e.baseSetBaseYearAmount = 0
        e.isExcluded = false
      }
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentDropdown']),
    title () {
      return 'Lease Expense Account Settings'
    },
    buttonText () {
      return 'Close'
    },
    buttonColor () {
      return 'var(--v-resBlue-darken1)'
    },
    tableData () {
      var items = this.currentData('Lease Expense Accounts')
      items.forEach(function (item) {
        item.readonly = false
      })
      return items
    },
    tableHeaders () {
      return this.currentHeaders('Lease Expense Accounts')
    }
  },
  created () {
    this.initializeStoreItem('Recovery Cycles')
    this.initializeStoreItem('Lease Expense Accounts')
    this.fetchDropdown({ newDropdown: 'Recovery Cycles', params: { PropertyId: this.leaseInfo.property, ChargeCode: this.leaseInfo.chargeCode } }).catch(error => { console.log(error.response) })
    this.getLeaseExpenseAccountSettings()
  }
}
</script>

<style lang="scss" scoped>
.container-card {
  border: 1px solid var(--v-resGray-lighten1);
  border-radius: 0;
}
::v-deep th.column {
  padding: 0px 16px !important;
}
</style>
