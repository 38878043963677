const state = {
  isSnackbar: false,
  snackbarMsg: '',
  snackbarSuccess: false
}

const getters = {
  currentSnackbar: (state) => state.isSnackbar,
  currentSnackbarMsg: (state) => state.snackbarMsg,
  currentSnackbarSuccess: (state) => state.snackbarSuccess
}

const actions = {
  changeSnackbar ({ commit }, newSnackbar) {
    commit('setSnackbar', newSnackbar)
  }
}

const mutations = {
  setSnackbar: (state, newSnackbar) => {
    state.isSnackbar = newSnackbar.isSnackbar
    state.snackbarMsg = newSnackbar.snackbarMsg
    state.snackbarSuccess = newSnackbar.snackbarSuccess
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
