<template>
  <v-card v-if="showWarning" class='warning-bar' color="var(--v-resOrange-lighten2)">
    <v-row no-gutters>
      <v-spacer></v-spacer>
      <v-icon color="var(--v-resGray-darken5)" class="mr-2 mt-1" small @click="closeWarning">close</v-icon>
    </v-row>
    <v-row no-gutters class="px-3 pb-4">
      <div>
        <v-icon color="var(--v-resGray-darken5)">info</v-icon>
        <span class="caption resGray-darken5--text ml-1 mr-4 mt-1 text-wrap">{{ message }}</span>
      </div>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: ['showWarning', 'message', 'warningClass', 'width'],
  data () {
    return {
    }
  },
  methods: {
    closeWarning () {
      this.$emit('closeWarning')
    }
  }
}
</script>

<style lang="scss" scoped>
  .warning-bar {
    border-radius: 6px;
  }
</style>
