<template>
  <div>
    <charge-codes-tabs>
      <router-view>
      </router-view>
    </charge-codes-tabs>
  </div>
</template>

<script>
import ChargeCodesTabs from './ChargeCodesTabs'

export default {
  data () {
    return {}
  },
  components: {
    ChargeCodesTabs
  }
}
</script>
