<template>
  <base-dialog
    :showDialog="showDialog"
    :title="'Edit Property details'"
    :maxWidth="500"
    @cancel="cancelEditProperty"
    @save="saveProperty">
    <v-row no-gutters>
      <v-col cols="12">
        <div class="caption ml-6 resGray-darken1--text">Property</div>
        <div class="caption ml-6">{{ editedProperty.propertyName }}</div>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-4">
      <v-col col="12">
        <div class="caption ml-6 resGray-darken1--text">Short name</div>
        <div class="caption ml-6">{{ editedProperty.shortName }}</div>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-4">
      <v-col cols="12">
        <drop-down
          :selectedValue="{ text: editedProperty.propertyTypeName }"
          :items="'Property Types'"
          :label="'Property type'"
          :multiple='false'
          width="180px"
          class="ml-6"
          @itemSelected="selectPropertyType">
        </drop-down>
        <drop-down
          :selectedValue="{ text: editedProperty.propertyTemplateName }"
          :items="'Property Templates'"
          :label="'Template'"
          :disabled="true"
          :multiple='false'
          width="180px"
          class="ml-6"
          @itemSelected="selectPropertyTemplate">
        </drop-down>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-6">
      <v-col cols="12">
        <drop-down
          :selectedValue="{ text: editedProperty.project }"
          :items="'Projects'"
          :label="'Project'"
          :multiple='false'
          width="180px"
          class="ml-6"
          @itemSelected="selectProject">
        </drop-down>
        <drop-down
          :selectedValue="{ text: editedProperty.vendor }"
          :items="'Vendors'"
          :label="'Vendor'"
          :multiple='false'
          width="180px"
          class="ml-6"
          @itemSelected="selectVendor">
        </drop-down>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-6">
      <v-switch
        class="ml-6 my-0 py-0"
        :disabled="true"
        v-model="editedProperty.isClosed">
      </v-switch>
      <span class="caption pt-1-plus resGray-darken1--text">Closed</span>
    </v-row>
    <v-row no-gutters class="mx-6">
      <v-col cols="12">
        <text-area label="Notes"
          :value="editedProperty.notes"
          :height="75"
          @textAreaUpdated="updateNotes">
        </text-area>
      </v-col>
    </v-row>
  </base-dialog>
</template>

<script>
import DropDown from '../../BaseComponents/DropDown'
import TextArea from '../../BaseComponents/TextArea'
import BaseDialog from '../../BaseComponents/BaseDialog'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: ['editedProperty', 'showDialog', 'editMode'],
  components: {
    TextArea,
    DropDown,
    BaseDialog
  },
  methods: {
    ...mapActions(['changeData', 'changeSelectedItem', 'fetchData']),
    selectPropertyType (val) {
      this.editedProperty.propertyType = val.value
      this.editedProperty.propertyTypeName = val.text
    },
    selectPropertyTemplate (val) {
      this.editedProperty.propertyTemplate = val.value
      this.editedProperty.propertyTemplateName = val.text
    },
    selectProject (val) {
      this.editedProperty.project = val.value
      this.editedProperty.project = val.text
    },
    selectVendor (val) {
      this.editedProperty.vendor = val.value
      this.editedProperty.vendor = val.text
    },
    updateNotes (val) {
      this.editedProperty.notes = val
    },
    saveProperty () {
      this.changeData({ currentPage: 'Properties', newData: this.editedProperty }).then(() => {
        this.fetchData({ newPage: 'Properties' }).then(() => {
          let property = this.getCurrentProperties().find(x => x.property === this.editedProperty.property)
          this.changeSelectedItem(property)
          this.$emit('save')
        })
      }).catch(error => { console.log(error.response) })
    },
    cancelEditProperty () {
      this.$emit('cancel', true)
    },
    getCurrentProperties () {
      return this.currentData('Properties')
    }
  },
  computed: {
    ...mapGetters(['currentData'])
  }
}
</script>
