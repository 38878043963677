<template>
  <div>
    <balances-tabs>
      <router-view>
      </router-view>
    </balances-tabs>
  </div>
</template>

<script>
import BalancesTabs from './BalancesTabs'

export default {
  data () {
    return {}
  },
  components: {
    BalancesTabs
  }
}
</script>
