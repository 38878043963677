<template>
  <v-layout>
    <v-flex>
      <month-range-button
        :direction="'left'"
        :active="true"
        @click.native="changeRangeUp">
      </month-range-button>
    </v-flex>
    <v-flex v-for="(date, index) in dateRange" :key="index">
      <month-picker-card
        :date="date"
        :selectedDate="selectedDate"
        :error="resolveError(date)"
        :disabled="!isMonthActive(date)"
        @click.native="selectMonth(date)">
      </month-picker-card>
    </v-flex>
    <v-flex>
      <month-range-button
        :direction="'right'"
        :active="rightButtonActive"
        @click.native="changeRangeDown">
      </month-range-button>
    </v-flex>
  </v-layout>
</template>

<script>
import moment from 'moment'
import MonthPickerCard from './MonthPickerCard'
import MonthRangeButton from '../../BaseComponents/MonthRangeButton'

export default {
  components: {
    MonthPickerCard,
    MonthRangeButton
  },
  props: ['errorMessages'],
  data () {
    return {
      startDate: moment().add(1, 'months').startOf('Month'),
      selectedDate: moment().add(1, 'months').startOf('Month')
    }
  },
  methods: {
    startOfMonth (date) {
      let monthStart = moment(date, 'MM/DD/YYYY').add(1, 'months').startOf('Month')
      return monthStart
    },
    selectMonth (e) {
      if (this.isMonthActive(e)) {
        this.selectedDate = e
        this.$emit('dateSelected', e)
      }
    },
    changeRangeUp () {
      this.startDate = moment(this.startDate.subtract(5, 'months')).startOf('Month')
    },
    changeRangeDown () {
      if (this.rightButtonActive) {
        this.startDate = moment(this.startDate.add(5, 'months')).startOf('Month')
      }
    },
    isMonthActive (date) {
      return date <= moment().add(1, 'months').startOf('Month')
    },
    resolveError (date) {
      if (this.errorMessages) {
        let error = this.errorMessages.find(e => {
          return moment(e.date).format('MM/DD/YYYY') === date.format('MM/DD/YYYY')
        })

        if (error) return error.message
      }
    }
  },
  computed: {
    dateRange () {
      let dateRange = []
      if (this.startDate) {
        for (let i = 0; i < 6; i++) {
          let dateToAdd = JSON.parse(JSON.stringify(this.startDate))
          let addDate = moment(dateToAdd).add(i, 'months').startOf('Month')
          dateRange.push(addDate)
        }
      }
      return dateRange
    },
    lastMonth () {
      return this.dateRange[this.dateRange.length - 1]
    },
    rightButtonActive () {
      return this.isMonthActive(this.lastMonth)
    }
  }
}
</script>
