<template>
  <base-dialog
    :showDialog="showDialog"
    :title="dialogTitle"
    @cancel="cancel"
    @save="save">
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="12">
          <text-input :value="editedItem.chargeCode"
            label="Charge Code"
            @textInputUpdated="updateChargeCode"
            width="150px"
            rules="required"
            textFormat='capitalize'
            :disabled="mode=='Edit'">
          </text-input>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-5">
        <v-col cols="12">
          <text-input :value="editedItem.name"
            label="Name"
            rules="required"
            @textInputUpdated="updateName"
            width="250px">
          </text-input>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-5">
        <v-col cols="12">
          <text-input :value="editedItem.shortName"
            label="Short Name"
            rules="required"
            @textInputUpdated="updateShortName"
            width="150px">
          </text-input>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-5">
        <v-col cols="12">
          <drop-down label="Charge Code Type"
            :selectedValue="{ text: editedItem.chargeTypeName }"
            :items="'Charge Code Types'"
            :multiple='false'
            rules="required"
            width='200px'
            @itemSelected="selectChargeCodeType">
          </drop-down>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-5">
        <v-col cols="12">
          <text-input :value="editedItem.displayColumn"
            label="Rent Roll Column Display"
            rules="required"
            @textInputUpdated="updateDisplayColumn"
            width="50px">
          </text-input>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-5">
        <v-col cols="12">
          <text-area label="Notes"
            :value="editedItem.notes"
            @textAreaUpdated="updateNotes">
          </text-area>
        </v-col>
      </v-row>
    </v-card-text>
  </base-dialog>
</template>

<script>
import BaseDialog from '../../BaseComponents/BaseDialog'
import TextInput from '../../BaseComponents/TextInput'
import TextArea from '../../BaseComponents/TextArea'
import DropDown from '../../BaseComponents/DropDown'

export default {
  components: {
    BaseDialog,
    TextInput,
    TextArea,
    DropDown
  },
  props: ['showDialog', 'editedItem', 'mode'],
  data () {
    return {
      editedChargeCode: {}
    }
  },
  methods: {
    cancel () {
      this.$emit('cancel', true)
    },
    save () {
      this.$eventHub.$emit('validate')
      setTimeout(() => {
        if (!this.errors.items.length > 0) {
          this.$emit('save', true)
        }
      }, 100)
    },
    updateChargeCode (val) {
      this.editedItem.chargeCode = val
    },
    updateName (val) {
      this.editedItem.name = val
    },
    updateShortName (val) {
      this.editedItem.shortName = val
    },
    selectChargeCodeType (val) {
      if (val !== null) {
        this.editedItem.chargeTypeName = val.text
        this.editedItem.chargeType = val.value
      } else {
        this.editedItem.chargeTypeName = null
        this.editedItem.chargeType = null
      }
    },
    updateDisplayColumn (val) {
      this.editedItem.displayColumn = val
    },
    updateNotes (val) {
      this.editedItem.notes = val
    }
  },
  computed: {
    dialogTitle () {
      return `${this.mode} Charge Code`
    }
  }
}
</script>
