<template>
  <imports-template
    ref='template'
    :canImport='canImport'
    :canExport='canExport'
    currentPage='GetImportLeaseChargesData'
    @downloadTemplate='downloadTemplate'
    @clearImportData='clearImportData'
    @saveUpload='saveUpload'
    @addNew='addNewItem'
    @submit='submit'>
    <template v-slot:header>
      <drop-down
        :selectedValue="{ text: selectedPropertyName }"
        :items="'Properties'"
        :label="'Property'"
        :multiple='false'
        width="200px"
        hideDetails
        rules="required"
        class="ml-2"
        :getParams="{ IncludeIncomplete: false, IncompleteOnly: false }"
        @itemSelected="selectProperty">
      </drop-down>
    </template>
    <template v-slot:body>
      <data-table
        :headers="headers"
        :tableData="getData"
        @itemDeleteRequested="deleteItem"
        @unlockEditing="unlockEdit"
        @dataChanged="save"
        @canceled="cancel"
        class="mx-6"
        :noData="'Please select an import template to upload.'">
      </data-table>
      <v-layout row mt-1 v-if='showWarning'>
        <v-icon small color="var(--v-resRed-base)">warning</v-icon>
        <span class='resRed-base--text'>
            {{ warningMessage }}
        </span>
      </v-layout>
    </template>
  </imports-template>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as fileDownload from '../../../../utility/fileDownloader'
import * as validation from '../../../../utility/validation'
import DataTable from '../../../BaseComponents/Table'
import DropDown from '../../../BaseComponents/DropDown'
import ImportsTemplate from './ImportsTemplate'
export default {
  components: {
    DataTable,
    DropDown,
    ImportsTemplate
  },
  data () {
    return {
      files: {},
      selectedProperty: undefined,
      selectedPropertyName: undefined,
      importDialog: false,
      confirmDialog: false
    }
  },
  methods: {
    ...mapActions(['fetchData', 'changeData', 'initializeStoreItem', 'fetchDropdown', 'addNew']),
    selectProperty (val) {
      if (val !== null) {
        this.selectedProperty = val.value
        this.selectedPropertyName = val.text
      } else {
        this.selectedProperty = null
        this.selectedPropertyName = null
      }
    },
    deleteItem (item) {
      this.$refs.template.deleteItem(item, this.getData)
    },
    unlockEdit (item) {
      this.$refs.template.unlockEdit(item, this.getData)
    },
    cancel (item) {
      this.$refs.template.cancelEdit(item, this.getData)
    },
    save (item) {
      this.$refs.template.save(item)
    },
    addNewItem () {
      this.addNew({
        currentPage: 'GetImportLeaseChargesData',
        newItem: {
          lease: { text: null, value: null },
          leaseSpace: { text: null, value: null },
          chargeCode: { text: null, value: null },
          leaseItem: null,
          detailItem: null,
          startDate: null,
          endDate: null,
          baseAmount: null,
          actionColumn: { delete: true, edit: true },
          readonly: false,
          new: true,
          errorToolTip: { hasIcon: false, tooltip: null, value: '' }
        }
      })
    },
    downloadTemplate () {
      this.fetchData({ newPage: 'ImportLeaseCharges', params: { Property: this.selectedProperty } }).then(() => {
        let file = this.currentData('ImportLeaseCharges')
        let bufferArray = fileDownload.convertArrayBuffer(file.fileContent)
        let fileName = file.fileName.split('.')[0]
        fileName += '-' + this.selectedPropertyName + '.xlsx'
        fileDownload.createDownloadFile(bufferArray, fileName)
      }).catch(error => { console.log(error.response) })
    },
    saveUpload (item) {
      this.fetchDropdown({ newDropdown: 'Leases', params: { LeaseStatus: 0, PropertyId: this.selectedProperty } }).catch(error => { console.log(error.response) })
      this.fetchDropdown({ newDropdown: 'Property Spaces', params: { Property: this.selectedProperty } }).catch(error => { console.log(error.response) })
      this.fetchDropdown({ newDropdown: 'Filtered Charge Codes', params: { Property: this.selectedProperty } }).then(() => {
        this.changeData({ currentPage: 'GetImportLeaseChargesData', newData: item, updateItem: {} }).then(() => {
          this.$refs.template.cancel()
        })
      }).catch(error => { console.log(error.response) })
    },
    submit () {
      this.changeData({ currentPage: 'ImportLeaseCharges', newData: { Property: this.selectedProperty, LeaseCharges: this.getData } }).then(() => {
        this.$refs.template.cancelImport()
      }).catch(error => { console.log(error.response) })
    },
    clearImportData () {
      this.initializeStoreItem('GetImportLeaseChargesData')
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentDropdown']),
    headers () {
      let items = JSON.parse(JSON.stringify(this.currentHeaders('GetImportLeaseChargesData')))
      let validation = items.splice(items.findIndex(x => x.value === 'errorToolTip'), 1)
      return validation.concat(items)
    },
    rawData () {
      return this.currentData('GetImportLeaseChargesData')
    },
    getData () {
      let items = this.rawData
      let leases = this.currentDropdown('Leases')
      let leasesLength = leases.length
      let spaces = this.currentDropdown('Property Spaces')
      let spacesLength = spaces.length
      let chargeCodes = this.currentDropdown('Filtered Charge Codes')
      let chargeCodesLength = chargeCodes.length
      items.forEach(item => {
        if (leasesLength > 0 && validation.hasValue(item.lease) && validation.hasValue(item.lease.text)) {
          item.lease = leases.find(x => {
            if (validation.hasValue(x.text)) {
              return x.text.toLowerCase() === item.lease.text.toLowerCase()
            }
          })
        }
        if (spacesLength > 0 && validation.hasValue(item.leaseSpace) && validation.hasValue(item.leaseSpace.text)) {
          item.leaseSpace = spaces.find(x => {
            if (validation.hasValue(x.text)) {
              return x.text.toLowerCase() === item.leaseSpace.text.toLowerCase()
            }
          })
        }
        if (chargeCodesLength > 0 && validation.hasValue(item.chargeCode) && validation.hasValue(item.chargeCode.text)) {
          item.chargeCode = chargeCodes.find(x => {
            if (validation.hasValue(x.text)) {
              return x.text.toLowerCase() === item.chargeCode.text.toLowerCase()
            }
          })
        }
        let errors = validation.validateValue(item, [
          { value: 'lease', required: true },
          { value: 'leaseSpace', required: true },
          { value: 'chargeCode', required: true },
          { value: 'leaseItem', required: true },
          { value: 'detailItem', required: true },
          { value: 'startDate', required: true },
          { value: 'endDate', required: true },
          { value: 'baseAmount', positive: true }
        ], items)
        validation.handleErrorToolTip(item, errors)
      })
      return items
    },
    canContinue () {
      if (this.selectedProperty !== null && this.selectedProperty !== undefined) {
        return true
      }
      return false
    },
    canExport () {
      if (this.selectedProperty !== null && this.selectedProperty !== undefined) {
        return true
      }
      return false
    },
    canImport () {
      if (this.getData.length > 0 && !this.showWarning) {
        return true
      }
      return false
    },
    warningMessage () {
      return this.getData.filter(x => x.errorToolTip.hasIcon === true).length + ' row(s) have errors. Please fix these to continue.'
    },
    showWarning () {
      return this.getData.filter(x => x.errorToolTip.hasIcon === true).length > 0
    }
  },
  beforeDestroy () {
    this.clearImportData()
  }
}
</script>
