<template>
  <div v-resize="onResize">

    <!-- Table -->
    <div>
      <v-data-table
        hide-default-footer
        :hide-default-header="true"
        must-sort
        v-model="selected"
        :header-props="{ sortIcon: null }"
        :headers="displayableHeaders"
        :item-key="tableKey"
        :items="formattedTableData"
        :options="pagination"
        :disable-pagination="noPagination"
        :class="['basic-table', showTotals ? 'basic-table--totals' : '', isMobile ? 'mobile': '']"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        @input="selectionChanged">

        <!-- Headers -->
        <template v-slot:header="{ props }" class="pl-0">
          <thead v-if="!isMobile">
          <tr>
            <th v-if="selectOneRow && selectedRowIcon" class="selected-row-icon pl-2 pr-0">
            </th>

            <th v-if="showSelectAllCol">
              <v-checkbox
                v-if="showSelectAll"
                v-model="allSelected"
                primary
                color="var(--v-resBlue-darken1)"
                hide-details
                :class="['resBlue-darken1--text', someSelected ? 'selected' : '']"
                :indeterminate="props.indeterminate"
                :ripple="false"
                @change="toggleAll">
              </v-checkbox>
            </th>

            <th v-for="header in props.headers"
              :key="header.value"
              role="columnheader"
              scope="col"
              :style="`width: ${header.width}px`"
              :class="['column', { 'sortable': header.sortable }, `text-${header.columnAlign}`, { 'pr-0': header.columnType==='icon'}]"
              @click="header.sortable ? changeSort(header.value) : ''">
              <span class="header__text" v-if="header.columnType!=='boolRadioGroup'">
                <div class="float-left" :class="[ header.sortable ? (sortBy != header.value ? 'sort-icon--no-sort': 'sort-icon--sort') : '', header.sortable ? 'mr-1': '' ]">
                  <svgicon
                    icon="up"
                    scale="1"
                    color="var(--v-resGray-darken4)"
                    class="sort-icon"
                    v-if="header.sortable && (sortBy != header.value || sortDesc === false)">
                  </svgicon>
                  <svgicon
                    icon="down"
                    scale="1"
                    color="var(--v-resGray-darken4)"
                    class="sort-icon"
                    v-if="header.sortable && (sortBy != header.value || sortDesc === true)">
                  </svgicon>
                </div>
                {{ $vuetify.breakpoint.lgAndUp ? header.displayName : (header.displayNameAbbreviation || header.displayName) }}
              </span>
              <span>
                <radio-group v-if="header.columnType==='boolRadioGroup'"
                  :options="[{value: true, label: header.radioGroupTrueValueLabel },{value: false, label: header.radioGroupFalseValueLabel },{value: null, label: header.radioGroupNullValueLabel }]"
                  :readonly="disableRadioGroupHeader"
                  :disabled="disableRadioGroupHeader"
                  @valueChosen="toggleRadioButtons(header.value, $event)">
                </radio-group>
              </span>
            </th>
          </tr>
          </thead>
        </template>

        <!-- Body -->
        <template v-slot:item="props">
          <!-- Standard View -->
          <tr v-if="!isMobile">
            <td v-if="selectOneRow && selectedRowIcon" class="selected-row-icon pl-2 pr-0">
              <v-icon
                v-if="props.item[selectedRowValue] && !selectRowActive"
                :size="20">
                {{ selectedRowIcon }}
              </v-icon>
              <v-radio-group
                v-if="selectRowActive"
                v-model="selectedRow"
                hide-details
                class="table-radio-button-group align-center"
                @change="selectUniqueRow">
                <v-radio
                  color="var(--v-resBlue-darken1)"
                  class="table-radio-button"
                  :ripple="false"
                  :value="props.item">
                </v-radio>
              </v-radio-group>
            </td>

            <td v-if="selectableRows" class="table-row__select-column pr-0 mr-0">
              <v-checkbox
                v-if="!props.item.notSelectable"
                v-model="props.isSelected"
                primary
                color="var(--v-resBlue-darken1)"
                :ripple="false"
                hide-details
                @change="props.select($event)">
              </v-checkbox>
            </td>

            <td v-for="header in displayableHeaders"
              :key="header.displayName"
              :class="[ header.actionClass, `text-${header.columnAlign}`,  header.columnType==='icon' ? 'pl-2 pr-0': '', header.columnType==='icon' && header.textFormat==='decimal' ? 'pl-0' : '' ]">
              <span>

                <!--In-line edit for text fields -->
                <text-input
                  v-focus="props.item.readonly"
                  v-model="props.item[header.value]"
                  v-if="props.item.readonly===false && header.isEditable===true && header.columnType==='text'"
                  :readonly="props.item.readonly===true"
                  :hideDetails="true"
                  :textFormat="header.textFormat"
                  :prefix="header.textFormat === 'currency' ? '$' : ''"
                  :width="header.width ? `${header.width}px` : '250px'"
                  :class="[props.item.readonly ? '': 'input-outline', header.textFormat === 'numeric' || header.textFormat==='decimal' ? 'edit-numeric': '']"
                  :placeholder="dynamicPlaceholder ? props.item.placeholder[header.value] : (placeHolder ? placeHolder : header.placeHolder)"
                  @textInputUpdated="textInputUpdated(props.item, header, $event )">
                </text-input>

                <!--In-line edit for dates with date picker -->
                <date-picker
                  v-if="(props.item.readonly===false && (!singleFieldBeingEdited || header.value === singleFieldBeingEdited)) && header.isEditable===true && header.columnType==='DatePicker'"
                  :initialDate="props.item[header.value]"
                  v-model="props.item[header.value]"
                  :defaultNull="header.defaultNull"
                  :hideDetails='true'
                  @dateSelected="props.item[header.value] = $event">
                </date-picker>

                <!--In-line edit for selections with dropdown -->
                <dropdown
                  v-if="props.item.readonly===false && header.isEditable===true && header.columnType==='Dropdown'"
                  :selectedValue="{ text: props.item[header.value] ? props.item[header.value].text : null }"
                  :multiple="false"
                  :width="`${header.width}px`"
                  :genericLabel="true"
                  :hideDetails="true"
                  :noRefresh='true'
                  :items="header.dropdownItems"
                  :customList="customDropdown"
                  :getParams="getDropdownParams(header)"
                  @itemSelected="dropdownUpdate(props.item, header, $event)"> <!-- "props.item[header.value] = $event"> -->
                </dropdown>

                <!--In-line edit for radio buttons for permission -->
                <permissions-radio
                  :key="pagination.page"
                  v-if="header.columnType==='Permissions'"
                  :selectedValue="props.item[header.value]"
                  @valueChosen="props.item[header.value] = $event">
                </permissions-radio>

                <!--In-line edit for boolean (nullable) radio buttons -->
                <radio-group
                  :key="pagination.page"
                  v-if="header.columnType==='boolRadioGroup'"
                  :selectedValue="props.item[header.value]"
                  :options="[{value: true, label: header.radioGroupTrueValueLabel },{value: false, label: header.radioGroupFalseValueLabel },{value: null, label: header.radioGroupNullValueLabel }]"
                  :readonly="props.item.readonly === true"
                  @valueChosen="props.item[header.value] = $event">
                </radio-group>

                <!-- tooltips -->
                <v-tooltip
                  right
                  :disabled="!header.showToolTip"
                  v-if="(header.columnType==='text' || header.columnType==='DatePicker' || header.columnType === 'Dropdown') && props.item.readonly === false && header.isEditable===false">
                  <template v-slot:activator="{ on }">
                    <v-hover>
                      <span v-on="on" v-html="textFormatter(header.columnType === 'Dropdown' ? (props.item[header.value] ? props.item[header.value].text : null) : props.item[header.value], header.textFormat, header.maxCharacters)" v-if="(header.columnType==='text' || header.columnType==='DatePicker' || header.columnType === 'Dropdown') && props.item.readonly === false && header.isEditable===false"
                        @click.stop="emitAction(header.clickAction, props.item)"
                        @hover.stop="hoverAction"
                        class="basic-table__text"
                        slot-scope="{ hover }"
                        :class="[ 'text-' + header.align, { 'clickable': header.clickAction }, hover ?  header.hoverColor + '--text' : header.textColor + '--text' ]"
                        :style="columnWidth(header.width, header.minWidth)">
                      </span>
                    </v-hover>
                  </template>
                    <span>
                    {{ resolveNullTooltip(header.nullTooltip, props.item[header.value]) || props.item[header.value] }}
                  </span>
                  <span v-if="header.showToolTip">
                    {{ props.item[header.value] }}
                  </span>
                </v-tooltip>

                <v-tooltip
                  bottom
                  :disabled="disableTooltip(header.columnType, props.item[header.value], header.maxCharacters, header.nullTooltip) && !header.showToolTip"
                  v-if="(header.columnType==='text' || header.columnType==='DatePicker' || header.columnType === 'Dropdown') && (props.item.readonly !== false || (header.isEditable === true && singleFieldBeingEdited && header.value !== singleFieldBeingEdited))">
                  <template v-slot:activator="{ on }">
                    <v-hover>
                      <div v-on="on" slot-scope="{ hover }">
                        <span
                          v-html="textFormatter(header.columnType === 'Dropdown' ? (props.item[header.value] ? props.item[header.value].text : null) : props.item[header.value], header.textFormat, header.maxCharacters)"
                          v-if="(header.columnType==='text' || header.columnType==='DatePicker' || header.columnType === 'Dropdown') && (props.item.readonly !== false || (header.isEditable === true && singleFieldBeingEdited && header.value !== singleFieldBeingEdited))"
                          @click.stop="emitAction(header.clickAction, props.item)"
                          class="basic-table__text"
                          :class="[ 'text-' + header.align, { 'clickable': header.clickAction }, hover ?  header.hoverColor + '--text' : header.textColor + '--text' ]"
                          :style="columnWidth(header.width, header.minWidth)">
                        </span>
                        <v-icon
                          v-if="hover && header.hoverIcon || props.item[header.hoverIconShowIfValue]"
                          :color="hover && !props.item[header.hoverIconShowIfValue] ? 'var(--v-resGray-darken3)': 'var(--v-resGreen-darken1)'"
                          :class="!props.item[header.hoverIconShowIfValue] ? 'clickable' : null"
                          small
                          @click.stop="emitAction(header.hoverIconAction, props.item)">
                          done
                        </v-icon>
                      </div>
                    </v-hover>
                  </template>
                  <span v-if="header.showToolTip">
                    {{ props.item[header.value] }}
                  </span>
                </v-tooltip>

                <!-- icons -->
                <v-icon v-if="header.columnType=='icon' && props.item[header.value]===true && props.item.readonly !== false"
                  :color="`var(--v-${header.textColor})`"
                  :small="header.iconSize==='small'"
                  @click.stop="emitAction(header.clickAction, props.item)">
                  {{ header.icon }}
                </v-icon>

                <v-icon v-if="header.columnType=='icon' && props.item[header.value]===true && props.item.readonly === false && header.isEditable===false"
                  :color="`var(--v-${header.textColor})`"
                  :small="header.iconSize==='small'"
                  @click.stop="emitAction(header.clickAction, props.item)">
                  {{ header.icon }}
                </v-icon>

                <v-hover>
                  <div slot-scope="{ hover }">
                    <svgicon
                      v-if="header.columnType=='customIcon' && props.item[header.value] !== ''"
                      :icon="props.item[header.value]"
                      scale="1"
                      :color="hover && header.hoverIconColor ? `var(--v-${header.hoverIconColor})` : ''"
                      :class="header.hoverIconColor ? 'clickable' : ''"
                      @click.stop="emitAction(header.clickAction, props.item)">
                    </svgicon>
                  </div>
                </v-hover>

                <span v-if="header.columnType=='calculatedField'" v-html="textFormatter(props.item[header.firstValue] - props.item[header.secondValue], header.textFormat)">
                </span>

                <!-- In-line edit for check box -->
                <v-checkbox
                  v-if="header.columnType=='icon' && props.item.readonly === false && header.isEditable===true"
                  height="20"
                  class="table-row__checkbox"
                  :ripple="false"
                  hide-details
                  v-model="props.item[header.value]"
                  color="var(--v-resBlue-darken1)"
                  @change="emitAction(header.clickAction, props.item)">
                </v-checkbox>

                <span v-if="header.columnType=='iconWithText'" :class="`text-${header.align}`">
                  <v-layout>
                    <v-icon class="mr-2">
                      {{ props.item[header.value] | resolveIconFilter(header.value) }}
                    </v-icon>
                    <span class="basic-table__text">{{ props.item[header.value] }}</span>
                  </v-layout >
                </span>

                <span v-if="header.columnType=='textWithIcon'" :class="`text-${header.align}`">
                  <span class="basic-table__text">{{ props.item[header.value].value }}</span>
                  <v-icon
                    small
                    class="mx-1"
                    v-if="props.item[header.value].showIcon">
                    {{ props.item[header.value].icon }}
                  </v-icon>
                </span>

                <span v-if="header.columnType=='iconWithTextAndTooltip'" :class="`text-${header.align}`">
                  <span class="mr-1">{{ props.item[header.value].value }}</span>
                  <v-tooltip top nudge-right="20" max-width=250>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" v-if="props.item[header.value].hasIcon" class="material-icons-outlined resBlue-darken1--text">
                        {{ header.icon }}
                      </v-icon>
                    </template>
                    <span>{{ props.item[header.value].tooltip }}</span>
                  </v-tooltip>
                </span>

                <!-- hidden columns menu and button -->
                <v-menu v-if="header.value=='more'" offset-y :nudge-left="95" :nudge-bottom="2" min-width="125">
                  <template v-slot:activator="{ on }">
                    <v-btn :ripple="false"
                      class="more-menu__button"
                      depressed
                      flat
                      v-on="on">
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="more-menu">
                    <v-list-item
                      class="more-menu__item"
                      v-for="header in hiddenHeaders"
                      :key="header.displayName"
                      @click.stop="showMore(header.displayName, props)">
                      <v-list-item-title>
                        <span class="more-menu__item-text">{{ header.displayName }}</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <!-- table button -->
                <round-button
                  height='20px'
                  buttonClass='ma-0'
                  :width="header.width + 'px'"
                  :depressed='true'
                  :flat='true'
                  roundness="10px"
                  v-if="header.columnType==='button'"
                  :borderColor="`var(--v-${props.item[header.value].color})`"
                  :buttonTextColor="`var(--v-${props.item[header.value].color})`"
                  :fontSize="'10px'"
                  :outline="true"
                  :backgroundColor="'var(--v-resWhite-base)'"
                  :buttonText="props.item[header.value].displayText"
                  @click="emitAction(header.clickAction, props.item)">
                </round-button>

                <!-- table status indicator -->
                <v-btn class="table-indicator pa-0 ma-0"
                  depressed
                  height="15px"
                  min-width="30px"
                  :ripple="false"
                  v-if="header.columnType=='statusIndicator' && props.item[header.value]"
                  :color="`var(--v-${props.item[header.value].color})`">
                  <span
                    class="text-center"
                    :class="`${props.item[header.value].textColor}--text`">
                    {{ props.item[header.value].value || '' }}
                  </span>
                </v-btn>

                <!-- table actions -->
                <span v-if="header.columnType=='Actions'">
                  <span v-if="props.item.readonly">
                    <span v-if="props.item[header.value].singleFieldEdits">
                      <span class="d-inline-block pt-1"
                        v-for="singleEdit in props.item[header.value].singleFieldEdits"
                        v-bind:key="singleEdit.editField">
                        <a
                          v-if="!props.item[singleEdit.editField] && (singleEdit.preRequisiteField === 'None' || props.item[singleEdit.preRequisiteField])"
                          class="d-inline-block pr-2"
                          :class="['single-action-link', singleEdit.cssClass]"
                          @click="editSingleField(singleEdit.editField, props.item)">
                          {{singleEdit.text}}
                        </a>
                      </span>
                    </span>
                    <v-hover v-if="props.item[header.value].edit">
                      <template slot-scope="{ hover }">
                        <v-icon
                          color="var(--v-resGray-lighten1)"
                          class="mr-2"
                          :class="[`${hover? 'resGray-darken4--text': ''}`, `${props.item[header.value].singleFieldEdits ? 'mb-0-plus': ''}`]"
                          @click.stop="unlockEdit(props.item)">
                          edit
                        </v-icon>
                      </template>
                    </v-hover>
                    <v-hover v-if="props.item[header.value].delete">
                      <template slot-scope="{ hover }">
                        <v-icon
                          color="var(--v-resGray-lighten1)"
                          :class="[`${hover? 'resRed-base--text': ''}`, `${props.item[header.value].singleFieldEdits ? 'mb-0-plus': ''}`]"
                          @click.stop="deleteItem(props.item)">
                          delete
                        </v-icon>
                      </template>
                    </v-hover>
                  </span>
                  <span v-if="!props.item.readonly">
                    <v-icon
                      v-if="!props.item[header.value].close"
                      class="pr-3"
                      small
                      color='var(--v-resRed-base)'
                      @click="cancel(props.item)">
                      close
                    </v-icon>
                    <v-icon
                      v-if="props.item[header.value].add"
                      class="pr-3"
                      small
                      color='var(--v-resBlue-darken1)'
                      @click="add(props.item)">
                      add_circle
                    </v-icon>
                    <v-icon
                      v-if="!props.item[header.value].add"
                      class="pr-3"
                      small
                      color='var(--v-resGreen-lighten1)'
                      @click="save(props.item)">
                      done
                    </v-icon>
                  </span>
                </span>
              </span>
            </td>
          </tr>

          <!-- Mobile View -->
          <tr v-else>
            <td>
              <span></span>
              <v-card class="mobile-row d-flex flex-wrap" outlined color="transparent">
                <template v-for="header in displayableHeaders">
                  <v-card
                    v-if="header.columnType !== 'Actions' && header.columnType !== 'statusIndicator'"
                    :key="header.displayName"
                    class="mobile-row-item py-1"
                    outlined
                    color="transparent">
                    <label class="mobile-row-item-label resGray-darken1--text text-no-wrap pl-1 pt-2">{{ header.displayName }}</label>
                    <div>
                      <label class="mobile-row-item-value pl-3">{{ textFormatter(header.columnType === 'Dropdown' ? (props.item[header.value] ? props.item[header.value].text : null) : props.item[header.value], header.textFormat, header.maxCharacters) }}</label>
                    </div>
                  </v-card>
                  <v-card
                    v-if="header.columnType === 'statusIndicator'"
                    :key="header.displayName"
                    class="mobile-row-item py-1"
                    outlined
                    color="transparent">
                    <label class="mobile-row-item-label resGray-darken1--text text-no-wrap pl-1 pt-2">{{ header.displayName }}</label>
                    <div>
                      <v-btn class="table-indicator pa-0 ma-0"
                        depressed
                        height="15px"
                        min-width="30px"
                        :ripple="false"
                        v-if="header.columnType=='statusIndicator' && props.item[header.value]"
                        :color="`var(--v-${props.item[header.value].color})`">
                        <span
                          class="xs-text-center"
                          :class="`${props.item[header.value].textColor}--text`">
                          {{ props.item[header.value].value || '' }}
                        </span>
                      </v-btn>
                    </div>
                  </v-card>
                </template>
              </v-card>
            </td>
          </tr>
        </template>

        <!-- No Data -->
        <template v-slot:no-data>
          <div v-if="loading">
            <div class="text-center">
              <v-progress-circular
                :size="15"
                :width="2"
                indeterminate
                color="var(--v-resBlue-darken1)">
              </v-progress-circular>
              <span>
                {{ loadingMessage }}
              </span>
              </div>
          </div>
          <div v-else class="basic-table__no-data pt-4">
            <span>{{ noData }}</span>
          </div>
        </template>

        <template v-if="expand">
          <v-card flat>
            <v-card-text>Peek-a-boo!</v-card-text>
          </v-card>
        </template>

        <!-- Totals -->
        <template v-slot:[`body.append`] v-if="showTotals">
          <tr>
          <td
            v-if="summableColumns.length > 0"
            :colspan="headers.length - summableColumns.length">
          </td>
          <td
            v-for="column in summableColumns"
            :key="column.value"
            class="text-right">
            <span v-if="column.columnType !='icon' && !column.alignAsTotalsOnly"
              class="resGray-darken1--text">
              Total:
            </span>
            <span
              v-if="column.columnType !='icon' && !column.alignAsTotalsOnly"
              :style="`display: inline-block; width: ${column.width}`"
              :class="[ 'text-right', column.textColor + '--text' ]">
              <strong>{{ sumColumn(column.value, 'decimal', true) }}</strong>
            </span>
          </td>

          <td
            v-if="dynamicColumns.length > 0"
            :colspan="headers.length - dynamicColumns.length">
            <span class="text-right totals-label">
              <strong>Totals ({{units}}):</strong>
            </span>
          </td>
          <td
            v-for="column in dynamicColumns"
            :key="column"
            class="text-center">
            <span
              style="display: inline-block; width: 75px;"
              class="text-right">
              <strong>{{ sumColumn(column) }}</strong>
            </span>
          </td>
          </tr>
        </template>
      </v-data-table>
    </div>

    <!-- Footer -->
    <v-container style="width: 100%">
      <v-row
        class="basic-table-footer"
        v-if="!noFooter && tableData && tableData.length > 0">
        <v-pagination
          v-show="pages > 1"
          v-model="pagination.page"
          color="var(--v-resGray-darken4)"
          :length="pages"
          class="pl-4 pt-4"
          :total-visible="isMobile ? 3: 10">
        </v-pagination>
        <v-spacer></v-spacer>
        <v-text-field
          v-if="!fixedPagination"
          class="basic-table-footer__rows-per-page"
          :class="isMobile ? 'mr-4' : ''"
          height="25"
          flat
          solo
          hide-details
          single-line
          placeholder="5"
          v-model.number="rowsPerPage">
        </v-text-field>
        <span v-if="!isMobile" class="pt-6 pr-6 pl-2">Rows  {{ startRow }} - {{ maxRow }} ({{ pagination.totalItems }} Total)</span>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import DatePicker from './DatePicker'
import TextInput from './TextInput'
import RoundButton from './RoundButton'
import Dropdown from './DropDown'
import PermissionsRadio from './PermissionsRadio'
import RadioGroup from './RadioGroup'
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import '../../compiled-icons'

export default {
  components: {
    RoundButton,
    DatePicker,
    TextInput,
    Dropdown,
    PermissionsRadio,
    RadioGroup
  },
  props: [
    'headers',
    'tableData',
    'noData',
    'selectableRows',
    'tableKey',
    'showEditButtons',
    'placeHolder',
    'searchString',
    'resetPage',
    'expand',
    'statusIndicators',
    'noFooter',
    'noHeader',
    'noPagination',
    'baseRowPerPage',
    'showTotals',
    'units',
    'dynamicPlaceholder',
    'customDropdown',
    'allowNullColumn',
    'customLoadingMessage',
    'fixedPagination',
    'selectedRows',
    'nullDisplayValue',
    'selectOneRow',
    'selectRowActive',
    'initialSelectedRow',
    'selectedRowIcon',
    'selectedRowValue',
    'tableDropdownParameters'
  ],
  data () {
    return {
      selected: this.selectedRows !== undefined && this.selectedRows !== null ? this.selectedRows : [],
      editing: false,
      singleFieldBeingEdited: '',
      sortBy: '',
      sortDesc: false,
      pagination: {
        page: 1,
        itemsPerPage: this.baseRowPerPage || 5,
        totalItems: 0
      },
      rowsPerPage: 5,
      allSelected: false,
      selectedRow: null
    }
  },
  methods: {
    ...mapActions(['updateRowsPerPage']),
    changeSort (column) {
      if (this.sortBy === column) {
        this.sortDesc = (this.sortDesc === false)
      } else {
        this.sortBy = column
        this.sortDesc = false
      }
    },
    emitAction (action, val) {
      if (action) {
        this.$emit(action, val)
      }
    },
    textFormatter (value, format, maxChars) {
      if (format === 'date' && value !== null) {
        return moment(value).format('MM/DD/YYYY')
      } else if (format === 'currency') {
        if (this.allowNullColumn && value === null) {
          return '-'
        }
        return value ? '$' + value.toLocaleString(undefined, { 'minimumFractionDigits': 2, 'maximumFractionDigits': 2 }) : '$0.00'
      } else if (format === 'decimal') {
        return value ? value.toLocaleString(undefined, { 'minimumFractionDigits': 2, 'maximumFractionDigits': 2 }) : '0.00'
      } else if (format === 'numeric') {
        return value ? value.toLocaleString() : this.nullDisplayValue || '-'
      } else if (format === 'bold') {
        return value.bold()
      } else if (format === 'text') {
        let max = maxChars === 0 ? 25 : maxChars
        if (value && value.length > max) {
          return `${value.substr(0, max)}...`
        } else {
          return value
        }
      } else {
        return value
      }
    },
    selectionChanged (e) {
      this.allSelected = e.length === this.tableData.filter(t => !t.notSelectable).length
      this.$emit('selectionChanged', e)
    },
    unlockEdit (item) {
      // this handles the client side of locking and unlocking editing
      this.$emit('unlockEditing', item)
    },
    editSingleField (fieldName, item) {
      this.singleFieldBeingEdited = fieldName
      this.unlockEdit(item)
    },
    cancel (item) {
      this.singleFieldBeingEdited = ''
      // if we can cancel a new item we want to remove it from the datatable, otherwise we just want to set the value to its previous state before any editing
      this.$emit('canceled', item)
    },
    save (item) {
      // check to see if the item was new, if it was we need to remove the new property
      this.$emit('dataChanged', item)
    },
    add (item) {
      this.$emit('inlineAdd', item)
    },
    deleteItem (item) {
      this.$emit('itemDeleteRequested', item)
    },
    showMore () {
    },
    resolveStatusColor (value) {
      if (this.statusIndicators && this.statusIndicators.length > 0) {
        let filteredStatusIndicator = this.statusIndicators.filter(s => {
          return s.value === value
        })

        if (filteredStatusIndicator && filteredStatusIndicator.length > 0) {
          return filteredStatusIndicator[0].color
        }
      }
    },
    columnWidth (width, minWidth) {
      let style = ''
      if (width && width !== 0) {
        style = style + `width: ${width}px;`
      }

      if (minWidth) {
        style = style + `min-width: ${minWidth}px;`
      }
      return style
    },
    sumColumn (column, format, preserveCase) {
      if (!format) format = 'numeric'
      return this.textFormatter(this.formattedTableData.reduce((prev, current) => {
        let key = column
        if (!preserveCase) {
          key = column.toLowerCase()
        }
        return prev + parseFloat((current[key] || 0))
      }, 0), format)
    },
    textInputUpdated (item, header, e) {
      item[header.value] = e
      this.$emit('textInputUpdated', e, item)
    },
    dropdownUpdate (item, header, e) {
      if (e !== null && e !== undefined) {
        item[header.value] = e
      } else {
        item[header.value].text = null
        item[header.value].value = null
      }
    },
    toggleAll () {
      if (this.allSelected) {
        this.selected = this.tableData.filter(t => !t.notSelectable).slice()
      } else {
        this.selected = []
      }
      this.$emit('selectionChanged', this.selected)
    },
    resetSelections () {
      this.allSelected = false
      this.selected = []
      this.$emit('selectionChanged', this.selected)
    },
    tooltipActive (value, max, tooltip) {
      let maxChars = 25
      if (max !== 0) maxChars = max
      if (value && value.length > maxChars) {
        return true
      } else {
        if (tooltip) {
          return true
        }
      }
      return false
    },
    resolveNullTooltip (nullTooltip, value) {
      if (!value) {
        return nullTooltip
      }
    },
    disableTooltip (columnType, value, maxCharacters, nullTooltip) {
      if (columnType !== 'text') return true
      if (!this.tooltipActive(value, maxCharacters, nullTooltip)) return true
      if (nullTooltip && value) return true
      return false
    },
    onResize () {
    //   if (window.innerWidth < 769) {
    //     this.isMobile = true
    //   } else {
    //     this.isMobile = false
    //   }
    },
    selectUniqueRow (e) {
      this.$emit('selectUniqueRow', e)
    },
    getDropdownParams (item) {
      let headerValue = item.value
      if (item.dropdownHasParameters) {
        let item = this.tableDropdownParameters.find(function (x) {
          return x.header === headerValue
        })
        return item.parameter
      }
    },
    toggleRadioButtons (columnName, e) {
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].readonly !== true) {
          this.tableData[i][columnName] = e
        }
      }
    }
  },
  computed: {
    ...mapGetters(['currentLoading', 'currentRowsPerPage']),
    isMobile () {
      return this.$vuetify.breakpoint.mobile
    },
    loading () {
      return this.currentLoading
    },
    loadingMessage () {
      return this.customLoadingMessage || 'Loading Information...'
    },
    displayableHeaders () {
      let displayableHeaders = this.headers.filter(h => {
        return !h.hidden && !Array.isArray(h)
      })

      if (this.headers.some(h => {
        return h.hidden
      })) {
        displayableHeaders = displayableHeaders.concat({
          text: '', value: 'more'
        })
      }
      return displayableHeaders
    },
    hiddenHeaders () {
      return this.headers.filter(h => {
        return h.hidden
      })
    },
    formattedTableData () {
      return this.tableData.map(d => {
        d.searchBlob = this.searchableColumns.reduce((val, col, idx) => {
          return idx === 0 ? d[col.value] : val + ' ' + d[col.value]
        }, '').trim()

        if (d.dynamicColumns) {
          let keys = Object.keys(d.dynamicColumns)

          for (let i = 0; i < keys.length; i++) {
            let key = keys[i]
            d[key.toLowerCase()] = d.dynamicColumns[key]
          }
        }

        return d
      }).filter(d => {
        return !this.searchString || this.searchableColumns.length === 0 || d.searchBlob.toLowerCase().includes(this.searchString.toLowerCase())
      })
    },
    dynamicColumns () {
      if (this.tableData.length > 0) {
        let row = this.tableData[0]
        if (row.dynamicColumns) {
          let keys = Object.keys(row.dynamicColumns)
          return keys
        }
      }

      return []
    },
    summableColumns () {
      return this.displayableHeaders.filter(c => {
        return c.showTotals && (c.textFormat === 'decimal' || c.textFormat === 'numeric' || c.alignAsTotalsOnly)
      })
    },
    searchableColumns () {
      let searchableColumns = this.headers.filter(h => {
        return h.searchable
      })
      return searchableColumns
    },
    calculatedRowsPerPage () {
      if ((!this.rowsPerPage && this.baseRowPerPage) || this.rowsPerPage < 5) {
        return 5
      } else {
        return this.rowsPerPage
      }
    },
    showSelectAllCol () {
      return this.selectableRows &&
      this.tableData && this.tableData.length > 0
    },
    showSelectAll () {
      return this.showSelectAllCol && this.formattedTableData.filter(d => {
        return !d.notSelectable
      }).length > 0
    },
    pages () {
      if (this.pagination.itemsPerPage == null ||
          this.pagination.totalItems == null) return 1
      return Math.ceil(this.pagination.totalItems / this.calculatedRowsPerPage)
    },
    startRow () {
      return this.pagination.page === 1
        ? 1 : ((this.pagination.page - 1) * this.calculatedRowsPerPage) + 1
    },
    maxRow () {
      if (this.pagination.itemsPerPage == null) return 0
      return this.pagination.page * this.pagination.itemsPerPage < this.pagination.totalItems
        ? this.pagination.page * this.pagination.itemsPerPage : this.pagination.totalItems
    },
    statusColorCodes () {
      let statusColorCodes = this.headers.filter(h => {
        return h.statusColorCode
      })

      if (statusColorCodes.length > 0) {
        let statusCodeString = statusColorCodes[0].statusColorCode
        let statusColorCodeObject = JSON.parse(statusCodeString)
        return statusColorCodeObject
      }

      return null
    },
    someSelected () {
      return this.selected.length > 0
    },
    disableRadioGroupHeader () {
      if (this.tableData.length > 0) {
        var hasEditableItems = (this.tableData.filter(r => {
          return r.readonly === false
        })).length >= 1

        return hasEditableItems === false
      }

      return true
    }
  },
  watch: {
    formattedTableData () {
      this.$nextTick(() => {
        this.pagination.totalItems = this.formattedTableData.length
      })
    },
    rowsPerPage () {
      this.pagination.page = 1
      this.pagination.itemsPerPage = this.calculatedRowsPerPage
      this.updateRowsPerPage(this.calculatedRowsPerPage)
    },
    resetPage: function () {
      this.pagination.page = 1
    },
    selectedRows: function (val) {
      if (val) {
        this.selected = val
      }
    },
    initialSelectedRow: function (val) {
      if (val) this.selectedRow = val
    }
  },
  filters: {
    resolveIconFilter (value) {
      if (value === 'Email') {
        return 'email'
      } else if (value === 'Telephone') {
        return 'phone'
      } else if (value === 'Letter') {
        return 'fa-envelope-open-text'
      } else if (value === 'Fax') {
        return 'fa-fax'
      } else if (value === 'System') {
        return 'fa-cog'
      } else if (value === 'Undefined') {
        return 'fa-question-circle'
      } else if (value === 'Meeting') {
        return 'fa-handshake'
      } else if (value === 'Charge' || value === 'Payment') {
        return 'fa-file-invoice'
      } else if (value === 'Text Message') {
        return 'textsms'
      } else {
        return 'more_horiz'
      }
    }
  },
  mounted () {
    this.$eventHub.$on('deselectAll', this.resetSelections)
    if (this.currentRowsPerPage) {
      this.rowsPerPage = this.currentRowsPerPage
    }
  },
  beforeDestroy () {
    this.$eventHub.$off('deselectAll')
  }
}
</script>

<style lang="scss" scoped>
  .single-action-link {
    white-space: nowrap;
    font-size: 12px;
  }
  span.text-right {
    padding-right: 0 !important;
  }

  .basic-table {
    background-color: var(--v-resWhite-base);
    border: 1px solid rgba(0,0,0,.12);

    // table header
    ::v-deep thead tr {
      background-color: var(--v-resGray-lighten3);
      height: 35px !important;
    }
    ::v-deep thead th {
      height: 35px !important;
      &.selected-row-icon {
        min-width: 0px;
        width: 20px !important;
        max-width: 20px !important;
      }
    }
    ::v-deep thead th:first-child .text-right {
      padding-right: 0 !important;
    }
    ::v-deep thead th.column.sortable {
      ::v-deep .v-icon {
        opacity: 1;
        color: var(--v-resGray-darken4) !important;
        font-size: 20px;
        vertical-align: middle;
      }
      &:hover {
        cursor: pointer;
      }
    }
    ::v-deep thead th .v-input--selection-controls__input .v-icon {
      font-size:18px;
    }
    ::v-deep thead th .selected .v-input--selection-controls__input .v-icon {
      caret-color: var(--v-resBlue-darken1) !important;
      color: var(--v-resBlue-darken1) !important;
    }

    // table body
    ::v-deep tbody td {
      height: 40px;
      &.selected-row-icon {
        min-width: 0px;
        width: 20px !important;
        max-width: 20px !important;
      }
    }
    .edit-numeric {
      ::v-deep .v-input__slot {
        border: solid 1px var(--v-resGray-darken2) !important;
        input {
          text-align: right;
        }
      }
    }
    ::v-deep input{
      padding-left: 5px;
      padding-top: 0;
      padding-bottom: 0;
    }
    ::v-deep .v-input--selection-controls__ripple {
      width: 20px !important;
    }
    ::v-deep .v-input--selection-controls__input {
      margin-right: 0px;
    }
    &__text {
      display: inline-block;
      font-size: 13px;
    }
    &__no-data {
      height: 50px;
      font-style: italic;
      font-weight: 300;
      color: var(--v-resGray-darken5);
      ::v-deep .v-input--selection-controls__input {
        padding-right: 0px!important;
        font-size: 15px;
      }
    }
    &--totals {
      border-bottom: none;
    }
    &-footer {
      background-color: var(--v-resWhite-base);
      border: 1px solid rgba(0,0,0,.12);
      border-top: none;
      height: 70px;
      font-size: 12px;
      ::v-deep .v-pagination__navigation{
        height:20px;
        width:20px !important;
        min-width:20px !important;
        box-shadow: none;
        margin-top: 0;
        margin-bottom: 0;
        &:focus{
          outline: none !important;
        }
        .v-icon {
          height:20px;
          width:20px !important;
          min-width:20px !important;
        }
      }
      ::v-deep .v-pagination__item{
        height:20px;
        width:20px !important;
        min-width:20px !important;
        box-shadow: none;
        border: 1px solid var(--v-resGray-lighten3);
        font-size: 12px;
      }
      &__rows-per-page{
        flex: none;
        ::v-deep .v-input__slot{
          min-height: auto;
          display: flex;
          width: 30px;
          border-radius: 6px;
          border-width: 1px;
          text-align: left;
          border: solid 1px var(--v-resGray-darken1) !important;
          padding: 0 !important;
        }
        ::v-deep input{
          margin-top: 0px;
          font-size: 13px;
          text-align: center;
        }
        ::v-deep .v-input__control {
          padding-top: 20px !important;
        }
      }
      &-header__select-all  {
        ::v-deep .v-icon {
          font-size: 18px !important;
        }
      }
    }
  }

  .v-input--selection-controls {
    &.v-input--checkbox {
      margin-top: 0 !important;
      padding-top: 0 !important;
    }
    &.permissions {
      margin-top: 0 !important;
    }
  }

  .v-datatable__actions__select {
    width: 50%;
    margin: 0px;
    justify-content: flex-start;
  }

  // mobile
  @media screen and (max-width: 768px) {
    .mobile table.v-table tr {
      max-width: 100%;
      position: relative;
      display: block;
    }
  }

  .mobile table.v-table tr td {
    display: flex;
    width: 100%;
    border-bottom: 1px solid var(--v-resGray-lighten5);
    height: auto;
    padding: 10px;
  }

  .mobile table.v-table tr td ul li:before {
    content: attr(data-label);
    text-align: left;
    display: block;
    color: var(--v-resGray-darken1);
  }

  .mobile .theme--light.v-table tbody tr:hover:not(.v-datatable__expand-row) {
    background: transparent;
  }

  .mobile-row {
    width: 100%;
    &-item {
      min-width: 50%;
      border: none !important;
      &-label {
        font-weight: 800;
      }
      &-value {
        font-weight: bold !important;
      }
    }
  }

  .v-list-item--two-line {
    min-height: 48px !important;
  }

  .flex-content {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .flex-item {
    padding: 5px;
    width: 50%;
    height: 40px;
    font-weight: bold;
  }

  .more-menu {
    .v-list {
      padding: 0px;
    }
    ::v-deep .v-list__tile {
      height: 30px;
    }
    &__item-text {
      font-size: 10.7px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--v-resBlue-base);
    }
    &__button {
      padding: 0;
      margin: 0;
      min-width: 24px;
      width: 24px;
      background-color: inherit !important;
      .v-btn .v-btn__content .v-icon {
        color: var(--v-resGray-darken1);
      }
    }
  }

  .table-row {
    &__action-icon {
      height: 100%;
      padding-top: 10px;
      width: 100px;
    }
    &__select-column {
      width: 20px;
      ::v-deep .v-icon {
        font-size: 18px;
      }
    }
    &__checkbox {
      align-items: center;
      justify-content: center;
      ::v-deep .v-input__slot {
        margin-bottom: 0px;
      }
    }
  }

  .clickable {
    color: var(--v-resBlue-darken1);
    cursor: pointer;
  }

  .input-outline {
    ::v-deep .v-input__slot{
      border: solid 1px var(--v-resBlue-darken1) !important;
    }
  }

  .header__text {
    color: var(--v-resGray-darken5);
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
  }

  .accent--text {
    color: var(--v-resBlue-darken1)  !important;
    caret-color: var(--v-resBlue-darken1)  !important;
  }

  .green--text {
    color: var(--v-resGreen-darken1) !important;
    caret-color: var(--v-resGreen-darken1) !important;
  }

  .table-indicator {
    display: inline-block;
    height: 15px;
    min-width: 30px;
    font-size: 11px;
    text-transform: capitalize;
    border-radius: 2px;
    padding: 0;
    margin: 0;
    cursor: default;
    ::v-deep .v-btn__content {
      padding-left: 8px;
      padding-right: 8px;
      margin:0;
      color: var(--v-resWhite-base);
    }
  }

  .sort-icon {
    display: block;
    margin: 2px;
  }

  .sort-icon--no-sort {
    display: inline-block;
    height: 15px;
    width: 15px;
  }

  .sort-icon--sort {
    height: 100%;
    display: inline-flex;
    align-items: center;
  }

  .totals-label {
    float: right;
  }

  .mobile-message {
    font-size: 18px;
  }

  .table-radio-button-group {
    ::v-deep .v-input__slot {
      margin-bottom: 0px;
    }
    .table-radio-button {
      ::v-deep .v-input--selection-controls__input {
        height: 20px;
        width: 20px;
      }
      ::v-deep .v-radio {
        margin-right: 0px;
      }
    }
  }

  .basic-table-footer__rows-per-page {
    margin-top: 0px;
    font-size: 13px;
    text-align: center;
  }
</style>
