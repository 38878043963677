<template>
  <v-container fluid class="resGray lighten-6">
    <v-app-bar flat height="36">
      <v-spacer></v-spacer>
      <round-button
        color="var(--v-resGray-darken3)"
        height="30px"
        :depressed="true"
        buttonText="+ New Property Group"
        backgroundColor="var(--v-resGreen-lighten1)"
        borderColor="var(--v-resGray-lighten1)"
        buttonClass="mr-2"
        @click="addPropertyGroup">
      </round-button>
    </v-app-bar>
    <v-row>
      <v-col cols="12" class="mt-3">
        <data-table
          class="mx-6"
          :headers="tableHeaders"
          :tableData="tableData"
          @unlockEditing="editPropertyGroup"
          @itemDeleteRequested="deletePropertyGroup">
        </data-table>
      </v-col>
    </v-row>
    <!-- Dialogs -->
    <property-groups-dialog
      v-if="selectedPropertyGroup"
      :showDialog="showPropertyGroupsDialog"
      :propertyGroup="selectedPropertyGroup"
      @cancel="hidePropertyGroupsDialog">
    </property-groups-dialog>
    <new-property-group-dialog
      v-if="showNewPropertyGroupDialog"
      :showDialog="showNewPropertyGroupDialog"
      @cancel="hideNewPropertyGroupDialog"
      @saveComplete="completeSavePropertyGroupDialog">
    </new-property-group-dialog>
    <delete-property-group-dialog
      v-if="showDeletePropertyGroupDialog"
      :showDialog="showDeletePropertyGroupDialog"
      :propertyGroup="selectedPropertyGroup"
      @cancel="hideDeletePropertyGroupDialog"
      @delete="completeDeletePropertyGroupDialog">
    </delete-property-group-dialog>
  </v-container>
</template>

<script>
import RoundButton from '../../BaseComponents/RoundButton'
import DataTable from '../../BaseComponents/Table'
import PropertyGroupsDialog from './Dialogs/PropertyGroups'
import NewPropertyGroupDialog from './Dialogs/NewPropertyGroup'
import DeletePropertyGroupDialog from './Dialogs/DeletePropertyGroup'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    RoundButton,
    DataTable,
    PropertyGroupsDialog,
    NewPropertyGroupDialog,
    DeletePropertyGroupDialog
  },
  data () {
    return {
      selectedUser: undefined,
      selectedUserName: undefined,
      toggleButtons: [
        { text: 'No Access', index: 0 },
        { text: 'View Access', index: 1 },
        { text: 'Full Access', index: 2 }
      ],
      dropDownLabel: 'Select User',
      showPropertyGroupsDialog: false,
      selectedPropertyGroup: null,
      showNewPropertyGroupDialog: false,
      showDeletePropertyGroupDialog: false
    }
  },
  methods: {
    ...mapActions(['fetchData', 'deleteData']),
    getPropertyGroups () {
      this.fetchData({ newPage: 'PropertyGroups' }).catch(error => { console.log(error.response) })
    },
    editPropertyGroup (e) {
      this.showPropertyGroupsDialog = true
      this.selectedPropertyGroup = e
    },
    hidePropertyGroupsDialog () {
      this.showPropertyGroupsDialog = false
      this.selectedPropertyGroup = null
    },
    addPropertyGroup () {
      this.showNewPropertyGroupDialog = true
    },
    hideNewPropertyGroupDialog () {
      this.showNewPropertyGroupDialog = false
    },
    completeSavePropertyGroupDialog () {
      this.hideNewPropertyGroupDialog()
      this.getPropertyGroups()
    },
    hideDeletePropertyGroupDialog () {
      this.showDeletePropertyGroupDialog = false
    },
    completeDeletePropertyGroupDialog (e) {
      this.deleteData({
        currentPage: 'PropertyGroups',
        removeItem: {
          propertyGroup: e.propertyGroup,
          rowVersion: e.rowVersion
        }
      }).then(() => {
        this.hideDeletePropertyGroupDialog()
      }).catch(error => { console.log(error.response) })
    },
    deletePropertyGroup (e) {
      this.selectedPropertyGroup = e
      this.showDeletePropertyGroupDialog = true
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders']),
    tableHeaders () {
      return this.currentHeaders('PropertyGroups')
    },
    tableData () {
      return this.currentData('PropertyGroups').filter(p => {
        return p.propertyGroup !== 'ALL'
      })
    }
  },
  created () {
    this.getPropertyGroups()
  }
}
</script>
