<template>
  <div>
    <v-layout align-center justify-space-between column fill-height>
      <div>
        <div>
          <v-btn :ripple="false" text @click="returnToGrid" color="var(--v-resWhite-base)" class="ml-0 pl-0">
            <v-btn small color="var(--v-resBlue-darken1)" icon><v-icon size="18" dark>arrow_back</v-icon></v-btn>
            <span class="text-none ml-0 back-button-text resBlue-darken1--text">Back to Results</span>
          </v-btn>
        </div>
        <v-card width="1000" height="150" class="mt-4">
          <v-flex>
            <v-layout>
              <v-flex>
                <v-flex mt-4>
                  <span class="title ml-6">Applied Credit Summary</span>
                </v-flex>
                <v-flex mt-4>
                  <v-layout>
                    <v-flex ml-6>
                      <div class="caption resGray-darken1--text mb-1">Application date</div>
                      <div class="caption resGray-darken5--text font-weight-medium">{{ appliedCreditDetails.depositDate | dateFilter(appliedCreditDetails.depositDate) }}</div>
                    </v-flex>
                    <v-flex>
                      <div class="caption resGray-darken1--text mb-1">Property</div>
                      <div class="caption resGray-darken5--text font-weight-medium">{{ appliedCreditDetails.bankDepositDetails[0].propertyName }}</div>
                    </v-flex>
                    <v-flex>
                      <div class="caption resGray-darken1--text mb-1">Cash Account</div>
                      <div class="caption resGray-darken5--text font-weight-medium">{{ appliedCreditDetails.bankAccountCode }} - {{ appliedCreditDetails.bankAccountName }}</div>
                    </v-flex>
                    <v-flex>
                      <div class="caption resGray-darken1--text mb-1">Description</div>
                      <div class="caption resGray-darken5--text font-weight-medium">{{ appliedCreditDetails.reference }}</div>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-card>
      </div>
      <div>
        <v-flex mt-6 mb-4>
          <span class="title">Distribution Details</span>
        </v-flex>
        <applied-credit-details
          :depositId="currentAppliedCredit"
          :appliedCreditDetails="appliedCreditDetails">
        </applied-credit-details>
        <v-layout>
          <v-spacer></v-spacer>
          <round-button
            v-if="canUnapply"
            height='30px'
            buttonClass='mt-4 ml-4 mr-0'
            width='140px'
            :depressed='true'
            :flat='true'
            :buttonTextColor="'var(--v-resWhite-base)'"
            :backgroundColor="'var(--v-resRed-base)'"
            :buttonText="'Unapply Credit'"
            @click="openUnapplyDialog">
          </round-button>
        </v-layout>
      </div>
    </v-layout>
    <!-- Dialogs -->
    <delete-dialog
      :showDialog="unapplyCreditConfirm"
      v-if="unapplyCreditConfirm"
      :title="'Are you sure?'"
      confirmButton="Yes, unapply credit"
      confirmButtonWidth="108px"
      @confirmDelete="confirmDialog"
      message="Please confirm that you would like to unapply this credit. Doing so will re-open all charges this credit is applied to."
      @cancelDelete="cancelDialog">
      <v-flex>
        <v-layout mt-2 ml-6>
          <v-flex>
            <div class="caption resGray-darken1--text mb-1">Application date</div>
            <div class="caption">{{ currentCreditToUnapply.applicationDate }}</div>
          </v-flex>
          <v-flex>
            <div class="caption resGray-darken1--text mb-1">Reference number</div>
            <div class="caption">{{ currentCreditToUnapply.payerCheckNumber }}</div>
          </v-flex>
        </v-layout>
      </v-flex>
    </delete-dialog>
  </div>
</template>

<script>
import AppliedCreditDetails from './AppliedCreditDetails'
import RoundButton from '../../../BaseComponents/RoundButton'
import DeleteDialog from '../../../BaseComponents/DeleteDialog'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {
  props: ['appliedCreditDetails', 'currentAppliedCredit'],
  components: {
    RoundButton,
    DeleteDialog,
    AppliedCreditDetails
  },
  data () {
    return {
      editedItem: {},
      appliedTotal: Number(0),
      unapplyCreditConfirm: false,
      currentCreditToUnapply: this.appliedCreditDetails.bankDepositDetails.find(x => x.depositId === this.currentAppliedCredit)
    }
  },
  methods: {
    ...mapActions(['fetchDropdown', 'changeData', 'fetchData', 'initializeStoreItem']),
    openUnapplyDialog () {
      this.currentCreditToUnapply.applicationDate = moment(this.currentCreditToUnapply.applicationDate).format('L')
      this.unapplyCreditConfirm = true
    },
    cancelDialog () {
      console.log(this.currentCreditToUnapply)
      this.unapplyCreditConfirm = false
    },
    confirmDialog () {
      this.changeData({ currentPage: 'UnapplyCredits',
        newData: {
          companyId: this.currentCreditToUnapply.companyId,
          bankAccountCode: this.currentCreditToUnapply.bankAccountCode,
          depositSlipId: this.currentCreditToUnapply.depositSlipId,
          bankDepositId: this.currentCreditToUnapply.depositId,
          leaseId: this.currentCreditToUnapply.payer,
          referenceNumber: this.currentCreditToUnapply.payerCheckNumber,
          applicationDate: this.currentCreditToUnapply.payerCheckDate,
          payerType: this.currentCreditToUnapply.payerType
        }
      }).then(() => {
        this.$emit('returnToGrid', this.currentCreditToUnapply)
        this.unapplyCreditConfirm = false
      }).catch(error => { console.log(error.response) })
    },
    returnToGrid () {
      this.$emit('returnToGrid')
    }
  },
  computed: {
    ...mapGetters(['currentDropdown', 'currentData', 'currentHeaders']),
    canUnapply () {
      return true
    }
  },
  filters: {
    dateFilter (val) {
      return moment(val).format('L')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
