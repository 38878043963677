<template>
  <v-tabs
    centered
    slider-color="var(--v-resBlue-base)"
    v-model="activeTab">
    <v-tab v-for="tab in tabs"
      :ripple="false"
      :key="tab.header"
      :name="tab.header"
      class="mx-1"
      @change="changeTabs(tab)">
      {{ tab.header }}
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  props: ['tabs'],
  data () {
    return {
      activeTab: null
    }
  },
  methods: {
    changeTabs (e) {
      this.$emit('changeTab', e)
    }
  },
  created () {
  }
}
</script>

<style lang="scss" scoped>
  .v-tabs {
    ::v-deep &__container {
      height: 30px;
    }

    &__div {
      font-size: 12px;
      min-width: 90px;
      text-transform: none;
    }

    &__div:hover {
      border-bottom: 2px solid var(--v-resBlue-base);
    }

    ::v-deep &__item {
      padding-bottom: 0;
      color: var(--v-resGray-darken4);
      &--active{
        color: var(--v-resBlue-darken2) !important;
      }
    }

    ::v-deep &__div--menu {
      margin-right: auto;
      text-transform: lowercase;
      border-bottom: none!important;
    }

    ::v-deep &__div--no-menu {
      margin-right: auto;
      text-transform: uppercase;
      border-bottom: none!important;
    }
  }

  a.menu-item {
    color: var(--v-resGray-darken4);
    font-size: 12px;
  }
</style>
