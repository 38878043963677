<template>
  <v-app>
    <loading v-if="!appLoaded"></loading>
    <div v-if="appLoaded">
      <toast></toast>
      <error-dialog></error-dialog>
      <side-bar></side-bar>
      <div v-if="$vuetify.breakpoint.smAndDown">
        <mobile-right-side-bar></mobile-right-side-bar>
        <mobile-left-side-bar></mobile-left-side-bar>
        <mobile-left-drawer></mobile-left-drawer>
      </div>
      <top-nav></top-nav>
      <v-main>
        <secondary-top-nav></secondary-top-nav>
        <router-view></router-view>
      </v-main>
      <commercial-reports
        v-if="showCommercialReportsDialog"
        :showDialog="showCommercialReportsDialog"
        @cancel="hideCommercialReports">
      </commercial-reports>
    </div>
    <inactivity-dialog
      v-if="showExpiringWarning"
      :showDialog="showExpiringWarning"
      :showExpired="!signedIn"
      @continueSession="continueSession"
      @logOff="signOut">
    </inactivity-dialog>
  </v-app>
</template>

<script>
import Loading from './components/BaseComponents/Loading'
import SideBar from './components/SideNavigation/SideBar'
import TopNav from './components/TopNavigation/TopNav'
import SecondaryTopNav from './components/SecondaryTopNavigation/SecondaryTopNav'
import MobileRightSideBar from './components/SideNavigation/MobileRightSideBar'
import MobileLeftSideBar from './components/SideNavigation/MobileLeftSideBar'
import MobileLeftDrawer from './components/SideNavigation/MobileLeftDrawer'
import InactivityDialog from './components/InactivityDialog'
import Toast from './components/BaseComponents/Toast'
import ErrorDialog from './components/BaseComponents/ErrorDialog'
import Mgr from './services/auth/'
import CommercialReports from './components/Reports/Commercial'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'App',
  data () {
    return {
      mgr: new Mgr(),
      signedIn: false,
      showCommercialReportsDialog: false,
      autoRefresh: true,
      showExpiringWarning: false,
      appLoaded: false
    }
  },
  components: {
    Loading,
    SideBar,
    TopNav,
    SecondaryTopNav,
    MobileRightSideBar,
    MobileLeftSideBar,
    MobileLeftDrawer,
    InactivityDialog,
    Toast,
    ErrorDialog,
    CommercialReports
  },
  computed: {
    ...mapGetters(['currentEnvironment', 'checkPermissions', 'currentUserIsAdmin']),
    hasPermission () {
      let route = this.$router.currentRoute
      return this.checkPermissions(route.name) || this.currentUserIsAdmin
    }
  },
  methods: {
    ...mapActions(['changeUser', 'fetchPermissions']),
    async signIn () {
      await this.mgr.getSignedIn()
        .then(signIn => {
          if (signIn) {
            this.mgr.getUser()
              .then(user => {
                if (user && !user.expired) {
                  this.handleUserChange(user)
                }
              })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    signOut () {
      this.mgr.signOut()
    },
    showCommercialReports () {
      this.showCommercialReportsDialog = true
    },
    hideCommercialReports () {
      this.showCommercialReportsDialog = false
    },
    promptTokenExpiring () {
      this.showExpiringWarning = true
    },
    promptTokenExpired () {
      this.showExpiringWarning = true
      this.signedIn = false
    },
    continueSession () {
      this.mgr.renewToken()
        .then(user => {
          this.handleUserChange(user)
        })
    },
    handleUserChange (user) {
      this.signedIn = true
      this.changeUser(user)
      this.showExpiringWarning = false
      this.fetchPermissions(user).then(() => {
        if (!this.hasPermission) {
          this.$router.push('accessdenied')
        }
        this.appLoaded = true
      })
    },
    goToResman (e) {
      this.mgr.getUser()
        .then(user => {
          let url = user.profile.resman_core_url
          if (url && url !== '') {
            if (e) {
              url += e
            }
            window.open(url, '_blank')
          }
        })
    },
    toggleTheme () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    }
  },
  async created () {
    this.$eventHub.$on('logout', this.signOut)
    this.$eventHub.$on('showReports', this.showCommercialReports)
    this.$eventHub.$on('goToResman', this.goToResman)
    /* this.$eventHub.$on('toggleTheme', this.toggleTheme) */

    window.addEventListener('tokenExpiring', () => {
      if (this.autoRefresh) {
        this.mgr.renewToken()
      } else {
        this.promptTokenExpiring()
      }
    })

    window.addEventListener('tokenExpired', () => {
      if (this.autoRefresh) {
        this.continueSession()
      } else {
        this.promptTokenExpired()
      }
    })
    setTimeout(() => {
      this.signIn()
    }, 1000)
  }
}
</script>

<style lang="scss">
  @import "./styles/app.scss";
  @import "./styles/fonts.scss";

  #app {
    background-color: var(--v-resGray-lighten6);
  }

  @media(device-width: 768px) and (device-height: 1024px) {
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0,0,0,.5);
      box-shadow: 0 0 1px rgba(255,255,255,.5);
      -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
    }
  }
</style>
