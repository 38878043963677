<template>
  <base-dialog
    :showDialog="showDialog"
    :title="dialogTitle"
    @cancel="cancelEditArea"
    @save="saveArea">
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="12">
          <drop-down label="Building"
            :selectedValue="{ text: editedArea.buildingName }"
            :items="'Property Buildings'"
            :multiple='false'
            width="200px"
            :getParams="{ PropertyId: currentSelectedItem.property }"
            @itemSelected="selectBuilding">
          </drop-down>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-5">
        <v-col cols="12">
          <drop-down label="Area Type"
            :selectedValue="{ text: editedArea.buildingAreaTypeName }"
            :items="'Area Types'"
            :multiple='false'
            width="200px"
            @itemSelected="selectAreaType">
          </drop-down>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-5">
        <v-col cols="12">
          <text-input :value="editedArea.areaName"
            label='Area'
            width='180px'
            @textInputUpdated="updateArea">
          </text-input>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-5">
        <v-col cols="12">
          <date-picker
            :label="'Available Date'"
            :defaultNull="true"
            :initialDate="editedArea.availableDate"
            @dateSelected="updateAvailableDate">
          </date-picker>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-5">
        <v-col cols="12">
          <text-area label="Notes"
            :value="editedArea.areaNotes"
            @textAreaUpdated="updateAreaNotes">
          </text-area>
        </v-col>
      </v-row>
    </v-card-text>
  </base-dialog>
</template>

<script>
import DropDown from '../../BaseComponents/DropDown'
import TextInput from '../../BaseComponents/TextInput'
import DatePicker from '../../BaseComponents/DatePicker'
import TextArea from '../../BaseComponents/TextArea'
import BaseDialog from '../../BaseComponents/BaseDialog'
import { mapGetters } from 'vuex'

export default {
  components: {
    TextInput,
    TextArea,
    DatePicker,
    BaseDialog,
    DropDown
  },
  props: ['editedArea', 'showDialog', 'editMode'],
  methods: {
    updateArea (val) {
      this.editedArea.areaName = val
    },
    updateAvailableDate (val) {
      this.editedArea.availableDate = val
    },
    selectAreaType (val) {
      if (val !== null) {
        this.editedArea.buildingAreaType = val.value
        this.editedArea.buildingAreaTypeName = val.text
      } else {
        this.editedArea.buildingAreaType = null
        this.editedArea.buildingAreaTypeName = null
      }
    },
    updateAreaNotes (val) {
      this.editedArea.areaNotes = val
    },
    selectBuilding (val) {
      if (val !== null) {
        this.editedArea.building = val.value
        this.editedArea.buildingName = val.text
      } else {
        this.editedArea.building = null
        this.editedArea.buildingName = null
      }
    },
    saveArea () {
      this.$emit('save', this.editedArea)
    },
    cancelEditArea () {
      this.$emit('cancel', true)
    }
  },
  computed: {
    ...mapGetters(['currentSelectedItem']),
    dialogTitle () {
      return `${this.editMode} Area`
    }
  }
}
</script>
