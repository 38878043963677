const state = {
  steps: [
    'Property', 'Spaces', 'ChargesChoose', 'ChargesNew', 'ChargesTemplate', 'Custom', 'Review'
  ],
  step: 'Property',
  review: false,
  Property: {
    buildingName: 'Building',
    areaName: 'Area'
  },
  Charges: [],
  propertyPropertyChanged: false,
  oneSpaceRequired: false
}

const getters = {
  currentProperty: (state) => state.Property,
  currentPropertyCharges: (state) => state.Charges,
  currentPropertyStep: (state) => state.step,
  currentPropertyReview: (state) => state.review,
  currentOneSpaceRequired: (state) => state.oneSpaceRequired
}

const actions = {
  changeProperty ({ commit }, newProperty) {
    commit('setProperty', newProperty)
  },
  changePropertyCharges ({ commit }, newPropertyCharges) {
    commit('setPropertyCharges', newPropertyCharges)
  },
  changePropertyStep ({ commit }, newPropertyStep) {
    commit('setPropertyStep', newPropertyStep)
  },
  changePropertyNextStep ({ commit }) {
    commit('setPropertyNextStep')
  },
  changePropertyPreviousStep ({ commit }) {
    commit('setPropertyPreviousStep')
  },
  clearPropertyWorkflow ({ commit }) {
    commit('setClearedPropertyWorkflow')
  },
  changePropertyPropertyChanged ({commit}, newStatus) {
    commit('setPropertyPropertyChanged', newStatus)
  },
  changePropertyReview ({commit}, newStatus) {
    commit('setPropertyReview', newStatus)
  },
  changeOneSpaceRequired ({commit}, newChange) {
    commit('setOneSpaceRequired', newChange)
  }
}

const mutations = {
  setPropertyNextStep: (state) => {
    var currentIndex = state.steps.indexOf(state.step)
    if (currentIndex < 6) {
      state.step = state.steps[currentIndex + 1]
    }
  },
  setPropertyPreviousStep: (state) => {
    var currentIndex = state.steps.indexOf(state.step)
    if (currentIndex > 0) {
      state.step = state.steps[currentIndex - 1]
    }
  },
  setPropertyStep: (state, newStep) => (state.step = newStep),
  setClearedPropertyWorkflow: (state) => {
    state.steps = [
      'Property', 'Spaces', 'ChargesChoose', 'ChargesNew', 'ChargesTemplate', 'Custom', 'Review'
    ]
    state.step = 'Property'
    state.review = false
    state.chargesType = ''
    state.Property = {
      buildingName: 'Building',
      areaName: 'Area'
    }
    state.Charges = []
    state.propertyPropertyChanged = false
    state.oneSpaceRequired = false
  },
  setProperty: (state, newProperty) => (state.Property = newProperty),
  setPropertyPropertyChanged: (state, newStatus) => (state.propertyPropertyChanged = newStatus),
  setPropertyCharges: (state, newCharges) => (state.Charges = newCharges),
  setPropertyReview: (state, newStatus) => (state.review = newStatus),
  setOneSpaceRequired: (state, newChange) => (state.oneSpaceRequired = newChange)
}

export default {
  state,
  getters,
  actions,
  mutations
}
