<template>
  <v-card flat width="1000">
    <data-table
      :headers="headers"
      :tableData="getData"
      @unlockEditing="getInfo"
      @itemDeleteRequested="deleteItem"
      :noData="'No information to display.'">
    </data-table>
    <delete-dialog
      :message="'Please confirm that you would like to delete this row. Completing this action will permanently delete this item.'"
      :showDialog="isDialog"
      @confirmDelete="confirmDialog"
      @cancelDelete="cancelDialog">
    </delete-dialog>
  </v-card>
</template>

<script>
import DataTable from '../../../BaseComponents/Table'
import DeleteDialog from '../../../BaseComponents/DeleteDialog'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    DataTable,
    DeleteDialog
  },
  data () {
    return {
      isDialog: false,
      itemHolder: {}
    }
  },
  methods: {
    ...mapActions(['fetchData', 'deleteData']),
    getInfo (val) {
      this.fetchData({ newPage: 'BankDepositSlipDraftsFullInfo', params: { CompanyId: val.company, BankAccountCode: val.bankAccount, BankDepositSlipId: val.depositSlipId } }).then(() => {
        this.$emit('EditDeposit', this.currentData('BankDepositSlipDraftsFullInfo'))
      }).catch(error => { console.log(error.response) })
    },
    deleteItem (val) {
      this.itemHolder = {
        CompanyId: val.company,
        BankAccountCode: val.bankAccount,
        BankDepositSlipId: val.depositSlipId
      }
      this.isDialog = true
    },
    confirmDialog () {
      this.deleteData({ currentPage: 'BankDepositSlipDrafts', removeItem: this.itemHolder }).then(() => {
        this.fetchData({ newPage: 'BankDepositSlipDrafts' }).then(() => {
          this.isDialog = false
          this.itemHolder = {}
        })
      }).catch(error => { console.log(error.response) })
    },
    cancelDialog () {
      this.isDialog = false
      this.itemHolder = {}
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders']),
    headers: {
      get () {
        return this.currentHeaders('BankDepositSlipDrafts')
      }
    },
    getData: {
      get () {
        return this.currentData('BankDepositSlipDrafts')
      }
    }
  },
  created () {
    this.fetchData({ newPage: 'BankDepositSlipDrafts' }).catch(error => { console.log(error.response) })
  }
}
</script>
