import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
  icons: {
    iconfont: 'mdi'
  },
  breakpoint: {
    mobileBreakpoint: 'sm'
  },
  theme: {
    options: {
      customProperties: true
    },
    dark: false,
    themes: {
      light: {
        primary: '#03739c',
        secondary: '#b0bec5',
        accent: '#ebfaff',
        error: '#d0021b',
        resBlack: {
          base: '#000000'
        },
        resWhite: {
          base: '#ffffff'
        },
        resBlue: {
          lighten4: '#ebfaff', // accent
          lighten3: '#6CD0F5',
          lighten2: '#35afdb',
          lighten1: '#1998C6',
          base: '#0891c2',
          darken1: '#03739c', // primary
          darken2: '#005675'
        },
        resGray: {
          lighten6: '#f2f4f6',
          lighten5: '#f5f5f5',
          lighten4: '#ededed',
          lighten3: '#e8e8e8', // ** svg/js files
          lighten2: '#d9d9d9',
          lighten1: '#d1d1d1', // ** svg/js files
          base: '#b0bec5', // secondary
          darken1: '#a6a6a6', // ** svg/js files
          darken2: '#979797',
          darken3: '#80868b',
          darken4: '#7a7a7a',
          darken5: '#4f4f4f'
        },
        resGreen: {
          lighten1: '#83ba1c', // ** svg/js files
          base: '#5f9400',
          darken1: '#476e00'
        },
        resOrange: {
          lighten2: '#ffd17d',
          lighten1: '#ffc356',
          base: '#e68609'
        },
        resRed: {
          lighten1: '#ffd0c5',
          base: '#d0021b' // error
        }
      }
      // dark: {
      //   primary: '#6CD0F5', // '#03739c',
      //   secondary: '#d1d1d1', // '#b0bec5',
      //   accent: '#005675', // '#ebfaff',
      //   error: '#ffd0c5', // '#d0021b',
      //   resBlack: {
      //     base: '#ffffff' // '#000000'
      //   },
      //   resWhite: {
      //     base: '#000000' // '#ffffff'
      //   },
      //   resBlue: {
      //     lighten4: '#005675', // '#ebfaff', // accent
      //     lighten3: '#03739c', // '#6CD0F5',
      //     lighten2: '#0891c2', // '#35afdb',
      //     lighten1: '#1998C6', // '#1998C6',
      //     base: '#35afdb', // '#0891c2',
      //     darken1: '#6CD0F5', // '#03739c', // primary
      //     darken2: '#ebfaff' // '#005675'
      //   },
      //   resGray: {
      //     lighten6: '#4f4f4f', // '#f2f4f6',
      //     lighten5: '#7a7a7a', // '#f5f5f5',
      //     lighten4: '#80868b', // '#ededed',
      //     lighten3: '#979797', // '#e8e8e8', // ** svg/js files
      //     lighten2: '#a6a6a6', // '#d9d9d9',
      //     lighten1: '#b0bec5', // '#d1d1d1', // ** svg/js files
      //     base: '#d1d1d1', // '#b0bec5', // secondary
      //     darken1: '#d9d9d9', // '#a6a6a6', // ** svg/js files
      //     darken2: '#e8e8e8', // '#979797',
      //     darken3: '#ededed', // '#80868b',
      //     darken4: '#f5f5f5', // '#7a7a7a',
      //     darken5: '#f2f4f6' // '#4f4f4f'
      //   },
      //   resGreen: {
      //     lighten1: '#476e00', // '#83ba1c', // ** svg/js files
      //     base: '#5f9400', // '#5f9400',
      //     darken1: '#83ba1c' // '#476e00'
      //   },
      //   resOrange: {
      //     lighten2: '#e68609', // '#ffd17d',
      //     lighten1: '#ffc356', // '#ffc356',
      //     base: '#ffd17d' // '#e68609'
      //   },
      //   resRed: {
      //     lighten1: '#d0021b', // '#ffd0c5',
      //     base: '#ffd0c5' // '#d0021b' // error
      //   }
      // }
    }
  }
}

export default new Vuetify(opts)
