<template>
  <v-container fluid class="resGray lighten-6">
    <v-app-bar flat height="36">
      <v-spacer></v-spacer>
      <span class="mr-2">
        As of:
      </span>
      <date-picker
        :initialDate="asOfDate"
        @dateSelected="setAsOfDate"
        class="mr-3">
      </date-picker>
      <search
        class="mb-0-plus mr-3"
        placeholder='Search Spaces'
        @searchStringUpdated="updateSearchString">
      </search>
      <round-button
        color='var(--v-resGray-darken3)'
        height='30px'
        width='105px'
        :dark='true'
        :depressed='true'
        buttonText='+ Add Space'
        backgroundColor='var(--v-resGreen-lighten1)'
        borderColor='var(--v-resGray-lighten1)'
        buttonClass='mr-2'
        @click="addSpace()">
      </round-button>
    </v-app-bar>
    <v-row>
      <v-col cols="12" class="mt-3">
        <data-table
          class="mx-6"
          :headers="headers"
          :tableData="getData"
          :searchString="searchString"
          @ClickAttachments="openAttachments"
          @unlockEditing="unlockEditing"
          @itemDeleteRequested="deleteItem"
          @sendLeaseOverview="sendLeaseOverview">
        </data-table>
      </v-col>
    </v-row>
    <!-- Dialogs -->
    <spaces-dialog
      v-if="propertySpaceDialog"
      :editedItem="editedSpace"
      :showDialog="propertySpaceDialog"
      :mode="mode"
      @cancel="cancel"
      @save="saveSpace">
    </spaces-dialog>
    <delete-dialog
      :showDialog="deleteDialog"
      @confirmDelete="confirmDeleteDialog"
      @cancelDelete="cancelDeleteDialog">
    </delete-dialog>
    <attachments
      v-if="attachmentsDialogOpen"
      :showDialog="attachmentsDialogOpen"
      @cancel="closeAttachments"
      :primaryKey="attachmentSpaceId"
      applicationRole="SPAC">
    </attachments>
  </v-container>
</template>

<script>
import Attachments from './Dialogs/Attachments'
import DataTable from '../BaseComponents/Table'
import RoundButton from '../BaseComponents/RoundButton'
import DatePicker from '../BaseComponents/DatePicker'
import Search from '../BaseComponents/Search'
import moment from 'moment'
import SpacesDialog from './Dialogs/Spaces'
import DeleteDialog from '../BaseComponents/DeleteDialog'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    Attachments,
    DataTable,
    RoundButton,
    DatePicker,
    Search,
    DeleteDialog,
    SpacesDialog
  },
  data () {
    return {
      searchString: null,
      mode: '',
      editedSpace: {},
      itemToDelete: {},
      deleteDialog: false,
      propertySpaceDialog: false,
      attachmentsDialogOpen: false,
      attachmentSpaceId: 0,
      asOfDate: moment(new Date())
    }
  },
  methods: {
    ...mapActions(['changeLoading', 'fetchData', 'changeTab', 'changeSelectedItem', 'changeData', 'deleteData', 'initializeStoreItem']),
    addSpace () {
      this.mode = 'Add'
      this.editedSpace.property = this.currentSelectedItem.property
      this.propertySpaceDialog = true
    },
    setAsOfDate (e) {
      this.asOfDate = moment(e).format('YYYY-MM-DD')
      this.fetchData({newPage: 'PropertySpaces', params: { Property: this.currentSelectedItem.property, AsOfDate: this.asOfDate }}).catch(error => { console.log(error.response) })
    },
    updateSearchString (val) {
      this.searchString = val
    },
    sendLeaseOverview (val) {
      this.fetchData({newPage: 'SingleLease', params: { LeaseId: val.lease }}).then(() => {
        this.changeSelectedItem(this.currentData('SingleLease'))
        this.changeTab({ header: 'Overview', index: 0, route: 'LeasesOverview' })
        this.$router.push({ name: 'LeasesOverview' })
      }).catch(error => { console.log(error.response) })
    },
    saveSpace (val) {
      this.changeData({ currentPage: 'PropertySpaces', newData: val }).then(() => {
        this.fetchData({ newPage: 'PropertySpaces', params: { Property: this.currentSelectedItem.property, AsOfDate: this.asOfDate } }).then(() => {
          this.editedSpace = {}
          this.propertySpaceDialog = false
        })
      }).catch(error => { console.log(error.response) })
    },
    deleteItem (item) {
      this.itemToDelete = item
      this.deleteDialog = true
    },
    cancel () {
      this.editedSpace = {}
      this.propertySpaceDialog = false
    },
    unlockEditing (val) {
      this.mode = 'Edit'
      this.editedSpace = JSON.parse(JSON.stringify(val))
      this.propertySpaceDialog = true
    },
    cancelDeleteDialog () {
      this.deleteDialog = false
      this.itemToDelete = null
    },
    confirmDeleteDialog () {
      this.deleteDialog = false
      this.deleteData({ currentPage: 'PropertySpaces', removeItem: this.itemToDelete }).catch(error => { console.log(error.response) })
    },
    openAttachments (val) {
      this.attachmentSpaceId = val.space
      this.attachmentsDialogOpen = true
    },
    closeAttachments () {
      this.attachmentsDialogOpen = false
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentSelectedItem']),
    getData () {
      return this.currentData('PropertySpaces')
    },
    headers () {
      return this.currentHeaders('PropertySpaces')
    }
  },
  created () {
    this.changeTab({ header: 'Spaces', index: 1, route: 'PropertiesSpaces' })
  },
  beforeDestroy () {
    this.initializeStoreItem('PropertySpaces')
  }
}
</script>
