<template>
  <base-dialog
    :showDialog="showDialog"
    :title="'Payment details'"
    :titleClass="'dark-header'"
    :hideClose="true"
    :maxWidth="600"
    :saveButtonOnly="true"
    :saveButtonText="'Close'"
    :saveButtonColor="'var(--v-resBlue-darken1)'"
    @save="closeDialog">
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="3">
          <div>Check #</div>
          <div><b>{{ payment.checkNumber }}</b></div>
        </v-col>
        <v-col cols="3">
          <div>Date</div>
          <div><b>{{ payment.date }}</b></div>
        </v-col>
        <v-col cols="3">
          <div>Amount</div>
          <div><b>${{ formatCurrency(payment.amount) }}</b></div>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-3">
        <v-col cols="12">
          <data-table
            :headers="headers"
            :tableData="paymentHistory"
            :allowNullColumn="true"
            :noData="'No information to display.'">
          </data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </base-dialog>
</template>

<script>
import BaseDialog from '../../BaseComponents/BaseDialog'
import DataTable from '../../BaseComponents/Table'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    BaseDialog,
    DataTable
  },
  props: ['showDialog', 'payment'],
  data () {
    return {}
  },
  methods: {
    ...mapActions(['fetchData']),
    closeDialog () {
      this.$emit('closeDialog')
    },
    getChargeCodeHistory () {
      this.fetchData({
        newPage: 'LeaseChargeActivityPaymentDetails',
        params: {
          property: this.currentSelectedItem.property,
          lease: this.currentSelectedItem.lease,
          bankDepositId: this.payment.bankDepositId
        }
      }).catch(error => { console.log(error.response) })
    },
    formatCurrency (val) {
      return parseFloat(val).toFixed(2)
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentSelectedItem']),
    paymentHistory () {
      return this.currentData('LeaseChargeActivityPaymentDetails')
    },
    headers () {
      return this.currentHeaders('LeaseChargeActivityPaymentDetails')
    }
  },
  created () {
    this.getChargeCodeHistory()
  }
}
</script>
