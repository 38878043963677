<template>
  <base-dialog
    :showDialog="showDialog"
    :title="dialogTitle"
    :titleClass="'dark-header'"
    :iconColor="'var(--v-resWhite-base)'"
    :maxWidth="600"
    :saveButtonText="'Undo'"
    :saveDisabled="saveDisabled"
    :saveButtonColor="'var(--v-resRed-base)'"
    :cancelButtonTextColor="'var(--v-resBlue-darken1)'"
    :cancelButtonColor="'var(--v-resBlue-darken1)'"
    @cancel="close"
    @save="save">
    <v-card-text>
      <v-row no-gutters>
        <v-col>
          <data-table
            :selectableRows="true"
            :tableKey="'lease'"
            :headers="headers"
            :tableData="tableData"
            @selectionChanged="updateSelectedCharges">
          </data-table>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-spacer></v-spacer>
        <span class="mr-2">({{leasesSelected}}) Leases selected</span>
      </v-row>
    </v-card-text>
  </base-dialog>
</template>

<script>
import BaseDialog from '../../../BaseComponents/BaseDialog'
import DataTable from '../../../BaseComponents/Table'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    BaseDialog,
    DataTable
  },
  props: ['showDialog', 'propertyName', 'property', 'chargeDate'],
  data () {
    return {
      selectedCharges: []
    }
  },
  methods: {
    ...mapActions(['deleteData', 'changeLoading']),
    updateSelectedCharges (e) {
      this.selectedCharges = e
    },
    close () {
      this.$emit('closeUndoDialog', true)
    },
    save () {
      this.deleteData({
        currentPage: 'Monthly Recurring Charges',
        removeItem: {
          PropertyId: this.property,
          Leases: this.selectedCharges.map(c => c.lease),
          ChargeDate: this.chargeDate
        }
      }).then(() => {
        this.selectedCharges = []
        this.changeLoading(false)
        this.$emit('undoDialogComplete', true)
      }).catch(error => {
        console.log(error.response)
        this.$emit('undoDialogComplete', true) // still want to reload
      })
      this.$emit('saveUndoDialog', true)
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders']),
    dialogTitle () {
      return `Undo Charges for ${this.propertyName}`
    },
    headers () {
      return this.currentHeaders('Leases With Charges').filter(h => {
        return h.displayName !== 'Charges'
      })
    },
    tableData () {
      return this.currentData('Leases With Charges')
    },
    saveDisabled () {
      return this.selectedCharges.length === 0
    },
    leasesSelected () {
      return this.selectedCharges.length
    }
  }
}
</script>
