<template>
  <div>
    <overview-card
      v-if="showStackingPlan"
      :title="'Stacking Diagram'"
      :noEdit="true"
      class="pb-4 pr-2">
        <v-row>
          <v-col offset="1">
            <span>As of:</span>
            <date-picker
              @dateSelected="refreshDiagram"
              :hideDetails="true">
            </date-picker>
          </v-col>
          <v-spacer></v-spacer>
          <v-col align="right">
            <div v-for="legend in legendData" :key="legend.value" class="legend-container">
              <div class="ml-4 mr-1 mt-1 legend-item" :style="`background-color: var(--v-${legend.color})`"></div>
              <span class="caption">
                {{ legend.value }}
              </span>
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-0" v-for="row in chartData" :key="row.area">
          <v-col cols="1" class="text-right mt-3">
            <span>{{ row.area }}</span>
          </v-col>
          <v-col cols="11">
            <stacking-block-card
              v-for="item in row.blocks"
              :key="item.space"
              :area="item.width"
              :width="100*item.width/row.totalWidth"
              :color="`${item.statusCode.color}`"
              :expireDate="item.leaseExpiration"
              :leaseName="item.leaseName"
              :leaseId="item.leaseId"
              :space="item.space"
              :transition='true'
              :textColor='item.statusCode.textColor'>
            </stacking-block-card>
          </v-col>
        </v-row>
    </overview-card>
    <warning-bar
      message="Stacking Diagram disabled for this property due to missing square foot measurements or at least one area having greater than 15 spaces.
            To enable the Stacking Diagram, please ensure square foot measurements are entered and/or reconfigure areas and spaces."
      @closeWarning="closeWarning"
      :showWarning="noStackingPlan">
    </warning-bar>
  </div>
</template>

<script>
import DatePicker from '../BaseComponents/DatePicker'
import OverviewCard from '../BaseComponents/OverviewCard'
import StackingBlockCard from '../BaseComponents/StackingBlockCard'
import WarningBar from '../BaseComponents/WarningBar'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {
  components: {
    DatePicker,
    OverviewCard,
    StackingBlockCard,
    WarningBar
  },
  data () {
    return {
      asOfDate: moment().format(),
      noStackingPlan: false,
      showStackingPlan: true
    }
  },
  methods: {
    ...mapActions(['fetchData', 'initializeStoreItem']),
    async getChartData () {
      this.fetchData({
        newPage: 'Property Stacking Chart',
        params: {
          propertyId: this.currentSelectedItem.property,
          asOfDate: this.asOfDate
        }
      }).then(() => {
        if (this.chartData.length === 0) {
          this.noStackingPlan = true
          this.showStackingPlan = false
        }
      }).catch(error => { console.log(error.response) })
    },
    refreshDiagram (e) {
      this.asOfDate = e
      this.getChartData()
    },
    closeWarning () {
      this.noStackingPlan = false
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentSelectedItem']),
    chartData () {
      let items = this.currentData('Property Stacking Chart').filter(x => x.blocks.length > 15)
      if (items.length > 0) {
        return []
      }
      return this.currentData('Property Stacking Chart')
    },
    legendData () {
      return this.chartData.map(x => {
        return x.blocks
      }).flat().map(x => {
        return x.statusCode
      }).reduce((unique, item) => {
        let findInArray = unique.find(x => {
          return x.value === item.value
        })

        if (!findInArray) {
          unique.push(item)
        }

        return unique
      }, [])
    }
  },
  created () {
    this.getChartData()
  },
  beforeDestroy () {
    this.initializeStoreItem('Property Stacking Chart')
  }
}
</script>

<style lang="scss" scoped>
.legend-container {
  display: inline-flex;
  vertical-align: bottom;
  padding-top: 4px;
  padding-right: 12px;
}
.legend-item {
  display: inline-block;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  margin-top: 3px !important;
}
.caption {
  margin-top: 2px;
}
.subheading {
  vertical-align: top;
}
</style>
