<template>
  <base-dialog
    iconColor="var(--v-resWhite-base)"
    titleClass="dark-header"
    :saveButtonColor="saveButtonColor"
    :title="title"
    :saveButtonOnly="this.accountInfo.isLocked"
    :saveButtonText="saveButtonText"
    :cancelButtonColor="'var(--v-resBlue-darken1)'"
    :cancelButtonTextColor="'blue--text'"
    :setWidth="950"
    :maxWidth="950"
    :showDialog="showDialog"
    @cancel="cancel"
    @save="save">
    <v-card-text>
      <v-card flat height="60" class="container-card">
        <v-layout class="pa-2">
          <v-flex>
            <div class="caption resGray-darken1--text font-weight-medium">GL Account</div>
            <div class="caption resGray-darken5--text font-weight-medium">{{ this.accountInfo.account }} - {{ this.accountInfo.accountName }}</div>
          </v-flex>
          <v-flex>
            <div class="caption resGray-darken1--text font-weight-medium">GL Actuals</div>
            <div class="caption resGray-darken5--text font-weight-medium">{{ this.accountInfo.glActuals.toLocaleString() }}</div>
          </v-flex>
          <v-flex>
            <div class="caption resGray-darken1--text font-weight-medium">Reconciled Amount (Included)</div>
            <div class="caption resGray-darken5--text font-weight-medium">{{ this.includedAmount.toLocaleString() }}</div>
          </v-flex>
        </v-layout>
      </v-card>
      <!-- <v-layout my-4>
        <v-flex>
          <text-input
            width="250px"
            :placeholder="'Search accounts'">
          </text-input>
        </v-flex>
      </v-layout> -->
      <data-table
        v-if="tableData"
        class="details-table mt-2"
        :tableKey="'coreJournalEntryId'"
        :tableData="tableData"
        :headers="tableHeaders"
        @ClickCoreReference="ClickCoreReference">
      </data-table>
    </v-card-text>
  </base-dialog>
</template>

<script>
import DataTable from '../../../BaseComponents/Table'
import BaseDialog from '../../../BaseComponents/BaseDialog'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    BaseDialog,
    DataTable,
  },
  props: ['showDialog', 'accountInfo'],
  data () {
    return {
      confirm: false,
      selectedDetails: []
    }
  },
  methods: {
    ...mapActions(['fetchData', 'changeData']),
    ClickCoreReference (val) {
      var path = ''
      switch (val.coreObjectType) {
        case 'Invoice':
          path = '/Reports/InvoicePrintWithDocuments?invoiceIds=' + val.coreTransactionObjectId
          break
        default:
          path = '/#/Boardroom' // just go to home page if object does not have specific link
          break
      }
      this.$eventHub.$emit('goToResman', `${path}`)
    },
    cancel () {
      this.$emit('cancelDialog')
    },
    save () {
      if (this.accountInfo.isLocked) {
        this.cancel()
        return
      }

      this.changeData({
        currentPage: 'Reconciliations Expense Account Details',
        newData: {
          property: this.accountInfo.property,
          chargeCode: this.accountInfo.chargeCode,
          cycleNumber: this.accountInfo.cycleNumber,
          account: this.accountInfo.account,
          accountList: this.accountInfo.accountList,
          details: this.tableData
        }
      }).then(() => {
        this.$emit('save')
      }).catch(error => { console.log(error.response) })
    },
    getAccountDetails () {
      this.fetchData({
        newPage: 'Reconciliations Expense Account Details',
        params: {
          property: this.accountInfo.property,
          chargeCode: this.accountInfo.chargeCode,
          cycleNumber: this.accountInfo.cycleNumber,
          account: this.accountInfo.account,
          accountList: this.accountInfo.accountList
        }
      }).catch(error => { console.log(error.response) })
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders']),
    title () {
      return 'Expense Recovery Account Detail'
    },
    saveButtonText () {
      return this.accountInfo.isLocked ? 'Close' : 'Save'
    },
    saveButtonColor () {
      return this.accountInfo.isLocked ? 'var(--v-resBlue-darken1)' : 'var(--v-resGreen-base)'
    },
    tableData () {
      return this.currentData('Reconciliations Expense Account Details').map(x => {
        x.readonly = this.accountInfo.isLocked
        return x
      })
    },
    tableHeaders () {
      return this.currentHeaders('Reconciliations Expense Account Details')
    },
    includedAmount () {
      let amount = this.tableData.map(item => {
        if (item.isIncluded === true) {
          return item.baseExpenseAmount
        } else {
          return 0
        }
      }).reduce((a, b) => a + b, 0)
      return amount
    }
  },
  created () {
    this.getAccountDetails()
  }
}
</script>

<style lang="scss" scoped>
  .container-card {
    border: 1px solid var(--v-resGray-lighten1);
  }

  .details-table {
    ::v-deep span {
      font-size: 12px;
    }
    ::v-deep thead tr {
      height: 35px !important;
    }
    ::v-deep thead th {
      padding: 0 !important;
      height: 35px !important;
      // &:first-child {
      //   padding-left: 10px !important;
      // }
    }
    ::v-deep tbody tr {
      height: 35px !important;
    }
    ::v-deep tbody td {
      padding: 0 !important;
      height: 35px !important;
      // &:first-child {
      //   padding-left: 10px !important;
      // }
    }
  }
</style>
