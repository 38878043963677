<template>
  <imports-template
    ref='template'
    :canImport='canImport'
    :canExport='true'
    currentPage='GetImportChargeCodeTemplatesData'
    @downloadTemplate='downloadTemplate'
    @clearImportData='clearImportData'
    @saveUpload='saveUpload'
    @addNew='addNewItem'
    @submit='submit'>
    <template v-slot:body>
      <data-table
        :headers="headers"
        :tableData="getData"
        @itemDeleteRequested="deleteItem"
        @unlockEditing="unlockEdit"
        @dataChanged="save"
        @canceled="cancel"
        class="mx-6"
        :noData="'Please select an import template to upload.'">
      </data-table>
      <v-layout row mt-1 v-if='showWarning'>
        <v-icon small color="var(--v-resRed-base)">warning</v-icon>
        <span class='resRed-base--text'>
            {{ warningMessage }}
        </span>
      </v-layout>
    </template>
  </imports-template>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as fileDownload from '../../../../utility/fileDownloader'
import * as validation from '../../../../utility/validation'
import DataTable from '../../../BaseComponents/Table'
import ImportsTemplate from './ImportsTemplate'
export default {
  components: {
    DataTable,
    ImportsTemplate
  },
  data () {
    return {
      files: {},
      importDialog: false,
      confirmDialog: false
    }
  },
  methods: {
    ...mapActions(['fetchData', 'changeData', 'initializeStoreItem', 'fetchDropdown', 'addNew']),
    deleteItem (item) {
      this.$refs.template.deleteItem(item, this.getData, 'ImportChargeCodeMappings')
    },
    unlockEdit (item) {
      this.$refs.template.unlockEdit(item, this.getData)
    },
    cancel (item) {
      this.$refs.template.cancelEdit(item, this.getData, 'ImportChargeCodeMappings')
    },
    save (item) {
      this.$refs.template.save(item)
    },
    addNewItem () {
      this.addNew({
        currentPage: 'GetImportChargeCodeTemplatesData',
        newItem: {
          chargeCode: { text: null, value: null },
          chargeFrequency: { text: null, value: null },
          chargeType: { text: null, value: null },
          displayColumn: null,
          displayOrder: null,
          lateFeeApplies: false,
          managementFeeApplies: false,
          propertyTemplate: { text: null, value: null },
          reference: '',
          actionColumn: { delete: true, edit: true },
          readonly: false,
          new: true,
          errorToolTip: { hasIcon: false, tooltip: null, value: '' }
        }
      })
    },
    downloadTemplate () {
      this.fetchData({ newPage: 'ImportChargeCodeTemplates' }).then(() => {
        let file = this.currentData('ImportChargeCodeTemplates')
        let bufferArray = fileDownload.convertArrayBuffer(file.fileContent)
        fileDownload.createDownloadFile(bufferArray, file.fileName)
      })
    },
    saveUpload (item) {
      this.fetchDropdown({ newDropdown: 'Property Templates' })
      this.fetchDropdown({ newDropdown: 'Charge Frequency' })
      this.fetchDropdown({ newDropdown: 'Charge Codes' }).then(() => {
        this.changeData({ currentPage: 'GetImportChargeCodeTemplatesData', newData: item, updateItem: {} }).then(() => {
          this.$refs.template.cancel()
        })
      }).catch(error => { console.log(error.response) })
    },
    submit () {
      this.changeData({ currentPage: 'ImportChargeCodeTemplates', newData: { ChargeCodeTemplates: this.getData } }).then(() => {
        this.$refs.template.cancelImport()
      }).catch(error => { console.log(error.response) })
    },
    clearImportData () {
      this.initializeStoreItem('GetImportChargeCodeTemplatesData')
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentDropdown']),
    headers () {
      let items = JSON.parse(JSON.stringify(this.currentHeaders('GetImportChargeCodeTemplatesData')))
      let validation = items.splice(items.findIndex(x => x.value === 'errorToolTip'), 1)
      return validation.concat(items)
    },
    rawData () {
      return this.currentData('GetImportChargeCodeTemplatesData')
    },
    getData () {
      let items = this.rawData
      let propertyTemplates = this.currentDropdown('Property Templates')
      let propertyTemplatesLength = propertyTemplates.length
      let chargeFrequencies = this.currentDropdown('Charge Frequency')
      let chargeFrequenciesLength = chargeFrequencies.length
      let chargeCodes = this.currentDropdown('Charge Codes')
      let chargeCodesLength = chargeCodes.length
      items.forEach(item => {
        if (propertyTemplatesLength > 0 && validation.hasValue(item.propertyTemplate) && validation.hasValue(item.propertyTemplate.text)) {
          item.propertyTemplate = propertyTemplates.find(x => {
            if (validation.hasValue(x.text)) {
              return x.text.toLowerCase() === item.propertyTemplate.text.toLowerCase()
            }
          })
        }
        if (chargeFrequenciesLength > 0 && validation.hasValue(item.chargeFrequency) && validation.hasValue(item.chargeFrequency.text)) {
          item.chargeFrequency = chargeFrequencies.find(x => {
            if (validation.hasValue(x.text)) {
              return x.text.toLowerCase() === item.chargeFrequency.text.toLowerCase()
            }
          })
        }
        if (chargeCodesLength > 0 && validation.hasValue(item.chargeCode) && validation.hasValue(item.chargeCode.text)) {
          item.chargeCode = chargeCodes.find(x => {
            if (validation.hasValue(x.text)) {
              return x.text.toLowerCase() === item.chargeCode.text.toLowerCase()
            }
          })
        }
        let errors = validation.validateValue(item, [
          { value: 'propertyTemplate', required: true },
          { value: 'chargeCode', required: true },
          { value: 'chargeFrequency', required: true },
          { value: 'statementOrder', positive: true },
          { value: 'rentRollColumn', positive: true },
          { value: 'reference', required: true, maxLength: 50 }
        ], items)
        validation.handleErrorToolTip(item, errors)
      })
      return items
    },
    canImport () {
      if (this.getData.length > 0 && !this.showWarning) {
        return true
      }
      return false
    },
    warningMessage () {
      return this.getData.filter(x => x.errorToolTip.hasIcon === true).length + ' row(s) have errors. Please fix these to continue.'
    },
    showWarning () {
      return this.getData.filter(x => x.errorToolTip.hasIcon === true).length > 0
    }
  },
  beforeDestroy () {
    this.clearImportData()
  }
}
</script>
