<template>
  <base-dialog
    iconColor="var(--v-resWhite-base)"
    titleClass="dark-header"
    :saveButtonColor="saveButtonColor"
    :title="title"
    :saveButtonText="saveButtonText"
    :setWidth="350"
    :saveButtonWidth="130"
    :showDialog="showDialog"
    @cancel="cancel"
    @save="save">
    <v-card-text>
      <span>{{ dialogText }}</span>
    </v-card-text>
  </base-dialog>
</template>

<script>
import BaseDialog from '../../../BaseComponents/BaseDialog'

export default {
  components: {
    BaseDialog
  },
  props: ['showDialog', 'isCurrentlyLocked'],
  data () {
    return {
    }
  },
  methods: {
    cancel () {
      this.$emit('cancelLockReconciliation')
    },
    save () {
      this.$emit('confirmLockReconciliation', true)
    }
  },
  computed: {
    title () {
      return this.isCurrentlyLocked ? 'Confirm Unlock Reconciliation' : 'Confirm Lock Reconciliation'
    },
    dialogText () {
      return 'Are you sure you want to ' + (this.isCurrentlyLocked ? 'unlock' : 'lock') + ' reconciliations for this expense cycle?'
    },
    saveButtonText () {
      return this.isCurrentlyLocked ? 'Unlock' : 'Lock'
    },
    saveButtonColor () {
      return 'var(--v-resGreen-base)'
    }
  }
}
</script>
