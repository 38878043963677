<template>
  <base-dialog
    :showDialog="showDialog"
    :title="dialogTitle"
    :titleClass="'dark-header'"
    :iconColor="'var(--v-resWhite-base)'"
    :saveButtonText="'Run Report'"
    :saveButtonWidth="108"
    :saveDisabled="false"
    :maxWidth="500"
    @cancel="cancel"
    @save="runReport">
    <v-card-text v-if="!reportSelected">
      <div>
        <span>Please select the type of report you would like to generate</span>
      </div>
      <v-row no-gutters>
        <v-col cols="12">
          <dialog-tabs
            :tabs="tabs"
            @changeTab="setActiveTab">
          </dialog-tabs>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-4">
        <v-col cols="12" class="report-drop-down">
          <v-combobox
            v-model="selectedReport"
            class="report-drop-down__combobox ma-0 pa-0"
            single-line
            outlined
            height="35"
            solo
            flat
            dense
            prepend-inner-icon="search"
            append-icon=""
            :items="reportList"
            :item-text="'text'"
            :item-value="'value'"
            :placeholder="`Search ${currentTab} reports`"
            hide-details
            @change="selectReport"
            :menu-props="{ contentClass: 'report-drop-down__menu' }">
          </v-combobox>
        </v-col>
      </v-row>
      <v-layout row>
        <v-flex class="spacer"></v-flex>
      </v-layout>
    </v-card-text>
    <parameters v-else
      :selectedReport="selectedReportName"
      @goBack="deselectReport"
      @queryStringUpdated="setQueryString">
    </parameters>
  </base-dialog>
</template>

<script>
import BaseDialog from '../BaseComponents/BaseDialog'
import DialogTabs from '../BaseComponents/DialogTabs'
import Parameters from './Parameters'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BaseDialog,
    DialogTabs,
    Parameters
  },
  props: ['showDialog'],
  data () {
    return {
      dialogTitle: 'Commercial Management Reports',
      selectedReport: null,
      selectedReportName: null,
      tabs: [
        { header: 'Operational' },
        { header: 'Forms' },
        { header: 'Custom' }
      ],
      reportItems: [
        'Property Evaluation',
        'Tenant Statements',
        'Tenant Statements Fees',
        'CAM Reconciliation Letter'
      ],
      selectedCategory: 'Operational',
      queryString: '',
      currentTab: 'Operational'
    }
  },
  methods: {
    ...mapActions(['fetchDropdown', 'fetchData']),
    cancel () {
      this.selectedReport = null
      this.$emit('cancel')
    },
    selectReport (e) {
      if (e && e.value) {
        this.selectedReport = e.value
        this.selectedReportName = e.text
      }
    },
    deselectReport () {
      this.selectedReport = null
    },
    setActiveTab (e) {
      if (e && e.header) {
        this.selectedCategory = e.header
        this.getReports()
        this.currentTab = e.header
      }
    },
    getReports () {
      this.fetchDropdown({ newDropdown: 'Reports', params: { ReportCategory: this.selectedCategory } }).catch(error => { console.log(error.response) })
    },
    getToken () {
      this.fetchData({ newPage: 'ReportConnectionToken', params: { } }).catch(error => { console.log(error.response) })
    },
    runReport () {
      window.open(this.reportUrl, '_blank')
    },
    setQueryString (e) {
      if (e) {
        this.queryString = e
      }
    }
  },
  computed: {
    ...mapGetters(['currentDropdown', 'currentUser', 'currentData']),
    reportList () {
      return this.currentDropdown('Reports')
    },
    reportSelected () {
      if (!this.selectedReport) {
        return false
      }
      return true
    },
    clientQueryString () {
      return `&report_connection_token=${this.connectionToken}`
    },
    reportUrl () {
      const baseURL = process.env.VUE_APP_REPORT_VIEWER_URL
      return `${baseURL}/ViewReport.aspx?report_path=/RMCOMM/PM/${this.selectedReport}${this.queryString}${this.clientQueryString}`
    },
    connectionToken () {
      return this.currentData('ReportConnectionToken')
    }
  },
  created () {
    this.getReports()
    this.getToken()
  }
}
</script>

<style lang="scss" scoped>
.resBlue-darken1--text {
  color: var(--v-resGray-darken3) !important;
  caret-color: var(--v-resGray-darken3) !important;
}

.report-drop-down  {
  display: inline-block;
  min-width: 50px;
  max-width: 340px;
  width: var(--width);
  min-height: 35px;
  max-height: 35px;
  height: 35px;
  ::v-deep .v-text-field--outlined>.v-input__control>.v-input__slot {
    background-color: var(--v-resWhite-base)!important;
    min-height: 35px;
    max-height: 35px;
    height: 35px;
  }
  ::v-deep .v-input__control {
    width: var(--width);
    min-height: 35px;
    max-height: 35px;
    height: 35px;
  }
  ::v-deep .v-input__slot {
    padding-right:0px !important;
    margin-bottom: 0px;
    min-height: 35px;
    max-height: 35px;
    height: 35px;
    span {
      font-size:12px;
      white-space: nowrap;
    }
    ::v-deep .v-select__selections {
      width: calc(var(--width) - 42px);
      min-height: 35px;
      max-height: 35px;
      height: 35px;
    }
  }
  ::v-deep .v-input__append-inner {
    border-radius: 0px 4px 4px 0px;
    padding: 4px 2px 2px 2px;
    margin: 0px;
    .v-icon {
      color: var(--v-resGray-darken3);
      transform: rotate(0);
    }
  }

  ::v-deep .v-text-field__details {
    margin-bottom: 0px;
  }

  ::v-deep .v-text-field--box .v-input__slot,
  ::v-deep .v-text-field--outline .v-input__slot {
    min-height: auto!important;
    display: flex!important;
    align-items: center!important;
    border-radius: 6px!important;
    border: solid 1px var(--v-resGray-darken2) !important;
    text-align: left!important;
    min-height: 35px;
    max-height: 35px;
    height: 35px;
  }
  ::v-deep .v-icon {
    font-size: 20px;
  }
  &__menu {
    .v-select-list {
      ::v-deep .v-list__tile {
        min-height: 35px;
        max-height: 35px;
        height: 35px !important;
      }
      ::v-deep .v-icon {
        font-size: 15px;
      }
    }
    .v-card {
      border-radius: 6px !important;
    }
    .v-select-list {
      ::v-deep .v-list {
        padding-bottom: 0px;
      }
      ::v-deep .v-list__tile {
        font-size: 13px;
      }
      ::v-deep .v-list__tile__action, .v-list__tile__avatar {
        min-width: 32px;
      }
    }
  }
  &__combobox {
    ::v-deep input {
      font-size: 12px;
    }
    ::v-deep .v-label {
      font-size: 12px;
    }
    ::v-deep .v-select__slot {
      padding-top: 2px;
      display: -webkit-box;
    }
  }
  &__label {
    font-size: 12px;
  }
  &__header {
    border-bottom: 1px solid var(--v-resGray-darken1);
    &.v-list-item {
      height: 30px;
      min-height: 30px !important;
    }
  }
}

.spacer {
  display: block;
  height: 150px;
}
</style>
