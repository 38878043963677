<template>
  <v-container>
    <v-dialog
      v-model="showDialog"
      persistent
      width="790">
      <v-app-bar dark height="40" color="var(--v-resBlue-darken1)">
        <span class="caption">New Lease</span>
        <v-spacer></v-spacer>
        <v-btn @click="cancelDialog" icon>
          <v-icon>close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card height="570" style="border-radius: 0;">
        <v-card-text class="px-0 pt-0 pb-0">
          <v-stepper alt-labels class="elevation-0" :value="currentStep">
            <v-stepper-header class="elevation-0">
              <v-stepper-step class="pb-0" :complete="leasesComplete" step="Lease">
                Lease
              </v-stepper-step>
              <v-divider :class="leasesComplete ? 'complete__divider' : ''"></v-divider>
              <v-stepper-step class="pb-0" :complete="spacesComplete" step="Spaces">
                Space
              </v-stepper-step>
              <v-divider :class="spacesComplete ? 'complete__divider' : ''"></v-divider>
              <v-stepper-step class="pb-0" :complete="chargesComplete" step="Charges">
                Charges
              </v-stepper-step>
              <v-divider :class="chargesComplete ? 'complete__divider' : ''"></v-divider>
              <v-stepper-step class="pb-0" :class="currentStep === 'Addresses' ? 'v-stepper__step--active' : ''" :complete="contactsComplete" step="Contacts">
                Contacts
              </v-stepper-step>
              <v-stepper-step class="pb-0" v-show="false" :complete="addressesComplete" step="Addresses">
                Address
              </v-stepper-step>
              <v-divider :class="[contactsComplete ? 'complete__divider' : '', !addressesComplete && contactsComplete  ? 'half__step' : '']" ></v-divider>
              <v-stepper-step class="pb-0" :complete="false" step="Review">
                Review
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content class="pt-0" step="Lease">
                <lease></lease>
              </v-stepper-content>
              <v-stepper-content class="pt-0" step="Spaces">
                <lease-spaces></lease-spaces>
              </v-stepper-content>
              <v-stepper-content class="pt-0" step="Charges">
                <lease-charges  v-if="this.currentStep === 'Charges'" @updateCharges="chargesUpdate"></lease-charges>
              </v-stepper-content>
              <v-stepper-content class="pt-0" step="Contacts">
                <keep-alive>
                  <lease-contacts v-if="this.currentStep === 'Contacts'"></lease-contacts>
                </keep-alive>
              </v-stepper-content>
              <v-stepper-content class="pt-0" step="Addresses">
                <keep-alive>
                  <lease-addresses v-if="this.currentStep === 'Addresses'"></lease-addresses>
                </keep-alive>
              </v-stepper-content>
              <v-stepper-content class="pt-0" step="Review">
                <lease-review v-if="this.currentStep === 'Review'"></lease-review>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
        <v-card-actions class="px-4 ml-2 pt-0 pb-4">
          <round-button
            v-if="currentStep !== 'Lease' && currentStep !== 'Review'"
            height='30px'
            :width='backPreviousButton.width'
            :disabled="currentLoading"
            :buttonText='backPreviousButton.buttonText'
            :buttonClass='backPreviousButton.buttonClass'
            :buttonTextColor="'var(--v-resBlue-darken1)'"
            :borderColor="backPreviousButton.borderColor"
            :depressed='backPreviousButton.depressed'
            :flat='backPreviousButton.flat'
            :outline='backPreviousButton.outline'
            @click="currentPreviousCharge ? previousChargePage() : previousStep()">
          </round-button>
          <v-spacer></v-spacer>
          <round-button
            v-if="currentStep === 'Contacts' || currentStep === 'Addresses'"
            height='30px'
            :width="'80px'"
            :buttonText="'Skip'"
            :borderColor="'var(--v-resRed-base)'"
            :backgroundColor="'var(--v-resRed-base)'"
            :depressed="true"
            :dark="true"
            :disabled="currentLoading"
            :flat="true"
            :outline="true"
            @click="skipStep">
          </round-button>
          <round-button
            v-if="currentStep === 'Lease' || currentStep === 'Review'"
            height='30px'
            :width="'80px'"
            :buttonText="'Cancel'"
            :disabled="currentLoading"
            :buttonTextColor="currentLoading ? 'var(--v-resGray-darken1)' : 'var(--v-resRed-base)'"
            :buttonClass="'resRed-base--text'"
            :borderColor="currentLoading ? 'var(--v-resGray-darken1)' : 'var(--v-resRed-base)'"
            :depressed="true"
            :flat="true"
            :outline="true"
            @click="cancelDialog">
          </round-button>
          <round-button
            height='30px'
            :width="continueButton.width"
            buttonClass='ml-3 mr-3'
            :depressed='true'
            :flat='true'
            :disabled="currentLoading"
            :backgroundColor="continueButton.color"
            :buttonText="continueButton.text"
            @click="continueDialog">
          </round-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialogs -->
    <base-dialog
      :title="'Are you sure?'"
      :showDialog="confirmCancel"
      :saveButtonText="'yes, proceed'"
      :iconColor="'white'"
      :saveButtonColor="'var(--v-resBlue-darken1)'"
      :cancelButtonTextColor="'resBlue-darken1--text'"
      :cancelButtonColor="'var(--v-resBlue-darken1)'"
      :cancelButtonText="'Cancel'"
      :titleClass="'dark-header'"
      :saveButtonWidth="120"
      @cancel="cancelDelete"
      @save="confirmDelete">
      <v-card-text class="pl-6">
        <span>You will lose all information you have entered for this lease and related content if you close this window. Do you still wish to proceed?</span>
      </v-card-text>
    </base-dialog>
  </v-container>
</template>

<script>
import BaseDialog from '../../BaseComponents/BaseDialog'
import RoundButton from '../../BaseComponents/RoundButton'
import Lease from './Lease'
import LeaseSpaces from './LeaseSpaces'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BaseDialog,
    RoundButton,
    Lease,
    LeaseSpaces,
    LeaseCharges: () => import('./LeaseCharges'),
    LeaseContacts: () => import('./LeaseContacts'),
    LeaseAddresses: () => import('./LeaseAddresses'),
    LeaseReview: () => import('./LeaseReview')
  },
  props: ['showDialog', 'lease'],
  data () {
    return {
      previousButton: false,
      leasesComplete: false,
      spacesComplete: false,
      chargesComplete: false,
      contactsComplete: false,
      addressessComplete: false,
      confirmCancel: false
    }
  },
  methods: {
    ...mapActions(['initializeStoreItem', 'fetchData', 'changeData', 'deleteData', 'changePropertyChanged', 'changeReview', 'clearLeaseWorkflow', 'changeNextStep', 'changePreviousStep', 'changeLease', 'changeSpaces', 'changeStep', 'changeSpaceIndex']),
    previousStep () {
      this.changePreviousStep()
      if (this.currentStep === 'Charges') {
        this.changeSpaceIndex()
      }
    },
    nextStep () {
      if (this.currentReview) {
        this.changeStep('Review')
      } else {
        this.changeNextStep()
      }
      if (this.currentStep === 'Review') {
        this.changeReview(true)
      }
    },
    continueDialog () {
      if (!this.currentLoading) {
        this.emitChanges()
      }
    },
    activatePrevious () {
      this.previousButton = true
    },
    deactivatePrevious () {
      this.previousButton = false
    },
    emitChanges () {
      if (!this.currentLoading) {
        if (this.currentStep === 'Lease') {
          this.leaseUpdate()
        } else if (this.currentStep === 'Spaces') {
          this.spacesUpdate()
        } else if (this.currentStep === 'Charges') {
          this.$eventHub.$emit('completeCharges')
        } else if (this.currentStep === 'Contacts') {
          this.contactsUpdate()
        } else if (this.currentStep === 'Addresses') {
          this.addressesUpdate()
        } else if (this.currentStep === 'Review') {
          this.completeDialog()
        }
      }
    },
    resetWorkflow () {
      this.leasesComplete = false
      this.spacesComplete = false
      this.chargesComplete = false
      this.contactsComplete = false
      this.addressesComplete = false
      this.changeStep('Lease')
      this.initializeStoreItem('LeaseAvailableSpaces')
      this.initializeStoreItem('LeasesRecurringChargesNew')
      this.clearLeaseWorkflow()
    },
    confirmDelete () {
      if (this.currentLease.lease !== undefined) {
        this.deleteData({ currentPage: 'Leases', removeItem: { lease: this.currentLease.lease, rowVersion: this.currentLease.rowVersion } }).catch(error => { console.log(error.response) })
      }
      this.resetWorkflow()
      this.$emit('cancel', true)
    },
    cancelDialog () {
      this.confirmCancel = true
    },
    cancelDelete () {
      this.confirmCancel = false
    },
    completeDialog () {
      this.resetWorkflow()
      this.$emit('complete', true)
    },
    skipStep () {
      this.nextStep()
    },
    leaseUpdate () {
      this.$eventHub.$emit('validate')
      setTimeout(() => {
        if (!this.errors.items.length > 0) {
          if (this.leasesComplete) {
            if (this.currentPropertyChanged && this.currentReview) {
              this.changeStep('Spaces')
            } else {
              this.nextStep()
            }
          } else {
            this.nextStep()
            this.leasesComplete = true
          }
        }
      }, 100)
      this.$eventHub.$emit('deselectAll')
    },
    spacesUpdate () {
      if (this.currentSpaces.selectedSpaces && this.currentSpaces.selectedSpaces.length > 0) {
        if (this.spacesComplete) {
          this.nextStep()
        } else {
          this.nextStep()
          this.spacesComplete = true
        }
      }
    },
    previousChargePage () {
      this.$eventHub.$emit('previousCharge')
    },
    chargesUpdate () {
      if (this.currentSpaceIndex + 1 === this.currentSpaces.selectedSpaces.length) {
        this.chargesComplete = true
        this.nextStep()
      }
    },
    contactsUpdate () {
      this.$eventHub.$emit('validate')
      setTimeout(() => {
        if (!this.errors.items.length > 0) {
          this.$eventHub.$emit('completeContacts')
          if (this.currentContacts.length > 0) {
            this.contactsComplete = true
            this.changeData({ currentPage: 'ContactsWorkflow', newData: { AllContacts: this.currentContacts } }).then(() => {
              this.nextStep()
            }).catch(error => { console.log(error.response) })
          }
        }
      }, 100)
    },
    addressesUpdate () {
      this.$eventHub.$emit('validate')
      setTimeout(() => {
        if (!this.errors.items.length > 0) {
          this.$eventHub.$emit('completeAddresses')
          if (this.currentAddresses.length > 0) {
            this.addressesComplete = true
            this.changeData({ currentPage: 'AddressesNewLease', newData: { AllAddresses: this.currentAddresses } }).then(() => {
              this.nextStep()
            }).catch(error => { console.log(error.response) })
          }
        }
      }, 100)
    }
  },
  created () {
    this.resetWorkflow()
  },
  computed: {
    ...mapGetters(['currentReview', 'currentNextCharge', 'currentPropertyChanged', 'currentData', 'currentAddresses', 'currentLoading', 'currentLease', 'currentSpaces', 'currentStep', 'currentPreviousCharge', 'currentSpaceIndex', 'currentContacts']),
    nextCharge: {
      get () {
        if (this.currentSpaces.selectedSpaces.length > 1) {
          return true
        } else {
          return false
        }
      }
    },
    continueButton: {
      get () {
        if (this.currentStep === 'Lease' && !this.leasesComplete) {
          return { text: 'Start', width: '80px', color: 'var(--v-resGreen-base)' }
        } else if (this.currentStep === 'Review') {
          return { text: 'Finish', width: '80px', color: 'var(--v-resGreen-base)' }
        } else if (this.currentReview) {
          if (!this.currentPropertyChanged && this.currentStep === 'Lease') {
            return { text: 'Review & Finish', width: '150px', color: 'var(--v-resBlue-darken1)' }
          } else if (this.currentStep === 'Spaces') {
            return { text: 'Next', width: '80px', color: 'var(--v-resBlue-darken1)' }
          }
          return { text: 'Review & Finish', width: '150px', color: 'var(--v-resBlue-darken1)' }
        } else if (this.currentNextCharge) {
          return { text: 'Next Charge', width: '150px', color: 'var(--v-resBlue-darken1)' }
        } else {
          return { text: 'Next', width: '80px', color: 'var(--v-resBlue-darken1)' }
        }
      }
    },
    backPreviousButton: {
      get () {
        if ((this.currentStep !== 'Lease' || this.currentStep !== 'Review') && !this.currentPreviousCharge) {
          return {
            width: '80px',
            buttonText: 'Back',
            buttonClass: this.currentLoading ? 'ml-1' : 'resBlue-darken1--text ml-1',
            borderColor: 'var(--v-resBlue-darken1)',
            depressed: true,
            flat: true,
            outline: true
          }
        } else if (this.currentPreviousCharge) {
          return {
            width: '150px',
            buttonText: 'Previous Charge',
            buttonClass: this.currentLoading ? 'ml-1' : 'resBlue-darken1--text ml-1',
            borderColor: 'var(--v-resBlue-darken1)',
            depressed: true,
            flat: true,
            outline: true
          }
        }
        return null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-stepper__header{
    width:450px;
    margin: auto;
    hr {
      max-height:5px;
      background-color: var(--v-resGray-lighten4);
      border: 1px var(--v-resGray-lighten2) solid;
      height: 5px;
      margin: 30px -82px 0 !important;
      box-shadow: inset 0 0 0 0 var(--v-resGray-lighten4);
      -webkit-transition: all ease .75s;
      transition: all ease .75s;
    }
    .complete__divider {
      box-shadow: inset 60px 0 0 0 var(--v-resGreen-lighten1);
      -webkit-transition: all ease .75s;
      transition: all ease .75s;
    }
    .half__step {
      box-shadow: inset 30px 0 0 0 var(--v-resGreen-lighten1);
      -webkit-transition: all ease .75s;
      transition: all ease .75s;
    }
    ::v-deep .v-stepper__step {
      min-width: 0px;
      margin-bottom: 4px;
      &__step {
        min-width: 0px;
        height: 18px;
        width: 18px;
      }
      .v-stepper__step__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) {
        background-color: var(--v-resGray-lighten4);
        color: transparent;
        border: 1px var(--v-resGray-lighten2) solid;
        -webkit-transition: background-color ease .5s;
        transition: background-color ease .5s;
      }
      &--complete {
        .v-stepper__step__step {
          background-color: var(--v-resGreen-lighten1) !important;
          border-color: var(--v-resGreen-lighten1) !important;
          -webkit-transition: background-color ease .5s;
          transition: background-color ease .5s;
          .v-icon {
            font-size: 14px;
          }
        }
      }
      &--active {
        .v-stepper__step__step{
          background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 45%, rgba(131,186,28,1) 50%, rgba(131,186,28,1) 100%);
          background-color: var(--v-resGreen-lighten1) !important;
          border-color: var(--v-resGreen-lighten1) !important;
          -webkit-transition: background-color ease .5s;
          transition: background-color ease .5s;
        }
      }
    }
  }
  .dot {
    height: 10px;
    width: 10px;
    background-color: var(--v-resGreen-lighten1);
    border-radius: 50%;
    margin-left: 2px;
    margin-right: 2px;
    display: inline-block;
  }
</style>
