<template>
  <base-dialog
    class="add-charge-dialog"
    :showDialog="showDialog"
    title="Add Charge"
    :saveButtonText="getButtonText"
    :maxWidth="650"
    :saveDisabled="continueDisabled"
    @cancel="cancelDialog"
    @save="continueDialog">
    <v-card-text>
      <v-stepper class="elevation-0" v-model="e1">
        <v-stepper-header v-show="false"  class="elevation-0">
          <v-stepper-step :complete="e1 > 1" step="1"></v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="e1 > 2" step="2"></v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1" class='ma-0 pa-0'>
            <v-card flat>
              <drop-down
                :items="'Charges'"
                :label="'Which charge would you like to add?'"
                :multiple='false'
                :getParams="{ Lease: currentSelectedItem.lease, Property: currentSelectedItem.property }"
                class="mb-6"
                width="200px"
                @itemSelected="selectChargeType">
              </drop-down>
              <v-tooltip top nudge-right="110" content-class="tooltip">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small>help</v-icon>
                </template>
                <span>
                  If you do not see the charge you would like to add, verify that a general ledger account is mapped
                  <br/>to the charge code by going to Properties -> Charge Code Mapping. Then verify that the charge
                  <br/>code is mapped to the lease space by going to Properties -> Property Charge Code.
                </span>
              </v-tooltip>
            </v-card>
          </v-stepper-content>
          <v-stepper-content step="2" class="ma-0 pa-0">
            <v-row no-gutters>
              <a color="var(--v-resBlue-darken1)" @click="e1 = 1">
                {{ goBackText }}
              </a>
            </v-row>
            <v-row no-gutters class="mt-2">
              <span>{{ selectedChargeDescription }}</span>
              <span class="font-weight-thin">{{"' - (current charges)'"}}</span>
            </v-row>
            <v-row no-gutters>
              <div style="overflow: auto">
                <v-card flat max-height="190">
                  <data-table
                    :headers="filteredHeaders"
                    :tableData="filteredCharges"
                    :showActions="false"
                    :editOnly='false'
                    :noFooter="true"
                    :noPagination="true"
                    :baseRowPerPage="50"
                    :noData="'No information to display.'">
                  </data-table>
                </v-card>
              </div>
            </v-row>
            <v-row no-gutters>
              <span class="font-weight-bold">New Charge</span>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <date-picker
                  :label="'Start Date'"
                  class="mr-3"
                  @dateSelected="updateStartDate">
                </date-picker>
                <date-picker
                  :label="'End Date'"
                  class="mr-3"
                  @dateSelected="updateEndDate">
                </date-picker>
                <text-input
                  :value="newCharge.baseAmount"
                  label='Amount'
                  width="100px"
                  :prefix="'$'"
                  rules="decimal:2"
                  textFormat="currency"
                  @textInputUpdated="updateBaseAmt">
                </text-input>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
  </base-dialog>
</template>

<script>
import DropDown from '../../BaseComponents/DropDown'
import TextInput from '../../BaseComponents/TextInput'
import DatePicker from '../../BaseComponents/DatePicker'
import BaseDialog from '../../BaseComponents/BaseDialog'
import DataTable from '../../BaseComponents/Table'
import { mapGetters, mapActions } from 'vuex'
import * as currency from '../../../utility/currency'

export default {
  components: {
    TextInput,
    DatePicker,
    BaseDialog,
    DropDown,
    DataTable
  },
  props: ['showDialog', 'lease'],
  data () {
    return {
      e1: 1,
      newCharge: {},
      selectedLeaseItem: 0,
      selectedChargeDescription: ''
    }
  },
  methods: {
    ...mapActions(['fetchData']),
    selectChargeType (val) {
      this.newCharge.leaseItem = val.value
      this.selectedLeaseItem = Number(val.value)
      this.selectedChargeDescription = val.text
    },
    updateBaseAmt (val) {
      var currencyVal = currency.parseCurrency(val)
      this.newCharge.baseAmount = currencyVal
      this.newCharge.tranAmount = currencyVal
    },
    continueDialog () {
      if (this.e1 === '1' || this.e1 === 1) {
        this.e1 = 2
        this.fetchData({ newPage: 'LeasesRecurringChargesItem', params: { Lease: this.lease, Property: this.currentSelectedItem.property, LeaseItem: this.selectedLeaseItem } }).then(() => {
          this.newCharge.lease = this.lease
          this.newCharge.chargeCode = this.getChargeItem.chargeCode
          this.newCharge.leaseItemReference = this.getChargeItem.reference
          if (this.filteredCharges.length > 0) {
            this.newCharge.quantity = this.filteredCharges[0].quantity
            this.newCharge.tranTaxAmount = this.filteredCharges[0].tranTaxAmount
            this.newCharge.baseTaxAmount = this.filteredCharges[0].baseTaxAmount
            this.newCharge.tranVatAmount = this.filteredCharges[0].tranVatAmount
            this.newCharge.baseVatAmount = this.filteredCharges[0].baseVatAmount
          } else {
            this.newCharge.quantity = 0
            this.newCharge.tranTaxAmount = 0.00
            this.newCharge.baseTaxAmount = 0.00
            this.newCharge.tranVatAmount = 0.00
            this.newCharge.baseVatAmount = 0.00
          }
        }).catch(error => { console.log(error.response) })
      } else {
        this.$emit('saveNewCharge', this.newCharge)
        this.resetWorkflow()
      }
    },
    resetWorkflow () {
      this.selectedLeaseItem = 0
      this.newCharge = {}
      this.e1 = 1
    },
    updateStartDate (val) {
      this.newCharge.startDate = val
    },
    updateEndDate (val) {
      this.newCharge.endDate = val
    },
    cancelDialog () {
      this.resetWorkflow()
      this.$emit('cancel', true)
    }
  },
  created () {
    this.resetWorkflow()
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentSelectedItem']),
    getButtonText () {
      return this.e1 === '1' || this.e1 === 1 ? 'Next' : 'Save'
    },
    headers: {
      get () {
        return this.currentHeaders('LeasesRecurringCharges')
      }
    },
    getData: {
      get () {
        return this.currentData('LeasesRecurringCharges')
      }
    },
    getChargeItem: {
      get () {
        return this.currentData('LeasesRecurringChargesItem')
      }
    },
    goBackText: {
      get () {
        return '< Select a different charge'
      }
    },
    filteredCharges: {
      get () {
        var selectedCharges = this.getData.filter(item => {
          return item.leaseItem === this.selectedLeaseItem
        })

        return selectedCharges
      }
    },
    filteredHeaders: {
      get () {
        var selectedHeaders = JSON.parse(JSON.stringify(this.headers))
        var itemToRemove = [0, 1, 8, 9, 10, 11]
        for (var i = itemToRemove.length - 1; i >= 0; i--) {
          selectedHeaders.splice(itemToRemove[i], 1)
        }
        return selectedHeaders
      }
    },
    continueDisabled () {
      return (this.e1 === '1' || this.e1 === 1) && this.selectedLeaseItem <= 0
    }
  }
}
</script>

<style lang="scss" scoped>
  .add-charge-dialog {
    ::v-deep span {
      font-size: 12px;
    }
    ::v-deep thead tr {
      height: 30px !important;
    }
    ::v-deep thead th {
      padding: 0 !important;
      height: 30px !important;
    }
    ::v-deep tbody tr {
      height: 30px !important;
    }
    ::v-deep tbody td {
      padding: 0 !important;
      height: 30px !important;
    }
  }
</style>
