<template>
  <base-dialog
    :setWidth="350"
    :showDialog="showDialog"
    title='Property Parameters'
    @cancel="cancel"
    @save="save">
    <v-card-text>
      <v-row>
        <v-col>
          <text-input
            v-model="editedParameters.salesTax"
            suffix="%"
            placeholder="0"
            label="Sales Tax Percentage"
            width="125px">
          </text-input>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <drop-down :selectedValue="{
                                      value: editedParameters.salesTaxControlAccount || '' ,
                                      text: editedParameters.salesTaxControlAccountName || ''
                                    }"
                      items="Company Accounts"
                      label="Sales Tax GL Account"
                      :multiple='false'
                      width="220px"
                      rules="required"
                      @itemSelected="selectControlAccount">
          </drop-down>
        </v-col>
      </v-row>
    </v-card-text>
  </base-dialog>
</template>

<script>
import BaseDialog from '../../BaseComponents/BaseDialog'
import TextInput from '../../BaseComponents/TextInput'
import DropDown from '../../BaseComponents/DropDown'
import * as validation from '../../../utility/validation'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BaseDialog,
    TextInput,
    DropDown
  },
  props: ['showDialog'],
  data () {
    return {
      editedParameters: {}
    }
  },
  methods: {
    ...mapActions(['fetchData', 'changeData']),
    getParameters () {
      this.fetchData({
        newPage: 'PropertyParameters',
        params: {
          property: this.currentSelectedItem.property
        }
      }).then(() => {
        if (validation.hasValue(this.currentData('PropertyParameters'))) {
          this.editedParameters = this.currentData('PropertyParameters')
        } else {
          this.editedParameters = {}
        }
      }).catch(error => { console.log(error.response) })
    },
    cancel () {
      this.$emit('cancel', true)
    },
    save () {
      this.changeData({
        currentPage: 'PropertyParameters',
        newData: {
          property: this.currentSelectedItem.property,
          salesTax: this.editedParameters.salesTax,
          salesTaxControlAccount: this.editedParameters.salesTaxControlAccount
        }
      }).then(() => {
        this.$emit('saveComplete')
      }).catch(error => { console.log(error.response) })
    },
    selectControlAccount (val) {
      if (val !== null) {
        this.editedParameters.salesTaxControlAccount = val.value
        this.editedParameters.salesTaxControlAccountName = val.text
      } else {
        this.editedParameters.salesTaxControlAccount = null
        this.editedParameters.salesTaxControlAccountName = null
      }
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentSelectedItem']),
    currentParameters () {
      return this.currentData('PropertyParameters')
    }
  },
  created () {
    this.getParameters()
  }
}
</script>
