<template>
  <v-card height="110" flat class="mx-4">
    <v-container>
      <v-layout>
        <v-flex>
          <div class="caption resGray-darken1--text font-weight-medium">Cycle year</div>
          <div class="caption resGray-darken5--text font-weight-medium">{{ cycle.cycleYear }}</div>
          <div class="caption resGray-darken1--text font-weight-medium mt-2">Estimate charge code</div>
          <div class="caption resGray-darken5--text font-weight-medium">{{ cycle.estimateChargeCode }}</div>
        </v-flex>
        <v-flex>
          <div class="caption resGray-darken1--text font-weight-medium">Cycle #</div>
          <div class="caption resGray-darken5--text font-weight-medium">{{ cycle.cyclePeriod }}</div>
          <div class="caption resGray-darken1--text font-weight-medium mt-2">Estimate frequency</div>
          <div class="caption resGray-darken5--text font-weight-medium">{{ cycle.estimateChargeFrequencyName }}</div>
        </v-flex>
        <v-flex>
          <div class="caption resGray-darken1--text font-weight-medium">Cycle start date</div>
          <div class="caption resGray-darken5--text font-weight-medium">{{ formatDate(cycle.cycleStartDate) }}</div>
          <div class="caption resGray-darken1--text font-weight-medium mt-2">Estimate locked</div>
          <div class="caption resGray-darken5--text font-weight-medium">{{ cycle.estimateIsLocked ? 'YES' : 'NO' }}</div>
        </v-flex>
        <v-flex>
          <div class="caption resGray-darken1--text font-weight-medium">Cycle end date</div>
          <div class="caption resGray-darken5--text font-weight-medium">{{ formatDate(cycle.cycleEndDate) }}</div>
          <div class="caption resGray-darken1--text font-weight-medium mt-2">Reconciliation locked</div>
          <div class="caption resGray-darken5--text font-weight-medium">{{ cycle.reconcileIsLocked ? 'YES' : 'NO' }}</div>
        </v-flex>
        <v-flex>
          <div class="caption resGray-darken1--text font-weight-medium">Reconciliation charge date</div>
          <div class="caption resGray-darken5--text font-weight-medium">{{ formatDate(cycle.reconcileChargeDate) }}</div>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex text-right>
          <div class="caption resGray-darken1--text font-weight-medium">Actuals start Date</div>
          <div class="caption resGray-darken5--text font-weight-medium">{{ formatDate(cycle.cycleStartDate) }}</div>
        </v-flex>
        <v-flex text-right>
          <div class="caption resGray-darken1--text font-weight-medium">Actuals end Date</div>
          <div class="caption resGray-darken5--text font-weight-medium">{{ formatDate(cycle.cycleEndDate) }}</div>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
import moment from 'moment'

export default {
  props: ['cycle'],
  data () {
    return {
      content: {
        current: 'test'
      }
    }
  },
  methods: {
    formatDate (val) {
      if (val) {
        return moment(val).format('MM/DD/YYYY')
      } else {
        return '-'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .container-card {
    border: 1px solid var(--v-resGray-lighten1);
  }
</style>
