<template>
  <div class="drop-down" :style="dropdownProps">
    <label v-if="label !== undefined" class="drop-down__label d-block mb-0">{{ label }}</label>
    <v-combobox
      v-model="currentItem"
      class="drop-down__combobox ma-0 pa-0"
      single-line
      outlined
      height="31"
      solo
      flat
      dense
      return-object
      :disabled="disabled"
      v-validate:currentItem="rules"
      data-vv-name="currentItem"
      :error-messages="errors.collect('currentItem')"
      :mask="mask"
      :items="list"
      :filter="filter"
      :loading="currentLoading"
      :label="labelDropdown"
      :multiple="multiple"
      :hide-details="hideDetails"
      :menu-props="{ closeOnContentClick: false, contentClass: menuClass }"
      @change="selectItem">
      <template v-if="showCheckBox" v-slot:prepend-item>
        <v-list-item class='drop-down__header'>
          <v-list-item-content>
            <v-layout class="checkbox__container">
              <v-checkbox
                height="20"
                :ripple="false"
                v-model="checkBoxValue"
                @change='checkBoxChanged'
                class="shrink ma-0 pa-0"
                color="var(--v-resBlue-darken1)">
              </v-checkbox>
              <span class="checkbox-label">{{ checkBoxText }}</span>
            </v-layout>
          </v-list-item-content>
        </v-list-item>
      </template>

      <template v-slot:selection="{ index }">
        <span v-if="index==0" v-html="textShortener(displayedValue)"></span>
      </template>

      <template v-slot:item="{ item }" v-if="type==='sqFtMeasures'">
        <v-list-item-content>
          <div class="v-list__tile__title">
            {{ item.text }}
          </div>
          <span v-if="item.value.includes('*')" class="property-updated">*Property updated</span>
        </v-list-item-content>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as validation from '../../utility/validation'

export default {
  props: [
    'items',
    'label',
    'multiple',
    'selectedValue',
    'getParams',
    'genericLabel',
    'type',
    'width',
    'mask',
    'filter',
    'defaultFirst',
    'disabled',
    'rules',
    'customList',
    'hideDetails',
    'multipleDetail',
    'noRefresh',
    'showCheckBox',
    'checkBoxText'
  ],
  data () {
    return {
      currentItem: null,
      inactive: false,
      checkBoxValue: false
    }
  },
  methods: {
    ...mapActions(['fetchDropdown', 'initializeStoreDropdown']),
    selectItem (e) {
      if (validation.hasValue(e)) {
        this.$emit('itemSelected', this.currentItem)
      } else {
        this.$emit('itemSelected', { text: null, value: null })
      }
    },
    initialize () {
      if (this.defaultFirst && (this.selectedValue === undefined || this.selectedValue.text === undefined) && this.currentDropdown(this.items).length > 0) {
        this.currentItem = this.currentDropdown(this.items)[0]
        this.$emit('itemSelected', this.currentItem)
      }
      if (this.multiple) {
        this.currentItem = this.selectedValue
      }
      if (this.selectedValue) {
        if (this.currentDropdown(this.items).length > 0) {
          if (this.selectedValue.value) {
            this.currentItem = this.currentDropdown(this.items).find(x => x.value === this.selectedValue.value)
            this.$emit('itemSelected', this.currentItem)
          }
          // find by text if unable to select by value
          if (!this.currentItem && this.selectedValue.text) {
            this.currentItem = this.currentDropdown(this.items).find(x => x.text === this.selectedValue.text)
            this.$emit('itemSelected', this.currentItem)
          }
        } else {
          this.currentItem = this.selectedValue
        }
      } else {
        this.currentItem = null
      }
    },
    refresh () {
      this.fetchDropdown({ newDropdown: this.items, params: this.getParams })
    },
    validate () {
      if (!this.inactive) {
        this.$validator.validateAll()
      }
    },
    textShortener (value) {
      if (this.width !== undefined && this.width !== null) {
        let maxChars = Math.floor(this.width.split('p')[0]) / (8 * (Math.pow(this.width.split('p')[0], -0.5) + Math.pow(this.width.split('p')[0], -0.5) + 0.8))
        if (value && value.length > maxChars) {
          return `${value.substr(0, maxChars - 3)}...`
        } else {
          return value
        }
      } else {
        return value
      }
    },
    checkBoxChanged (val) {
      this.$emit('checkBoxChanged', val)
    },
    resetCheckBox () {
      this.checkBoxValue = false
    }
  },
  created () {
    if (this.noRefresh && this.currentDropdown(this.items).length > 0) {
      this.initialize()
    } else {
      this.fetchDropdown({ newDropdown: this.items, params: this.getParams }).then(() => {
        this.initialize()
      })
    }
    this.$eventHub.$on('refreshDropdown', this.refresh)
    this.$eventHub.$on('validate', this.validate)
  },
  beforeDestroy () {
    this.$eventHub.$off('refreshDropdown')
    this.$eventHub.$off('validate')
    this.initializeStoreDropdown(this.items)
  },
  computed: {
    ...mapGetters(['currentDropdown', 'currentLoading']),
    dropdownProps () {
      return {
        '--width': this.width
      }
    },
    displayedValue () {
      if (this.currentItem && this.multiple && this.currentItem.length > 0) {
        return `(${this.currentItem.length}) ${this.multipleDetail} selected`
      }
      if (this.type === 'sqFtMeasures') {
        if (this.currentItem && this.currentItem.text === 'Today') {
          return this.currentItem.value
        } else if (!this.currentItem.value) {
          return this.currentItem
        }
      }
      if (this.currentItem != null && this.currentItem.text != null) {
        return this.currentItem.text
      } else {
        return null
      }
    },
    labelDropdown () {
      if (this.type === 'sqFtMeasures') return ''
      if (this.genericLabel || this.customList) {
        return 'Select Option'
      }
      return 'Select ' + this.items
    },
    list () {
      if (this.type === 'sqFtMeasures') {
        return this.currentDropdown(this.items).map((item, index) => {
          if (index === 1) {
            item.disabled = true
          }
          return item
        })
      }
      if (this.customList) {
        return this.customList
      }
      return this.currentDropdown(this.items)
    },
    menuClass () {
      let specialClass = this.type === 'sqFtMeasures' ? 'sqFtMeasures' : ''
      return `drop-down__menu ${specialClass}`
    }
  },
  activated: function () {
    this.inactive = false
  },
  deactivated: function () {
    this.inactive = true
    this.$validator.reset()
  },
  watch: {
    selectedValue: function () {
      if (this.multiple && this.selectedValue.length > 0) {
        this.currentItem = this.selectedValue
        return
      }
      if (!this.selectedValue) {
        this.currentItem = null
      } else {
        if (this.selectedValue.text !== undefined) {
          this.currentItem = this.selectedValue
        } else {
          this.currentItem = null
        }
      }
    },
    items: function () {
      this.fetchDropdown({ newDropdown: this.items, params: this.getParams }).then(() => {
        this.initialize()
      }).catch(error => { console.log(error.response) })
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-text-field.v-text-field--solo.v-input--dense>.v-input__control {
    min-height: 31px !important;
    margin: 0;
    padding: 0;
  }

  .drop-down  {
    display: inline-block;
    min-width: 50px;
    max-width: 340px;
    width: var(--width);
    min-height: 31px;
    max-height: 31px;
    height: 31px;
    ::v-deep .v-text-field--outlined>.v-input__control>.v-input__slot {
      background-color: var(--v-resWhite-base) !important;
      min-height: 31px;
      max-height: 31px;
      height: 31px;
    }
    ::v-deep .v-input__control {
      width: var(--width);
      min-height: 31px;
      max-height: 31px;
      height: 31px;
    }
    ::v-deep .v-input__slot {
      padding-right:0px !important;
      margin-bottom: 0px;
      min-height: 31px;
      max-height: 31px;
      height: 31px;
      span {
        font-size: 12px;
        white-space: nowrap;
      }
      ::v-deep .v-select__selections {
        width: calc(var(--width) - 42px);
        min-height: 31px;
        max-height: 31px;
        height: 31px;
      }
    }
    ::v-deep .v-input__append-inner {
      background-color: var(--v-resBlue-darken1);
      border-radius: 0px 4px 4px 0px;
      padding: 4px 0px 2px 0px;
      margin-top: 1px;
      .v-icon {
        color: var(--v-resWhite-base) !important
      }
    }
    ::v-deep .v-text-field__details {
      margin-bottom: 0px;
    }
    .prepend_item {
      border-style: solid;
      border-width: 2px;
    }
    ::v-deep .v-text-field--box .v-input__slot,
    ::v-deep .v-text-field--outline .v-input__slot {
      min-height: auto!important;
      display: flex!important;
      align-items: center!important;
      border-radius: 6px!important;
      border: solid 1px var(--v-resGray-darken2) !important;
      text-align: left!important;
      min-height: 31px;
      max-height: 31px;
      height: 31px;
    }
    ::v-deep .v-icon {
      font-size: 20px;
    }
    &__menu {
      .v-select-list {
        ::v-deep .v-list__tile {
          min-height: 31px;
          max-height: 31px;
          height: 31px !important;
        }
        ::v-deep .v-icon {
          font-size: 15px;
        }
      }
      .v-card {
        border-radius: 6px !important;
      }
      .v-select-list {
        ::v-deep .v-list {
          padding-bottom: 0px;
        }
        ::v-deep .v-list__tile {
          font-size: 13px;
        }
        ::v-deep .v-list__tile__action, .v-list__tile__avatar {
          min-width: 32px;
        }
      }
    }
    &__combobox {
      ::v-deep input {
        font-size: 12px;
      }
      ::v-deep .v-label {
        font-size: 12px;
      }
      ::v-deep .v-select__slot {
        display: -webkit-box;
      }
    }
    &__label {
      font-size: 12px;
    }
    &__header {
      border-bottom: 1px solid var(--v-resGray-darken1);
      &.v-list-item {
        height: 30px;
        min-height: 30px !important;
      }
    }
    &__footer {
      background-color: var(--v-resBlue-base);
      color: var(--v-resWhite-base);
      text-align: center;
      text-transform: uppercase;
    }
  }

  ::v-deep .checkbox__container {
    height: 31px;
    ::v-deep .v-icon {
      font-size: 18px;
    }
    ::v-deep .v-input__slot {
      width: 20px;
    }
  }

  span.checkbox-label {
    padding-top: 8px;
  }

  .v-overflow-btn .v-input__append-inner {
    padding-left: 10px;
    height: 32px!important;
    width: 30px!important;
    margin-top: 0px!important;
  }

  .close-button {
    margin-left: auto;
    margin-right: auto;
  }

  .sqFtMeasures {
    .property-updated {
      font-size: 10px;
      font-style: italic;
      text-align: right;
      float: right;
    }
    .v-list__tile__content {
      align-items: flex-end;
    }
    .v-select-list {
      ::v-deep .v-list__tile {
        .v-list__tile__content {
          .v-list__tile__title {
            height: 20px;
            line-height: 20px;
            text-align: right;
            font-size: 12px;
          }
        }
        &--active {
          color: var(--v-resGray-darken5) !important;
        }
      }
      ::v-deep .v-list {
        div:nth-child(1) {
          .v-list__tile {
            .v-list__tile__content:not(.no-item) {
              .v-list__tile__title {
                color: var(--v-resBlue-darken1);
              }
            }
          }
        }
        div:nth-child(2) {
          .v-list__tile {
            padding: 0 5px;
            .v-list__tile__content:not(.no-item) {
              position: relative;
              z-index: 1;
              &:before {
                border-top: 1px solid var(--v-resGray-darken1);
                content:"";
                margin: 0 auto;
                position: absolute;
                top: 50%; left: 0; right: 0; bottom: 0;
                width: 100%;
                z-index: -1;
              }
              .v-list__tile__title {
                margin-left: auto;
                margin-right: auto;
                display: inline-block;
                width: 50%;
                color: var(--v-resGray-darken1);
                font-size: 11px;
                text-align: center;
                background: var(--v-resWhite-base);
                padding: 0 5px;
              }
            }
          }
        }
      }
    }
  }
</style>
