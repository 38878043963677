<template>
  <div>
    <v-flex mt-4 mb-2>
      <span class="title">New Charges</span>
    </v-flex>
    <v-data-table
      hide-default-footer
      class="distribution"
      :headers="headers"
      :items="totalDistributedCharges">
      <template v-slot:headers="props">
        <tr>
          <th v-for="header in props.headers" :key="header.value"
            role="columnheader"
            scope="col"
            :style="`width: ${header.width}px`"
            :class="['column', `text-${header.align}`]">
            <span class="header__text">
              {{ header.title }}
            </span>
          </th>
        </tr>
      </template>
      <template v-slot:items="props">
        <td>
          <drop-down
            :selectedValue="{ text: props.item.chargeCodeName }"
            :customList="chargeCodes"
            :multiple='false'
            width="125px"
            v-if="!completed"
            :hideDetails='true'
            @itemSelected="selectChargeCode(props.item, $event)">
          </drop-down>
          <span v-if="completed">{{ props.item.chargeCodeName }}</span>
        </td>
        <td>
          <text-input
            :value="props.item.reference"
            width="190px"
            v-if="!completed"
            :hideDetails='true'
            @textInputUpdated="updateItemReference(props.item, $event)">
          </text-input>
          <span v-if="completed">{{ props.item.reference }}</span>
        </td>
        <td>
          <drop-down
            :selectedValue="{ text: props.item.paymentCompany }"
            :multiple='false'
            width="125px"
            v-if="!completed"
            :disabled="true"
            :hideDetails='true'>
          </drop-down>
          <span v-if="completed">{{ props.item.paymentCompany }}</span>
        </td>
        <td>
          <drop-down
            :selectedValue="{ text: props.item.paymentBankAccount }"
            :customList="banks"
            :multiple='false'
            width="125px"
            v-if="!completed"
            :disabled="true"
            :hideDetails='true'
            @itemSelected="selectBankAccount(props.item, $event)">
          </drop-down>
          <span v-if="completed">{{ props.item.paymentBankAccount }}</span>
        </td>
        <td class="text-center">
          <text-input
            :value="props.item.baseAmount"
            width="100px"
            :prefix="'$'"
            v-if="!completed"
            textFormat="currency"
            rules="decimal:2"
            :hideDetails='true'
            @textInputUpdated="updateItemBaseAmount(props.item, $event)">
          </text-input>
          <span v-if="completed">$ &emsp; &emsp; {{ props.item.baseAmount | currencyFilter(props.item.baseAmount) }}</span>
        </td>
        <td v-if="!completed">
          <v-layout row>
            <round-button
              v-if="props.item.Id !== 0"
              color='var(--v-resRed-base)'
              height='24px'
              width='24px'
              :icon='true'
              :flat='true'
              iconText='close'
              :depressed='true'
              buttonClass='pa-0 ma-1'
              @click="removeDistribution(props.item)">
            </round-button>
            <v-spacer></v-spacer>
            <round-button
              color='var(--v-resBlue-darken1)'
              height='24px'
              width='24px'
              :icon='true'
              :flat='true'
              iconText='add_circle'
              :depressed='true'
              buttonClass='ma-1 pa-0'
              @click="addNewDistribution">
            </round-button>
          </v-layout>
        </td>
      </template>
      <template v-slot:footer>
        <td :colspan="headers.length">
          <v-layout row>
            <v-spacer></v-spacer>
            <label class="">Rows  1 - {{ totalDistributedCharges.length }} ({{ totalDistributedCharges.length }} Total)</label>
          </v-layout>
        </td>
      </template>
    </v-data-table>
    <v-layout>
      <v-spacer></v-spacer>
      <round-button
        height='30px'
        width='80px'
        v-if="!completed"
        :buttonText="'Cancel'"
        :buttonTextColor="'var(--v-resRed-base)'"
        :buttonClass="'mt-4 mr-2'"
        :borderColor="'var(--v-resRed-base)'"
        background-color="var(--v-resWhite-base)"
        :depressed="true"
        :flat="true"
        :outline="true"
        @click='cancel'>
      </round-button>
      <round-button
        height='30px'
        width='80px'
        v-if="!completed"
        :buttonText="'Skip'"
        :buttonClass="'mt-4'"
        :borderColor="'var(--v-resBlue-darken1)'"
        background-color="var(--v-resBlue-darken1)"
        :depressed="true"
        :flat="true"
        :outline="true"
        @click='nextStep'>
      </round-button>
      <round-button
        height='30px'
        buttonClass='mt-4 ml-2 mr-0'
        width='80px'
        :depressed='true'
        :flat='true'
        v-if="!completed"
        :backgroundColor="'var(--v-resGreen-lighten1)'"
        :buttonText="'Save'"
        @click="save">
      </round-button>
      <round-button
        height='30px'
        buttonClass='mr-0 mt-4'
        width='80px'
        :depressed='true'
        :flat='true'
        v-if="completed"
        :backgroundColor="'var(--v-resBlue-darken1)'"
        :buttonText="'Next'"
        @click="nextStep">
      </round-button>
    </v-layout>
  </div>
</template>

<script>
import RoundButton from '../../../BaseComponents/RoundButton'
import DropDown from '../../../BaseComponents/DropDown'
import TextInput from '../../../BaseComponents/TextInput'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import * as currency from '../../../../utility/currency'

export default {
  props: ['currentCheck'],
  components: {
    RoundButton,
    DropDown,
    TextInput
  },
  data () {
    return {
      completed: false,
      totalDistributedCharges: []
    }
  },
  methods: {
    ...mapActions(['fetchDropdown', 'changeData', 'fetchData', 'changeRefundOpenCharges']),
    nextStep () {
      this.$emit('newChargesComplete')
    },
    updateItemBaseAmount (item, val) {
      item.baseAmount = currency.parseCurrency(val)
    },
    updateItemReference (item, val) {
      item.reference = val
    },
    selectChargeCode (item, val) {
      item.chargeCode = val.value
      item.chargeCodeName = val.text
    },
    selectBankAccount () {
    },
    cancel () {
      this.$emit('cancelRefund')
    },
    getChargeCodes () {
      this.fetchDropdown({ newDropdown: 'Filtered Charge Codes', params: { Property: this.currentCheck.property } }).catch(error => { console.log(error.response) })
    },
    getBanks () {
      this.fetchDropdown({ newDropdown: 'Banks' }).catch(error => { console.log(error.response) })
    },
    save () {
      if (this.totalDistributedCharges.length > 0) {
        let sendData = JSON.parse(JSON.stringify(this.currentCheck))
        delete sendData.leaseCharge
        sendData.journalReference = 'Refund'
        sendData.postingDate = moment().format('L')
        sendData.baseAmount = this.newChargesTotal
        sendData.chargeItems = this.totalDistributedCharges
        this.changeData({ currentPage: 'AdjustCharges', newData: sendData }).then(() => {
          this.completed = true
        }).catch(error => { console.log(error.response) })
      }
    },
    addNewDistribution () {
      var newCharge = JSON.parse(JSON.stringify(this.leaseItem))
      newCharge.account = null
      newCharge.accountList = null
      newCharge.chargeCode = undefined
      newCharge.chargeCodeName = undefined
      newCharge.reference = null
      newCharge.baseAmount = null
      newCharge.leaseItem = 1
      newCharge.detailItem = 1
      newCharge.lineItem = 0
      newCharge.quantity = 0
      newCharge.Action = ''
      newCharge.Id = this.totalDistributedCharges.length
      this.totalDistributedCharges.unshift(newCharge)
    },
    removeDistribution (val) {
      var index = this.totalDistributedCharges.indexOf(val)
      this.totalDistributedCharges.splice(index, 1)
    }
  },
  computed: {
    ...mapGetters(['currentDropdown', 'currentData']),
    chargeCodes () {
      return this.currentDropdown('Filtered Charge Codes')
    },
    banks () {
      return this.currentDropdown('Banks')
    },
    leaseItem () {
      return this.currentData('AdjustCharges')
    },
    headers () {
      if (this.completed) {
        return [
          { title: 'Charge Code', value: 'chargeCode', align: 'left' },
          { title: 'Description', value: 'reference', align: 'left' },
          { title: 'Receiving Entity', value: 'paymentCompany', align: 'left' },
          { title: 'Bank', value: 'paymentBankAccount', align: 'left' },
          { title: 'Amount', value: 'baseAmount', align: 'center' }
        ]
      }
      return [
        { title: 'Charge Code', value: 'chargeCode', align: 'left' },
        { title: 'Description', value: 'reference', align: 'left' },
        { title: 'Receiving Entity', value: 'paymentCompany', align: 'left' },
        { title: 'Bank', value: 'paymentBankAccount', align: 'left' },
        { title: 'Amount', value: 'baseAmount', align: 'center' },
        { title: 'Action', value: 'Action', align: 'center' }
      ]
    },
    refundTotal () {
      return currency.convertToCurrency(this.currentCheck.baseAmount)
    },
    newChargesTotal () {
      let sum = Number(0)
      this.totalDistributedCharges.forEach(item => {
        sum += currency.parseCurrency(item.baseAmount)
      })
      return sum
    }
  },
  created () {
    this.fetchData({ newPage: 'AdjustCharges', params: { Lease: this.currentCheck.lease, Property: this.currentCheck.property, PropertySpace: this.currentCheck.propertySpace } }).then(() => {
      this.addNewDistribution()
      this.getBanks()
      this.getChargeCodes()
      this.changeRefundOpenCharges([])
    }).catch(error => { console.log(error.response) })
  }
}
</script>

<style lang="scss" scoped>
.distribution {
  width: 1000px;
  thead {
    tr {
      height: 35px;
      background-color: var(--v-resGray-lighten3);
      font-size: 12px;
      th {
        padding: 0 14px;
        color: var(--v-resGray-darken5);
      }
    }
  }
  tbody {
    tr {
      height: 35px;
      td {
        padding: 0 14px;
      }
    }
  }
}

::v-deep table.v-table tfoot {
  height: 40px;
  tr {
    height: 40px;
    td {
      height: 40px;
      label {
        font-size: 12px;
      }
    }
  }
}
</style>
