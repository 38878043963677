<template>
  <v-container class="resGray lighten-6">
    <payments
      v-if="currentState === 'Payment'"
      @selectPayment='selectPayment'>
    </payments>
    <v-layout align-center justify-space-between column fill-height>
      <refund-details
      v-if="currentState !== 'Payment'"
      @returnToPayments='returnToPayments'
      :distributedTotal='distributedTotal'
      :currentCheck='currentPayment'>
      </refund-details>
      <new-charges
      v-if="currentState === 'NewCharges'"
      :currentCheck='currentPayment'
      @cancelRefund='returnToPayments'
      @newChargesComplete='newChargesComplete'>
      </new-charges>
      <current-charges-details
      v-if="currentState === 'CurrentCharges'"
      :currentCheck='currentPayment'
      @currentChargesComplete='currentChargesComplete'
      @updateDistributedTotal='updateDistributedTotal'
      @backToNewCharges='backToNewCharges'>
      </current-charges-details>
      <clearing-account
      v-if="currentState === 'ClearingAccount'"
      @refundComplete='returnToPayments'
      @backToCurrentCharges='backToCurrentCharges'
      @updateDistributedTotal='updateDistributedTotal'
      :currentCheck='currentPayment'>
      </clearing-account>
    </v-layout>
  </v-container>
</template>

<script>
import Payments from './RefundComponents/Payments'
import CurrentChargesDetails from './RefundComponents/CurrentChargesDetails'
import NewCharges from './RefundComponents/NewCharges'
import ClearingAccount from './RefundComponents/ClearingAccount'
import RefundDetails from './RefundComponents/RefundDetails'

export default {
  components: {
    Payments,
    CurrentChargesDetails,
    NewCharges,
    ClearingAccount,
    RefundDetails
  },
  data () {
    return {
      currentPayment: {},
      currentState: 'Payment',
      distributedTotal: 0
    }
  },
  methods: {
    selectPayment (val) {
      this.currentPayment = val
      this.currentState = 'NewCharges'
    },
    returnToPayments () {
      this.currentState = 'Payment'
      this.currentPayment = {}
    },
    newChargesComplete () {
      this.currentState = 'CurrentCharges'
    },
    currentChargesComplete () {
      this.currentState = 'ClearingAccount'
    },
    backToNewCharges () {
      this.distributedTotal = 0
      this.currentState = 'NewCharges'
    },
    backToCurrentCharges () {
      this.currentState = 'CurrentCharges'
    },
    updateDistributedTotal (val) {
      this.distributedTotal = val
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
