import Vue from 'vue'
import Axios from '../../services/api'
import dataAccessConfiguration from '../dataAccessConfiguration.json'
import dropdownConfiguartion from '../dropdownConfiguration.json'

const baseURL = process.env.VUE_APP_API_URL

const state = {
  dropdown: [],
  currentDropdown: '',
  container: [],
  getParams: {}
}

const getters = {
  currentDropdown: (state) => dropdown => {
    var data = []
    if (state.dropdown.filter(item => {
      return item.currentDropdown === dropdown
    }).length > 0) {
      data = state.dropdown.filter(item => {
        return item.currentDropdown === dropdown
      })[0]
      return data.data
    }
    return data
  },
  currentData: (state) => page => {
    var dataContainer = state.container.find(item => {
      return item.currentPage === page
    })

    if (dataContainer) {
      if (Array.isArray(dataContainer.data)) {
        return dataContainer.data.map(d => {
          if (d) {
            Vue.set(d, 'readonly', !(d.new === true))
            return d
          }
        })
      } else {
        return dataContainer.data
      }
    } else {
      return []
    }
  },
  currentHeaders: (state) => page => {
    var data = []
    if (state.container.filter(item => {
      return item.currentPage === page
    }).length > 0) {
      data = state.container.filter(item => {
        return item.currentPage === page
      })[0]
      return data.headers
    }
    return data
  },
  currentItem: (state) => (index) => {
    return state.data[index]
  },
  currentPlaceholder: (state) => page => {
    var data = []
    if (state.container.filter(item => {
      return item.currentPage === page
    }).length > 0) {
      data = state.container.filter(item => {
        return item.currentPage === page
      })[0]
      return data.headers[0].displayName
    }
    return data
  },
  currentGetParams: (state) => {
    return state.getParams
  }
}

const actions = {
  changeData ({ dispatch, state, commit }, payload) {
    var index = state.container.findIndex(x => x.currentPage === payload.currentPage)
    var selectConfig = {}
    if (~index) {
      selectConfig = state.container[index].api
      if (payload.updateItem) {
        var itemIndex = []
        if (Array.isArray(payload.updateItem)) {
          payload.updateItem.forEach(function (item) {
            itemIndex.push(state.container[index].data.indexOf(item))
          })
        } else {
          if (state.container[index].data.length > 0) {
            itemIndex = state.container[index].data.indexOf(payload.updateItem)
          } else {
            itemIndex = 0
          }
        }
      }
    } else {
      selectConfig = dataAccessConfiguration.filter(item => {
        return item.currentPage === payload.currentPage
      })[0]
      if (selectConfig) {
        commit('setNewCurrentPage', selectConfig)
        index = state.container.length - 1
        if (payload.updateItem) {
          itemIndex = 0
        }
      }
    }
    if (selectConfig.subApi) {
      const PostData = { ...payload.newData }
      dispatch('changeLoading', true, {root: true})
      return new Promise((resolve, reject) => {
        Axios.post(baseURL + selectConfig.subApi + selectConfig.saveApi, PostData)
          .then((response) => {
            if (~itemIndex) {
              commit('setUpdatedData', { containerIndex: index, index: itemIndex, itemUpdate: response.data.data, headers: response.data.headers })
            }
            dispatch('changeSnackbar', {isSnackbar: true, snackbarMsg: response.data.message, snackbarSuccess: response.data.succeeded}, {root: true})
            dispatch('changeLoading', false, {root: true})
            resolve()
          }).catch(function (error) {
            dispatch('handlerError', error)
            reject(error)
          })
      })
    }
  },
  addNew ({ commit }, payload) {
    commit('setNew', payload)
  },
  fetchDropdown ({ commit, dispatch }, payload) {
    var selectConfig = dropdownConfiguartion.filter(item => {
      return item.currentDropdown === payload.newDropdown
    })[0]
    if (selectConfig) {
      if (payload.params) {
        dispatch('setParams', payload.params)
      }

      dispatch('changeLoading', true, { root: true })
      return new Promise((resolve, reject) => {
        Axios.get(baseURL + selectConfig.dropdownSubApi + selectConfig.dropdownApi, { params: state.getParams })
          .then((response) => {
            commit('setFetchedDropdown', { response: response.data, selectConfig: selectConfig })
            dispatch('changeLoading', false, { root: true })
            resolve()
          }).catch(function (error) {
            dispatch('handlerError', error)
            reject()
          })
        commit('clearParams')
      })
    }
  },
  setParams ({ commit }, params) {
    commit('setGetParams', params)
  },
  fetchData ({ commit, dispatch, state }, payload) {
    var selectConfig = dataAccessConfiguration.filter(item => {
      return item.currentPage === payload.newPage
    })[0]
    if (selectConfig) {
      dispatch('setParams', payload.params)

      dispatch('changeLoading', true, {root: true})
      return new Promise((resolve, reject) => {
        Axios.get(baseURL + selectConfig.subApi + selectConfig.getApi, { params: state.getParams })
          .then((response) => {
            commit('setFetchedData', { response: response.data, selectConfig: selectConfig })
            dispatch('changeLoading', false, {root: true})
            resolve()
          }).catch(function (error) {
            dispatch('handlerError', error)
            reject(error)
          })
        commit('clearParams')
      })
    }
  },
  deleteData ({ commit, dispatch, state }, payload) {
    var index = state.container.findIndex(x => x.currentPage === payload.currentPage)
    if (~index) {
      var selectConfig = state.container[index].api
      if (Array.isArray(state.container[index].data)) {
        var itemIndex = state.container[index].data.indexOf(payload.removeItem)
      }

      dispatch('changeLoading', true, {root: true})
      return new Promise((resolve, reject) => {
        Axios.post(baseURL + selectConfig.subApi + selectConfig.deleteApi, payload.removeItem)
          .then((response) => {
            dispatch('changeSnackbar', {isSnackbar: true, snackbarMsg: response.data.message, snackbarSuccess: response.data.succeeded}, {root: true})
            if (response.data.succeeded) {
              commit('removeData', { containerIndex: index, index: itemIndex, itemDelete: payload.removeItem })
              dispatch('changeLoading', false, {root: true})
              resolve()
            }
          }).catch(function (error) {
            dispatch('handlerError', error)
            reject(error)
          })
      })
    }
  },
  handlerError ({dispatch}, error) {
    if (error.response) {
      if (error.response.data && error.response.data.Message) {
        dispatch('changeErrorDialog', {isErrorDialog: true, errorDialogMsg: error.response.data.Message, errorDialogStatus: error.response.status, errorDialogStatusText: error.response.statusText}, {root: true})
      } else {
        dispatch('changeErrorDialog', {isErrorDialog: true, errorDialogMsg: error.response.data, errorDialogStatus: error.response.status, errorDialogStatusText: error.response.statusText}, {root: true})
      }
    } else if (error.request) {
      dispatch('changeErrorDialog', {isErrorDialog: true, errorDialogMsg: 'Service Unavailable.'}, {root: true})
    } else {
      dispatch('changeErrorDialog', {isErrorDialog: true, errorDialogMsg: error.message}, {root: true})
    }
    dispatch('changeLoading', false, {root: true})
  },
  cancelNew ({ commit }, payload) {
    var index = state.container.findIndex(x => x.currentPage === payload.currentPage)
    if (~index) {
      if (payload.index) {
        commit('removeData', { containerIndex: index, index: payload.index })
      } else {
        commit('removeData', { containerIndex: index, index: 0 })
      }
    }
  },
  initializeStoreItem ({ commit }, page) {
    var index = state.container.findIndex(x => x.currentPage === page)
    if (~index) {
      commit('clearStoreItem', index)
    }
  },
  initializeStoreDropdown ({ commit }, dropdown) {
    var index = state.dropdown.findIndex(x => x.currentDropdown === dropdown)
    if (~index) {
      commit('clearDropdownItem', index)
    }
  }
}

const mutations = {
  setNew: (state, response) => {
    var index = state.container.findIndex(x => x.currentPage === response.currentPage)
    if (~index) {
      if (response.index) {
        if (response.newItem) {
          state.container[index].data.splice(response.index, 0, response.newItem)
        } else {
          state.container[index].data.splice(response.index, 0, { readonly: false, new: true, actionColumn: { add: false, close: false, delete: true, edit: true } })
        }
      } else {
        if (response.newItem) {
          state.container[index].data.unshift(response.newItem)
        } else {
          state.container[index].data.unshift({ readonly: false, new: true, actionColumn: { add: false, close: false, delete: true, edit: true } })
        }
      }
    }
  },
  setNewCurrentPage: (state, response) => {
    state.container.push({ currentPage: response.currentPage, api: response, data: [], headers: [] })
  },
  setFetchedData: (state, response) => {
    var index = state.container.findIndex(x => x.currentPage === response.selectConfig.currentPage)
    if (~index) {
      state.container.splice(index, 1)
    }
    state.container.push({ currentPage: response.selectConfig.currentPage, api: response.selectConfig, data: response.response.data, headers: response.response.headers })
  },
  setUpdatedData: (state, response) => {
    if (response.index !== undefined) {
      if (response.headers && response.headers.length > 0) {
        state.container[response.containerIndex].headers = response.headers
      }
      if (Array.isArray(response.index)) {
        response.index.sort().reverse()
        response.index.forEach(function (item) {
          state.container[response.containerIndex].data.splice(item, 1)
        })
      } else {
        if (~response.index) {
          state.container[response.containerIndex].data.splice(response.index, 1)
        }
      }
      if (Array.isArray(response.itemUpdate)) {
        response.itemUpdate.forEach(function (item) {
          state.container[response.containerIndex].data.push(item)
        })
      } else {
        state.container[response.containerIndex].data.push(response.itemUpdate)
      }
    }
  },
  clearStoreItem: (state, index) => {
    state.container.splice(index, 1)
  },
  clearDropdownItem: (state, index) => {
    state.dropdown.splice(index, 1)
  },
  clearParams: (state) => {
    state.getParams = {}
  },
  setFetchedDropdown: (state, response) => {
    var index = state.dropdown.findIndex(x => x.currentDropdown === response.selectConfig.currentDropdown)
    if (~index) {
      Vue.set(state.dropdown, index, { currentDropdown: response.selectConfig.currentDropdown, api: response.selectConfig, data: response.response.data })
    } else {
      state.dropdown.push({ currentDropdown: response.selectConfig.currentDropdown, api: response.selectConfig, data: response.response.data })
    }
  },
  removeData: (state, removeItem) => {
    if (removeItem.index !== null && removeItem.index !== undefined) {
      state.container[removeItem.containerIndex].data.splice(removeItem.index, 1)
    } else {
      state.container[removeItem.containerIndex].data = {}
    }
  },
  setGetParams: (state, params) => {
    if (params) {
      state.getParams = Object.assign(state.getParams, params)
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
