<template>
  <setup-tabs :tabs="tabs">
    <setup-table></setup-table>
  </setup-tabs>
</template>

<script>
import SetupTabs from './SetupTabs'
import SetupTable from './SetupTable'

export default {
  data () {
    return {
      tabs: []
    }
  },
  components: {
    SetupTabs,
    SetupTable
  },
  created () {
    this.initialize()
  },
  methods: {
    initialize () {
      this.tabs = [
        { header: 'Charge Codes', dialog: 'charge-code-dialog' },
        { header: 'Lease Types' },
        { header: 'Amendment Types' },
        { header: 'Option Types' },
        // { header: 'Clause Types' },
        { header: 'Comment Types' },
        { header: 'Comment Sources' },
        { header: 'Insurance Types' }
        // { header: 'NAICS Codes' },
        // { header: 'National Accounts' }
      ]
    }
  }
}
</script>
