<template>
  <base-dialog
    :showDialog="showDialog"
    :title="dialogTitle"
    :titleClass="'dark-header'"
    :iconColor="'var(--v-resWhite-base)'"
    :maxWidth="600"
    :saveButtonText="'Yes, Proceed'"
    :saveButtonWidth="110"
    :cancelButtonColor="'var(--v-resBlue-darken1)'"
    :cancelButtonTextColor="'var(--v-resBlue-darken1)'"
    @cancel="cancel"
    @save="save">
    <v-card-text>
      <v-row no-gutters>
        <v-col>
          <span>
            <b>You are about to post charges for the following properties.</b>
          </span>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <span>
            <b>Are you sure you want to proceed?</b>
          </span>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <data-table
            class="mx-0 my-2"
            :allowNullColumn="true"
            :headers="summaryHeaders"
            :tableData="summaryData">
          </data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </base-dialog>
</template>

<script>
import BaseDialog from '../../../BaseComponents/BaseDialog'
import DataTable from '../../../BaseComponents/Table'
import { mapActions } from 'vuex'

export default {
  components: {
    BaseDialog,
    DataTable
  },
  props: ['showDialog', 'propertyName', 'tableData', 'headers', 'chargeDate'],
  data () {
    return {
      dialogTitle: 'Confirm posting'
    }
  },
  methods: {
    ...mapActions(['changeData', 'changeLoading']),
    cancel () {
      this.$emit('closeDialog', true)
    },
    save () {
      this.changeData({ currentPage: 'Monthly Recurring Charges',
        newData: {
          Properties: this.tableData.map(d => {
            return d.property
          }),
          ChargeDate: this.chargeDate
        }
      }).then(() => {
        this.changeLoading(false)
        this.$emit('saveDialogComplete', true)
      }).catch(error => {
        console.log(error.response)
        this.$emit('saveDialogComplete', true) // still want to reload
      })
      this.$emit('saveDialog', true)
    }
  },
  computed: {
    summaryHeaders () {
      return this.headers.filter(h => {
        return ['propertyName', 'chargeDate', 'postedCharge'].includes(h.value)
      }).map(h => {
        let summaryHeader = JSON.parse(JSON.stringify(h))
        if (summaryHeader.displayName.includes('Posted')) {
          summaryHeader.displayName = summaryHeader.displayName.replace('Posted', 'Total')
        }
        return summaryHeader
      })
    },
    summaryData () {
      return this.tableData.map(d => {
        let summary = JSON.parse(JSON.stringify(d))
        summary.postedCharge = summary.previewCharge
        return summary
      })
    }
  }
}
</script>
