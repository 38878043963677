<template>
  <v-btn-toggle
    mandatory
    v-model="selectedButton"
    @change="$emit('toggled', selectedButton)"
    class="button-group">
    <v-layout row wrap>
      <v-btn
        depressed
        color="var(--v-resWhite-base)"
        class="button-group__round-button px-2 mt-2 mb-2 mr-6"
        v-for="button in buttons"
        :key="button.text"
        :disabled="button.disabled"
        height="25px"
        width="130px"
        font-size
        active-class="v-btn--active button-group__round-button--active">
        <span>{{ button.textOverride || button.text }}</span>
      </v-btn>
    </v-layout>
  </v-btn-toggle>
</template>

<script>
export default {
  props: ['buttons'],
  data () {
    return {
      selectedButton: 0
    }
  }
}
</script>

<style lang="scss" scoped>
  .button-group {
    background: var(--v-resGray-lighten6) !important;
    box-shadow: none;
    &__round-button {
      width: 124px;
      height: 25px !important;
      border-radius: 15px !important;
      text-transform: capitalize;
      font-weight: 400;
      font-size: 12px;
      &.theme--light:not(.v-btn--active) {
        background-color: var(--v-resWhite-base) !important;
        border: solid 1px var(--v-resGray-lighten1)!important;
        color: var(--v-resGray-darken5) !important;
      }
      &.theme--dark {
        background-color: var(--v-resWhite-base) !important;
        color: var(--v-resGray-darken5)!important;
        border: solid 1px var(--v-resGray-lighten1) !important;
      }
      &--active{
        background-color: var(--v-resGray-darken5) !important;
        color: var(--v-resWhite-base) !important;
        font-weight: 400;
      }
    }
  }
  .v-btn:not(.v-btn--active) {
    opacity: 1;
  }
</style>
