<template>
  <imports-template
    ref='template'
    :canImport='canImport'
    :canExport='true'
    currentPage='GetImportLeasesData'
    @downloadTemplate='downloadTemplate'
    @clearImportData='clearImportData'
    @saveUpload='saveUpload'
    @addNew='addNewItem'
    @submit='submit'>
    <template v-slot:body>
      <data-table
        :headers="headers"
        :tableData="getData"
        @itemDeleteRequested="deleteItem"
        @unlockEditing="unlockEdit"
        @dataChanged="save"
        @canceled="cancel"
        class="mx-6"
        :noData="'Please select an import template to upload.'">
      </data-table>
      <v-layout row mt-1 v-if='showWarning'>
        <v-icon small color="var(--v-resRed-base)">warning</v-icon>
        <span class='resRed-base--text'>
            {{ warningMessage }}
        </span>
      </v-layout>
    </template>
  </imports-template>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as fileDownload from '../../../../utility/fileDownloader'
import * as validation from '../../../../utility/validation'
import DataTable from '../../../BaseComponents/Table'
import ImportsTemplate from './ImportsTemplate'
export default {
  components: {
    DataTable,
    ImportsTemplate
  },
  data () {
    return {
      files: {},
      importDialog: false,
      confirmDialog: false
    }
  },
  methods: {
    ...mapActions(['fetchData', 'changeData', 'initializeStoreItem', 'fetchDropdown', 'addNew']),
    deleteItem (item) {
      this.$refs.template.deleteItem(item, this.getData)
    },
    unlockEdit (item) {
      this.$refs.template.unlockEdit(item, this.getData)
    },
    cancel (item) {
      this.$refs.template.cancelEdit(item, this.getData)
    },
    save (item) {
      this.$refs.template.save(item)
    },
    addNewItem () {
      this.addNew({
        currentPage: 'GetImportLeasesData',
        newItem: {
          property: { text: null, value: null },
          name: null,
          leaseType: { text: null, value: null },
          startDate: null,
          endDate: null,
          reference: null,
          address1: null,
          address2: null,
          city: null,
          state: { text: null, value: null },
          country: { text: null, value: null },
          zipCode: null,
          jobTitle: null,
          firstName: null,
          lastName: null,
          emailAddress: null,
          phoneNumber: null,
          actionColumn: { delete: true, edit: true },
          readonly: false,
          new: true,
          errorToolTip: { hasIcon: false, tooltip: null, value: '' }
        }
      })
    },
    downloadTemplate () {
      this.fetchData({ newPage: 'ImportLeases' }).then(() => {
        let file = this.currentData('ImportLeases')
        let bufferArray = fileDownload.convertArrayBuffer(file.fileContent)
        fileDownload.createDownloadFile(bufferArray, file.fileName)
      }).catch(error => { console.log(error.response) })
    },
    saveUpload (item) {
      this.fetchDropdown({ newDropdown: 'State' }).catch(error => { console.log(error.response) })
      this.fetchDropdown({ newDropdown: 'Country' }).catch(error => { console.log(error.response) })
      this.fetchDropdown({ newDropdown: 'Lease Types' }).catch(error => { console.log(error.response) })
      this.fetchDropdown({ newDropdown: 'Properties', params: { IncludeIncomplete: false, IncompleteOnly: false } }).then(() => {
        this.changeData({ currentPage: 'GetImportLeasesData', newData: item, updateItem: {} }).then(() => {
          this.$refs.template.cancel()
        })
      }).catch(error => { console.log(error.response) })
    },
    submit () {
      this.changeData({ currentPage: 'ImportLeases', newData: { Leases: this.getData } }).then(() => {
        this.$refs.template.cancelImport()
      }).catch(error => { console.log(error.response) })
    },
    clearImportData () {
      this.initializeStoreItem('GetImportLeasesData')
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentDropdown']),
    headers () {
      let items = JSON.parse(JSON.stringify(this.currentHeaders('GetImportLeasesData')))
      let validation = items.splice(items.findIndex(x => x.value === 'errorToolTip'), 1)
      return validation.concat(items)
    },
    rawData () {
      return this.currentData('GetImportLeasesData')
    },
    getData () {
      let items = this.rawData
      let properties = this.currentDropdown('Properties')
      let propertiesLength = properties.length
      let leaseTypes = this.currentDropdown('Lease Types')
      let leaseTypesLength = leaseTypes.length
      let states = this.currentDropdown('State')
      let statesLength = states.length
      let countries = this.currentDropdown('Country')
      let countriesLength = countries.length
      items.forEach(item => {
        if (propertiesLength > 0 && validation.hasValue(item.property) && validation.hasValue(item.property.text)) {
          item.property = properties.find(x => {
            return x.text.toLowerCase() === item.property.text.toLowerCase()
          })
        }
        if (leaseTypesLength > 0 && validation.hasValue(item.leaseType) && validation.hasValue(item.leaseType.text)) {
          item.leaseType = leaseTypes.find(x => {
            return x.text.toLowerCase() === item.leaseType.text.toLowerCase()
          })
        }
        if (statesLength > 0 && validation.hasValue(item.state) && validation.hasValue(item.state.text)) {
          item.state = states.find(x => {
            return x.text.toLowerCase() === item.state.text.toLowerCase()
          })
        }
        if (countriesLength > 0 && validation.hasValue(item.country) && validation.hasValue(item.country.text)) {
          item.country = countries.find(x => {
            return x.text.toLowerCase() === item.country.text.toLowerCase()
          })
        }
        let errors = validation.validateValue(item, [
          { value: 'property', required: true },
          { value: 'name', required: true, maxLength: 50 },
          { value: 'leaseType', required: true },
          { value: 'startDate', required: true },
          { value: 'endDate', required: true },
          { value: 'reference', maxLength: 50 },
          { value: 'address1', maxLength: 50 },
          { value: 'address2', maxLength: 50 },
          { value: 'city', maxLength: 50 },
          { value: 'zipcode', maxLength: 15 },
          { value: 'jobTitle', maxLength: 50 },
          { value: 'firstName', maxLength: 50 },
          { value: 'lastName', maxLength: 50 },
          { value: 'emailAddress', maxLength: 75 },
          { value: 'phoneNumber', maxLength: 15 }
        ], items)
        validation.handleErrorToolTip(item, errors)
      })
      return items
    },
    canImport () {
      if (this.getData.length > 0 && !this.showWarning) {
        return true
      }
      return false
    },
    warningMessage () {
      return this.getData.filter(x => x.errorToolTip.hasIcon === true).length + ' row(s) have errors. Please fix these to continue.'
    },
    showWarning () {
      return this.getData.filter(x => x.errorToolTip.hasIcon === true).length > 0
    }
  },
  beforeDestroy () {
    this.clearImportData()
  }
}
</script>
