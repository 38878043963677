/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'txt': {
    width: 16,
    height: 16,
    viewBox: '0 0 56 56',
    data: '<path pid="0" d="M36.985 0H7.963C7.155 0 6.5.655 6.5 1.926V55c0 .345.655 1 1.463 1h40.074c.808 0 1.463-.655 1.463-1V12.978c0-.696-.093-.92-.257-1.085L37.607.257A.884.884 0 0 0 36.985 0z" fill="#e9e9e0"/><path pid="1" fill="#d9d7ca" d="M37.5.151V12h11.849z"/><path pid="2" d="M48.037 56H7.963A1.463 1.463 0 0 1 6.5 54.537V39h43v15.537c0 .808-.655 1.463-1.463 1.463z" fill="#95a5a5"/><g fill="#fff"><path pid="3" d="M21.867 42.924v1.121h-3.008V53h-1.654v-8.955h-3.008v-1.121h7.67zM28.443 48.105L31 53h-1.9l-1.6-3.801h-.137L25.641 53h-1.9l2.557-4.895-2.721-5.182h1.873l1.777 4.102h.137l1.928-4.102h1.873l-2.722 5.182zM40.529 42.924v1.121h-3.008V53h-1.654v-8.955h-3.008v-1.121h7.67z"/></g><path pid="4" d="M18.5 13h-6a1 1 0 1 1 0-2h6a1 1 0 1 1 0 2zM21.5 18h-9a1 1 0 1 1 0-2h9a1 1 0 1 1 0 2zM25.5 18c-.26 0-.521-.11-.71-.29-.181-.19-.29-.44-.29-.71s.109-.52.3-.71c.36-.37 1.04-.37 1.41 0 .18.19.29.45.29.71 0 .26-.11.52-.29.71-.19.18-.45.29-.71.29zM37.5 18h-8a1 1 0 1 1 0-2h8a1 1 0 1 1 0 2zM12.5 33c-.26 0-.521-.11-.71-.29-.181-.19-.29-.45-.29-.71 0-.26.109-.52.29-.71.37-.37 1.05-.37 1.42.01.18.18.29.44.29.7 0 .26-.11.52-.29.71-.19.18-.45.29-.71.29zM24.5 33h-8a1 1 0 1 1 0-2h8a1 1 0 1 1 0 2zM43.5 18h-2a1 1 0 1 1 0-2h2a1 1 0 1 1 0 2zM34.5 23h-22a1 1 0 1 1 0-2h22a1 1 0 1 1 0 2zM43.5 23h-6a1 1 0 1 1 0-2h6a1 1 0 1 1 0 2zM16.5 28h-4a1 1 0 1 1 0-2h4a1 1 0 1 1 0 2zM30.5 28h-10a1 1 0 1 1 0-2h10a1 1 0 1 1 0 2zM43.5 28h-9a1 1 0 1 1 0-2h9a1 1 0 1 1 0 2z" fill="#c8bdb8"/>'
  }
})
