<template>
  <imports-template
    ref='template'
    :canImport='canImport'
    :canExport='canExport'
    currentPage='GetImportSqFtMeasuresData'
    @addNew='addNewItem'
    @downloadTemplate='downloadTemplate'
    @clearImportData='clearImportData'
    @saveUpload='saveUpload'
    @submit='submit'>
    <template v-slot:header>
      <drop-down
        :selectedValue="{ text: selectedPropertyName }"
        :items="'Properties'"
        :label="'Property'"
        :multiple='false'
        width="200px"
        hideDetails
        rules="required"
        class="ml-2 mr-3"
        :getParams="{ IncludeIncomplete: false, IncompleteOnly: false }"
        @itemSelected="selectProperty">
      </drop-down>
      <date-picker
        :label="'Conversion Date'"
        class="mt-5"
        hideDetails
        :defaultNull="true"
        rules="required"
        :initialDate="conversionDate"
        @dateSelected="setDate">
      </date-picker>
    </template>
    <template v-slot:body>
      <data-table
        :headers="propertyHeaders"
        :tableData="getPropertyData"
        @itemDeleteRequested="deletePropertyItem"
        @unlockEditing="unlockPropertyEdit"
        @dataChanged="saveProperty"
        @canceled="cancelProperty"
        class="mx-6"
        :noData="'Please select an import template to upload.'">
      </data-table>
      <v-layout justify-end>
        <round-button
            v-if="canImport"
            color='var(--v-resGray-darken3)'
            height='30px'
            width='100px'
            :depressed='true'
            buttonText='+ New'
            backgroundColor='var(--v-resGreen-lighten1)'
            borderColor='var(--v-resGray-lighten1)'
            buttonClass='ml-0 mr-0 mb-0 mt-4'
            @click="newSpaceItem">
        </round-button>
      </v-layout>
      <data-table
        :headers="spaceHeaders"
        :tableData="getSpaceData"
        @itemDeleteRequested="deleteSpaceItem"
        @unlockEditing="unlockSpaceEdit"
        @dataChanged="saveSpace"
        @canceled="cancelSpace"
        class="mx-6"
        :noData="'Please select an import template to upload.'">
      </data-table>
      <v-layout justify-end>
        <round-button
            v-if="canImport"
            color='var(--v-resGray-darken3)'
            height='30px'
            width='100px'
            :depressed='true'
            buttonText='+ New'
            backgroundColor='var(--v-resGreen-lighten1)'
            borderColor='var(--v-resGray-lighten1)'
            buttonClass='ml-0 mr-0 mb-0 mt-4'
            @click="newAreaItem">
        </round-button>
      </v-layout>
      <data-table
        :headers="areaHeaders"
        :tableData="getAreaData"
        @itemDeleteRequested="deleteAreaItem"
        @unlockEditing="unlockAreaEdit"
        @dataChanged="saveArea"
        @canceled="cancelArea"
        class="mx-6"
        :noData="'Please select an import template to upload.'">
      </data-table>
    </template>
  </imports-template>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as fileDownload from '../../../../utility/fileDownloader'
import * as validation from '../../../../utility/validation'
import DataTable from '../../../BaseComponents/Table'
import DropDown from '../../../BaseComponents/DropDown'
import DatePicker from '../../../BaseComponents/DatePicker'
import RoundButton from '../../../BaseComponents/RoundButton'
import ImportsTemplate from './ImportsTemplate'
export default {
  components: {
    DataTable,
    DropDown,
    DatePicker,
    RoundButton,
    ImportsTemplate
  },
  data () {
    return {
      files: {},
      selectedProperty: undefined,
      selectedPropertyName: undefined,
      importDialog: false,
      confirmDialog: false,
      conversionDate: null
    }
  },
  methods: {
    ...mapActions(['fetchData', 'changeData', 'initializeStoreItem', 'fetchDropdown', 'addNew']),
    selectProperty (val) {
      if (val !== null) {
        this.selectedProperty = val.value
        this.selectedPropertyName = val.text
      } else {
        this.selectedProperty = null
        this.selectedPropertyName = null
      }
    },
    setDate (val) {
      this.conversionDate = val
    },
    deletePropertyItem (item) {
      this.$refs.template.deleteItem(item, this.getPropertyData)
    },
    unlockPropertyEdit (item) {
      this.$refs.template.unlockEdit(item, this.getPropertyData)
    },
    cancelProperty (item) {
      this.$refs.template.cancelEdit(item, this.getPropertyData)
    },
    saveProperty (item) {
      this.$refs.template.save(item)
    },
    deleteSpaceItem (item) {
      this.$refs.template.deleteItem(item, this.getSpaceData)
    },
    unlockSpaceEdit (item) {
      this.$refs.template.unlockEdit(item, this.getSpaceData)
    },
    cancelSpace (item) {
      this.$refs.template.cancelEdit(item, this.getSpaceData)
    },
    saveSpace (item) {
      this.$refs.template.save(item)
    },
    deleteAreaItem (item) {
      this.$refs.template.deleteItem(item, this.getAreaData)
    },
    unlockAreaEdit (item) {
      this.$refs.template.unlockEdit(item, this.getAreaData)
    },
    cancelArea (item) {
      this.$refs.template.cancelEdit(item, this.getAreaData)
    },
    saveArea (item) {
      this.$refs.template.save(item)
    },
    addNewItem () {
      this.addNew({
        currentPage: 'GetImportSqFtMeasuresData',
        newItem: {
          measureType: { text: null, value: null },
          sqFtMeasure: null,
          space: null,
          area: null,
          actionColumn: { delete: true, edit: true },
          readonly: false,
          new: true,
          errorToolTip: { hasIcon: false, tooltip: null, value: '' }
        }
      })
    },
    newSpaceItem () {
      this.addNew({
        currentPage: 'GetImportSqFtMeasuresData',
        newItem: {
          measureType: { text: null, value: null },
          sqFtMeasure: null,
          space: { text: null, value: null },
          area: null,
          actionColumn: { delete: true, edit: true },
          readonly: false,
          new: true,
          errorToolTip: { hasIcon: false, tooltip: null, value: '' }
        }
      })
    },
    newAreaItem () {
      this.addNew({
        currentPage: 'GetImportSqFtMeasuresData',
        newItem: {
          measureType: { text: null, value: null },
          sqFtMeasure: null,
          space: null,
          area: { text: null, value: null },
          actionColumn: { delete: true, edit: true },
          readonly: false,
          new: true,
          errorToolTip: { hasIcon: false, tooltip: null, value: '' }
        }
      })
    },
    downloadTemplate () {
      this.fetchData({ newPage: 'ImportSqFtMeasures', params: { Property: this.selectedProperty } }).then(() => {
        let file = this.currentData('ImportSqFtMeasures')
        let bufferArray = fileDownload.convertArrayBuffer(file.fileContent)
        let fileName = file.fileName.split('.')[0]
        fileName += '-' + this.selectedPropertyName + '.xlsx'
        fileDownload.createDownloadFile(bufferArray, fileName)
      }).catch(error => { console.log(error.response) })
    },
    saveUpload (item) {
      this.fetchDropdown({ newDropdown: 'Expense Measure Types', params: { Property: this.selectedProperty } })
      this.fetchDropdown({ newDropdown: 'Property Spaces', params: { Property: this.selectedProperty } })
      this.fetchDropdown({ newDropdown: 'Property Areas', params: { PropertyId: this.selectedProperty } }).then(() => {
        this.changeData({ currentPage: 'GetImportSqFtMeasuresData', newData: item, updateItem: {} }).then(() => {
          this.$refs.template.cancel()
        })
      }).catch(error => { console.log(error.response) })
    },
    submit () {
      let returnData = this.getPropertyData.concat(this.getSpaceData.concat(this.getAreaData))
      this.changeData({ currentPage: 'ImportSqFtMeasures', newData: { Property: this.selectedProperty, Date: this.conversionDate, SqFtMeasures: returnData } }).then(() => {
        this.$refs.template.cancelImport()
      }).catch(error => { console.log(error.response) })
    },
    clearImportData () {
      this.initializeStoreItem('GetImportSqFtMeasuresData')
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentDropdown']),
    propertyHeaders () {
      let items = JSON.parse(JSON.stringify(this.currentHeaders('GetImportSqFtMeasuresData')))
      items.splice(items.findIndex(x => x.value === 'space'), 1)
      items.splice(items.findIndex(x => x.value === 'area'), 1)
      let validation = items.splice(items.findIndex(x => x.value === 'errorToolTip'), 1)
      return validation.concat(items)
    },
    propertyRawData () {
      return this.currentData('GetImportSqFtMeasuresData')
    },
    getPropertyData () {
      let propertyMeasure = []
      let items = this.propertyRawData
      let expenseMeasureTypes = this.currentDropdown('Expense Measure Types')
      let expenseMeasureTypesLength = expenseMeasureTypes.length
      items.forEach(item => {
        if (item.area === null && item.space === null) {
          if (expenseMeasureTypesLength > 0 && validation.hasValue(item.measureType) && validation.hasValue(item.measureType.text)) {
            item.measureType = expenseMeasureTypes.find(x => {
              if (validation.hasValue(x.text)) {
                return x.text.toLowerCase() === item.measureType.text.toLowerCase()
              }
            })
          }
          let errors = validation.validateValue(item, [
            { value: 'measureType', required: true },
            { value: 'sqFtMeasure', required: true, positive: true }
          ], items)
          validation.handleErrorToolTip(item, errors)
          propertyMeasure.push(item)
        }
      })
      return propertyMeasure
    },
    spaceHeaders () {
      let items = JSON.parse(JSON.stringify(this.currentHeaders('GetImportSqFtMeasuresData')))
      items.splice(items.findIndex(x => x.value === 'area'), 1)
      let validation = items.splice(items.findIndex(x => x.value === 'errorToolTip'), 1)
      return validation.concat(items)
    },
    spaceRawData () {
      return this.currentData('GetImportSqFtMeasuresData')
    },
    getSpaceData () {
      let spaceMeasure = []
      let items = this.spaceRawData
      let expenseMeasureTypes = this.currentDropdown('Expense Measure Types')
      let expenseMeasureTypesLength = expenseMeasureTypes.length
      let spaces = this.currentDropdown('Property Spaces')
      let spacesLength = spaces.length
      items.forEach(item => {
        if (item.area === null && item.space !== null) {
          if (expenseMeasureTypesLength > 0 && validation.hasValue(item.measureType.text)) {
            item.measureType = expenseMeasureTypes.find(x => {
              if (validation.hasValue(x.text)) {
                return x.text.toLowerCase() === item.measureType.text.toLowerCase()
              }
            })
          }
          if (spacesLength > 0 && validation.hasValue(item.space) && validation.hasValue(item.space.text)) {
            item.space = spaces.find(x => {
              if (validation.hasValue(x.text)) {
                return x.text.toLowerCase() === item.space.text.toLowerCase()
              }
            })
          }
          let errors = validation.validateValue(item, [
            { value: 'space', required: true },
            { value: 'measureType', required: true },
            { value: 'sqFtMeasure', required: true, positive: true }
          ], items)
          validation.handleErrorToolTip(item, errors)
          spaceMeasure.push(item)
        }
      })
      return spaceMeasure
    },
    areaHeaders () {
      let items = JSON.parse(JSON.stringify(this.currentHeaders('GetImportSqFtMeasuresData')))
      items.splice(items.findIndex(x => x.value === 'space'), 1)
      let validation = items.splice(items.findIndex(x => x.value === 'errorToolTip'), 1)
      return validation.concat(items)
    },
    areaRawData () {
      return this.currentData('GetImportSqFtMeasuresData')
    },
    getAreaData () {
      let areaMeasure = []
      let items = this.areaRawData
      let expenseMeasureTypes = this.currentDropdown('Expense Measure Types')
      let expenseMeasureTypesLength = expenseMeasureTypes.length
      let areas = this.currentDropdown('Property Areas')
      let areasLength = areas.length
      items.forEach(item => {
        if (item.area !== null && item.space === null) {
          if (expenseMeasureTypesLength > 0 && validation.hasValue(item.measureType.text)) {
            item.measureType = expenseMeasureTypes.find(x => {
              if (validation.hasValue(x.text)) {
                return x.text.toLowerCase() === item.measureType.text.toLowerCase()
              }
            })
          }
          if (areasLength > 0 && validation.hasValue(item.area.text)) {
            item.area = areas.find(x => {
              if (validation.hasValue(x.text)) {
                return x.text.toLowerCase() === item.area.text.toLowerCase()
              }
            })
          }
          let errors = validation.validateValue(item, [
            { value: 'area', required: true },
            { value: 'measureType', required: true },
            { value: 'sqFtMeasure', required: true, positive: true }
          ], items)
          validation.handleErrorToolTip(item, errors)
          areaMeasure.push(item)
        }
      })
      return areaMeasure
    },
    canExport () {
      if (validation.hasValue(this.selectedProperty) && validation.hasValue(this.conversionDate)) {
        return true
      }
      return false
    },
    canImport () {
      if (this.getPropertyData.length > 0) {
        return true
      }
      return false
    }
  },
  beforeDestroy () {
    this.clearImportData()
  }
}
</script>
