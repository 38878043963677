const state = {
  applyCharges: []
}

const getters = {
  currentRefundOpenCharges: (state) => state.applyCharges
}

const actions = {
  changeRefundOpenCharges ({ commit }, applyCharges) {
    commit('setOpenCharges', applyCharges)
  }
}

const mutations = {
  setOpenCharges: (state, newCharges) => (state.applyCharges = newCharges)
}

export default {
  state,
  getters,
  actions,
  mutations
}
