<template>
  <v-container class="resGray lighten-6">
    <v-layout align-center justify-space-between column fill-height>
      <v-card flat width="1000" height="230">
        <v-flex>
          <v-layout>
            <v-flex>
              <v-flex mt-4>
                <span class="title ml-6">Deposit Details</span>
              </v-flex>
              <v-flex mt-6>
                <v-layout>
                  <v-flex ml-6>
                    <div class="caption resGray-darken1--text mb-1">Deposit date</div>
                    <div class="caption resGray-darken5--text font-weight-medium">{{ depositDetails.depositDate | dateFilter(depositDetails.depositDate) }}</div>
                  </v-flex>
                  <v-flex>
                    <div class="caption resGray-darken1--text mb-1">Property</div>
                    <div class="caption resGray-darken5--text font-weight-medium">{{ depositDetails.bankDepositDetails[0].propertyName }}</div>
                  </v-flex>
                  <v-flex>
                    <div class="caption resGray-darken1--text mb-1">Cash Account</div>
                    <div class="caption resGray-darken5--text font-weight-medium">{{ depositDetails.bankAccountCode }} - {{ depositDetails.bankAccountName }}</div>
                  </v-flex>
                </v-layout>
                <v-layout ml-6 mt-6>
                  <v-flex>
                    <div class="caption resGray-darken1--text mb-1">Description</div>
                    <div class="caption resGray-darken5--text font-weight-medium">{{ depositDetails.reference }}</div>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-flex>
            <v-flex>
              <v-layout align-end justify-space-between column fill-height>
                <span class="caption mr-6 mt-4 resGray-darken1--text">Deposit total</span>
                <span class="title mb-2 mr-6">{{ depositTotal }}</span>
                <span class="caption resGray-darken1--text mr-6 mt-4">Applied total</span>
                <span class="title mb-2 mr-6">{{ appliedTotal }}</span>
                <span class="caption resGray-darken1--text mr-6 mt-4">Unapplied Total</span>
                <span class="title mr-6" :class="reconcileColor">{{ unappliedTotal }}</span>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-card>
      <v-card flat color="var(--v-resGray-lighten6)" width="1000" class='mt-1'>
        <v-flex mt-6 mb-4>
          <span class="title">Distribute amount</span>
        </v-flex>
        <v-tabs
          v-model="active"
          class="distribution__tabs"
          prev-icon="arrow_left"
          next-icon="arrow_right"
          show-arrows
          hide-slider>
          <v-tab
            v-for="n in totalTabs"
            :disabled="!checkcomplete"
            :key="n">
            <span class="caption text-capitalize ml-1">
              <div>{{ tabTitle(n - 1) }}</div>
            </span>
          </v-tab>
          <v-tabs-items v-model="active">
            <v-tab-item
              v-for="n in totalTabs"
              :key="n">
              <distribution-tab
                :active="active"
                v-if="(n - 1) === active"
                @reconciled="checkReconciled"
                @appliedTotalChange="changeTotalApplied"
                :depositDetails="depositDetails">
              </distribution-tab>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
        <v-layout mt-4>
          <v-spacer></v-spacer>
          <span class="subheading">(Check {{ active + 1 }} of {{ totalTabs }})</span>
        </v-layout>
        <v-layout>
          <v-spacer></v-spacer>
          <round-button
            height='30px'
            width='80px'
            :buttonText="'Cancel'"
            :buttonClass="'mt-4'"
            buttonTextColor='var(--v-resRed-base)'
            :borderColor="'var(--v-resRed-base)'"
            :depressed="true"
            :flat="true"
            v-if="!processCompleted"
            :outline="true"
            @click="cancelDistribution">
          </round-button>
          <round-button
            height='30px'
            buttonClass='mt-4 ml-4 mr-0'
            width='100px'
            v-if="!processCompleted"
            :depressed='true'
            :flat='true'
            :backgroundColor="'var(--v-resBlue-darken1)'"
            :buttonText="this.totalTabs - 1 === this.active ? 'Complete': 'Next Check'"
            :disabled="!currentCheckReconciled"
            @click="save">
          </round-button>
          <round-button
            height='30px'
            buttonClass='mr-0 mt-4'
            width='140px'
            :depressed='true'
            :flat='true'
            v-if="processCompleted"
            :buttonTextColor="'var(--v-resWhite-base)'"
            :backgroundColor="'var(--v-resGreen-lighten1)'"
            :buttonText="'New Cash Receipt'"
            @click="resetWorkFlow">
          </round-button>
        </v-layout>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import RoundButton from '../../../BaseComponents/RoundButton'
import DistributionTab from './DistributionTab'
import * as currency from '../../../../utility/currency'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {
  props: ['depositDetails'],
  components: {
    RoundButton,
    DistributionTab
  },
  data () {
    return {
      active: 0,
      totalTabs: this.depositDetails.bankDepositDetails.length,
      editedItem: {},
      allLeases: [],
      autoApply: false,
      prepayBalance: false,
      processCompleted: false,
      showDialog: false,
      appliedTotal: Number(0),
      currentCheckReconciled: false
    }
  },
  methods: {
    ...mapActions(['changeData', 'fetchData', 'initializeStoreItem']),
    setActiveTab (val) {
      if (this.currentCheckReconciled) {
        this.active = val
      }
    },
    openDialog () {
      this.showDialog = true
    },
    cancel () {
      this.showDialog = false
    },
    checkReconciled (val) {
      this.currentCheckReconciled = val
    },
    save () {
      if (this.currentCheckReconciled) {
        let company = this.depositDetails.companyId
        let bankAccount = this.depositDetails.bankAccountCode
        let details = this.getData
        for (let i = 0; i < details.length; i++) {
          if (!details[i].selectBasePaymentAmount) {
            details[i].selectBasePaymentAmount = 0
          }
        }
        this.changeData(
          {
            currentPage: 'LeaseOpenCharges',
            newData:
            {
              CompanyId: company,
              BankAccountCode: bankAccount,
              BankDepositSlipId: this.depositDetails.depositSlipId,
              Lease: this.depositDetails.bankDepositDetails[this.active].payer,
              PayerCheckNumber: this.depositDetails.bankDepositDetails[this.active].payerCheckNumber,
              PayerCheckDate: this.depositDetails.bankDepositDetails[this.active].payerCheckDate,
              PaymentDetails: details
            }
          }).then(() => {
          this.fetchData({ newPage: 'BankDepositSlipDraftsFullInfo', params: { CompanyId: company, BankAccountCode: bankAccount, BankDepositSlipId: this.depositDetails.depositSlipId } })
            .then(() => {
              this.$emit('EditDeposit', this.currentData('BankDepositSlipDraftsFullInfo'))
              this.initializeStoreItem('LeaseOpenCharges')
              if (this.active === this.totalTabs - 1) {
                this.$emit('completeDistribution')
              } else {
                this.active++
                this.setActiveTab(this.active)
              }
            })
        }).catch(error => { console.log(error.response) })
      }
    },
    cancelDistribution () {
      this.$emit('cancelDistribution')
    },
    tabTitle (tabIndex) {
      let check = this.depositDetails.bankDepositDetails[tabIndex]
      if (check && check.payerCheckNumber) {
        if (check.payerCheckNumber.length > 7) {
          return `# ${check.payerCheckNumber.substr(0, 7)}...`
        } else {
          return `# ${check.payerCheckNumber}`
        }
      }
    },
    changeTotalApplied (val) {
      let sum = Number(0)
      this.depositDetails.bankDepositDetails.forEach(function (check) {
        if (check.transactionStatus !== 20) {
          sum += check.baseAmount
        }
      })
      sum += parseFloat(val)
      this.appliedTotal = currency.convertToCurrency(sum)
    }
  },
  computed: {
    ...mapGetters(['currentDropdown', 'currentData', 'currentHeaders']),
    totalReconciled () {
      if (this.depositTotal !== this.appliedTotal) {
        return false
      }
      return true
    },
    checkcomplete () {
      if (this.depositDetails.bankDepositDetails[this.active].transactionStatus !== 20) {
        return true
      } else {
        return false
      }
    },
    unappliedTotal () {
      let remaining = currency.parseCurrency(this.depositTotal) - currency.parseCurrency(this.appliedTotal)
      return currency.convertToCurrency(remaining)
    },
    depositTotal () {
      if (this.depositDetails.bankDepositDetails.length > 0) {
        let sum = Number(0)
        this.depositDetails.bankDepositDetails.forEach(function (charge) {
          sum += currency.parseCurrency(charge.baseAmount)
        })
        return currency.convertToCurrency(sum)
      }
      return currency.convertToCurrency(0)
    },
    reconcileColor () {
      if (!this.totalReconciled) {
        return 'resRed-base--text'
      } else {
        return 'resGreen-base--text'
      }
    },
    getData: {
      get () {
        return this.currentData('LeaseOpenCharges')
      }
    },
    currentDepositSlip: {
      get () {
        return this.currentData('BankDepositSlips')
      }
    },
    completeDistribution () {
      return null
    }
  },
  filters: {
    dateFilter (val) {
      return moment(val).format('L')
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-tabs {
    &.distribution__tabs {
      ::v-deep .v-tabs-bar {
        height: 40px;
        background-color: var(--v-resGray-lighten6)
      }
      ::v-deep .v-slide-group__prev {
        background-color: var(--v-resGray-lighten3);
        border-color: var(--v-resGray-lighten1);
        border-style: solid;
        border-width: 1px 1px 0px 1px;
      }
      ::v-deep .v-slide-group__next {
        background-color: var(--v-resGray-lighten3);
        border-color: var(--v-resGray-lighten1);
        border-style: solid;
        border-width: 1px 1px 0px 1px;
      }
      ::v-deep .v-tab {
        justify-content: left;
        padding: 6px 6px;
        background-color: var(--v-resGray-lighten3);
        border-color: var(--v-resGray-lighten1);
        border-style: solid;
        border-width: 1px 1px 0px 1px;
        color: var(--v-resGray-darken4);
        .v-icon {
          color: var(--v-resGray-darken3);
        }
      }
      ::v-deep .v-tab--active {
        background-color: var(--v-resBlue-base);
        border-color: var(--v-resBlue-base);
        border-style: solid;
        border-width: 1px 1px 0px 1px;
        color: var(--v-resWhite-base) !important;
        .v-icon {
          color: var(--v-resWhite-base);
        }
        &.v-tab--disabled {
          opacity: 100%;
        }
      }
    }
  }

  .tabs-card {
    border-radius: 0px;
    border-top: 2px var(--v-resBlue-base) solid;
    border-right: 1px var(--v-resGray-lighten1) solid;
    border-left: 1px var(--v-resGray-lighten1) solid;
    border-bottom: 1px var(--v-resGray-lighten1) solid;
  }

  .contact-title--primary {
    font-style: italic;
  }
</style>
