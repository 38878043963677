<template>
  <v-container fluid class="resGray lighten-6">
    <v-app-bar flat :height="headerHeight">
      <drop-down
        class="ml-2"
        v-if="currentTab.dropdown"
        :label="currentTab.dropdown"
        :items="currentTab.dropdown"
        :multiple='false'
        width='200px'
        @itemSelected="selectedDropdown">
      </drop-down>
      <v-spacer></v-spacer>
      <!-- <round-button
        color='var(--v-resGray-darken3)'
        width='40px'
        height='30px'
        :outline='true'
        :icon='true'
        :depressed='true'
        iconText='get_app'
        backgroundColor='white'
        borderColor='var(--v-resGray-lighten1)'
        buttonClass='ml-4 mt-0'>
      </round-button> -->
      <round-button
        v-if="!currentTab.HideAddNew"
        color='var(--v-resGray-darken3)'
        height='30px'
        width='100px'
        :dark='!this.addingNew && !this.editing'
        :depressed='true'
        :disabled='this.addingNew || this.editing'
        buttonText='+ New'
        backgroundColor='var(--v-resGreen-lighten1)'
        borderColor='var(--v-resGray-lighten1)'
        buttonClass='mr-2'
        @click="newItem">
      </round-button>
    </v-app-bar>
    <v-row>
      <v-col cols="12" class="mt-3">
        <data-table
          class="mx-6"
          :selectableRows="false"
          :headers="headers"
          :tableData="getData"
          :noData="'No information to display.'"
          :placeHolder="placeHolder"
          :showActions="true"
          @dataChanged="save"
          @canceled="cancel"
          @itemDeleteRequested="deleteItem"
          @unlockEditing="unlockEdit"
          @showParameters="showParameters"
          :resetPage="resetPage">
        </data-table>
      </v-col>
    </v-row>
    <!-- Dialogs -->
    <delete-dialog
      :showDialog="isDialog"
      @confirmDelete="confirmDialog"
      @cancelDelete="cancelDialog">
    </delete-dialog>
    <component
      v-if="showDialog"
      :is="currentDialog"
      :showDialog="showDialog"
      :editedItem="dialogItem"
      :mode="dialogMode"
      @save="saveDialog"
      @cancel="cancelDialog">
    </component>
    <parameters-dialog
      v-if="showParametersDialog && dropdownValue && selectedChargeCode"
      :showDialog="showParametersDialog"
      :type="selectedChargeType"
      :propertyTemplateId="dropdownValue"
      :chargeCode="selectedChargeCode"
      @cancel="hideParameters"
      @saveComplete="hideParameters">
    </parameters-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DataTable from '../BaseComponents/Table'
import RoundButton from '../BaseComponents/RoundButton'
import DropDown from '../BaseComponents/DropDown'
import DeleteDialog from '../BaseComponents/DeleteDialog'
import ChargeCodeDialog from './Dialogs/ChargeCode'
import ChargeCodeTemplateDialog from './Dialogs/ChargeCodeTemplate'
import ParametersDialog from './Dialogs/Parameters'

export default {
  components: {
    DataTable,
    RoundButton,
    DeleteDialog,
    ChargeCodeDialog,
    DropDown,
    ChargeCodeTemplateDialog,
    ParametersDialog
  },
  data: () => ({
    pagination: {
      sortBy: null
    },
    previousItem: {},
    dialog: false,
    resetPage: false,
    addingNew: false,
    editing: false,
    readonly: false,
    isDialog: false,
    itemHolder: {},
    currentDialog: '',
    showDialog: false,
    dialogItem: {},
    dialogMode: 'Edit',
    dropdownValue: '',
    showParametersDialog: false,
    selectedChargeType: null,
    selectedChargeCode: null
  }),
  computed: {
    pages () {
      if (this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      ) return 0
      return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
    },
    ...mapGetters(['currentData', 'currentHeaders', 'currentItem', 'currentPlaceholder', 'currentTab', 'currentLoading']),
    getData () {
      return this.currentData(this.currentTab.header)
    },
    headers () {
      return this.currentHeaders(this.currentTab.header)
    },
    placeHolder () {
      return this.currentPlaceholder(this.currentTab.header)
    },
    headerHeight () {
      return this.currentTab.dropdown ? null : '36'
    }
  },
  watch: {
    getData () {
      this.$nextTick(() => {
        this.pagination.totalItems = this.getData.length
      })
    }
  },
  methods: {
    ...mapActions(['changeData', 'deleteData', 'fetchData', 'addNew', 'cancelNew']),
    unlockEdit (item) {
      if (!this.currentLoading) {
        // this handles the client side of locking and unlocking editing
        if (this.currentTab && this.currentTab.dialog) {
          this.dialogMode = 'Edit'
          this.dialogItem = JSON.parse(JSON.stringify(item))
          this.currentDialog = this.currentTab.dialog
          this.showDialog = true
        } else {
          if (!this.editing && !this.addingNew) {
            this.editedIndex = this.getData.indexOf(item)
            this.previousItem = Object.assign({}, item)
            item.readonly = false
            this.editing = true
            Object.assign(this.getData[this.editedIndex], item)
          }
        }
      }
    },
    selectedDropdown (val) {
      if (val !== null) {
        this.dropdownValue = val.value
        this.fetchData({ newPage: this.currentTab.header, params: { Value: val.value } }).catch(error => { console.log(error.response) })
      } else {
        this.dropdownValue = null
      }
    },
    deleteItem (item) {
      if (!this.editing) {
        this.itemHolder = item
        this.isDialog = true
      }
    },
    save (item) {
      // check to see if the item was new, if it was we need to remove the new property
      if (item.new === true) {
        item.readonly = true
        this.addingNew = false
        delete item.new
      }
      this.changeData({ currentPage: this.currentTab.header, newData: item }).then(() => {
        this.fetchData({ newPage: this.currentTab.header, params: { Value: this.dropdownValue } }).then(() => {
          this.editing = false
        })
      }).catch(error => { console.log(error.response) })
    },
    cancel (item) {
      // if we can cancel a new item we want to remove it from the datatable, otherwise we just want to set the value to its previous state before any editing
      if (item.new === true) {
        this.cancelNew({ currentPage: this.currentTab.header })
        this.addingNew = false
      } else {
        this.editedIndex = this.getData.indexOf(item)
        Object.assign(this.getData[this.editedIndex], this.previousItem)
        this.previousItem = {}
      }
      this.editing = false
      this.isSorted = true
    },
    saveDialog () {
      this.changeData({ currentPage: this.currentTab.header, newData: this.dialogItem }).then(() => {
        this.fetchData({ newPage: this.currentTab.header, params: { Value: this.dropdownValue } }).then(() => {
          this.showDialog = false
        })
      }).catch(error => { console.log(error.response) })
    },
    cancelDialog () {
      this.isDialog = false
      this.showDialog = false
    },
    confirmDialog () {
      this.isDialog = false
      this.deleteData({ currentPage: this.currentTab.header, removeItem: this.itemHolder }).catch(error => { console.log(error.response) })
      this.snackbar = true
      this.snackbarMsg = 'deleted'
      this.itemHolder = {}
    },
    newItem () {
      // this handle the new item sorting has to be turned off and the item added to the top of the list
      if (this.currentTab && this.currentTab.dialog) {
        this.dialogMode = 'New'
        this.dialogItem = {}
        this.currentDialog = this.currentTab.dialog
        this.showDialog = true
      } else {
        if (!this.addingNew) {
          this.pagination.sortBy = null
          this.addingNew = true
          this.resetPage = !this.resetPage
          this.addNew({ currentPage: this.currentTab.header })
        }
      }
    },
    showParameters (e) {
      this.showParametersDialog = true
      this.selectedChargeType = e.chargeType
      this.selectedChargeCode = e.chargeCode
    },
    hideParameters () {
      this.showParametersDialog = false
      this.selectedChargeType = null
    }
  }
}
</script>
