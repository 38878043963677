<template>
  <v-container>
    <div>
      <v-btn :ripple="false" text @click="openPoolsPage" color="var(--v-resWhite-base)" class="ml-2 pl-0">
        <v-btn small color="var(--v-resBlue-darken1)" icon><v-icon size="18" dark>arrow_back</v-icon></v-btn>
        <span class="text-none ml-0 back-button-text resBlue-darken1--text">{{ poolInfo.propertyName }}</span>
      </v-btn>
    </div>
    <v-app-bar flat>
      <drop-down
        :items="'Recovery Cycles'"
        :label="'Select Cycle'"
        width="240px"
        :genericLabel="true"
        :multiple='false'
        :getParams="{ propertyId: poolInfo.property }"
        @itemSelected="selectCycle">
      </drop-down>
    </v-app-bar>
    <div v-if="selectedCycle" class="ml-4 mt-6 mb-2">
      <span class="title">{{ poolInfo.poolName }} <span class="resGray-darken4--text" v-if="selectedPoolInfo">{{ selectedPoolInfo.poolFrequencyName ? `(${selectedPoolInfo.poolFrequencyName})` : ''}}</span>
        <v-icon v-if="selectedPoolInfo && selectedPoolInfo.reconcileIsLocked" :color="'var(--v-resGreen-base)'" medium>lock</v-icon>
      </span>
    </div>
    <reconciliations-cycle-info-card
      v-if="selectedCycle && selectedPoolInfo"
      :cycle="selectedPoolInfo">
    </reconciliations-cycle-info-card>
    <div v-if="selectedCycle" class="ml-4 mt-6 mb-2">
      <span class="title">Cycle Reconciliation</span>
    </div>
    <reconciliation-table
      v-if="selectedCycle && selectedPoolInfo"
      class="mb-4"
      :cycle="selectedPoolInfo"
      :selectedTab="selectedTab"
      @launchDetails="launchDetails"
      @showDeleteChargesDialog="showDeleteReconciliationCharges"
      @showChargesDialog="showReconciliationCharges"
      @showReportsDialog="showReconciliationReports"
      @showLettersDialog="showReconciliationLetters"
      @refreshCycle="refreshCycle">
    </reconciliation-table>
    <!-- Dialogs -->
    <expense-recovery-account-details-dialog
      v-if="showExpenseRecoveryAccountDetailsDialog && selectedAccountInfo"
      :showDialog="showExpenseRecoveryAccountDetailsDialog"
      :accountInfo="selectedAccountInfo"
      @cancelDialog="hideExpenseRecoveryAccountDetailsDialog"
      @save="hideExpenseRecoveryAccountDetailsDialogAndRefresh">
    </expense-recovery-account-details-dialog>
    <delete-reconciliation-charges-dialog
      :showDialog="showDeleteReconciliationChargesDialog"
      @cancelDialog="hideDeleteReconciliationChargesDialog">
    </delete-reconciliation-charges-dialog>
    <reconciliation-charges-dialog
      v-if="showReconciliationChargesDialog"
      :showDialog="showReconciliationChargesDialog"
      @cancelDialog="hideReconciliationChargesDialog"
      @confirmReconciliationCharges="confirmReconciliationCharges">
    </reconciliation-charges-dialog>
    <reconciliation-reports-dialog
      v-if="showReconciliationReportsDialog"
      :showDialog="showReconciliationReportsDialog"
      :cycleInfo="selectedPoolInfo"
      @cancelDialog="hideReconciliationReportsDialog">
    </reconciliation-reports-dialog>
    <reconciliation-letters-dialog
      v-if="showReconciliationLettersDialog"
      :showDialog="showReconciliationLettersDialog"
      :cycleInfo="selectedPoolInfo"
      @cancelDialog="hideReconciliationLettersDialog">
    </reconciliation-letters-dialog>
  </v-container>
</template>

<script>
import DropDown from '../../BaseComponents/DropDown'
import ReconciliationsCycleInfoCard from './ReconciliationsCycleInfoCard'
import ReconciliationTable from './ReconciliationTable'
import ExpenseRecoveryAccountDetailsDialog from './Dialogs/ExpenseRecoveryAccountDetails'
import DeleteReconciliationChargesDialog from './Dialogs/DeleteReconciliationCharges'
import ReconciliationChargesDialog from './Dialogs/ReconciliationCharges'
import ReconciliationReportsDialog from './Dialogs/ReconciliationReports'
import ReconciliationLettersDialog from './Dialogs/ReconciliationLetters'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: ['poolInfo'],
  components: {
    DropDown,
    ReconciliationsCycleInfoCard,
    ReconciliationTable,
    ExpenseRecoveryAccountDetailsDialog,
    DeleteReconciliationChargesDialog,
    ReconciliationChargesDialog,
    ReconciliationReportsDialog,
    ReconciliationLettersDialog
  },
  data () {
    return {
      selectedTab: 0,
      selectedCycle: null,
      showExpenseRecoveryAccountDetailsDialog: false,
      showDeleteReconciliationChargesDialog: false,
      showReconciliationChargesDialog: false,
      showReconciliationReportsDialog: false,
      showReconciliationLettersDialog: false,
      selectedAccountInfo: null
    }
  },
  methods: {
    ...mapActions(['fetchData', 'changeData', 'initializeStoreItem']),
    selectCycle (val) {
      this.selectedCycle = val.value
      this.getCycleInfo()
    },
    getCycleInfo () {
      this.fetchData({
        newPage: 'Recovery Cycles',
        params: {
          property: this.poolInfo.property,
          chargeCode: this.poolInfo.chargeCode,
          cycleNumber: this.selectedCycle
        }
      }).catch(error => { console.log(error.response) })
    },
    openPoolsPage () {
      this.$emit('openPoolsPage')
    },
    showDeleteReconciliationCharges () {
      this.showDeleteReconciliationChargesDialog = true
    },
    hideDeleteReconciliationChargesDialog () {
      this.showDeleteReconciliationChargesDialog = false
    },
    showReconciliationCharges () {
      this.showReconciliationChargesDialog = true
    },
    hideReconciliationChargesDialog () {
      this.showReconciliationChargesDialog = false
    },
    showReconciliationReports () {
      this.showReconciliationReportsDialog = true
    },
    hideReconciliationReportsDialog () {
      this.showReconciliationReportsDialog = false
    },
    showReconciliationLetters () {
      this.showReconciliationLettersDialog = true
    },
    hideReconciliationLettersDialog () {
      this.showReconciliationLettersDialog = false
    },
    confirmReconciliationCharges (e) {
      this.changeData({
        currentPage: 'Reconciliations Lease Expenses',
        newData: {
          property: this.poolInfo.property,
          chargeCode: this.poolInfo.chargeCode,
          cycleNumber: this.selectedCycle,
          chargeDate: e
        }
      }).then(() => {
        this.showReconciliationChargesDialog = false
        this.refreshCycle()
      }).catch(error => { console.log(error.response) })
    },
    launchDetails (e) {
      this.selectedAccountInfo = {
        property: this.poolInfo.property,
        chargeCode: this.poolInfo.chargeCode,
        cycleNumber: this.selectedCycle,
        account: e.account,
        accountList: e.accountList,
        accountName: e.accountName,
        glActuals: e.actuals,
        reconciledAmount: e.reconciledAmount,
        difference: e.reconciledBilled,
        isLocked: this.selectedPoolInfo.reconcileIsLocked
      }
      this.showExpenseRecoveryAccountDetailsDialog = true
    },
    hideExpenseRecoveryAccountDetailsDialog () {
      this.showExpenseRecoveryAccountDetailsDialog = false
    },
    hideExpenseRecoveryAccountDetailsDialogAndRefresh () {
      this.showExpenseRecoveryAccountDetailsDialog = false
      this.refreshCycle()
    },
    refreshCycle () {
      this.initializeStoreItem('Recovery Cycles')
      if (this.selectedCycle) {
        this.getCycleInfo()
      }
    }
  },
  computed: {
    ...mapGetters(['currentData']),
    selectedPoolInfo () {
      return this.currentData('Recovery Cycles').length !== 0 ? this.currentData('Recovery Cycles') : null
    }
  }
}
</script>

<style lang="scss" scoped>
  .back-button {
    &__icon {
      width:20px;
      height:20px;
    }
  }
</style>
