import Axios from '../../services/api'
const baseURL = process.env.VUE_APP_API_URL

const state = {
  currentPermissions: []
}

const getters = {
  checkPermissions: (state) => route => {
    let check = state.currentPermissions.find(x => x.routeName.replace(/\s/g, '').toLowerCase() === route.replace(/\s/g, '').toLowerCase())
    if (check !== null && check !== undefined) {
      return check.readWrite
    }
    return null
  }
}

const actions = {
  fetchPermissions ({ commit }) {
    return new Promise((resolve, reject) => {
      Axios.get(baseURL + '/ui-permissions' + '/get')
        .then((response) => {
          commit('setPermissions', response.data)
          resolve()
        }).catch(function (error) {
          reject(error)
        })
    })
  }
}

const mutations = {
  setPermissions (state, newData) {
    state.currentPermissions = newData.data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
