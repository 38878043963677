<template>
  <div class="resGray lighten-6">
    <reconciliations v-if="reconciliationsActive"
      @openCyclePage='openAddReconciliationPage'>
    </reconciliations>
    <add-reconciliation v-if="addReconciliationActive"
      :poolInfo="poolInfo"
      @openPoolsPage="openPoolsPage">
    </add-reconciliation>
  </div>
</template>

<script>
import Reconciliations from './Reconciliations'
import AddReconciliation from './AddReconciliation'

export default {
  components: {
    Reconciliations,
    AddReconciliation
  },
  data () {
    return {
      addReconciliationActive: false,
      reconciliationsActive: true,
      poolInfo: null
    }
  },
  methods: {
    openAddReconciliationPage (val) {
      this.reconciliationsActive = false
      this.addReconciliationActive = true
      this.poolInfo = val
    },
    openPoolsPage () {
      this.poolInfo = {}
      this.reconciliationsActive = true
      this.addReconciliationActive = false
    }
  }
}
</script>
