import axios from 'axios'
import 'babel-polyfill'

const baseUrl = process.env.VUE_APP_API_URL
const api = axios.create({
  baseURL: baseUrl
})
api.CancelToken = axios.CancelToken
api.isCancel = axios.isCan

api.interceptors.request.use((config) => {
  let user = JSON.parse(localStorage.getItem('user'))
  if (user) {
    config.headers.Authorization = `Bearer ${user.access_token}`
  }
  return config
})

export default api
