<template>
  <div :class="sqFtExpirationChartWrapperClass" @click="setSelected(true)">
    <div class="chart-title">Lease SqFt Expiration</div>
    <div v-if="currentLoading && needToRefresh" class="loading-container">
      <v-progress-circular
        color="var(--v-resBlue-darken1)"
        size="64"
        indeterminate
        class="loading">
      </v-progress-circular>
    </div>
    <div v-if="(!currentLoading || !needToRefresh) && sqFtExpirationChartData != null" id="sqft-expiration-chart-container" class="chart-container">
      <g-chart
        type="BarChart"
        :data="sqFtExpirationChartData"
        :options="sqFtExpirationChartOptions"
        @ready="onChartReady">
      </g-chart>
    </div>
    <div v-if="!currentLoading && !needToRefresh && sqFtExpirationChartData == null" class="no-data-container">
      <div>
        <v-icon color="var(--v-resGray-lighten1)" size="80">error_outline</v-icon>
      </div>
      <div>No results found</div>
      <div class="message-detail">The date you are currently viewing has no lease sqft expiration data</div>
    </div>
  </div>
</template>

<script>
import { GChart } from 'vue-google-charts'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    GChart
  },
  props: ['asOfDate'],
  data () {
    return {
      needToRefresh: true,
      sqFtExpirationChartWrapperClass: 'chart-wrapper selected',
      sqFtExpirationChartData: null,
      sqFtExpirationChartOptions: null
    }
  },
  methods: {
    ...mapActions(['changeLoading', 'fetchData']),
    setSelected (selected) {
      this.sqFtExpirationChartWrapperClass = 'chart-wrapper'
      if (selected) {
        this.sqFtExpirationChartWrapperClass += ' selected'
        this.$emit('selectChart')
      }
    },
    refreshChartData () {
      this.fetchData({ newPage: 'DashboardSqFtExpirationSummary', params: { AsOfDate: this.asOfDate } }).then(() => {
        // Lease SqFt Expiration Data
        let summary = this.getSqFtExpirationSummaryData
        let maxExpirationSqFt = Math.max(summary.expiresIn60MonthsSqFt, summary.expiresIn48MonthsSqFt, summary.expiresIn36MonthsSqFt, summary.expiresIn24MonthsSqFt, summary.expiresIn12MonthsSqFt)
        // Handle no data
        if (maxExpirationSqFt > 0) {
          let otherColor = this.$vuetify.theme.themes.light.resBlue.lighten3
          let maxColor = this.$vuetify.theme.themes.light.resBlue.lighten1
          this.sqFtExpirationChartData = [
            ['Months', 'SqFt', { role: 'style' }],
            ['60 ' + this.getChartMonthLabelDescription, summary.expiresIn60MonthsSqFt, summary.expiresIn60MonthsSqFt >= maxExpirationSqFt ? maxColor : otherColor],
            ['48 ' + this.getChartMonthLabelDescription, summary.expiresIn48MonthsSqFt, summary.expiresIn48MonthsSqFt >= maxExpirationSqFt ? maxColor : otherColor],
            ['36 ' + this.getChartMonthLabelDescription, summary.expiresIn36MonthsSqFt, summary.expiresIn36MonthsSqFt >= maxExpirationSqFt ? maxColor : otherColor],
            ['24 ' + this.getChartMonthLabelDescription, summary.expiresIn24MonthsSqFt, summary.expiresIn24MonthsSqFt >= maxExpirationSqFt ? maxColor : otherColor],
            ['12 ' + this.getChartMonthLabelDescription, summary.expiresIn12MonthsSqFt, summary.expiresIn12MonthsSqFt >= maxExpirationSqFt ? maxColor : otherColor]
          ]
          let minTickValue = 0
          let maxTickValue = Math.round(maxExpirationSqFt / 1000) * 1000 // round highest square footage to nearest thousand
          let maxChartValue = (maxTickValue > maxExpirationSqFt) // ensure max chart value is greater than actual max expiration
            ? maxTickValue + (maxTickValue * 0.01)
            : maxExpirationSqFt + (maxExpirationSqFt * 0.01)
          this.sqFtExpirationChartOptions = {
            titlePosition: 'none',
            backgroundColor: 'transparent',
            legend: 'none',
            chartArea: {
              top: '10%',
              left: this.getChartLeftAlignment,
              height: '65%',
              width: '100%'
            },
            hAxis: {
              title: '(Measured in SqFt)',
              titleTextStyle: {
                italic: false,
                bold: false,
                fontSize: 12
              },
              textStyle: {
                fontSize: 11
              },
              minValue: minTickValue,
              maxValue: maxChartValue,
              baseline: minTickValue,
              baselineColor: 'transparent',
              gridlines: {
                color: 'transparent'
              },
              ticks: [0, (maxTickValue / 2), maxTickValue]
            }
          }
        } else {
          this.sqFtExpirationChartData = null
          this.sqFtExpirationChartOptions = null
        }
        this.needToRefresh = false
      }).catch(error => { console.log(error.response) })
    },
    onChartReady () {
      // google does not provide an option for styling a specific label on the chart
      // so iterate through the chart container text elements and bold the label that
      // corresponds to the bar with the highest square footage
      setTimeout(() => {
        if (this.sqFtExpirationChartData) {
          var container = document.getElementById('sqft-expiration-chart-container')
          var labels = container.getElementsByTagName('text')

          var maxFound = false
          for (var i = 0; i < this.sqFtExpirationChartData.length; i++) {
            if (maxFound) {
              break
            }

            if (this.sqFtExpirationChartData[i][2] !== this.$vuetify.theme.themes.light.resBlue.lighten1) {
              continue
            }

            for (var j = 0; j < labels.length; j++) {
              if (labels[j].textContent !== this.sqFtExpirationChartData[i][0]) {
                continue
              }

              maxFound = true
              labels[j].setAttribute('font-weight', 'Bold')
              break
            }
          }
        }
      }, 500)
    }
  },
  computed: {
    ...mapGetters(['currentLoading', 'currentData']),
    getSqFtExpirationSummaryData: {
      get () {
        return this.currentData('DashboardSqFtExpirationSummary')
      }
    },
    getChartMonthLabelDescription: {
      get () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
          case 'sm':
          case 'md':
            return 'Mos'
          default:
            return 'Months'
        }
      }
    },
    getChartLeftAlignment: {
      get () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
          case 'sm':
            return '24%'
          default:
            return '20%'
        }
      }
    }
  },
  watch: {
    asOfDate: function () {
      this.needToRefresh = true
      this.refreshChartData()
    }
  },
  created () {
    this.changeLoading(true)
    setTimeout(() => {
      this.refreshChartData()
    }, 1000)
  }
}
</script>

<style lang="scss" scoped>
  .chart-wrapper {
    height: 240px;
    background-color: var(--v-resWhite-base);
    border: solid 1px var(--v-resGray-lighten1);
    .chart-title {
      margin-bottom: 0px;
      padding: 10px 0px 0px 20px;
      font-size: 17px;
      color: var(--v-resGray-darken4);
    }
  }

  .chart-wrapper:hover {
    box-shadow: 2px 2px 6px 0 var(--v-resGray-darken5);
  }

  .chart-wrapper.selected {
    border: solid 2px var(--v-resBlue-base) !important;
    .chart-title {
      font-weight: 500;
      color: var(--v-resGray-darken5);
    }
  }

  .chart-wrapper.selected:hover {
    box-shadow: none;
  }

  .chart-container {
    width: 90%;
    display: block;
    margin: 0 auto;
  }

  .loading-container {
    margin-bottom: 10%;
  }

  .loading {
    display: block;
    margin: 10% auto;
  }

  .no-data-container {
    margin-top: 4%;
    font-size: 12;
    color: var(--v-resGray-darken4);
    text-align: center;
    .message-detail {
      font-weight: 300;
      padding: 0 20px;
    }
  }
</style>
