<template>
  <v-container fluid class="resGray lighten-6">
    <v-app-bar flat height="36">
      <toggle-button-group
        class="ml-5"
        :buttons="toggleButtons"
        @toggled="changeFilter">
      </toggle-button-group>
      <v-spacer></v-spacer>
      <search
        class="mb-0-plus"
        :placeholder="'Search Leases'"
        :class="isMobile ? 'ml-6' : 'mr-3'"
        :width="isMobile ? '97%' : ''"
        @searchStringUpdated="updateSearchString">
      </search>
      <round-button
        color='var(--v-resGray-darken3)'
        height='30px'
        :dark='true'
        :depressed='true'
        buttonText='+ New Lease'
        backgroundColor='var(--v-resGreen-lighten1)'
        borderColor='var(--v-resGray-lighten1)'
        buttonClass='mr-2'
        @click="AddLease">
      </round-button>
    </v-app-bar>
    <v-row>
      <v-col cols="12" class="mt-3">
        <data-table
          :headers="headers"
          :tableData="getData"
          :noData="'No information to display.'"
          :searchString="searchString"
          @ClickLease="ClickLease"
          class="ml-6 mr-6">
        </data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" align="end" class="mt-0 pt-0 pr-10">
        <v-icon small>stars</v-icon>
        <span class="caption">Selected primary space</span>
      </v-col>
    </v-row>
    <!-- Dialogs -->
    <add-lease
      v-if="addLeaseDialog"
      :showDialog="addLeaseDialog"
      @cancel="Cancel"
      @complete="Complete">
    </add-lease>
  </v-container>
</template>

<script>
import DataTable from '../BaseComponents/Table'
import ToggleButtonGroup from '../BaseComponents/ToggleButtonGroup'
import Search from '../BaseComponents/Search'
import RoundButton from '../BaseComponents/RoundButton'
import AddLease from './LeaseDialogs/AddLease'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    DataTable,
    ToggleButtonGroup,
    Search,
    RoundButton,
    AddLease
  },
  data () {
    return {
      searchString: null,
      addLeaseDialog: false,
      toggleButtons: [
        { text: 'Current & Future' },
        { text: 'Expired' }
      ]
    }
  },
  methods: {
    ...mapActions(['changeLoading', 'changeSelectedItem', 'fetchData', 'changeTab']),
    updateSearchString (val) {
      this.searchString = val
    },
    ClickLease (val) {
      this.$router.push({ name: 'LeasesOverview' })
      this.changeSelectedItem(val)
    },
    changeFilter (val) {
      this.fetchData({ newPage: 'Leases', params: { LeaseType: val } }).catch(error => { console.log(error.response) })
    },
    AddLease () {
      this.addLeaseDialog = true
    },
    Cancel () {
      this.addLeaseDialog = false
    },
    Complete () {
      this.fetchData({ newPage: 'Leases', params: { LeaseType: 0 } }).catch(error => { console.log(error.response) })
      this.addLeaseDialog = false
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentItem', 'currentPlaceholder']),
    isMobile () {
      return this.$vuetify.breakpoint.mobile
    },
    headers: {
      get () {
        return this.currentHeaders('Leases')
      }
    },
    getData: {
      get () {
        return this.currentData('Leases')
      }
    }
  },
  created () {
    this.changeLoading(true)
    this.fetchData({ newPage: 'Leases', params: { LeaseType: 0 } }).catch(error => { console.log(error.response) })
    this.changeSelectedItem()
    this.changeTab()
  }
}
</script>
