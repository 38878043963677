<template>
  <div>
    <v-app-bar class="pl-0" flat color="var(--v-resWhite-base)" height="40">
      <v-btn :ripple="false" text :to="{ name: 'Imports' }" color="var(--v-resWhite-base)" class="back-button">
        <v-btn small color="var(--v-resBlue-darken1)" icon><v-icon size="18" dark>arrow_back</v-icon></v-btn>
        <span class="text-none ml-0 back-button-text">Back to Imports</span>
      </v-btn>
    </v-app-bar>
    <base-tabs
      :tabs="tabs"
      @tabChanged="switchTab">
      <slot></slot>
    </base-tabs>
  </div>
</template>

<script>
import BaseTabs from '../../../BaseComponents/BaseTabs'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    BaseTabs
  },
  data () {
    return {
      tabs: [
        { header: 'Delinquents', route: 'Imports Delinquents' },
        { header: 'Prepaids', route: 'Imports Prepaids' },
        { header: 'Security Deposits', route: 'Imports Security Deposits' }
      ],
      selectedTab: null
    }
  },
  methods: {
    ...mapActions(['changeTab']),
    initialize () {
      if (this.currentTab === undefined) {
        this.switchTab(this.tabs[0])
      } else {
        if (this.currentTab.originalIndex !== undefined) {
          this.switchTab(this.tabs[this.currentTab.originalIndex])
        } else {
          this.switchTab(this.tabs[this.currentTab.index])
        }
      }
    },
    switchTab (e) {
      if (!e || !e.route || e.route === this.$route.name) {
        return
      }
      this.$router.push({ name: e.route })
      var tab = this.tabs[this.tabs.indexOf(e)]
      if (!tab.index) {
        tab.index = this.tabs.indexOf(e)
      }
      if (tab.index > 5) {
        tab.index = 5
      }
      this.changeTab(tab)
    }
  },
  computed: {
    ...mapGetters(['currentSection', 'currentTab'])
  },
  created () {
    this.initialize()
  }
}
</script>

<style lang="scss" scoped>
.back-button {
  cursor: pointer;
  padding-left: 0px;
}

.back-button-text {
  color: var(--v-resBlue-base);
}
</style>
