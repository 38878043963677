<template>
  <div>
    <v-app-bar height="50" app clipped-left color="var(--v-resBlue-base)" text dark>
      <v-app-bar-nav-icon v-if="$vuetify.breakpoint.smAndDown" @click.stop="openSideBar"></v-app-bar-nav-icon>
      <v-toolbar-title class="resman-title">
        ResMan Commercial
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <!-- Menu item for help-->
        <v-menu :nudge-left="126" :nudge-bottom="41" min-width="290" attach=".v-toolbar">
          <template v-slot:activator="{ on }">
            <v-btn :ripple="false" class="top-nav__button" text v-on="on" v-if="$vuetify.breakpoint.mdAndUp">
                <v-icon>help</v-icon>
            </v-btn>
          </template>
          <top-pull-downs :navItems="help"></top-pull-downs>
        </v-menu>
        <!-- Menu item for notifications  DESKTOP VERSION-->
        <v-menu :nudge-left="226" :nudge-bottom="41" min-width="340" attach=".v-toolbar">
          <template v-slot:activator="{ on }">
            <v-btn :ripple="false"  @click="markRead" class="top-nav__button" text v-on="on" v-if="$vuetify.breakpoint.mdAndUp">
                <v-badge overlap color="var(--v-resRed-base)" :value="unreadNotifications.length > 0">
                  <template v-slot:badge>
                    <span class="notifications-count-text">{{ unreadNotifications.length }}</span>
                  </template>
                  <v-icon class="notifications-icon">notification_important</v-icon>
                </v-badge>
            </v-btn>
          </template>
          <!-- dropdown card for notifications -->
            <v-list dense>
              <template v-for="(item, index) in notifications">
                <v-list-item v-if="item.heading" :key="item.heading" class="my-2">
                  <v-list-item-title>
                    <span><strong>{{ item.heading }}</strong></span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="openNotificationDetailDialog(item.sourceId, item.eventInfo)" :key="item.eventInfo" v-else  :ripple="{ center: true }">
                    <v-list-item-content>
                      <span class="pl-2">{{ item.eventInfo }}</span>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn class="notifications-count-icon resWhite-base--text" depressed small color="orange"><span>{{ item.exceptionCount }}</span></v-btn>
                    </v-list-item-action>
                </v-list-item>
                <v-divider class="notifications-divider" :key="index" v-if="!item.heading && index + 1 < notifications.length"></v-divider>
              </template>
          </v-list>
        </v-menu>
        <!-- Menu item for notifications  Mobile VERSION-->
        <template @click.stop="openDrawer" v-if="$vuetify.breakpoint.smAndDown">
          <v-btn :ripple="false"  @click="markRead" class="top-nav__button" text>
              <v-badge color="var(--v-resRed-base)">
                <template v-slot:badge  v-if="unreadNotifications.length > 0">
                  <span class="notifications-count-text">{{ unreadNotifications.length }}</span>
                </template>
                <v-icon class="notifications-icon">notification_important</v-icon>
              </v-badge>
          </v-btn>
        </template>
        <!-- Menu item for account information -->
        <v-menu :nudge-left="226" :nudge-bottom="41" min-width="290" attach=".v-toolbar">
          <template v-slot:activator="{ on }">
            <v-btn :ripple="false" class="top-nav__button" text v-on="on" v-if="$vuetify.breakpoint.mdAndUp">
                <v-icon>account_circle</v-icon>
            </v-btn>
          </template>
          <top-pull-downs :navItems="account"></top-pull-downs>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>
    <notification-details
      v-if="showNotificationDetailsDialog"
      :showDialog="showNotificationDetailsDialog"
      :eventSourceId="notificationSourceId"
      :eventSourceDescription="notificationDescription"
      @close="closeNotificationDetailDialog">
    </notification-details>
  </div>
</template>

<script>
import TopPullDowns from './TopPullDowns'
import NotificationDetails from './Dialogs/NotificationDetails'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    TopPullDowns,
    NotificationDetails
  },
  data () {
    return {
      drawer: null,
      account: [],
      help: [],
      notificationSourceId: null,
      notificationDescription: null,
      showNotificationDetailsDialog: false
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'currentUserName', 'currentUserEmail', 'currentData', 'checkPermissions', 'currentUserIsAdmin']),
    unreadNotifications () {
      return this.notifications.filter(n => {
        return n.read === false
      })
    },
    notifications () {
      var notify = this.currentData('Notifications')
      notify.unshift({ heading: 'Notifications' })
      return notify
    }
  },
  created () {
    this.intialize()
    this.setUser()
    setTimeout(() => {
      if (this.checkPermissions('Notifications') || this.currentUserIsAdmin) {
        this.fetchData({ newPage: 'Notifications' }).catch(error => { console.log(error.response) })
      }
    }, 750)
  },
  methods: {
    ...mapActions(['fetchData']),
    intialize () {
      this.account = [
        { heading: 'Account Name', subtitle: 'Property Manager', sectionHeader: true },
        { title: 'Company Name', action: 'goToResman' },
        { title: 'ResMan Homepage', action: 'goToResman' }
        /** { title: 'Toggle Theme (Light/Dark)', action: 'toggleTheme' } **/
      ]
      this.help = [
        { heading: 'Helpful Links', sectionHeader: true },
        { title: 'User Guides' },
        { title: 'About ResMan Commercial' }
      ]
    },
    markRead () {
      for (var i = 0; i < this.currentData('Notifications').length; i++) {
        this.currentData('Notifications')[i].read = true
      }
    },
    openNotificationDetailDialog: function (source, sourceDescription) {
      this.notificationSourceId = source
      this.notificationDescription = sourceDescription
      this.showNotificationDetailsDialog = true
    },
    closeNotificationDetailDialog: function () {
      this.notificationSourceId = null
      this.notificationDescription = null
      this.showNotificationDetailsDialog = false
    },
    openDrawer: function () {
      this.$eventHub.$emit('open-drawer', true)
    },
    openSideBar: function () {
      this.$eventHub.$emit('open-sideBar', true)
    },
    openCommercialDialog: function () {
      this.$eventHub.$emit('open-inactivityDialog', { type: 'Timeout', switch: true })
    },
    logout () {
      this.mgr.signOut()
    },
    setUser () {
      if (this.currentUser) {
        this.account[0].heading = this.currentUserName
        this.account[0].subtitle = this.currentUserEmail
        this.account[1].title = this.currentUser.profile.company
      }
    }
  },
  watch: {
    currentUser: function (val) {
      if (val) {
        this.setUser()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-toolbar__items{
    height: 28px;
  }
  .top-nav__button{
    min-width: 40px !important;
    width: 40px !important;
    height: 30px !important;
    min-width: 0px;
    border-radius: 0px;
    margin-left:10px !important;
    &:focus{
      box-shadow: 0 2px 0var(--v-resWhite-base);
      background-color: transparent;
    }
    &:hover{
      background-color: transparent;
      box-shadow: 0 2px 0var(--v-resWhite-base);
    }
    &:before{
      background-color: transparent;
    }
    &:focus:before{
      background-color: transparent;
    }
    ::v-deep .v-badge__badge{
      min-height:16px !important;
      height: 16px;
      min-width:16px !important;
      width: 16px;
      text-align: center;
      vertical-align: middle;
      padding: 2px 0 6px 1px;
      cursor: pointer;
    }
  }
  .notifications-divider{
    width:90%;
    margin-left: 15px;
  }
  .notifications-count-icon{
    border-radius: 6px;
    min-width: 25px !important;
    max-width:25px !important;
    width: 25px !important;
    min-height: 20px !important;
    max-height: 20px !important;
    height: 20px !important;
    &__content{
      font-family:Roboto;
      font-size:10.3px;
      cursor: pointer;
    }
  }
  .v-menu__content{
    left: 100vw;
  }
  .notifications-count-text{
    font-size:9.7px;
  }
  .resman-title{
    font-family: Whitney;
    font-size: 21.6px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--v-resWhite-base);
    margin-left: 5px;
  }
  .v-list.v-sheet{
    padding: 0 !important;
  }
  .v-list-item{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .v-list-item__content{
    font-size: 13px;
  }
  .notifications-divider{
    width:90%;
    margin-left: 15px;
  }
</style>
