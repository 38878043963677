export function parseCurrency (s) {
  if (s) {
    return parseFloat(parseFloat(s.toString().replace(/,/g, '')).toFixed(2))
  }
  return parseFloat(0) // if it is not defined or empty then return 0
}

export function convertToCurrency (f) {
  if (!isNaN(parseCurrency(f)) && f.toString().length > 0) {
    return parseCurrency(f)
      .toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  } else {
    return f
  }
}
