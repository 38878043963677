<template>
  <v-container fluid class="resGray lighten-6">
    <v-app-bar flat>
      <drop-down
        :selectedValue="{ text: selectedRoleName }"
        :items="'UserRoles'"
        :label="'Select Role'"
        :genericLabel="true"
        :multiple='false'
        :hideDetails="true"
        width="220px"
        rules="required"
        class="mr-3"
        @itemSelected="selectRole">
      </drop-down>
      <round-button
        height='30px'
        width='100px'
        :dark='true'
        :depressed='true'
        :outline="true"
        buttonText='+ New Role'
        backgroundColor='white'
        buttonTextColor="var(--v-resBlue-darken1)"
        borderColor='var(--v-resBlue-darken1)'
        buttonClass='mt-9'
        @click="newRole">
      </round-button>
    </v-app-bar>
    <v-layout>
      <v-item-group
        v-model="window"
        class="shrink mx-4 permission-categories"
        mandatory
        tag="v-flex">
        <v-item v-for="(name, index) in cards" :key="index">
          <div slot-scope="{ active, toggle }">
            <permission-cards
                class="permissions-card"
                :cardText="name"
                :isSelected="active"
                @click.native="toggle(); selectCard(name)">
            </permission-cards>
          </div>
        </v-item>
      </v-item-group>
      <v-flex>
        <v-window
          v-model="window"
          vertical
          class="mr-4 pt-3">
          <v-window-item v-for="(name, index) in cards" :key="index">
            <v-card flat max-height="500">
              <v-flex style="overflow: auto">
                <v-card max-height="500" flat>
                  <data-table
                    v-if="name === selectedCard"
                    class="permissions-table"
                    :headers="headers"
                    :tableData="getData"
                    :noData="'No information to display.'">
                  </data-table>
                </v-card>
              </v-flex>
            </v-card>
          </v-window-item>
        </v-window>
      </v-flex>
    </v-layout>
    <v-app-bar flat height="36" class="mt-3">
      <v-spacer></v-spacer>
      <round-button
        buttonTextColors='var(--v-resRed-base)'
        height='30px'
        width='100px'
        :depressed='true'
        :outline="true"
        :disabled="!canSave"
        buttonText='Delete Role'
        buttonTextColor='var(--v-resRed-base)'
        backgroundColor="var(--v-resWhite-base)"
        :borderColor="!canSave ? 'var(--v-resGray-lighten1)': 'var(--v-resRed-base)'"
        buttonClass='mr-3'
        @click="openDeleteDialog">
      </round-button>
      <round-button
        color='var(--v-resGray-darken3)'
        height='30px'
        width='135px'
        :depressed='true'
        :disabled="!canSave"
        buttonText='Save Changes'
        backgroundColor='var(--v-resGreen-lighten1)'
        borderColor='var(--v-resGray-lighten1)'
        @click="openUpdateDialog">
      </round-button>
    </v-app-bar>
    <!-- Dialogs -->
    <base-dialog
      :showDialog="showDialog"
      :title="'Add New Role'"
      :titleClass="'dark-header'"
      :maxWidth="380"
      saveButtonText="Create Role"
      saveButtonWidth="110"
      @cancel="cancelRole"
      iconColor="var(--v-resWhite-base)"
      @save="saveRole">
      <v-row no-gutters class="ml-4 mt-4">
        <v-col cols="12">
          <text-input :value="roleName"
            label='Role name'
            width="300px"
            @textInputUpdated="updateRoleName">
          </text-input>
        </v-col>
      </v-row>
      <v-row no-gutters class="ml-4 mb-4 mt-2">
        <v-col cols="12">
          <drop-down
            :selectedValue="{ text: copySelectedRoleName }"
            :items="'UserRoles'"
            :label="'Copy from existing role'"
            :genericLabel="true"
            :multiple='false'
            width="165px"
            rules="required"
            @itemSelected="selectCopyRole">
          </drop-down>
        </v-col>
      </v-row>
    </base-dialog>
    <base-dialog
      :showDialog="showUpdateDialog"
      :title="'User Role Update'"
      :iconColor="'white'"
      :titleClass="'dark-header'"
      :maxWidth="350"
      saveButtonText="Update Changes"
      saveButtonWidth="130px"
      @cancel="cancelUpdate"
      @save="submit">
      <v-card-text>
        <p class="caption">This action will update all users that are assgined to this user role. You can choose to ignore users with modified roles.</p>
        <p class="caption">Are you sure you want to make this update?</p>
        <v-layout row class="checkbox-container">
          <v-checkbox
            height="20"
            hide-details
            :ripple="false"
            v-model="ignoreModified"
            class="shrink ma-0 pa-0"
            color="var(--v-resBlue-darken1)">
          </v-checkbox>
          <span class="caption">Ignore modified user roles</span>
        </v-layout>
      </v-card-text>
    </base-dialog>
    <delete-dialog
      :showDialog="deleteDialog"
      :title="'Delete User Role'"
      :message="'This role can only be deleted if there are no users assigned to this role.'"
      confirmButton="Yes, Delete"
      confirmButtonWidth="100px"
      @confirmDelete="confirmDelete"
      @cancelDelete="cancelDelete">
      <p>Are you sure you want to continue?</p>
    </delete-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import RoundButton from '../../BaseComponents/RoundButton'
import DropDown from '../../BaseComponents/DropDown'
import BaseDialog from '../../BaseComponents/BaseDialog'
import DataTable from '../../BaseComponents/Table'
import TextInput from '../../BaseComponents/TextInput'
import PermissionCards from './PermissionCards'
import DeleteDialog from '../../BaseComponents/DeleteDialog'

export default {
  components: {
    DropDown,
    RoundButton,
    BaseDialog,
    TextInput,
    PermissionCards,
    DataTable,
    DeleteDialog
  },
  data () {
    return {
      showDialog: false,
      selectedRole: undefined,
      selectedRoleName: undefined,
      selectedCard: 'General',
      roleName: '',
      cards: ['General', 'Leases', 'Properties', 'Transactions', 'Recoveries', 'Setup'],
      window: 0,
      showUpdateDialog: false,
      ignoreModified: true,
      deleteDialog: false,
      copySelectedRole: undefined,
      copySelectedRoleName: undefined
    }
  },
  methods: {
    ...mapActions(['fetchData', 'changeData', 'initializeStoreItem', 'deleteData']),
    selectRole (val) {
      this.selectedRole = val.value
      this.selectedRoleName = val.text
      if (this.selectedRole !== null && this.selectedRole !== undefined) {
        this.initializeStoreItem('UserRoles')
        this.fetchData({ newPage: 'UserRoles', params: { RoleId: this.selectedRole } }).catch(error => { console.log(error.response) })
      } else {
        this.selectedRole = null
        this.selectedRoleName = null
      }
    },
    selectCopyRole (val) {
      if (val !== null && val !== undefined) {
        this.copySelectedRole = val.value
        this.copySelectedRoleName = val.text
      } else {
        this.copySelectedRole = null
        this.copySelectedRoleName = null
      }
    },
    newRole () {
      this.showDialog = true
    },
    cancelRole () {
      this.showDialog = false
    },
    selectCard (val) {
      this.selectedCard = val
    },
    saveRole () {
      this.changeData({ currentPage: 'UserRoles', newData: { RoleName: this.roleName, RoleToCopy: this.copySelectedRole } }).then(() => {
        this.showDialog = false
        this.roleName = ''
        this.refreshDropdown()
      }).catch(error => { console.log(error.response) })
    },
    refreshDropdown () {
      this.$eventHub.$emit('refreshDropdown', true)
    },
    updateRoleName (val) {
      this.roleName = val
    },
    openUpdateDialog () {
      if (this.canSave) {
        this.showUpdateDialog = true
      }
    },
    cancelUpdate () {
      this.showUpdateDialog = false
    },
    submit () {
      this.changeData({ currentPage: 'UserRoles', newData: { RoleId: this.selectedRole, RolePermissions: this.allData, IgnoreModified: this.ignoreModified } }).then(() => {
        this.fetchData({ newPage: 'UserRoles', params: { RoleId: this.selectedRole } }).then(() => {
          this.showUpdateDialog = false
        })
      }).catch(error => { console.log(error.response) })
    },
    openDeleteDialog () {
      if (this.canSave) {
        this.deleteDialog = true
      }
    },
    confirmDelete () {
      this.deleteData({ currentPage: 'UserRoles', removeItem: { RoleId: this.selectedRole } }).then(() => {
        this.selectedRole = null
        this.selectedRoleName = null
        this.refreshDropdown()
        this.deleteDialog = false
      }).catch(error => { console.log(error.response) })
    },
    cancelDelete () {
      this.deleteDialog = false
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentUser', 'currentHeaders']),
    allData () {
      return this.currentData('UserRoles')
    },
    getData () {
      return this.allData.filter(x => x.category === this.selectedCard)
    },
    headers () {
      return this.currentHeaders('UserRoles')
    },
    canSave () {
      return this.selectedRole !== null && this.selectedRole !== undefined
    }
  },
  beforeDestroy () {
    this.initializeStoreItem('UserRoles')
  }
}
</script>

<style lang="scss" scoped>
.permission-categories {
  padding-top: 2px;
}
.permissions-table {
  border: solid 1px var(--v-resGray-lighten1);
}
.checkbox-container {
  margin-left: 21px;
}
</style>
