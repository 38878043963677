<template>
  <base-dialog
    :showDialog="showDialog"
    :title="dialogTitle"
    :maxWidth="900"
    :hideActions="true"
    @cancel="cancel">
    <v-card-text>
      <data-table
        class="mx-2 my-2"
        :headers="headers"
        :tableData="getData"
        :noFooter="true">
      </data-table>
    </v-card-text>
  </base-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DataTable from '../../BaseComponents/Table'
import BaseDialog from '../../BaseComponents/BaseDialog'

export default {
  components: {
    DataTable,
    BaseDialog
  },
  props: ['showDialog', 'spaceOrArea', 'measureType'],
  data () {
    return {
      dialogTitle: 'Space Measures History'
    }
  },
  methods: {
    ...mapActions(['changeLoading', 'fetchData', 'initializeStoreItem']),
    cancel () {
      this.initializeStoreItem('PropertySpaceMeasuresHistory')
      this.$emit('cancel', true)
    },
    getHistory () {
      this.changeLoading(true)
      setTimeout(() => {
        this.fetchData({newPage: 'PropertySpaceMeasuresHistory', params: { PropertyId: this.currentSelectedItem.property, Space: this.spaceOrArea, Area: this.spaceOrArea, measureType: this.measureType }}).catch(error => { console.log(error.response) })
      }, 250)
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentSelectedItem', 'currentLoading']),
    getData () {
      return this.currentData('PropertySpaceMeasuresHistory')
    },
    headers () {
      return this.currentHeaders('PropertySpaceMeasuresHistory').filter(h => {
        if (this.measureType === 'Space') {
          return h.value !== 'area'
        } else {
          return h.value !== 'space'
        }
      })
    }
  },
  mounted () {
    this.getHistory()
  }
}
</script>
