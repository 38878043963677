import { render, staticRenderFns } from "./SpaceAvailability.vue?vue&type=template&id=40433b51&scoped=true&"
import script from "./SpaceAvailability.vue?vue&type=script&lang=js&"
export * from "./SpaceAvailability.vue?vue&type=script&lang=js&"
import style0 from "./SpaceAvailability.vue?vue&type=style&index=0&id=40433b51&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40433b51",
  null
  
)

export default component.exports