<template>
  <base-dialog
    :showDialog="showDialog"
    :title="'Add Lease Spaces'"
    :maxWidth="700"
    :setWidth="700"
    @cancel="cancel"
    @save="save">
    <v-card-text>
      <v-div>
        <span class="font-weight-medium">Selected Spaces: {{ this.Spaces.selectedSpaces.length }}</span>
      </v-div>
      <v-card max-height="240" flat>
        <div style="overflow: auto">
          <v-card max-height="235" flat>
            <data-table
              class="space__table"
              :headers="headers"
              :selectableRows="true"
              :tableData="getData"
              :tableKey="'propertySpace'"
              :showActions="false"
              :editOnly='false'
              :noFooter="true"
              :noPagination="true"
              :baseRowPerPage="50"
              :selectedRows="Spaces.selectedSpaces"
              @selectionChanged="changeSelected"
              :noData="'No information to display.'">
            </data-table>
          </v-card>
        </div>
      </v-card>
      <v-row no-gutters>
        <v-col col="12">
          <date-picker
            :initialDate="Spaces.sameAsLeaseDate ? Spaces.planOccupyDate : null"
            :defaultNull="!Spaces.sameAsLeaseDate"
            :label="'Choose Actual Move-In Date. (Optional)'"
            @dateSelected="updatePlanOccupyDate">
          </date-picker>
        </v-col>
      </v-row>
      <v-row no-gutters class="checkbox__container">
        <v-col cols="12">
          <v-checkbox
            height="20"
            :ripple="false"
            v-model="Spaces.sameAsLeaseDate"
            class="shrink ma-0 pa-0"
            color="var(--v-resBlue-darken1)"
            label="Move tenant in">
          </v-checkbox>
        </v-col>
      </v-row>
    </v-card-text>
  </base-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DatePicker from '../../BaseComponents/DatePicker'
import BaseDialog from '../../BaseComponents/BaseDialog'
import DataTable from '../../BaseComponents/Table'
import moment from 'moment'

export default {
  components: {
    DatePicker,
    BaseDialog,
    DataTable
  },
  props: ['showDialog'],
  data () {
    return {
      Spaces: {
        sameAsLeaseDate: null,
        selectedSpaces: [],
        startDate: null
      }
    }
  },
  methods: {
    ...mapActions(['fetchData', 'changeData']),
    updatePlanOccupyDate (val) {
      this.Spaces.planOccupyDate = val
    },
    changeSelected (val) {
      this.Spaces.selectedSpaces = val
    },
    cancel () {
      this.$emit('cancel')
    },
    save () {
      if (this.Spaces.selectedSpaces && this.Spaces.selectedSpaces.length > 0) {
        this.Spaces.selectedSpaces.forEach(space => {
          space.lease = this.currentSelectedItem.lease
          space.leaseRevision = this.currentSelectedItem.leaseRevision
          space.planOccupyDate = this.Spaces.planOccupyDate
          space.planVacateDate = this.currentSelectedItem.endDate
        })
        this.changeData({ currentPage: 'LeaseSpaces', newData: { LeaseSpaces: this.Spaces.selectedSpaces } }).then(() => {
          this.$emit('save')
        }).catch(error => { console.log(error.response) })
      }
    },
    calculateDate () {
      this.Spaces.sameAsLeaseDate = this.currentSelectedItem ? moment(this.currentLease.startDate).isSameOrBefore(new Date()) : null
      this.Spaces.planOccupyDate = this.currentSelectedItem.startDate
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentSelectedItem']),
    headers: {
      get () {
        return this.currentHeaders('LeaseAvailableSpaces')
      }
    },
    getData: {
      get () {
        return this.currentData('LeaseAvailableSpaces')
      }
    }
  },
  created () {
    this.fetchData({ newPage: 'LeaseAvailableSpaces', params: { Property: this.currentSelectedItem.property } }).catch(error => { console.log(error.response) })
  }
}
</script>

<style lang="scss" scoped>
  .checkbox__container {
    ::v-deep label {
      color: var(--v-resBlack-base);
      font-size: 14px;
    }
  }
  .space__table {
    ::v-deep span {
      font-size: 12px;
    }
    ::v-deep thead tr {
      height: 30px !important;
    }
    ::v-deep thead th {
      padding: 0 !important;
      height: 30px !important;
    }
    ::v-deep tbody tr {
      height: 30px !important;
    }
    ::v-deep tbody td {
      padding: 0 !important;
      height: 30px !important;
    }
  }
</style>
