<template>
  <v-container>
    <v-dialog
      v-model="showDialog"
      persistent
      width="790">
      <v-app-bar dark height="40" color="var(--v-resBlue-darken1)">
        <span class="caption">New Property</span>
        <v-spacer></v-spacer>
        <v-btn @click="cancelDialog" icon>
          <v-icon>close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card height="570" style="border-radius: 0;">
        <v-card-text class="px-0 pt-0 pb-0">
          <v-stepper alt-labels class="elevation-0" :value="currentPropertyStep">
            <v-stepper-header class="elevation-0">
              <v-stepper-step class="pb-0" :complete="propertyComplete" step="Property">
                Property
              </v-stepper-step>
              <v-divider :class="propertyComplete ? 'complete__divider' : ''"></v-divider>
              <v-stepper-step class="pb-0" :complete="spacesComplete" step="Spaces">
                Space
              </v-stepper-step>
              <v-divider :class="spacesComplete ? 'complete__divider' : ''"></v-divider>
              <v-stepper-step class="pb-0" :complete="chargesComplete" step="ChargesChoose">
                Charges
              </v-stepper-step>
              <v-stepper-step class="pb-0" v-show="false" step="ChargesNew">
                Charges New
              </v-stepper-step>
              <v-stepper-step class="pb-0" v-show="false" step="ChargesTemplate">
                Charges Template
              </v-stepper-step>
              <v-divider :class="chargesComplete ? 'complete__divider' : ''"></v-divider>
              <v-stepper-step class="pb-0" :complete="customComplete" step="Custom">
                Custom
              </v-stepper-step>
              <v-divider :class="customComplete ? 'complete__divider' : ''" ></v-divider>
              <v-stepper-step class="pb-0" :complete="false" step="Review">
                Review
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content class="pt-0" step="Property">
                <property></property>
              </v-stepper-content>
              <v-stepper-content class="pt-0" step="Spaces">
                <property-spaces v-if="this.currentPropertyStep === 'Spaces'"></property-spaces>
              </v-stepper-content>
              <v-stepper-content class="pt-0" step="ChargesChoose">
                <property-charges-choose></property-charges-choose>
              </v-stepper-content>
              <v-stepper-content class="pt-0" step="ChargesNew">
                <property-charges-new v-if="this.currentPropertyStep === 'ChargesNew'" @loaded="showWarning"></property-charges-new>
              </v-stepper-content>
              <v-stepper-content class="pt-0" step="ChargesTemplate">
                <property-charges-template v-if="this.currentPropertyStep === 'ChargesTemplate'" @loaded="showWarning"></property-charges-template>
              </v-stepper-content>
              <v-stepper-content class="pt-0" step="Custom">
                <property-custom v-if="this.currentPropertyStep === 'Custom'"></property-custom>
              </v-stepper-content>
              <v-stepper-content class="pt-0" step="Review">
                <property-review v-if="this.currentPropertyStep === 'Review'"></property-review>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
        <warning-bar
          class="mx-7 mb-2"
          message="You must complete at least one charge code mapping to proceed. It's recommended to complete all."
          @closeWarning="showWarning"
          :showWarning="warningShowing">
        </warning-bar>
        <v-card-actions class="px-4 ml-2 pt-0 pb-4">
          <round-button
            color='var(--v-resGray-darken3)'
            height='30px'
            width='80px'
            v-if="this.currentPropertyStep !== 'Property' && this.currentPropertyStep !== 'Review' && !this.currentPropertyReview"
            dark
            :depressed='true'
            buttonText='Back'
            :borderColor="'var(--v-resBlue-darken1)'"
            :buttonTextColor="'var(--v-resBlue-darken1)'"
            :outline="true"
            :disabled="currentLoading"
            buttonClass='ml-1 mt-0'
            @click="previousStep">
          </round-button>
          <v-spacer></v-spacer>
          <round-button
            color='var(--v-resRed-base)'
            height='30px'
            width='80px'
            :disabled="currentLoading"
            v-if="(this.currentPropertyStep === 'Property' || this.currentPropertyStep === 'Review') && !this.currentPropertyReview"
            dark
            :depressed='true'
            :outline='true'
            buttonText='Cancel'
            :buttonTextColor="'var(--v-resRed-base)'"
            :borderColor="'var(--v-resRed-base)'"
            buttonClass='mr-3'
            @click="cancelDialog">
          </round-button>
          <round-button
            color='var(--v-resGray-darken3)'
            height='30px'
            v-if="this.currentPropertyStep !== 'ChargesChoose'"
            :width='continueButton.width'
            dark
            :disabled="currentLoading"
            :depressed='true'
            :buttonText='continueButton.text'
            :backgroundColor="continueButton.color"
            borderColor='var(--v-resGray-lighten1)'
            buttonClass=' mr-3 mt-0'
            @click="currentPropertyReview ? returnToReview() : continueDialog()">
          </round-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialogs -->
    <base-dialog
      :title="'Are you sure?'"
      :showDialog="confirmCancel"
      :saveButtonText="'yes, proceed'"
      :iconColor="'white'"
      :saveButtonColor="'var(--v-resBlue-darken1)'"
      :cancelButtonTextColor="'resBlue-darken1--text'"
      :cancelButtonColor="'var(--v-resBlue-darken1)'"
      :cancelButtonText="'Cancel'"
      :titleClass="'dark-header'"
      :saveButtonWidth="120"
      @cancel="cancelDelete"
      @save="confirmDelete">
      <v-card-text class="pl-6">
        <span>You will lose all information you have entered for this property and related content if you close this window. Do you still wish to proceed?</span>
      </v-card-text>
    </base-dialog>
  </v-container>
</template>

<script>
import RoundButton from '../../BaseComponents/RoundButton'
import BaseDialog from '../../BaseComponents/BaseDialog'
import WarningBar from '../../BaseComponents/WarningBar'
import Property from './Property'
import PropertyChargesChoose from './PropertyChargesChoose'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    RoundButton,
    BaseDialog,
    WarningBar,
    Property,
    PropertySpaces: () => import('./PropertySpaces'),
    PropertyChargesChoose,
    PropertyChargesNew: () => import('./PropertyChargesNew'),
    PropertyChargesTemplate: () => import('./PropertyChargesTemplate'),
    PropertyCustom: () => import('./PropertyCustom'),
    PropertyReview: () => import('./PropertyReview')
  },
  props: ['showDialog'],
  data () {
    return {
      previousButton: false,
      propertyComplete: false,
      spacesComplete: false,
      chargesComplete: false,
      customComplete: false,
      warningShowing: false,
      confirmCancel: false
    }
  },
  methods: {
    ...mapActions(['initializeStoreItem', 'changeData', 'deleteData', 'changeProperty', 'changeOneSpaceRequired', 'changePropertyStep', 'changePropertyPreviousStep', 'changePropertyNextStep', 'changePropertyReview', 'clearPropertyWorkflow']),
    continueDialog () {
      if (!this.currentLoading) {
        if (this.currentPropertyStep === 'Property') {
          this.propertyUpdate()
        } else if (this.currentPropertyStep === 'Spaces') {
          this.spacesUpdate()
        } else if (this.currentPropertyStep === 'ChargesNew' || this.currentPropertyStep === 'ChargesTemplate') {
          this.chargesUpdate()
        } else if (this.currentPropertyStep === 'Custom') {
          this.customUpdate()
        } else if (this.currentPropertyStep === 'Review') {
          this.completeDialog()
        }
      }
    },
    nextStep () {
      if (this.currentPropertyStep === 'ChargesNew' || this.currentPropertyStep === 'ChargesTemplate') {
        this.changePropertyStep('Custom')
      } else {
        this.changePropertyNextStep()
      }
      if (this.currentPropertyStep === 'Review') {
        this.changePropertyReview(true)
      }
    },
    previousStep () {
      if (!this.currentLoading) {
        if (this.currentPropertyStep === 'ChargesNew' || this.currentPropertyStep === 'ChargesTemplate') {
          this.initializeStoreItem('ChargeCodeMaps')
          this.changePropertyStep('ChargesChoose')
        } else if (this.currentPropertyStep === 'Custom') {
          this.changePropertyStep('ChargesNew')
        } else {
          this.changePropertyPreviousStep()
        }
      }
    },
    returnToReview () {
      if (this.currentPropertyStep === 'Review') {
        this.completeDialog()
      }
      this.changePropertyStep('Review')
    },
    resetWorkflow () {
      this.propertyComplete = false
      this.spacesComplete = false
      this.chargesComplete = false
      this.customComplete = false
      this.initializeStoreItem('ChargeCodeMaps')
      this.initializeStoreItem('PropertiesNew')
      this.initializeStoreItem('PropertySpacesNew')
      this.changePropertyStep('Property')
      this.clearPropertyWorkflow()
    },
    showWarning () {
      this.warningShowing = !this.warningShowing
    },
    propertyUpdate () {
      this.$eventHub.$emit('validate')
      setTimeout(() => {
        if (!this.errors.items.length > 0) {
          let currentProperty = this.currentProperty
          this.changeData({ currentPage: 'PropertiesNew', newData: this.currentProperty, updateItem: this.currentProperty }).then(() => {
            let property = this.currentData('PropertiesNew')[0]
            property.buildingName = currentProperty.buildingName
            property.areaType = currentProperty.areaType
            property.areaTypeName = currentProperty.areaTypeName
            property.propertyTypeName = currentProperty.propertyTypeName
            property.propertyName = currentProperty.propertyName
            property.propertyTemplateName = currentProperty.propertyTemplateName
            property.propertyTemplate = currentProperty.propertyTemplate
            property.areaName = currentProperty.areaName
            this.changeProperty(property)
            this.propertyComplete = true
            this.nextStep()
          }).catch(error => { console.log(error.response) })
        }
      }, 100)
    },
    spacesUpdate () {
      if (this.currentData('PropertySpacesNew').length > 0) {
        this.spacesComplete = true
        this.nextStep()
      } else {
        this.changeOneSpaceRequired(true)
      }
    },
    chargesUpdate () {
      if (this.currentPropertyCharges.length > 0) {
        this.chargesComplete = true
        this.changeData({ currentPage: 'ChargeCodeMaps', newData: { Property: this.currentProperty.company, Charges: this.currentPropertyCharges } }).then(() => {
          this.nextStep()
          if (this.warningShowing) {
            this.showWarning()
          }
        }).catch(error => { console.log(error.response) })
      } else {
        if (!this.warningShowing) {
          this.showWarning()
        }
      }
    },
    customUpdate () {
      this.customComplete = true
      this.nextStep()
    },
    confirmDelete () {
      if (this.currentProperty.property !== undefined) {
        this.deleteData({ currentPage: 'PropertiesNew', removeItem: { Property: this.currentProperty.property } }).then(() => {
          this.resetWorkflow()
        }).catch(error => { console.log(error.response) })
      } else {
        this.resetWorkflow()
      }
      this.$emit('cancel', true)
    },
    cancelDelete () {
      this.confirmCancel = false
    },
    cancelDialog () {
      this.confirmCancel = true
    },
    completeDialog () {
      this.changeData({ currentPage: 'PublishPropertySpaces', newData: { Property: this.currentProperty.property, Space: null } }).catch(error => { console.log(error.response) })
      this.resetWorkflow()
      this.$emit('complete', true)
    }
  },
  created () {
    this.resetWorkflow()
  },
  computed: {
    ...mapGetters(['currentLoading', 'currentData', 'currentProperty', 'currentPropertyStep', 'currentPropertyReview', 'currentPropertyCharges']),
    continueButton: {
      get () {
        if (this.currentPropertyReview && this.currentPropertyStep !== 'Review') {
          return { text: 'Review & Finish', color: 'var(--v-resBlue-darken1)', width: '150px' }
        } else if (this.currentPropertyStep === 'Property' && !this.propertyComplete) {
          return { text: 'Start', color: 'var(--v-resGreen-lighten1)', width: '80px' }
        } else if (this.currentPropertyStep === 'Review') {
          return { text: 'Finish', color: 'var(--v-resGreen-lighten1)', width: '80px' }
        } else {
          return { text: 'Next', color: 'var(--v-resBlue-darken1)', width: '80px' }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-stepper__header{
    width:450px;
    margin: auto;
    hr {
      max-height:5px;
      background-color: var(--v-resGray-lighten4);
      border: 1px var(--v-resGray-lighten2) solid;
      height: 5px;
      margin: 30px -82px 0 !important;
      box-shadow: inset 0 0 0 0 var(--v-resGray-lighten4);
      -webkit-transition: all ease .75s;
      transition: all ease .75s;
    }
    .complete__divider {
      box-shadow: inset 60px 0 0 0 var(--v-resGreen-lighten1);
      -webkit-transition: all ease .75s;
      transition: all ease .75s;
    }
    .half__step {
      box-shadow: inset 30px 0 0 0 var(--v-resGreen-lighten1);
      -webkit-transition: all ease .75s;
      transition: all ease .75s;
    }
    ::v-deep .v-stepper__step {
      min-width: 0px;
      margin-bottom: 4px;
      &__step {
        min-width: 0px;
        height: 18px;
        width: 18px;
      }
      .v-stepper__step__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) {
        background-color: var(--v-resGray-lighten4);
        color: transparent;
        border: 1px var(--v-resGray-lighten2) solid;
        -webkit-transition: background-color ease .5s;
        transition: background-color ease .5s;
      }
      &--complete {
        .v-stepper__step__step {
          background-color: var(--v-resGreen-lighten1) !important;
          border-color: var(--v-resGreen-lighten1) !important;
          -webkit-transition: background-color ease .5s;
          transition: background-color ease .5s;
          .v-icon {
            font-size: 14px;
          }
        }
      }
      &--active {
        .v-stepper__step__step{
          background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 45%, rgba(131,186,28,1) 50%, rgba(131,186,28,1) 100%);
          background-color: var(--v-resGreen-lighten1) !important;
          border-color: var(--v-resGreen-lighten1) !important;
          -webkit-transition: background-color ease .5s;
          transition: background-color ease .5s;
        }
      }
    }
  }
  .dot {
    height: 10px;
    width: 10px;
    background-color: var(--v-resGreen-lighten1);
    border-radius: 50%;
    margin-left: 2px;
    margin-right: 2px;
    display: inline-block;
  }
</style>
