<template>
  <div>
    <leases-tabs>
      <router-view></router-view>
    </leases-tabs>
  </div>
</template>

<script>
import LeasesTabs from './LeasesTabs'

export default {
  data () {
    return {}
  },
  components: {
    LeasesTabs
  }
}
</script>
