<template>
  <div class="resGray lighten-6">
    <unapply-prepayments-table
      v-if="!viewCheck"
      @setDate="setDate"
      :selectedProperty="selectedProperty"
      :selectedDate="selectedDate"
      @setProperty="setProperty"
      @openCheckDetails="getInfo">
    </unapply-prepayments-table>
    <applied-prepayment-summary
      v-if="viewCheck"
      @returnToGrid="refreshGrid"
      :currentCheck="currentCheck"
      :currentJournalItem="currentJournalItem"
      :depositDetails="depositDetails">
    </applied-prepayment-summary>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UnapplyPrepaymentsTable from './UnapplyPrepaymentsComponents/UnapplyPrepaymentsTable'
import AppliedPrepaymentSummary from './UnapplyPrepaymentsComponents/AppliedPrepaymentSummary'
import moment from 'moment'

export default {
  components: {
    UnapplyPrepaymentsTable,
    AppliedPrepaymentSummary
  },
  data () {
    return {
      depositDetails: {},
      currentCheck: 0,
      currentJournalItem: 0,
      viewCheck: false,
      selectedProperty: {},
      selectedDate: moment().startOf('month')
    }
  },
  methods: {
    ...mapActions(['initializeStoreItem', 'fetchData']),
    getInfo (val) {
      console.log(val)
      this.fetchData({ newPage: 'BankDepositSlipDraftsFullInfo', params: { CompanyId: val.companyId, BankAccountCode: val.bankAccountCode, BankDepositSlipId: val.depositSlipId } }).then(() => {
        this.depositDetails = this.currentData('BankDepositSlipDraftsFullInfo')
        if (this.viewCheck === true) {
          this.fetchData({ newPage: 'AppliedPrepaymentsDistributionDetails', params: { Lease: val.payer, BankDepositId: val.depositId } }).then(() => {
            this.viewCheck = true
            this.currentCheck = val.depositId
            this.currentJournalItem = val.journalItemId
          })
        } else {
          this.viewCheck = true
          this.currentCheck = val.depositId
          this.currentJournalItem = val.journalItemId
        }
      }).catch(error => { console.log(error.response) })
    },
    setDate (val) {
      this.selectedDate = val
    },
    setProperty (val) {
      if (val.value !== null) {
        this.selectedProperty = val
      }
    },
    refreshGrid () {
      this.fetchData({newPage: 'UnapplyPrepayments', params: { Property: this.selectedProperty.value, AsOfDate: this.selectedDate }}).then(() => {
        this.viewCheck = false
      }).catch(error => { console.log(error.response) })
    }
  },
  computed: {
    ...mapGetters(['currentData'])
  }
}
</script>

<style lang="scss" scoped>
</style>
