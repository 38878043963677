<template>
  <v-dialog
    v-model="showDialog"
    persistent
    width="700">
    <v-card width="700" height=420 max-height=420 style="overflow: hidden;">
      <v-app-bar height="40" color='var(--v-resWhite-base)' flat>
        <span class="mt-4 resGray-darken4--text">Attachments</span>
        <v-spacer></v-spacer>
        <v-btn @click="cancelDialog" icon class="mt-4">
          <v-icon color="var(--v-resGray-darken4)">close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card flat>
        <v-app-bar flat color='var(--v-resWhite-base)'>
          <v-spacer></v-spacer>
          <round-button
            color='var(--v-resGray-darken3)'
            height='30px'
            width='140px'
            dark
            :depressed='true'
            buttonText='New Attachment'
            backgroundColor='var(--v-resGreen-lighten1)'
            borderColor='var(--v-resGray-lighten1)'
            buttonClass='mt-0'
            @click="newItem">
          </round-button>
        </v-app-bar>
        <div style="overflow: auto">
          <v-card height="250" flat>
            <data-table
              class='mx-4 mb-6'
              :headers="headers"
              :tableData="getData"
              :noData="'There are currently no attachments.'"
              @downloadFile="openDownloadDrawer"
              @unlockEditing='editItem'
              @itemDeleteRequested="deleteItem">
            </data-table>
          </v-card>
        </div>
      </v-card>
      <v-navigation-drawer
        v-model="drawer"
        temporary
        absolute
        :width="addDrawer ? '600' : '376'"
        right>
        <drag-and-drop
          v-if="addDrawer"
          @cancelUpload="newItem"
          @complete="completeWorkflow">
        </drag-and-drop>
        <v-app-bar flat height="40" color='var(--v-resWhite-base)' v-if="!addDrawer">
          <span class="caption mt-4 resGray-darken5--text">{{ editDrawer ? 'Edit Attachment' : downloadDrawer ? 'Preview Attachment' : 'Delete Attachment' }}</span>
          <v-spacer></v-spacer>
          <v-btn @click="editDrawer ? cancelEdit() : cancelDelete()" icon class="mt-4">
            <v-icon>close</v-icon>
          </v-btn>
        </v-app-bar>
        <v-card v-if="deleteDrawer" height="290" flat class="ma-4">
          <v-layout align-center justify-center column fill-height>
            <v-icon x-large>far fa-file</v-icon>
            <span class="file-name">{{ itemToDelete.documentUri }}</span>
            <span class="caption resGray-darken1--text">{{ itemToDelete.documentDate | dateFilter(itemToDelete.documentDate) }}</span>
            <p class="title mt-6 mb-1 text-center">Are you sure you want to</p>
            <p class="title text-center">delete this file?</p>
          </v-layout>
          <v-layout class="mt-4">
            <v-spacer></v-spacer>
            <round-button
              color='var(--v-resBlue-darken1)'
              height='30px'
              width='80px'
              dark
              :depressed='true'
              :outline='true'
              buttonText='Cancel'
              buttonTextColor='var(--v-resBlue-darken1)'
              borderColor='var(--v-resBlue-darken1)'
              buttonClass='mr-3'
              @click="cancelDelete">
            </round-button>
            <round-button
              color='var(--v-resGray-darken3)'
              height='30px'
              width='109px'
              dark
              :depressed='true'
              buttonText='Yes, Delete'
              :backgroundColor="'var(--v-resRed-base)'"
              @click="confirmDelete">
            </round-button>
          </v-layout>
        </v-card>
        <v-card v-if="editDrawer" height="290" flat class="ma-4">
          <v-layout align-center justify-center column fill-height>
            <v-icon x-large>far fa-file</v-icon>
            <span class="file-name">{{ itemToEdit.documentUri }}</span>
            <span class="caption resGray-darken1--text">{{ itemToEdit.documentDate | dateFilter(itemToEdit.documentDate) }}</span>
            <text-input
              :value="itemToEdit.documentTitle"
              class="mt-12"
              label='Description'
              rules="required|max:250"
              width="315px"
              :textCounter="250"
              @textInputUpdated="updateDocumentTitle">
            </text-input>
          </v-layout>
          <v-layout class="mt-4">
            <v-spacer></v-spacer>
            <round-button
              color='var(--v-resRed-base)'
              height='30px'
              width='80px'
              dark
              :depressed='true'
              :outline='true'
              buttonText='Cancel'
              buttonTextColor='var(--v-resRed-base)'
              borderColor='var(--v-resRed-base)'
              buttonClass='mr-3'
              @click="cancelEdit">
            </round-button>
            <round-button
              color='var(--v-resGray-darken3)'
              height='30px'
              width='80px'
              dark
              :depressed='true'
              buttonText='Save'
              :backgroundColor="'var(--v-resGreen-lighten1)'"
              @click="confirmEdit">
            </round-button>
          </v-layout>
        </v-card>
        <v-card v-if="downloadDrawer" height="290" flat class="ma-4">
          <v-layout align-center justify-center column fill-height>
            <v-img
              v-if="previewAvaiable"
              height="250"
              width="250"
              class="ma-0"
              :src="image">
            </v-img>
            <v-card
              flat
              width="250"
              height="250"
              color="var(--v-resGray-lighten3)"
              class="mb-1"
              v-else>
              <v-layout align-center justify-center row fill-height>
                <span class="caption resGray-darken5--text">No preview available</span>
              </v-layout>
            </v-card>
            <span class="caption">{{ itemToDownload.documentUri }}</span>
            <span class="caption resGray-darken1--text">{{ itemToDownload.documentDate | dateFilter(itemToDownload.documentDate) }}</span>
          </v-layout>
          <v-layout class="mt-4">
            <v-spacer></v-spacer>
            <round-button
              color='var(--v-resRed-base)'
              height='30px'
              width='80px'
              dark
              :depressed='true'
              :outline='true'
              buttonText='Cancel'
              buttonTextColor='var(--v-resRed-base)'
              borderColor='var(--v-resRed-base)'
              buttonClass='mr-3'
              @click="cancelEdit">
            </round-button>
            <round-button
              color='var(--v-resBlue-darken1)'
              height='30px'
              width='110px'
              dark
              :depressed='true'
              buttonText='Download'
              :backgroundColor="'var(--v-resBlue-darken1)'"
              @click="downloadFile">
            </round-button>
          </v-layout>
        </v-card>
      </v-navigation-drawer>
      <v-app-bar height="40" color='var(--v-resWhite-base)' flat>
        <v-spacer></v-spacer>
        <round-button
          color='var(--v-resBlue-darken1)'
          height='30px'
          width='80px'
          dark
          :depressed='true'
          buttonText='Close'
          :backgroundColor="'var(--v-resBlue-darken1)'"
          buttonClass='mt-4'
          @click="cancelDialog">
        </round-button>
      </v-app-bar>
    </v-card>
  </v-dialog>
</template>

<script>
import RoundButton from '../../BaseComponents/RoundButton'
import DataTable from '../../BaseComponents/Table'
import TextInput from '../../BaseComponents/TextInput'
import DragAndDrop from '../../BaseComponents/DragAndDrop'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import * as fileDownload from '../../../utility/fileDownloader'

export default {
  props: ['showDialog'],
  components: {
    RoundButton,
    DataTable,
    TextInput,
    DragAndDrop
  },
  data () {
    return {
      drawer: false,
      addDrawer: false,
      deleteDrawer: false,
      editDrawer: false,
      downloadDrawer: false,
      itemToDelete: {},
      itemToEdit: {},
      itemToDownload: {},
      image: {},
      previewAvaiable: false
    }
  },
  methods: {
    ...mapActions(['fetchData', 'changeData', 'deleteData']),
    cancelDialog () {
      this.$emit('cancel', true)
    },
    newItem () {
      this.drawer = !this.drawer
      this.addDrawer = !this.addDrawer
    },
    editItem (val) {
      this.itemToEdit = JSON.parse(JSON.stringify(val))
      this.drawer = !this.drawer
      this.editDrawer = true
      this.addDrawer = false
      this.downloadDrawer = false
    },
    cancelEdit () {
      this.itemToEdit = {}
      this.drawer = !this.drawer
      this.editDrawer = false
      this.addDrawer = false
      this.downloadDrawer = false
      this.previewAvaiable = false
    },
    confirmEdit () {
      let item = {}
      item.documentId = this.itemToEdit.documentId
      item.fileName = this.itemToEdit.documentUri
      item.title = this.itemToEdit.documentTitle
      item.applicationRole = this.itemToEdit.applicationRole
      item.notes = this.itemToEdit.notes
      this.changeData({ currentPage: 'Documents', newData: { documents: [item] } }).then(() => {
        this.fetchData({newPage: 'Documents', params: { ApplicationRole: 'LEAS', PrimaryKey: this.currentSelectedItem.lease }}).then(() => {
          this.drawer = !this.drawer
          this.addDrawer = false
          this.itemToEdit = {}
          this.editDrawer = false
          this.downloadDrawer = false
        })
      }).catch(error => { console.log(error.response) })
    },
    openDownloadDrawer (val) {
      this.itemToDownload = val
      this.downloadDrawer = true
      this.drawer = !this.drawer
      if (val.fileType.toLowerCase() === '.jpg' || val.fileType.toLowerCase() === '.jpeg') {
        this.previewAvaiable = true
        this.fetchData({newPage: 'DocumentsContent', params: { DocumentId: val.documentId }}).then(() => {
          this.image = 'data:image/jpeg;base64,' + this.currentData('DocumentsContent').content
        }).catch(error => { console.log(error.response) })
      }
    },
    downloadFile () {
      this.fetchData({newPage: 'DocumentsContent', params: { DocumentId: this.itemToDownload.documentId }}).then(() => {
        let document = this.currentData('DocumentsContent')
        let bufferArray = fileDownload.convertArrayBuffer(document.content)
        fileDownload.createDownloadFile(bufferArray, document.documentUri)
      }).catch(error => { console.log(error.response) })
    },
    cancelDelete () {
      this.drawer = !this.drawer
      this.deleteDrawer = false
      this.itemToDelete = {}
    },
    confirmDelete () {
      this.deleteData({ currentPage: 'Documents', removeItem: this.itemToDelete }).then(() => {
        this.drawer = !this.drawer
        this.deleteDrawer = false
        this.itemToDelete = {}
      }).catch(error => { console.log(error.response) })
    },
    deleteItem (val) {
      this.drawer = !this.drawer
      this.deleteDrawer = true
      this.itemToDelete = val
    },
    updateDocumentTitle (val) {
      this.itemToEdit.documentTitle = val
    },
    completeWorkflow (documents) {
      for (const document of documents) {
        document.applicationRole = this.currentSelectedItem.applicationRole
        document.primaryKey = this.currentSelectedItem.lease
        document.secondaryKey = ''
      }
      this.changeData({ currentPage: 'Documents', newData: { documents } }).then(() => {
        this.fetchData({newPage: 'Documents', params: { ApplicationRole: 'LEAS', PrimaryKey: this.currentSelectedItem.lease }}).then(() => {
          this.drawer = !this.drawer
          this.addDrawer = false
          this.itemToEdit = {}
          this.editDrawer = false
          this.downloadDrawer = false
        })
      }).catch(error => { console.log(error.response) })
    }
  },
  computed: {
    ...mapGetters(['currentSelectedItem', 'currentData', 'currentHeaders']),
    headers () {
      return this.currentHeaders('Documents')
    },
    getData () {
      return this.currentData('Documents')
    }
  },
  created () {
    this.fetchData({newPage: 'Documents', params: { ApplicationRole: 'LEAS', PrimaryKey: this.currentSelectedItem.lease }}).catch(error => { console.log(error.response) })
  },
  filters: {
    dateFilter (val) {
      return moment(val).format('L')
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .v-image__image {
    height: 250px;
  }

  .file-name {
    width: 100%;
    overflow-wrap: break-word;
    text-align: center;
    padding-left: 1rem;
    padding-right: 1rem;
  }
</style>
