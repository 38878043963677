<template>
    <v-btn-toggle
      mandatory
      v-model="selectedButton"
      @change="$emit('toggled', selectedButton)"
      class="button-group"
      :style="buttonGroupProps">
      <v-subheader
        v-if="label"
        class="px-0"
        :class="bigLabels ? 'subheading' : ''">
        {{ label }}
      </v-subheader>
      <v-btn
        v-for="button in buttons"
        depressed
        color="var(--v-resWhite-base)"
        :class="getButtonClasses(button.index)"
        :key="button.text"
        :disabled="button.disabled"
        active-class="v-btn--active button-group__round-button--active">
        <span>{{ button.textOverride || button.text }}</span>
      </v-btn>
    </v-btn-toggle>
</template>

<script>
export default {
  props: ['buttons', 'label', 'currentSelected', 'bigLabels', 'groupWidth'],
  data () {
    return {
      selectedButton: this.currentSelected !== null && this.currentSelected !== undefined ? this.currentSelected : 0
    }
  },
  methods: {
    getButtonClasses (index) {
      let classes = ['button-group__round-button mt-1 mb-1']
      if (index === 0) {
        classes.push('button-group__round-button-left')
      }
      if (index === this.buttons.length - 1) {
        classes.push('button-group__round-button-right')
      }
      return classes
    }
  },
  computed: {
    buttonGroupProps () {
      return {
        '--group-width': this.groupWidth,
        '--button-width': (100 * (1 / this.buttons.length)) + '%'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .button-group {
    display: inline-block;
    background: var(--v-resGray-lighten6) !important;
    box-shadow: none;
    -webkit-box-shadow: none;
    width: var(--group-width);
    &__round-button {
      text-transform: capitalize !important;
      min-height: 30px !important;
      max-height: 30px !important;
      height: 30px !important;
      border: none !important;
      font-weight: 400;
      width: var(--button-width);
      border-left: solid 1px var(--v-resGray-darken2) !important;
      &-left {
        border-top-left-radius: 6px !important;
        border-bottom-left-radius: 6px !important;
        border-top-right-radius: none !important;
        border-bottom-right-radius: none !important;
      }
      &-right {
        border-right: solid 1px var(--v-resGray-darken2) !important;
        border-top-left-radius: none !important;
        border-bottom-left-radius: none !important;
        border-top-right-radius: 6px !important;
        border-bottom-right-radius: 6px !important;
      }
      &.theme--light:not(.v-btn--active) {
        background-color: var(--v-resWhite-base) !important;
        border-top: solid 1px var(--v-resGray-darken2) !important;
        border-bottom: solid 1px var(--v-resGray-darken2) !important;
        color: var(--v-resGray-darken5) !important;
      }
      &.theme--dark {
        background-color: var(--v-resWhite-base) !important;
        color: var(--v-resGray-darken5)!important;
        border: solid 1px var(--v-resGray-lighten1) !important;
      }
      &--active{
        background-color: var(--v-resBlue-darken1) !important;
        color: var(--v-resWhite-base) !important;
        font-weight: 400;
      }
    }
    .v-subheader {
      display: block;
      height: 20px;
      font-size: 12px;
      font-weight: 400;
      color: rgba(0,0,0,.87);
      padding-top: 6px;
    }
  }
  .v-btn:not(.v-btn--active) {
    opacity: 1;
  }
</style>
