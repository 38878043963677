<template>
  <v-card-text class="pa-1">
    <h3>Select Charge Code Mapping Option.</h3>
    <v-card flat height="285" class="text-center mt-8">
      <h3>You can setup a new charge code mapping or </h3>
      <h3>copy mapping from an existing property.</h3>
      <div>
        <round-button
          color='var(--v-resGray-darken3)'
          height='40px'
          width='325px'
          dark
          :depressed='true'
          buttonText='New Charge Code Mapping'
          backgroundColor='var(--v-resBlue-darken1)'
          borderColor='var(--v-resGray-lighten1)'
          buttonClass='mt-4'
          @click="chargesNew">
        </round-button>
      </div>
      <div>
        <round-button
          color='var(--v-resGray-darken3)'
          height='40px'
          width='325px'
          dark
          :depressed='true'
          buttonText='Copy From Existing Property'
          backgroundColor='var(--v-resBlue-darken1)'
          borderColor='var(--v-resGray-lighten1)'
          buttonClass='mt-4'
          @click="chargesTemplate">
        </round-button>
      </div>
    </v-card>
  </v-card-text>
</template>

<script>
import { mapActions } from 'vuex'
import RoundButton from '../../BaseComponents/RoundButton'

export default {
  components: {
    RoundButton
  },
  data () {
    return {
    }
  },
  methods: {
    ...mapActions(['changePropertyStep']),
    chargesNew () {
      this.changePropertyStep('ChargesNew')
    },
    chargesTemplate () {
      this.changePropertyStep('ChargesTemplate')
    }
  }
}
</script>
