<template>
  <v-btn
    :outlined="outline"
    :color="color"
    :depressed="depressed"
    :text="flat"
    :class="[buttonClass, 'round-button']"
    :dark="dark"
    :style="buttonProps"
    :height="height"
    :min-height="minHeight"
    :max-height="maxHeight"
    :width="width"
    :min-width="minWidth"
    :max-width="maxWidth"
    :disabled="disabled"
    @click="sendClicked">
    <v-icon
      small
      v-if="icon"
      :class="buttonText ? 'mr-1' : ''">
      {{ iconText }}
    </v-icon>
    <span
      :class="[capitalize ? 'capitalize' : '', disabled ? 'text--white': '']">
      {{ buttonText }}
    </span>
  </v-btn>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  inheritAttrs: false,
  props: [
    'roundness',
    'outline',
    'color',
    'width',
    'minWidth',
    'maxWidth',
    'height',
    'minHeight',
    'maxHeight',
    'depressed',
    'flat',
    'buttonText',
    'buttonTextColor',
    'buttonClass',
    'icon',
    'iconText',
    'backgroundColor',
    'borderColor',
    'dark',
    'disabled',
    'capitalize',
    'fontSize'
  ],
  data () {
    return {
    }
  },
  methods: {
    sendClicked () {
      if (!this.currentLoading) {
        this.$emit('click')
      }
    }
  },
  computed: {
    ...mapGetters(['currentLoading']),
    buttonProps () {
      return {
        '--width': this.width,
        '--height': this.height,
        '--background-color': !this.disabled && !this.currentLoading ? this.backgroundColor : 'var(--v-resGray-lighten1)',
        '--border-color': !this.disabled && !this.currentLoading ? this.borderColor : 'var(--v-resGray-lighten1)',
        '--border-radius': this.roundness === undefined ? '10px' : this.roundness,
        '--text-color': this.buttonTextColor === undefined || this.disabled ? 'white' : this.buttonTextColor,
        '--font-size': this.fontSize === undefined ? '12px' : this.fontSize
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .round-button {
    height: var(--height);
    width: var(--width);
    background-color: var(--background-color) !important;
    border-color: var(--border-color) !important;
    border-radius: var(--border-radius);
    min-width: 0px;
    span {
      font-size: var(--font-size);
      color: var(--text-color) !important;
    }

    .capitalize {
      text-transform: capitalize !important;
    }
  }
</style>
