<template>
  <div>
    <v-card width="1000" height="90" class="tabs-card">
      <v-layout row>
          <v-layout ml-4 mt-2 column>
            <label class="caption resGray-darken1--text mb-2">Lease name</label>
            <span class="caption resGray-darken5--text font-weight-medium">{{ currentCheck.leaseName }}</span>
          </v-layout>
          <v-layout ml-4 mt-2 column>
            <label class="caption resGray-darken1--text mb-2">Check date</label>
            <span class="caption resGray-darken5--text font-weight-medium">{{ currentCheck.payerCheckDate | dateFilter(currentCheck.payerCheckDate) }}</span>
          </v-layout>
          <v-spacer></v-spacer>
          <v-layout ml-12 mt-2 column class="text-right">
            <label class="caption resGray-darken1--text mb-2">Refund total</label>
            <span class="subheading resGray-darken5--text font-weight-medium">{{ checkTotal }}</span>
          </v-layout>
          <v-layout mt-2 column class="text-right">
            <label class="caption resGray-darken1--text mb-2">Applied total</label>
            <span class="subheading resGray-darken5--text font-weight-medium">{{ appliedCheckTotal }}</span>
          </v-layout>
          <v-layout mr-6 mt-2 column class="text-right">
            <label class="caption resGray-darken1--text mb-2">Unapplied total</label>
            <span class="subheading font-weight-medium" :class="reconcileCheckColor">{{ unappliedCheckTotal }}</span>
          </v-layout>
      </v-layout>
    </v-card>
    <v-card flat width="1000">
      <data-table
        class="distribute-table"
        :headers="headers"
        :tableData="getData"
        @applyAmount="applyAmount"
        :noData="'No information to display.'">
      </data-table>
    </v-card>
  </div>
</template>

<script>
import DataTable from '../../../BaseComponents/Table'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import * as currency from '../../../../utility/currency'

export default {
  props: ['currentCheck', 'active'],
  components: {
    DataTable
  },
  data () {
    return {
    }
  },
  methods: {
    ...mapActions(['fetchDropdown', 'changeData', 'fetchData', 'addNew', 'cancelNew']),
    applyAmount (item) {
      var unapplied = currency.parseCurrency(this.unappliedCheckTotal)
      if (item.baseOpenAmount <= unapplied) {
        item.selectBasePaymentAmount = item.baseOpenAmount
      } else if (unapplied > 0) {
        item.selectBasePaymentAmount = unapplied
      }
    },
    getCurrentCharges () {
      return this.getData
    }
  },
  computed: {
    ...mapGetters(['currentDropdown', 'currentData', 'currentHeaders', 'currentRefundOpenCharges']),
    totalCheckReconciled () {
      if (this.appliedCheckTotal !== this.checkTotal) {
        this.$emit('reconciled', false)
        return false
      }
      this.$emit('reconciled', true)
      return true
    },
    appliedCheckTotal () {
      if (this.getData.length > 0) {
        let sum = Number(0)
        this.getData.forEach(function (charge) {
          sum += Number(charge.selectBasePaymentAmount)
          sum = currency.parseCurrency(sum) // remove excess decimal values
        })
        this.$emit('updateDistributed', sum)
        return currency.convertToCurrency(sum)
      }
      return currency.convertToCurrency(0)
    },
    unappliedCheckTotal () {
      let remaining = currency.parseCurrency(this.checkTotal) - currency.parseCurrency(this.appliedCheckTotal)
      let total = currency.convertToCurrency(remaining)
      return total
    },
    checkTotal () {
      if (this.currentCheck.baseAmount !== undefined) {
        return currency.convertToCurrency(this.currentCheck.baseAmount)
      }
      return currency.convertToCurrency(0)
    },
    reconcileCheckColor () {
      if (!this.totalCheckReconciled) {
        return 'resRed-base--text'
      } else {
        return 'resGreen-base--text'
      }
    },
    headers: {
      get () {
        return this.currentHeaders('LeaseOpenCharges')
      }
    },
    getData () {
      let items = []
      if (this.currentRefundOpenCharges.length > 0) {
        items = this.currentRefundOpenCharges
        items.forEach(function (item) {
          item.readonly = false
        })
      } else {
        items = this.currentData('LeaseOpenCharges')
        items.forEach(function (item) {
          item.readonly = false
          item.selectBasePaymentAmount = 0
        })
      }
      return items
    },
    completeDistribution () {
      return null
    }
  },
  created () {
    this.fetchData({ newPage: 'LeaseOpenCharges', params: { Lease: this.currentCheck.lease, CompanyId: this.currentCheck.property, BankAccountCode: this.currentCheck.bankAccount, BankDepositId: -1 } })
      .catch(error => { console.log(error.response) })
  },
  filters: {
    dateFilter (val) {
      return moment(val).format('L')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .checkbox__container {
  height: 30px;
  .v-input--selection-controls__input {
    margin: 0px;
  }
  .v-icon {
    font-size: 18px;
  }
}
.distribute-table {
  ::v-deep .v-datatable {
    thead th {
      padding: 0px 19px;
    }
    tbody td {
      padding: 0px 19px;
    }
  }
}

.tabs-card {
  border-top: 2px var(--v-resBlue-base) solid;
  border-right: 1px var(--v-resGray-lighten1) solid;
  border-left: 1px var(--v-resGray-lighten1) solid;
  border-bottom: 1px var(--v-resGray-lighten1) solid;
}

.contact-title--primary {
  font-style: italic;
}
</style>
