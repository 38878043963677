<template>
  <div>
    <v-card flat width="1000" height="90" class="tabs-card">
      <v-layout>
          <v-flex ml-4 mt-2 mr-12>
            <div class="caption resGray-darken1--text mb-2">Lease name</div>
            <div class="caption resGray-darken5--text font-weight-medium">{{ currentCheck.leaseName }}</div>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex ml-12 mt-2>
            <div class="caption resGray-darken1--text mb-2">Check date</div>
            <div class="caption resGray-darken5--text font-weight-medium">{{ currentCheck.payerCheckDate | dateFilter(currentCheck.payerCheckDate) }}</div>
          </v-flex>
          <v-flex ml-4 mt-2>
            <div class="caption resGray-darken1--text mb-2">Check number</div>
            <div class="caption resGray-darken5--text font-weight-medium">{{ currentCheck.payerCheckNumber }}</div>
          </v-flex>
          <v-flex mt-2 class="text-right">
            <div class="caption resGray-darken1--text mb-2">Check total</div>
            <div class="subheading resGray-darken5--text font-weight-medium">{{ checkTotal }}</div>
          </v-flex>
          <v-flex mt-2 mr-6 class="text-right">
            <div class="caption resGray-darken1--text mb-2">Applied total</div>
            <div
              class="subheading font-weight-medium"
              :class="currentCheck.transactionStatus === 25 ? 'resRed-base--text' : 'resGray-darken5--text'">
              {{ currentCheck.transactionStatus === 25 ? "VOIDED" : appliedCheckTotal }}
            </div>
          </v-flex>
      </v-layout>
    </v-card>
    <v-card flat width="1000">
      <data-table
        :headers="headers"
        :tableData="getData"
        :noData="'No information to display.'">
      </data-table>
    </v-card>
  </div>
</template>

<script>
import DataTable from '../../../BaseComponents/Table'
import { mapGetters, mapActions } from 'vuex'
import * as currency from '../../../../utility/currency'
import moment from 'moment'

export default {
  props: ['depositDetails', 'depositId'],
  components: {
    DataTable
  },
  data () {
    return {
    }
  },
  methods: {
    ...mapActions(['fetchDropdown', 'changeData', 'fetchData', 'addNew', 'cancelNew'])
  },
  computed: {
    ...mapGetters(['currentDropdown', 'currentData', 'currentHeaders']),
    appliedCheckTotal () {
      if (this.getData.length > 0) {
        let sum = Number(0)
        this.getData.forEach(function (charge) {
          sum += Number(charge.basePaymentAmount)
        })
        return currency.convertToCurrency(sum)
      }
      return currency.convertToCurrency(0)
    },
    checkTotal () {
      if (this.currentCheck.baseAmount !== undefined) {
        return currency.convertToCurrency(this.currentCheck.baseAmount)
      }
      return currency.convertToCurrency(0)
    },
    headers: {
      get () {
        if (this.currentCheck.transactionStatus !== 25) {
          return this.currentHeaders('BankDepositDetailsDistribution')
        }
        return this.currentHeaders('LeasePayments')
      }
    },
    getData: {
      get () {
        if (this.currentCheck.transactionStatus !== 25) {
          let items = this.currentData('BankDepositDetailsDistribution')
          return items
        } else {
          let items = this.currentData('LeasePayments').filter(x => x.basePaymentAmount < 0)
          return items
        }
      }
    },
    currentCheck () {
      return this.depositDetails.bankDepositDetails.find(x => x.depositId === this.depositId)
    }
  },
  created () {
    this.fetchData({ newPage: 'LeasePayments', params: { Lease: this.currentCheck.payer, BankDepositId: this.currentCheck.depositId } }).catch(error => { console.log(error.response) })
    this.fetchData({ newPage: 'BankDepositDetailsDistribution', params: { Lease: this.currentCheck.payer, BankDepositId: this.currentCheck.depositId } })
      .catch(error => { console.log(error.response) })
  },
  filters: {
    dateFilter (val) {
      return moment(val).format('L')
    }
  }
}
</script>

<style lang="scss" scoped>
  .tabs-card {
    border-radius: 0;
    border-top: 2px var(--v-resBlue-base) solid;
    border-right: 1px var(--v-resGray-lighten1) solid;
    border-left: 1px var(--v-resGray-lighten1) solid;
    border-bottom: 1px var(--v-resGray-lighten1) solid;
  }
</style>
