<template>
  <div>
      <v-layout row>
        <v-btn :ripple="false" flat @click="returnToPayments" color="var(--v-resWhite-base)" class="pa-0 ml-0 mb-4">
          <v-btn small color="var(--v-resBlue-darken1)" icon><v-icon size="18" dark>arrow_back</v-icon></v-btn>
          <span class="text-none ml-0 back-button-text resBlue-darken1--text">Back to Results</span>
        </v-btn>
      </v-layout>
      <v-card width=1000 height=200>
        <v-flex xs12>
          <v-layout row>
            <v-flex xs10>
              <v-flex mt-4 xs12>
                <span class="title ml-6">Refund Details</span>
              </v-flex>
              <v-flex mt-4 xs8>
                <v-layout row>
                  <v-layout ml-6 column>
                    <label class="caption resGray-darken1--text mb-1">Deposit date</label>
                    <span class="caption resGray-darken5--text font-weight-medium">{{ currentCheck.depositDate | dateFilter(currentCheck.depositDate) }}</span>
                  </v-layout>
                  <v-layout column>
                    <label class="caption resGray-darken1--text mb-1">Property</label>
                    <span class="caption resGray-darken5--text font-weight-medium">{{ currentCheck.propertyName }}</span>
                  </v-layout>
                  <v-layout column>
                    <label class="caption resGray-darken1--text mb-1">Cash Account</label>
                    <span class="caption resGray-darken5--text font-weight-medium">{{ currentCheck.bankAccount }} - {{ currentCheck.bankAccountName }}</span>
                  </v-layout>
                  <v-layout column>
                    <label class="caption resGray-darken1--text mb-1">Description</label>
                    <span class="caption resGray-darken5--text font-weight-medium">{{ currentCheck.reference }}</span>
                  </v-layout>
                </v-layout>
              </v-flex>
            </v-flex>
            <v-flex xs2>
              <v-layout align-end justify-space-between column fill-height>
                <span class="caption mr-6 mt-4 resGray-darken1--text">Charge total</span>
                <span class="title mb-2 mr-6 resGray-darken1--text">{{ chargeTotal }}</span>
                <span class="caption resGray-darken1--text mr-6 mt-4">Distribution total</span>
                <span class="title mb-2 mr-6 resGray-darken1--text">{{ distributedTotal }}</span>
                <span class="caption resGray-darken1--text mr-6 mt-4">Remaining Total</span>
                <span class="title mr-6" :class="reconcileColor">{{ remainingTotal }}</span>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-card>
    </div>
</template>

<script>
import * as currency from '../../../../utility/currency'
import moment from 'moment'

export default {
  props: ['currentCheck', 'distributedTotal'],
  data () {
    return {
      editedItem: {},
      appliedTotal: Number(0),
      currentCheckReconciled: false,
      deleteConfirm: false
    }
  },
  methods: {
    returnToPayments () {
      this.$emit('returnToPayments')
    }
  },
  computed: {
    chargeTotal () {
      return currency.convertToCurrency(this.currentCheck.baseAmount)
    },
    remainingTotal () {
      return currency.convertToCurrency(currency.parseCurrency(this.chargeTotal) - currency.parseCurrency(this.distributedTotal))
    },
    reconcileColor () {
      return 'resGray-darken1--text'
    }
  },
  filters: {
    dateFilter (val) {
      return moment(val).format('L')
    }
  }
}
</script>
