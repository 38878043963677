<template>
  <base-dialog
    :showDialog="showDialog"
    :title="'Edit Lease details'"
    :maxWidth="500"
    @cancel="cancelEditLease"
    @save="saveLease">
    <v-row no-gutters class="mt-2">
      <v-col cols="12">
        <text-input
          class="ml-6"
          label="Lease Name"
          width="320px"
          :value="editedLease.name"
          @textInputUpdated="updateLeaseName">
        </text-input>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-5">
      <v-col cols="12">
        <text-input
          class="ml-6"
          label="DBA"
          width="320px"
          :value="editedLease.dbaName"
          @textInputUpdated="updateDbaName">
        </text-input>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-5">
      <v-col cols="12">
        <drop-down
          :selectedValue="{ text: editedLease.leaseCategoryName }"
          :items="'Lease Types'"
          :label="'Category'"
          :multiple='false'
          class='mx-6'
          width="180px"
          @itemSelected="selectLeaseCategory">
        </drop-down>
        <drop-down
          :selectedValue="{ text: editedLease.statementPreferenceName }"
          :customList="statementPreferenceList"
          :label="'Statement Preference'"
          :multiple='false'
          width="180px"
          @itemSelected="selectStatementPreference">
        </drop-down>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-5">
      <v-col cols="4">
        <span class="caption ml-6 pt-0-plus">Month to Month</span>
        <v-tooltip top :disabled="!checkMonthToMonthStatus">
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-switch
                class="ml-6 my-0 pa-0 toggle"
                color="var(--v-resGreen-base)"
                :disabled="checkMonthToMonthStatus"
                v-model="editedLease.isMonthToMonth"
                @change="updateMonthToMonthStatus">
              </v-switch>
            </span>
          </template>
          <span>Month to Month may only be enabled within 2 months of the lease end date.</span>
        </v-tooltip>
      </v-col>
      <v-col cols="8">
        <date-picker
          v-if="!editedLease.isMonthToMonth"
          class="pt-0-plus pl-2-plus"
          :label="'Month to Month End Date'"
          :defaultNull="true"
          :initialDate="editedLease.cancelDate"
          @dateSelected="updateMonthToMonthEndDate">
        </date-picker>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <date-picker
          class="ml-6"
          :label="'Start Date'"
          :defaultNull="true"
          :initialDate="editedLease.startDate"
          @dateSelected="updateStartDate">
        </date-picker>
        <date-picker
          class="ml-6"
          :label="'Expire Date'"
          :defaultNull="true"
          :initialDate="editedLease.endDate"
          @dateSelected="updateEndDate">
        </date-picker>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-5">
      <v-col cols="12">
        <date-picker
          class="ml-6"
          :label="'Lease Date'"
          :defaultNull="true"
          :initialDate="editedLease.leaseDate"
          @dateSelected="updateLeaseDate">
        </date-picker>
        <date-picker
          class="ml-6"
          :label="'Approve Date'"
          :defaultNull="true"
          :initialDate="editedLease.approvedDate"
          @dateSelected="updateApproveDate">
        </date-picker>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-5 mx-6">
      <v-col cols="12">
        <text-area label="Notes"
          :value="editedLease.notes"
          :height="75"
          @textAreaUpdated="updateNotes">
        </text-area>
      </v-col>
    </v-row>
  </base-dialog>
</template>

<script>
import TextInput from '../../BaseComponents/TextInput'
import DatePicker from '../../BaseComponents/DatePicker'
import TextArea from '../../BaseComponents/TextArea'
import DropDown from '../../BaseComponents/DropDown'
import BaseDialog from '../../BaseComponents/BaseDialog'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    TextInput,
    TextArea,
    DatePicker,
    BaseDialog,
    DropDown
  },
  props: ['editedLease', 'showDialog'],
  data () {
    return {
      statementPreferenceList: [
        { text: 'Mailing', value: 'M' },
        { text: 'Email', value: 'E' },
        { text: 'Email and Mailing', value: 'B' }
      ]
    }
  },
  methods: {
    ...mapActions(['fetchData', 'changeData', 'changeSelectedItem']),
    updateLeaseName (val) {
      this.editedLease.name = val
    },
    updateDbaName (val) {
      this.editedLease.dbaName = val
    },
    selectLeaseCategory (val) {
      this.editedLease.leaseCategory = val.value
      this.editedLease.leaseCategoryName = val.text
    },
    selectStatementPreference (val) {
      this.editedLease.statementPreference = val.value
      this.editedLease.statementPreferenceName = val.text
    },
    updateStartDate (val) {
      this.editedLease.startDate = val
    },
    updateEndDate (val) {
      this.editedLease.endDate = val
    },
    updateLeaseDate (val) {
      this.editedLease.leaseDate = val
    },
    updateApproveDate (val) {
      this.editedLease.approvedDate = val
    },
    updateNotes (val) {
      this.editedLease.notes = val
    },
    saveLease () {
      this.changeData({ currentPage: 'Leases', newData: this.editedLease }).then(() => {
        if (moment(moment().format()).isAfter(this.editedLease.endDate)) {
          this.fetchData({ newPage: 'Leases', params: { leaseType: 1 } }).then(() => {
            let lease = this.getCurrentLeases().find(x => x.lease === this.editedLease.lease)
            this.changeSelectedItem(lease)
            this.$emit('save')
          }).catch(error => { console.log(error.response) })
        } else {
          this.fetchData({ newPage: 'Leases' }).then(() => {
            let lease = this.getCurrentLeases().find(x => x.lease === this.editedLease.lease)
            this.changeSelectedItem(lease)
            this.$emit('save')
          }).catch(error => { console.log(error.response) })
        }
      })
    },
    cancelEditLease () {
      this.$emit('cancel', true)
    },
    getCurrentLeases () {
      return this.currentData('Leases')
    },
    updateMonthToMonthEndDate (val) {
      this.editedLease.cancelDate = val
    },
    updateMonthToMonthStatus (val) {
      this.editedLease.isMonthToMonth = val
    }
  },
  computed: {
    ...mapGetters(['currentData']),
    checkMonthToMonthStatus () {
      return !moment(this.editedLease.endDate).subtract(2, 'M').isSameOrBefore(moment().format())
    }
  }
}
</script>
