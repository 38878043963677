<template>
  <div>
    <v-layout align-center justify-space-between column fill-height>
      <div>
        <div>
          <v-btn :ripple="false" text @click="returnVoid" color="var(--v-resWhite-base)" class="ml-0 pl-0">
            <v-btn small color="var(--v-resBlue-darken1)" icon><v-icon size="18" dark>arrow_back</v-icon></v-btn>
            <span class="text-none ml-0 back-button-text resBlue-darken1--text">Back to Results</span>
          </v-btn>
        </div>
        <v-card width="1000" height="212" class="mt-4">
          <v-flex>
            <v-layout>
              <v-flex>
                <v-flex mt-4>
                  <span class="title ml-6">Deposit Details</span>
                </v-flex>
                <v-flex mt-4>
                  <v-layout>
                    <v-flex ml-6>
                      <div class="caption resGray-darken1--text mb-1">Deposit date</div>
                      <div class="caption resGray-darken5--text font-weight-medium">{{ depositDetails.depositDate | dateFilter(depositDetails.depositDate) }}</div>
                    </v-flex>
                    <v-flex>
                      <div class="caption resGray-darken1--text mb-1">Property</div>
                      <div class="caption resGray-darken5--text font-weight-medium">{{ depositDetails.bankDepositDetails[0].propertyName }}</div>
                    </v-flex>
                    <v-flex>
                      <div class="caption resGray-darken1--text mb-1">Cash Account</div>
                      <div class="caption resGray-darken5--text font-weight-medium">{{ depositDetails.bankAccountCode }} - {{ depositDetails.bankAccountName }}</div>
                    </v-flex>
                  </v-layout>
                  <v-flex ml-6 mt-6>
                    <div class="caption resGray-darken1--text mb-1">Description</div>
                    <div class="caption resGray-darken5--text font-weight-medium">{{ depositDetails.reference }}</div>
                  </v-flex>
                </v-flex>
              </v-flex>
              <v-flex>
                <v-layout align-end column fill-height>
                  <span class="caption mr-6 mt-4 mb-2 resGray-darken1--text">Deposit slip total</span>
                  <span class="title mb-2 mr-6">{{ depositTotal }}</span>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-card>
      </div>
      <div>
        <v-flex mt-6 mb-4>
          <span class="title">Check Details</span>
        </v-flex>
        <distribution-tab
          :depositId="currentCheck"
          @reconciled="checkReconciled"
          :depositDetails="depositDetails">
        </distribution-tab>
        <v-layout>
          <v-spacer></v-spacer>
          <round-button
            v-if="canVoid"
            height='30px'
            buttonClass='mt-4 ml-4 mr-0'
            width='100px'
            :depressed='true'
            :flat='true'
            :buttonTextColor="'var(--v-resWhite-base)'"
            :backgroundColor="'var(--v-resRed-base)'"
            :buttonText="'Void Check'"
            @click="openDeleteDialog">
          </round-button>
        </v-layout>
      </div>
    </v-layout>
    <!-- Dialogs -->
    <delete-dialog
      :showDialog="deleteConfirm"
      v-if="deleteConfirm"
      :title="'Are you sure?'"
      confirmButton="Yes, void check"
      confirmButtonWidth="108px"
      @confirmDelete="confirmDialog"
      message="Please confirm that you would like to void this check. Doing so will reverse all charges this check is applied to."
      @cancelDelete="cancelDialog">
      <v-flex>
        <v-layout>
          <div class="fees-reminder">
            <span>If this is an NSF check, be sure to post NSF fees accordingly in the Adjusting Charges module.</span>
          </div>
        </v-layout>
        <v-layout ml-6>
          <v-flex>
            <div class="caption resGray-darken1--text mb-1">Check date</div>
            <div class="caption">{{ currentCheckToVoid.payerCheckDate }}</div>
          </v-flex>
          <v-flex>
            <div class="caption resGray-darken1--text mb-1">Check number</div>
            <div class="caption">{{ currentCheckToVoid.payerCheckNumber }}</div>
          </v-flex>
          <v-flex mr-6>
            <div class="caption resGray-darken1--text mb-1 text-right">Check total</div>
            <div class="caption text-right">{{ currentCheckToVoid.baseAmount }}</div>
          </v-flex>
        </v-layout>
        <v-layout mt-6>
          <date-picker
            label="Void Date"
            :minDate="minVoidDate"
            :initialDate="voidDate"
            @dateSelected="setVoidDate">
          </date-picker>
          <v-checkbox
            height="18"
            :ripple="false"
            v-model="nonSufficientFunds"
            class="checkbox-label shrink ml-6"
            color="var(--v-resBlue-darken1)"
            label="This is an NSF check">
          </v-checkbox>
        </v-layout>
      </v-flex>
    </delete-dialog>
  </div>
</template>

<script>
import DistributionTab from '../VoidNsfComponents/DistributionTab'
import RoundButton from '../../../BaseComponents/RoundButton'
import DatePicker from '../../../BaseComponents/DatePicker'
import DeleteDialog from '../../../BaseComponents/DeleteDialog'
import { mapGetters, mapActions } from 'vuex'
import * as currency from '../../../../utility/currency'
import moment from 'moment'

export default {
  props: ['depositDetails', 'currentCheck'],
  components: {
    RoundButton,
    DatePicker,
    DeleteDialog,
    DistributionTab
  },
  data () {
    return {
      editedItem: {},
      appliedTotal: Number(0),
      currentCheckReconciled: false,
      deleteConfirm: false,
      currentCheckToVoid: this.depositDetails.bankDepositDetails.find(x => x.depositId === this.currentCheck),
      nonSufficientFunds: false,
      voidDate: moment().format('L')
    }
  },
  methods: {
    ...mapActions(['fetchDropdown', 'changeData', 'fetchData', 'initializeStoreItem']),
    checkReconciled (val) {
      this.currentCheckReconciled = val
    },
    setVoidDate (val) {
      this.voidDate = val
    },
    openDeleteDialog () {
      this.currentCheckToVoid.payerCheckDate = moment(this.currentCheckToVoid.payerCheckDate).format('L')
      this.currentCheckToVoid.baseAmount = currency.convertToCurrency(this.currentCheckToVoid.baseAmount)
      this.deleteConfirm = true
    },
    cancelDialog () {
      this.deleteConfirm = false
    },
    confirmDialog () {
      this.changeData({ currentPage: 'BankDepositDetailsVoid',
        newData: {
          CompanyId: this.currentCheckToVoid.companyId,
          BankAccountCode: this.currentCheckToVoid.bankAccountCode,
          DepositSlipId: this.currentCheckToVoid.depositSlipId,
          BankDepositId: this.currentCheckToVoid.depositId,
          Payer: this.currentCheckToVoid.payer,
          PayerType: this.currentCheckToVoid.payerType,
          PayerCheckNumber: this.currentCheckToVoid.payerCheckNumber,
          PayerCheckDate: this.currentCheckToVoid.payerCheckDate,
          VoidDate: this.voidDate,
          NonSufficientFunds: this.nonSufficientFunds
        } }).then(() => {
        this.$emit('refreshInfo', this.currentCheckToVoid)
        this.deleteConfirm = false
      }).catch(error => { console.log(error.response) })
    },
    returnVoid () {
      this.$emit('returnVoid')
    }
  },
  computed: {
    ...mapGetters(['currentDropdown', 'currentData', 'currentHeaders']),
    canVoid () {
      let item = this.depositDetails.bankDepositDetails.find(x => x.depositId === this.currentCheck)
      return item.transactionStatus !== 25 && item.bankDepositType === 1
    },
    depositTotal () {
      if (this.depositDetails.bankDepositDetails.length > 0) {
        let sum = Number(0)
        this.depositDetails.bankDepositDetails.forEach(function (charge) {
          sum += currency.parseCurrency(charge.baseAmount)
        })
        return currency.convertToCurrency(sum)
      }
      return currency.convertToCurrency(0)
    },
    minVoidDate () {
      if (this.currentCheckToVoid !== null) {
        var checkDate = new Date(this.currentCheckToVoid.payerCheckDate)
        var depositDate = new Date(this.currentCheckToVoid.depositDate)
        if (checkDate > depositDate) {
          return checkDate.toISOString().substr(0, 10)
        } else {
          return depositDate.toISOString().substr(0, 10)
        }
      }
      return null
    }
  },
  filters: {
    dateFilter (val) {
      return moment(val).format('L')
    }
  }
}
</script>

<style lang="scss" scoped>
  .fees-reminder {
    margin: 8px 0px 8px 0px;
    font-weight: bold;
  }

  .checkbox-label {
    height: 30px;
    ::v-deep label {
      color: var(--v-resBlack-base);
      font-size: 14px;
    }
  }
</style>
