<template>
  <div>
    <v-card width="1000" height="90" class="tabs-card">
      <v-layout>
          <v-flex ml-4 mt-2>
            <div class="caption resGray-darken1--text mb-2">Lease name</div>
            <div class="caption resGray-darken5--text font-weight-medium">{{ currentCheck.leaseName }}</div>
          </v-flex>
          <v-flex ml-4 mt-2>
            <div class="caption resGray-darken1--text mb-2">Check date</div>
            <div class="caption resGray-darken5--text font-weight-medium">{{ currentCheck.payerCheckDate | dateFilter(currentCheck.payerCheckDate) }}</div>
          </v-flex>
          <v-flex ml-4 mr-12 mt-2>
            <div class="caption resGray-darken1--text mb-2">Check number</div>
            <div class="caption resGray-darken5--text font-weight-medium">{{ currentCheck.payerCheckNumber }}</div>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex ml-12 mt-2 class="text-right">
            <div class="caption resGray-darken1--text mb-2">Prepayment total</div>
            <div class="subheading resGray-darken5--text font-weight-medium">{{ checkTotal }}</div>
          </v-flex>
          <v-flex mt-2 class="text-right">
            <div class="caption resGray-darken1--text mb-2">Applied total</div>
            <div class="subheading resGray-darken5--text font-weight-medium">{{ appliedCheckTotal }}</div>
          </v-flex>
          <v-flex mr-6 mt-2 class="text-right">
            <div class="caption resGray-darken1--text mb-2">Unapplied total</div>
            <div class="subheading font-weight-medium" :class="reconcileCheckColor">{{ unappliedCheckTotal }}</div>
          </v-flex>
      </v-layout>
    </v-card>
    <v-card flat width="1000">
      <data-table
        class="distribute-table"
        :headers="headers"
        :tableData="getDataNoCredits"
        @applyAmount="applyAmount"
        :noData="'No information to display.'">
      </data-table>
    </v-card>
  </div>
</template>

<script>
import DataTable from '../../../BaseComponents/Table'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import * as currency from '../../../../utility/currency'

export default {
  props: ['depositDetails', 'applicationDate'],
  components: {
    DataTable
  },
  data () {
    return {
    }
  },
  methods: {
    ...mapActions(['fetchDropdown', 'changeData', 'fetchData', 'addNew', 'cancelNew', 'initializeStoreItem']),
    applyAmount (item) {
      var unapplied = currency.parseCurrency(this.unappliedCheckTotal)
      if (item.baseOpenAmount <= unapplied) {
        item.selectBasePaymentAmount = item.baseOpenAmount
      } else if (unapplied > 0) {
        item.selectBasePaymentAmount = unapplied
      }
    },
    save () {
      if (currency.parseCurrency(this.unappliedCheckTotal) >= 0) {
        let prepayData = this.getData
        for (var i = 0; i < prepayData.length; i++) {
          if (!prepayData[i].selectBasePaymentAmount) {
            prepayData[i].selectBasePaymentAmount = 0
          }
        }
        let newPrepay = {}
        newPrepay.selectBasePaymentAmount = currency.parseCurrency(this.appliedCheckTotal) * -1
        newPrepay.baseAmount = currency.parseCurrency(this.appliedCheckTotal) * -1
        newPrepay.baseOpenAmount = currency.parseCurrency(this.appliedCheckTotal) * -1
        newPrepay.chargeCode = this.currentCheck.chargeCode
        newPrepay.leaseCharge = this.currentCheck.leaseCharge
        newPrepay.chargeItem = this.currentCheck.chargeItem
        newPrepay.lease = this.currentCheck.lease
        prepayData.unshift(newPrepay)
        this.changeData(
          {
            currentPage: 'LeaseOpenCharges',
            newData:
            {
              CompanyId: this.currentCheck.property,
              BankAccountCode: this.currentCheck.bankAccount,
              BankDepositSlipId: this.currentCheck.depositSlipId,
              Lease: this.currentCheck.lease,
              PayerCheckNumber: this.currentCheck.payerCheckNumber,
              PayerCheckDate: this.currentCheck.payerCheckDate,
              ApplyPrepay: true,
              ApplicationDate: this.applicationDate,
              PaymentDetails: prepayData
            }
          }).then(() => {
          this.fetchData({ newPage: 'ApplyPrepayments', params: { Property: this.currentCheck.property } })
          if (currency.parseCurrency(this.unappliedCheckTotal) !== 0) {
            this.fetchData({ newPage: 'LeaseOpenCharges', params: { Lease: this.currentCheck.lease, CompanyId: this.currentCheck.property, BankAccountCode: this.currentCheck.bankAccount, BankDepositId: this.currentCheck.bankDepositId } })
          }
        }).catch(error => { console.log(error.response) })
      }
    }
  },
  computed: {
    ...mapGetters(['currentDropdown', 'currentData', 'currentHeaders']),
    totalCheckReconciled () {
      let imbalanced = this.getData.filter(x => {
        if (Math.abs(x.selectBasePaymentAmount) > Math.abs(x.baseAmount)) {
          return true
        } else if (x.baseAmount > 0 && x.selectBasePaymentAmount < 0) {
          return true
        } else if (x.baseAmount < 0 && x.selectBasePaymentAmount > 0) {
          return true
        }
        return false
      })
      if (imbalanced.length === 0) {
        this.$emit('reconciled', false)
        return false
      }
      this.$emit('reconciled', true)
      return true
    },
    appliedCheckTotal () {
      if (this.getData.length > 0) {
        let sum = Number(0)
        this.getData.forEach(function (charge) {
          sum += Number(charge.selectBasePaymentAmount)
          sum = currency.parseCurrency(sum) // remove excess decimal values
        })
        return currency.convertToCurrency(sum)
      }
      return currency.convertToCurrency(0)
    },
    unappliedCheckTotal () {
      let remaining = currency.parseCurrency(this.checkTotal) - currency.parseCurrency(this.appliedCheckTotal)
      let total = currency.convertToCurrency(remaining)
      return total
    },
    checkTotal () {
      if (this.currentCheck.baseAppliedAmount !== undefined) {
        return currency.convertToCurrency(this.currentCheck.baseAppliedAmount)
      }
      return currency.convertToCurrency(0)
    },
    reconcileCheckColor () {
      if (!this.totalCheckReconciled) {
        return 'resRed-base--text'
      } else {
        return 'resGreen-base--text'
      }
    },
    currentCheck () {
      return this.depositDetails
    },
    headers: {
      get () {
        return this.currentHeaders('LeaseOpenCharges')
      }
    },
    getData () {
      let items = this.currentData('LeaseOpenCharges')
      items.forEach(function (item) {
        item.readonly = false
        item.selectBasePaymentAmount = 0
      })
      return items
    },
    getDataNoCredits () {
      return this.getData.filter((item) => { return item.baseAmount >= 0 })
    },
    completeDistribution () {
      return null
    }
  },
  created () {
    this.initializeStoreItem('LeaseOpenCharges')
    this.fetchData({ newPage: 'LeaseOpenCharges', params: { Lease: this.currentCheck.lease, CompanyId: this.currentCheck.property, BankAccountCode: this.currentCheck.bankAccount, BankDepositId: this.currentCheck.bankDepositId } })
      .catch(error => { console.log(error.response) })
  },
  filters: {
    dateFilter (val) {
      return moment(val).format('L')
    }
  }
}
</script>

<style lang="scss" scoped>
  .distribute-table {
    ::v-deep thead th {
      padding: 0px 10px !important;
    }
    ::v-deep tbody td {
      padding: 0px 10px !important;
    }
  }

  .tabs-card {
    border-top: 2px var(--v-resBlue-base) solid;
    border-right: 1px var(--v-resGray-lighten1) solid;
    border-left: 1px var(--v-resGray-lighten1) solid;
    border-bottom: 1px var(--v-resGray-lighten1) solid;
    border-radius: 0;
  }

  .contact-title--primary {
    font-style: italic;
  }
</style>
