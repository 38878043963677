/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ppt': {
    width: 16,
    height: 16,
    viewBox: '0 0 56 56',
    data: '<path pid="0" d="M36.985 0H7.963C7.155 0 6.5.655 6.5 1.926V55c0 .345.655 1 1.463 1h40.074c.808 0 1.463-.655 1.463-1V12.978c0-.696-.093-.92-.257-1.085L37.607.257A.884.884 0 0 0 36.985 0z" fill="#e9e9e0"/><path pid="1" fill="#d9d7ca" d="M37.5.151V12h11.849z"/><path pid="2" d="M48.037 56H7.963A1.463 1.463 0 0 1 6.5 54.537V39h43v15.537c0 .808-.655 1.463-1.463 1.463z" fill="#f6712e"/><g fill="#fff"><path pid="3" d="M17.581 53H15.94V42.924h2.898c.428 0 .852.068 1.271.205.419.137.795.342 1.128.615.333.273.602.604.807.991s.308.822.308 1.306c0 .511-.087.973-.26 1.388a2.9 2.9 0 0 1-.725 1.046c-.31.282-.684.501-1.121.656s-.921.232-1.449.232H17.58V53zm0-8.832v3.992h1.504c.2 0 .398-.034.595-.103.196-.068.376-.18.54-.335s.296-.371.396-.649c.1-.278.15-.622.15-1.032 0-.164-.023-.354-.068-.567a1.637 1.637 0 0 0-.28-.615 1.657 1.657 0 0 0-.595-.492c-.255-.132-.593-.198-1.012-.198h-1.23zM25.853 53h-1.641V42.924h2.898c.428 0 .852.068 1.271.205.419.137.795.342 1.128.615.333.273.602.604.807.991s.308.822.308 1.306c0 .511-.087.973-.26 1.388a2.9 2.9 0 0 1-.725 1.046c-.31.282-.684.501-1.121.656s-.921.232-1.449.232h-1.217V53zm0-8.832v3.992h1.504c.2 0 .398-.034.595-.103.196-.068.376-.18.54-.335s.296-.371.396-.649c.1-.278.15-.622.15-1.032 0-.164-.023-.354-.068-.567a1.637 1.637 0 0 0-.28-.615 1.657 1.657 0 0 0-.595-.492c-.255-.132-.593-.198-1.012-.198h-1.23zM39.606 42.924v1.121h-3.008V53h-1.654v-8.955h-3.008v-1.121h7.67z"/></g><path pid="4" d="M39.5 30h-24V14h24v16zm-22-2h20V16h-20v12z" fill="#c8bdb8"/><path pid="5" d="M20.499 35a1 1 0 0 1-.857-1.515l3-5a1 1 0 1 1 1.715 1.029l-3 5a.997.997 0 0 1-.858.486zM34.501 35a.998.998 0 0 1-.858-.485l-3-5a1 1 0 1 1 1.715-1.029l3 5A1 1 0 0 1 34.501 35zM27.5 16a1 1 0 0 1-1-1v-3a1 1 0 1 1 2 0v3a1 1 0 0 1-1 1z" fill="#c8bdb8"/><path pid="6" fill="#d3ccc9" d="M17.5 16h20v12h-20z"/>'
  }
})
