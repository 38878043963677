<template>
  <base-dialog
    :showDialog="showDialog"
    :title="dialogTitle"
    :titleClass="'dark-header'"
    :iconColor="'var(--v-resWhite-base)'"
    @cancel="cancel"
    @save="save">
    <v-card-text class="pl-6 py-0">
      <text-input v-model="newReport.report_Name"
        label="Report Name"
        width="150px">
      </text-input>
    </v-card-text>
    <v-card-text class="pl-6 py-0">
      <text-input v-model="newReport.report_Description"
        label="Name"
        width="250px">
      </text-input>
    </v-card-text>

    <v-card-text class="pl-6 py-0">
      <text-input v-model="newReport.category"
        label="Category"
        width="250px">
      </text-input>
    </v-card-text>

  </base-dialog>
</template>

<script>
import BaseDialog from '../../BaseComponents/BaseDialog'
import TextInput from '../../BaseComponents/TextInput'
import { mapActions } from 'vuex'

export default {
  components: {
    BaseDialog,
    TextInput
  },
  props: ['showDialog', 'category', 'report'],
  data () {
    return {
      newReport: {
        report_Id: null,
        report_Name: null,
        category: this.category,
        report_Description: null
      }
    }
  },
  methods: {
    ...mapActions(['changeData']),
    cancel () {
      this.$emit('cancel', true)
    },
    save () {
      this.changeData({
        currentPage: 'SetupReports',
        newData: {
          reportList: [this.newReport]
        }
      }).then(() => {
        this.$emit('saveComplete')
      }).catch(error => { console.log(error.response) })
    }
  },
  computed: {
    dialogTitle () {
      return 'New Report'
    }
  },
  created () {
    if (this.report) {
      this.newReport = this.report
    }
  }
}
</script>
