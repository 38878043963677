<template>
  <v-card
    flat
    width="100"
    height="80"
    :class="['month-picker-card ma-2', isSelected ? 'month-picker-card--selected' : disabled ? 'month-picker-card--disabled' : error ? 'month-picker-card--error' : '']">
    <div class="text-center pt-2">
      <span
        :class="['display-1 text-uppercase', isSelected ? 'month-picker-card__year--selected': disabled ? 'month-picker-card__year--disabled' : error ? 'month-picker-card__year--error' : '']">
        {{ month }}
      </span>
    </div>
    <div class="text-right">
      <span
        :class="['mr-4 subheading text-uppercase font-weight-regular', isSelected ? 'month-picker-card__year--selected': disabled ? 'month-picker-card__year--disabled' : error ? 'month-picker-card__year--error' : '']">
        {{ year }}
      </span>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ['selectedDate', 'date', 'error', 'disabled'],
  data () {
    return {
    }
  },
  computed: {
    isSelected () {
      if (this.selectedDate) {
        if (this.date.format('MM/DD/YYYY') === this.selectedDate.format('MM/DD/YYYY')) {
          return true
        }
      }
      return false
    },
    month () {
      return this.date.format('MMM')
    },
    year () {
      return this.date.format('YYYY')
    }
  }
}
</script>

<style lang="scss" scoped>
.month-picker-card {
  background: var(--v-resWhite-base);
  border: 1px solid var(--v-resGray-darken2);
  border-radius: 6px;
  cursor: pointer;
  &--disabled {
    background: var(--v-resGray-lighten6);
    border: 1px solid var(--v-resGray-darken2);
    cursor: default;
  }
  &--selected {
    background: var(--v-resBlue-darken1);
    border: 4px solid var(--v-resBlue-lighten3);
    color: var(--v-resWhite-base) !important;
  }
  &--error {
    background: var(--v-resWhite-base);
    border: 2px solid var(--v-resRed-base);
    color: var(--v-resRed-base) !important;
  }
  &__year {
    &--disabled {
      color: var(--v-resGray-darken2) !important;
    }
    &--selected {
      color: var(--v-resWhite-base) !important;
    }
    &--error {
      color: var(--v-resRed-base) !important;
    }
  }
}

.subheading, .display-1 {
  color: var(--v-resGray-darken5);
}

.sq-ft-container {
  margin-right: auto;
  margin-left: auto;
  width: 60px;
}

.discrepancy {
  border-bottom: 3px solid var(--v-resRed-base)
;
}

.discrepancy-text {
  color: var(--v-resRed-base);
  font-size: 11px;
}
</style>
