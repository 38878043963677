<template>
  <v-card flat>
    <v-app-bar flat height="40" color='var(--v-resWhite-base)'>
      <div class="caption mt-3 resGray-darken5--text">New Attachment</div>
      <v-spacer></v-spacer>
      <v-btn @click="cancelUpload" icon class="mt-3">
        <v-icon>close</v-icon>
      </v-btn>
    </v-app-bar>

    <v-row no-gutters>
      <v-spacer></v-spacer>
      <v-col>
        <!-- Waiting for file -->
        <v-card
          width="315"
          flat
          height="165"
          :class="fileHover ? 'drag-drop__card--hover' : 'drag-drop__card--no-file'"
          class="ma-3"
          v-if="!fileDropped && !fileLimitExceeded && !wrongFileType"
          @drop.prevent="dropFile"
          @dragstart.prevent
          @drag.prevent
          @dragenter.prevent="hoverChange"
          @dragleave.prevent="hoverChange"
          @dragend.prevent
          @dragover.prevent>
            <input type="file" ref="fileUploadButton" v-show="false" @change="onFileSelection">
            <v-layout align-center justify-center column fill-height>
              <span class="caption resGray-darken1--text">Drag & drop file here or</span>
              <round-button
                color='var(--v-resGray-darken3)'
                height='30px'
                width='100px'
                :buttonText="'Browse'"
                backgroundColor='var(--v-resBlue-darken1)'
                borderColor='var(--v-resGray-lighten1)'
                buttonClass='mt-1'
                :dark='true'
                :depressed='true'
                @click="$refs.fileUploadButton.click()">
              </round-button>
              <span class="caption resGray-darken1--text">max file size: 25mb</span>
            </v-layout>
        </v-card>
        <!-- Valid file uploading -->
        <v-card v-if="fileDropped && !fileLimitExceeded && !wrongFileType" flat width="315" height="165" class="ma-3" :class="fileHover ? 'drag-drop__card--hover' : 'drag-drop__card--no-file'">
          <v-layout align-center justify-center column fill-height>
            <v-icon class="mb-1" x-large>far fa-file</v-icon>
            <div class="file-name text-center my-2">{{ files.name }}</div>
            <v-card width="200" flat>
              <v-layout>
                <v-progress-linear
                  class="ml-3"
                  height="8px"
                  rounded
                  background-color="var(--v-resGray-lighten3)"
                  color="var(--v-resBlue-darken1)"
                  :value="loadingBarValue">
                </v-progress-linear>
                <round-button
                  color='var(--v-resGray-lighten1)'
                  class="drag-drop__cancel-icon pl-2 ml-1"
                  height='20px'
                  minHeight='20px'
                  width='20px'
                  minWidth='20px'
                  :icon='true'
                  :flat='true'
                  iconText='delete'
                  :depressed='true'
                  @click="tryAgain">
                </round-button>
              </v-layout>
            </v-card>
          </v-layout>
        </v-card>
        <!-- Invalid file attempted -->
        <v-card v-if="fileLimitExceeded || wrongFileType" flat width="315" height="165" class="ma-3" :class="fileHover ? 'drag-drop__card--hover' : 'drag-drop__card--no-file'">
          <v-layout align-center justify-center column fill-height>
            <span class="caption resRed-base--text font-weight-medium">Upload Failed</span>
            <span class="mini-text resGray-darken5--text" v-if="fileLimitExceeded">File size too large,</span>
            <span class="mini-text resGray-darken5--text" v-if="fileLimitExceeded">max file size: 25mb</span>
            <span class="mini-text resGray-darken5--text" v-if="wrongFileType">File type not supported,</span>
            <span class="mini-text resGray-darken5--text" v-if="wrongFileType">Supported types include:</span>
            <span class="mini-text resGray-darken5--text" v-if="wrongFileType">jpg, jpeg, gif, bmp, png, pdf, xls, xlsx, doc, docx, txt</span>
            <round-button
              color='var(--v-resGray-darken3)'
              height='25px'
              width='100px'
              :buttonText="'Try Again'"
              backgroundColor='var(--v-resRed-base)'
              borderColor='var(--v-resGray-lighten1)'
              buttonClass='mt-2'
              :dark='true'
              :depressed='true'
              @click="tryAgain">
            </round-button>
          </v-layout>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>

    <v-row no-gutters>
      <v-spacer></v-spacer>
      <v-col>
        <text-input
          :value="description"
          v-if="addDescription"
          label='Description'
          rules="required|max:250"
          width="315px"
          :textCounter="250"
          @textInputUpdated="updateDescription">
        </text-input>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>

    <v-app-bar flat height="60" color='var(--v-resWhite-base)'>
      <v-spacer></v-spacer>
      <round-button
        color='var(--v-resRed-base)'
        height='30px'
        width='80px'
        :depressed='true'
        :outline='true'
        buttonText='Cancel'
        buttonTextColor='var(--v-resRed-base)'
        borderColor='var(--v-resRed-base)'
        buttonClass='mr-3'
        @click="cancelUpload">
      </round-button>
      <round-button
        color='var(--v-resGray-darken3)'
        height='30px'
        width='80px'
        :depressed='true'
        buttonText='Save'
        :disabled="!saveAllowed"
        :backgroundColor="'var(--v-resGreen-lighten1)'"
        @click="saveUpload">
      </round-button>
    </v-app-bar>
  </v-card>
</template>

<script>
import RoundButton from './RoundButton'
import TextInput from './TextInput'

export default {
  props: ['addDescription'],
  components: {
    RoundButton,
    TextInput
  },
  data () {
    return {
      files: {},
      fileDropped: false,
      description: '',
      fileLimitExceeded: false,
      wrongFileType: false,
      loadingBarValue: 0,
      loadingInterval: setInterval(this.loadingTimer, 125),
      fileHover: false
    }
  },
  methods: {
    cancelUpload () {
      this.resetWorkFlow()
      this.$emit('cancelUpload')
    },
    tryAgain () {
      this.files = {}
      this.fileDropped = false
      this.fileLimitExceeded = false
      this.wrongFileType = false
      this.loadingBarValue = 0
    },
    updateDescription (val) {
      this.description = val
    },
    convertFileToArray (resolve) {
      let reader = new FileReader()
      reader.readAsArrayBuffer(this.files)
      reader.onload = function () {
        let arrayBuffer = reader.result
        var bytes = new Uint8Array(arrayBuffer)
        resolve(bytes)
      }
    },
    saveUpload () {
      this.$eventHub.$emit('validate')
      if (this.saveAllowed) {
        let promise = new Promise(this.convertFileToArray)
        promise.then((data) => {
          let item = {}
          item.fileName = this.files.name
          item.title = this.description
          item.FileContent = btoa(new Uint8Array(data).reduce(function (data, byte) {
            return data + String.fromCharCode(byte)
          }, ''))
          this.$emit('complete', item)
          this.resetWorkFlow()
        })
      }
    },
    resetWorkFlow () {
      this.files = {}
      this.fileDropped = false
      this.description = ''
      this.fileLimitExceeded = false
      this.wrongFileType = false
      this.loadingBarValue = 0
    },
    onFileSelection () {
      if (this.checkFileSize(this.$refs.fileUploadButton.files[0].size)) {
        if (this.checkFileExtension(this.$refs.fileUploadButton.files[0].name)) {
          this.files = this.$refs.fileUploadButton.files[0]
          this.fileDropped = true
        } else {
          this.wrongFileType = true
        }
      } else {
        this.fileLimitExceeded = true
      }

      this.updateDescription(this.$refs.fileUploadButton.files[0].name)
    },
    dropFile (e) {
      this.fileHover = false
      if (this.checkFileSize(e.dataTransfer.files[0].size)) {
        if (this.checkFileExtension(e.dataTransfer.files[0].name)) {
          this.files = e.dataTransfer.files[0]
          this.fileDropped = true
        } else {
          this.wrongFileType = true
        }
      } else {
        this.fileLimitExceeded = true
      }

      this.updateDescription(e.dataTransfer.files[0].name)
    },
    loadingTimer () {
      if (this.fileDropped && this.loadingBarValue <= 100) {
        this.loadingBarValue += 10
      }
    },
    checkFileExtension (fileName) {
      let extension = (fileName.slice((fileName.lastIndexOf('.') - 1 >>> 0) + 2)).toLowerCase()
      switch (extension) {
        case 'jpg':
        case 'gif':
        case 'jpeg':
        case 'bmp':
        case 'png':
        case 'pdf':
        case 'doc':
        case 'docx':
        case 'xls':
        case 'xlsx':
        case 'csv':
        case 'txt':
          return true
      }
      return false
    },
    checkFileSize (fileSize) {
      if (fileSize < 25000000 && fileSize > 0) {
        return true
      }
      return false
    },
    hoverChange () {
      this.fileHover = !this.fileHover
    }
  },
  computed: {
    saveAllowed () {
      if (this.files !== {} && this.loadingBarValue >= 100) {
        if (this.addDescription) {
          if (this.description.length > 0) {
            return true
          }
          return false
        }
        return true
      }
      return false
    }
  },
  beforeDestroy () {
    clearInterval(this.loadingInterval)
  }
}
</script>

<style lang="scss" scoped>
  .drag-drop__card {
    &--no-file {
      border-radius: 6px;
      border: dashed 1px var(--v-resGray-darken2);
    }
    &--hover {
      border-radius: 6px;
      border: solid 1px var(--v-resBlue-darken1);
      background-color: var(--v-resBlue-lighten4);
    }
  }
  .drag-drop__cancel-icon {
    width: 20px;
    ::v-deep .v-icon {
      font-size:20px;
      margin-bottom: 12px;
    }
  }
  .mini-text {
    font-size: 10px;
  }
  .file-name {
    width: 100%;
    overflow-wrap: break-word;
    padding-left: 1rem;
    padding-right: 1rem;
  }
</style>
