<template>
  <v-radio-group
    v-model="returnValue"
    @change="changeSelected"
    class="radio-group my-0 py-0"
    hide-details
    :readonly="readonly"
    :disabled="disabled"
    row>
    <v-radio
      v-for="option in options"
      :key="option.label"
      :label="option.label"
      :value="option.value"
      color="var(--v-resBlue-darken1)"
      :ripple="false">
    </v-radio>
  </v-radio-group>
</template>

<script>
export default {
  props: ['selectedValue', 'options', 'readonly', 'disabled'],
  data () {
    return {
      returnValue: this.selectedValue
    }
  },
  methods: {
    changeSelected (val) {
      this.$emit('valueChosen', val)
    }
  },
  watch: {
    selectedValue (val) {
      this.returnValue = val
    }
  }
}
</script>

<style lang="scss" scoped>
  .radio-group {
    ::v-deep label {
      font-size: 12px;
      color: var(--v-resGray-darken5);
    }
    ::v-deep i {
      font-size: 15px !important;
      color: var(--v-resGray-darken5);
    }
    padding-left: 10px;
    padding-right: 10px;
  }
</style>
