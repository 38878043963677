<template>
  <v-container fluid class="resGray lighten-6">
    <v-app-bar flat height="36">
      <v-spacer></v-spacer>
      <round-button
        color='var(--v-resGray-darken3)'
        height='30px'
        width='130px'
        :dark='true'
        :depressed='true'
        buttonText='+ Add Comment'
        backgroundColor='var(--v-resGreen-lighten1)'
        borderColor='var(--v-resGray-lighten1)'
        buttonClass='mr-2'
        @click="addNew">
      </round-button>
    </v-app-bar>
    <v-row>
      <v-col cols="12" class="mt-3">
        <data-table
          class="mx-6"
          :headers="getHeaders"
          :tableData="getData"
          :expand="true"
          :noData="'No information to display.'"
          :showActions="true"
          @unlockEditing='unlockEdit'
          @itemDeleteRequested="deleteItem">
        </data-table>
      </v-col>
    </v-row>
    <!-- Dialogs -->
    <base-dialog
      :showDialog="isDialog"
      v-if="isDialog"
      :title="addOrEdit"
      @cancel="cancelDialog"
      @save="confirmDialog">
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12">
            <date-picker
              :label="'Date'"
              :initialDate="editedItem.effectiveDate"
              @dateSelected="setCommentDate">
            </date-picker>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-4">
          <v-col cols="12">
            <drop-down
              :selectedValue="{ text: editedItem.sourceTypeName}"
              :items="'Lease Comment Source'"
              :label="'Source'"
              :multiple='false'
              width="250px"
              @itemSelected="selectSourceType">
            </drop-down>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-5">
          <v-col cols="12">
            <drop-down
              :items="'Lease Comment Types'"
              :selectedValue="{ text: editedItem.commentTypeName}"
              :label="'Type'"
              width="250px"
              :multiple='false'
              @itemSelected="selectCommentType">
            </drop-down>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-5">
          <v-col cols="12">
            <text-area
              :value="editedItem.commentSummary"
              label='Comment'
              @textAreaUpdated="commentSummaryUpdate">
            </text-area>
          </v-col>
        </v-row>
      </v-card-text>
    </base-dialog>
  </v-container>
</template>

<script>
import DataTable from '../BaseComponents/Table'
import RoundButton from '../BaseComponents/RoundButton'
import DropDown from '../BaseComponents/DropDown'
import DatePicker from '../BaseComponents/DatePicker'
import TextArea from '../BaseComponents/TextArea'
import BaseDialog from '../BaseComponents/BaseDialog'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    RoundButton,
    DataTable,
    DropDown,
    DatePicker,
    TextArea,
    BaseDialog
  },
  data () {
    return {
      addingNew: false,
      editing: false,
      isDialog: false,
      editedIndex: -1,
      editedItem: {},
      typeSelected: {},
      sourceSelected: {},
      addOrEdit: ''
    }
  },
  created () {
    this.fetchData({newPage: 'LeaseComments', params: { Lease: this.currentSelectedItem.lease }})
  },
  methods: {
    ...mapActions(['changeLoading', 'fetchData', 'changeData', 'deleteData']),
    unlockEdit (item) {
      // this handles the client side of locking and unlocking editing
      this.editedIndex = this.getData.indexOf(item)
      this.editedItem = JSON.parse(JSON.stringify(this.getData[this.editedIndex], item))
      this.addOrEdit = 'Edit Comment'
      this.isDialog = true
    },
    cancelDialog () {
      this.isDialog = false
    },
    selectType (val) {
      this.typeSelected = val
    },
    selectSourceType (val) {
      this.editedItem.sourceTypeName = val.text
      this.editedItem.commentSourceType = val.value
    },
    selectCommentType (val) {
      this.editedItem.commentTypeName = val.text
      this.editedItem.commentType = val.value
    },
    commentSummaryUpdate (val) {
      this.editedItem.commentSummary = val
    },
    setCommentDate (val) {
      this.editedItem.effectiveDate = val
    },
    deleteItem (item) {
      this.deleteData({ currentPage: 'LeaseComments', removeItem: item })
    },
    confirmDialog () {
      this.changeData({ currentPage: 'LeaseComments', newData: this.editedItem }).then(() => {
        this.fetchData({newPage: 'LeaseComments', params: { Lease: this.currentSelectedItem.lease }}).then(() => {
          this.isDialog = false
        })
      }).catch(error => { console.log(error.response) })
    },
    addNew () {
      this.editedItem = {}
      this.addOrEdit = 'Add Comment'
      this.editedItem.lease = this.currentSelectedItem.lease
      this.editedItem.leaseRevision = this.currentSelectedItem.leaseRevision
      this.isDialog = true
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentSelectedItem']),
    getData: {
      get () {
        return this.currentData('LeaseComments')
      }
    },
    getHeaders: {
      get () {
        return this.currentHeaders('LeaseComments')
      }
    }
  }
}
</script>
