<template>
  <v-container class="resGray lighten-6">
    <v-layout align-center justify-space-between column fill-height>
      <v-card flat width="1000" height="130">
        <v-row>
          <v-col cols="12" class="mt-2">
            <div class="mx-6">
              <span class="title">Prepayments Application Details</span>
            </div>
          </v-col>
        </v-row>
        <v-app-bar flat class="mt-2 resWhite">
          <date-picker
            class="ml-2 mr-3"
            :label="'Application date'"
            hideDetails
            rules="required"
            :initialDate="applicationDate"
            @dateSelected="setDate">
          </date-picker>
          <drop-down
            :selectedValue="{ text: selectedPropertyName }"
            :items="'Properties'"
            :label="'Property'"
            class="mb-5 mr-3"
            :multiple='false'
            width="200px"
            hideDetails
            rules="required"
            :getParams="{ IncludeIncomplete: false, IncompleteOnly: false }"
            @itemSelected="selectProperty">
          </drop-down>
        </v-app-bar>
      </v-card>
      <warning-bar
        message="There are no open prepayments for this property. Please select a different property to apply prepayments."
        @closeWarning="closeWarning"
        :showWarning="noPrepayments">
      </warning-bar>
      <v-card flat color="var(--v-resGray-lighten6)" width="1000" class='mt-1' v-if="getData.length > 0">
        <v-flex mt-6 mb-4>
          <span class="title">Distribute amount</span>
        </v-flex>
        <v-tabs
          v-model="active"
          class="distribution__tabs"
          prev-icon="arrow_left"
          next-icon="arrow_right"
          show-arrows
          hide-slider>
          <v-tab
            v-for="n in getData.length"
            :disabled="currentLoading"
            :key="n">
            <span class="caption text-capitalize ml-1">
              <div>{{ tabTitle(n - 1) }}</div>
            </span>
          </v-tab>
          <v-tabs-items v-model="active">
            <v-tab-item
              v-for="n in getData.length"
              :key="n">
              <distribution-tab
                :active="active"
                ref='tabs'
                @reconciled="unlockSave"
                :depositDetails="getData[active]"
                :applicationDate="applicationDate"
                v-if="n - 1 === active">
              </distribution-tab>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
        <v-layout mt-4>
          <v-spacer></v-spacer>
          <span class="subheading">(Check {{ active + 1 }} of {{ getData.length }})</span>
        </v-layout>
        <v-layout>
          <round-button
            height='30px'
            buttonClass='mt-4'
            width='140px'
            v-if="active !== 0"
            :depressed='true'
            :flat='true'
            :buttonTextColor="'var(--v-resWhite-base)'"
            :backgroundColor="'var(--v-resBlue-darken1)'"
            :buttonText="'Previous Check'"
            @click="previousCheck">
          </round-button>
          <v-spacer></v-spacer>
          <round-button
            height='30px'
            v-if="getData.length - 1 !== active"
            buttonClass='mt-4 mr-3'
            width='110px'
            :depressed='true'
            :flat='true'
            :buttonTextColor="'var(--v-resWhite-base)'"
            :backgroundColor="'var(--v-resBlue-darken1)'"
            :buttonText="'Next Check'"
            @click="nextCheck">
          </round-button>
          <round-button
            height='30px'
            buttonClass='mt-4'
            width='80px'
            :depressed='true'
            :flat='true'
            :disabled='canSave'
            :buttonTextColor="'var(--v-resWhite-base)'"
            :backgroundColor="'var(--v-resGreen-lighten1)'"
            :buttonText="'Save'"
            @click="save">
          </round-button>
        </v-layout>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import RoundButton from '../../../BaseComponents/RoundButton'
import DatePicker from '../../../BaseComponents/DatePicker'
import DistributionTab from './DistributionTab'
import DropDown from '../../../BaseComponents/DropDown'
import WarningBar from '../../../BaseComponents/WarningBar'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    RoundButton,
    DropDown,
    DatePicker,
    DistributionTab,
    WarningBar
  },
  data () {
    return {
      active: 0,
      editedItem: {},
      allLeases: [],
      selectedProperty: undefined,
      selectedPropertyName: undefined,
      applicationDate: null,
      canSave: false,
      noPrepayments: false
    }
  },
  methods: {
    ...mapActions(['changeData', 'fetchData', 'initializeStoreItem']),
    selectProperty (val) {
      if (val !== null) {
        this.selectedProperty = val.value
        this.selectedPropertyName = val.text
        this.getOpenPrepays()
      } else {
        this.selectedProperty = null
        this.selectedPropertyName = null
      }
    },
    setDate (val) {
      this.applicationDate = val
    },
    getOpenPrepays () {
      this.fetchData({ newPage: 'ApplyPrepayments', params: { Property: this.selectedProperty } }).then(() => {
        if (this.getData.length === 0) {
          this.noPrepayments = true
        } else {
          this.noPrepayments = false
        }
      }).catch(error => { console.log(error.response) })
    },
    setActiveTab (val) {
      if (this.currentCheckReconciled) {
        this.active = val
      }
    },
    openDialog () {
      this.showDialog = true
    },
    cancel () {
      this.showDialog = false
    },
    nextCheck () {
      if (this.getData.length - 1 !== this.active) {
        this.active++
      }
    },
    closeWarning () {
      this.noPrepayments = false
    },
    previousCheck () {
      if (this.active !== 0) {
        this.active--
      }
    },
    unlockSave (e) {
      this.canSave = e
    },
    save () {
      this.$refs.tabs[0].save()
    },
    tabTitle (tabIndex) {
      let check = this.getData[tabIndex]
      if (check && check.payerCheckNumber) {
        if (check.payerCheckNumber.length > 7) {
          return `# ${check.payerCheckNumber.substr(0, 7)}...`
        } else {
          return `# ${check.payerCheckNumber}`
        }
      }
    },
    clearPrepaids () {
      this.initializeStoreItem('ApplyPrepayments')
      this.initializeStoreItem('LeaseOpenCharges')
    }
  },
  computed: {
    ...mapGetters(['currentDropdown', 'currentData', 'currentHeaders', 'currentLoading']),
    getData () {
      return this.currentData('ApplyPrepayments')
    }
  },
  beforeDestroy () {
    this.clearPrepaids()
  }
}
</script>

<style lang="scss" scoped>
  .v-tabs {
    &.distribution__tabs {
      ::v-deep .v-tabs-bar {
        height: 40px;
        background-color: var(--v-resGray-lighten6)
      }
      ::v-deep .v-slide-group__prev {
        background-color: var(--v-resGray-lighten3);
        border-color: var(--v-resGray-lighten1);
        border-style: solid;
        border-width: 1px 1px 0px 1px;
      }
      ::v-deep .v-slide-group__next {
        background-color: var(--v-resGray-lighten3);
        border-color: var(--v-resGray-lighten1);
        border-style: solid;
        border-width: 1px 1px 0px 1px;
      }
      ::v-deep .v-tab {
        justify-content: left;
        padding: 6px 6px;
        background-color: var(--v-resGray-lighten3);
        border-color: var(--v-resGray-lighten1);
        border-style: solid;
        border-width: 1px 1px 0px 1px;
        color: var(--v-resGray-darken4);
        .v-icon {
          color: var(--v-resGray-darken3);
        }
      }
      ::v-deep .v-tab--active {
        background-color: var(--v-resBlue-base);
        border-color: var(--v-resBlue-base);
        border-style: solid;
        border-width: 1px 1px 0px 1px;
        color: var(--v-resWhite-base) !important;
        .v-icon {
          color: var(--v-resWhite-base);
        }
        &.v-tab--disabled {
          opacity: 100%;
        }
      }
    }
  }

  .tabs-card {
    border-radius: 0px;
    border-top: 2px var(--v-resBlue-base) solid;
    border-right: 1px var(--v-resGray-lighten1) solid;
    border-left: 1px var(--v-resGray-lighten1) solid;
    border-bottom: 1px var(--v-resGray-lighten1) solid;
  }

  .contact-title--primary {
    font-style: italic;
  }
</style>
