<template>
  <v-card flat width="540" height="70" class="accounts-card">
    <v-layout align-center justify-space-around fill-height>
      <v-flex ml-6>
        <div class="caption resGray-darken4--text">Pool</div>
        <div class="caption resGray-darken5--text font-weight-medium">{{ currentPool.poolName }}</div>
      </v-flex>
      <v-flex>
        <div class="caption resGray-darken4--text">Pool Frequency</div>
        <div class="caption resGray-darken5--text font-weight-medium">{{ currentPool.poolFrequencyName }}</div>
      </v-flex>
      <v-flex>
        <div class="caption resGray-darken4--text">Area Measure</div>
        <div class="caption resGray-darken5--text font-weight-medium">{{ currentPool.areaMeasureTypeName }}</div>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters(['currentPool'])
  }
}
</script>

<style lang="scss" scoped>
  .accounts-card {
    border: solid 1px var(--v-resGray-lighten1);
  }
</style>
