<template>
  <base-dialog
    :showDialog="showDialog"
    :title="eventSourceDescription"
    :titleClass="'dark-header'"
    :hideClose="true"
    :maxWidth="960"
    :setWidth="960"
    :saveButtonOnly="true"
    :saveButtonText="'Close'"
    :saveButtonColor="'var(--v-resBlue-darken1)'"
    @save="closeDialog">
    <v-card-text>
      <div v-if="showToggleButtons" class="pt-0 mt-0">
        <span>Filter by</span>
      </div>
      <toggle-button-group
        v-if="showToggleButtons"
        class="ml-0 mb-4"
        group-width="300px"
        :buttons="toggleButtons"
        @toggled="changeFilter">
      </toggle-button-group>
      <data-table
        :headers="headers"
        :tableData="details"
        :baseRowPerPage="5"
        :fixedPagination="true"
        :noData="'No information to display.'"
        @ClickLease="ClickLease"
        @ClickLeaseInsurance="ClickLeaseInsurance"
        @ClickProperty="ClickProperty"
        @ClickPropertyMeasurements="ClickPropertyMeasurements"
        @ClickDepositSlipDraft="ClickDepositSlipDraft">
      </data-table>
    </v-card-text>
  </base-dialog>
</template>

<script>
import BaseDialog from '../../BaseComponents/BaseDialog'
import ToggleButtonGroup from '../../BaseComponents/ToggleButtonGroup2'
import DataTable from '../../BaseComponents/Table'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    BaseDialog,
    ToggleButtonGroup,
    DataTable
  },
  props: ['showDialog', 'eventSourceId', 'eventSourceDescription'],
  data () {
    return {
      filterSelection: 0
    }
  },
  methods: {
    ...mapActions(['changeLoading', 'changeSelectedItem', 'fetchData', 'changeTab']),
    ClickLease (val) {
      this.fetchData({ newPage: 'SingleLease', params: { leaseId: val.leaseId } }).then(() => {
        this.closeDialog()
        this.changeSelectedItem(this.selectedLeaseData)
        if (!this.currentTab || this.currentTab.route !== 'LeasesOverview') {
          this.changeTab({ header: 'Overview', index: 0, route: 'LeasesOverview' })
          this.$router.push({ name: 'LeasesOverview' })
        } else {
          // explicitly refresh the page
          this.$router.go()
        }
      }).catch(error => { console.log(error.response) })
    },
    ClickLeaseInsurance (val) {
      this.fetchData({ newPage: 'SingleLease', params: { leaseId: val.leaseId } }).then(() => {
        this.closeDialog()
        this.changeSelectedItem(this.selectedLeaseData)
        if (!this.currentTab || this.currentTab.route !== 'LeasesInsurance') {
          this.changeTab({ header: 'Insurance', index: 5, route: 'LeasesInsurance' })
          this.$router.push({ name: 'LeasesInsurance' })
        } else {
          // explicitly refresh the page
          this.$router.go()
        }
      }).catch(error => { console.log(error.response) })
    },
    ClickProperty (val) {
      this.fetchData({ newPage: 'SingleProperty', params: { propertyId: val.propertyId } }).then(() => {
        this.closeDialog()
        this.changeSelectedItem(this.selectedPropertyData)
        if (!this.currentTab || this.currentTab.route !== 'PropertiesOverview') {
          this.changeTab({ header: 'Overview', index: 0, route: 'PropertiesOverview' })
          this.$router.push({ name: 'PropertiesOverview' })
        } else {
          // explicitly refresh the page
          this.$router.go()
        }
      }).catch(error => { console.log(error.response) })
    },
    ClickPropertyMeasurements (val) {
      this.fetchData({ newPage: 'SingleProperty', params: { propertyId: val.propertyId } }).then(() => {
        this.closeDialog()
        this.changeSelectedItem(this.selectedPropertyData)
        if (!this.currentTab || (this.currentTab.route !== 'SqFtMeasures' && this.currentTab.route !== 'PropertiesSqFtMeasures')) {
          this.changeTab({ header: 'SqFtMeasures', index: 2, route: 'PropertiesSqFtMeasures' })
          this.$router.push({ name: 'PropertiesSqFtMeasures' })
        } else {
          // explicitly refresh the page
          this.$router.go()
        }
      }).catch(error => { console.log(error.response) })
    },
    ClickDepositSlipDraft () {
      this.closeDialog()
      if (!this.currentTab || (this.currentTab.route !== 'ReceiptsDetail' && this.currentTab.route !== 'Cash Receipts')) {
        this.changeTab({ header: 'CashReceipts', index: 0, route: 'CashReceipts' })
        this.$router.push({ name: 'ReceiptsDetail' })
      } else {
        // explicitly refresh the page
        this.$router.go()
      }
    },
    changeFilter (val) {
      this.filterSelection = val
    },
    getNotificationData () {
      switch (this.eventSourceId) {
        case 1:
          this.fetchData({ newPage: 'ExpiringVendorInsuranceNotifications' }).catch(error => { console.log(error.response) })
          break
        case 2:
          this.fetchData({ newPage: 'ExpiringLeaseInsuranceNotifications' }).catch(error => { console.log(error.response) })
          break
        case 3:
          this.fetchData({ newPage: 'ExpiringLeaseNotifications' }).catch(error => { console.log(error.response) })
          break
        case 4:
          this.fetchData({ newPage: 'FutureLeaseOptionNotifications' }).catch(error => { console.log(error.response) })
          break
        case 5:
          this.fetchData({ newPage: 'ExpiringLeaseOptionNotifications' }).catch(error => { console.log(error.response) })
          break
        case 6:
          this.fetchData({ newPage: 'MonthToMonthLeaseNotifications' }).catch(error => { console.log(error.response) })
          break
        case 7:
          this.fetchData({ newPage: 'PropertySpaceVarianceNotifications' }).catch(error => { console.log(error.response) })
          break
        case 8:
          this.fetchData({ newPage: 'PropertyAreaVarianceNotifications' }).catch(error => { console.log(error.response) })
          break
        case 9:
          this.fetchData({ newPage: 'DepositSlipDraftNotifications' }).catch(error => { console.log(error.response) })
          break
      }
    },
    closeDialog () {
      this.$emit('close', true)
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentTab']),
    headers () {
      switch (this.eventSourceId) {
        case 1:
          return this.currentHeaders('ExpiringVendorInsuranceNotifications')
        case 2:
          return this.currentHeaders('ExpiringLeaseInsuranceNotifications')
        case 3:
          return this.currentHeaders('ExpiringLeaseNotifications')
        case 4:
          return this.currentHeaders('FutureLeaseOptionNotifications')
        case 5:
          return this.currentHeaders('ExpiringLeaseOptionNotifications')
        case 6:
          return this.currentHeaders('MonthToMonthLeaseNotifications')
        case 7:
          return this.currentHeaders('PropertySpaceVarianceNotifications')
        case 8:
          return this.currentHeaders('PropertyAreaVarianceNotifications')
        case 9:
          return this.currentHeaders('DepositSlipDraftNotifications')
      }
      return null
    },
    details () {
      switch (this.eventSourceId) {
        case 1:
          return this.currentData('ExpiringVendorInsuranceNotifications')
            .filter(item => {
              switch (this.filterSelection) {
                case 0: // All
                  return true
                case 1: // Unexpired
                  return item.daysUntilExpire > 0
                case 2: // Expired
                  return item.daysAfterExpire > 0
              }
            })
        case 2:
          return this.currentData('ExpiringLeaseInsuranceNotifications')
            .filter(item => {
              switch (this.filterSelection) {
                case 0: // All
                  return true
                case 1: // Unexpired
                  return item.daysUntilExpire > 0
                case 2: // Expired
                  return item.daysAfterExpire > 0
              }
            })
        case 3:
          return this.currentData('ExpiringLeaseNotifications')
        case 4:
          return this.currentData('FutureLeaseOptionNotifications')
            .filter(item => {
              switch (this.filterSelection) {
                case 0: // All
                  return true
                case 1: // Not Started
                  return item.daysUntilOptionStart > 0
                case 2: // Started
                  return item.daysAfterOptionStart > 0
              }
            })
        case 5:
          return this.currentData('ExpiringLeaseOptionNotifications')
        case 6:
          return this.currentData('MonthToMonthLeaseNotifications')
        case 7:
          return this.currentData('PropertySpaceVarianceNotifications')
        case 8:
          return this.currentData('PropertyAreaVarianceNotifications')
        case 9:
          return this.currentData('DepositSlipDraftNotifications')
      }
      return null
    },
    toggleButtons () {
      var text1 = ''
      var text2 = ''
      switch (this.eventSourceId) {
        case 4:
          text1 = 'Not Started'
          text2 = 'Started'
          break
        default:
          text1 = 'Unexpired'
          text2 = 'Expired'
          break
      }
      return [
        { text: 'All', index: 0 },
        { text: text1, index: 1 },
        { text: text2, index: 2 }
      ]
    },
    showToggleButtons () {
      switch (this.eventSourceId) {
        case 1:
        case 2:
        case 4:
          return true
        default:
          return false
      }
    },
    selectedLeaseData () {
      return this.currentData('SingleLease')
    },
    selectedPropertyData () {
      return this.currentData('SingleProperty')
    }
  },
  created () {
    this.changeLoading(true)
    this.getNotificationData()
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .button-group.v-btn-toggle {
    background: var(--v-resWhite-base) !important;
  }
  ::v-deep .v-dialog {
      position: absolute;
      top: 12%;
  }
</style>
