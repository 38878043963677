<template>
  <base-dialog
    :showDialog="showDialog"
    :title="'Contact information'"
    :maxWidth="680"
    @cancel="cancelContactEdit"
    @save="saveContactEdit">
    <v-card-text>
      <h3 class="mb-4">Enter contact information for  {{ this.currentSelectedItem.name }}.</h3>
      <v-tabs
        v-model="active"
        class="address-contacts"
        prev-icon="arrow_left"
        next-icon="arrow_right"
        show-arrows
        hide-slider>
        <v-tab
          v-for="n in totalTabs"
          :href="`#tab-${n}`"
          @change="setActiveTab(n)"
          :key="n">
          <v-icon>account_circle</v-icon>
          <span class="caption text-capitalize ml-1">
            <div>{{ tabTitle(n) }}</div>
            <div v-if="primaryContactTab(n)" class="contact-title--primary text-left">Primary</div>
          </span>
        </v-tab>
        <a class="v-tabs__item add-new" @click.stop="additionalContact">
          <v-icon>add</v-icon>
        </a>
        <v-tabs-items v-model="active">
          <v-tab-item
            v-for="n in renderedTabs"
            :key="n"
            :value="`tab-${n}`">
            <v-card max-height="310" flat class="tabs-card">
              <div style="overflow: auto" ref="scroll-card">
                <v-card max-height="300" flat>
                  <v-row no-gutters class="mt-3">
                    <v-col cols="12">
                      <text-input
                        :value="editedItem.positionTitle"
                        class="mx-6"
                        label='Job Title'
                        :optionalLabel="true"
                        optionalLabelText="(Optional)"
                        width="150px"
                        @textInputUpdated="updatePositionTitle">
                      </text-input>
                      <drop-down
                        :selectedValue="{ text: editedItem.contactTypeName }"
                        :items="'Contact Type'"
                        :label="'Contact type'"
                        width="150px"
                        rules="required"
                        :multiple='false'
                        :customList="customContactTypes"
                        @itemSelected="selectContactType">
                      </drop-down>
                      <v-btn v-if="totalTabs.length > 1" class="float-right button__no-hover" text @click="removeTab" :ripple="false">
                        <v-icon>delete_forever</v-icon>
                        <span class="resGray-darken5--text">Delete</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="mt-5">
                    <v-col cols="12">
                      <text-input
                        :value="editedItem.firstName"
                        class="mx-6"
                        label='First name'
                        width="150px"
                        rules="required"
                        @textInputUpdated="updateFirstName">
                      </text-input>
                      <text-input
                        :value="editedItem.lastName"
                        label='Last name'
                        width="150px"
                        rules="required"
                        @textInputUpdated="updateLastName">
                      </text-input>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="mt-5">
                    <v-col cols="12">
                      <text-input
                        :value="editedItem.emailAddress"
                        class="ml-6"
                        label='Email address'
                        width="250px"
                        rules="required|email"
                        @textInputUpdated="updateEmailAddress">
                      </text-input>
                    </v-col>
                  </v-row>
                  <div class="pb-5">
                    <div v-for="item in editedItem.phones" :key="item.id">
                      <v-row no-gutters class="mt-5" :key="totalPhones">
                        <v-col cols="12">
                          <text-input
                            :value="item.phoneNumber"
                            class="mx-6"
                            label='Phone number'
                            width="108px"
                            :mask="'###-###-####'"
                            rules="required|min:10"
                            @textInputUpdated="item.phoneNumber = $event">
                          </text-input>
                          <drop-down
                            :selectedValue="item.phoneTypeName ? { text: item.phoneTypeName } : null"
                            :items="'Phone Type'"
                            :label="'Type'"
                            :multiple='false'
                            width="180px"
                            rules="required"
                            :genericLabel='true'
                            @itemSelected="item.phoneTypeName = $event.text, item.phoneType = $event.value">
                          </drop-down>
                          <v-hover
                            v-if="item.id > 0"
                            v-slot="{ hover }">
                            <v-icon
                              :color="hover ? 'var(--v-resRed-base)': 'var(--v-resGray-darken4)'"
                              class="button__no-hover-icon ml-2"
                              @click="removePhone(item)">
                              close
                            </v-icon>
                          </v-hover>
                          <v-hover
                            v-if="editedItem.phones.indexOf(item) === editedItem.phones.length - 1"
                            v-slot="{ hover }">
                            <v-icon
                              :color="hover ? 'var(--v-resBlue-darken1)': 'var(--v-resGray-darken4)'"
                              class="button__no-hover-icon ml-2"
                              @click="addPhone">
                              add_circle
                            </v-icon>
                          </v-hover>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </v-card>
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card-text>
  </base-dialog>
</template>

<script>
import DropDown from '../../BaseComponents/DropDown'
import TextInput from '../../BaseComponents/TextInput'
import BaseDialog from '../../BaseComponents/BaseDialog'
import { mapGetters, mapActions } from 'vuex'

export default {
  props: ['showDialog'],
  components: {
    DropDown,
    TextInput,
    BaseDialog
  },
  data () {
    return {
      active: 'tab-0',
      tabCount: 0,
      totalTabs: [0],
      editedItem: {
        contactId: 0,
        phones: [
          {
            phoneType: null,
            phoneTypeName: null,
            phoneNumber: null,
            id: 0
          }
        ]
      },
      allContacts: [],
      deleteContacts: [],
      totalPhones: 0
    }
  },
  methods: {
    ...mapActions(['fetchDropdown', 'fetchData', 'changeData']),
    additionalContact () {
      this.$eventHub.$emit('validate')
      setTimeout(() => {
        if (!this.errors.items.length > 0) {
          this.editedItem.organizationId = this.currentSelectedItem.organizationId
          var index = this.allContacts.find(x => x.contactId === this.editedItem.contactId) || null
          if (index === null) {
            this.allContacts.push(this.editedItem)
          }
          this.editedItem = {}
          this.tabCount++
          this.editedItem.organizationId = this.currentSelectedItem.organizationId
          this.editedItem.contactId = this.tabCount
          this.editedItem.phones = [{ phoneType: null, phoneTypeName: null, phoneNumber: null, id: 0 }]
          this.totalTabs.push(this.tabCount)
          this.active = 'tab-' + this.totalTabs.indexOf(this.tabCount)
        }
      }, 100)
    },
    cancelContactEdit () {
      this.$emit('cancel')
    },
    saveContactEdit () {
      this.$eventHub.$emit('validate')
      setTimeout(() => {
        if (!this.errors.items.length > 0) {
          this.editedItem.organizationId = this.currentSelectedItem.organizationId
          let index = this.allContacts.findIndex(x => x.contactId === this.editedItem.contactId)
          if (!~index) {
            this.allContacts.push(this.editedItem)
          }
          this.changeData({ currentPage: 'Contacts', newData: { allContacts: this.allContacts, deleteContacts: this.deleteContacts } }).then(() => {
            this.$emit('save')
          }).catch(error => { console.log(error.response) })
        }
      }, 100)
    },
    removeTab () {
      var index = this.totalTabs.indexOf(parseInt(this.active.split('-').pop()))
      if (this.allContacts[index] !== undefined && (this.allContacts[index].rowVersion !== null || this.allContacts[index].rowVersion !== undefined)) {
        this.deleteContacts.push(this.allContacts[index])
      }
      this.allContacts.splice(index, 1)
      this.totalTabs.splice(index, 1)
      this.tabCount = this.totalTabs[this.totalTabs.length - 1]
      this.active = 'tab-' + this.totalTabs[0]
      this.editedItem = this.allContacts[0]
    },
    removePhone (val) {
      var index = this.editedItem.phones.indexOf(val)
      if (~index) {
        this.editedItem.phones.splice(index, 1)
        this.totalPhones--
      }
    },
    addPhone () {
      this.totalPhones++
      var newPhone = {
        phoneType: null,
        phoneTypeName: null,
        phoneNumber: null,
        id: this.totalPhones
      }
      this.editedItem.phones.push(newPhone)
      this.$nextTick(() => {
        let container = this.$refs['scroll-card'][0]
        container.scrollTop = container.scrollHeight - container.clientHeight
      })
    },
    setActiveTab (val) {
      var index = this.allContacts.find(x => x.contactId === this.editedItem.contactId) || null
      if (index === null) {
        this.allContacts.push(this.editedItem)
      }
      this.editedItem = this.allContacts[this.totalTabs.indexOf(val)]
      this.active = 'tab-' + this.totalTabs.indexOf(val)
    },
    updatePositionTitle (val) {
      this.editedItem.positionTitle = val
    },
    selectContactType (val) {
      this.editedItem.contactTypeName = val.text
      this.editedItem.contactType = val.value
    },
    updateEmailAddress (val) {
      this.editedItem.emailAddress = val
    },
    updateFirstName (val) {
      this.editedItem.firstName = val
    },
    updateLastName (val) {
      this.editedItem.lastName = val
    },
    tabTitle (tabIndex) {
      let contact = this.allContacts[tabIndex]
      if (contact && contact.firstName && contact.lastName) {
        return `${contact.firstName} ${contact.lastName}`
      } else {
        return 'New Contact'
      }
    },
    primaryContactTab (tabIndex) {
      let contact = this.allContacts[tabIndex]
      if (contact && contact.contactType && contact.contactType === '1') {
        return true
      }
      return false
    }
  },
  computed: {
    ...mapGetters(['currentDropdown', 'currentSelectedItem', 'currentData']),
    customContactTypes () {
      let selectedContactTypes = JSON.parse(JSON.stringify(this.contactTypes))
      let alreadySelectedContactTypes = []
      this.allContacts.forEach(function (item) {
        alreadySelectedContactTypes.push(item.contactType)
      })
      alreadySelectedContactTypes.forEach(function (item) {
        if (item !== undefined && item !== null) {
          let removeItem = selectedContactTypes.find(x => parseInt(x.value) === parseInt(item))
          let removeItemIndex = selectedContactTypes.indexOf(removeItem)
          if (~removeItemIndex) {
            selectedContactTypes.splice(removeItemIndex, 1)
          }
        }
      })
      return selectedContactTypes
    },
    contactTypes () {
      return this.currentDropdown('Contact Type')
    },
    renderedTabs () {
      return this.totalTabs.filter(t => {
        return `tab-${t}` === this.active
      })
    }
  },
  created () {
    this.fetchDropdown({ newDropdown: 'Phone Type', params: { OrganizationId: this.currentSelectedItem.organizationId } }).catch(error => { console.log(error.response) })
    this.fetchDropdown({ newDropdown: 'Contact Type', params: { OrganizationId: this.currentSelectedItem.organizationId } }).catch(error => { console.log(error.response) })
    this.fetchData({newPage: 'Contacts', params: { OrganizationId: this.currentSelectedItem.organizationId, ApplicationRole: this.currentSelectedItem.applicationRole }}).then(() => {
      this.allContacts = this.currentData('Contacts')
      if (this.allContacts.length > 0) {
        this.allContacts.forEach(contact => {
          // ensure phones list is populated
          if (!contact.phones.length) {
            contact.phones = [{ phoneType: null, phoneTypeName: null, phoneNumber: null, id: 0 }]
          }
        })
        this.editedItem = this.allContacts[0]
        this.totalTabs = []
        this.tabCount = this.allContacts.length - 1
        for (var i = 0; i < this.allContacts.length; i++) {
          this.totalTabs.push(i)
        }
      }
    }).catch(error => { console.log(error.response) })
  }
}
</script>

<style lang="scss" scoped>
  .v-tabs {
    &.address-contacts {
      ::v-deep a.add-new {
        padding-top: 10px;
        padding-left: 12px;
        width: 50px;
        background-color: var(--v-resGray-lighten3);
        border-color: var(--v-resGray-lighten1);
        border-style: solid;
        border-width: 1px 1px 0px 1px;
        color: var(--v-resGray-darken4);
        .v-icon {
          color: var(--v-resGray-darken3);
        }
      }
      ::v-deep .v-slide-group__prev {
        background-color: var(--v-resGray-lighten3);
        border-color: var(--v-resGray-lighten1);
        border-style: solid;
        border-width: 1px 1px 0px 1px;
      }
      ::v-deep .v-slide-group__next {
        background-color: var(--v-resGray-lighten3);
        border-color: var(--v-resGray-lighten1);
        border-style: solid;
        border-width: 1px 1px 0px 1px;
      }
      ::v-deep .v-tab {
        background-color: var(--v-resGray-lighten3);
        border-color: var(--v-resGray-lighten1);
        border-style: solid;
        border-width: 1px 1px 0px 1px;
        color: var(--v-resGray-darken4);
        .v-icon {
          color: var(--v-resGray-darken3);
        }
      }
      ::v-deep .v-tab--active {
        background-color: var(--v-resBlue-base);
        border-color: var(--v-resBlue-base);
        border-style: solid;
        border-width: 1px 1px 0px 1px;
        color: var(--v-resWhite-base) !important;
        .v-icon {
          color: var(--v-resWhite-base);
        }
      }
    }
  }

  .tabs-card {
    border-radius: 0px;
    border-top: 2px var(--v-resBlue-base) solid;
    border-right: 1px var(--v-resGray-lighten1) solid;
    border-left: 1px var(--v-resGray-lighten1) solid;
    border-bottom: 1px var(--v-resGray-lighten1) solid;
  }
</style>
