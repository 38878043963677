<template>
  <div class="mx-4">
    <v-tabs
      v-model="active"
      class="estimate__tabs"
      hide-slider>
      <v-tab
        v-for="n in totalTabs"
        :href="`#tab-${n}`"
        :key="n"
        :disabled="checkIfTabActive(n)">
        <span class="caption text-uppercase">{{ n }}</span>
      </v-tab>
      <v-spacer></v-spacer>
      <v-checkbox
        v-if="!cycle.estimateIsLocked && activeTabIndex===0"
        height="18"
        :ripple="false"
        v-model="autofill"
        class="checkbox-label shrink"
        color="var(--v-resBlue-darken1)"
        label="Auto fill from Budgets"
        @change="autoFillEstimatesFromBudgets">
      </v-checkbox>
      <v-tabs-items v-model="active">
        <v-tab-item
          v-for="n in totalTabs"
          :key="n"
          :value="`tab-${n}`">
          <v-card flat class="tabs-card">
            <data-table
              v-if="tableData"
              :tableData="tableData"
              :headers="tableHeaders"
              :showTotals="true"
              @launchDetails="launchDetails">
            </data-table>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
    <v-layout mt-4>
      <round-button
        v-if="activeTabIndex===1 && !cycle.estimateIsLocked"
        buttonClass=""
        flat
        outline
        buttonText="Previous"
        height="30px"
        borderColor="var(--v-resBlue-darken1)"
        backgroundColor="var(--v-resWhite-base)"
        buttonTextColor='var(--v-resBlue-darken1)'
        color="var(--v-resBlue-darken1)"
        @click="previousTab">
      </round-button>
      <v-spacer></v-spacer>
      <round-button
        v-if="activeTabIndex===1 && !cycle.estimateIsLocked"
        buttonClass="ml-3"
        flat
        outline
        buttonText="Estimate Reports"
        height="30px"
        backgroundColor="var(--v-resBlue-darken1)"
        borderColor='var(--v-resBlue-darken1)'
        @click="showReportDialog">
      </round-button>
      <round-button
        v-if="!cycle.estimateIsLocked"
        buttonClass="ml-3"
        flat
        outline
        height="30px"
        :buttonText="buttonText"
        :backgroundColor="activeTabIndex === 0 ? 'var(--v-resBlue-darken1)' : 'var(--v-resGreen-base)'"
        :borderColor="activeTabIndex === 0 ? 'var(--v-resBlue-darken1)' : 'var(--v-resGreen-base)'"
        :disabled="!estimatesComplete"
        @click="saveEstimates">
      </round-button>
      <round-button
        v-if="cycle.estimateIsLocked || cycle.estimateRetroChargeDate !== null"
        buttonClass="ml-3"
        flat
        outline
        :icon="true"
        :iconText="lockEstimateIcon"
        :buttonText="lockEstimateButtonText"
        :capitalize="true"
        height="30px"
        borderColor='var(--v-resGreen-base)'
        :backgroundColor="'var(--v-resGreen-base)'"
        @click="showLockEstimate">
      </round-button>
      <!-- Dialogs -->
      <lock-estimate-dialog
        :isCurrentlyLocked="cycle.estimateIsLocked"
        :showDialog="showLockEstimateDialog"
        @cancelLockEstimate="hideLockEstimateDialog"
        @confirmLockEstimate="confirmLockEstimate">
      </lock-estimate-dialog>
    </v-layout>
  </div>
</template>

<script>
import RoundButton from '../../BaseComponents/RoundButton'
import DataTable from '../../BaseComponents/Table'
import LockEstimateDialog from './Dialogs/LockEstimate'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    DataTable,
    RoundButton,
    LockEstimateDialog
  },
  props: ['cycle', 'selectedTab'],
  data () {
    return {
      active: null,
      autofill: false,
      showLockEstimateDialog: false,
      totalTabs: ['GL Accounts', 'Lease Estimates']
    }
  },
  methods: {
    ...mapActions(['fetchData', 'changeData']),
    async getGlAccounts () {
      this.fetchData({
        newPage: 'Estimates Expense Accounts',
        params: {
          property: this.cycle.property,
          chargeCode: this.cycle.chargeCode,
          cycleNumber: this.cycle.cycleNumber
        }
      }).catch(error => { console.log(error.response) })
    },
    async getLeaseEstimates () {
      this.fetchData({
        newPage: 'Estimates Lease Expenses',
        params: {
          property: this.cycle.property,
          chargeCode: this.cycle.chargeCode,
          cycleNumber: this.cycle.cycleNumber
        }
      }).catch(error => { console.log(error.response) })
    },
    autoFillEstimatesFromBudgets () {
      if (this.autofill) {
        for (let i = 0; i < this.tableData.length; i++) {
          this.tableData[i].estimates = this.tableData[i].budgets
        }
      } else {
        for (let i = 0; i < this.tableData.length; i++) {
          this.tableData[i].estimates = null
        }
      }
    },
    saveEstimates () {
      if (this.activeTabIndex === 0) this.saveGlEstimates()
      if (this.activeTabIndex === 1) this.showChargesDialog()
    },
    saveGlEstimates () {
      this.changeData({
        currentPage: 'Estimates Expense Accounts',
        newData: {
          property: this.cycle.property,
          chargeCode: this.cycle.chargeCode,
          cycleNumber: this.cycle.cycleNumber,
          poolBalances: this.tableData
        }
      }).then(() => {
        this.active = `tab-${this.totalTabs[1]}`
      }).catch(error => { console.log(error.response) })
    },
    showReportDialog () {
      this.$emit('showReportDialog')
    },
    showChargesDialog () {
      this.$emit('showChargesDialog')
    },
    checkIfTabActive (e) {
      if (e === this.totalTabs[1] && !this.estimatesComplete) {
        return true
      }
    },
    previousTab () {
      this.active = `tab-${this.totalTabs[this.activeTabIndex - 1]}`
    },
    launchDetails (e) {
      this.$emit('launchDetails', e)
    },
    showLockEstimate () {
      this.showLockEstimateDialog = true
    },
    hideLockEstimateDialog () {
      this.showLockEstimateDialog = false
    },
    confirmLockEstimate (confirm) {
      this.showLockEstimateDialog = false
      if (confirm === true) {
        this.changeData({
          currentPage: 'Lock Estimates',
          newData: {
            property: this.cycle.property,
            chargeCode: this.cycle.chargeCode,
            cycleNumber: this.cycle.cycleNumber,
            lockEstimate: this.cycle.estimateIsLocked === false // flip lock
          }
        }).then(() => {
          this.active = `tab-${this.totalTabs[0]}`
          this.$emit('refreshCycle')
        }).catch(error => { console.log(error.response) })
      }
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders']),
    activeTabIndex () {
      if (this.active) {
        return this.totalTabs.indexOf(this.active.split('-')[1])
      } else {
        return 0
      }
    },
    buttonText () {
      return this.activeTabIndex === 0 ? 'Next' : 'Estimate Charges'
    },
    tableData () {
      if (this.active) {
        let data = 'Estimates Expense Accounts'
        let i = this.totalTabs.indexOf(this.active.split('-')[1])
        switch (i) {
          case 0:
            data = 'Estimates Expense Accounts'
            break
          case 1:
            data = 'Estimates Lease Expenses'
            break
        }
        return this.currentData(data).map(x => {
          x.readonly = x.isLocked
          return x
        })
      }
      return null
    },
    tableHeaders () {
      if (this.active) {
        let data = 'Estimates Expense Accounts'
        let i = this.totalTabs.indexOf(this.active.split('-')[1])
        switch (i) {
          case 0:
            data = 'Estimates Expense Accounts'
            break
          case 1:
            data = 'Estimates Lease Expenses'
            break
        }
        return this.currentHeaders(data)
      }
      return null
    },
    estimatesComplete () {
      if (this.cycle.estimateIsLocked) {
        return true
      }
      if (this.tableData && this.activeTabIndex === 0) {
        return !this.tableData.some(x => {
          return !x.estimates || x.estimates === ''
        })
      } else {
        return true
      }
    },
    lockEstimateIcon () {
      return this.cycle.estimateIsLocked ? 'lock_open' : 'lock'
    },
    lockEstimateButtonText () {
      return this.cycle.estimateIsLocked ? 'Unlock Estimate Cycle' : 'Lock Estimate Cycle'
    }
  },
  watch: {
    cycle: function () {
      this.getGlAccounts()
      this.getLeaseEstimates()
    },
    estimatesComplete: function (val) {
      if (val && val === true) {
        this.$emit('estimatesComplete', true)
      } else {
        this.$emit('estimatesComplete', false)
      }
    },
    selectedTab: function (val) {
      if (val) {
        this.active = `tab-${this.totalTabs[val]}`
      }
    },
    active: function (val) {
      if (val) {
        let i = this.totalTabs.indexOf(val.split('-')[1])
        switch (i) {
          case 0:
            this.getGlAccounts()
            break
          case 1:
            this.getLeaseEstimates()
            break
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-tabs {
    &.estimate__tabs {
      ::v-deep .v-tabs-bar {
        height: 40px;
        background-color: var(--v-resGray-lighten6)
      }
      ::v-deep .v-tab {
        justify-content: left;
        padding: 6px 6px;
        background-color: var(--v-resGray-lighten3);
        border-color: var(--v-resGray-lighten1);
        border-style: solid;
        border-width: 1px 1px 0px 1px;
        color: var(--v-resGray-darken4);
        .v-icon {
          color: var(--v-resGray-darken3);
        }
      }
      ::v-deep .v-tab--active {
        background-color: var(--v-resBlue-base);
        border-color: var(--v-resBlue-base);
        border-style: solid;
        border-width: 1px 1px 0px 1px;
        color: var(--v-resWhite-base) !important;
        .v-icon {
          color: var(--v-resWhite-base);
        }
        &.v-tab--disabled {
          opacity: 100%;
        }
      }
    }
  }

  .tabs-card {
    border-radius: 0;
    border-top: 2px var(--v-resBlue-base) solid;
    border-right: 1px var(--v-resGray-lighten1) solid;
    border-left: 1px var(--v-resGray-lighten1) solid;
    border-bottom: 1px var(--v-resGray-lighten1) solid;
  }

  .checkbox-label {
    margin: 0;
    padding: 10px 4px 0 0;
    height: 30px;
    ::v-deep label {
      color: var(--v-resBlack-base);
      font-size: 14px;
    }
  }
</style>
