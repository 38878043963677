<template>
  <div>
    <imports-landing>
    </imports-landing>
  </div>
</template>

<script>
import ImportsLanding from './ImportsLanding'

export default {
  data () {
    return {}
  },
  components: {
    ImportsLanding
  }
}
</script>
