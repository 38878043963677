<template>
  <v-container fluid class="resGray lighten-6">
    <v-row>
      <v-col cols="12" class="mt-3">
        <data-table
          class="mx-6"
          :headers="headers"
          :tableData="getData"
          :noData="'Select Charge Code to begin.'">
        </data-table>
      </v-col>
    </v-row>
    <v-app-bar flat height="36" class="mt-2">
      <v-spacer></v-spacer>
      <round-button
        color='var(--v-resGray-darken3)'
        height='30px'
        width='80px'
        :dark='true'
        :depressed='true'
        buttonText='Save'
        :backgroundColor="'var(--v-resGreen-base)'"
        borderColor='var(--v-resGray-lighten1)'
        buttonClass='mr-2'
        @click="save">
      </round-button>
    </v-app-bar>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DataTable from '../../BaseComponents/Table'
import RoundButton from '../../BaseComponents/RoundButton'

export default {
  components: {
    DataTable,
    RoundButton
  },
  data () {
    return {
      userId: 1
    }
  },
  methods: {
    ...mapActions(['fetchData', 'changeData']),
    save () {
      this.changeData({ currentPage: 'NotificationOptions', newData: { UserName: this.currentUserName, NotificationOptions: this.getData } }).then(() => {
        this.fetchData({newPage: 'NotificationOptions', params: { UserName: this.currentUserName }})
        setTimeout(() => {
          if (this.checkPermissions('Notifications') || this.currentUserIsAdmin) {
            this.fetchData({ newPage: 'Notifications' })
          }
        }, 750)
      }).catch(error => { console.log(error.response) })
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentUserName', 'checkPermissions', 'currentUserIsAdmin']),
    headers () {
      return this.currentHeaders('NotificationOptions')
    },
    getData () {
      let items = this.currentData('NotificationOptions')
      items.forEach(item => {
        item.readonly = false
      })
      return items
    }
  },
  created () {
    this.fetchData({newPage: 'NotificationOptions', params: { UserName: this.currentUserName }}).catch(error => { console.log(error.response) })
  }
}
</script>

<style lang="scss" scoped>
::v-deep .checkbox__container {
  height: 30px;
}
.space__table {
  ::v-deep .v-datatable {
  span {
    font-size: 12px;
    }
  }
}
</style>
