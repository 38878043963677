<template>
  <base-dialog
    :showDialog="showDialog"
    :title="'Delete Property Group'"
    :iconColor="'var(--v-resWhite-base)'"
    :maxWidth="500"
    :saveButtonText="'Yes, delete it!'"
    :saveButtonWidth="200"
    :saveButtonColor="'var(--v-resRed-base)'"
    :titleClass="'dark-header'"
    @cancel="cancel"
    @save="save">
    <v-layout row mb-4>
      <v-flex xs12>
        <span>
          The following report will be deleted!
        </span>
      </v-flex>
    </v-layout>
    <v-layout row mb-4>
      <v-flex>
        <span><b>{{ report.report_Name }}</b></span>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs12>
        <span>
          Do you want to continue?
        </span>
      </v-flex>
    </v-layout>
  </base-dialog>
</template>

<script>
import BaseDialog from '../../BaseComponents/BaseDialog'
import { mapActions } from 'vuex'

export default {
  components: {
    BaseDialog
  },
  props: ['showDialog', 'report'],
  data () {
    return {
    }
  },
  methods: {
    ...mapActions(['deleteData']),
    cancel () {
      this.$emit('cancel')
    },
    save () {
      this.deleteData({
        currentPage: 'SetupReports',
        removeItem: {
          reportId: this.report.report_Id
        }
      }).then(() => {
        this.$emit('deleteComplete')
      })
    }
  }
}
</script>
