<template>
  <v-card
    flat
    width="40"
    height="60"
    :class="['ma-2 month-range-button', active ? 'month-range-button--active' : '']">
    <div class="text-center month-range-button__icon-container">
      <v-icon
        large
        color="var(--v-resGray-darken2)"
        class="mt-2 pt-1">
        {{ comptedIcon }}
      </v-icon>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ['direction', 'active'],
  data () {
    return {}
  },
  computed: {
    comptedIcon () {
      if (this.direction === 'left') {
        return 'arrow_left'
      }
      return 'arrow_right'
    }
  }
}
</script>

<style lang="scss" scoped>
  .month-range-button {
    background: var(--v-resGray-lighten6);
    border: 1px solid var(--v-resGray-darken2);
    border-radius: 6px;
    cursor: pointer;
    &--active {
      background: var(--v-resWhite-base);
    }
    &__icon-container {
      height: 60px;
    }
  }
</style>
