<template>
  <v-container class="resGray lighten-6">
    <deposit-details
      v-if="depositeDetails"
      @DepositSlipComplete="completeDeposits">
    </deposit-details>
    <distribute-details
      v-if="distributeDetails"
      :depositDetails="depositeDetailsInfo"
      @EditDeposit="changeDepositDetails"
      @cancelDistribution="cancelDistribution"
      @completeDistribution="completeDistribute">
    </distribute-details>
  </v-container>
</template>

<script>
import DepositDetails from './CashReceiptComponents/DepositDetails'
import DistributeDetails from './CashReceiptComponents/DistributeDetails'
import { mapActions } from 'vuex'

export default {
  components: {
    DepositDetails,
    DistributeDetails
  },
  data () {
    return {
      depositeDetails: true,
      distributeDetails: false,
      depositeDetailsInfo: {}
    }
  },
  methods: {
    ...mapActions(['initializeStoreItem']),
    completeDeposits (val) {
      this.depositeDetailsInfo = val
      this.depositeDetails = false
      this.distributeDetails = true
    },
    completeDistribute () {
      this.distributeDetails = false
      this.depositeDetails = true
      this.resetWorkflow()
    },
    cancelDistribution () {
      this.distributeDetails = false
      this.depositeDetails = true
      this.resetWorkflow()
    },
    changeDepositDetails (val) {
      this.depositeDetailsInfo = val
    },
    resetWorkflow () {
      this.initializeStoreItem('BankDepositSlips')
      this.initializeStoreItem('LeaseOpenCharges')
    }
  }
}
</script>

<style lang="scss" scoped>
.distribution {
  width: 900px;
  thead {
    tr {
      height: 35px;
      background-color: var(--v-resGray-lighten3);
      font-size: 12px;
      th {
        padding: 0 14px;
        color: var(--v-resGray-darken5);
      }
    }
  }
  tbody {
    tr {
      height: 35px;
      td {
        padding: 0 14px;
      }
    }
  }
}

.dot {
  height: 10px;
  width: 10px;
  background-color: var(--dotColor);
  border-radius: 50%;
  margin-left: 2px;
  margin-right: 2px;
  display: inline-block;
}

.drop-down  {
  min-width: 50px;
  max-width: 340px;
  width: var(--width);
  ::v-deep .v-input__control {
    width: var(--width);
    min-height: 31px;
  }
  ::v-deep .v-input__slot {
    padding-right:0px !important;
    margin-bottom: 0px;
    span {
      font-size:12px;
    }
    .v-select__selections {
      width: calc(var(--width) - 7px - 35px);
    }
  }
  ::v-deep .v-input__append-inner {
    border-radius: 0px 4px 4px 0px;
    padding: 2px;
    margin-top:3px;
    .v-icon {
      color: var(--v-resGray-darken3);
    }
  }

  ::v-deep .v-text-field__details {
    margin-bottom: 0px;
  }

  ::v-deep .v-text-field--box .v-input__slot,
  ::v-deep .v-text-field--outline .v-input__slot {
    min-height: auto!important;
    display: flex!important;
    align-items: center!important;
    border-radius: 6px!important;
    border: solid 1px var(--v-resGray-darken2) !important;
    text-align: left!important;
  }
  ::v-deep .v-icon {
    font-size: 20px;
  }
  &__menu {
    .v-select-list {
      ::v-deep .v-list__tile {
          height: 30px !important;
      }
      ::v-deep .v-icon {
        font-size: 15px;
      }
    }
    .v-card {
      border-radius: 6px !important;
    }
    .v-select-list {
      ::v-deep .v-list {
        padding-bottom: 0px;
      }
      ::v-deep .v-list__tile {
        font-size: 13px;
      }
      ::v-deep .v-list__tile__action, .v-list__tile__avatar {
        min-width: 32px;
      }
    }
  }
  &__combobox {
    padding: 0px;
    margin: 0px;
    ::v-deep input {
      font-size: 12px;
    }
    ::v-deep .v-label {
      font-size: 12px;
    }
    ::v-deep .v-select__slot {
      display: -webkit-box;
    }
  }
  &__label {
    font-size: 12px;
  }
}

.v-overflow-btn .v-input__append-inner {
  padding-left: 10px;
  height: 30px!important;
  width: 30px!important;
  margin-top: 0px!important;
}

::v-deep table.v-table tfoot {
  height: 40px;
  tr {
    height: 40px;
    td {
      height: 40px;
      label {
        font-size: 12px;
      }
    }
  }
}
</style>
