export function convertArrayBuffer (content) {
  let binaryString = window.atob(content)
  let bytes = new Uint8Array(binaryString.length)
  return bytes.map((byte, i) => binaryString.charCodeAt(i))
}
export function createDownloadFile (body, fileName) {
  let blob = new Blob([body])
  let link = document.createElement('a')
  if (link.download !== undefined) {
    let url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', fileName)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}
