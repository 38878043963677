<template>
  <v-navigation-drawer app temporary v-model="LeftDrawer" dark width="246" v-show="false"> <!-- $vuetify.breakpoint.smAndDown -->
        <v-list dense>
            <v-list-item @click="openSideBar" class="my-2">
              <v-list-item-action>
                <v-icon>arrow_back</v-icon>
              </v-list-item-action>
              <v-list-item-content v-if="this.sideBarSelected">
                <span>{{ this.sideBarSelected.toUpperCase() }}</span>
              </v-list-item-content>
            </v-list-item>
            <template class="secondary-side-bar__menu-item"
            v-for="(item, index) in selectedList()">
            <v-list-item :key="index" v-if="item.sectionHeader">
              <v-list-item-title><span class="menu-header">{{ item.title }}</span></v-list-item-title>
            </v-list-item>
            <v-list-item :to="item.route" :key="index" v-else>
                <span class="pl-4">{{ item.title }}</span>
            </v-list-item>
          </template>
        </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data () {
    return {
      LeftDrawer: false,
      sideBarSelected: null,
      items: []
    }
  },
  created () {
    this.$eventHub.$on('open-LeftDrawer', this.openLeftDrawer)
    this.initialize()
  },
  computed: {
    currentSection: {
      get: function () {
        return this.$store.state.currentSection
      }
    }
  },
  methods: {
    initialize () {
      this.items = [
        { header: 'Home Pages',
          listItems: [
            { title: 'Home Pages', sectionHeader: true },
            { title: 'Commercial', route: '/HomePages/Commercial' },
            { title: 'Advanced', route: '/HomePages/Advanced' },
            { title: 'Setup', route: '/HomePages/Setup' },
            { title: 'Work Orders', route: '/HomePages/WorkOrders' }
          ]
        },
        { header: 'Dashboards',
          listItems: [
            { title: 'Property Management' },
            { title: 'Projects' },
            { title: 'Projects No Graphs' },
            { title: 'Financial Reporting' }
          ]
        },
        { header: 'Setup',
          section: 'Commercial',
          listItems: [
            { title: 'Leases', sectionHeader: true },
            { title: 'New Lease' },
            { title: 'Leases' },
            { title: 'Lease Amendments' },
            { title: 'Lease Options' },
            { title: 'Delete Lease' },
            { title: 'Properties and Setup', sectionHeader: true },
            { title: 'New Com Property' },
            { title: 'Properties' },
            { title: 'Buildings' },
            { title: 'Spaces' },
            { title: 'Space Measures' },
            { title: 'Charge Code Mapping' },
            { title: 'Property Charge Code' },
            { title: 'Property Groups' },
            { title: 'Delete Properties' }
          ]
        },
        { header: 'Transactions',
          section: 'Commercial',
          listItems: [
            { title: 'Charges and Receipts', sectionHeader: true },
            { title: 'Monthly Recurring Charges' },
            { title: 'Adjusting Charges and Credits' },
            { title: 'Cash Receipts' },
            { title: 'Late Fees' },
            { title: 'Void Cash Receipt and NSF' },
            { title: 'Refunds' },
            { title: 'Forms and Statements', sectionHeader: true },
            { title: 'Invoice Statements' },
            { title: 'Email Notifications' },
            { title: 'Leases', sectionHeader: true },
            { title: 'New Lease' },
            { title: 'Leases' },
            { title: 'Lease Amendments' },
            { title: 'Lease Options' },
            { title: 'Delete leases' }
          ]
        },
        { header: 'Setup',
          section: 'Advanced',
          listItems: [
            { title: 'Pools and Cycles', sectionHeader: true },
            { title: 'Pools' },
            { title: 'Cycles' },
            { title: 'Pool Expense Accounts Types' },
            { title: 'National Tenant', sectionHeader: true },
            { title: 'National Tenant' }
          ]
        },
        { header: 'Transactions',
          section: 'Advanced',
          listItems: [
            { title: 'Expense Recovery', sectionHeader: true },
            { title: 'Estimates' },
            { title: 'Reconciliations' },
            { title: 'Pass-Throughs' },
            { title: 'Retail', sectionHeader: true },
            { title: 'Enter Retail Sales' },
            { title: 'Calculate Breakpoint Charges' }
          ]
        },
        { header: 'Setup',
          section: 'Setup',
          listItems: [
            { title: 'Property Items and Types', sectionHeader: true },
            { title: 'Area Measures' },
            { title: 'Owners' },
            { title: 'Locations' },
            { title: 'Amenities' },
            { title: 'Property Types' },
            { title: 'Area Types' },
            { title: 'Space Types' },
            { title: 'Property Group Types' },
            { title: 'Amenities Types' },
            { title: 'Templates', sectionHeader: true },
            { title: 'Charge Code Templates' },
            { title: 'Area Measures Templates' },
            { title: 'Lease Clauses Templates' },
            { title: 'Evaluation Templates' },
            { title: 'Amenities Templates' },
            { title: 'Custom Fields', sectionHeader: true },
            { title: 'Property Custom Fields' },
            { title: 'Building Custom Items' },
            { title: 'Space Custom Items' },
            { title: 'Lease Custom Items' },
            { title: 'Custom Fields', sectionHeader: true },
            { title: 'Bank Account Types' },
            { title: 'Sales Categories' },
            { title: 'NAICS Codes' },
            { title: 'Evaluation' },
            { title: 'Evaluation Items' },
            { title: 'Evaluation Types' }
          ]
        },
        { header: 'Transactions',
          section: 'Setup',
          listItems: [
            { title: 'No Transaction Items to display', sectionHeader: true }
          ]
        },
        { header: 'System',
          listItems: [
            { title: 'Users / security' },
            { title: 'Teams' },
            { title: 'CRM' },
            { title: 'Allocations' },
            { title: 'Notifications' },
            { title: 'Alerts' },
            { title: 'Forms' },
            { title: 'Select database' },
            { title: 'System Utilities' }
          ]
        },
        { header: 'Help',
          listItems: [
            { title: 'User Guides' },
            { title: 'About ResMan Commercial' }
          ]
        },
        { header: 'Account Settings',
          listItems: [
            { title: 'Account Name' },
            { title: 'ResMan HomePage' },
            { title: 'Logout' }
          ]
        }]
    },
    openLeftDrawer: function (value) {
      this.LeftDrawer = value.switch
      this.sideBarSelected = value.header
    },
    selectedList: function () {
      if (this.sideBarSelected) {
        if (this.sideBarSelected === 'Setup' || this.sideBarSelected === 'Transactions') {
          return this.items.filter(item => {
            return item.header === this.sideBarSelected && item.section === this.currentSection
          })[0].listItems
        } else {
          return this.items.filter(item => {
            return item.header === this.sideBarSelected
          })[0].listItems
        }
      }
    },
    openSideBar: function () {
      this.LeftDrawer = false
      this.$eventHub.$emit('open-sideBar', true)
    }
  }
}
</script>
