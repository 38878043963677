<template>
  <v-card-text class="pa-1">
    <h3>Enter the lease information below to start.</h3>
    <v-row no-gutters class="pt-4">
      <v-col>
        <div>Which property would you like to create a lease for?</div>
        <drop-down
          :selectedValue="{ text: currentLease.propertyName }"
          :items="'Properties'"
          :multiple='false'
          width="200px"
          rules="required"
          @itemSelected="selectProperty">
        </drop-down>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-4">
      <v-col>
        <drop-down
          :selectedValue="currentLease.leaseTermName ? { text: currentLease.leaseTermName } : null"
          :customList="leaseTerm"
          :label="'Lease Term'"
          width="120px"
          rules="required"
          :genericLabel="true"
          :multiple='false'
          class="mr-4"
          @itemSelected="selectLeaseTerm">
        </drop-down>
        <date-picker
          :label="'Start Date'"
          class="mr-4"
          :defaultNull="true"
          rules="required"
          :initialDate="lease.startDate"
          @dateSelected="setStartDate">
        </date-picker>
        <date-picker
          :label="'Expire Date'"
          :defaultNull="true"
          rules="required"
          :initialDate="lease.endDate"
          @dateSelected="setExpireDate">
        </date-picker>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-4">
      <v-col>
        <text-input
          :value="currentLease.leaseName"
          class="mr-4"
          label='Lease Name'
          width="249px"
          rules="required"
          @textInputUpdated="updateLeaseName">
        </text-input>
        <drop-down
          :selectedValue="{ text: currentLease.leaseCategoryName }"
          :items="'Lease Types'"
          :label="'Lease Type'"
          :multiple='false'
          rules="required"
          width="150px"
          @itemSelected="selectLeaseType">
        </drop-down>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-4">
      <v-col>
        <text-input
          :value="currentLease.reference"
          class="mb-4"
          label='Reference (Optional)'
          width="249px"
          @textInputUpdated="updateReference">
        </text-input>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import DropDown from '../../BaseComponents/DropDown'
import TextInput from '../../BaseComponents/TextInput'
import DatePicker from '../../BaseComponents/DatePicker'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    DropDown,
    TextInput,
    DatePicker
  },
  data () {
    return {
      lease: {
        startDate: null,
        endDate: null
      }
    }
  },
  computed: {
    ...mapGetters(['currentReview', 'currentLease', 'currentStep', 'currentData']),
    leaseTerm () {
      const list = []
      for (var i = 1; i < 21; i++) {
        list.push({ text: i + ' year(s)', value: i.toString() })
      }
      return list
    }
  },
  methods: {
    ...mapActions(['changeLease', 'changePropertyChanged', 'changeData', 'initializeStoreItem', 'changeReview']),
    getToday () {
      return moment().format('L')
    },
    updateReference (val) {
      this.lease.reference = val
      this.changeLease(this.lease)
    },
    selectLeaseType (val) {
      this.lease.leaseCategoryName = val.text
      this.lease.leaseCategory = val.value
      this.changeLease(this.lease)
    },
    selectLeaseTerm (val) {
      this.lease.leaseTermName = val.text
      this.lease.leaseTerm = val.value
      if (this.lease.startDate) {
        this.setExpireDateLeaseTerm()
      }
      this.changeLease(this.lease)
      if (this.currentReview) {
        this.changeReview(false)
      }
    },
    setStartDate (val) {
      this.lease.startDate = moment(val).format('L')
      this.lease.startDate = moment(val).format('L')
      if (this.lease.leaseTerm) {
        this.setExpireDateLeaseTerm()
      }
      this.changeLease(this.lease)
      if (this.currentReview) {
        this.changeReview(false)
      }
    },
    setExpireDateLeaseTerm () {
      this.lease.endDate = moment(this.lease.startDate).add(this.lease.leaseTerm, 'y').subtract(1, 'd').format('L')
    },
    setExpireDate (val) {
      this.lease.endDate = moment(val).format('L')
      this.changeLease(this.lease)
      if (this.currentReview) {
        this.changeReview(false)
      }
    },
    updateLeaseName (val) {
      this.lease.name = val
      this.changeLease(this.lease)
    },
    selectProperty (val) {
      this.lease.propertyName = val.text
      this.lease.property = val.value
      this.changeLease(this.lease)
      this.changePropertyChanged(true)
      if (this.currentReview) {
        this.changeReview(false)
      }
    },
    createLease () {
      if (!this.errors.items.length > 0) {
        this.initializeStoreItem('LeasesNew')
        this.initializeStoreItem('LeaseAvailableSpaces')
        this.changeData({ currentPage: 'LeasesNew', newData: this.currentLease, updateItem: this.currentLease }).then(() => {
          setTimeout(() => {
            var lease = this.currentData('LeasesNew')[this.currentData('LeasesNew').length - 1]
            lease.propertyName = this.currentLease.propertyName
            lease.leaseTermName = this.currentLease.leaseTermName
            lease.leaseTerm = this.currentLease.leaseTerm
            lease.leaseCategoryName = this.currentLease.leaseCategoryName
            lease.startDate = moment(this.currentLease.startDate).format('L')
            lease.endDate = moment(this.currentLease.endDate).format('L')
            this.changeLease(lease)
          }, 250)
          this.changePropertyChanged(false)
          this.leasesComplete = true
        }).catch(error => { console.log(error.response) })
      }
    }
  },
  created () {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'setStep' && mutation.payload !== 'Lease') {
        if (this.currentStep === 'Lease') {
          this.createLease()
        }
      } else if (mutation.type === 'setNextStep') {
        if (this.currentStep === 'Spaces') {
          this.createLease()
        }
      }
    })
  },
  beforeDestroy () {
    this.unsubscribe()
  }
}
</script>
