<template>
  <imports-template
    ref='template'
    :canImport='canImport'
    :canExport='true'
    currentPage='GetImportPropertiesData'
    @downloadTemplate='downloadTemplate'
    @clearImportData='clearImportData'
    @saveUpload='saveUpload'
    @addNew='addNewItem'
    @submit='submit'>
    <template v-slot:body>
      <data-table
        :headers="headers"
        :tableData="getData"
        @itemDeleteRequested="deleteItem"
        @unlockEditing="unlockEdit"
        @dataChanged="save"
        @canceled="cancel"
        class="mx-6"
        :noData="'Please select an import template to upload.'">
      </data-table>
      <v-layout row mt-1 v-if='showWarning'>
        <v-icon small color="var(--v-resRed-base)">warning</v-icon>
        <span class='resRed-base--text'>
            {{ warningMessage }}
        </span>
      </v-layout>
    </template>
  </imports-template>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as fileDownload from '../../../../utility/fileDownloader'
import * as validation from '../../../../utility/validation'
import DataTable from '../../../BaseComponents/Table'
import ImportsTemplate from './ImportsTemplate'
export default {
  components: {
    DataTable,
    ImportsTemplate
  },
  data () {
    return {
      files: {},
      importDialog: false,
      confirmDialog: false
    }
  },
  methods: {
    ...mapActions(['fetchData', 'changeData', 'initializeStoreItem', 'fetchDropdown', 'addNew']),
    deleteItem (item) {
      this.$refs.template.deleteItem(item, this.getData)
    },
    unlockEdit (item) {
      this.$refs.template.unlockEdit(item, this.getData)
    },
    cancel (item) {
      this.$refs.template.cancelEdit(item, this.getData)
    },
    save (item) {
      this.$refs.template.save(item)
    },
    addNewItem () {
      this.addNew({
        currentPage: 'GetImportPropertiesData',
        newItem: {
          property: { text: null, value: null },
          propertyType: { text: null, value: null },
          propertyTemplate: { text: null, value: null },
          buildingName: null,
          areaName: null,
          areaType: { text: null, value: null },
          actionColumn: { delete: true, edit: true },
          readonly: false,
          new: true,
          errorToolTip: { hasIcon: false, tooltip: null, value: '' }
        }
      })
    },
    downloadTemplate () {
      this.fetchData({ newPage: 'ImportProperties' }).then(() => {
        let file = this.currentData('ImportProperties')
        let bufferArray = fileDownload.convertArrayBuffer(file.fileContent)
        fileDownload.createDownloadFile(bufferArray, file.fileName)
      }).catch(error => { console.log(error.response) })
    },
    saveUpload (item) {
      this.fetchDropdown({ newDropdown: 'Properties', params: { IncludeIncomplete: true, IncompleteOnly: true } }).catch(error => { console.log(error.response) })
      this.fetchDropdown({ newDropdown: 'Property Templates' }).catch(error => { console.log(error.response) })
      this.fetchDropdown({ newDropdown: 'Property Types' }).catch(error => { console.log(error.response) })
      this.fetchDropdown({ newDropdown: 'Area Types' }).then(() => {
        this.changeData({ currentPage: 'GetImportPropertiesData', newData: item, updateItem: {} }).then(() => {
          this.$refs.template.cancel()
        })
      }).catch(error => { console.log(error.response) })
    },
    submit () {
      this.changeData({ currentPage: 'ImportProperties', newData: { Properties: this.getData } }).then(() => {
        this.$refs.template.cancelImport()
      }).catch(error => { console.log(error.response) })
    },
    cancelImport () {
      this.confirmDialog = false
    },
    clearImportData () {
      this.initializeStoreItem('GetImportPropertiesData')
    }
  },
  computed: {
    ...mapGetters(['currentData', 'currentHeaders', 'currentDropdown']),
    headers () {
      let items = JSON.parse(JSON.stringify(this.currentHeaders('GetImportPropertiesData')))
      let validation = items.splice(items.findIndex(x => x.value === 'errorToolTip'), 1)
      return validation.concat(items)
    },
    rawData () {
      return this.currentData('GetImportPropertiesData')
    },
    getData () {
      let items = this.rawData
      let properties = this.currentDropdown('Properties')
      let propertiesLength = properties.length
      let propertyTemplates = this.currentDropdown('Property Templates')
      let propertyTemplatesLength = propertyTemplates.length
      let propertyTypes = this.currentDropdown('Property Types')
      let propertyTypesLength = propertyTypes.length
      let areaTypes = this.currentDropdown('Area Types')
      let areaTypesLength = areaTypes.length
      items.forEach(item => {
        if (propertiesLength > 0 && validation.hasValue(item.property) && validation.hasValue(item.property.text)) {
          item.property = properties.find(x => {
            if (validation.hasValue(x.text)) {
              return x.text.toLowerCase() === item.property.text.toLowerCase()
            }
          })
        }
        if (propertyTemplatesLength > 0 && validation.hasValue(item.propertyTemplate) && validation.hasValue(item.propertyTemplate.text)) {
          item.propertyTemplate = propertyTemplates.find(x => {
            if (validation.hasValue(x.text)) {
              return x.text.toLowerCase() === item.propertyTemplate.text.toLowerCase()
            }
          })
        }
        if (propertyTypesLength > 0 && validation.hasValue(item.propertyType) && validation.hasValue(item.propertyType.text)) {
          item.propertyType = propertyTypes.find(x => {
            if (validation.hasValue(x.text)) {
              return x.text.toLowerCase() === item.propertyType.text.toLowerCase()
            }
          })
        }
        if (areaTypesLength > 0 && validation.hasValue(item.areaType) && validation.hasValue(item.areaType.text)) {
          item.areaType = areaTypes.find(x => {
            if (validation.hasValue(x.text)) {
              return x.text.toLowerCase() === item.areaType.text.toLowerCase()
            }
          })
        }
        let errors = validation.validateValue(item, [
          { value: 'property', required: true },
          { value: 'propertyType', required: true },
          { value: 'propertyType', required: true },
          { value: 'buildingName', required: true, maxLength: 50 },
          { value: 'areaName', required: true, maxLength: 50 },
          { value: 'areaType', required: true }
        ], items)
        validation.handleErrorToolTip(item, errors)
      })
      return items
    },
    canImport () {
      if (this.getData.length > 0 && !this.showWarning) {
        return true
      }
      return false
    },
    warningMessage () {
      return this.getData.filter(x => x.errorToolTip.hasIcon === true).length + ' row(s) have errors. Please fix these to continue.'
    },
    showWarning () {
      return this.getData.filter(x => x.errorToolTip.hasIcon === true).length > 0
    }
  },
  beforeDestroy () {
    this.clearImportData()
  }
}
</script>
